import React, { useState } from 'react'
import styled from 'styled-components/macro'
import AttachmentInput from '../../Attachment/components/AttachmentInput'

const List = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
`

const AttachmentListItem = styled.li`
  margin-right: 0.5rem;
`
/**
 * AttachmentsList is one of the basic blocks for displaying files to the user
 * there are two types of display : if we are in a clinical exam, and if we are in a questionnaire
 * It has two features:
 * 1. it shows the list of file already uploaded to the server as an Attachment
 *    i.e. a big gray box with some controls
 * 2. it can show an upload progress when passing current and progress
 * @param attachments
 * @param current
 * @param progress
 * @param children
 * @param {function} onProgress
 * @param readOnly
 * @param onChange
 * @param maxOccurs
 * @returns {boolean|*}
 * @constructor
 */

const AttachmentsListFromEncounter = ({
  attachments,
  current,
  progress,
  children,
  onProgress,
  readOnly,
  onChange,
  maxOccurs,
  fileType,
}) => {
  const [feedBackMessage, setFeedBackMessage] = useState(null)
  const isMaxOccursReached =
    maxOccurs != null && maxOccurs === 1 && attachments.length > 0

  const onError = message => {
    setFeedBackMessage(message)
  }

  // children is used as a render prop which means it take a function
  // that returns a Component.
  // here we pass three parameters to the children function
  // the parent component will have to take care of what component should be
  // returned
  return (
    <>
      <List>
        {attachments.length !== 0 || current != null
          ? attachments.map((attachment, i) => (
              <AttachmentListItem key={i}>
                {children(attachment, null, i)}
              </AttachmentListItem>
            ))
          : null}
        {current && (
          <AttachmentListItem>{children(current, progress)}</AttachmentListItem>
        )}
        {/*
      AttachmentInput will handle the file upload, we update the state on upload
      progress and when the file is loaded, i.e. onChange.
      */}
        {readOnly === false && !isMaxOccursReached && (
          <AttachmentListItem>
            <AttachmentInput
              onProgress={onProgress}
              onChange={onChange}
              feedBackMessage={feedBackMessage}
              onError={onError}
              fileType={fileType}
            />
          </AttachmentListItem>
        )}
      </List>
      {feedBackMessage}
    </>
  )
}

export default AttachmentsListFromEncounter
