import createSimpleReducers from '../Shared/ducks/createSimpleReducers'
import { ReduxState } from '../Shared/ducks/types'
import { QuestionnaireResponse } from 'fhir-stu3'
const ducks = createSimpleReducers('QuestionnaireResponse')

const getAllResponses = (state: ReduxState): QuestionnaireResponse[] => {
  return state.QuestionnaireResponse.allIds.map(
    (id) => state.QuestionnaireResponse.byId[id]
  )
}
export const getResponsesByencounterID = (encounterID: string) => (
  state: ReduxState
): QuestionnaireResponse[] => {
  return getAllResponses(state).filter((response) => {
    return response.context?.reference === 'Encounter/' + encounterID
  })
}

export const getResponsesByQuestionnaireAndEncounterAndPatient = (
  questionnaireID: string,
  patientID: string,
  encounterID: string
) => (state: ReduxState): QuestionnaireResponse[] => {
  return getAllResponses(state).filter((response) => {
    return (
      response.questionnaire?.reference ===
        'Questionnaire/' + questionnaireID &&
      response.subject?.reference === 'Patient/' + patientID &&
      response.context?.reference === 'Encounter/' + encounterID
    )
  })
}

export default ducks
