import { useEffect } from 'react'
import mixpanel, {
  MIXPANEL_PATIENT_DETAILS_READ
} from '../../Shared/mixpanel/index'
import { useDispatch, useSelector } from 'react-redux'
import { Router } from '@reach/router'
import PatientInfo from './PatientInfo'
import PatientIntro from './PatientIntro'
import EncounterList from '../../Encounter/containers/EncounterList'
import DefaultEncounterRoute from '../../Encounter/components/DefaultEncounterRoute'
import StartEncounterRoute from '../../Encounter/components/StartEncounterRoute'
import { getCurrentEncounter } from '../../Encounter/selectors'
import PatientsGoBackDialog from '../../Shared/components/PatientsGoBackDialog'
import { deselectEncounter } from '../../Encounter/actions'
import { search } from '../../Shared/actions'
import ClinicalEncounter from '../../Encounter/components/ClinicalEncounter'
import {
  MEDEO_ENCOUNTER_TYPE_BOOKING,
  MEDEO_ENCOUNTER_TYPE_CLINICAL_EXAM,
  MEDEO_ENCOUNTER_TYPE_PRE_TELECONSULTATION,
  MEDEO_ENCOUNTER_TYPE_TELECONSULTATION_CONSULTATION
} from '../../utils/encounter-type'
import PatientPageHeader from '../containers/PatientPageHeader'
import PatientNotFoundCard from './PatientNotFoundCard'
import { useEffectOnce } from 'react-use'
import BookingRouter from '../../Booking/containers/BookingRouter'
import Grid from '../../Shared/components/GridLayout'
import PreconsultationPlanDefinition from '../../PlanDefinition/containers/PreconsultationPlanDefinition'
import TeleconsultationPlanDefinition from '../../PlanDefinition/containers/TeleconsultationPlanDefinition'
import usePatient from '../usePatient'
import usePractitioner from '../../Practitioner/usePractitioner'

const PatientPage = () => {
  useEffectOnce(() => {
    mixpanel.track(MIXPANEL_PATIENT_DETAILS_READ)
  })
  const patient = usePatient()
  const practitioner = usePractitioner()
  const currentEncounter = useSelector(getCurrentEncounter)
  const dispatch = useDispatch()
  const handleRemoveEncounter = () => {
    dispatch(deselectEncounter(currentEncounter?.id))
  }
  const hasCurrentEncounter = currentEncounter && currentEncounter.status !== 'entered-in-error'

  useEffect(() => {
    if (patient != null) {
      // Load procedure requests from patient
      // We do not only fetch the active ones so that once completed,
      // The procedure request would be updated in the store
      dispatch(
        search('ProcedureRequest', {
          subject: 'Patient/' + patient.id,
          _sort: '-_id',
          _include: 'ProcedureRequest:performer'
        })
      )
      dispatch(
        search('Encounter', {
          subject: `Patient/${patient.id}`,
          _sort: '-_id'
        })
      )
      dispatch(
        search('Communication', {
          subject: `Patient/${patient.id}`
        })
      )
    }
  }, [patient, dispatch])

  useEffectOnce(() => {
    // search for the Consent related with the patient
    dispatch(
      search('Consent', {
        patient: `Patient/${patient.id}`
      })
    )
  })
  // Starting from here we are no longer fetching the patient resource
  // This means either we have found a patient or we don't
  // If we don't have it we show the NotFoundCard for the user.
  if (patient == null) {
    console.error("Patient not found, this shouldn't happen")
    return <PatientNotFoundCard />
  }
  if (practitioner == null) {
    console.error("Practitioner not found, this shouldn't happen")
    return null
  }
  return (
    <>
      <Grid.Aside>
        <PatientsGoBackDialog
          hasCurrentEncounter={hasCurrentEncounter}
          onBack={handleRemoveEncounter}
        />
        <PatientIntro patient={patient} />

        <PatientInfo patient={patient} />
      </Grid.Aside>
      {/* @ts-ignore */}
      <Grid.Main>
        <PatientPageHeader
          patient={patient}
          hasCurrentEncounter={hasCurrentEncounter}
        />

        {/*
            This subrouter is responsible for selecting which component to show depending on the action the user is about
            to do, whether to display the list of encounter or to start one of the different kind of exam.
             */}
        <Router>
          {/*
              EncounterList does not use the default props,
              because we want to catch every wrong url somewhere else and redirect to history after
              */}
          <EncounterList path="history" patient={patient} pagination={2000} />

          {/*
              StartEncounterRoute creates an encounter in redux, i.e. currentEncounter
              if the currentEncounter is not set and the user tries to access clinical-exam or something else
              DefaultRoute will redirect to history
              */}
          {/* @ts-ignore */}
          <StartEncounterRoute path="start/:exam" />
          {/*
              The different types of exam are protected with DefaultRoute, if a prop is missing to start an exam
              It will safely redirect to the history page
              */}
          {/* @ts-ignore */}
          <DefaultEncounterRoute default>
            <BookingRouter path={`${MEDEO_ENCOUNTER_TYPE_BOOKING}/*`} patient={patient} />
            <ClinicalEncounter path={MEDEO_ENCOUNTER_TYPE_CLINICAL_EXAM} patient={patient} />
            <PreconsultationPlanDefinition
              path={MEDEO_ENCOUNTER_TYPE_PRE_TELECONSULTATION}
              encounter={currentEncounter!}
              patient={patient}
              practitioner={practitioner}
            />
            <TeleconsultationPlanDefinition
              path={MEDEO_ENCOUNTER_TYPE_TELECONSULTATION_CONSULTATION}
              encounter={currentEncounter!}
              patient={patient}
              practitioner={practitioner}
            />
          </DefaultEncounterRoute>
        </Router>
      </Grid.Main>
    </>
  )
}

export default PatientPage
