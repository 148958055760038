import React, { useContext, useState } from 'react'
import _ from 'lodash'
import { useDispatch } from 'react-redux'
import { Trans } from '@lingui/macro'
import { Form, Button } from '../../Components'
import styled from 'styled-components/macro'
import {
  removeItemByAddressInArray,
  duplicateItem,
  removeItemInResponse,
  getItemByLinkId
} from '../utils'
import { QuestionnaireResponseContext } from './Questionnaire'
import QuestionnaireComponent from './QuestionnaireComponent'

const CustomRow = styled(Form.Row)`
  margin-bottom: 0;
`

const ActDiv = styled.div`
  margin-bottom: 1rem;
`

/**
 * Get the duplicated items in the response
 *
 * @param {*} questionnaireResponse
 * @param {*} item
 */
const getItemsInResponse = (questionnaireResponse, linkId) => {
  // we look for the parent item as in order to fetch all the potential children duplicates
  const splittedAddress = linkId.split('.')
  splittedAddress.pop()
  const parentLinkId = splittedAddress.join('.')

  // check if the item is already in the response
  const filledItems = getItemByLinkId(questionnaireResponse, parentLinkId)

  // If it is, return the sub items, taht are the duplicates
  return filledItems && filledItems.item ? filledItems.item : null
}

const QuestionnaireDuplicableInput = ({ item, ...rest }) => {
  const [questionnaireResponse, dispatchQuestionnaireResponse] = useContext(
    QuestionnaireResponseContext
  )

  const dispatch = useDispatch()

  const filledItems = getItemsInResponse(questionnaireResponse, item.linkId)

  const [itemArray, setItemArray] = useState([{ ...item, isFirst: true }])

  // Here we check if duplicates are already in the response
  // and if their amount is inferior to the presently display amount of duplicates
  if (
    filledItems &&
    itemArray.length < filledItems.length &&
    itemArray.length !== 0
  ) {
    // If it is, we build an item array with the linkIds of the duplicated
    // items in the response in order to iniciate the component correctly
    let builtArray = []
    filledItems.forEach((i, j) => {
      const linkId = i.linkId
      const newItem = JSON.parse(
        JSON.stringify(item)
          .split(`${item.linkId}`)
          .join(linkId)
      )
      newItem.visible = true
      if (j < filledItems.length - 1) {newItem.showAddButton = false}
      if (j === 0) {newItem.isFirst = true}

      builtArray.push(newItem)
    })

    setItemArray(builtArray)
  }

  return itemArray.map((child, j) => (
    <ActDiv key={j} data-testid={`Act_${j}`}>
      {child.visible !== false && (
        <>
          <CustomRow>
            {child.item.map(i => (
              <QuestionnaireComponent key={i.linkId} item={i} {...rest} />
            ))}
          </CustomRow>
          {rest.readOnly === false && child.isFirst !== true && (
            <Button
              data-testid={`removeBtn_${j}`}
              color="ocean"
              variant="text"
              noborder={true}
              onClick={e => {
                e.preventDefault()
                const newArray = removeItemByAddressInArray(
                  itemArray,
                  child.linkId
                )

                newArray[newArray.length - 1].showAddButton = true

                removeItemInResponse(
                  questionnaireResponse,
                  child.linkId,
                  dispatchQuestionnaireResponse
                )
                setItemArray(newArray)
                dispatch({ type: 'INPUT_REMOVED', payload: {} })
              }}
            >
              <Trans>Remove</Trans>
            </Button>
          )}
        </>
      )}
      {rest.readOnly === false && child.showAddButton !== false && (
        <Button
          data-testid="addDuplicableInputBtn"
          color="ocean"
          variant="text"
          onClick={e => {
            e.preventDefault()
            const newArray = duplicateItem(itemArray, child)
            // Here we clone deep the array so that the update of the state gets detected
            // by a new array reference and triggers a re-render
            setItemArray(_.cloneDeep(newArray))
          }}
        >
          +<Trans>Add</Trans>
        </Button>
      )}
    </ActDiv>
  ))
  // I kept this commented in order to have them, when we will plug back care sheets
  /* {item &&
        item.repeats !== undefined &&
        item.visible !== false &&
        rest.readOnly === false && (
          <Button
            color="aqua"
            variant="text"
            noborder={true}
            onClick={e => {
              e.preventDefault()
              const newQuestionnaire = updateVisibleInItem(
                questionnaire,
                item.linkId
              )
              setQuestionnaire(newQuestionnaire)
              dispatch({ type: 'INPUT_REMOVED', payload: {} })
            }}
          >
            <Trans>Remove</Trans>
          </Button>
        )} */
  /* {item.repeats && item.repeats === true && rest.readOnly === false && (
        <Button
          color="aqua"
          variant="text"
          onClick={e => {
            e.preventDefault()
            const newQuestionnaire = addQuestionnaireInput({
              questionnaire,
              itemToDuplicate: item,
            })
            setQuestionnaire(newQuestionnaire)
            // dispatch an action to launch the re-render, because Context doesn't run that way
            dispatch({ type: 'INPUT_ADDED', payload: {} })
          }}
        >
          +<Trans>Add</Trans>
        </Button>
      )} */
}

export default QuestionnaireDuplicableInput
