import React, {
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useState
} from 'react'
import { Patient, Practitioner, QuestionnaireResponse, Slot } from 'fhir-stu3'
import { API, Auth } from 'aws-amplify'
import useEncounter from '../../Encounter/useEncounter'
import usePractitioner from '../../Practitioner/usePractitioner'

type BookingState = {
  patient?: Patient
  performer?: Practitioner
  motiveResponse?: QuestionnaireResponse
  slot?: Slot
  loading: boolean
}

const BookingContext = React.createContext<
  [BookingState, Dispatch<SetStateAction<BookingState>>] | null
>(null)

const useBookingContext = () => {
  const value = useContext(BookingContext)
  const { deselect, encounter } = useEncounter()
  const currentPractitioner = usePractitioner()
  if (value == null) {
    throw new Error('BookingContext is not initialized')
  }
  const [state, setState] = value

  //@todo Not sure this really belongs here, should be outside of the context?
  const book = useCallback(async () => {
    setState((s) => ({ ...s, loading: true }))
    if (encounter == null) {
      throw new Error('encounter should be defined')
    }
    const { slot, performer, motiveResponse } = state

    if (slot?.id == null) {
      throw new Error('slot should be defined')
    }
    if (performer?.id == null) {
      throw new Error('practitioner should be defined')
    }
    if (motiveResponse == null) {
      throw new Error('motiveResponse should be defined')
    }

    const body = {
      currentPractitionerID: currentPractitioner!.id,
      response: state.motiveResponse,
      patient: state.patient,
      encounterID: encounter?.id
    }

    await API.post('booking', `/booking/book/${slot.id}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`
      },
      body: body
    })
    deselect()
    setState((s) => ({ ...s, loading: false }))
  }, [setState, encounter, state, currentPractitioner, deselect])

  const handleChangeSlot = useCallback(
    ({ slot, performer }: { slot: Slot; performer: Practitioner }) => {
      setState((s) => ({
        ...s,
        slot,
        performer
      }))
    },
    [setState]
  )

  const handleChangeMotive = useCallback(
    (motiveResponse: QuestionnaireResponse) => {
      setState((s) => ({
        ...s,
        motiveResponse
      }))
    },
    [setState]
  )

  const handleChangePatient = useCallback(
    (patient: Patient | undefined) => {
      setState((s) => ({ ...s, patient }))
    },
    [setState]
  )

  return {
    state,
    handleChangeSlot,
    handleChangeMotive,
    handleChangePatient,
    book
  }
}

export const BookingContextProvider: FC<{
  children: ReactNode
  patient?: Patient
}> = ({ children, patient }) => {
  /**
   *
   * @property {QuestionnaireResponse} motiveResponse - response to the motive questionnaire,
   * @property {Patient} patient - the subject of the booking,
   *
   *
   * @property {Slot} slot - the selected slot in case of a scheduled booking
   * @property {Practitioner} practitioner - the practitioner from which the slot has been selected
   */
  const value = useState<BookingState>({
    ...(patient && { patient }),
    loading: false
  })

  return (
    <BookingContext.Provider value={value}>{children}</BookingContext.Provider>
  )
}
export default useBookingContext
