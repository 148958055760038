import { Trans } from '@lingui/macro'
import { Link } from '@reach/router'
import { transparentize } from 'polished'
import React, { useReducer, useEffect } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components/macro'
import { getPatientActiveProcedureRequests } from '../../ProcedureRequest/selectors'
import {
  MEDEO_TELECONSULTATION,
  MEDEO_PROCEDURE_REQUEST_BOOKING_APPOINTMENT_CODE
} from '../../utils/codes'
import RequirePermission from '../../Permissions/containers/RequirePermission'
import usePatient from '../../Patient/usePatient'

/**
 * This is a link that looks like a button hence the name.
 * Override default behaviors of :visited and :hover
 */
export const CustomLinkButton = styled(Link)`
  padding: 1rem;
  width: 100%;
  height: 8rem;
  display: flex;
  flex-direction: column;
  color: ${p => p.theme.ebony};
  border-radius: 0.25rem;
  &:visited {
    color: ${p => p.color.ebony};
  }
  &:hover {
    border-color: ${p => p.theme.ocean};
    cursor: pointer;
    background: ${p => transparentize(0.75, p.theme.ocean)};
  }
  text-decoration: none;
  border: 1px solid ${p => p.theme.nevada};
  text-align: left;
  //text-transform: capitalize;
  font-weight: normal;
  font-size: ${p => p.theme.medium};
`

const ButtonText = styled.div`
  display: inline-block;
`
const DisableBox = styled.div`
  background: rgba(0, 0, 0, 0.1);
  padding: 1rem;
  cursor: not-allowed;
  width: 100%;
  height: 8rem;
  color: rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;
  border-radius: 0.25rem;
`

const LabelDiv = styled.div`
  opacity: 0.5;
  padding-top: 0.5rem;
  font-size: small;
`
const initialState = {
  displayTeleconsultationMessage: false,
  message: null
}

const reducer = (state, action) => {
  switch (action) {
    case 'preconsultation-blocked':
      return {
        displayTeleconsultationMessage: true,
        message: (
          <Trans>
            First you need to take a new appointment to start the remote
            consultation process.
          </Trans>
        )
      }
    case 'teleconsultation-blocked':
      return {
        displayTeleconsultationMessage: true,
        message: (
          <Trans>
            This patient has already a process of remote teleconsultation.
            Please close the actual one before to begin and a new remote
            teleconsultation request.
          </Trans>
        )
      }
    case 'wrong-day':
      return {
        displayTeleconsultationMessage: true,
        message: (
          <Trans>
            The appointment booked for this patient will not happen today.
            Please come back on the appointment day to continue the
            teleconsultation process.
          </Trans>
        )
      }

    case 'booking-blocked':
      return {
        displayTeleconsultationMessage: true,
        message: null
      }
    case 'recent-appointment':
      return {
        displayTeleconsultationMessage: true,
        message: (
          <Trans>
            You already took an appointment less than 20 minutes ago. Please
            start again in a little longer.
          </Trans>
        )
      }
    case 'teleconsultation-enabled':
      return initialState
    default:
      return initialState
  }
}

const ModalCreateEncounterButton = ({ encounter, hasAppointmentToday }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const currentPatient = usePatient()
  // Select the patient procedure from the state which are linked to the new encounter
  const patientProcedureRequests = useSelector(state =>
    getPatientActiveProcedureRequests(state, currentPatient.id)
  )

  const linkedProcedureRequestsForTeleconsulation = patientProcedureRequests.filter(
    pr => pr.code?.coding?.[0]?.code === MEDEO_TELECONSULTATION
  )

  const linkedProcedureRequestsForBooking = patientProcedureRequests.filter(
    pr =>
      pr.code?.coding?.[0]?.code ===
      MEDEO_PROCEDURE_REQUEST_BOOKING_APPOINTMENT_CODE
  )

  // here we assume several things :
  // 1. the patient will never take an appointment while he has a ongoing appointment
  // 2. the practitioner cannot have access to the pre consultation until it's the day of the appointment
  useEffect(() => {
    // This is for the 'Démarrer une téléconsultation' button
    if (encounter.linkedProcedureRequestCode === MEDEO_TELECONSULTATION) {
      // if the appointment is today and there is no preconsultation, we want to do it
      if (
        linkedProcedureRequestsForBooking.length > 0 &&
        linkedProcedureRequestsForTeleconsulation.length === 0 &&
        hasAppointmentToday
      ) {
        dispatch('teleconsultation-enabled')
        // If there was an appointment booked but that it is not planned for today
      } else if (
        linkedProcedureRequestsForBooking.length > 0 &&
        linkedProcedureRequestsForTeleconsulation.length === 0 &&
        !hasAppointmentToday
      ) {
        dispatch('wrong-day')
      } else {
        dispatch('preconsultation-blocked')
      }
    }
    // this is for 'Prendre un rendez-vous' button
    else if (
      encounter.linkedProcedureRequestCode ===
      MEDEO_PROCEDURE_REQUEST_BOOKING_APPOINTMENT_CODE
    ) {
      // if there is already a ProcedureRequest for a booking which is active
      if (linkedProcedureRequestsForBooking.length > 0) {
        dispatch('booking-blocked')
      }
      // if there is still things done without the teleconsultation express process
      else if (
        linkedProcedureRequestsForBooking.length === 0 &&
        linkedProcedureRequestsForTeleconsulation.length > 0
      ) {
        dispatch('teleconsultation-blocked')
      } else {
        dispatch('teleconsultation-enabled')
      }
    }
    // this is for every other case
    else {
      dispatch('teleconsultation-enabled')
    }
  }, [
    linkedProcedureRequestsForTeleconsulation.length,
    encounter.linkedProcedureRequestCode,
    hasAppointmentToday,
    linkedProcedureRequestsForBooking.length
  ])
  if (state.displayTeleconsultationMessage === true && state.message != null)
    {return (
      <DisableBox>
        <ButtonText>{encounter.display}</ButtonText>
        <LabelDiv>{state?.message}</LabelDiv>
      </DisableBox>
    )}
  return state.displayTeleconsultationMessage ? null : (
    <RequirePermission resource={encounter.action} action="start">
      <CustomLinkButton
        to={`start/${encounter.coding[0].code}`}
        color="white"
        variant="outline"
      >
        <ButtonText>{encounter.display}</ButtonText>
        <LabelDiv>{encounter?.label}</LabelDiv>
      </CustomLinkButton>
    </RequirePermission>
  )
}

export default ModalCreateEncounterButton
