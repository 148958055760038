import { takeEvery } from 'redux-saga/effects'
import {
  removeFhirResourceWorker,
  saveFhirResourceWorker,
} from '../Shared/sagas'
import {
  REMOVING_ALLERGY_INTOLERANCE,
  CREATING_ALLERGY_INTOLERANCE,
} from './actions'

export default function* watcherSaga() {
  yield takeEvery(
    REMOVING_ALLERGY_INTOLERANCE,
    removeFhirResourceWorker,
    ({ payload: p }) => ({ resourceType: 'AllergyIntolerance', id: p.id })
  )

  yield takeEvery(
    CREATING_ALLERGY_INTOLERANCE,
    saveFhirResourceWorker,
    a => a.payload
  )
}
