import RequiredLabel from './RequiredLabel'
import React from 'react'
import styled, { css } from 'styled-components/macro'

const Flex = styled.div`
  display: flex;
  ${p =>
    p.orientation === 'column' &&
    css`
      flex-direction: column;
      & > * {
        margin-right: 0;
      }
      & > :not(:last-child) {
        margin-bottom: 0.5rem;
      }
    `}
`
const RadioGroup = ({
  label,
  required,
  readOnly,
  children,
  value,
  orientation,
}) => {
  const shouldDisplayPlaceholder = readOnly === true && value == null
  return (
    <div>
      <RequiredLabel required={required}>{label}</RequiredLabel>
      <Flex orientation={orientation}>
        {shouldDisplayPlaceholder === true ? '-' : children}
      </Flex>
    </div>
  )
}

export default RadioGroup
