import React, { useState, useContext } from 'react'
import styled from 'styled-components/macro'
import { Input } from '../Components'
import * as R from 'ramda'

import Subheading from '../Shared/components/Subheading'

import { removeItemAtIndex } from '../Shared/utils'
import Centered from '../Shared/components/Centered'
import { ModelContext, ReadOnlyContext } from './utils'
import Tag from '../Shared/components/Tag'

const Container = styled.div`
  width: 100%;
  position: relative;
`

const ListContainer = styled.div`
  width: 100%;
  margin-bottom: ${p => p.theme.spacing.medium};
  min-height: 5rem;
  border-radius: 0.25rem;
  border: 1px solid ${p => p.theme.gray};
  background-color: ${p => p.theme.cream};
  display: flex;
  flex-flow: row wrap;
  padding: ${p => p.theme.spacing.small};
  flex: 1;
`

const HiddenSelect = styled.select`
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
`

const TagInput = ({
  yaml,
  name,
  fallback,
  id,
  onRemove,
  placeholder,
  ...rest
}) => {
  const [model, setModel] = useContext(ModelContext)
  const readOnly = useContext(ReadOnlyContext)
  const defaultValue = (model[yaml.map] || []).map(o => o[yaml.value])
  const [options, setOptions] = useState(defaultValue || [])
  const [value, setValue] = useState('')
  const onSubmit = e => {
    e.preventDefault()
    const trimmedValue = (value || '').trim()
    if (trimmedValue !== '') {
      const updatedOptions = options.concat(trimmedValue)
      setOptions(updatedOptions)
      const lens = R.lensPath(yaml.map.split('.'))
      setModel(
        R.mergeDeepRight(
          model,
          R.set(
            lens,
            updatedOptions.map(o => ({ [yaml.value]: o })),
            {}
          )
        )
      )
    }
    setValue('')
  }

  const handleTagRemove = i => {
    setOptions(removeItemAtIndex(options, i))
    onRemove(i)
  }

  return (
    <Container className="fs-block">
      {/*<pre>{JSON.stringify(model)}</pre>*/}

      <ListContainer>
        {options.length ? (
          options.map((item, i) => (
            <Tag
              key={i}
              value={item}
              readOnly={readOnly}
              onRemove={() => handleTagRemove(i)}
            />
          ))
        ) : (
          <Centered>
            <Subheading>{fallback}</Subheading>
          </Centered>
        )}
      </ListContainer>
      {!readOnly && (
        <Input
          type="text"
          backgroundColor="white"
          label=""
          value={value}
          name={`${name}-input`}
          onKeyPress={e => {
            if (e.which === 13) {
              return onSubmit(e)
            }
          }}
          onChange={e => setValue(e.target.value)}
          onBlur={onSubmit}
          autoComplete="off"
          readOnly={readOnly}
          placeholder={`+  ${placeholder}`}
        />
      )}
      <HiddenSelect
        name={name}
        id={id}
        multiple
        value={options}
        onChange={() => {}}
        {...rest}
      >
        {options.map((o, i) => (
          <option key={i} value={o}>
            {o}
          </option>
        ))}
      </HiddenSelect>
    </Container>
  )
}

TagInput.defaultProps = {
  readOnly: false,
  onRemove: () => {}
}

const MultiSection = styled.section`
  width: 100%;
  margin-top: ${p => p.theme.spacing.medium};
`

const YamlTagsInput = ({ yaml, readOnly }) => (
  <MultiSection>
    <span>{yaml.label}</span>
    <Subheading>{yaml.subtitle}</Subheading>
    <TagInput
      yaml={yaml}
      name={yaml.label}
      id={yaml.label}
      fallback={yaml.fallback}
      placeholder={yaml.placeholder}
      data-yaml-form={yaml.value}
      data-yaml-map={yaml.map}
      readOnly={readOnly}
      label=""
    />
  </MultiSection>
)
export default YamlTagsInput
