import React from 'react'
import styled from 'styled-components/macro'
import { Trans } from '@lingui/macro'

const ValueDiv = styled.div``

const BooleanAnswer = ({ value }) => {
  // We use this switch to compute a string out of the boolean answer we have
  // Below we declare a IIFE function (Immediately Invoked Function Expressions), which enables
  // to directly invoke the function as soon as it is created.
  const answer = (booleanValue => {
    switch (booleanValue) {
      case false:
        return <Trans>No</Trans>
      case true:
        return <Trans>Yes</Trans>
      default:
        return booleanValue
    }
  })(value)

  return <ValueDiv>{answer}</ValueDiv>
}

export default BooleanAnswer
