import React, { useContext } from 'react'
import { Trans } from '@lingui/macro'
import { Table, Span } from '../../Components'
import styled from 'styled-components/macro'
import Centered from '../../Shared/components/Centered'
import RequirePermission from '../../Permissions/containers/RequirePermission'
import { CurrentPractitionerRoleContext } from '../../PractitionerRole/containers/CurrentPractitionerRoleProvider'
import StaticErrorNotification, {
  NO_ESANTE_ROLE_ERROR
} from '../../Notifications/components/StaticErrorNotification'

const WIDTHS = [33, 15, 10, 23]

const Container = styled.div``

const HeaderCell = styled.th`
  width: ${props => props.width}%;
`

const PatientList = ({ patients, patientsTotal, children }) => {
  const [currentPractitionerRole] = useContext(CurrentPractitionerRoleContext)
  return (
    <Container>
      {currentPractitionerRole == null && (
        <StaticErrorNotification error={NO_ESANTE_ROLE_ERROR} />
      )}
      <Table style={{ margin: 0 }}>
        <thead>
          <tr>
            <HeaderCell width={WIDTHS[0]} style={{ paddingLeft: '2rem' }}>
              <Trans>Patients</Trans>
              <Span color="gray" style={{ paddingLeft: '0.5rem' }}>
                ({patientsTotal})
              </Span>
            </HeaderCell>
            <HeaderCell width={WIDTHS[1]}>
              <Trans>Age</Trans>
            </HeaderCell>
            <HeaderCell width={WIDTHS[2]}>
              <Trans>Gender</Trans>
            </HeaderCell>
            <HeaderCell width={WIDTHS[4]}>
              <Trans>Last Visit</Trans>
            </HeaderCell>
            <HeaderCell width={WIDTHS[4]}>
              <RequirePermission
                resource={'requestPatientList'}
                action={'read'}
              >
                <Centered>
                  <Trans>Requests</Trans>
                </Centered>
              </RequirePermission>
            </HeaderCell>
          </tr>
        </thead>
        <tbody>
          {patients
            .filter(p => p != null) // we need to remove this filter when we move to amazon :)
            .map(children)}
        </tbody>
      </Table>
    </Container>
  )
}

PatientList.defaultProps = {
  patients: [],
  total: null
}

export default PatientList
