export const PRACTITIONER_ROLE_RECEIVED = 'PRACTITIONER_ROLE_RECEIVED'
export const PRACTITIONER_ROLES_RECEIVED = 'PRACTITIONER_ROLES_RECEIVED'
export const FETCHING_PRACTITIONER_ROLE = 'FETCHING_PRACTITIONER_ROLE'
export const EDITING_PRACTITIONER_ROLE = 'EDITING_PRACTITIONER_ROLE'
export const CREATING_PRACTITIONER_ROLE = 'CREATING_PRACTITIONER_ROLE'

export const createPractitionerRole = payload => ({
  type: CREATING_PRACTITIONER_ROLE,
  payload: payload,
})
