import React from 'react'

import { Router } from '@reach/router'
import DeviceList from './DeviceList'
import Device from '../components/DevicePage'

/**
 * Holds the router for the devices part of the settings
 * @return {*}
 * @constructor
 */
const Devices = () => {
  return (
    <Router>
      <Device path="/:deviceId/*" />
      {/* by default shows the devices list*/}
      <DeviceList path="/" />
    </Router>
  )
}
export default Devices
