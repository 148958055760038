import React, { useContext } from 'react'
import styled from 'styled-components/macro'
import { getLargeDisplay, getMediumDisplay } from '../utils'
import { VideoScreenContext } from '../containers/VideoSequenceManager'

export const MediumMessage = styled.div`
  margin-top: ${props => props.theme.spacing.small};
  margin-bottom: ${props => props.theme.spacing.small};
  color: white;
  text-align: center;
  font-size: ${props => props.theme.medium};
`

const LargeMessage = styled.div`
  margin-top: ${props => props.theme.spacing.small};
  margin-bottom: ${props => props.theme.spacing.small};
  color: white;
  text-align: center;
  font-size: ${props => props.theme.large};
`

const Container = styled.div`
  display: flex;
  align-content: center;
  flex-direction: column;
  justify-content: center;
  margin: 2rem;
`

export const Indications = () => {
  const [{ patientSide, status }] = useContext(VideoScreenContext)
  return (
    <Container>
      <LargeMessage>{getLargeDisplay(status)}</LargeMessage>
      <MediumMessage>{getMediumDisplay(status, patientSide)}</MediumMessage>
    </Container>
  )
}

export default Indications
