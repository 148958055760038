import React from 'react'
import { useDispatch } from 'react-redux'
import { deleteNotification } from '../actions'
import styled from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
const CloseBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: none;
  color: inherit;
  cursor: pointer;
  font-size: 2em;
  border-radius: 0.5rem;
  z-index: 1;
`

const DeleteNotificationButton = () => {
  const dispatch = useDispatch()

  const button = (
    <CloseBtn onClick={() => dispatch(deleteNotification())}>
      <FontAwesomeIcon size="xs" icon={faTimes} />
    </CloseBtn>
  )

  return button
}

export default DeleteNotificationButton
