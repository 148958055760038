import * as Sentry from '@sentry/react';
import isEmpty from 'lodash/isEmpty';
import { applyMiddleware, compose, createStore } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';
import ducks from '../ducks';
import rootSaga from '../sagas';

const persistConfig = {
  key: 'root',
  storage: storage,
  whitelist: ['auth', 'language']
};

const persistedReducer = persistReducer(persistConfig, ducks);

const sagaMiddleware = createSagaMiddleware({
  onError(err) {
    Sentry.captureException(err);
  }
});

// this enhancer will send redux state to sentry when a crash occurs
const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // c.f. https://docs.sentry.io/clients/javascript/usage/#raven-js-additional-context
  stateTransformer: state => {
    // remove unnecessary states and send the rest to sentry
    // fhirClient won't be relevant
    // neither is notifications or _persist
    // auth could contain sensible info
    const { ...rest } = state;

    // byId subreducers are not relevant here.
    // byIds contain sensible info and can be checked directly in database
    // we send allIds and othersubreducers to provided debug data
    const entries = Object.entries({ ...rest })
      // we filter empty parts of the state.
      .filter(([, value]) => {
        if (value != null) {
          const { byId, ...otherSubReducers } = value;
          return !isEmpty(byId) || !isEmpty({ ...otherSubReducers });
        } else {
          return null;
        }
      })
      // we remove byId
      .map(([key, value]) => {
        if (value != null) {
          const { allIds, ...otherSubReducers } = value;
          return [key, { allIds, ...otherSubReducers }];
        }
        return null;
      });
    // reconstruct the altered state
    return Object.fromEntries(entries);
  }
});

// eslint-disable-next-line
const storeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  persistedReducer,
  storeEnhancers(applyMiddleware(sagaMiddleware), sentryReduxEnhancer)
);

sagaMiddleware.run(rootSaga);

export const persistor = persistStore(store);
export default store;
