import { Trans } from '@lingui/macro'
import React from 'react'
import { useSelector } from 'react-redux'
import Attachment from '../../Attachment/components/Attachment'
import AttachmentDownloadControl from '../../Attachment/components/AttachmentDownloadControl'
import AttachmentPrintControl from '../../Attachment/components/AttachmentPrintControl'
import AttachmentViewControl from '../../Attachment/components/AttachmentViewControl'
import { getConsentsByPatientSortedByDate } from '../selectors'

const PatientConsent = ({ patientID }) => {
  const [consent] = useSelector(s =>
    getConsentsByPatientSortedByDate(s, patientID)
  )
  return consent != null ? (
    <Attachment attachment={consent.sourceAttachment}>
      <AttachmentDownloadControl attachment={consent.sourceAttachment} />
      <AttachmentPrintControl attachment={consent.sourceAttachment} />
      <AttachmentViewControl attachment={consent.sourceAttachment} />
    </Attachment>
  ) : (
    <p>
      <Trans>No consent found</Trans>
    </p>
  )
}

export default PatientConsent
