import { Trans } from '@lingui/macro'
import { useNavigate } from '@reach/router'
import * as Sentry from '@sentry/react'
import { API, Auth } from 'aws-amplify'
import { Encounter, Patient, Practitioner, ProcedureRequest } from 'fhir-stu3'
import { FC, StrictMode, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from '../../Components'
import { saveEncounterSequence } from '../../Encounter/actions'
import { getCurrentOrganization } from '../../Organization/selector'
import { PlanDefinitionState } from '../../PlanDefinition/types'
import { TOTAL_PRICE_ACTION_VALUE } from '../../PlanDefinition/utils'
import { getQuestionnaireByIdentifier } from '../../Questionnaire/selector'
import mixpanel, {
  MIXPANEL_TELECONCULTATION_FINISHED
} from '../../Shared/mixpanel'
import {
  FhirEnvIsProd,
  GetFSEQuestionnaireIDs,
  GetOrderQuestionnaireIDs
} from '../../_common/config'
import { FinishBody } from '../../_common/Request/types/booking'
import { organizationStringUrl } from '../utils'
const FinishTeleconsultationButton: FC<{
  encounter: Encounter
  procedureRequest: ProcedureRequest
  state: PlanDefinitionState
  patient: Patient
  practitioner: Practitioner
}> = ({ state, procedureRequest, encounter, patient, practitioner }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const currentOrg = useSelector(getCurrentOrganization)
  const navigate = useNavigate()
  const reduxDispatch = useDispatch()
  const totalPriceQuestionnaire = useSelector(
    getQuestionnaireByIdentifier(TOTAL_PRICE_ACTION_VALUE)
  )

  const handleFinishConsultation = async () => {
    setIsLoading(true)
    if (process.env.NODE_ENV !== 'test') {
      const missingFSEOrDocs =
        state.sequenceResponse.filter((response) =>
          // caresheet, document,
          GetFSEQuestionnaireIDs().includes(response?.questionnaireId)
        ).length === 0
      const missingOrderOrDocs =
        state.sequenceResponse.filter((response) =>
          // ordo, paramedicale, document,
          GetOrderQuestionnaireIDs().includes(response?.questionnaireId)
        ).length === 0
      if (missingFSEOrDocs) {
        // eslint-disable-next-line
        const confirmWithoutFSE = confirm(
          'Souhaitez-vous clôturer cet acte sans fournir de feuille de soin ?'
        )
        if (!confirmWithoutFSE) {
          return
        }
      }
      if (missingOrderOrDocs) {
        // eslint-disable-next-line no-restricted-globals, no-alert
        const confirmWithoutOrder = confirm(
          "Souhaitez-vous clôturer cet acte sans fournir d'ordonnance à votre patient ?"
        )
        if (!confirmWithoutOrder) {
          return
        }
      }
    }

    // first we make sure the planDefinition corresponds to teleconsultation_consultation
    // send mixpanel event only in prod
    // comment the condition to test on dev
    if (FhirEnvIsProd() === true) {
      mixpanel.track(MIXPANEL_TELECONCULTATION_FINISHED, {
        requester: procedureRequest?.requester?.onBehalfOf?.display,
        requested: organizationStringUrl(currentOrg)
      })
    }
    let priceResponse = 0
    if (totalPriceQuestionnaire !== undefined) {
      state.sequenceResponse.forEach((response) => {
        if (response.questionnaireId === totalPriceQuestionnaire.id) {
          // Price is always in the first answer.
          priceResponse = response.answer.item[0].answer[0].valueQuantity.value
        }
      })
    }
    const body: FinishBody = {
      encounter: encounter,
      totalPrice: priceResponse
    }
    await API.post('booking', `/booking/finish/${procedureRequest.id}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`
      },
      body: body
    })
      .then(() => {
        reduxDispatch(
          saveEncounterSequence(
            patient.id,
            practitioner.id,
            state.sequenceResponse,
            state.sequenceWorkThrough.action,
            state.planDefinitionId
          )
        )
        setIsLoading(false)
        return navigate('/teleconsultation')
      })
      .catch((e) => {
        Sentry.captureException(e)
      })
  }

  return (
    <StrictMode>
      <Button
        disabled={isLoading}
        data-testid="finishTeleconsultationBtn"
        color="ocean"
        data-test="finish-consultation-button"
        onClick={handleFinishConsultation}
      >
        <Trans>Finish the consultation</Trans>
      </Button>
    </StrictMode>
  )
}

export default FinishTeleconsultationButton

/**
 *    const isTeleconsultation =
 *       teleconsultationPlanDefinition &&
 *       teleconsultationPlanDefinition.id === state.planDefinitionId
 *
 *     if (isTeleconsultation) {
 *       // setIsLoading(true)
 *       // Here we extract the price from the totalValue response
 *       // The price input will take the total value of the caresheet if there is one
 *       // if there is no caresheet it will take the value of the totalPrice response
 *       let price = 0
 *       // Here we extract the price from the questionnaire totalPrice
 *       if (totalPriceQuestionnaire != null && careSheetQuestionnaire != null) {
 *         const totalPriceIndex = state.sequenceResponse.findIndex(
 *           sr =>
 *             sr.questionnaireId &&
 *             sr.questionnaireId === totalPriceQuestionnaire.id
 *         )
 *         const isTotalPriceResponseInMyState = totalPriceIndex !== -1
 *
 *         if (isTotalPriceResponseInMyState) {
 *           const totalPriceResponse = state.sequenceResponse[totalPriceIndex]
 *           const priceResponse = getItemByLinkId(totalPriceResponse.answer, '1')
 *           price = parseInt(priceResponse?.answer?.[0]?.valueQuantity?.value, 10)
 *         }
 */
