export const getAllDocumentReferences = state =>
  state.documentReferences.allIds.map(id => state.documentReferences.byId[id])

export const getDocumentReferencesFromencounterID = (
  state,
  encounterID,
  filters = [() => true]
) => {
  if (encounterID != null) {
    return getAllDocumentReferences(state)
      .filter(d => d.context.encounter.reference === `Encounter/${encounterID}`)
      .filter(d => filters.every(f => f(d)))
  } else {
    return []
  }
}

export const filterOutRelatingDocumentReference = d => d.related == null
export const filterOutEnteredInErrorDocumentReference = d =>
  d.status !== 'entered-in-error'

export const isBinaryCreated = (state, id) => {
  return state.documentReferences.createdBinaryIds.some(
    binaryId => binaryId === id
  )
}
