import React from 'react'
import { Label } from '../../Components'
import AttachmentAnswer from './AttachmentAnswer'
import styled from 'styled-components/macro'
import DateAnswer from './DateAnswer'
import DateTimeAnswer from './DateTimeAnswer'
import QuantityAnswer from './QuantityAnswer'
import CodingAnswer from './CodingAnswer'
import StringAnswer from './StringAnswer'
import BooleanAnswer from './BooleanAnswer'
import InlineList from '../../Shared/components/InlineList'
import InlineListItem from '../../Shared/components/InlineListItem'
import { MEDEO_QUESTIONNAIRE_TAG } from '../../utils/codes'
import { Trans } from '@lingui/macro'

const ItemDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
`

const AnswerDiv = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

const ValueDiv = styled.div``

const Answer = ({ value, valueType }) => {
  switch (valueType) {
    case 'valueString':
      return <StringAnswer value={value} />
    case 'valueBoolean':
      return <BooleanAnswer value={value} />
    case 'valueDecimal':
    case 'valueInteger':
      return <ValueDiv>{value}</ValueDiv>
    case 'valueDate':
      return <DateAnswer value={value} />
    case 'valueDateTime':
      return <DateTimeAnswer value={value} />
    case 'valueQuantity':
      return <QuantityAnswer value={value} />
    case 'valueAttachment':
      return <AttachmentAnswer value={value} />
    case 'valueCoding':
      return <CodingAnswer value={value} />
    default:
      return null
  }
}

const QuestionnaireResponseItem = ({ responseItem }) => {
  // If the response tiem has sub items, iterate through them
  if (responseItem?.item) {
    return (
      <>
        {/** Display the potential parent item text as a title */}
        {responseItem.text && <h4>{responseItem.text}</h4>}
        {responseItem.item.map(item => {
          return (
            <QuestionnaireResponseItem
              key={`item_${item.linkId}`}
              responseItem={item}
            />
          )
        })}
      </>
    )
  }

  // Else, display the item and its potential answers
  return responseItem != null ? (
    <ItemDiv>
      <Label>{responseItem.text}</Label>
      {responseItem.answer != null &&
        (responseItem.definition === MEDEO_QUESTIONNAIRE_TAG ? (
          <InlineList
            items={responseItem.answer}
            fallback={<Trans>No values added</Trans>}
          >
            {(value, i) => {
              const display = value?.valueString
              // title attribute is used in the case of the display being to big
              // and not fitting in the Component, when the user will hover
              // the component it will show the full name in a prompt.
              return (
                <InlineListItem title={display} key={i}>
                  {display}
                </InlineListItem>
              )
            }}
          </InlineList>
        ) : (
          <AnswerDiv>
            {/**  Here we map over the answers of the item */}
            {responseItem.answer.map((answer, i) => {
              // And here over the values, which look like
              // {valueString: 'response'}
              return Object.entries(answer).reduce(
                (prev, [valueType, value], j) => {
                  const curr = (
                    <Answer
                      key={`value_${i}_${j}`}
                      value={value}
                      valueType={valueType}
                    />
                  )
                  const separator = (
                    <span key={`value_${i}_${j}_span`}>,&nbsp;</span>
                  )

                  // We add a coma only starting from the second answer
                  // and if it is not a document
                  if (valueType === 'valueAttachment' || i === 0)
                    {return [prev, curr]}
                  else {return [prev, separator, curr]}
                },
                []
              )
            })}
          </AnswerDiv>
        ))}
    </ItemDiv>
  ) : null
}

export default QuestionnaireResponseItem
