import MedicationRequestDisplay from './MedicationRequestDisplay'
import styled from 'styled-components/macro'
import { MedicationRequest } from 'fhir-stu3'
import { FC } from 'react'

const List = styled.ul`
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
  flex-direction: column;
  width: 100%;
  text-align: left;
  & > li {
    flex: 1;
    padding: 0.5rem 0;
  }
`
interface MedicationRequestListProps {
  medicationRequests: MedicationRequest[] | null,
  onClickDelete: (key: number) => void,
  onClickUpdate: (key: number) => void,
}
const MedicationRequestList: FC<MedicationRequestListProps>= (props:MedicationRequestListProps ) => {
  const { medicationRequests, onClickDelete, onClickUpdate } = props
  if (!medicationRequests || medicationRequests.length < 1) {
    return <List><li>&mdash;</li></List>
  }
  return (
    <List>
      {medicationRequests.map((medicationRequest, i)  => (
          <li key={i}>
            <MedicationRequestDisplay
              medicationRequest={medicationRequest}
              onClickDelete={() => {
                onClickDelete(i)
              }}
              onClickUpdate={() => {
                onClickUpdate(i)
              }}
            /></li>
        ))
      }
    </List>
  )
}

export default MedicationRequestList
