import React, { useState, useContext } from 'react'
import { useEffectOnce } from 'react-use'
import styled from 'styled-components/macro'
import { ReactiveBase, DataSearch } from '@appbaseio/reactivesearch'
import { Trans } from '@lingui/macro'
import { Input, Form, Button } from '../Components'
import { ModelContext, mergeObject, ReadOnlyContext } from './utils'
import { displayPractitionerImg } from '../Shared/display/practitioner'
import { hoverMixin } from '../Shared/mixins'

const Name = styled.span`
  text-transform: uppercase;
`

const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  opacity: ${p => (p.isActive ? '0.4' : '0')};
  height: 100vh;
  ${p => (!p.isActive ? 'pointer-events: none;' : '')}
  background-color: grey;
  z-index: 5;
`

const FirstName = styled.span`
  text-transform: capitalize !important;
`

const Job = styled.div`
  color: ${p => p.theme.ocean};
  margin-top: 0.1rem;
`

const City = styled.div`
  text-transform: capitalize;
`

const Suggestion = styled.div`
  border-radius: 5px;
  margin-top: 1rem;
  padding: 0.5rem;
  width: 100%;
  position: absolute;
  background-color: white;
  z-index: 10;
`

const Right = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  flex-direction: column;
  text-align: right;
  text-transform: lowercase;
  color: ${p => p.theme.gray};
`
const Result = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: row;
  padding: 0.5rem;
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid transparent;
  margin-bottom: 1rem;
  ${hoverMixin};
  &:hover ${Job} {
    color: ${p => p.theme.ebony};
  }
`

const Left = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.5rem;
`

const CheckBox = styled(Input)`
  margin-top: 1rem;
  border: none;
`

const HeadImage = styled.img`
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 50%;
  margin-right: 1rem;
`

const Container = styled.div`
  z-index: 10;
`
const SuggestionResult = ({ onClick, suggestion }) => {
  // using regex to decompose 69008 LYON CEDEX 4 -> ['69008', 'LYON CEDEX 4']
  // first item in the list is the original string, so we discard it
  let [, zipCode, city] =
    suggestion.source.postalCode.match(/([0-9]*)(.*)/) ?? []
  // this will transform "Lyon CEDEX 4" ->"Lyon"
  // using the modifier i will make the regex case insensitive
  city = city.replace(/cedex.*?$/i, '').toLowerCase()

  return (
    <Result onClick={() => onClick(suggestion)}>
      <Left>
        <HeadImage src={displayPractitionerImg()} />
        <div>
          <FirstName>{suggestion.source.firstName.toLowerCase()} </FirstName>
          <Name>{suggestion.source.lastName} </Name>
          <Job>{suggestion.source.name_trade}</Job>
        </div>
      </Left>
      <Right>
        <div>{zipCode}</div>
        <City>{city}</City>
      </Right>
    </Result>
  )
}
/**
 * Build up the query we want to use to fetch the practitioners
 *
 * @param {string} query: string entered by the user
 */
function getPractitionerQuery(query) {
  return {
    size: 10,
    query: {
      bool: {
        must: [
          {
            multi_match: {
              query: query,
              type: 'most_fields',
              fields: ['lastName^3', 'firstName^3', '*']
            }
          },
          {
            match: {
              'name_trade.keyword': {
                query: 'Medecin'
              }
            }
          }
        ]
      }
    }
  }
}

const reduceByRpps = (array, item) => {
  if (!array.some(i => i.source.rpps === item.source.rpps)) {
    array.push(item)
  }
  return array
}

const YamlPractitionerSearch = () => {
  const [isBackground, setIsBackground] = useState(false)
  const readOnly = useContext(ReadOnlyContext)
  const [model, setModel] = useContext(ModelContext)
  const [generalPractitioner, setGeneralPractitioner] = useState(null)
  const [isChecked, setIsChecked] = useState(false)
  // this case is when we go back to this page or when we are in the recap
  useEffectOnce(() => {
    if (model.$generalPractitioner != null) {
      setGeneralPractitioner(model.$generalPractitioner)
      if (model.$generalPractitioner === 'no') {setIsChecked(true)}
    }
  })

  const handleClick = suggestion => {
    setGeneralPractitioner({
      ...suggestion.source,
      country: 'France'
    })
    setModel(
      mergeObject(model, {
        $generalPractitioner: {
          ...suggestion.source,
          country: 'France'
        }
      })
    )
    setIsChecked(false)
  }

  return (
    <>
      <Background isActive={isBackground} />
      {!readOnly && generalPractitioner == null ? (
        <Container>
          <ReactiveBase
            app="practitioner"
            url="https://search-medeo-elasticsearch-whxkhi7oxdmphqgi26dhwq72bi.eu-west-1.es.amazonaws.com/"
          >
            <DataSearch
              componentId="SearchPra"
              dataField={['firstName', 'lastName', 'postalCode']}
              placeholder="Rechercher un médecin..."
              queryFormat="or"
              onBlur={() => setIsBackground(false)}
              onKeyUp={e => {
                if (e.target.value !== '') {
                  setIsBackground(true)
                } else {
                  setIsBackground(false)
                }
              }}
              onFocus={e => {
                if (e.target.value !== '') {
                  setIsBackground(true)
                } else {
                  setIsBackground(false)
                }
              }}
              showDistinctSuggestions={true}
              defaultQuery={query => getPractitionerQuery(query)}
              render={({
                loading,
                error,
                data,
                value,
                downshiftProps: { isOpen }
              }) => {
                if (loading) {
                  return (
                    <Suggestion>
                      <Trans>Fetching Suggestions</Trans>...
                    </Suggestion>
                  )
                }
                if (error) {
                  return (
                    <Suggestion>
                      <Trans>Something went wrong!</Trans>
                    </Suggestion>
                  )
                }
                return isOpen && Boolean(value.length) ? (
                  <Suggestion>
                    {data.reduce(reduceByRpps, []).map((suggestion, index) => (
                      <SuggestionResult
                        suggestion={suggestion}
                        onClick={handleClick}
                        key={index}
                      />
                    ))}
                  </Suggestion>
                ) : null
              }}
            />
          </ReactiveBase>
        </Container>
      ) : generalPractitioner != null && generalPractitioner !== 'no' ? (
        <>
          <Form.Row>
            <Input
              type="text"
              label="Identifiant Rpps"
              name="gp.rpps"
              defaultValue={generalPractitioner.rpps}
              readOnly={true}
            />
          </Form.Row>
          <Form.Row>
            <Input
              type="text"
              label="Nom"
              name="gp.lastName"
              defaultValue={generalPractitioner.lastName}
              readOnly={true}
            />
            <Input
              type="text"
              label="Prénom"
              name="gp.firstName"
              defaultValue={generalPractitioner.firstName}
              readOnly={true}
            />
          </Form.Row>
          <Form.Row>
            <Input
              type="text"
              label="Mail MSanté"
              name="gp.email"
              defaultValue={generalPractitioner.email}
              readOnly={true}
            />
            <Input
              type="text"
              label="N° téléphone"
              name="gp.phone"
              defaultValue={generalPractitioner.phone}
              readOnly={true}
            />
          </Form.Row>
          <Form.Row>
            <Input
              type="text"
              label="Adresse"
              name="gp.line"
              defaultValue={generalPractitioner.address}
              readOnly={true}
            />
          </Form.Row>
          <Form.Row>
            <Input
              type="text"
              label="Code postal"
              name="gp.postalCode"
              defaultValue={generalPractitioner.postalCode}
              readOnly={true}
            />
            <Input
              type="text"
              label="Ville"
              name="gp.city"
              defaultValue={generalPractitioner.city}
              readOnly={true}
            />
          </Form.Row>
          <Form.Row>
            <Input
              type="text"
              label="Pays"
              name="gp.country"
              defaultValue={generalPractitioner.country}
              readOnly={true}
            />
          </Form.Row>

          {!readOnly && (
            <Button
              color="ocean"
              variant="outline"
              onClick={() => setGeneralPractitioner(null)}
            >
              <Trans>Change general practitioner</Trans>
            </Button>
          )}
        </>
      ) : null}
      {/* This component is here to prevent no display if the user has uncheck the checkbox
      for no general practitioner and doesn't indicate a generalPractitioner. This div will be
      in recap at the end of add patient form */}
      {readOnly && generalPractitioner === 'no' && (
        <div>Pas de médecin traitant</div>
      )}
      {!readOnly && (
        <CheckBox
          type="checkbox"
          id="noPractitioner"
          name="noPractitioner"
          label="Pas de médecin traitant"
          readOnly={readOnly}
          checked={isChecked}
          onChange={() => {
            if (generalPractitioner === 'no') {
              setGeneralPractitioner(null)
              setModel(
                mergeObject(model, {
                  $generalPractitioner: null
                })
              )
            } else {
              setGeneralPractitioner('no')
              setModel(
                mergeObject(model, {
                  $generalPractitioner: 'no'
                })
              )
            }
            setIsChecked(!isChecked)
          }}
        />
      )}
    </>
  )
}

export default YamlPractitionerSearch
