import { Redirect } from '@reach/router'
import React from 'react'

/**
 * This component should be set as the last children of a Router.
 * It will redirect the user to a safe and known url, by default '/patients'
 * @param to
 * @return {JSX.Element}
 * @constructor
 */
const NotFound = ({ to = '/patients' }) => <Redirect to={to} noThrow />
// we use noThrow to prevent reach/router to display an error ☝️
// see https://github.com/reach/router/issues/100
// or see https://reach.tech/router/api/Redirect

export default NotFound
