import React from 'react'
import InlineList from './InlineList'
import InlineListItem from './InlineListItem'
import { Trans } from '@lingui/macro'

const TagView = ({ resource, type }) => {
  return (
    <InlineList
      items={resource}
      fallback={
        <div style={{ color: '#77808e' }}>
          {type === 'allergy' ? (
            <Trans>No allergy found</Trans>
          ) : (
            <Trans>No history found</Trans>
          )}
        </div>
      }
    >
      {(m, i) => {
        const display = m?.code?.text
        // title attribute is used in the case of the display being to big
        // and not fitting in the Component, when the user will hover
        // the component it will show the full name in a prompt.
        return (
          <InlineListItem title={display} key={i}>
            {display}
          </InlineListItem>
        )
      }}
    </InlineList>
  )
}

export default TagView
