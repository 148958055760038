import styled from 'styled-components/macro'
import React from 'react'

const Wrapper = styled.div`
  width: ${p => (p.size === 'large' ? '5rem' : '3rem')};
  height: ${p => (p.size === 'large' ? '5rem' : '3rem')};
  border-radius: 50%;
  border: 0.125rem solid ${p => p.theme.white};
  box-shadow: ${p => p.theme.boxShadow};
  overflow: hidden;
  display: flex;
  justify-content: center;
  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const ProfilePicture = ({ size, src, alt, ...rest }) => {
  return (
    <Wrapper size={size}>
      <img src={src} alt={alt} {...rest} />
    </Wrapper>
  )
}
ProfilePicture.defaultProps = {
  size: 'large',
  alt: '',
}

export default ProfilePicture
