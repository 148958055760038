import { useDispatch } from 'react-redux';
import React, { useContext } from 'react';
import { CurrentPractitionerContext } from '../../Practitioner/usePractitioner';
import { CurrentSearchBarValueContext } from '../../Shared/components/CurrentSearchBarValueProvider';
import { signOut } from '../actions';
import { deletePatient } from '../../Patient/actions';
import { Trans } from '@lingui/macro';
import UrlControlledMenu from '../../Shared/containers/UrlControlledMenu';

const SignOutMenuListItem = () => {
  const dispatch = useDispatch();
  const [, setPractitioner] = useContext(CurrentPractitionerContext);
  const [, setCurrentSearchBarValue] = useContext(CurrentSearchBarValueContext);
  return (
    <UrlControlledMenu.ListItem
      onClick={() => {
        dispatch(signOut());
        setPractitioner(null);
        // if there was an active search we need to deleted when we signOut
        dispatch(deletePatient());
        setCurrentSearchBarValue('');
      }}
      data-intercom-target="sign-out"
      controlUrlParam={'settings'}
    >
      <Trans>Sign out</Trans>
    </UrlControlledMenu.ListItem>
  );
};

export default SignOutMenuListItem;
