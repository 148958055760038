import React from 'react'
import YamlSelect from './YamlSelect'

// shows france at the top of the list
const countries = [
  'France',
  'Afghanistan',
  'Afrique du Sud',
  'Albanie',
  'Algérie',
  'Allemagne',
  'Andorre',
  'Angola',
  'Antarctique',
  'Antigua-et-Barbuda',
  'Argentine',
  'Arménie',
  'Aruba',
  'Australie',
  'Autriche',
  'Azerbaïdjan',
  'Bahamas',
  'Bahreïn',
  'Bangladesh',
  'Barbade',
  'Belau',
  'Belgique',
  'Belize',
  'Bénin',
  'Bhoutan',
  'Biélorussie',
  'Birmanie',
  'Bolivie',
  'Bosnie-Herzégovine',
  'Botswana',
  'Brésil',
  'Brunei',
  'Bulgarie',
  'Burkina',
  'Burundi',
  'Cambodge',
  'Cameroun',
  'Canada',
  'Cap Vert',
  'Chili',
  'Chine',
  'Chypre',
  'Colombie',
  'Comores',
  'Congo',
  'Cook',
  'Corée du Nord',
  'Corée du Sud',
  'Costa Rica',
  "Cote d'Ivoire",
  'Croatie',
  'Cuba',
  'Danemark',
  'Djibouti',
  'Dominique',
  'Egypte',
  'Emirats arabes unis',
  'Equateur',
  'Erythree',
  'Espagne',
  'Estonie',
  'Etats Unis',
  'Ethiopie',
  'Fidji',
  'Finlande',
  'Gabon',
  'Gambie',
  'Georgie',
  'Ghana',
  'Grèce',
  'Grenade',
  'Guatemala',
  'Guinee',
  'Guinee Bissao',
  'Guinee equatoriale',
  'Guyana',
  'Haiti',
  'Honduras',
  'Hongrie',
  'Inde',
  'Indonesie',
  'Iran',
  'Iraq',
  'Irlande',
  'Islande',
  'Israël',
  'Italie',
  'Jamaique',
  'Japon',
  'Jordanie',
  'Kazakhstan',
  'Kenya',
  'Kirghizistan',
  'Kiribati',
  'Koweit',
  'Laos',
  'Lesotho',
  'Lettonie',
  'Liban',
  'Liberia',
  'Libye',
  'Liechtenstein',
  'Lituanie',
  'Luxembourg',
  'Macedoine',
  'Madagascar',
  'Malaisie',
  'Malawi',
  'Maldives',
  'Mali',
  'Malte',
  'Maroc',
  'Marshall',
  'Maurice',
  'Mauritanie',
  'Mexique',
  'Micronesie',
  'Moldavie',
  'Monaco',
  'Mongolie',
  'Mozambique',
  'Namibie',
  'Nauru',
  'Nepal',
  'Nicaragua',
  'Niger',
  'Nigeria',
  'Niue',
  'Norvège',
  'Nouvelle-Zélande',
  'Oman',
  'Ouganda',
  'Ouzbekistan',
  'Pakistan',
  'Panama',
  'Papouasie Nouvelle Guinée',
  'Paraguay',
  'Pays Bas',
  'Perou',
  'Philippines',
  'Pologne',
  'Portugal',
  'Qatar',
  'Republique centrafricaine',
  'Republique dominicaine',
  'Republique tcheque',
  'Roumanie',
  'Royaume Uni',
  'Russie',
  'Rwanda',
  'Saint-Christophe-et-Niévès',
  'Sainte Lucie',
  'Saint Marin',
  'Saint-Vincent-et-les Grenadines',
  'Salomon',
  'Salvador',
  'Samoa occidentales',
  'Sao Tome et Principe',
  'Senegal',
  'Seychelles',
  'Sierra Leone',
  'Singapour',
  'Slovaquie',
  'Slovenie',
  'Somalie',
  'Soudan',
  'Sri Lanka',
  'Suede',
  'Suisse',
  'Suriname',
  'Swaziland',
  'Syrie',
  'Tadjikistan',
  'Tanzanie',
  'Tchad',
  'Thailande',
  'Togo',
  'Tonga',
  'Trinite et Tobago',
  'Tunisie',
  'Turkmenistan',
  'Turquie',
  'Tuvalu',
  'Ukraine',
  'Uruguay',
  'Vanuatu',
  'Vatican',
  'Venezuela',
  'Vietnam',
  'Yemen',
  'Yougoslavie',
  'Zaire',
  'Zambie',
  'Zimbabwe',
]

const YamlCountrySelect = ({ yaml, ...rest }) => (
  <YamlSelect
    yaml={{
      ...yaml,
      options: [
        // this display a placeholder in the BetterSelect
        ...countries.map(c => ({ children: c, value: c })),
      ],
    }}
    {...rest}
  />
)

export default YamlCountrySelect
