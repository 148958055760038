/** @module Shared */
import { takeEvery } from '@redux-saga/core/effects';
import { Auth } from 'aws-amplify';
import buildFhir from 'lifen-fhir.js/src/adapters/native';
import { all, call, put, select } from 'redux-saga/effects';
import { getCurrentEncounter } from '../../Encounter/selectors';
import { getTypeAndIdFromLocalReference } from '../../utils/fhir';
import { FHIR_BASE_URL } from '../../_common/config';
import {
    failReceivingFhirResource,
    fhirResourceRemoved, READING_FHIR_RESOURCE, receiveFhirResource, receiveFhirResources, receiveTotalFhirResources,
    SAVING_FHIR_RESOURCE,
    SEARCHING_FHIR_RESOURCES
} from '../actions';

// this file should not be forked from rootSaga, it contains helper functions for fhir resources

/**
 * create a FhirClient dynamically, it calls Amplify Auth library
 * to get the latest accessToken.
 *
 * This method should be called every time an api call is made to the
 * database.
 *
 * Auth library makes sure to refresh the token if required.
 * @see https://github.com/aws-amplify/amplify-js/wiki/FAQ
 * @returns {Promise<fhirClient.FhirClient>}
 */
export const createFhirClient = async () => {
  // accessToken should not be fetched from the redux state,
  // as it is not updated once expired.
  // this mechanism is provided by Amplify and the Auth library
  if (process.env.NODE_ENV === 'test') {
    return buildFhir({
      baseUrl: FHIR_BASE_URL.toString(),
      credentials: 'same-origin',
      auth: {
        bearer: 'aaa.bbb.ccc'
      }
    });
  }
  const session = await Auth.currentSession();
  const token = session.accessToken.getJwtToken();
  // accessToken looks like
  // {
  //   jwt: "aaa.bbb.ccc",
  //   payload: { /*...*/ },
  // }
  // fhirClient actually requires the jwt token

  // return prod or dev base url
  return buildFhir({
    baseUrl: FHIR_BASE_URL.toString(),
    credentials: 'same-origin',
    auth: {
      bearer: token
    }
  });
};
// this worker is intended to be used with every fhir resources
// type refers to the resourceType: i.e. Observation, Encounter, etc...
// createQueryFromPayload: is an optional function that is used to extract payload and create a query with it
// action will be passed when using takeLatest for instance
export function* getResourceFromParams(resourceOrResourceFn, action) {
  return typeof resourceOrResourceFn === 'function'
    ? yield resourceOrResourceFn(action)
    : resourceOrResourceFn;
}

export function* searchFhirResourceWorker(type, queryOrQueryFn, action) {
  try {
    const query = yield getResourceFromParams(queryOrQueryFn, action);
    const fhirClient = yield createFhirClient();
    const response = yield call(fhirClient.search, {
      type,
      ...(queryOrQueryFn != null && {
        query
      })
    });

    return yield put(receiveFhirResources(response.data));
  } catch (o_O) {
    console.error(o_O);
    return yield put(failReceivingFhirResource(type, o_O));
  }
}

export function* nextPageFhirResourceWorker(type, link) {
  try {
    const fhirClient = yield createFhirClient();
    const response = yield call(fhirClient.nextPage, {
      type: type,
      bundle: {
        link
      }
    });

    return yield put(receiveFhirResources(response.data));
  } catch (err) {
    console.log(err);
  }
}

export function* fetchMostRecentEncounter(subjectId) {
  try {
    const fhirClient = yield createFhirClient();
    const { data } = yield call(fhirClient.search, {
      type: 'Encounter',
      query: {
        subject: subjectId,
        _sort: '-date'
      }
    });

    return data.total > 0 ? data.entry[0].resource : null;
  } catch (err) {
    console.error(err);
    throw err;
  }
}

export function* searchTotalFhirResourceWorker(type, queryOrQueryFn, action) {
  try {
    const query = yield getResourceFromParams(queryOrQueryFn, action);
    const fhirClient = yield createFhirClient();
    const { data } = yield call(fhirClient.search, {
      type,
      ...(queryOrQueryFn != null && {
        query
      })
    });
    return yield put(receiveTotalFhirResources(type, data));
  } catch (o_O) {
    console.error(o_O);
    return yield put(failReceivingFhirResource(type, o_O));
  }
}

/**
 *
 * @param type the FHIR resourceType of the element to create
 * @param resourceOrResourceFn the method used to extract the resource from the action, it can be a generator function
 * @param action the action triggering the call
 * @param customClient a custom FHIR client to be used, optional
 * @returns {IterableIterator<CallEffect | *|PutEffect<{payload, type}> | *|AllEffect | GenericAllEffect<any> | *|*>}
 */
export function* saveFhirResourceWorker(
  resourceOrResourceFn,
  action,
  customClient
) {
  const resource = yield getResourceFromParams(resourceOrResourceFn, action);

  try {
    // Bundles are not supported by the FHIR server thus we need to hack our way to
    // POST multiple resource at the same time.
    // here we check whether the resource is an array or not.
    // If this is the case we simply call again this method but to every item in the array.
    if (Array.isArray(resource)) {
      return yield all(resource.map(r => call(saveFhirResourceWorker, r)));
    } else {
      const fhirClient = yield customClient || createFhirClient();
      if (
        resource.resourceType === 'Bundle' &&
        resource.type === 'transaction'
      ) {
        let method = fhirClient.transaction;
        const { data: body } = yield method({ resource });
        // With a Transaction, response body will not contained resources
        // it will simply display the location where the resource is stored
        // to be compatible with our redux store and actions
        // we merge the entries from the request and from the response
        // we add the id contained in the location of the response to the resources
        const entry = body.entry?.map((e, i) => {
          const [, id] = getTypeAndIdFromLocalReference(e.response?.location);
          return {
            ...e,
            resource: {
              ...resource.entry[i].resource,
              id
            }
          };
        });
        const mergedBody = { ...body, entry };
        yield put(receiveFhirResources(mergedBody));
        return mergedBody;
      } else {
        // check whether it should create or update the resource
        const method =
          resource.id == null ? fhirClient.create : fhirClient.update;
        // this is the normal case, we got a simple resource to POST/PUT,
        // The resource is posted/put then a receiveFhirResource is dispatched.
        const { data } = yield call(method, {
          resource
        });
        yield put(receiveFhirResource(resource.resourceType, data));
        return data;
      }
    }
  } catch (O_o) {
    console.log(O_o);
    // relay the error through the action bus.
    return yield put(failReceivingFhirResource(resource.resourceType, O_o));
  }
}

export function* removeFhirResourceWorker(resourceOrResourceFn, action) {
  // check whether it should create or update the resource
  const resource = yield getResourceFromParams(resourceOrResourceFn, action);
  const fhirClient = yield createFhirClient();
  try {
    yield call(fhirClient.delete, {
      resource
    });
    return yield put(fhirResourceRemoved(resource));
  } catch (O_o) {
    // relay the error through the action bus.
    console.error(O_o);
    return yield put(failReceivingFhirResource(resource.resourceType, O_o));
  }
}

// used in observations sagas for instance...
export function* appendContextToFhirResource(fhirResource) {
  const { id: encounterID } = yield select(getCurrentEncounter);
  if (encounterID == null) {return fhirResource;}
  return {
    ...fhirResource,
    context: {
      reference: 'Encounter/' + encounterID
    }
  };
}

// It is hard to differentiate medicalHistory from currentCondition
// One way to do it is to set the clinicalStatus to 'active' for currentCondition
// for medicalHistory we do not set the clinicalStatus.
export const createFhirRessourceFromTag = (
  ressourceType,
  tag,
  patientID,
  isActive,
  systemUrl
) => {
  return {
    ...(tag.id != null && { id: tag.id }),
    resourceType: ressourceType,
    ...(ressourceType === 'Condition' && {
      category: [
        {
          coding: [
            {
              system:
                'http://terminology.hl7.org/CodeSystem/condition-category',
              code: 'problem-list-item',
              display: 'Problem List Item'
            }
          ]
        }
      ]
    }),
    ...(isActive === true && {
      clinicalStatus: 'active'
    }),
    code: {
      coding: [
        {
          system: systemUrl,
          code: tag.value
        }
      ],
      text: tag.value
    },
    ...(ressourceType === 'Condition' && {
      subject: { reference: 'Patient/' + patientID }
    }),
    ...(ressourceType === 'AllergyIntolerance' && {
      patient: { reference: 'Patient/' + patientID }
    })
  };
};

/**
 * This function decomposes search action and call searchFhirResourceWorker
 * with the params in the correct order
 * @param {SearchAction} action
 * @returns {*}
 */
function* adaptToSearchFhirResourceWorker(action) {
  const { payload } = action;
  yield call(searchFhirResourceWorker, payload.type, payload.params);
}

/**
 *
 * @param {ReadAction} action
 * @return {Generator<*, void, *>}
 */
function* readFhirResourceWorker(action) {
  const { payload } = action;
  const fhirClient = yield createFhirClient();
  const response = yield call(fhirClient.read, payload);
  return yield put(receiveFhirResource(payload.type, response.data));
}

function* rootSaga() {
  yield takeEvery(SAVING_FHIR_RESOURCE, saveFhirResourceWorker, p => p.payload);
  yield takeEvery(SEARCHING_FHIR_RESOURCES, adaptToSearchFhirResourceWorker);
  yield takeEvery(READING_FHIR_RESOURCE, readFhirResourceWorker);
}
export default rootSaga;
