import * as graphlibCore from '@dagrejs/graphlib'

export const SAVING_MODEL = 'SAVING_MODEL'

export const getSavedActionName = resourceType =>
  `${resourceType.toUpperCase()}_SAVED`

export const saveModel = (graph, model, yaml, refs, rootNode = '$root') => {
  return {
    type: SAVING_MODEL,
    payload: {
      graph: graphlibCore.json.write(graph),

      rootNode,
      form: model,
      fhir: yaml.fhir,
      refs,
    },
  }
}
