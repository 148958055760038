import React from 'react';
import styled from 'styled-components/macro';

const Container = styled.div`
  margin-top: ${props => props.theme.spacing.medium};
  display: ${p => (p.hidden ? 'none' : 'block')};
`;

const Label = styled.div`
  color: ${props => props.theme.nevada};
  margin-right: ${props => props.theme.spacing.medium};
  margin-bottom: ${p => p.theme.spacing.small};
`;

const ExtraRow = ({ text, children, hidden }) => (
  <Container hidden={hidden}>
    <Label>{text}</Label>
    {children}
  </Container>
);

export default ExtraRow;
