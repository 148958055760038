import { API } from 'aws-amplify'
import {
  connect as connectToRoom,
  createLocalVideoTrack,
  createLocalAudioTrack,
} from 'twilio-video'
import {
  attachVideoToDiv,
  initiateRoomListeners,
} from '../Teleconsultation/utils'

/**
 * Calculate the rms for a given samples array
 *
 * @param {Array} samples
 * @returns Number
 */
const rootMeanSquare = samples => {
  const sumSquare = samples.reduce(
    (sumSquare, sample) => sumSquare + sample * sample,
    0
  )
  return Math.sqrt(sumSquare / samples.length)
}

/**
 * Get audio level on a given audio track and AudioContext
 *
 * @param {Object} audioContext - AudioContext of the current page
 * @param {Object} audioTrack - the track on which the sound is transmitted
 * @param {Function} updateLevel - callback to trigger once the sound level was computed
 */
export const getAudioTrackLevel = async (
  { audioContext, audioTrack },
  updateLevel
) => {
  // Create a AnalyserNode to be able to expose audio time and frequency data
  const analyser = audioContext.createAnalyser()
  analyser.fftSize = 1024

  // Create a stream from the local audio track
  const stream = new MediaStream([audioTrack.mediaStreamTrack])
  // Create a MediaStreamAudioSourceNode out of it
  const source = audioContext.createMediaStreamSource(stream)
  source.connect(analyser)

  const bufferLength = analyser.frequencyBinCount
  const dataArray = new Uint8Array(bufferLength)
  analyser.getByteTimeDomainData(dataArray)

  let level = null

  // Periodically calculate the audio level from the captured samples,
  // and if changed, call the callback with the new audio level.
  requestAnimationFrame(function checkLevel() {
    analyser.getByteFrequencyData(dataArray)

    // Refer to https://www.twilio.com/docs/video/build-js-video-application-recommendations-and-best-practices
    // for this part
    const rms = rootMeanSquare(dataArray)

    const log2Rms = rms && Math.log2(rms)

    // Audio level ranges from 0 (silence) to 10 (loudest).
    const newLevel = Math.ceil((10 * log2Rms) / 8)
    if (level !== newLevel) {
      level = newLevel
      updateLevel(newLevel)
    }

    // Continue calculating the level only if the audio track is live.
    if (audioTrack.mediaStreamTrack.readyState === 'live') {
      requestAnimationFrame(checkLevel)
    } else {
      updateLevel(0)
    }
  })
}

const request = async (identity, orgId) => {
  const apiName = 'twilio'
  const path = '/test'

  const params = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: { identity: identity, orgId: orgId },
  }

  const response = await API.post(apiName, path, params)

  return response
}

class ErrorMessage {
  constructor({ error, type }) {
    this.error = error
    this.type = type
  }
}

/**
 * Start video and twilio test connection for this organization id
 *
 * @param {Number} orgId
 */
export const startVideo = async (identity, orgId, remoteMediaContainer) => {
  const response = await request(identity, orgId)

  const { token } = response

  if (token == null) {
    alert('There are already 2 people in this room.')
    throw new ErrorMessage({
      type: 'Connection',
      error: 'The room is already busy.',
    })
  }

  const previewVideoTrack = await createLocalVideoTrack({})

  const room = await connectToRoom(token, {
    name: orgId,
    tracks: [previewVideoTrack],
  }).catch(() => {
    throw new ErrorMessage({
      type: 'Video publication',
      error: 'The video track could not be published',
    })
  })

  const audioTrack = await createLocalAudioTrack({
    // We add those parameters here to prevent to browser from
    // auto adjusting the input sound, which would spoil the use
    // of the stethoscope (RNK), even if the first audio track should
    // not be the stethoscope.
    autoGainControl: false,
    noiseSuppression: false,
    echoCancellation: false,
  })

  // publish audio track
  await room?.localParticipant.publishTrack(audioTrack).catch(() => {
    throw new ErrorMessage({
      type: 'Audio publication',
      error: 'The audio track could not be published',
    })
  })

  initiateRoomListeners({
    room: room,
    trackPublicationCallback: track => {
      attachVideoToDiv(remoteMediaContainer, track)
    },
  })

  return { audioTrack, previewVideoTrack, room }
}

export const disconnect = (videoTrack, room) => {
  room?.localParticipant?.tracks.forEach(publication => {
    const attachedElements = publication.track.detach()
    attachedElements.forEach(element => element.remove())
    publication.track.stop()
  })
  room?.disconnect()
  videoTrack?.stop()
  const attachedElementsBis = videoTrack?.detach()
  attachedElementsBis?.forEach(element => element.remove())
}
