// If it's OK
export const OBSERVATIONS_RECEIVED = 'OBSERVATIONS_RECEIVED'
// If it's OK
export const OBSERVATION_RECEIVED = 'OBSERVATION_RECEIVED'
export const FAIL_RECEIVING_OBSERVATIONS = 'FAIL_RECEIVING_OBSERVATIONS'
// If it's Error
export const API_ERRORED = 'API_ERRORED'
// Add Observation
export const ADDING_OBSERVATION = 'ADDING_OBSERVATION'
export const ADDING_TELE_EXPERTISE_OBSERVATION =
  'ADDING_TELE_EXPERTISE_OBSERVATION'

//
export const OBSERVATION_REMOVED = 'OBSERVATION_REMOVED'
// Edit Observation
export const UPDATING_OBSERVATION = 'UPDATING_OBSERVATION'
export const UPDATING_ALL_OBSERVATIONS = 'UPDATING_ALL_OBSERVATIONS'
// If it's Error during validation
export const FETCHING_OBSERVATIONS = 'FETCHING_OBSERVATIONS'

// Get specific Observations
export const FETCHING_SPECIFIC_OBSERVATIONS = 'FETCHING_SPECIFIC_OBSERVATIONS'
export const SPECIFIC_OBSERVATIONS_SEARCH_SUCCEEDED =
  'SPECIFIC_OBSERVATIONS_SEARCH_SUCCEEDED'

// Action Creator

export const searchSpecificObservation = payload => {
  return {
    type: FETCHING_SPECIFIC_OBSERVATIONS,
    payload: {
      specificCode: /*'http://chainedelespoir.org/|' +*/ payload.specificCode,
      //'http://medeo.io/medical-Observation|' + payload.specificCode,
      patientID: payload.patientID,
    },
  }
}

export const saveObservation = data => ({
  type: ADDING_OBSERVATION,
  payload: data,
})

export const updateObservation = observation => ({
  type: UPDATING_OBSERVATION,
  payload: observation,
})

export const updateAllObservations = (
  observationType,
  encounterID,
  status
) => ({
  type: UPDATING_ALL_OBSERVATIONS,
  payload: { observationType, encounterID, status },
})

export const loadObservationsFromContext = (encounterID, patientID) => ({
  type: FETCHING_OBSERVATIONS,
  payload: {
    encounterID,
    patientID,
  },
})
