import { useContext } from 'react';
import { CurrentPractitionerRoleContext } from '../PractitionerRole/containers/CurrentPractitionerRoleProvider';
import {
  getSpecialtyCodingFromSystem,
  PHARMACIST_ESANTE,
  GENERAL_PRACTITIONER_ESANTE,
  NURSE_ESANTE,
  OPTICIAN_ESANTE,
  AUDIOPROSTHESIST_ESANTE,
  DENTIST_ESANTE,
  MIDWIFE_ESANTE,
  DENTAL_ASSISTANT_ESANTE,
  MEDICAL_PHYSICIST_ESANTE,
  NURSING_ASSISTANT_ESANTE,
  PHARMACEUTICAL_ASSISTANT_HOSPITAL_ESANTE,
  SOCIAL_CARE_ASSISTANT_ESANTE,
  PERSONAL_CARER_ASSISTANT_ESANTE,
  SOCIAL_INTERVENTION_ASSISTANT_ESANTE,
  PSYCHOLOGICAL_ASSISTANT_ESANTE,
  PSYCHIATRIST_NURSE_ESANTE,
  PHYSIOTHERAPIST_ESANTE,
  OSTEAOPATH_ESANTE,
  PSYCHOTHERAPIST_ESANTE,
  CHIROPRACTOR_ESANTE,
  ORTHOPROSTHESIST_ESANTE,
  PHARMACEUTICAL_ASSISTANT_DISPENSARY_ESANTE,
  OCULARIST_ESANTE,
  ORTHOPEDIST_ESANTE,
  PEDORTHIST_ESANTE,
  EPITHESIST_ESANTE,
  MEDICAL_LAB_ASSISTANT_ESANTE,
  ORTHOPTIST_ESANTE,
  PSYCHOLOGIST_ESANTE,
  OCCUPATIONAL_THERAPIST_ESANTE,
  DIETITIAN_ESANTE,
  PSYCHOMOTOR_THERAPIST_ESANTE,
  SPEECH_THERAPIST_ESANTE,
  GENETIC_ADVISOR_ESANTE,
  RADIO_OPERATOR,
  DOMESTIC_HELPER_ESANTE,
  SECRETARY_ESANTE,
  MEDICAL_ASSISTANT_ESANTE,
  AMBULANCE_DRIVER_ESANTE
} from './roles';
import { MEDEO_ENCOUNTER_TYPE_CLINICAL_EXAM } from '../utils/encounter-type';
import { ESANTE_OCCUPATION_SYSTEM } from '../utils/codes';

export const getCurrentPractitionerRoleGouvCode = (roles, fallback = null) => {
  // Take the first role (even if the practitioner has multiple)
  const activeRole = roles && roles.length ? roles[0] : null;
  const eSanteRole = getSpecialtyCodingFromSystem(
    activeRole,
    ESANTE_OCCUPATION_SYSTEM
  );
  if (activeRole != null && eSanteRole != null) {
    return eSanteRole.code;
  }
  return fallback;
};

// we choose to not give a particular name to roles, since they will be used by various type of practitioner
// you can see what's the difference between them at https://www.notion.so/medeo/Roles-sur-Medeo-75b7e4c5ee834077b8d9ab09251c9668
const firstRole = [
  // CTA permissions:
  `patient:add`,
  `encounter:start`,
  `waitingRoomList:read`,

  // Encounter types permissions:
  `${MEDEO_ENCOUNTER_TYPE_CLINICAL_EXAM}:read`,
  `pre_teleconsultation:read`,
  `pre_teleconsultation:start`,
  `teleconsultationPatientRoom:go`,
  `joinTeleconsultationForDistant:go`,
  `location:read`,
  `teleconsultation_consultation:read`,
  `booking-appointment:read`,
  `booking-appointment:start`,
  `add_patient:read`,
  // routes permissions
  `teleconsultation:read`,
  `billing:read`,

  //teleconsultation permissions
  `diagnosis:see`,
  `synthesis:see`,

  // schedule permission
  'schedule:see',
  'appointment:take'
];

const secondRole = [
  // CTA permissions:
  `encounter:start`,
  `patient:add`,

  // Encounter types permissions:
  `${MEDEO_ENCOUNTER_TYPE_CLINICAL_EXAM}:read`,
  `teleconsultationPatientRoom:go`,
  `preconsultationForm:go`,
  `cancelAppointmentCalendar:see`,
  `pre_teleconsultation_1:read`,
  `teleconsultation_consultation:read`,
  `pre_teleconsultation:read`,
  `booking-appointment:read`,
  `pre_teleconsultation:start`,
  `booking-appointment:start`,
  `add_patient:read`,
  'availability:see',
  'booking:see',
  `doctor:read`,
  'appointment:take'
];

const thirdRole = [
  // CTA permissions:
  `encounter:start`,
  `patient:add`,

  // Specific data permissions:

  // Encounter types permissions:
  `${MEDEO_ENCOUNTER_TYPE_CLINICAL_EXAM}:read`,
  `teleconsultationPatientRoom:go`,
  `preconsultationForm:go`,
  `cancelAppointmentCalendar:see`,
  `teleconsultation_consultation:read`,
  `pre_teleconsultation:read`,
  `booking-appointment:read`,
  `pre_teleconsultation:start`,
  `booking-appointment:start`,
  `add_patient:read`,

  'availability:see',
  'booking:see',
  `doctor:read`,
  'appointment:take'
];

const fourthRole = [
  // CTA permissions:
  `patient:export`,
  `encounter:start`,
  `patient:add`,

  // Specific data permissions:

  // Encounter types permissions:
  `${MEDEO_ENCOUNTER_TYPE_CLINICAL_EXAM}:read`,
  `teleconsultationPatientRoom:go`,
  `preconsultationForm:go`,
  `cancelAppointmentCalendar:see`,
  `teleconsultation_consultation:read`,
  `pre_teleconsultation:read`,
  `booking-appointment:read`,
  `pre_teleconsultation:start`,
  `booking-appointment:read`,
  `add_patient:read`,

  'availability:see',
  'booking:see',
  `doctor:read`
];

const fifthRole = [
  // CTA permissions:
  `patient:export`,
  `patient:add`,
  `waitingRoomList:read`,

  // Encounter types permissions:
  `${MEDEO_ENCOUNTER_TYPE_CLINICAL_EXAM}:read`,
  `teleconsultationPatientRoom:go`,
  `preconsultationForm:go`,
  `cancelAppointmentCalendar:see`,
  `teleconsultation_consultation:read`,

  `pre_teleconsultation:read`,
  `booking-appointment:read`,
  `add_patient:read`,

  // routes permissions
  `teleconsultation:read`,
  `billing:read`,

  //teleconsultation permissions
  `diagnosis:see`,
  `synthesis:see`,

  // schedule permission
  'schedule:see'
];

const noRolePermissions = [
  // CTA permissions:
  `patient:add`,
  `${MEDEO_ENCOUNTER_TYPE_CLINICAL_EXAM}:read`
  // Specific data permissions:
];

export const can = (roleName, action, resource) => {
  const permission = getPermissionFromRoleName(roleName);
  return permission.includes(resource + `:` + action);
};

const getPermissionFromRoleName = roleName => {
  switch (roleName) {
    case GENERAL_PRACTITIONER_ESANTE:
    case DENTIST_ESANTE:
    case MIDWIFE_ESANTE:
    case MEDICAL_ASSISTANT_ESANTE:
      return firstRole;
    case PHARMACIST_ESANTE:
      return secondRole;
    case AUDIOPROSTHESIST_ESANTE:
    case OPTICIAN_ESANTE:
    case DENTAL_ASSISTANT_ESANTE:
    case MEDICAL_PHYSICIST_ESANTE:
    case NURSING_ASSISTANT_ESANTE:
    case AMBULANCE_DRIVER_ESANTE:
    case PHARMACEUTICAL_ASSISTANT_DISPENSARY_ESANTE:
    case PHARMACEUTICAL_ASSISTANT_HOSPITAL_ESANTE:
    case NURSE_ESANTE:
    case PSYCHIATRIST_NURSE_ESANTE:
    case ORTHOPTIST_ESANTE:
      return thirdRole;
    case SOCIAL_CARE_ASSISTANT_ESANTE:
    case PERSONAL_CARER_ASSISTANT_ESANTE:
    case SOCIAL_INTERVENTION_ASSISTANT_ESANTE:
    case PSYCHOLOGICAL_ASSISTANT_ESANTE:
    case PHYSIOTHERAPIST_ESANTE:
    case OSTEAOPATH_ESANTE:
    case PSYCHOTHERAPIST_ESANTE:
    case CHIROPRACTOR_ESANTE:
    case ORTHOPROSTHESIST_ESANTE:
    case PEDORTHIST_ESANTE:
    case ORTHOPEDIST_ESANTE:
    case OCULARIST_ESANTE:
    case EPITHESIST_ESANTE:
    case MEDICAL_LAB_ASSISTANT_ESANTE:
    case SPEECH_THERAPIST_ESANTE:
    case PSYCHOLOGIST_ESANTE:
    case OCCUPATIONAL_THERAPIST_ESANTE:
    case DIETITIAN_ESANTE:
    case PSYCHOMOTOR_THERAPIST_ESANTE:
    case GENETIC_ADVISOR_ESANTE:
    case RADIO_OPERATOR:
    case DOMESTIC_HELPER_ESANTE:
      return fourthRole;
    case SECRETARY_ESANTE:
      return fifthRole;
    default:
      return noRolePermissions;
  }
};

export const usePermission = (action, resource) => {
  const [currentPractitionerRole] = useContext(CurrentPractitionerRoleContext);
  return can(currentPractitionerRole, action, resource);
};

export const specialtyFirstRole = [
  GENERAL_PRACTITIONER_ESANTE,
  DENTIST_ESANTE,
  MIDWIFE_ESANTE,
  MEDICAL_ASSISTANT_ESANTE
];
export const specialtySecondRole = [PHARMACIST_ESANTE];
export const specialtyThirdRole = [
  AUDIOPROSTHESIST_ESANTE,
  OPTICIAN_ESANTE,
  MEDICAL_PHYSICIST_ESANTE,
  NURSING_ASSISTANT_ESANTE,
  AMBULANCE_DRIVER_ESANTE,
  PHARMACEUTICAL_ASSISTANT_DISPENSARY_ESANTE,
  PHARMACEUTICAL_ASSISTANT_HOSPITAL_ESANTE,
  NURSE_ESANTE,
  PSYCHIATRIST_NURSE_ESANTE,
  ORTHOPTIST_ESANTE
];

/**
 * Check wether the practitioner can start a remote consultation or not
 *
 * @param {string} practitionerRole
 */
export const isPractitionerAllowedToStartRemoteConsultation = practitionerRole => {
  return (
    specialtyFirstRole.some(spe => spe === practitionerRole) ||
    specialtySecondRole.some(spe => spe === practitionerRole) ||
    specialtyThirdRole.some(spe => spe === practitionerRole)
  );
};
