import { useDispatch, useSelector } from 'react-redux'
import { getCurrentEncounter } from './selectors'
import { Encounter } from 'fhir-stu3'
import { useCallback } from 'react'
import { deselectEncounter, updateEncounter } from './actions'

const useEncounter = () => {
  const encounter = useSelector(getCurrentEncounter) as Encounter | null
  const dispatch = useDispatch()
  const deselect = useCallback(() => {
    dispatch(deselectEncounter(encounter?.id))
  }, [dispatch, encounter])

  const cancel = useCallback(() => {
    dispatch(
      updateEncounter({
        ...encounter,
        status: 'entered-in-error'
      })
    )
  }, [dispatch, encounter])
  return { encounter, deselect, cancel }
}

export default useEncounter
