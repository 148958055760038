import moment from 'moment'

const parseRelative = input => {
  const now = moment()
  const parsed = /^(\+|-)(\d*?) (\w*)$/gi.exec(input)

  if (!input) {
    return null
  }

  const operator = parsed[1]
  const value = parseInt(parsed[2], 10)
  const period = parsed[3]

  if (operator === '-') {
    return now.subtract(value, period)
  } else if (operator === '+') {
    return now.add(value, period)
  }

  return null
}

export const parseYAMLDate = input => {
  if (!input) {
    return null
  }

  let date

  if (typeof input === 'object') {
    if (input.relative) {
      date = parseRelative(input.relative)
    }
  } else if (typeof input === 'string') {
    if (input === 'now') {
      date = moment()
    } else {
      date = moment(input, 'YYYY-MM-DD')
    }
  }

  if (!date || !date.isValid()) {
    throw new Error(
      `Invalid date configuration ${JSON.stringify(input, null, 2)}`
    )
  }

  return date.format('YYYY-MM-DD')
}
