import styled from 'styled-components/macro'
import React, { useState, useEffect } from 'react'
import { Trans } from '@lingui/macro'
import { Button } from '../../Components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

const Backdrop = styled.div`
  z-index: 10;
  position: fixed;
  top: 0;
  // the two following height are necessary
  // on iPad 100vh is not consistent...
  // see https://stackoverflow.com/questions/37112218/css3-100vh-not-constant-in-mobile-browser
  height: 100%;
  margin: 0 !important;
  left: 0;
  right: 0;
  background: #00000088;
  animation: backdrop 300ms ease-out;
  @keyframes backdrop {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`

const Dialog = styled.div`
  animation: dialog 300ms ease-out;
  @keyframes dialog {
    from {
      opacity: 0;
      transform: translateY(-55%);
    }
    to {
      opacity: 1;
      transform: translateY(-50%);
    }
  }
  background: white;
  border: none;
  display: flex;
  flex-direction: column;
  max-height: calc(100% - 4rem);
  @media (max-width: 1280px) {
    max-width: 39rem;
  }
  @media (min-width: 1280px) {
    max-width: 44.5rem;
  }
  @media (min-width: 1920px) {
    max-width: 55rem;
  }
  border-radius: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
  position: relative;
  padding: 1rem 0;
  box-shadow: ${p => p.theme.boxShadow};
  margin: auto;
`
const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 2rem;
  & > h1 {
    font-weight: normal;
    font-size: 28px;
    margin: 0;
  }
`

const Main = styled.main`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`
const Separator = styled.div`
  margin: 1rem 2rem 0 2rem;
  border-bottom: 1px solid ${p => p.theme.nevada};
`

/**
 * Modal component that will show a modal window
 * @param {string} title - the title in the header
 * @param {boolean} open - programmatically open/close the modal
 * @param {function} onChange - call every time the modal is open/closed
 * @param {JSX.Element} children - render prop that will return a close function
 * @param {JSX.Element} Component - to component that will be open the modal using onClick
 * @return {JSX.Element}
 * @constructor
 */
const Modal = ({ title, open, onChange, children, component: Component }) => {
  const [state, setState] = useState(false)
  useEffect(() => {
    setState(open)
  }, [open])

  useEffect(() => {
    onChange(state)
  }, [state, onChange])

  const close = () => setState(false)
  return (
    <>
      <Component onClick={() => setState(true)} />
      {state === true && (
        <Backdrop>
          <Dialog>
            <Header>
              <h2>{title}</h2>
              <Button
                variant="text"
                color="white"
                onClick={close}
                data-test={'quit'}
              >
                <FontAwesomeIcon size="2x" icon={faTimes} />
              </Button>
            </Header>
            <Separator />
            <Main>
              {/* Modal accepts function as child prop */}
              {/* see https://medium.com/merrickchristensen/function-as-child-components-5f3920a9ace9 */}
              {typeof children === 'function' ? children(close) : children}
            </Main>
          </Dialog>
        </Backdrop>
      )}
    </>
  )
}

Modal.defaultProps = {
  title: 'Modal',
  onChange: () => {},
  open: false,
  component: props => (
    <Button {...props}>
      <Trans>open</Trans>
    </Button>
  )
}
export default React.memo(Modal)
