import React from 'react'
import { getDisplay } from '../utils'

const QuestionnaireDisplay = ({ item }) => {
  const variant = item.extension?.[0]?.valueCodeableConcept?.coding?.[0]?.code
  let style

  switch (variant) {
    case 'help':
      style = {
        color: 'grey',
        fontSize: `${p => p.theme.small}`,
        paddingTop: '0.5rem',
        marginBottom: '1rem',
      }
      break
    default:
      style = {
        fontWeight: 'bold',
        fontSize: `${p => p.theme.medium}`,
        paddingTop: '0.5rem',
        marginBottom: '1rem',
      }
  }

  return <div style={style}>{getDisplay(item)}</div>
}

export default QuestionnaireDisplay
