import { CREATING_CONSENT } from './actions'
import { takeEvery } from 'redux-saga/effects'
import { saveFhirResourceWorker } from '../Shared/sagas'

function* createConsentWorker(action) {
  yield saveFhirResourceWorker(action.payload)
}

function* rootSaga() {
  yield takeEvery(CREATING_CONSENT, createConsentWorker)
}

export default rootSaga
