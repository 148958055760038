import { combineReducers, Reducer } from 'redux'
import {
  PRACTITIONER_RECEIVED,
  PRACTITIONERS_RECEIVED,
  API_ERRORED,
  PRACTITIONER_REMOVED,
  ASKING_FOR_PIN_CODE,
  PIN_CODE_DELETED,
  PIN_CODE_NOT_FOUND,
  PIN_CODE_NOT_VALIDATED,
  PIN_CODE_VALIDATED,
  PRACTITIONER_SELECTED,
  PRACTITIONER_UNSELECTED
} from './actions'
import {
  SELECT_PRACTITIONER_FORM,
  TYPE_CODE_PIN_FORM,
  USER_AUTHORIZED,
  CREATE_PIN_CODE_FORM
} from './containers/Auth'
import { appendAllUniqueIds, reduceById } from '../Shared/ducks'
import { FHIR_RESOURCES_RECEIVED } from '../Shared/actions'
import { getTypeAndIdFromLocalReference } from '../utils/fhir'
//@ts-ignore
import uniq from 'lodash/uniq'
import {
  PRACTITIONER_ROLE_RECEIVED,
  PRACTITIONER_ROLES_RECEIVED
} from '../PractitionerRole/actions'
import { ById, ReduxState } from '../Shared/ducks/types'
import { Practitioner, PractitionerRole } from 'fhir-stu3'

export const allIds: Reducer<string[]> = (state = [], action) => {
  const { type, payload } = action
  switch (type) {
    case FHIR_RESOURCES_RECEIVED:
    case PRACTITIONERS_RECEIVED:
      return appendAllUniqueIds(state, payload.Practitioner)
    case PRACTITIONER_RECEIVED:
      return appendAllUniqueIds(state, payload.practitioner)
    case API_ERRORED:
      return state
    case PRACTITIONER_REMOVED:
      return state.filter(i => i !== payload.id)
    default:
      return state
  }
}

export const byId: Reducer<ById<Practitioner>> = (state = {}, action) => {
  const { type, payload } = action
  switch (type) {
    case FHIR_RESOURCES_RECEIVED:
    case PRACTITIONERS_RECEIVED:
      return reduceById(state, payload.Practitioner)
    case PRACTITIONER_RECEIVED:
      return { ...state, [payload.practitioner.id]: payload.practitioner }
    case API_ERRORED:
      return state
    case PRACTITIONER_REMOVED:
      return { ...state, [payload.id]: null }
    default:
      return state
  }
}

// Initial state for byAuthentication reducer
const practitionerAuthInitialState = {
  step: SELECT_PRACTITIONER_FORM,
  wrongPinMessage: false,
  currentPractitioner: null
}

export const authentication: Reducer<{
  step: string
  wrongPinMessage: boolean
  currentPractitioner: Practitioner | null
}> = (state = practitionerAuthInitialState, action) => {
  switch (action.type) {
    case PIN_CODE_DELETED:
      return {
        ...state,
        wrongPinMessage: false
      }
    case PRACTITIONER_UNSELECTED:
      return {
        ...state,
        step: SELECT_PRACTITIONER_FORM,
        wrongPinMessage: false
      }
    case PRACTITIONER_SELECTED:
      return {
        ...state,
        step: TYPE_CODE_PIN_FORM,
        currentPractitioner: action.payload.practitioner,
        context: null
      }
    case PIN_CODE_VALIDATED:
      return {
        ...state,
        step: USER_AUTHORIZED,
        wrongPinMessage: false,
        context: null
      }
    case PIN_CODE_NOT_VALIDATED:
      return {
        ...state,
        step: TYPE_CODE_PIN_FORM,
        wrongPinMessage: true
      }
    case PIN_CODE_NOT_FOUND:
      return {
        ...state,
        step: CREATE_PIN_CODE_FORM,
        wrongPinMessage: false,
        currentPractitioner: action.payload.practitioner
      }
    case ASKING_FOR_PIN_CODE:
      return {
        ...state,
        step: TYPE_CODE_PIN_FORM,
        context:
          action.payload && action.payload.context
            ? action.payload.context
            : null
      }
    default:
      return state
  }
}

/**
 * This function is used in the byOrganizationId duck. It creates an object
 * with every organization as a key and an array of practitioner id as value
 * @param state - byOrganization state
 * @param {PractitionerRole[]} roles - PractitionerRole array
 * @return {*}
 */
const reducePractitionersByOrganization = (
  state: ById<string[]>,
  roles: PractitionerRole[]
) =>
  roles?.reduce?.((state, role) => {
    const [, organizationId] = getTypeAndIdFromLocalReference(
      role?.organization?.reference
    )
    const [, practitionerID] = getTypeAndIdFromLocalReference(
      role?.practitioner?.reference
    )
    return {
      ...state,
      [organizationId]: uniq([
        ...(state?.[organizationId] ?? []),
        practitionerID
      ])
    }
  }, state) ?? state

/**
 * This duck prevents the use of an over-complicated selectors.
 * Typically it will be used when requesting practitioners by organization
 * this create lookup tables for every organization
 * @see getPractitionersByOrganizationId for more details
 * @param state
 * @param action
 * @return {{}|*}
 */
export const byOrganizationId: Reducer<ById<string[]>> = (
  state = {},
  action
) => {
  const { type, payload } = action
  switch (type) {
    case FHIR_RESOURCES_RECEIVED:
    case PRACTITIONER_ROLES_RECEIVED:
      return reducePractitionersByOrganization(state, payload.PractitionerRole)
    case PRACTITIONER_ROLE_RECEIVED:
      const [, organizationId] = getTypeAndIdFromLocalReference(
        payload.practitionerRole?.organization?.reference
      )
      const [, practitionerID] = getTypeAndIdFromLocalReference(
        payload.practitionerRole?.practitioner?.reference
      )
      return {
        ...state,
        [organizationId]: uniq([
          ...(state?.[organizationId] ?? []),
          practitionerID
        ])
      }
    default:
      return state
  }
}

export const getPractitionerById = (id: string) => (
  state: ReduxState
): Practitioner => {
  return state.practitioners.byId[id]
}

export default combineReducers({
  allIds,
  byId,
  byOrganizationId,
  authentication
})
