import { FC, useEffect, useState } from 'react'
import { queryMedicationFromES } from '../../MedicationStatement/utils'
import MedicationReferenceSearchDetails from '../../Medication/components/MedicationReferenceSearchDetails'
import { Button, Input } from '../../Components'
import styled from 'styled-components/macro'
import { lighten } from 'polished'
import { Trans } from '@lingui/macro'
import { Medication } from 'fhir-stu3'

interface SearchMedicationInputState {
  open: boolean
  search: string
  selected: Medication | null
  datalist: Medication[]
}
const initialState: SearchMedicationInputState = {
  open: false,
  search: '',
  selected: null,
  datalist: []
}
const SearchListInner = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 12rem;
  display: flex;
  flex-direction: column;
`

const Absolute = styled.div`
  position: absolute;
  left: 0;
  z-index: 1;
  right: 0;
`

const SearchList = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  box-shadow: ${(p) => p.theme.boxShadow};
  margin-top: 1rem;
  border: 1px solid ${(p) => p.theme.gray};
  border-radius: 0.25rem;
  & > ${SearchListInner} {
    background: ${(p) => p.theme.white};
    padding-bottom: 3rem;
  }
  &::after {
    position: absolute;
    height: 3rem;
    border-radius: 0 0 0.25rem 0.25rem;
    left: 0;
    right: 0;
    bottom: 0;
    content: '';
    background: linear-gradient(0deg, white, transparent);
    pointer-events: none;
  }
`
const ListItem = styled.li.attrs({ role: 'button' })`
  list-style: none;
  border-radius: 0.25rem;
  &:hover {
    background-color: ${(p) => lighten(0.25, p.theme.ocean)};
  }
  &:active {
    background-color: ${(p) => lighten(0.33, p.theme.ocean)};
  }
`
const Div = styled.div`
  display: flex;
  align-items: baseline;
  & > :first-child {
    flex: 1;
    min-width: 0;
    margin-right: 0.5rem;
    & div {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`

const Relative = styled.div`
  position: relative;
`

interface SearchMedicationInputProps {
  onChange: (medication: Medication) => void
  value: any
}
const SearchMedicationInput: FC<SearchMedicationInputProps> = (props) => {
  const { onChange, value } = props
  const [state, setState] = useState(initialState)

  useEffect(() => {
    if (state.search !== '') {
      queryMedicationFromES(state.search).then((datalist) => {
        setState((s) => ({ ...s, datalist }))
      })
    }
  }, [state.search])

  // reset the input when value is set to null
  useEffect(() => {
    if (value == null) {
      setState(initialState)
    }
  }, [value])

  const handleClick = (medication: Medication) => {
    setState((s) => ({ ...s, selected: medication }))
    onChange(medication)
  }
  if (state.selected !== null) { 
    return(
    <Div>
      <MedicationReferenceSearchDetails
        medication={state.selected}
        handleClick={() => {}}
      />
      <Button
        onClick={() => setState((s) => ({ ...s, selected: null }))}
        // variant="tertiary"
        data-test={'switch-medication-button'}
      >
        <Trans>Switch medication</Trans>
      </Button>
    </Div>
  )
  }
  const dummyMedication: Medication = {
    resourceType: 'Medication',
    code: { coding: [{ display: state.search }] }
  } 
  return (
    <Relative>
      <Input
        data-testid="search-medication-bar"
        value={state.search}
        required={true}
        label={<Trans>Medication name</Trans>}
        onChange={(e: Event) => {
          const target = e.target as HTMLInputElement
          const stringSearch = target?.value
          setState((s) => ({ ...s, search: stringSearch, open: true }))
        }}
      />
      {state.open === true && (
        <Absolute>
          <SearchList>
            <SearchListInner>
              {/* Allows the user to select what they just have typed */}
              <ListItem data-testid={`medication_typed`}>
                <MedicationReferenceSearchDetails
                  medication={dummyMedication}
                  handleClick={handleClick}
                />
              </ListItem>
              {state.datalist.map((medication: Medication, i) => {
                return (
                  <ListItem key={i} data-testid={`medication_${i}`}>
                    <MedicationReferenceSearchDetails
                      medication={medication}
                      handleClick={handleClick}
                    />
                  </ListItem>
                )
              })}
            </SearchListInner>
          </SearchList>
        </Absolute>
      )}
    </Relative>
  )
}
export default SearchMedicationInput
