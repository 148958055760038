import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useI18n } from '../../utils/I18nHookAdapter'
import { t } from '@lingui/macro'
import AttachmentControl from './AttachmentControl'
import { useToken } from '../utils'
import { faDownload } from '@fortawesome/free-solid-svg-icons'

const AttachmentDownloadControl = ({ attachment }) => {
  const token = useToken()

  // this handleClick invites the user to download the document
  // by prompting the native dialog from the browser
  // it creates a <a href="..." download/> tag to trigger the dialog
  // see https://www.w3schools.com/tags/att_a_download.asp
  //
  // 1. my first idea here was to patch the HAPI-FHIR Binary Provider
  // to accept the token as a urlSearchParams
  // we could have a link like
  // <a href=".../Binary/123?token=aaa.bbb.ccc" download>Download ⬇</a>
  // but unfortunately HAPI-FHIR is hard to patch and it won't accept to add
  // a search parameter easily 😅
  //
  // 2. the second solution was to download the file using fetch, and to create
  // the file in the browser, we then create another tag that point to the newly
  // created file.
  //
  // this is the method used here
  const handleClick = e => {
    // prevent the browser to open the link
    e.preventDefault()
    // first we fetch the document using the token
    fetch(attachment.url, {
      method: 'get',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      // res.blob() returns the binary content of the file
      .then(res => res.blob())
      .then(blob => {
        // we create a new hidden link tag that will point to the blob
        // this tag is the one which will trigger the download
        var a = document.createElement('a')
        document.body.appendChild(a)
        a.style = 'display: none'

        // we create the file from the blob and create an URL
        // where we can retrieve the file
        const file = window.URL.createObjectURL(
          blob.slice(0, blob.size, attachment.contentType)
        )

        // we can now make the href point to the file
        a.href = file
        // either we have
        // - <a href="..." download>...</a>
        // - <a href="..." download="toto">...</a>
        // the second option will indicate the download prompt of the browser
        // how the file should be named, thus we use the title
        a.download = attachment.title

        a.click()

        // we destruct the object we create
        // and free the memory
        document.body.removeChild(a)
        window.URL.revokeObjectURL(file)
      })
  }

  // we are using @lingui/core@2.9 which does not provide the useLingui hook
  // we need to use a custom hook instead:
  const i18n = useI18n()
  const title = i18n._(t`Download`)

  return (
    token != null && (
      <AttachmentControl
        as="a"
        href={attachment.url}
        onClick={handleClick}
        title={title}
        aria-label="download"
      >
        <FontAwesomeIcon icon={faDownload} />
      </AttachmentControl>
    )
  )
}

export default AttachmentDownloadControl
