import styled, { css } from 'styled-components/macro';
import Div100vh from 'react-div-100vh';

const Grid = styled.div`
  background: #ebf2fa;

  --main-sm-width: 23rem;
  --main-md-width: 28.5rem;
  --main-lg-width: 39rem;
  --aside-sm-width: 15rem;
  --aside-md-width: 19.5rem;
  --aside-lg-width: 22.5rem;
  --gap: 2rem;

  width: 100vw;
  // we use a grid here to get the scrollbar behavior nicely and the aside fixed
  // we want the scroll interaction to occur everywhere on the page
  // we also want to prevent the scrollbar from being stuck to the main content
  // see how facebook's website handle scrollbars 🤩
  display: grid;
  // the last column is set to 0 in order to use justify-content properly
  // this will allow the gap to be spaced between each column.
  grid-template-columns: 4rem 15rem 1fr 15rem 0;
  grid-template-rows: 5rem 1fr;
  // by setting the gap here we define a min value to the gutter
  // it should never be smaller than this value
  grid-column-gap: var(--gap);
  // if there is enough space then it will grow the gap between columns

  // the following media will make the different columns grow
  // depending on the viewport size.
  // see https://medeohealth.atlassian.net/wiki/spaces/PROD/pages/1145864195/Grid+Layout
  @media (max-width: 1024px) {
    overflow: auto;
    grid-template-columns:
      4rem var(--aside-sm-width) var(--main-sm-width) var(--aside-sm-width)
      0;
  }
  @media (min-width: 1024px) {
    grid-template-columns: 4rem var(--aside-sm-width) 1fr var(--aside-sm-width) 0;
  }
  @media (min-width: 1280px) {
    grid-template-columns: 4rem var(--aside-md-width) 1fr var(--aside-md-width) 0;
  }
  @media (min-width: 1920px) {
    grid-template-columns: 4rem var(--aside-lg-width) 1fr var(--aside-lg-width) 0;
  }
`;

const Aside = styled.aside`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: 100%;
  grid-row: 1 / 3;
  grid-column: 2;

  gap: 2rem;
`;

const HeaderAside = styled.header`
  position: relative;
  display: flex;
  grid-row: 1;
  grid-column: 2;
`;

const Nav = styled.nav`
  max-height: 100%;
  grid-row: 1/3;
`;

const Main = styled.main`
  @media (max-width: 1024px) {
    width: calc(var(--aside-sm-width) + var(--gap) + var(--main-sm-width));
  }
  @media (min-width: 1024px) {
    width: calc(var(--aside-sm-width) + var(--gap) + var(--main-sm-width));
  }
  @media (min-width: 1280px) {
    width: calc(var(--aside-md-width) + var(--gap) + var(--main-md-width));
  }
  @media (min-width: 1920px) {
    width: calc(var(--aside-lg-width) + var(--gap) + var(--main-lg-width));
  }
  margin: auto;

  position: relative;
`;
const FullHeader = styled.header`
  width: 100%;
  max-width: 1024px;
  margin: auto;
  grid-column: 2 / 5;
  grid-row: 1;
  display: flex;
`;
const CenterHeader = styled.header`
  width: calc(var(--aside-sm-width) + var(--gap) + var(--main-sm-width));
  grid-row: 1;
  grid-column: 2 /5;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (min-width: 1024px) {
    width: calc(var(--aside-sm-width) + var(--main-sm-width));
  }
  @media (min-width: 1280px) {
    width: calc(var(--aside-md-width) + var(--main-md-width));
  }
  @media (min-width: 1920px) {
    width: calc(var(--aside-lg-width) + var(--main-lg-width));
  }
  padding-right: var(--grid-column-gap);

  margin: 0 auto;
`;

const centerMixin = css`
  @media (max-width: 1024px) {
    margin-left: 0;
    grid-column: 2/6;
  }
  @media (min-width: 1024px) {
    margin-left: 0;
  }
  @media (min-width: 1280px) {
    margin-left: 0;
  }
  @media (min-width: 1920px) {
    margin-left: 0;
  }
`;
const fullMixin = css`
  width: 100%;
`;

const defaultMixin = css`
  @media (max-width: 1024px) {
    margin-left: 0;
    grid-column: 3 / 6;
  }
  @media (min-width: 1024px) {
    margin-left: calc(var(--aside-sm-width) + var(--gap));
  }
  @media (min-width: 1280px) {
    margin-left: calc(var(--aside-md-width) + var(--gap));
  }
  @media (min-width: 1920px) {
    margin-left: calc(var(--aside-lg-width) + var(--gap));
  }
`;
const Scroll = styled.div`
  // default to auto
  // apply overlay on webkit browsers
  overflow-y: auto;
  overflow-y: overlay;
  max-height: 100%;
  grid-row: 1 / 3;
  grid-column: 2 / 6;

  padding-right: var(--gap);
  ${p => p.variant === 'full' && fullMixin}
  ${p => p.variant === 'center' && centerMixin}
  ${p => p.variant === 'default' && defaultMixin}
`;

const MainPanelHeader = styled.header`
  grid-row: 1;
  grid-column: 3/5;
  @media (max-width: 1024px) {
    width: calc(var(--aside-sm-width) + var(--gap) + var(--main-sm-width));
  }
  @media (min-width: 1024px) {
    width: calc(var(--aside-sm-width) + var(--gap) + var(--main-sm-width));
  }
  @media (min-width: 1280px) {
    width: calc(var(--aside-md-width) + var(--gap) + var(--main-md-width));
  }
  @media (min-width: 1920px) {
    width: calc(var(--aside-lg-width) + var(--gap) + var(--main-lg-width));
  }
  margin: 0 auto;
  display: flex;

  justify-content: space-between;
  align-items: center;
`;

const FullMain = styled.main`
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
  grid-column: 2 / 6;
  display: flex;
  flex-direction: column;
`;

const GridMain = ({ variant, ...rest }) => {
  if (variant === 'full') {
    return (
      <Scroll variant="full">
        <FullMain {...rest} />
      </Scroll>
    );
  }
  return (
    <Scroll variant={variant}>
      <Main {...rest} />
    </Scroll>
  );
};
GridMain.defaultProps = {
  variant: 'default'
};

const GridLayout = props => {
  return (
    <Div100vh style={{ display: 'flex' }}>
      {/* Div100vh fixes height issues for iOS*/}
      <Grid {...props} />
    </Div100vh>
  );
};

GridLayout.Main = GridMain;

GridLayout.Header = ({ variant, ...rest }) => {
  if (variant === 'center') {
    return <CenterHeader {...rest} />;
  } else if (variant === 'full') {
    return <FullHeader {...rest} />;
  } else if (variant === 'aside') {
    return <HeaderAside {...rest} />;
  }
  return <MainPanelHeader {...rest} />;
};

GridLayout.Aside = Aside;
GridLayout.Nav = Nav;

export default GridLayout;
