import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from '@reach/router';
import CalendarView from '../../Schedule/containers/CalendarView';
import { getCurrentEncounter } from '../../Encounter/selectors';
import GridLayout from '../../Shared/components/GridLayout';

import useGetLocationFromOrganizationId from '../../Shared/hooks/useGetLocationFromOrganizationId';

const BookingCalendar = () => {
  const encounter = useSelector(getCurrentEncounter);
  const navigate = useNavigate();
  const location = useGetLocationFromOrganizationId();

  useEffect(() => {
    // navigate only when the encounter is created
    if (encounter != null) {navigate(`/booking/${location.id}/booking/`);}
  }, [encounter, navigate, location]);

  return (
    <GridLayout.Main variant="full">
      <CalendarView calendarType={'booking'} />
    </GridLayout.Main>
  );
};

export default BookingCalendar;
