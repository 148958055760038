import { useNavigate } from '@reach/router'
import { Trans } from '@lingui/macro'
import { FC, SyntheticEvent } from 'react'
import styled from 'styled-components/macro'
import { Button } from '../../Components'

const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  & > :not(:last-child) {
    margin-right: 1rem;
  }
`
const CustomCardFooter = styled.div`
  margin: 0 2rem 2rem 2rem !important;
`
const ContainerBtn = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

type BookingFormFooterProps = {
  canNavigateNext: boolean
}

const BookingFormFooter: FC<BookingFormFooterProps> = (props) => {
  const { canNavigateNext } = props
  const navigate = useNavigate()
  const onPrevious: React.EventHandler<SyntheticEvent> = async () => {
    await navigate(-1)
  }

  return (
    <CustomCardFooter>
      <ContainerBtn>
        <Button type={'button'} onClick={onPrevious} color="ocean">
          <Trans>Previous</Trans>
        </Button>
        <ButtonGroup>
          <Button
            form="booking"
            color="ocean"
            disabled={!canNavigateNext}
            data-test="next"
            data-intercom-target="next"
          >
            <Trans>Next</Trans>
          </Button>
        </ButtonGroup>
      </ContainerBtn>
    </CustomCardFooter>
  )
}

export default BookingFormFooter
