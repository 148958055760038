import React from 'react'
import moment from 'moment'
import styled from 'styled-components/macro'

const Day = styled.div`
  text-align: center;
  color: #1b2735;

  text-transform: capitalize;
  font-size: small;
  margin-bottom: 0.5rem;
`

const WeekDay = styled.div`
  color: #1b2735;
  text-align: center;
  text-transform: capitalize;
  font-weight: 600;
  margin-bottom: 0.5rem;
`
const Container = styled.header`
  width: 100%;
`

/**
 * Display an header with the date and day of the week
 * depending on the column index, i.e. the number of day from today
 * @param {number} index - the column index
 * @return {*}
 * @constructor
 */
const ColumnHeader = ({ date }) => {
  const weekDay = moment(date).format('dddd')
  const day = moment(date).format('DD MMM')
  return (
    <Container>
      <WeekDay>{weekDay}</WeekDay>
      <Day>{day}</Day>
    </Container>
  )
}

export default ColumnHeader
