import React, { useContext } from 'react'
const { I18n } = require('@lingui/react')

// simple context to hold the i18n object
// see I18nHookAdapter for more details
const I18nContext = React.createContext()

/**
 * The purpose of this component is to make the use18n() hook available
 * The reason behind is that @lingui/react@2 does not provide a hook for using
 * the i18n object.
 * The only ways to get this object is either to use:
 * -  <I18n /> with a render prop
 * -  withI18n() HOC
 *
 * This component retrieves the i18n object and make it available in a
 * Context that is not exposed. the use18n() is the only Consumer of this
 * Context.
 *
 * Note: when we migrated to @lingui/react@3 we can get rid of this adapter
 * @see https://lingui.js.org/tutorials/react-patterns.html
 *
 */
const I18nHookAdapter = ({ children }) => {
  // Provides the context and pass the children along
  // nothing fancy here :P
  return (
    <I18n>
      {({ i18n }) => (
        <I18nContext.Provider value={i18n}>{children}</I18nContext.Provider>
      )}
    </I18n>
  )
}

/**
 * provides the i18n from lingui context.
 * This hook is usefull for situation like string only translation like
 * like html tag attributes
 * @see https://lingui.js.org/tutorials/react-patterns.html#element-attributes-and-string-only-translations
 *
 * This hooks prevent the use of the HOC or the renderProps provided by @lingui/core@2
 * more details in I18nHookAdapter
 * Note: this hook will be deprecated once the useLingui hook is available
 *
 * @example
 *   // retrieve a string only translation for
 *   // for html attributes like placeholder or title
 *   // 👇
 *   const { t } = useI18n()
 *   const placeholder = i18n._(t`Search your patient`)
 *
 * @see https://lingui.js.org/releases/migration-3.html
 * @returns {unknown}
 */
export const useI18n = () => {
  return useContext(I18nContext)
}
export default I18nHookAdapter
