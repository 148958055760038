import * as R from 'ramda'

// add set method to allow to modify a fhirResource using fhirpath syntax
// right now it support only the item() function

export const set = (resource, path, value) => {
  const lensPath = path.split('.').map(str => {
    let match = str.match(/^item\(([0-9]+)\)$/m)

    if (match != null) {
      const [, index] = match
      return parseInt(index, 10)
    }
    return str
  })

  const lens = R.lensPath(lensPath)
  return R.set(lens, value, resource)
}
