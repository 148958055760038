import React from 'react'
import styled from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Trans } from '@lingui/macro'
import { Button } from '../../Components'
import { Link, useNavigate } from '@reach/router'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import Modal from './Modal'
const Div = styled.div`
  padding: 2rem;
`
const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  padding-top: 2rem;
  align-items: center;
`
const Footer = styled.footer`
  display: flex;
  padding: 0 2rem;
  justify-content: flex-end;

  & > :not(:last-child) {
    margin-right: 1rem;
  }

  align-items: flex-start;
`

const CustomButton = styled(Button)`
  & > svg {
    margin-right: 0.5rem;
  }
`

const PatientsGoBackDialog = ({ hasCurrentEncounter, onBack }) => {
  const navigate = useNavigate()
  return (
    <Container>
      {hasCurrentEncounter ? (
        <Modal
          title={<Trans>Attention, examination in progress</Trans>}
          component={props => (
            <CustomButton
              color="ocean"
              variant="secondary"
              style={{ fontSize: 'small' }}
              {...props}
            >
              <FontAwesomeIcon icon={faChevronLeft} />
              <Trans>Back</Trans>
            </CustomButton>
          )}
        >
          {close => (
            <>
              <Div>
                <Trans>
                  Are you sure you want to exit ? If you exit now, the current
                  examination will not be saved.
                </Trans>
              </Div>
              <Footer>
                <Button color="ocean" variant="secondary" onClick={close}>
                  <Trans>Resume exam</Trans>
                </Button>
                <Button
                  color="ocean"
                  onClick={() => {
                    onBack()
                    // small hack here,
                    // For some reasons the navigate is not performed
                    // if not delayed even with the smallest timeout possbile
                    // remove at your own risk
                    setTimeout(() => {
                      navigate('/')
                    }, 1)
                  }}
                >
                  <Trans>Quit the examination</Trans>
                </Button>
              </Footer>
            </>
          )}
        </Modal>
      ) : (
        <CustomButton
          to="/"
          as={Link}
          color="ocean"
          variant="secondary"
          style={{ fontSize: 'small' }}
        >
          <FontAwesomeIcon icon={faChevronLeft} />
          <Trans>Back</Trans>
        </CustomButton>
      )}
    </Container>
  )
}

PatientsGoBackDialog.defaultProps = {
  goBack: () => {}
}

export default PatientsGoBackDialog
