import React, { useState } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components/macro'
import { saveObservation } from '../actions'
import { getPatientById } from '../../Patient/selectors'
import * as fromLoinc from '../../utils/loinc'
import { bindActionCreators } from 'redux'
import {
  displayParticipantName,
  displayEndDateFromEncounter
} from '../../Shared/display/encounter'
import ObservationSelect from '../components/ObservationSelect'
import usePractitioner from '../../Practitioner/usePractitioner'

const Header = styled.div`
  margin-top: ${p => p.theme.spacing.large};
  margin-bottom: ${p => p.theme.spacing.medium};
  display: flex;
  align-items: flex-end;
`

const EncounterDate = styled.div`
  margin-right: ${p => p.theme.medium};
`

const Practitioner = styled.div`
  font-size: ${p => p.theme.small};
`

const ManualObservationForm = ({
  saveObservation,
  patientID,
  onAfterSubmit
}) => {
  const [selectedMeasureType, setMeasureType] = useState(
    fromLoinc.LOINC_BLOOD_PRESSURE_CODE
  )
  const currentPractitioner = usePractitioner()

  const onSubmit = event => {
    const { elements: e } = event.currentTarget
    const value = e.value == null ? null : e.value.value
    const sys = e.sys == null ? null : e.sys.value
    const dia = e.dia == null ? null : e.dia.value
    const lastMeal = e.lastMeal == null ? null : e.lastMeal.value
    const bili = e.bili == null ? null : e.bili.value
    const urobi = e.urobi == null ? null : e.urobi.value
    const keto = e.keto == null ? null : e.keto.value
    const gluc = e.gluc == null ? null : e.gluc.value
    const prot = e.prot == null ? null : e.prot.value
    const hemo = e.hemo == null ? null : e.hemo.value
    const pH = e.pH == null ? null : e.pH.value
    const nitrite = e.nitrite == null ? null : e.nitrite.value
    const leuko = e.leuko == null ? null : e.leuko.value
    const spec_grav = e.spec_grav == null ? null : e.spec_grav.value

    const observation = {
      loincCoding: selectedMeasureType,
      patientID: patientID,
      effectiveDateTime: new Date(),
      ...(lastMeal ? { lastMeal } : {}),
      // either we got one observation or we got the 2 components of blood pressure
      ...(value != null
        ? { value }
        : sys != null
        ? { systolicBloodPressure: sys, diastolicBloodPressure: dia }
        : {
            bilirubinPresence: bili,
            urobilinogenPresence: urobi,
            ketonesPresence: keto,
            glucosePresence: gluc,
            proteinPresence: prot,
            hemoglobinPresence: hemo,
            pH: pH,
            nitritePresence: nitrite,
            leukocytePresence: leuko,
            specificGravity: spec_grav
          }),
      ...(e.practitioner == null
        ? null
        : { practitioner: e.practitioner.value })
    }

    saveObservation(observation)
    event.currentTarget.reset()
    onAfterSubmit()
  }

  return (
    <form
      data-testid="observationForm"
      id="observation"
      method="dialog"
      onSubmit={onSubmit}
    >
      <Header>
        <EncounterDate>
          {displayEndDateFromEncounter({ period: { end: new Date() } })}
        </EncounterDate>
        <Practitioner>
          {displayParticipantName(currentPractitioner)}
        </Practitioner>
      </Header>
      <input
        name="practitioner"
        type="hidden"
        value={`Practitioner/${currentPractitioner.id}`}
      />
      <ObservationSelect
        selectedMeasureType={selectedMeasureType}
        setMeasureType={setMeasureType}
      />
    </form>
  )
}

const mapStateToProps = (state, ownProps) => ({
  patient: getPatientById(ownProps.patientID)(state)
})

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      saveObservation: saveObservation
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatch)(ManualObservationForm)
