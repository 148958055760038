import { t, Trans } from '@lingui/macro'
import * as Sentry from '@sentry/react'
import moment from 'moment'
import { useCallback, useEffect, useRef, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { useEffectOnce } from 'react-use'
import { bindActionCreators } from 'redux'
import styled from 'styled-components/macro'
import { Card } from '../../Components'
import {
  createEncounter,
  deselectEncounter,
  getInitialEncounterPage,
  updateEncounter
} from '../../Encounter/actions'
import { getCurrentEncounter } from '../../Encounter/selectors'
import { getCurrentOrganization } from '../../Organization/selector'
import PatientInfo from '../../Patient/components/PatientInfo'
import PatientIntro from '../../Patient/components/PatientIntro'
import { getPatientById } from '../../Patient/selectors'
import TeleconsultationPlanDefinition from '../../PlanDefinition/containers/TeleconsultationPlanDefinition'
import TeleconsultationWarnings from '../../Practitioner/containers/TeleconsultationWarnings'
import usePractitioner from '../../Practitioner/usePractitioner'
import { getActiveTeleconsultationBypatientID } from '../../ProcedureRequest/ducks'
import RequestGroup from '../../RequestGroup/components/RequestGroup'
import { loadFhirResourceFromId, search } from '../../Shared/actions'
import {
  removeShouldAlertOnLeave,
  setShouldAlertOnLeave
} from '../../Shared/actions/navigation'
import FallbackCard from '../../Shared/components/FallbackCard'
import GridLayout from '../../Shared/components/GridLayout'
import LoadingFallback from '../../Shared/components/LoadingFallback'
import mixpanel, { MIXPANEL_PATIENT_DETAILS_READ } from '../../Shared/mixpanel'
import { getIdByReference } from '../../Shared/utils'
import { MEDEO_ENCOUNTER_TYPE_SYSTEM } from '../../utils/codes'
import { MEDEO_ENCOUNTER_TYPE_TELECONSULTATION_CONSULTATION } from '../../utils/encounter-type'
import { useI18n } from '../../utils/I18nHookAdapter'
import { LOINC_SYSTEM_URL } from '../../utils/loinc'
import LiveObservationsView from '../containers/LiveObservationsView'
import VideoSequenceManager from '../containers/VideoSequenceManager'
import ClinicalExam from './ClinicalExam'
const VideoContainer = styled.div`
  margin-top: 2rem;
  width: 100%;
  overflow: hidden;
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  background-color: grey;
`
const Header = styled.div`
  border-top: 1px solid black;
`
const PatientInfoContainer = styled.div`
  margin-top: ${(props) => props.theme.large};
`
const CustomCard = styled(Card)`
  margin-bottom: 2rem;
`
/**
 * Get the updated observation list on reception of a new one
 * As we want to prevent flooding, we add this step to check that
 * there is 10sec between two observations of same type
 *
 * @param {Object} observation: the just received observation
 * @param {[Object]} observations: the array of already received observations
 */
const getUpdatedObservationList = (observation, observations) => {
  const lastObservation = observations[observations.length - 1]

  // The observations in the array are in chronological order
  // So we check if the last received one is of the same type as
  // the one just received
  const isObservationOfSameType =
    lastObservation?.code.coding.find(
      (coding) => coding.system === LOINC_SYSTEM_URL
    )?.code ===
    observation.code.coding.find((coding) => coding.system === LOINC_SYSTEM_URL)
      ?.code

  // If no, simply add it to the array
  if (
    isObservationOfSameType === false ||
    observation.effectiveDateTime == null
  ) {
    return [...observations, observation]
  }

  const idleTime =
    moment(observation.effectiveDateTime).valueOf() -
    moment(lastObservation.effectiveDateTime).valueOf()

  // If it was received less than 10sec ago, we replace it by the new one
  if (idleTime < 10000) {
    return [...observations.slice(0, -1), observation]
  }
  // Else we add it to the array
  return [...observations, observation]
}

const TeleconsultationPageForDistant = ({ patientID, preconsultationId }) => {
  const i18n = useI18n()
  const dispatch = useDispatch()
  const [encounterState, setEncounterState] = useState({
    sequenceIndexOfPage: 0,
    sequenceWorkThrough: { action: [] },
    sequenceResponse: [],
    isIsolatedElementView: false,
    questionnaireIdentifier: {
      system: null,
      code: null
    }
  })
  const currentPractitioner = usePractitioner()

  const [observations, setObservations] = useState([])

  const procedureRequestFrompatientIDAndActive = useSelector(
    getActiveTeleconsultationBypatientID(patientID)
  )
  const patient = useSelector(getPatientById(patientID))
  useEffectOnce(() => {
    // Switch patient will clear any previous patient values
    if (patient == null) {
      dispatch(
        search('Patient', {
          _id: patientID
        })
      )
    }

    dispatch(getInitialEncounterPage(2000, { subjectId: patientID }))
    dispatch(search('Encounter', { _id: preconsultationId }))

    // We might need to load the procedure requests again when we are not coming from the
    // waiting list
    if (procedureRequestFrompatientIDAndActive == null && patientID) {
      dispatch(
        search('ProcedureRequest', {
          subject: 'Patient/' + patientID,
          status: 'active',
          _sort: '-_id'
        })
      )
    }
  })

  useEffect(() => {
    // When the user arrives on this page, we set a message which would be displayed
    // if they would ever try to navigate away from the page to alert them that any information
    // unsaved would be lost.
    dispatch(
      setShouldAlertOnLeave(
        i18n._(
          t`Careful! You are going to leave the teleconsultation. Any unsaved information will be lost. Are you sure you want to continue?`
        )
      )
    )

    // This is removed as soon as the page is left
    return () => {
      dispatch(removeShouldAlertOnLeave())
    }
  }, [dispatch, i18n])

  const currentEncounter = useSelector(getCurrentEncounter)
  const currentencounterID = currentEncounter?.id

  // Here we preserve the id of the encounter that we created, so that
  // we would keep track of the fact that one was already created and we do not need to create
  // another one. This will prevent from creating several encounters for the teleconsultation
  const createdEncounterState = useRef(currentencounterID)

  // Here is the process to deselect and update the status of the current
  // encounter in the state
  const handleRemoveEncounter = useCallback(
    (formerEncounter) => {
      Sentry.captureException(
        new Error(`Encounter ${formerEncounter.id} set to entered-in-error`)
      )
      dispatch(deselectEncounter())
      dispatch(
        updateEncounter({
          ...formerEncounter,
          status: 'entered-in-error'
        })
      )
    },
    [dispatch]
  )
  // create teleconsultation encounter if not already created when arriving on page
  useEffect(() => {
    if (
      patientID != null &&
      currentPractitioner != null &&
      preconsultationId != null &&
      currentEncounter == null &&
      // we create a new encounter only if none was created yet
      createdEncounterState.current == null
    ) {
      dispatch(
        createEncounter(
          patientID,
          currentPractitioner.id,
          // Here we build the type of the newly created encounter
          {
            coding: [
              {
                system: MEDEO_ENCOUNTER_TYPE_SYSTEM,
                code: MEDEO_ENCOUNTER_TYPE_TELECONSULTATION_CONSULTATION
              }
            ]
          },
          preconsultationId
        )
      )
      // If the current encounter was just created, we store it in the
      // createdEncounterState which is a reference and will be preserved
    } else if (
      createdEncounterState.current == null &&
      currentEncounter != null
    ) {
      createdEncounterState.current = currentEncounter.id
    }
    // In the case a current encounter exists but does not correspond to the current patient,
    // we set it as entered-in-error and create a new one
    else if (
      currentEncounter != null &&
      getIdByReference(currentEncounter.subject.reference) !== patientID
    ) {
      handleRemoveEncounter(currentEncounter)
      createdEncounterState.current = null
    }
  }, [
    currentEncounter,
    preconsultationId,
    currentPractitioner,
    dispatch,
    patientID,
    handleRemoveEncounter
  ])

  useEffectOnce(() => {
    mixpanel.track(MIXPANEL_PATIENT_DETAILS_READ)
  })

  const organization = useSelector(getCurrentOrganization)

  const organizationName =
    organization != null
      ? `(id : ${organization.id}) ${organization.name}, ${organization.address?.[0]?.line?.[0]}, ${organization.address?.[0]?.postalCode}, ${organization.address?.[0]?.city}`
      : 'noNameProvided'
  const identifier = `${currentPractitioner.id}_${currentPractitioner.name?.[0]?.family}`

  const handleObservation = (observation) => {
    setObservations(getUpdatedObservationList(observation, observations))
  }

  return patient == null || currentEncounter == null ? (
    <LoadingFallback />
  ) : procedureRequestFrompatientIDAndActive != null ? (
    <>
      <GridLayout.Aside>
        <PatientIntro style={{ paddingTop: '2rem' }} patient={patient} />
        <PatientInfoContainer>
          <PatientInfo patient={patient} />
        </PatientInfoContainer>
      </GridLayout.Aside>
      <Sentry.ErrorBoundary fallback={FallbackCard}>
        <GridLayout.Main>
          <VideoContainer>
            <VideoSequenceManager
              patientID={patient.id}
              identifier={identifier}
              organizationName={organizationName}
              handleObservation={handleObservation}
            />
          </VideoContainer>
          <LiveObservationsView observations={observations} />
          <TeleconsultationWarnings />
          <CustomCard>
            {preconsultationId != null ? (
              <div>
                <h2>
                  <Trans>Preconsultation</Trans>
                </h2>
                <RequestGroup
                  encounterID={preconsultationId}
                  patient={patient}
                />
              </div>
            ) : (
              <div>
                <Trans>
                  No preconsultation form was filled for this patient
                </Trans>
              </div>
            )}
            <Header>
              <h2>
                <Trans>Consultation</Trans>
              </h2>
            </Header>
            <ClinicalExam preconsultationId={preconsultationId} />
            <TeleconsultationPlanDefinition
              practitioner={currentPractitioner}
              encounter={currentEncounter}
              patient={patient}
              procedureRequest={procedureRequestFrompatientIDAndActive}
              preconsultationencounterID={preconsultationId}
              saveState={setEncounterState}
              defaultState={encounterState}
            />
          </CustomCard>
        </GridLayout.Main>
      </Sentry.ErrorBoundary>
    </>
  ) : (
    <p>
      <Trans>
        Oh no ! It looks like this remote consultation in not active anymore.
        Please go to the waiting room.
      </Trans>
    </p>
  )
}

TeleconsultationPageForDistant.defaultProps = {
  patientID: null
}

const mapDispatch = (dispatch, ownProps) =>
  bindActionCreators(
    {
      loadPatient: loadFhirResourceFromId.bind(
        null,
        'Patient',
        ownProps.patientID
      )
    },
    dispatch
  )

export default connect(null, mapDispatch)(TeleconsultationPageForDistant)
