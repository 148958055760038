import React from 'react'
import PatientList from '../components/PatientList'

const PaginatedPatientList = ({ patientsTotal, patients, children }) => {
  return (
    <PatientList patientsTotal={patientsTotal} patients={patients}>
      {children}
    </PatientList>
  )
}

export default PaginatedPatientList
