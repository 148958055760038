import React from 'react'
import styled from 'styled-components/macro'

const TagContainer = styled.div`
  margin-right: ${p => p.theme.spacing.small};
  margin-bottom: ${p => p.theme.spacing.small};
`

const TagInner = styled.div`
  border-radius: 0.25rem;
  color: ${p => p.theme.white};
  background-color: ${p => p.theme.ocean};
  padding: 0.5rem 1rem;
  display: flex;
`

const TagRemove = styled.div`
  margin-right: ${p => p.theme.spacing.small};
  cursor: pointer;
  font-size: 1.3em;
  line-height: 0.8em;
`

const TagText = styled.div`
  line-height: 1em;
`

const Tag = ({ value, onRemove, readOnly }) => (
  <TagContainer>
    <TagInner>
      {!readOnly && <TagRemove onClick={onRemove}>&times;</TagRemove>}
      <TagText>{value}</TagText>
    </TagInner>
  </TagContainer>
)

export default Tag
