import { combineReducers } from 'redux'
import { OPEN_ID_TOKEN_RECEIVED, SIGN_OUT_REQUESTED } from './actions'

/**
 * @typedef Token
 * @type {object}
 * @property {string} access_token
 * @property {string} expires_in
 * @property {string} id_token
 * @property {string} issued_at
 * @property {string} refresh_token
 * @property {string} scope
 * @property {string} token_type
 */

/**
 * @typedef State
 * @type { Token | null}
 *
 * @param {State} state
 * @param action
 * @returns {State}
 */
const token = (state = null, action) => {
  const { type, payload } = action
  switch (type) {
    case OPEN_ID_TOKEN_RECEIVED:
      return payload.token
    case SIGN_OUT_REQUESTED:
      return null
    default:
      return state
  }
}

const attributes = (state = null, action) => {
  const { type, payload } = action
  switch (type) {
    case OPEN_ID_TOKEN_RECEIVED:
      return payload.attributes
    case SIGN_OUT_REQUESTED:
      return null
    default:
      return state
  }
}

const cognitoGroups = (state = null, action) => {
  const { type, payload } = action
  switch (type) {
    case OPEN_ID_TOKEN_RECEIVED:
      return payload.cognitoGroups
    case SIGN_OUT_REQUESTED:
      return []
    default:
      return state
  }
}

export default combineReducers({
  token,
  attributes,
  cognitoGroups,
})
