import React from 'react'
import styled from 'styled-components/macro'

const ValueDiv = styled.div``

const QuantityAnswer = ({ value }) => {
  const answerValue = value?.value
  const answerUnit = value?.unit

  return (
    <ValueDiv>
      {answerValue} {answerUnit ?? ''}
    </ValueDiv>
  )
}

export default QuantityAnswer
