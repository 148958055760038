import {
  call,
  put,
  select,
  take,
  takeEvery,
  takeLeading
} from 'redux-saga/effects'
import moment from 'moment'
import {
  getResourceFromParams,
  saveFhirResourceWorker,
  searchFhirResourceWorker
} from '../Shared/sagas'
import {
  filterOutEnteredInErrorEncounter,
  getCurrentEncounter,
  getEncounterFromId,
  getShownEncounters
} from './selectors'
import {
  CANCELING_ENCOUNTER,
  deselectEncounter,
  encounterPageChanged,
  encountersPaginationStale,
  FETCHING_ENCOUNTERS,
  GET_INITIAL_ENCOUNTER_PAGE,
  getInitialEncounterPage,
  LOAD_MORE_ENCOUNTERS,
  SAVING_ENCOUNTER_SEQUENCE,
  selectEncounter,
  STALE_ENCOUNTER_PAGE,
  STARTING_BLUETOOTH_ENCOUNTER,
  STARTING_ENCOUNTER,
  stopEncounter,
  STOPPING_ENCOUNTER,
  STOPPING_PRETELECONSULTATION_ENCOUNTER,
  stopPreTeleconsultationEncounter as stopPreTeleconsultationEncounterAction,
  UPDATING_ENCOUNTER
} from './actions'
import {
  OBSERVATION_RECEIVED,
  UPDATING_ALL_OBSERVATIONS,
  UPDATING_OBSERVATION
} from '../Observation/actions'
import { FHIR_DATE_FORMAT } from '../utils/dateUtils'
import * as pagination from '../Shared/sagas/pagination'
import { getObservationsFromencounterID } from '../Observation/selectors'
import { getTypeAndIdFromLocalReference } from '../utils/fhir'
import {
  CERFAS_AND_DOCUMENTS_SAVED,
  saveCerfaAndDocuments,
  saveQuestionnaireResponse
} from '../QuestionnaireResponse/actions'
import { saveDocumentReference } from '../DocumentReference/actions'
import { ADDING_REQUEST_GROUP } from '../RequestGroup/actions'
import { MEDEO_ENCOUNTER_TYPE_PRE_TELECONSULTATION } from '../utils/encounter-type'

import { getPlanDefinitionById } from '../PlanDefinition/selector'
import {
  MEDEO_CARE_SHEET_QUESTIONNAIRE_IDENTIFIER,
  MEDEO_DOCUMENT_IDENTIFIER,
  MEDEO_DRUG_PRESCRIPTION_IDENTIFIER,
  MEDEO_PARAMEDICAL_PRESCRIPTION_IDENTIFIER,
  MEDEO_SICK_LEAVE_QUESTIONNAIRE_IDENTIFIER,
  MEDEO_APPOINTMENT_TYPE_TELECONSULTATION
} from '../utils/codes'
import { getItemByLinkId } from '../Questionnaire/utils'
import {
  PLAN_DEFINITION_IDENTIFIER_SYSTEM,
  TELECONSULTATION_PLAN_DEFINITION_VALUE,
  TELECONSULTATION_QUESTIONNAIRE_SYSTEM
} from '../PlanDefinition/utils'
import { getAllQuestionnairesByIdentifierAndSystem } from '../Questionnaire/selector'
import { createAppointment } from '../Appointment/actions'
import { getStatusCerfasUploaded } from '../QuestionnaireResponse/selector'
import { getFutureAppointments } from '../Appointment/selectors'
import { getIdByReference } from '../Shared/utils'

export const MEDEO_ENCOUNTER_TYPE_SYSTEM =
  'http://medeo.io/fhir/ValueSet/encounter-type'

const extractEncounterWithStatus = status => action => {
  // preconsultationId is used for teleconsultation in patientSide. Like that we can receive the last Encounter to get documents
  const { patientID, practitionerID, type, partOfencounterID } = action.payload
  const now = moment().format(FHIR_DATE_FORMAT)
  return {
    resourceType: 'Encounter',
    status,
    period: {
      start: now,
      end: now
    },
    type,
    subject: {
      reference: `Patient/${patientID}`
    },
    participant: [
      { individual: { reference: `Practitioner/${practitionerID}` } }
    ],
    ...(partOfencounterID && {
      partOf: {
        reference: `Encounter/${partOfencounterID}`
      }
    })
  }
}

function* extractResourceFromStoppingEncounter(action) {
  const { encounterID } = action.payload
  const encounter = yield select(getEncounterFromId, encounterID)
  return {
    ...encounter,
    status: 'finished',
    period: {
      start: encounter.period.start,
      end: moment().format(FHIR_DATE_FORMAT)
    }
  }
}

function* startEncounterWorker(action) {
  const encounter = yield call(
    saveFhirResourceWorker,
    extractEncounterWithStatus('in-progress'),
    action
  )
  if (encounter != null) {
    yield put(selectEncounter(encounter.id))
  }
}

function* archiveEncounterWorker(resourceOrResourceFn, action) {
  const resource = yield getResourceFromParams(resourceOrResourceFn, action)
  const [, encounterID] = getTypeAndIdFromLocalReference(
    resource.context.reference
  )
  yield take(OBSERVATION_RECEIVED)
  const observations = yield select(getObservationsFromencounterID, encounterID, [
    filterOutEnteredInErrorEncounter
  ])
  const encounter = yield select(getEncounterFromId, encounterID)
  if (observations.length === 0 && encounter.status === 'finished') {
    const action = { ...encounter, status: 'entered-in-error' }
    yield saveFhirResourceWorker(action)
  }
}

function* archiveEncounterWorkerBis(action) {
  const encounter = yield select(getEncounterFromId, action.payload.encounterID)
  if (encounter.status === 'finished') {
    const action = { ...encounter, status: 'entered-in-error' }
    yield saveFhirResourceWorker(action)
  }
}

function* stopEncounterWorker(action) {
  const encounter = yield call(
    saveFhirResourceWorker,
    extractResourceFromStoppingEncounter,
    action
  )
  if (encounter != null) {
    yield put(deselectEncounter(encounter.id))
    // here the cause of the error linear params, we have to found solution
    // because we need this part of code for finalize encounter and teleconsultation
    if (action.type === 'STOPPING_ENCOUNTER')
      {yield put(encountersPaginationStale())}
  }
}

/**
 * Filter cerfas, orders and documents out of the PlanDefinitionContext answers
 *
 * @see QuestionnaireResponse/sagas for more details
 * @param {*} answers
 */
function* filterOutCerfasOrdersAndDocuments(answers) {
  const [caresheetQuestionnaire] = yield select(
    getAllQuestionnairesByIdentifierAndSystem,
    MEDEO_CARE_SHEET_QUESTIONNAIRE_IDENTIFIER,
    TELECONSULTATION_QUESTIONNAIRE_SYSTEM
  )

  const [sickLeaveQuestionnaire] = yield select(
    getAllQuestionnairesByIdentifierAndSystem,
    MEDEO_SICK_LEAVE_QUESTIONNAIRE_IDENTIFIER,
    TELECONSULTATION_QUESTIONNAIRE_SYSTEM
  )

  const [documentsQuestionnaire] = yield select(
    getAllQuestionnairesByIdentifierAndSystem,
    MEDEO_DOCUMENT_IDENTIFIER,
    TELECONSULTATION_QUESTIONNAIRE_SYSTEM
  )

  const [paramedicalQuestionnaire] = yield select(
    getAllQuestionnairesByIdentifierAndSystem,
    MEDEO_PARAMEDICAL_PRESCRIPTION_IDENTIFIER,
    TELECONSULTATION_QUESTIONNAIRE_SYSTEM
  )

  const [drugQuestionnaire] = yield select(
    getAllQuestionnairesByIdentifierAndSystem,
    MEDEO_DRUG_PRESCRIPTION_IDENTIFIER,
    TELECONSULTATION_QUESTIONNAIRE_SYSTEM
  )

  return answers.filter(
    answer =>
      answer.questionnaireId !== drugQuestionnaire.id &&
      answer.questionnaireId !== paramedicalQuestionnaire.id &&
      answer.questionnaireId !== sickLeaveQuestionnaire.id &&
      answer.questionnaireId !== caresheetQuestionnaire.id &&
      answer.questionnaireId !== documentsQuestionnaire.id
  )
}

function* saveEncounterAndResponse(action) {
  const { id: encounterID, type: encounterType, subject } = yield select(
    getCurrentEncounter
  )
  const code = encounterType?.coding?.[0]?.code

  const {
    patientID,
    practitionerID,
    answers,
    actions,
    planDefinitionId
  } = action.payload

  // if we are booking an appointment, at first the encounter has no patient.
  // We want to update it with the lines below
  if (subject.reference === 'Patient/null') {
    const encounter = yield select(getEncounterFromId, encounterID)
    const updatedEncounter = {
      ...encounter,
      subject: {
        reference: `Patient/${patientID}`
      }
    }
    yield call(saveFhirResourceWorker, updatedEncounter)
  }

  // TODO : this questionnaireResponse should be identified with its questionnaireId or an identifier
  // TODO : Bruno  : I think a.motive should not exist,
  //  as it should respect the questionnaireResponse fhir resource structure
  const questionnaireResponseMotive = answers.find(
    a => a.type === 'questionnaire' && a.motive != null
  )

  const motive =
    questionnaireResponseMotive != null
      ? questionnaireResponseMotive.motive
      : ''
  const clinicalExamItem =
    questionnaireResponseMotive != null
      ? getItemByLinkId(questionnaireResponseMotive.answer, '2.2')
      : null

  // "clinicalExamItem" contains a data about the teleconsultation.
  // if it's false, the patient is alone with the computer.
  // if it's true the pharmacist has to spend time with the patient.
  const clinicalExam = clinicalExamItem?.answer?.[0]?.valueBoolean ?? ''

  const planDefinition = yield select(getPlanDefinitionById(planDefinitionId))

  const planDefinitionCode = planDefinition?.identifier?.find(
    identifier => identifier.system === PLAN_DEFINITION_IDENTIFIER_SYSTEM
  ).value

  const isTeleconsultationPlanDefinition =
    planDefinitionCode === TELECONSULTATION_PLAN_DEFINITION_VALUE

  // In the case of a TLC we proceed the orders, cerfas and documents separately
  // at this moment orders and document pdfs have been saved to the database
  // Although cerfas are not saved yet thus the following action
  // We wait for the upload of cerfas to create a single response
  if (isTeleconsultationPlanDefinition) {
    yield put(saveCerfaAndDocuments(patientID, practitionerID, answers))
  }

  // Then in this case, we don't need to save the responses concerning:
  // - the documents
  // - the cerfas (sick leave, care sheet)
  // - the orders
  // it will be done in the saveCerfaOrdersAndDocuments
  const answersLeft = isTeleconsultationPlanDefinition
    ? yield call(filterOutCerfasOrdersAndDocuments, answers)
    : answers
  yield* answersLeft.map(function*(a) {
    switch (a.type) {
      case 'questionnaire':
        yield put(
          saveQuestionnaireResponse(
            a.answer,
            practitionerID,
            patientID,
            a.questionnaireId
          )
        )
        break

      case 'document':
        yield put(
          saveDocumentReference(
            patientID,
            encounterID,
            practitionerID,
            a.answer
          )
        )
        break
      case 'slot':
        yield put(
          createAppointment({
            ...a.answer,
            patientID,
            motive,
            practitionerID,
            clinicalExam,
            encounterID
          })
        )
        break
      default:
        break
    }
  })
  if (actions != null && planDefinitionId != null) {
    yield put({
      type: ADDING_REQUEST_GROUP,
      payload: {
        actions: actions,
        encounterID: encounterID,
        planDefinitionId: planDefinitionId,
        patientID: patientID,
        practitionerID: practitionerID
      }
    })
  }

  // Then we have to upload the status of the encounter in database.
  // In the case of teleconsultation, we wait for the documents to be uploaded before.
  // Else the documents won't appear on the screen at the end of teleconsultation.
  // Two cases at this point :
  // - either the documents are already uploaded, so we can continue and update encounter
  // - either documents are not uploaded, and we have to wait for the action
  // Note(chazz): orders don't use this process as they are uploaded in React
  // see QuestionnaireMedicationRequests component
  const cerfasUploaded = yield select(getStatusCerfasUploaded)
  if (isTeleconsultationPlanDefinition) {
    if (cerfasUploaded === false) {
      yield take(CERFAS_AND_DOCUMENTS_SAVED)
    }
  }

  if (code === MEDEO_ENCOUNTER_TYPE_PRE_TELECONSULTATION) {
    yield put(stopPreTeleconsultationEncounterAction(encounterID, patientID))
  } else {
    yield put(stopEncounter(encounterID, patientID, '0'))
  }
}

/**
 * Handle the completion of the pre-tlc encounter,
 * to which we add the reference to the appointment
 *
 * @param {*} action
 */
function* stopPreTeleconsultationEncounter(action) {
  const { encounterID } = action.payload
  const encounter = yield select(getEncounterFromId, encounterID)
  const patientID = getIdByReference(encounter.subject.reference)

  const [appointment] = yield select(
    getFutureAppointments(patientID, MEDEO_APPOINTMENT_TYPE_TELECONSULTATION)
  )
  const updatedEncounter = {
    ...encounter,
    ...(appointment != null && {
      appointment: { reference: `Appointment/${appointment.id}` }
    }),
    status: 'finished'
  }

  // Here we are not using the usual stopEncounterWorker as we need to update the encounter
  // with both the finished status and the appointment id it refers to at once
  const savedEncounter = yield call(saveFhirResourceWorker, updatedEncounter)

  if (savedEncounter != null) {
    yield put(deselectEncounter(savedEncounter.id))
  }
}

function* rootSaga() {
  // load when the app is launched. Fhir resources are paginated this method
  // is not reliable if we want every encounters..
  //  yield call(searchFhirResourceWorker, 'Encounter')
  //must be takeEvery otherwise fetching in parallel is not working...
  //yield takeEvery(FETCHING_ENCOUNTERS, fetchEncountersWorker, fhirClient)
  yield takeEvery(
    FETCHING_ENCOUNTERS,
    searchFhirResourceWorker,
    'Encounter',
    ({ payload: p }) => ({
      _sort: '-date',
      status: 'finished',
      subject: p.subjectId,
      ...(p.code && { code: p.code })
    })
  )

  yield takeEvery(
    [STARTING_ENCOUNTER, STARTING_BLUETOOTH_ENCOUNTER],
    startEncounterWorker
  )
  yield takeEvery(STOPPING_ENCOUNTER, stopEncounterWorker)
  yield takeEvery(
    STOPPING_PRETELECONSULTATION_ENCOUNTER,
    stopPreTeleconsultationEncounter
  )
  yield takeEvery(
    [UPDATING_OBSERVATION],
    archiveEncounterWorker,
    a => a.payload
  )
  yield takeEvery([UPDATING_ALL_OBSERVATIONS], archiveEncounterWorkerBis)
  yield takeEvery([UPDATING_ENCOUNTER], saveFhirResourceWorker, a => a.payload)
  yield takeEvery([CANCELING_ENCOUNTER], saveFhirResourceWorker, a => a.payload)
  yield takeEvery(
    GET_INITIAL_ENCOUNTER_PAGE,
    pagination.createPageWorker(
      'Encounter',
      payload => ({
        status: 'finished',
        subject: payload.subjectId,
        _sort: '-date'
      }),
      encounterPageChanged
    )
  )

  yield takeLeading(
    LOAD_MORE_ENCOUNTERS,
    pagination.createLoadMoreWorker('Encounter', encounterPageChanged)
  )
  yield takeEvery(
    STALE_ENCOUNTER_PAGE,
    pagination.paginationStale(getShownEncounters, getInitialEncounterPage)
  )
  yield takeEvery(SAVING_ENCOUNTER_SEQUENCE, saveEncounterAndResponse)
}

export default rootSaga
