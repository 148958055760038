import React, { useContext } from 'react'
import { Trans } from '@lingui/macro'
import { i18nMark } from '@lingui/react/index'
import { Form, Input } from '../../Components'
import { EditPatientContext } from '../containers/EditPatientForm'
import { set } from '../../utils/fhirpath'

const TelecomInputsRow = ({ isPatient, readOnly, ...rest }) => {
  const [resource, setState] = useContext(EditPatientContext)
  const telecoms = isPatient
    ? resource?.telecom
    : resource?.contact?.[0]?.telecom
  // handle dynamic translation for Telecom label according to the system
  // see https://github.com/lingui/js-lingui/issues/177
  // and mostly https://lingui.js.org/ref/react.html?highlight=i18nmark
  const telecomRows = isPatient
    ? [
        { label: i18nMark('mobile'), name: 'mobile', value: '' },
        { label: i18nMark('phone'), name: 'home', value: '' },
        {
          label: i18nMark('email'),
          name: 'email',
          value: ''
        }
      ]
    : [{ label: i18nMark('phone'), name: 'mobile', value: '' }] //we assume that the emergency contact will have one telecom
  telecomRows.map(
    row =>
      // there are two type of phones that we use (home and mobile and they have the same system) but only one type of mail
      (row.value = telecoms?.find(
        t => t.use === row.name || t.system === row.name
      )?.value)
  )

  const handleChange = e => {
    const { value, name } = e.currentTarget
    const index = telecoms.findIndex(i => i.system === name || i.use === name)
    if (index >= 0) {
      telecoms[index].value = value
    } else {
      if (name === 'email') {
        telecoms.push({
          system: name,
          value: value
        })
      } else {
        telecoms.push({
          system: 'phone',
          value: value,
          use: name
        })
      }
    }
    if (isPatient) {
      setState({
        ...resource,
        telecom: telecoms
      })
    } else {
      setState(set(resource, 'contact.item(0).telecom', telecoms))
    }
  }

  return telecomRows.map(row => (
    <Form.Row key={row.name}>
      <Input
        {...rest}
        className="fs-block"
        type={row.name === 'home' || row.name === 'mobile' ? 'tel' : 'email'}
        name={row.name}
        label={<Trans id={row.label} />}
        value={row.value ?? ''}
        onChange={handleChange}
        readOnly={readOnly}
        minLength={
          row.name === 'mobile' || row.name === 'home' ? '10' : undefined
        }
        maxLength={
          row.name === 'mobile' || row.name === 'home' ? '10' : undefined
        }
      />
    </Form.Row>
  ))
}

export default TelecomInputsRow
