/* eslint-disable object-shorthand */
import {
  MEDEO_APPOINTMENT_IDENTIFIER_SYSTEM,
  MEDEO_APPOINTMENT_TYPE_TELECONSULTATION
} from '../utils/codes'
import { v4 as uuid } from 'uuid'
import moment from 'moment'
import { upperFirst } from 'lodash'
import * as Sentry from '@sentry/react'

/**
 * Get participant from its code in an appointment resource
 *
 * @param {*} appointment
 * @param {*} code: the participant code, e.g 'patient'
 */
const getActorFromCode = (appointment, code) => {
  return appointment?.participant?.find?.(p =>
    p.type?.some?.(t => t.coding?.some?.(c => c.code === code))
  )?.actor
}

/**
 * Get participant reference from its code in an appointment resource
 *
 * @param {*} appointment
 * @param {*} code: the participant code, e.g 'patient'
 */
export const getReferenceFromParticipantCode = (appointment, code) => {
  return getActorFromCode(appointment, code)?.reference
}

/**
 * Get participant display from its code in an appointment resource
 *
 * @param {*} appointment
 * @param {*} code: the participant code, e.g 'patient'
 */
export const getDisplayFromParticipantCode = (appointment, code) => {
  return getActorFromCode(appointment, code)?.display
}

/**
 *
 * @param {Patient} patient
 * @param {Slot} slot
 * @param {Practitioner} practitioner
 * @param {Performer} performer
 * @param {PractitionerRole[]} performerRoles
 * @param {Location} location
 * @param {String} description
 * @param {String} patientRef: we might need this reference for the case were the patient
 * we want to refer the appointment to is not saved yet and thus does not have an id yet
 * @param {String} patientDisplay: this can be needed when the user creating the appointment
 * does not have the access to the patient resource (e.g when a practitioner accepts an unscheduled care request)
 * @return {Appointment} appointment */
export const computeAppointment = ({
  patient,
  slot,
  practitioner,
  performer,
  performerRoles,
  location,
  description,
  patientRef,
  patientDisplay
}) => {
  const practitionerSnomedCode =
    performerRoles?.[0]?.specialty?.[0]?.coding?.[0]?.code

  const patientFirstName = patient?.name?.[0]?.given?.[0]
  const patientFamilyName = patient?.name?.[0]?.family
  const practitionerFirstName = performer?.name?.[0]?.given?.[0]
  const practitionerFamilyName = performer?.name?.[0]?.family
  const practitionerPrefix = performer?.name?.[0]?.prefix

  const currentPractitionerFirstName = practitioner?.name?.[0]?.given?.[0]
  const currentPractitionerFamilyName = practitioner?.name?.[0]?.family
  const currentPractitionerPrefix = practitioner?.name?.[0]?.prefix

  return {
    resourceType: 'Appointment',
    identifier: [
      {
        system: MEDEO_APPOINTMENT_IDENTIFIER_SYSTEM,
        value: MEDEO_APPOINTMENT_TYPE_TELECONSULTATION
      }
    ],
    status: 'booked',
    specialty: [
      {
        coding: [
          {
            system: 'http://snomed.info/sct',
            code: practitionerSnomedCode
          }
        ]
      }
    ],
    slot: [
      {
        reference: `Slot/${slot.id}`
      }
    ],
    created: new Date().toISOString(),
    description,
    start: slot.start,
    end: slot.end,
    participant: [
      {
        actor: {
          reference: patientRef ?? `Patient/${patient.id}`,
          display: patientDisplay ?? `${patientFirstName} ${patientFamilyName}`
        },
        type: [
          {
            coding: [
              {
                system:
                  'http://medeo.io/fhir/Identifier/appointment-actor-code',
                code: 'patient'
              }
            ]
          }
        ],
        required: 'required',
        status: 'needs-action'
      },
      {
        actor: {
          reference: `Practitioner/${performer.id}`,
          display: `${practitionerPrefix} ${practitionerFirstName} ${practitionerFamilyName}`
        },
        type: [
          {
            coding: [
              {
                system:
                  'http://medeo.io/fhir/Identifier/appointment-actor-code',
                code: 'practitioner'
              }
            ]
          }
        ],
        required: 'required',
        status: 'accepted'
      },
      {
        actor: {
          reference: `Practitioner/${practitioner.id}`,
          display: `${currentPractitionerPrefix} ${currentPractitionerFirstName} ${currentPractitionerFamilyName}`
        },
        type: [
          {
            coding: [
              {
                system:
                  'http://medeo.io/fhir/Identifier/appointment-actor-code',
                code: 'taking-appointment'
              }
            ]
          }
        ],
        required: 'information-only',
        status: 'accepted'
      },
      {
        actor: {
          reference: `Location/${location?.id}`,
          display: location?.name
        },
        type: [
          {
            coding: [
              {
                system:
                  'http://medeo.io/fhir/Identifier/appointment-actor-code',
                code: 'location'
              }
            ]
          }
        ],
        required: 'required',
        status: 'accepted'
      }
    ],
    requestedPeriod: [
      {
        start: slot.start,
        end: slot.end
      }
    ]
  }
}

/**
 *
 * @param {Slot} slot
 * @param {Patient} patient
 * @param {Practitioner} performer: the practitioner an appointment was asked to
 * @param {[PractitionerRole]} performerRoles: their roles
 * @param {Location} location: where the appointment is being taken
 * @param {Practitioner} practitioner: the current practitioner
 * @param {Encounter} encounter: current encounter
 * @param {String} description: description for the appointment
 * @return {Bundle.Entry[]}
 * */
export const computeScheduledEntries = ({
  performerRoles,
  slot,
  patient,
  performer,
  location,
  practitioner,
  description,
  patientRef
}) => {
  const appointmentFullUrl = `urn:uuid:${uuid()}`

  const appointment = computeAppointment({
    slot,
    performer,
    patient,
    performerRoles,
    practitioner,
    location,
    description,
    patientRef
  })
  const appointmentEntry = {
    resource: appointment,
    fullUrl: appointmentFullUrl,
    request: {
      method: 'POST',
      url: 'Appointment'
    }
  }

  const slotEntry = {
    resource: {
      ...slot,
      status: 'busy'
    },
    request: {
      method: 'PUT',
      url: `Slot/${slot.id}`
    }
  }
  return [appointmentEntry, slotEntry]
}

/**
 * send sms and/or emails to the patient,and the practitioners
 * @param {Practitioner} practitioner
 * @param {Slot} slot
 * @param {Patient} patient
 * @param {Organization} organization
 * @return {Promise<void>}
 */
export const sendBookingNotifications = async ({
  practitioner,
  slot,
  patient,
  organization
}) => {
  try {
    const [patientFirstName] = patient.name?.find(n => n.use === 'usual')?.given
    const patientFamilyName = patient.name?.find(n => n.use === 'usual')?.family
    const patientPhoneNumber = patient.telecom?.find(
      t => t.system === 'phone' && t.use === 'mobile'
    )?.value
    const patientEmail = patient.telecom?.find(t => t.system === 'email')?.value

    const performerEmail = practitioner.telecom?.find(t => t.system === 'email')
      ?.value

    // at this moment the telephone of organization is not supported but it will be
    const organizationPhoneNumber =
      organization.telecom?.find(t => t.system === 'phone')?.value ??
      'aucun_numéro_fourni'

    const [performerFirstName] = practitioner.name?.find(
      n => n.use === 'usual'
    )?.given
    const performerFamilyName = practitioner.name?.find(n => n.use === 'usual')
      ?.family
    const performerPrefix = practitioner.name?.find(n => n.use === 'usual')
      ?.prefix?.[0]

    // First letter of the prefix should be upperCase() and add a dot at the end (dr => Dr.)
    let performerTitle = null
    if (performerPrefix) {
      performerTitle = `${upperFirst(performerPrefix)}.`
    }
    const startAppointment = slot?.start
    const appointmentDateForEmail = moment(startAppointment).format(
      'DD-MM-YYYY'
    )
    const appointmentTime = moment(startAppointment).format('HH:mm')
    const appointmentDateForSMS = moment(startAppointment).format('DD/MM')
    let orgName = ''
    // first letter of the organization name has to be uppercase for the email.
    if (organization && organization.name) {
      const firstLetter = organization.name.charAt(0).toUpperCase()
      const rest = organization.name.slice(1)
      orgName = firstLetter + rest
    }
    // we pass either the number or null
    // null will be handled in the lambda to display something else instead
    const orgStreet = organization.address?.[0]?.line?.[0]
    const orgPostalCode = organization.address?.[0]?.postalCode
    const orgCity = organization.address?.[0]?.city
    const orgCountry = organization.address?.[0]?.country
    const orgPhone = organizationPhoneNumber
    const orgAddressLine =
      orgStreet && orgPostalCode && orgCity && orgCountry
        ? `${orgStreet} ${orgPostalCode} ${orgCity} ${orgCountry}`
        : null
    const sendToPractitionerURL = `${process.env.REACT_APP_APPOINTMENT_BASE_URL}/sendToPractitioner`
    const sendToPatientURL = `${process.env.REACT_APP_APPOINTMENT_BASE_URL}/sendToPatient`

    // Call the email lambda
    await fetch(sendToPractitionerURL, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        performerEmail,
        performerTitle: 'Dr.',
        performerFamilyName,
        performerId: practitioner.id,
        orgName,
        orgPhone,
        appointmentDateForEmail,
        appointmentTime
      })
    })
    // Call the email lambda to send email to patient
    if (patientEmail) {
      await fetch(sendToPatientURL, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          patientEmail,
          patientFirstName,
          patientFamilyName,
          orgName,
          orgPhone,
          orgAddressLine,
          appointmentDateForEmail,
          appointmentTime,
          performerFirstName,
          performerFamilyName,
          performerTitle
        })
      })
    }

    // send appointment SMS
    if (patientPhoneNumber) {
      // lambda URL for sending SMS to patient
      const lambdaUrl = `${process.env.REACT_APP_APPOINTMENT_BASE_URL}/sendSMSToPatient`
      // Call the email lambda
      await fetch(lambdaUrl, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          patientPhoneNumber:
            // sometimes the first 0 in the number is not saved
            patientPhoneNumber.indexOf('0') === 0
              ? patientPhoneNumber
              : '0' + patientPhoneNumber,
          orgName,
          organizationPhoneNumber,
          appointmentTime,
          appointmentDateForSMS
        })
      })
    }
  } catch (e) {
    // we are in a catch block, the exception will appear as handled in Sentry
    Sentry.captureException(e)
  }
}
