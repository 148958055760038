import React from 'react';
import styled from 'styled-components/macro';

const Outer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Centered = ({ children }) => (
  <Outer>
    <Inner>{children}</Inner>
  </Outer>
);

export default Centered;
