import { useSelector } from 'react-redux'
import RequirePermission from '../../Permissions/containers/RequirePermission'
import { getQuestionnaireIdentifierByAction } from '../../PlanDefinition/utils'
import { getQuestionnaireByIdentifier } from '../../Questionnaire/selector'
import QuestionnaireResponse from '../../QuestionnaireResponse/components/QuestionnaireResponse'
import { getResponsesByQuestionnaireAndEncounterAndPatient } from '../../QuestionnaireResponse/ducks'
import { flattenItems } from '../../QuestionnaireResponse/utils'
import { ACTIONS_NEEDING_PERMISSION } from '../utils'
/**
 * Intermediate component to
 * select the questionnaire and response from the planDefinition context in recap,
 * that is when the response wasn't saved yet
 * @param {Object} questionnaireIdentifier: the identifier for the questionnaire
 * @param {Object} planDefinitionContext
 */
export const QuestionnaireResponseActionRecap = ({
  questionnaireIdentifier,
  planDefinitionContext
}) => {
  const questionnaireCode = questionnaireIdentifier?.code

  const questionnaire = useSelector(
    getQuestionnaireByIdentifier(questionnaireCode)
  )
  const questionnaireResponse = planDefinitionContext.sequenceResponse.find(
    sr => sr.questionnaireId === questionnaire.id
  ).answer

  return questionnaireResponse != null ? (
    <QuestionnaireResponse
      questionnaire={questionnaire}
      questionnaireResponse={questionnaireResponse}
    />
  ) : // In the case we did not find any response for this questionnaire, we do not display it
    null
}

/**
 * Intermediate component to
 * Select the questionnaire and response for the current action of the requestGroup
 *
 */
const QuestionnaireResponseAction = ({ action, patient, requestGroup, encounterID }) => {
  const questionnaireIdentifier = getQuestionnaireIdentifierByAction(
    action
  )?.[0]?.code

  const questionnaire = useSelector(
    getQuestionnaireByIdentifier(questionnaireIdentifier)
  )

  const questionnaireResponse = useSelector(
    getResponsesByQuestionnaireAndEncounterAndPatient(questionnaire?.id, patient.id, encounterID)
  )?.[0]

  // We use this indicator to know whether we want to display the title of the questionnaire or
  // not, that is only if the questionnaire has one single item in it
  const itemsLength = flattenItems(questionnaire)?.length

  return questionnaireResponse != null ? (
    // Some questionnaires need permission to be seen by the practitioner
    ACTIONS_NEEDING_PERMISSION.includes(questionnaireIdentifier) ? (
      <RequirePermission resource={questionnaireIdentifier} action="see">
        {itemsLength === 1 && <h4>{questionnaire.title}</h4>}
        <QuestionnaireResponse
          questionnaire={questionnaire}
          questionnaireResponse={questionnaireResponse}
        />
      </RequirePermission>
    ) : (
      <div>
        {itemsLength === 1 && <h4>{questionnaire.title}</h4>}
        <QuestionnaireResponse
          questionnaire={questionnaire}
          questionnaireResponse={questionnaireResponse}
        />
      </div>
    )
  ) : // In the case we did not find any response for this questionnaire, we do not display it
    null
}

export default QuestionnaireResponseAction
