import { combineReducers } from 'redux'
import {
  REMOVING_SHOULD_ALERT_ON_LEAVE,
  SETTING_SHOULD_ALERT_ON_LEAVE,
} from '../actions/navigation'

/**
 * Indicates whether an alert should be displayed when the user navigates
 * to somewhere else using the medeo bar
 * @param {*} state
 * @param {*} action
 * @returns Boolean
 */
export const alertOnLeave = (state = false, action) => {
  const { type } = action
  switch (type) {
    case SETTING_SHOULD_ALERT_ON_LEAVE:
      return true
    case REMOVING_SHOULD_ALERT_ON_LEAVE:
      return false
    default:
      return state
  }
}

/**
 *  The message to be displayed in the confirm popup
 *
 * @param {*} state
 * @param {*} action
 * @returns String
 */
export const message = (state = '', action) => {
  const { type, payload } = action
  switch (type) {
    case SETTING_SHOULD_ALERT_ON_LEAVE:
      return payload.message
    case REMOVING_SHOULD_ALERT_ON_LEAVE:
      return ''
    default:
      return state
  }
}

export default combineReducers({
  alertOnLeave,
  message,
})
