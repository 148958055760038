import upperFirst from 'lodash/upperFirst'

export const conditionBuilder = (value, patientID, name) => {
  const newCondition = {
    resourceType: 'Condition',
    identifier: [
      {
        type: {
          coding: [
            {
              system: `http://medeo.io/fhir/Value-set/${name}`,
              code: name,
              display: `${upperFirst(name.replace('-', ' '))}`,
            },
          ],
        },
      },
    ],
    category: [
      {
        coding: [
          {
            system: 'http://terminology.hl7.org/CodeSystem/condition-category',
            code: 'problem-list-item',
            display: 'Problem List Item',
          },
        ],
      },
    ],
    code: {
      coding: [
        {
          system: `http://medeo.io/${name}`,
          code: value,
        },
      ],
      text: value,
    },
    subject: {
      reference: `Patient/${patientID}`,
    },
  }
  return newCondition
}
