import { call, select, takeEvery } from 'redux-saga/effects'
import { SAVING_PAYMENT_NOTICE, UPDATING_PAYMENT_NOTICE } from './actions'
import { getCurrentOrganizationId } from '../Auth/selectors'
import { saveFhirResourceWorker } from '../Shared/sagas'
import {
  MEDEO_PAYMENT_AMOUNT_SYSTEM,
  MEDEO_PAYMENT_METHOD_SYSTEM,
  MEDEO_PAYMENT_STATUS_SYSTEM,
} from '../utils/codes'

function* savingPaymentNoticeWorker(action) {
  const {
    procedureRequestReference,
    provider,
    providerOrganization,
    price,
    paymentStatus,
    paymentMethod,
  } = action.payload

  const currentOrganizationId = yield select(getCurrentOrganizationId)

  const newPaymentNotice = {
    resourceType: 'PaymentNotice',
    status: 'active',
    request: { reference: procedureRequestReference },
    statusDate: '',
    created: new Date().toISOString(),
    target: { reference: `Organization/${currentOrganizationId}` },
    provider: { reference: provider },
    organization: { reference: providerOrganization },
    paymentStatus: {
      coding: [
        {
          system: MEDEO_PAYMENT_STATUS_SYSTEM,
          code: paymentStatus,
        },
        {
          system: MEDEO_PAYMENT_METHOD_SYSTEM,
          code: paymentMethod,
        },
        {
          system: MEDEO_PAYMENT_AMOUNT_SYSTEM,
          code: price,
        },
      ],
    },
  }
  yield call(saveFhirResourceWorker, newPaymentNotice)
}

function* updatingPaymentNoticeWorker(action) {
  let { paymentNotice } = action.payload

  const paymentStatusIndex = paymentNotice.paymentStatus?.coding?.findIndex(
    c => c.system === 'http://medeo.io/fhir/PaymentNotice/payment-status'
  )
  const paymentAmountIndex = paymentNotice.paymentStatus?.coding?.findIndex(
    c => c.system === 'http://medeo.io/fhir/PaymentNotice/payment-amount'
  )
  paymentNotice.paymentStatus?.coding?.splice(paymentStatusIndex, 1, {
    system: 'http://medeo.io/fhir/PaymentNotice/payment-status',
    code: 'no-payment',
  })

  paymentNotice.paymentStatus?.coding?.splice(paymentAmountIndex, 1, {
    system: 'http://medeo.io/fhir/PaymentNotice/payment-amount',
    code: 0,
  })

  yield call(saveFhirResourceWorker, paymentNotice)
}

function* rootSaga() {
  yield takeEvery(SAVING_PAYMENT_NOTICE, savingPaymentNoticeWorker)
  yield takeEvery(UPDATING_PAYMENT_NOTICE, updatingPaymentNoticeWorker)
}

export default rootSaga
