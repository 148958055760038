import { ReduxState } from '../Shared/ducks/types'
import { Location } from 'fhir-stu3'
export const getAllLocations = (state: ReduxState): Location[] =>
  state.Location.allIds.map((id: string) => state.Location.byId[id])

export const getLocationByOrganizationId = (
  state: ReduxState,
  organizationId: string
) =>
  getAllLocations(state).find(
    l => l.managingOrganization?.reference === `Organization/${organizationId}`
  )

export const getLocationById = (locationId: string) => (state: ReduxState) => {
  return state.Location.byId[locationId]
}
