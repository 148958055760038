import moment from 'moment'
import { FC } from 'react'
import { Trans } from '@lingui/macro'
import { Card, CardContent, Stack, Typography } from '@mui/material'
import { getIdByReference } from '../../Shared/utils'
import { displayFullNameWithTitle } from '../../Shared/display/entity'
import FutureEncounterButtonGroup from './FutureEncounterButtonGroup'
import MUIThemeProvider from '../../Shared/MUIThemeProvider'
import { Appointment, Patient } from 'fhir-stu3'
import CancelAppointmentButton from './CancelAppointmentButton'
import { useSelector } from 'react-redux'
import { getPractitionerById } from '../../Practitioner/ducks'
import { getActiveTeleconsultationBypatientID } from '../../ProcedureRequest/ducks'

const FutureEncounterCard: FC<{
  appointment: Appointment
  patient: Patient
}> = ({ appointment, patient }) => {
  const patientID = patient.id
  if (patientID == null) {
    throw new Error('patientID should be defined')
  }
  const performerRef = appointment.participant.find(p =>
    p.type?.some(t => t.coding?.some(coding => coding.code === 'practitioner'))
  )?.actor?.reference

  const performerId = getIdByReference(performerRef)
  if (patientID == null) {
    throw new Error('patientID should be defined')
  }
  const performer = useSelector(getPractitionerById(performerId))

  const orgName = appointment.participant?.find(p =>
    p.type?.some(t => t.coding?.some(coding => coding.code === 'location'))
  )?.actor?.display

  // Select active teleconsultation from store
  const procedureRequest = useSelector(
    getActiveTeleconsultationBypatientID(patientID)
  )
  return (
    <MUIThemeProvider>
      <Card>
        <CardContent sx={{ padding: '2rem' }}>
          <Stack direction="row" justifyContent="space-between">
            <Stack gap={2}>
              <Stack>
                <Typography variant="h6">
                  Téléconsultation - {displayFullNameWithTitle(performer)}
                </Typography>
                <Typography variant="subtitle1">
                  {moment(appointment.start).format('HH:mm - dddd LL')}
                </Typography>
              </Stack>
              <Stack direction="column">
                <Typography color="#77808e" variant="caption">
                  <Trans>Created by</Trans>
                </Typography>
                <Typography>{orgName}</Typography>
              </Stack>
              <Stack direction="column">
                <Typography color="#77808e" variant="caption">
                  <Trans>Motives</Trans>
                </Typography>
                <Typography>{appointment.description}</Typography>
              </Stack>
            </Stack>
            <Stack justifyContent="space-between">
              <FutureEncounterButtonGroup
                appointment={appointment}
                procedureRequest={procedureRequest}
                patient={patient}
              />
              <CancelAppointmentButton
                practitioner={performer}
                appointment={appointment}
                procedureRequest={procedureRequest}
              />
            </Stack>
          </Stack>
        </CardContent>
      </Card>
    </MUIThemeProvider>
  )
}
//
export default FutureEncounterCard
