const getFhirURLFromEnv = () => {
  // Try to gather the FHIR base URL from the environment.
  let fromEnv = process.env.REACT_APP_FHIR_BASE_URL
  if (!fromEnv) {
    throw new Error('Could not find REACT_APP_FHIR_BASE_URL in environment.')
  }
  // Normalize base path so we always have a trailing slash.
  // Needed, cause new URL('PaymentNotice', 'https://www.example.com/hello')
  // will return https://www.example.com/PaymentNotice instead of https://www.example.com/hello/PaymentNotice,
  // whereas new URL('PaymentNotice', 'https://www.example.com/hello/') will return https://www.example.com/hello/PaymentNotice').
  if (!fromEnv.endsWith('/')) {
    fromEnv += '/'
  }
  return new URL(fromEnv)
}
const getAppointmentURLFromEnv = () => {
  let fromEnv = process.env.REACT_APP_APPOINTMENT_BASE_URL
  if (!fromEnv) {
    throw new Error(
      'Could not find MEDEO_CARE_APPOINTMENT_BASE_URL in environment.'
    )
  }
  if (!fromEnv.endsWith('/')) {
    fromEnv += '/'
  }
  return new URL(fromEnv)
}

export const FhirEnvIsProd = () => {
  // We rely on FHIR to be set to dev/prod.
  return FHIR_BASE_URL.hostname === 'production.fhir.medeo.io'
}

// Returns the IDs for caresheet and document questionnaire.
export const GetFSEQuestionnaireIDs = (): string[] => {
  if (FhirEnvIsProd() === false) {
    return ['85810', '56998']
  }
  return ['17032', '56998']
}

// Returns the IDs for order, paramedical and document questionnaire.
export const GetOrderQuestionnaireIDs = (): string[] => {
  if (FhirEnvIsProd() === false) {
    return ['48828', '49966', '56998']
  }
  return ['6055', '6056', '56998']
}
export const FHIR_BASE_URL = getFhirURLFromEnv()
export const APPOINTMENT_BASE_URL = getAppointmentURLFromEnv()
