export const CREATING_APPOINTMENT = 'CREATING_APPOINTMENT'
export const CANCELLING_APPOINTMENT = 'CANCELLING_APPOINTMENT'
export const FETCHING_APPOINTMENT = 'FETCHING_APPOINTMENT'
export const SEND_MAIL_FAILED = 'SEND_MAIL_FAILED'

/**
 *
 * @param appointmentInformation:
 * { slotId,
     startTime,
     endTime,
     patientID,
     performerId,
     motive,
     autonomousLevel,
     practitionerID
   }
 * @returns {{payload: *, type: string}}
 */
export const createAppointment = appointmentInformation => ({
  type: CREATING_APPOINTMENT,
  payload: appointmentInformation,
})

export const cancelAppointment = (appointment, practitioner) => ({
  type: CANCELLING_APPOINTMENT,
  payload: { appointment, practitioner },
})

export const sendMailFailed = error => ({
  type: SEND_MAIL_FAILED,
  error : error,
})
