import React, { useCallback, useEffect, useState } from 'react'
import { Button, Form, Input, Spinner } from '../../Components'
import { t, Trans } from '@lingui/macro'
import { I18n } from '@lingui/react'
import { useLazySearch } from '../../Shared/hooks'
import Visio from '../components/Visio'
import styled from 'styled-components/macro'

const FallbackContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 5rem;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
`

/**
 * This component will enable the training organizations to connect to the test room
 * opened by another organization in order to check with them their audio and video
 * outputs.
 *
 * @param {organizationId} the current Organization Id
 */
const SelectOrganizationVisio = ({ organizationId }) => {
  const [state, setState] = useState({
    entry: '', // the orgId typed in
    selected: false // whether the user searched for the typed in orgId
  })

  const [search, { data, loading }] = useLazySearch()

  const handleChange = useCallback(e => {
    setState({ entry: e.target.value, selected: false })
  }, [])

  const handleSubmit = useCallback(
    e => {
      e.preventDefault()

      setState({ ...state, selected: true })
    },
    [state]
  )

  useEffect(() => {
    if (state.selected === true && state.entry != null) {
      search('Organization', {
        _id: state.entry
      })
    }
  }, [state.selected, state.entry, search])

  const selectedOrganization = data?.Organization?.[0]

  return (
    <I18n>
      {({ i18n }) => (
        <>
          <Wrapper>
            <Form onSubmit={handleSubmit}>
              <Form.Row>
                <Input
                  label={i18n._(t`Organization Id to connect to`)}
                  onChange={handleChange}
                  required
                  type="number"
                  value={state.entry}
                />
              </Form.Row>
              <Button disabled={loading}>Search...</Button>
            </Form>
          </Wrapper>
          {selectedOrganization != null && state.selected === true && (
            <Visio
              identity={`test_${organizationId}`}
              organizationId={selectedOrganization.id}
            />
          )}
          {loading && <Spinner />}
          {selectedOrganization == null && state.selected === true && (
            <FallbackContainer>
              <Trans>No organization was found for this id.</Trans>
            </FallbackContainer>
          )}
        </>
      )}
    </I18n>
  )
}

export default SelectOrganizationVisio
