import React from 'react'
import { DropDown } from '../../Components'
import { Trans } from '@lingui/macro'

const ObservationViewDropdown = ({
  onModifyComment,
  onRemoveComment,
  onRemoveObservation,
  onRemoveAllObservations,
  hasComment,
  isAlone
}) => {
  const modifyOrAddCommentEl = (
    <div onClick={onModifyComment}>
      {hasComment ? (
        <Trans>Modify comment</Trans>
      ) : (
        <Trans>Add a comment</Trans>
      )}
    </div>
  )
  const removeCommentEl = (
    <div onClick={onRemoveComment}>
      <Trans>Remove Comment</Trans>
    </div>
  )
  const removeObservationEl = (
    <div onClick={onRemoveObservation}>
      <Trans>Remove Measurement</Trans>
    </div>
  )
  const removeAllObservationsEl = (
    <div onClick={onRemoveAllObservations}>
      <Trans>Remove All Measurements</Trans>
    </div>
  )
  const items = [
    removeObservationEl,
    modifyOrAddCommentEl,
    isAlone ? [] : [removeAllObservationsEl],
    ...(hasComment ? [removeCommentEl] : [])
  ]

  return (
    <DropDown direction="left" role="button" tabIndex="1">
      <DropDown.KebabButton color="white" />
      <DropDown.Menu>
        {items.map((i, index) => (
          <DropDown.ListItem key={index}>{i}</DropDown.ListItem>
        ))}
      </DropDown.Menu>
    </DropDown>
  )
}

export default ObservationViewDropdown
