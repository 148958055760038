import { getTypeAndIdFromLocalReference } from '../utils/fhir/index'
import { getCurrentOrganizationId } from '../Auth/selectors'

export const getPractitionerById = id => state => state.practitioners.byId[id]

export const getDeprecatedPractitionerById = (state, id) =>
  state.practitioners.byId[id]

// gets current practitioner and the authentication steps
export const getPractitionerAuthState = state =>
  state.practitioners.authentication

/**
 * get active practitioners from the current organization
 * note that practitioner where active is not defined will be considered
 * as active.
 */
export const getPractitionersByOrganizationId = (state, organizationId) => {
  return (
    state.practitioners.byOrganizationId?.[organizationId]
      ?.map?.(id => state.practitioners.byId[id])
      // We need to check that the practitioner is not null as
      // it can be that a practitionerRole is pointing on a practitioner
      // which does not exist anymore
      .filter(p => p != null && p.active !== false) ?? []
  )
}

/**
 * get active practitioners from the current organization
 * note that practitioner where active is not defined will be considered
 * as active.
 */
export const getPractitionersFromCurrentOrganization = state => {
  const organizationId = getCurrentOrganizationId(state)
  return getPractitionersByOrganizationId(state, organizationId)
}

export const getAssessorFromClinicalImpression = (
  state,
  clinicalImpression
) => {
  if (clinicalImpression == null || clinicalImpression.assessor == null)
    {return null}
  const [, id] = getTypeAndIdFromLocalReference(
    clinicalImpression.assessor.reference
  )
  return getDeprecatedPractitionerById(state, id)
}

export const getAskingPractitionerFromProcedureRequestId = (
  state,
  procedureRequest
) => {
  if (procedureRequest == null) {return null}
  const encounterID = getTypeAndIdFromLocalReference(
    state.ProcedureRequest.allIds
      .map(i => state.ProcedureRequest.byId[i])
      .find(p => p.id === procedureRequest.id).context.reference
  )[1]
  const askingpractitionerID = getTypeAndIdFromLocalReference(
    state.encounters.allIds
      .map(i => state.encounters.byId[i])
      .find(e => e.id === encounterID).participant[0].individual.reference
  )[1]
  return state.practitioners.allIds
    .map(i => state.practitioners.byId[i])
    .find(p => p.id === askingpractitionerID)
}
