import { API, Auth } from 'aws-amplify'

export const fetchRetry = (url, options = {}, retries = 3) => {
  return fetch(url, options)
    .then(res => {
      // if the answer is ok, return the answer
      if (res.ok) {return res}

      // if not, retry one time
      if (retries > 0) {
        return fetchRetry(url, options, retries - 1)
      } else {
        throw new Error(res)
      }
    })
    .catch(err => {
      console.error(err)
      // if catch an error, retry too
      if (retries > 0) {
        return fetchRetry(url, options, retries - 1)
      } else {
        throw new Error(err)
      }
    })
}

export const removeItemAtIndex = (array, i) => [
  ...array.slice(0, i),
  ...array.slice(i + 1),
]

export const createPractitioner = gp => {
  return {
    resourceType: 'Practitioner',
    name: [
      {
        use: 'usual',
        family: gp.lastName,
        given: [gp.firstName],
      },
    ],
    telecom: [
      {
        system: 'email',
        value: gp.email,
        use: 'work',
      },
      {
        system: 'phone',
        value: gp.phone,
        use: 'work',
      },
    ],
    identifier: [
      {
        type: {
          coding: [
            {
              system:
                'http://interopsante.org/fhir/valueset/fr-practitioner-identifier-type',
              version: '1',
              code: 'INS-NIR',
              display: 'NIR',
            },
          ],
        },
        system: 'http://medeo.io/fhir/Identifier/patient-nir',
        value: '',
      },
      {
        type: {
          coding: [
            {
              code: 'RPPS',
              display: 'N° RPPS',
            },
          ],
        },
        system: 'http://medeo.io/fhir/Identifier/numero-rpps',
        value: gp.rpps,
      },
    ],
    address: [
      {
        line: [gp.line],
        city: gp.city,
        postalCode: gp.postalCode,
        country: gp.country,
      },
    ],
  }
}

/**
 * Use this function to send emails
 * @param {string} url
 * @param {object} bodyData - It is the data passed to the lambda
 * @param {string} method
 */
export const sendEmail = (url, bodyData, method) => {
  fetch(url, {
    method: method,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(bodyData),
  })
}
export const getIdByReference = reference => {
  return reference?.split('/')[1]
}

/**
 * This lambda will append the practitioner to the generalPractitioner of the patient, so that the practitioner can access the patient file
 * @param patientID
 * @param organizationId
 * @returns {Promise<void>}
 */
export const appendGeneralPractitioner = async (patientID, organizationId) => {
  return await API.post('fhir', '/fhir/appendGeneralPractitioner', {
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getAccessToken()
        .getJwtToken()}`,
    },
    body: { patientID, organizationId },
  })
}

/**
 * Prevent form submission is the enter key was pressed from
 * within a MultipleSearchInput
 *
 * @param {Event} e
 */
export const preventSubmitOnKeyPressIfRequired = e => {
  if (e.key === 'Enter' && e.target.name === 'multiple_search_input')
    {e.preventDefault()}
}

/**
 * Get search param from the current window location
 *
 * @param {String} param: param we want the value of
 */
export const getParamFromUrl = param => {
  const searchParams = new URLSearchParams(window.location.search)
  const value = searchParams.get(param)

  return value
}
