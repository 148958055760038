import { useState } from 'react'
import styled from 'styled-components'
import { Card, Form, Input } from '../../Components'
import { Trans } from '@lingui/macro'
import RadioGroup from '../../Shared/components/RadioGroup'
import SimpleAddPatient from './SimpleAddPatient'
import QuestionnaireSearchPatient from '../../Questionnaire/components/QuestionnaireSearchPatient'
import BookingRouterFooter from '../containers/BookingFormFooter'
import { Patient } from 'fhir-stu3'
import useBookingContext from '../containers/BookingContext'
import { useNavigate } from '@reach/router'
// @ts-ignore-next-line
const StyledInput = styled(Input)`&:hover {background: ${props => props.readOnly & ''};}`

const CustomCard = styled(Card)`
  padding: 0;
  margin: auto;
  max-width: 54rem;
`

const CustomCardHeader = styled(Card.Header)`
  margin: 2.5rem 2.5rem 0 2.5rem !important;
`

const H3 = styled.h3`
  font-size: 1.75rem;
  margin: 0;
  font-weight: normal;
  line-height: 2rem;
`

const CustomForm = styled(Form)`
  margin: 0 2.5rem 1rem 2.5rem;
`

const AddOrSelectPatient = () => {
  const [disabled, setDisabled] = useState(true)
  const [mode, setMode] = useState('select')
  const { state: bookingState, handleChangePatient } = useBookingContext()
  const patient = bookingState?.patient
  const navigate = useNavigate()
  const handleOptionChange = (e: any) => {
    setDisabled(true)
    setMode(e.target.value)
    handleChangePatient(undefined)
  }
  // We should define that as a type.
  const onPatientChange = (params: any) => {
    const newPatient = params?.patient as Patient
    setDisabled(params.cannotGoNext)
    // Only change the patient if it is different, to avoid infinite loop.
    if (newPatient?.id !== patient?.id) {
      handleChangePatient(newPatient)
    }
  }

  const handleFormSubmit = (e: any) => {
    e.preventDefault()
    navigate('./recap')
  }

  return (
    <CustomCard>
      {/* @ts-ignore */}
      <CustomCardHeader>
        <H3>
          <Trans>Making Appointment</Trans>
        </H3>
      </CustomCardHeader>
      {/* <CustomForm id="booking" onSubmit={handleOptionChange}> */}
      <CustomForm id="booking" onSubmit={handleFormSubmit}>
        {/* @ts-ignore */}
        <RadioGroup>
          <StyledInput
            type="radio"
            label={'Sélectionner un patient'}
            name={'add-or-select'}
            value={'select'}
            onChange={handleOptionChange}
            checked={mode === 'select'}
            data-test="select-patient"
          />
          <StyledInput
            type="radio"
            label={'Créer un nouveau patient'}
            name={'add-or-select'}
            value={'add'}
            onChange={handleOptionChange}
            checked={mode === 'add'}
            data-test="add-patient"
          />
        </RadioGroup>
        {mode === 'add' && (
          <SimpleAddPatient onChange={onPatientChange} />
        )}
        {mode === 'select' && (
          <QuestionnaireSearchPatient
            onSelectChange={onPatientChange} patient={patient}
          />
        )}
      </CustomForm>
      <BookingRouterFooter canNavigateNext={!disabled} />
    </CustomCard>
  )
}
export default AddOrSelectPatient
