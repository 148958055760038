import React from 'react'
import Icon from './Icon'

const Logomark = ({ className }) => (
  <Icon viewBox="0 0 250 250" className={className}>
    <g>
      <path
        d="M156.09,46.63c-12.86,0-23.32,10.46-23.32,23.32v89.74c0,4.29-3.49,7.77-7.77,7.77
			c-4.29,0-7.77-3.49-7.77-7.77v-53.84c0-12.86-10.46-23.32-23.32-23.32c-12.86,0-23.32,10.46-23.32,23.32v17.95
			c0,4.29,3.48,7.77,7.77,7.77c4.29,0,7.77-3.48,7.77-7.77v-17.95c0-4.29,3.49-7.77,7.77-7.77c4.29,0,7.77,3.49,7.77,7.77v53.84
			c0,12.86,10.46,23.32,23.32,23.32c12.86,0,23.32-10.46,23.32-23.32V69.95c0-4.29,3.49-7.77,7.77-7.77s7.77,3.49,7.77,7.77v125.64
			c0,4.29,3.48,7.77,7.77,7.77c4.29,0,7.77-3.48,7.77-7.77V69.95C179.41,57.09,168.95,46.63,156.09,46.63z"
      />
      <circle cx="78.37" cy="150.94" r="7.77" />
    </g>
  </Icon>
)
export default Logomark
