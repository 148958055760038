import { useSearch } from '../../Shared/hooks'
import { PatientContextProvider } from '../usePatient'
import React, { FC } from 'react'
import PatientPage from '../components/PatientPage'
//@ts-ignore
import { Spinner } from '../../Components'

type PatientPageContainerProps = {
  patientID: string
}
const PatientPageContainer: FC<PatientPageContainerProps> = ({ patientID }) => {
  // useSearch allow to add _include params
  const { loading } = useSearch('Patient', {
    _id: patientID,
    _include: 'Patient:general-practitioner'
  })

  // patient will be null if:
  // - the user is not authorized to see the patient's detail
  // - patientID points to another resourceType than a Patient

  if (loading === true) {
    return <Spinner />
  }

  return (
    <PatientContextProvider patientID={patientID}>
      <PatientPage />
    </PatientContextProvider>
  )
}

export default PatientPageContainer
