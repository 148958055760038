import {
  getLoincCoding,
  getLoincCodingFromObservation,
  LOINC_BLOOD_GLUCOSE_CODE,
  LOINC_BODY_TEMPERATURE_CODE,
  LOINC_DIASTOLIC_BLOOD_PRESSURE_CODE,
  LOINC_HEART_RATE_CODE,
  LOINC_OXYGEN_SATURATION_CODE,
  LOINC_RESPIRATORY_RATE_CODE,
  LOINC_SYSTOLIC_BLOOD_PRESSURE_CODE,
  LOINC_PH_CODE,
  LOINC_BILIRUBIN_PRESENCE_CODE,
  LOINC_UROBILINOGEN_PRESENCE_CODE,
  LOINC_KETONES_PRESENCE_CODE,
  LOINC_GLUCOSE_PRESENCE_CODE,
  LOINC_PROTEIN_PRESENCE_CODE,
  LOINC_HEMOGLOBIN_PRESENCE_CODE,
  LOINC_NITRITE_PRESENCE_CODE,
  LOINC_LEUKOCYTE_PRESENCE_CODE,
  LOINC_SPECIFIC_GRAVITY,
} from '../loinc'

class ObservationBuilder {
  constructor(observation) {
    Object.assign(this, observation)
  }
  appendStatus() {
    this.status = 'final'
    return this
  }
  appendNoCategory() {
    this.category = [
      {
        coding: [
          {
            system: 'http://hl7.org/fhir/observation-category',
            code: 'vital-signs',
            display: 'Vital Signs',
          },
        ],
        text: 'Vital Signs',
      },
    ]
    return this
  }
  appendCategory(category) {
    this.category = category
    return this
  }
  appendComment(comment) {
    this.comment = comment
    return this
  }
  /**
   * Append ReferenceRange using LOINC Coding
   * if no LOINC coding is available it will return the observation or the component untouched.
   */
  appendReferenceRangeIfPossible() {
    const coding = getLoincCodingFromObservation(this)
    if (coding == null) {return this}

    switch (coding.code) {
      case LOINC_SYSTOLIC_BLOOD_PRESSURE_CODE:
        this.referenceRange = {
          high: {
            value: 140,
            unit: 'mmHg',
          },
        }
        break
      case LOINC_DIASTOLIC_BLOOD_PRESSURE_CODE:
        this.referenceRange = {
          high: {
            value: 90,
            unit: 'mmHg',
          },
        }
        break
      case LOINC_HEART_RATE_CODE:
        this.referenceRange = {
          high: {
            value: 90,
            unit: 'bpm',
          },
        }
        this.referenceRange = {
          low: {
            value: 60,
            unit: 'bpm',
          },
        }
        break
      case LOINC_OXYGEN_SATURATION_CODE:
        this.referenceRange = {
          low: {
            value: 95,
            unit: '%',
          },
        }
        break
      case LOINC_BODY_TEMPERATURE_CODE:
        this.referenceRange = {
          high: {
            value: 38,
            unit: '°C',
          },
          low: {
            value: 36,
            unit: '°C',
          },
        }
        break
      case LOINC_BLOOD_GLUCOSE_CODE:
        this.referenceRange = {
          high: {
            value: 120,
            unit: 'mg/dL',
          },
          low: {
            value: 70,
            unit: 'mg/dL',
          },
        }
        break
      case LOINC_RESPIRATORY_RATE_CODE:
        this.referenceRange = {
          high: {
            value: 25,
            unit: 'cpm',
          },
        }
        break
      case LOINC_BILIRUBIN_PRESENCE_CODE:
        this.referenceRange = 'neg.'
        break
      case LOINC_UROBILINOGEN_PRESENCE_CODE:
        this.referenceRange = 'norm.'
        break
      case LOINC_KETONES_PRESENCE_CODE:
        this.referenceRange = 'neg.'
        break
      case LOINC_GLUCOSE_PRESENCE_CODE:
        this.referenceRange = 'norm.'
        break
      case LOINC_PROTEIN_PRESENCE_CODE:
        this.referenceRange = 'neg.'
        break
      case LOINC_HEMOGLOBIN_PRESENCE_CODE:
        this.referenceRange = 'neg.'
        break
      case LOINC_PH_CODE:
        this.referenceRange = {
          high: {
            value: 5,
            unit: '',
          },
        }
        break
      case LOINC_NITRITE_PRESENCE_CODE:
        this.referenceRange = 'neg.'
        break
      case LOINC_LEUKOCYTE_PRESENCE_CODE:
        this.referenceRange = 'neg.'
        break
      case LOINC_SPECIFIC_GRAVITY:
        this.referenceRange = '1.000'
        break
      default:
        break
    }
    return this
  }

  appendInterpretationIfPossible() {
    // cannot append if there is no referenceRange.
    // cannot add interpretation if the value is not a Quantity
    if (
      this.referenceRange == null &&
      (this.valueString == null || this.valueQuantity == null)
    )
      {return this}
    if (this.valueQuantity) {
      const { high, low } = this.referenceRange
      const { value, unit } = this.valueQuantity
      if (high != null && value > high.value && high.unit === unit) {
        this.interpretation = {
          coding: [
            {
              system: 'http://hl7.org/fhir/v2/0078',
              code: 'H',
              display: 'High',
            },
          ],
        }
      }

      if (low != null && value < low.value && low.unit === unit) {
        this.interpretation = {
          coding: [
            {
              system: 'http://hl7.org/fhir/v2/0078',
              code: 'L',
              display: 'Low',
            },
          ],
        }
      }
    }
    if (this.valueString) {
      const reference = this.referenceRange
      const value = this.valueString
      if (reference != null && value !== reference) {
        this.interpretation = {
          coding: [
            {
              system: 'http://hl7.org/fhir/v2/0078',
              code: 'H',
              display: 'High',
            },
          ],
        }
      }
    }
    return this
  }

  appendContext(reference) {
    this.context = {
      reference,
    }
    return this
  }
  appendPatient(reference) {
    this.subject = {
      reference,
    }
    return this
  }

  appendPerformer(reference) {
    if (Array.isArray(this.performer)) {
      this.performer.push(reference)
    } else {
      this.performer = [
        {
          reference,
        },
      ]
    }
    return this
  }

  appendLoincCoding(loincCoding) {
    if (this.code == null || Array.isArray(this.code.coding) === false) {
      this.code = {
        coding: [getLoincCoding(loincCoding)],
      }
    } else {
      this.code.coding.push(getLoincCoding(loincCoding))
    }

    return this
  }
  appendMedeoCoding(medeoCoding) {
    this.code = {
      coding: {
        code: medeoCoding,
        system: 'http://medeo.io/fhir/',
      },
    }
    return this
  }
  appendValueQuantity(value, unit) {
    this.valueQuantity = {
      value: value,
      unit: unit,
      system: 'http://unitsofmeasure.org',
    }
    return this
  }
  appendValueString(string) {
    this.valueString = string
    return this
  }
  appendValueAttachment(contentType, title, url) {
    this.valueAttachment = {
      contentType: { value: contentType },
      url: url,
      title: title,
      creation: new Date().toISOString(),
    }
    return this
  }
  appendComponent(component) {
    if (this.component != null && Array.isArray(this.component)) {
      this.component.push(component)
    } else {
      this.component = [component]
    }
    return this
  }

  appendDerivedFrom(reference) {
    if (this.related != null && Array.isArray(this.related)) {
      this.related.push({
        type: 'derived-from',
        target: { reference },
      })
    } else {
      this.related = [
        {
          type: 'derived-from',
          target: { reference },
        },
      ]
    }
    return this
  }
  appendValueTime(valueTime) {
    this.valueTime = valueTime
    return this
  }

  appendEffectiveDateTime(date) {
    this.effectiveDateTime = date.toISOString()
    return this
  }
}

export default ObservationBuilder
