// @ts-nocheck
import React, { FC, useEffect } from 'react'
import styled from 'styled-components/macro'
import { useDispatch, useSelector } from 'react-redux'
import { getCurrentEncounter } from '../selectors'
import { useNavigate } from '@reach/router'
import { createBluetoothEncounter } from '../actions'
import { MEDEO_ENCOUNTER_TYPE_SYSTEM } from '../../utils/codes'
import usePatient from '../../Patient/usePatient'
import usePractitioner from '../../Practitioner/usePractitioner'

const Container = styled.div`
  visibility: hidden;
`

/**
 * This Component start an Encounter when mounted
 * it checks yaml and exam property to find the correct encounter type for the exam
 * @param yaml
 * @param exam
 * @param patient
 * @returns {*}
 * @constructor
 */
const StartEncounterRoute: FC<{ path: string, exam: any }>= ({ path, exam }) => {
  const patient = usePatient()
  const currentPractitioner = usePractitioner()
  const encounter = useSelector(getCurrentEncounter)
  const reduxDispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    if (encounter == null) {
      reduxDispatch(
        createBluetoothEncounter(patient.id, currentPractitioner.id, {
          coding: [
            {
              system: MEDEO_ENCOUNTER_TYPE_SYSTEM,
              code: exam
            }
          ]
        })
      )
    } else {
      const type = encounter?.type?.[0]?.coding?.[0]?.code
      navigate(`../${type}`, {
        replace: true
      })
    }
  }, [
    exam,
    encounter,
    patient.id,
    currentPractitioner.id,
    reduxDispatch,
    navigate
  ])
  return (
    <Container>
      <span role="img" aria-label="ok">
        👍
      </span>{' '}
      starting new {exam}
    </Container>
  )
}

export default StartEncounterRoute
