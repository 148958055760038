import { Trans } from '@lingui/macro'
import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'
import mixpanel from 'mixpanel-browser'
import { StrictMode, useEffect, useReducer, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useEffectOnce } from 'react-use'
import styled from 'styled-components/macro'
import { Button, Card, Form } from '../../Components'
import { getEncounterById } from '../../Encounter/selectors'
import usePlanDefinition from '../../PlanDefinition/usePlanDefinition'
import { PRECONSULTATION_QUESTIONNAIRE_SYSTEM } from '../../PlanDefinition/utils'
import { getResponsesByencounterID } from '../../QuestionnaireResponse/ducks'
import { MIXPANEL_CERFA_EDITED } from '../../Shared/mixpanel'
import { getIdByReference } from '../../Shared/utils'
import {
  MEDEO_CARE_SHEET_QUESTIONNAIRE_IDENTIFIER,
  MEDEO_PREAMBLE_QUESTIONNAIRE_IDENTIFIER_V2
} from '../../utils/codes'
import { buildDefaultResponse, prefillCaresheet } from '../caresheetUtils'
import { getAllQuestionnairesByIdentifierAndSystem } from '../selector'
import {
  initQuestionnaireResponse,
  QuestionnaireResponseContext,
  questionnaireResponseReducer,
  SETTING_QUESTIONNAIRE_RESPONSE
} from './Questionnaire'
import QuestionnaireComponent from './QuestionnaireComponent'

const CustomForm = styled(Form)`
  width: 100%;
`

const FooterQuestionnaire = styled(Card.Footer)`
  border-top: none;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
`

const HeaderQuestionnaire = styled(Card.Header)`
  width: 100%;
  padding-bottom: 0;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  padding-left: 1rem;
`

/**
 *
 * This component encapsulate the QuestionnaireComponent, as the
 * logic behind is specific to the caresheet only.
 *
 */
const CaresheetQuestionnaire = ({
  encounterID,
  patient,
  practitioner,
  ...rest
}) => {
  const { planDefinition, setPlanDefinition } = usePlanDefinition()
  useEffectOnce(() => {
    setTimeout(() => {
      if (document && document.getElementById('topOfForm')) {
        document.getElementById('topOfForm').scrollIntoView()
      }
    }, 200)
  })

  // Here we keep track of the caresheet response from one update to the other
  // This is necessary here to autocomplete the tp AMO and tp AMC fields
  // depending on the answers of the user
  const formerCaresheetResponse = useRef(null)

  const encounter = useSelector(getEncounterById(encounterID))

  const [careSheetQuestionnaire] = useSelector((s) =>
    getAllQuestionnairesByIdentifierAndSystem(
      s,
      MEDEO_CARE_SHEET_QUESTIONNAIRE_IDENTIFIER
    )
  )

  // We need to select the preambule questionnaire response to auto-complete the
  // tp amo and tp amc fields depending on the preamble questionnaire
  // of the teleconsultation
  const [preambleQuestionnaire] = useSelector((s) =>
    getAllQuestionnairesByIdentifierAndSystem(
      s,
      MEDEO_PREAMBLE_QUESTIONNAIRE_IDENTIFIER_V2,
      PRECONSULTATION_QUESTIONNAIRE_SYSTEM
    )
  )

  const preambleQuestionnaireId = preambleQuestionnaire
    ? preambleQuestionnaire.id
    : null

  const preconsultationencounterID =
    encounter != null ? getIdByReference(encounter.partOf.reference) : null

  const preambleResponse = useSelector(
    getResponsesByencounterID(preconsultationencounterID)
  )?.find(
    (r) =>
      r.questionnaire.reference === `Questionnaire/${preambleQuestionnaireId}`
  )

  // Check if the care sheet is already in the plan definition context
  // If so, then it means we are modifying it
  const careSheetIndex =
    careSheetQuestionnaire != null
      ? planDefinition.sequenceResponse.findIndex((questionnaireResponse) => {
          if (!questionnaireResponse.internalResponseId) {
            return (
              questionnaireResponse.questionnaireId ===
              careSheetQuestionnaire.id
            )
          } else {
            return (
              questionnaireResponse.questionnaireId ===
                careSheetQuestionnaire.id &&
              // This is in case of several questionnaires of same type in the same sequence,
              // our case with the care sheet
              questionnaireResponse.internalResponseId ===
                planDefinition.internalResponseId
            )
          }
        })
      : -1

  // We prefill the tp fields depending on the questionnaire preambule
  const defaultResponse = buildDefaultResponse(
    careSheetQuestionnaire,
    preambleQuestionnaire,
    preambleResponse
  )

  // Build up the questionnaire response context
  const [questionnaireResponse, dispatchQuestionnaireResponse] = useReducer(
    questionnaireResponseReducer,
    defaultResponse,
    initQuestionnaireResponse
  )

  // Here we want to set the response only once the preamble response
  // was loaded, so we build it again
  useEffect(() => {
    // If the response was already set a first time,
    // return
    if (formerCaresheetResponse.current != null) {
      return
    }
    if (defaultResponse != null && preambleResponse && careSheetQuestionnaire) {
      // If the caresheet is not in the plan definition yet,
      // we use the default response to prefill it
      if (careSheetIndex < 0) {
        dispatchQuestionnaireResponse({
          type: SETTING_QUESTIONNAIRE_RESPONSE,
          payload: defaultResponse
        })
        formerCaresheetResponse.current = cloneDeep(defaultResponse)
      } else {
        // If it is already in the plan definition,
        // then we use this one
        dispatchQuestionnaireResponse({
          type: SETTING_QUESTIONNAIRE_RESPONSE,
          payload: planDefinition.sequenceResponse[careSheetIndex].answer
        })
        formerCaresheetResponse.current = cloneDeep(
          planDefinition.sequenceResponse[careSheetIndex].answer
        )
      }
    }
  }, [
    preambleResponse,
    defaultResponse,
    preambleQuestionnaire,
    careSheetIndex,
    planDefinition.sequenceResponse,
    careSheetQuestionnaire
  ])

  // Here we handle the questionnaire response change logic
  // As this can repercute on the tp amc / tp amo fields
  const onQuestionnaireResponseChange = (
    questionnaireResponse,
    questionnaireId
  ) => {
    // Do not do anything if the response did not change since its last update
    if (isEqual(formerCaresheetResponse.current, questionnaireResponse)) {
      return
    }

    let updatedSequenceContext = planDefinition

    // Prefill the caresheet depending on the already provided responses
    const updatedResponse = prefillCaresheet(
      careSheetQuestionnaire,
      formerCaresheetResponse,
      questionnaireResponse
    )
    // We update the former version on the caresheet with the new built one
    formerCaresheetResponse.current = cloneDeep(questionnaireResponse)
    if (careSheetIndex === -1) {
      // If no response was found for this questionnaire and this instance,
      // Add a new one to the context
      updatedSequenceContext.sequenceResponse.push({
        type: 'questionnaire',
        answer: updatedResponse,
        questionnaireId: questionnaireId,
        internalResponseId: planDefinition.internalResponseId
      })
    } else {
      // Otherwise we just update it with the new response
      updatedSequenceContext.sequenceResponse[
        careSheetIndex
      ].answer = cloneDeep(updatedResponse)
    }

    setPlanDefinition({ ...updatedSequenceContext })
    dispatchQuestionnaireResponse({
      type: SETTING_QUESTIONNAIRE_RESPONSE,
      payload: updatedResponse
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    mixpanel.track(MIXPANEL_CERFA_EDITED, {
      type: 'feuille de soin',
      encounterID,
      patientID: patient.id,
      practitionerID: practitioner.id
    })

    // On submit, we quit the isolated view specific to the care sheet
    setPlanDefinition({
      ...planDefinition,
      isIsolatedElementView: false,
      internalResponseId: null
    })
    if (document && document.getElementById('topOfForm')) {
      document.getElementById('topOfForm').scrollIntoView()
    }
  }

  return !careSheetQuestionnaire ? (
    <div>Couldn't load caresheet questionnaire</div>
  ) : (
    <StrictMode>
      <QuestionnaireResponseContext.Provider
        value={[questionnaireResponse, dispatchQuestionnaireResponse]}
      >
        <HeaderQuestionnaire id="topOfForm">
          <h3>{careSheetQuestionnaire.title}</h3>
        </HeaderQuestionnaire>
        <CustomForm onSubmit={handleSubmit}>
          {careSheetQuestionnaire.item.map((i) => (
            <QuestionnaireComponent
              item={i}
              key={i.linkId}
              readOnly={rest.readOnly}
              questionnaire={careSheetQuestionnaire}
              onChange={onQuestionnaireResponseChange}
            />
          ))}
          <FooterQuestionnaire>
            <Button
              color="ocean"
              variant="outline"
              data-test="cancel-button-FdS"
              onClick={() => {
                setPlanDefinition({
                  ...planDefinition,
                  isIsolatedElementView: false
                })
                // when we cancel a secondary form on consultation in doctor side
                if (document && document.getElementById('topOfForm')) {
                  document.getElementById('topOfForm').scrollIntoView()
                }
              }}
            >
              <Trans>Cancel</Trans>
            </Button>
            <Button color="ocean" data-test="save-button-FdS">
              <Trans>Save</Trans>
            </Button>
          </FooterQuestionnaire>
        </CustomForm>
      </QuestionnaireResponseContext.Provider>
    </StrictMode>
  )
}

export default CaresheetQuestionnaire
