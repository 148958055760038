import ProfilePicture from '../../Shared/components/ProfilePicture'
import {
  displayFamilyName,
  displayGivenName
} from '../../Shared/display/entity'
import React from 'react'
import styled from 'styled-components/macro'

const HeadContainer = styled.div`
  padding: 2rem 1rem;
  ${p => (p.isRow ? 'padding-bottom: 6rem' : '')};
  display: flex;
  flex-direction: ${p => (p.isRow ? 'row' : 'column')};
  align-items: center;
  justify-content: center;
`

const HeadName = styled.div`
  margin-top: ${props => props.theme.spacing.small};
  text-align: center;
`

const HeadRole = styled.div`
  margin-top: ${props => props.theme.spacing.small};
  font-size: ${props => props.theme.small};
  color: ${props => props.theme.ocean};
`

const WritingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${p => (p.isRow ? 'flex-start' : 'center')};
  justify-content: center;
  ${p => (p.isRow ? 'margin-left: 1.5rem' : '')}
`

const PractitionerHead = ({ img, role, practitioner, onClick, isRow }) => {
  return (
    <HeadContainer isRow={isRow} onClick={onClick}>
      <ProfilePicture src={img} />
      <WritingContainer isRow={isRow}>
        <HeadName>{displayGivenName(practitioner)}</HeadName>
        <HeadName>{displayFamilyName(practitioner)}</HeadName>
        <HeadRole>{role}</HeadRole>
      </WritingContainer>
    </HeadContainer>
  )
}

export default PractitionerHead
