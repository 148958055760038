import { call, put, select, takeEvery } from 'redux-saga/effects'

import { createNotification } from '../Notifications/actions'
import { getCurrentOrganizationId } from '../Auth/selectors'
import {
  saveFhirResourceWorker,
  searchFhirResourceWorker,
} from '../Shared/sagas'
import { UPDATING_ORGANIZATION } from './actions'

function* updateOrganizationWorker(action) {
  const response = yield saveFhirResourceWorker(action.payload)
  if (response != null) {
    yield put(
      createNotification('Organisation correctement modifiée', 'success', 4000)
    )
  } else {
    yield put(
      createNotification(
        "Une erreur est survenue lors de la modification de l'organisation",
        'error',
        4000
      )
    )
  }
}

export default function* watcherSaga() {
  const organizationId = yield select(getCurrentOrganizationId)
  yield call(searchFhirResourceWorker, 'Organization', {
    _id: organizationId,
  })
  yield takeEvery(UPDATING_ORGANIZATION, updateOrganizationWorker)
}
