import React from 'react'
import styled from 'styled-components/macro'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Trans } from '@lingui/macro'
import { Button } from '../../Components'
import ManualObservationForm from '../containers/ManualObservationForm'
import Modal from '../../Shared/components/Modal'

const Container = styled.div`
  padding: 0 2rem;
  overflow-y: auto;
`
const Footer = styled.footer`
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
`

const Separator = styled.div`
  margin: 2rem 0;
  border-bottom: 1px solid ${p => p.theme.nevada};
`

const ManualObservationFormDialog = ({ patientID }) => {
  return (
    <Modal
      title={<Trans>Manually add data</Trans>}
      component={props => (
        <Button {...props} color="ocean" variant="tertiary">
          <FontAwesomeIcon icon={faPlus} />
          &nbsp;
          <Trans>Manually add data</Trans>
        </Button>
      )}
    >
      {close => (
        <Container>
          <ManualObservationForm patientID={patientID} onAfterSubmit={close} />
          <Separator />
          <Footer>
            <Button form="observation" type="submit" color="ocean">
              <Trans>Add</Trans>
            </Button>
          </Footer>
        </Container>
      )}
    </Modal>
  )
}

export default ManualObservationFormDialog
