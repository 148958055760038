import mixpanel from 'mixpanel-browser'

// set of events tracked in mixpanel
// you can append new event at the end of this list

// Relatif au patient
export const MIXPANEL_ADDING_PATIENT = 'adding_patient'
export const MIXPANEL_PATIENT_ADDED = 'patient_added'
export const MIXPANEL_EDITING_PATIENT = 'editing_patient'
export const MIXPANEL_PATIENT_EDITED = 'edited_patient'

export const MIXPANEL_PATIENT_DETAILS_READ = 'patient_details_read'

// Relatif à la TLC
export const MIXPANEL_VISIO_LEFT = 'visio_left'
export const MIXPANEL_VISIO_JOINED = 'visio_joined'

export const MIXPANEL_CHARGE_ACCEPTED = 'charge_accepted'
export const MIXPANEL_CHARGE_DECLINED = 'charge_declined'
export const MIXPANEL_CERFA_EDITED = 'cerfa_edited'

export const MIXPANEL_APPOINTMENT_SAVED = 'appointment_saved'
export const MIXPANEL_APPOINTMENT_CANCELED = 'appointment_canceled'

export const MIXPANEL_STARTING_PRECONSULTATION = 'starting_preconsultation'
export const MIXPANEL_PHYSICAL_EXAM = 'physical_exam'

export const MIXPANEL_TELECONCULTATION_STARTED = 'teleconsultation_started'
export const MIXPANEL_TELECONCULTATION_FINISHED = 'teleconsultation_finished'

// Mixpanel is used to track usage of the app
// In order to make active users reports we identify users and organizations
// for Medeo it is irrelevant to track everything on a user level:
// in an Organization some people won't use the platform on a daily/weekly basis
// i.e. in a Pharmacy, there might be one user dedicated to use the app
// we prefer to think about active organization instead of active practitioner
// identifyUser method register the organization as a super property on mixpanel
export const identifyUser = (practitioner, organization, newRole) => {
  // these elements are legacy,
  // they are primarily use to register details for new users on Mixpanel
  const lastName = practitioner?.name?.[0]?.family
  const firstName = practitioner?.name?.[0]?.given?.[0]
  const email = practitioner?.telecom?.find(t => t.system === 'email')?.value
  let specialty
  for (let s of newRole?.[0]?.specialty) {
    if (s.coding?.[0]?.system === 'http://snomed.info/sct') {
      specialty = s.coding?.[0]?.display
      break
    }
  }

  // calls to Identify can be deduped:
  // When a single user does multiple actions such as remote consultation, etc..
  // They may be asked to type their pin again.
  // we can check if the distinct id used by Mixpanel is already set or not
  const currentDistinctId = mixpanel.get_distinct_id()
  // if we switch for user A to User B, we call reset and identify the new user
  if (currentDistinctId !== practitioner.id) {
    mixpanel.reset()
    mixpanel.identify(practitioner.id)
  }
  // register makes the props available on every subsequent requests
  mixpanel.register({
    organization: organization,
  })
  mixpanel.people.set({
    $last_login: new Date(),
    $email: email,
    $first_name: firstName,
    $last_name: lastName,
    specialty: specialty,
  })
}
// create an instance of the mixpanel client for Medeo
// REACT_APP_MIXPANEL_TOKEN should resolve to the following project
// https://mixpanel.com/settings/project/2122135
mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {
  protocol: 'https',
  api_host: 'https://api.mixpanel.com',
})

export default mixpanel
