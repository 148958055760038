import { shallowEqual, useSelector } from 'react-redux'
import { Trans } from '@lingui/macro'
import { getPractitionersFromCurrentOrganization } from '../selector'
import React from 'react'
import PractitionerTable, { Row } from '../components/PractitionerTable'
import PractitionerRow from '../components/PractitionerRow'

const TeamTableHeaderRow = () => (
  <Row>
    <th />
    <th>
      <Trans>Name</Trans>
    </th>
    <th>
      <Trans>Profession</Trans>
    </th>
    <th>
      <Trans>Role</Trans>
    </th>
  </Row>
)

/**
 * The two Component bellow (TeamTable and OcTable) are separated because their selectors are very long to execute.
 * Separate them is a good way to display the fastest without waiting the slowest.
 * @returns {*}
 * @constructor
 */
const TeamTable = () => {
  // shallow equal prevent re-render when selectors return objects
  // see https://react-redux.js.org/api/hooks#equality-comparisons-and-updates
  const practitioners = useSelector(
    getPractitionersFromCurrentOrganization,
    shallowEqual
  )
  return (
    <PractitionerTable
      practitioners={practitioners}
      headerRow={TeamTableHeaderRow}
    >
      {practitioner => (
        <PractitionerRow key={practitioner.id} practitioner={practitioner} />
      )}
    </PractitionerTable>
  )
}

export default TeamTable
