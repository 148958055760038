import React from 'react'
import styled from 'styled-components/macro'

const Screen = styled.div`
  width: 100%;
  height: calc(100% - 60px);
  display: flex;
  justify-content: center;
  flex-direction: column;
`

const AudioBar = styled.div`
  height: ${p => p.height}%;
  width: 2px;
  background: ${p => p.theme.ocean};
  align-self: center;
  margin: 2px;
  border-radius: 2px;
  z-index: 10;
`

const AudioDiv = styled.div`
  display: flex;
  position: absolute;
  bottom: 60px;
  right: 0;
  height: 50px;
  width: 50px;
  justify-content: center;
  align-items: center;
  z-index: 2;
`

const LocalVideo = styled.div`
  width: 150px;
  height: 100px;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  transform: scale(-1, 1);
`

const Top = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const ScreenVideo = ({
  audioLevel,
  localMediaContainer,
  remoteMediaContainer,
  status
}) => {
  // Compute percentage from the audioLevel, which can go from [0, 10]
  const relativeAudioLevel = Math.ceil(audioLevel * 10)

  // If sound is null or nearly null, display 5% heigh bars still
  const middleBarHeight = Math.max(relativeAudioLevel, 5)
  const sideBarHeight = Math.max(relativeAudioLevel / 2, 5)

  return (
    <Screen ref={remoteMediaContainer}>
      <Top>
        <LocalVideo ref={localMediaContainer} />
      </Top>
      {status === 'connected' && (
        <AudioDiv>
          <AudioBar height={sideBarHeight} />
          <AudioBar height={middleBarHeight} />
          <AudioBar height={sideBarHeight} />
        </AudioDiv>
      )}
    </Screen>
  )
}

export default ScreenVideo
