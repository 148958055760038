import React from 'react'
import styled from 'styled-components/macro'
import { faPhone, faPhoneSlash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Spinner } from '../../Components'

const Container = styled.div`
  width: 100%;
  bottom: 0;
  height: 60px;
  background-color: white;
  justify-content: center;
  align-items: center;
  display: flex;
`

const ConnectButton = ({ status, onStart, onClose }) => {
  switch (status) {
    case 'connected':
      return (
        <Button color="scarlett" variant="toggle-secondary" onClick={onClose}>
          <FontAwesomeIcon icon={faPhoneSlash} />
        </Button>
      )
    case 'disconnected':
      return (
        <Button color="emerald" variant="toggle-secondary" onClick={onStart}>
          <FontAwesomeIcon icon={faPhone} />
        </Button>
      )
    case 'connecting':
      return <Spinner />
    default:
      return null
  }
}

const CommandPanel = ({ status, onStart, onClose }) => {
  return (
    <Container>
      <ConnectButton onClose={onClose} onStart={onStart} status={status} />
    </Container>
  )
}

export default CommandPanel
