import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Trans } from '@lingui/macro'
import { useDispatch, useSelector } from 'react-redux'
import { useEffectOnce } from 'react-use'
import React from 'react'
import styled from 'styled-components/macro'
import moment from 'moment'

import {
  displayEncounterType,
  displayParticipantName
} from '../../Shared/display/encounter'
import { cancelEncounter, deselectEncounter } from '../actions'
import { getIdByReference } from '../../Shared/utils'
import { getParticipantFromencounterID } from '../selectors'
import { search } from '../../Shared/actions'
import { getAppointmentById } from '../../Appointment/selectors'
import { Button } from '../../Components'

const Header = styled.div`
  display: flex;
  background: transparent;
  padding: 0;
  outline: none;
  border-color: transparent;
  text-align: left;
  flex-direction: row;
  justify-content: space-around;
  align-items: baseline;
  & > :not(:last-child) {
    margin-bottom: 0.5rem;
  }
`

const Practitioner = styled.div`
  padding-top: ${props => props.theme.spacing.small};
  font-size: ${props => props.theme.small};
  color: #77808e;
`

const EncounterType = styled.div`
  font-size: ${props => props.theme.large};
`

const LeftHeader = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 8;
`

const RightHeader = styled.div`
  background: transparent;
  border-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-size: ${props => props.theme.large};
  flex-grow: 0;
  & > * + * {
    margin-top: 1rem;
  }
`

const Badge = styled.div`
  font-size: small;
  cursor: default;
  background: #979797;
  color: white;
  border-radius: 0.25rem;
  padding: 0.5rem;
  text-transform: uppercase;
`

const EncounterHeader = ({
  encounter,
  readOnly,
  collapsed,
  onClick,
  isCollapsable,
  ...props
}) => {
  const dispatch = useDispatch()
  const participant = useSelector(getParticipantFromencounterID(encounter.id))

  const handleCancel = () => {
    dispatch(deselectEncounter())
    dispatch(cancelEncounter({ ...encounter, status: 'entered-in-error' }))
  }
  const appointmentId = getIdByReference(encounter.appointment?.reference)
  const appointmentLinked = useSelector(getAppointmentById(appointmentId))
  useEffectOnce(() => {
    if (appointmentId && appointmentLinked == null)
      {dispatch(search('Appointment', { _id: appointmentId }))}
  })
  const isAppointmentCancelled =
    appointmentLinked != null && appointmentLinked.status === 'cancelled'

  return (
    <Header {...props}>
      <LeftHeader>
        <EncounterType>
          <span>{encounter.type && displayEncounterType(encounter)}</span>
          {encounter.type?.[0]?.coding?.[0]?.code === 'booking-appointment' && (
            <>
              <br />
              <span style={{ fontSize: 'small' }}>
                <Trans>Remote consultation</Trans> <Trans>of</Trans>{' '}
                {moment(appointmentLinked?.start)
                  .format('LL')
                  .toLocaleLowerCase()}{' '}
                <Trans>at</Trans>{' '}
                {moment(appointmentLinked?.start)
                  .format('HH[h]mm')
                  .toLocaleLowerCase()}
              </span>
            </>
          )}
        </EncounterType>
        <div style={{ display: 'flex', alignItems: 'baseline' }}>
          <Practitioner>{displayParticipantName(participant)}</Practitioner>
        </div>
      </LeftHeader>
      <RightHeader
        {...(readOnly === false && { onClick: handleCancel, as: 'button' })}
      >
        {isAppointmentCancelled && (
          <Badge
            title={moment(appointmentLinked?.meta?.lastUpdated)
              .calendar()
              .toLocaleLowerCase()}
          >
            <Trans>Cancelled appointment</Trans>
          </Badge>
        )}
        {isCollapsable && (
          <Button
            color="ocean"
            variant="secondary"
            style={{ fontSize: 'small' }}
            onClick={onClick}
          >
            {collapsed ? (
              <>
                <Trans>More details</Trans>&nbsp;&nbsp;
                <FontAwesomeIcon icon={faChevronDown} />{' '}
              </>
            ) : (
              <>
                <Trans>Less details</Trans>&nbsp;&nbsp;{' '}
                <FontAwesomeIcon icon={faChevronUp} />
              </>
            )}
          </Button>
        )}
      </RightHeader>
    </Header>
  )
}

EncounterHeader.defaultProps = {
  isCollapsable: true
}

export default EncounterHeader
