import { lighten } from 'polished'
import { css } from 'styled-components'
export const hoverMixin = css`
  &:hover {
    transition: 300ms ease-out all;
    border-color: ${p => p.theme.ocean};
    background: ${p => lighten(0.25, p.theme.ocean)};
    box-shadow: ${p => p.theme.boxShadow};
  }
`
