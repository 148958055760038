import React from 'react'
import styled from 'styled-components'
import Attachment from '../../Attachment/components/Attachment'
import AttachmentDownloadControl from '../../Attachment/components/AttachmentDownloadControl'
import AttachmentPrintControl from '../../Attachment/components/AttachmentPrintControl'
import AttachmentViewControl from '../../Attachment/components/AttachmentViewControl'

const Div = styled.div`
  flex: 0 0 20%;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
`

const AttachmentAnswer = ({ value }) => {
  return (
    <Div>
      <Attachment attachment={value}>
        <AttachmentDownloadControl attachment={value} />
        <AttachmentPrintControl attachment={value} />
        <AttachmentViewControl attachment={value} />
      </Attachment>
    </Div>
  )
}
AttachmentAnswer.defaultProps = {
  value: null,
}
export default AttachmentAnswer
