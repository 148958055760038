import { useI18n } from '../utils/I18nHookAdapter'
import {
  ADMINISTATOR,
  AMBULANCE_DRIVER_ESANTE,
  ANAESTHESIA_SPECIALTY,
  ANATOMY_SPECIALTY,
  AUDIOPROSTHESIST_ESANTE,
  CARDIAC_SURGERY_SPECIALTY,
  CARDIOLOGY_SPECIALTY,
  CHILD_PSYCHIATRY_SPECIALTY,
  CHILD_SURGERY_SPECIALTY,
  CHILDCARE_ASSISTANT_ESANTE,
  CHIROPRACTOR_ESANTE,
  CHRONIC_PATHOLOGY_SPECIALTY,
  DENTAL_ASSISTANT_ESANTE,
  DENTIST_ESANTE,
  DENTOFACIAL_ORTHOPAEDIC_SPECIALTY,
  DERMATOLOGY_SPECIALTY,
  DIETITIAN_ESANTE,
  DOMESTIC_HELPER_ESANTE,
  ENDOCRINOLOGY_SPECIALTY,
  ENT_OPHTHALMOLOGY_SPECIALTY,
  ENT_SPECIALTY,
  EPITHESIST_ESANTE,
  FACE_SURGERY_SPECIALTY,
  GASTROENTEROLOGY_SPECIALTY,
  GENERAL_MEDECINE_SPECIALIST_SPECIALTY,
  GENERAL_MEDECINE_SPECIALTY,
  GENERAL_MEDICINE_QUALIFIED_SPECIALTY,
  GENERAL_PRACTITIONER_ESANTE,
  GENERAL_SURGERY_SPECIALTY,
  GENETIC_ADVISOR_ESANTE,
  GERIATRICS_SPECIALTY,
  GYNAECOLOGY_FIRST_OPTION_SPECIALTY,
  GYNAECOLOGY_SECOND_OPTION_SPECIALTY,
  HEALTH_SPECIALTY,
  HEMATOLOGY_BLOOD_DISEASE_SPECIALTY,
  HEMATOLOGY_ONCOLOGY_SPECIALTY,
  HEMATOLOGY_SPECIALTY,
  HEMATOLOGYVIGILANCE_SPECIALTY,
  INTENSIVE_CARE_SPECIALTY,
  INTERNAL_MEDICINE_SPECIALTY,
  MEDICAL_ASSISTANT_ESANTE,
  MEDICAL_BIOLOGY_SPECIALTY,
  MEDICAL_GENETIC_SPECIALTY,
  MEDICAL_GYNAECOLOGY_SPECIALTY,
  MEDICAL_INVESTIGATION_SPECIALTY,
  MEDICAL_LAB_ASSISTANT_ESANTE,
  MEDICAL_ONCOLOGY_SPECIALTY,
  MEDICAL_PHYSICIST_ESANTE,
  MEMBER,
  MENTAL_HEALTH_SPECIALTY,
  MIDWIFE_ESANTE,
  NEPHROLOGY_SPECIALTY,
  NEUROLOGY_SPECIALTY,
  NEUROPSYCHIATRY_SPECIALTY,
  NEUROSURGERY_SPECIALTY,
  NUCLEAR_MEDICINE_SPECIALTY,
  NURSE_ESANTE,
  NURSING_ASSISTANT_ESANTE,
  OBSTETRIC_SPECIALTY,
  OCCUPATIONAL_MEDICINE_SPECIALTY,
  OCCUPATIONAL_THERAPIST_ESANTE,
  OCULARIST_ESANTE,
  ONCOLOGY_NURSE_SPECIALTY,
  ONCOLOGY_SPECIALTY,
  OPHTHALMOLOGY_SPECIALTY,
  OPTICIAN_ESANTE,
  ORAL_DENTAL_MEDICINE_SPECIALTY,
  ORAL_SURGERY_SPECIALTY,
  ORTHOPAEDIC_SURGERY_SPECIALTY,
  ORTHOPEDIST_ESANTE,
  ORTHOPROSTHESIST_ESANTE,
  ORTHOPTIST_ESANTE,
  OSTEAOPATH_ESANTE,
  OTORHINOLARYNGOLOGY_SPECIALTY,
  PEDIATRICS_SPECIALTY,
  PEDORTHIST_ESANTE,
  PERSONAL_CARER_ASSISTANT_ESANTE,
  PHARMACEUTICAL_ASSISTANT_DISPENSARY_ESANTE,
  PHARMACEUTICAL_ASSISTANT_HOSPITAL_ESANTE,
  PHARMACIST_ESANTE,
  PHARMACOVIGILANCE_SPECIALTY,
  PHYSICAL_MEDICINE_SPECIALTY,
  PHYSIOTHERAPIST_ESANTE,
  PLASTIC_SURGERY_SPECIALTY,
  PODIATRIST_ESANTE,
  PSYCHIATRIST_NURSE_ESANTE,
  PSYCHIATRY_SPECIALTY,
  PSYCHOLOGICAL_ASSISTANT_ESANTE,
  PSYCHOLOGIST_ESANTE,
  PSYCHOMOTOR_THERAPIST_ESANTE,
  PSYCHOTHERAPIST_ESANTE,
  PUBLIC_HEALTH_SPECIALTY,
  PULMONOLGY_SPECIALTY,
  RADIO_ONCOLOGY_SPECIALTY,
  RADIO_OPERATOR,
  RADIO_PHARMACY_SPECIALTY,
  RENAL_DISEASE_SPECIALTY,
  RHEUMATOLOGY_SPECIALTY,
  SECRETARY_ESANTE,
  SOCIAL_CARE_ASSISTANT_ESANTE,
  SOCIAL_INTERVENTION_ASSISTANT_ESANTE,
  SPEECH_THERAPIST_ESANTE,
  STOMACH_SURGERY_SPECIALTY,
  STOMATOLOGY_SPECIALTY,
  STOMATOLOGY_SURGERY_SPECIALTY,
  UROLOGICAL_SURGERY_SPECIALTY,
  VASCULAR_SURGERY_SPECIALTY,
  X_RAY_DIAGNOSIS_SPECIALTY,
  X_RAY_SPECIALTY,
  X_RAY_THERAPY_SPECIALTY,
} from '../Permissions/roles'
import { t } from '@lingui/macro'

// roles are often use inside select and option tags
// we cannot use <Trans> tag as it result in [Object object] :/
// this hook simplify the translation.
const useTranslateRole = role => {
  const i18n = useI18n()
  const translate = value => {
    switch (value) {
      case CHRONIC_PATHOLOGY_SPECIALTY:
        return i18n._(t`Chronic pathology`)
      case ONCOLOGY_NURSE_SPECIALTY:
        return i18n._(t`Oncology`)
      case RENAL_DISEASE_SPECIALTY:
        return i18n._(t`Renal disease`)
      case MENTAL_HEALTH_SPECIALTY:
        return i18n._(t`Mental health`)
      case ANATOMY_SPECIALTY:
        return i18n._(t`Anatomy and pathological cytology`)
      case ANAESTHESIA_SPECIALTY:
        return i18n._(t`Anaesthesia and intensive care`)
      case MEDICAL_BIOLOGY_SPECIALTY:
        return i18n._(t`Medical biology`)
      case CARDIOLOGY_SPECIALTY:
        return i18n._(t`Cardiology and vascular disease`)
      case GENERAL_SURGERY_SPECIALTY:
        return i18n._(t`General surgery`)
      case FACE_SURGERY_SPECIALTY:
        return i18n._(t`Maxillofacial surgery`)
      case STOMATOLOGY_SURGERY_SPECIALTY:
        return i18n._(t`Maxillofacial surgery and stomatology`)
      case ORTHOPAEDIC_SURGERY_SPECIALTY:
        return i18n._(t`Orthopaedic surgery and traumatology`)
      case CHILD_SURGERY_SPECIALTY:
        return i18n._(t`Child surgery`)
      case PLASTIC_SURGERY_SPECIALTY:
        return i18n._(t`Plastic and reconstrive surgery`)
      case CARDIAC_SURGERY_SPECIALTY:
        return i18n._(t`Thoracic and cardiovascular surgery`)
      case UROLOGICAL_SURGERY_SPECIALTY:
        return i18n._(t`Urological surgery`)
      case VASCULAR_SURGERY_SPECIALTY:
        return i18n._(t`Vascular surgery`)
      case STOMACH_SURGERY_SPECIALTY:
        return i18n._(t`Stomach and digestive surgery`)
      case DERMATOLOGY_SPECIALTY:
        return i18n._(t`Dermatology and venereology`)
      case ENDOCRINOLOGY_SPECIALTY:
        return i18n._(t`Endocrinology and metabolism`)
      case MEDICAL_GENETIC_SPECIALTY:
        return i18n._(t`Medical genetic `)
      case GERIATRICS_SPECIALTY:
        return i18n._(t`Geriatrics`)
      case MEDICAL_GYNAECOLOGY_SPECIALTY:
        return i18n._(t`Medical gynaecology`)
      case OBSTETRIC_SPECIALTY:
        return i18n._(t`Obstetric-gynaecology`)
      case HEMATOLOGY_SPECIALTY:
        return i18n._(t`Hematology`)
      case HEMATOLOGY_BLOOD_DISEASE_SPECIALTY:
        return i18n._(t`Hematology (blood disease option)`)
      case HEMATOLOGY_ONCOLOGY_SPECIALTY:
        return i18n._(t`Hematology (oncology and hematology option)`)
      case GASTROENTEROLOGY_SPECIALTY:
        return i18n._(t`Gastroenterology and hepatology`)
      case OCCUPATIONAL_MEDICINE_SPECIALTY:
        return i18n._(t`Occupational medicine`)
      case GENERAL_MEDICINE_QUALIFIED_SPECIALTY:
        return i18n._(t`General medicine qualified`)
      case INTERNAL_MEDICINE_SPECIALTY:
        return i18n._(t`Internal medicine`)
      case NUCLEAR_MEDICINE_SPECIALTY:
        return i18n._(t`Nuclear medicine`)
      case PHYSICAL_MEDICINE_SPECIALTY:
        return i18n._(t`Physical medicine and rehabilitation`)
      case NEPHROLOGY_SPECIALTY:
        return i18n._(t`Nephrology`)
      case NEUROSURGERY_SPECIALTY:
        return i18n._(t`Neurosurgery`)
      case NEUROLOGY_SPECIALTY:
        return i18n._(t`Neurology`)
      case NEUROPSYCHIATRY_SPECIALTY:
        return i18n._(t`Neuropsychiatry`)
      case ENT_SPECIALTY:
        return i18n._(t`ENT and cervical facial surgery`)
      case ONCOLOGY_SPECIALTY:
        return i18n._(t`Oncology (option oncology hematology)`)
      case MEDICAL_ONCOLOGY_SPECIALTY:
        return i18n._(t`Oncology medical option`)
      case RADIO_ONCOLOGY_SPECIALTY:
        return i18n._(t`Oncology radiotherapy option`)
      case OPHTHALMOLOGY_SPECIALTY:
        return i18n._(t`Ophthalmology`)
      case OTORHINOLARYNGOLOGY_SPECIALTY:
        return i18n._(t`Otorhinolaryngology`)
      case PEDIATRICS_SPECIALTY:
        return i18n._(t`Pediatrics`)
      case PULMONOLGY_SPECIALTY:
        return i18n._(t`Pulmonology`)
      case PSYCHIATRY_SPECIALTY:
        return i18n._(t`Psychiatry`)
      case CHILD_PSYCHIATRY_SPECIALTY:
        return i18n._(t`Psychiatry child and teenager option`)
      case X_RAY_DIAGNOSIS_SPECIALTY:
        return i18n._(t`Radiodiagnosis`)
      case X_RAY_THERAPY_SPECIALTY:
        return i18n._(t`Radiotherapy`)
      case INTENSIVE_CARE_SPECIALTY:
        return i18n._(t`Intensive care`)
      case MEDICAL_INVESTIGATION_SPECIALTY:
        return i18n._(t`Medical investigation`)
      case RHEUMATOLOGY_SPECIALTY:
        return i18n._(t`Rheumatology`)
      case PUBLIC_HEALTH_SPECIALTY:
        return i18n._(t`Public health and social medicine`)
      case STOMATOLOGY_SPECIALTY:
        return i18n._(t`Stomatology`)
      case GYNAECOLOGY_FIRST_OPTION_SPECIALTY:
        return i18n._(
          t`Obstetric gynaecology and medical gynaecology first option`
        )
      case GYNAECOLOGY_SECOND_OPTION_SPECIALTY:
        return i18n._(
          t`Obstetric gynaecology and medical gynaecology second option`
        )
      case GENERAL_MEDECINE_SPECIALIST_SPECIALTY:
        return i18n._(t`General medicine specialist`)
      case GENERAL_MEDECINE_SPECIALTY:
        return i18n._(t`General medicine`)
      case X_RAY_SPECIALTY:
        return i18n._(t`Radiodiagnosis and radiotherapy`)
      case ENT_OPHTHALMOLOGY_SPECIALTY:
        return i18n._(t`ENT and ophthalmology`)
      case DENTOFACIAL_ORTHOPAEDIC_SPECIALTY:
        return i18n._(t`Dentofacial orthopaedic`)
      case ORAL_SURGERY_SPECIALTY:
        return i18n._(t`Oral surgery`)
      case ORAL_DENTAL_MEDICINE_SPECIALTY:
        return i18n._(t`Oral dental medicine`)
      case RADIO_PHARMACY_SPECIALTY:
        return i18n._(t`Radio pharmacy`)
      case HEALTH_SPECIALTY:
        return i18n._(t`Health`)
      case PHARMACOVIGILANCE_SPECIALTY:
        return i18n._(t`Pharmacovigilance`)
      case HEMATOLOGYVIGILANCE_SPECIALTY:
        return i18n._(t`Hematology vigilance`)
      case GENERAL_PRACTITIONER_ESANTE:
        return i18n._(t`General Practitioner`)
      case PHARMACIST_ESANTE:
        return i18n._(t`Pharmacist`)
      case AUDIOPROSTHESIST_ESANTE:
        return i18n._(t`Audioprosthesist`)
      case OPTICIAN_ESANTE:
        return i18n._(t`Optician`)
      case DENTAL_ASSISTANT_ESANTE:
        return i18n._(t`Dental assistant`)
      case MEDICAL_PHYSICIST_ESANTE:
        return i18n._(t`Medical physicist`)
      case NURSING_ASSISTANT_ESANTE:
        return i18n._(t`Nursing assistant`)
      case AMBULANCE_DRIVER_ESANTE:
        return i18n._(t`Ambulance driver`)
      case CHILDCARE_ASSISTANT_ESANTE:
        return i18n._(t`Childcare assistant`)
      case PHARMACEUTICAL_ASSISTANT_HOSPITAL_ESANTE:
        return i18n._(t`Pharmaceutical assistant in hospital`)
      case PHARMACEUTICAL_ASSISTANT_DISPENSARY_ESANTE:
        return i18n._(t`Pharmaceutical assistant in dispensary`)
      case DENTIST_ESANTE:
        return i18n._(t`Dentist`)
      case SOCIAL_CARE_ASSISTANT_ESANTE:
        return i18n._(t`Social care assistant`)
      case PERSONAL_CARER_ASSISTANT_ESANTE:
        return i18n._(t`Personal carer assistant`)
      case SOCIAL_INTERVENTION_ASSISTANT_ESANTE:
        return i18n._(t`Social intervention assistant`)
      case PSYCHOLOGICAL_ASSISTANT_ESANTE:
        return i18n._(t`Psychological assistant`)
      case MIDWIFE_ESANTE:
        return i18n._(t`Midwife`)
      case NURSE_ESANTE:
        return i18n._(t`Nurse`)
      case PSYCHIATRIST_NURSE_ESANTE:
        return i18n._(t`Psychiatrist nurse`)
      case PHYSIOTHERAPIST_ESANTE:
        return i18n._(t`Physiotherapist`)
      case OSTEAOPATH_ESANTE:
        return i18n._(t`Osteopath`)
      case PSYCHOTHERAPIST_ESANTE:
        return i18n._(t`Psychotherapist`)
      case CHIROPRACTOR_ESANTE:
        return i18n._(t`Chiropractor`)
      case PODIATRIST_ESANTE:
        return i18n._(t`Podiatrist`)
      case ORTHOPROSTHESIST_ESANTE:
        return i18n._(t`Orthoprosthesist`)
      case PEDORTHIST_ESANTE:
        return i18n._(t`Pedorthist`)
      case ORTHOPEDIST_ESANTE:
        return i18n._(t`Orthopedist`)
      case OCULARIST_ESANTE:
        return i18n._(t`Ocularist`)
      case EPITHESIST_ESANTE:
        return i18n._(t`Epithesist`)
      case MEDICAL_LAB_ASSISTANT_ESANTE:
        return i18n._(t`Medical laboratory assistant`)
      case SPEECH_THERAPIST_ESANTE:
        return i18n._(t`Speech therapist`)
      case ORTHOPTIST_ESANTE:
        return i18n._(t`Orthoptist`)
      case PSYCHOLOGIST_ESANTE:
        return i18n._(t`Psychologist`)
      case OCCUPATIONAL_THERAPIST_ESANTE:
        return i18n._(t`Occupational therapist`)
      case DIETITIAN_ESANTE:
        return i18n._(t`Dietitian`)
      case PSYCHOMOTOR_THERAPIST_ESANTE:
        return i18n._(t`Psychomotor therapist`)
      case GENETIC_ADVISOR_ESANTE:
        return i18n._(t`Genetic advisor`)
      case RADIO_OPERATOR:
        return i18n._(t`Radio operator`)
      case DOMESTIC_HELPER_ESANTE:
        return i18n._(t`Domestic helper`)
      case SECRETARY_ESANTE:
        return i18n._(t`Secretary`)
      case MEDICAL_ASSISTANT_ESANTE:
        return i18n._(t`Medical assistant`)
      case ADMINISTATOR:
        return i18n._(t`Administrator`)
      case MEMBER:
        return i18n._(t`Member`)
      default:
        return i18n._(t`No specialty`)
      //return i18n._(t`Practitioner`)
    }
  }
  return translate(role)
}

export default useTranslateRole
