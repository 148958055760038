import React from 'react'
import styled from 'styled-components/macro'

const Contents = styled.div`
  display: contents;

  & > div[tabindex='-1'] {
    display: contents;
  }
`
const BookingPage = ({ children }) => {
  return <Contents>{children}</Contents>
}

export default BookingPage
