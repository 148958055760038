import { upperFirst } from 'lodash'
import moment from 'moment'
import { call, select, put, takeEvery } from '@redux-saga/core/effects'
import {
  saveFhirResourceWorker,
  searchFhirResourceWorker
} from '../Shared/sagas'

import {
  MEDEO_APPOINTMENT_IDENTIFIER_SYSTEM,
  MEDEO_APPOINTMENT_TYPE_TELECONSULTATION,
  MEDEO_PROCEDURE_REQUEST_BOOKING_APPOINTMENT_CODE,
  MEDEO_PROCEDURE_REQUEST_REMOTE_CONSULTATION_CODE
} from '../utils/codes'
import {
  CREATING_APPOINTMENT,
  FETCHING_APPOINTMENT,
  CANCELLING_APPOINTMENT,
  sendMailFailed
} from './actions'
import { getReferenceFromParticipantCode } from './utils'

import { updateSlotStatusWorker } from '../Slot/sagas'
// imports for I18n
import { setupI18n } from '@lingui/core'
import englishMessages from '../locales/en/messages.js'
import frenchMessages from '../locales/fr/messages.js'
import { t } from '@lingui/macro'
import * as Sentry from '@sentry/react'
import { getIdByReference } from '../Shared/utils'
import { createNotification } from '../Notifications/actions'
import { getPatientById } from '../Patient/selectors'
import { getEncounterFromId } from '../Encounter/selectors'
import { getPractitionerRoleBypractitionerID } from '../PractitionerRole/selector'
import { getCurrentOrganizationId } from '../Auth/selectors'
import { getPractitionerById } from '../Practitioner/ducks'
import { getLocationByOrganizationId } from '../Location/selectors'
import { getOrganizationById } from '../Organization/selector'
import { getActiveProcedureRequestsBypatientID } from '../ProcedureRequest/ducks'

// setup the i18n object with active language and catalogs
let i18n = setupI18n({
  language: 'fr',
  catalogs: {
    en: englishMessages,
    fr: frenchMessages
  }
})

function* createAppointmentWorker(action) {
  const {
    bookedSlotId,
    start,
    end,
    patientID,
    performerId,
    motive,
    clinicalExam,
    practitionerID,
    encounterID
  } = action.payload
  // update slot status to 'busy'
  yield call(updateSlotStatusWorker, bookedSlotId)

  const practitionerRole = yield select(
    getPractitionerRoleBypractitionerID,
    performerId
  )
  const practitionerSnomedCode =
    practitionerRole?.specialty?.[0]?.coding?.[0]?.code

  const patient = yield select(getPatientById(patientID))

  const practitioner = yield select(getPractitionerById(performerId))
  const patientFirstName = patient?.name?.[0]?.given?.[0]
  const patientFamilyName = patient?.name?.[0]?.family
  const practitionerFirstName = practitioner?.name?.[0]?.given?.[0]
  const practitionerFamilyName = practitioner?.name?.[0]?.family
  const practitionerPrefix = practitioner?.name?.[0]?.prefix

  const currentPractitioner = yield select(getPractitionerById(practitionerID))
  const currentPractitionerFirstName =
    currentPractitioner?.name?.[0]?.given?.[0]
  const currentPractitionerFamilyName = currentPractitioner?.name?.[0]?.family
  const currentPractitionerPrefix = currentPractitioner?.name?.[0]?.prefix

  const currentOrganizationId = yield select(getCurrentOrganizationId)

  const location = yield select(s =>
    getLocationByOrganizationId(s, currentOrganizationId)
  )

  const locationId = location?.id
  const locationName = location?.name

  // Bruno : I decided to store the clinicalExam in the description field too.
  // It's for time reason. Later we should store it differently.
  let indication = i18n._(t`with Clinical Exam`)
  if (clinicalExam === false) {indication = i18n._(t`without Clinical Exam`)}

  const description = `${motive} - ${indication}`

  const newAppointment = {
    resourceType: 'Appointment',
    identifier: [
      {
        system: MEDEO_APPOINTMENT_IDENTIFIER_SYSTEM,
        value: MEDEO_APPOINTMENT_TYPE_TELECONSULTATION
      }
    ],
    status: 'booked',
    specialty: [
      {
        coding: [
          {
            system: 'http://snomed.info/sct',
            code: practitionerSnomedCode
          }
        ]
      }
    ],
    slot: [
      {
        reference: `Slot/${bookedSlotId}`
      }
    ],
    created: new Date().toISOString(),
    description: description,
    start: start,
    end: end,
    participant: [
      {
        actor: {
          reference: `Patient/${patientID}`,
          display: `${patientFirstName} ${patientFamilyName}`
        },
        type: [
          {
            coding: [
              {
                system:
                  'http://medeo.io/fhir/Identifier/appointment-actor-code',
                code: 'patient'
              }
            ]
          }
        ],
        required: 'required',
        status: 'needs-action'
      },
      {
        actor: {
          reference: `Practitioner/${performerId}`,
          display: `${practitionerPrefix} ${practitionerFirstName} ${practitionerFamilyName}`
        },
        type: [
          {
            coding: [
              {
                system:
                  'http://medeo.io/fhir/Identifier/appointment-actor-code',
                code: 'practitioner'
              }
            ]
          }
        ],
        required: 'required',
        status: 'accepted'
      },
      {
        actor: {
          reference: `Practitioner/${practitionerID}`,
          display: `${currentPractitionerPrefix} ${currentPractitionerFirstName} ${currentPractitionerFamilyName}`
        },
        type: [
          {
            coding: [
              {
                system:
                  'http://medeo.io/fhir/Identifier/appointment-actor-code',
                code: 'taking-appointment'
              }
            ]
          }
        ],
        required: 'information-only',
        status: 'accepted'
      }
    ],
    requestedPeriod: [
      {
        start: start,
        end: end
      }
    ]
  }
  if (locationId != null) {
    newAppointment.participant.push({
      actor: {
        reference: `Location/${locationId}`,
        display: `${locationName}`
      },
      type: [
        {
          coding: [
            {
              system: 'http://medeo.io/fhir/Identifier/appointment-actor-code',
              code: 'location'
            }
          ]
        }
      ],
      required: 'required',
      status: 'accepted'
    })
  }
  const appointmentResponse = yield call(saveFhirResourceWorker, newAppointment)
  try {
    const encounter = yield select(getEncounterFromId, encounterID)
    yield call(saveFhirResourceWorker, {
      ...encounter,
      appointment: { reference: `Appointment/${appointmentResponse.id}` }
    })
  } catch (e) {
    console.warn(e.message)
  }
  yield call(sendEmail, appointmentResponse)
}

/**
 * it is exported for test purpose
 * @param appointment
 * @return {Generator<SimpleEffect<"SELECT", SelectEffectDescriptor>, void, *>}
 */
export function* sendEmail(appointment) {
  try {
    // Fetch all the needed informations for sending the appointment email
    const practitionerID = getIdByReference(
      getReferenceFromParticipantCode(appointment, 'practitioner')
    )
    const practitionerActor = yield select(getPractitionerById(practitionerID))
    const patientID = getIdByReference(
      getReferenceFromParticipantCode(appointment, 'patient')
    )

    const patientActor = yield select(getPatientById(patientID))
    const [patientFirstName] = patientActor.name?.find(
      n => n.use === 'usual'
    )?.given
    const patientFamilyName = patientActor.name?.find(n => n.use === 'usual')
      ?.family
    const patientPhoneNumber = patientActor.telecom?.find(
      t => t.system === 'phone' && t.use === 'mobile'
    )?.value
    const patientEmail = patientActor.telecom?.find(t => t.system === 'email')
      ?.value
    const currentOrganizationId = yield select(getCurrentOrganizationId)
    const currentOrganization = yield select(
      getOrganizationById(currentOrganizationId)
    )

    const performerEmail = practitionerActor.telecom?.find(
      t => t.system === 'email'
    )?.value

    // at this moment the telephone of organization is not supported but it will be
    const organizationPhoneNumber =
      currentOrganization.telecom?.find(t => t.system === 'phone')?.value ??
      'aucun numéro renseigné'

    const [performerFirstName] = practitionerActor.name?.find(
      n => n.use === 'usual'
    )?.given
    const performerFamilyName = practitionerActor.name?.find(
      n => n.use === 'usual'
    )?.family
    const performerPrefix = practitionerActor.name?.find(n => n.use === 'usual')
      ?.prefix?.[0]

    // First letter of the prefix should be upperCase() and add a dot at the end (dr => Dr.)
    let performerTitle = null
    if (performerPrefix) {
      performerTitle = `${upperFirst(performerPrefix)}.`
    }
    const startAppointment = appointment?.requestedPeriod?.start
    const appointmentDateForEmail = moment(startAppointment).format(
      'DD-MM-YYYY'
    )
    const appointmentTime = moment(startAppointment).format('HH:mm')
    const appointmentDateForSMS = moment(startAppointment).format('DD/MM')
    let orgName = ''
    // first letter of the organization name has to be uppercase for the email.
    if (currentOrganization && currentOrganization.name) {
      const firstLetter = currentOrganization.name.charAt(0).toUpperCase()
      const rest = currentOrganization.name.slice(1)
      orgName = firstLetter + rest
    }
    // we pass either the number or null
    // null will be handled in the lambda to display something else instead
    const orgStreet = currentOrganization.address?.[0]?.line?.[0]
    const orgPostalCode = currentOrganization.address?.[0]?.postalCode
    const orgCity = currentOrganization.address?.[0]?.city
    const orgCountry = currentOrganization.address?.[0]?.country
    const orgPhone = organizationPhoneNumber
    const orgAddressLine =
      orgStreet && orgPostalCode && orgCity && orgCountry
        ? `${orgStreet} ${orgPostalCode} ${orgCity} ${orgCountry}`
        : null
    const sendToPractitionerURL = `${process.env.REACT_APP_APPOINTMENT_BASE_URL}/sendToPractitioner`
    const sendToPatientURL = `${process.env.REACT_APP_APPOINTMENT_BASE_URL}/sendToPatient`

    // Call the email lambda
    fetch(sendToPractitionerURL, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        performerEmail: performerEmail,
        performerTitle: 'Dr.',
        performerFamilyName: performerFamilyName,
        performerId: practitionerActor.id,
        orgName: orgName,
        orgPhone: orgPhone,
        appointmentDateForEmail: appointmentDateForEmail,
        appointmentTime: appointmentTime,
      })
    })
    // Call the email lambda to send email to patient
    if (patientEmail) {
      fetch(sendToPatientURL, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          patientEmail,
          patientFirstName,
          patientFamilyName,
          orgName,
          orgPhone,
          orgAddressLine,
          appointmentDateForEmail,
          appointmentTime,
          performerFirstName,
          performerFamilyName,
          performerTitle
        })
      })
    }

    // send appointment SMS
    if (patientPhoneNumber) {
      // lambda URL for sending SMS to patient
      const lambdaUrl = `${process.env.REACT_APP_APPOINTMENT_BASE_URL}/sendSMSToPatient`
      // Call the email lambda
      fetch(lambdaUrl, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          patientPhoneNumber:
            // sometimes the first 0 in the number is not saved
            patientPhoneNumber.indexOf('0') === 0
              ? patientPhoneNumber
              : '0' + patientPhoneNumber,
          orgName: orgName,
          organizationPhoneNumber:organizationPhoneNumber,
          appointmentTime: appointmentTime,
          appointmentDateForSMS: appointmentDateForSMS,
        })
      })
    }
  } catch (e) {
    yield put(sendMailFailed(e))
    // we are in a catch block, the exception will appear as handled in Sentry
    Sentry.captureException(e)
  }
}

function* cancelAppointmentWorker(action) {
  const { appointment, practitioner } = action.payload
  const slotId = getIdByReference(appointment.slot?.[0]?.reference)
  const startAppointment = appointment.start
  const appointmentDateForEmail = moment(startAppointment).format(
    'DD MMMM YYYY'
  )
  const appointmentTime = moment(startAppointment).format('HH:mm')

  // fetch organization who booked the appointment (pharmacist)
  const currentOrganizationId = yield select(getCurrentOrganizationId)
  const currentOrganization = yield select(
    getOrganizationById(currentOrganizationId)
  )
  const orgName = upperFirst(currentOrganization?.name)

  const location = yield select(s =>
    getLocationByOrganizationId(s, currentOrganizationId)
  )
  const locationId = location?.id

  const sendToPerformerURL = `${process.env.REACT_APP_APPOINTMENT_BASE_URL}/cancelEmailPerformer`
  const sendToPractitionerURL = `${process.env.REACT_APP_APPOINTMENT_BASE_URL}/cancelEmailPractitioner`
  const sendToPatientURL = `${process.env.REACT_APP_APPOINTMENT_BASE_URL}/cancelAppointmentPatient`

  // get all perfomers info in order to send him a information of cancelation mail
  const performerRef = getReferenceFromParticipantCode(
    appointment,
    'practitioner'
  )
  const performerId = getIdByReference(performerRef)
  const performer = yield select(getPractitionerById(performerId))
  const performerFirstName = performer.name[0]?.given[0]
  const performerFamilyName = performer.name[0]?.family
  const performerPrefix = performer.name[0]?.prefix?.[0]
  let performerTitle = null
  if (performerPrefix) {
    performerTitle = `${upperFirst(performerPrefix)}.`
  }

  const performerEmail = performer.telecom?.find(t => t.system === 'email')
    ?.value
  fetch(sendToPerformerURL, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      performerEmail,
      performerTitle,
      performerFamilyName,
      performerId,
      orgName,
      appointmentDateForEmail,
      appointmentTime
    })
  })

  // gathering patient infos in order to send him a confirmation of cancelation mail
  const patientRef = getReferenceFromParticipantCode(appointment, 'patient')
  const patientID = getIdByReference(patientRef)
  const patient = yield select(getPatientById(patientID))
  const patientFirstName = patient.name[0]?.given[0]
  const patientFamilyName = patient.name[0]?.family
  const patientEmail = patient.telecom?.find(t => t.system === 'email')?.value
  if (patientEmail != null) {
    fetch(sendToPatientURL, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        patientEmail,
        patientFirstName,
        patientFamilyName,
        performerFirstName,
        performerFamilyName,
        performerTitle
      })
    })
  }

  // everytime there will be only one active, so we update it
  const patientProcedureRequests = yield select(
    getActiveProcedureRequestsBypatientID(patientID)
  )
  const procedureRequestToCancel = patientProcedureRequests
    ?.sort((a, b) => b.id - a.id)
    ?.find(procedureRequest =>
      procedureRequest.code?.coding.find(
        coding =>
          coding.code === MEDEO_PROCEDURE_REQUEST_BOOKING_APPOINTMENT_CODE ||
          coding.code === MEDEO_PROCEDURE_REQUEST_REMOTE_CONSULTATION_CODE
      )
    )

  // gathering practitioner infos in order to send him a confirmation of cancelation mail
  const practitionerFamilyName = practitioner.name[0]?.family
  const practitionerPrefix = practitioner.name[0]?.prefix?.[0]
  const practitionerEmail = practitioner.telecom?.find(
    t => t.system === 'email'
  )?.value

  let practitionerTitle = null
  if (practitionerPrefix) {
    practitionerTitle = `${upperFirst(practitionerPrefix)}.`
  }
  fetch(sendToPractitionerURL, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      practitionerEmail: practitionerEmail,
      practitionerTitle: practitionerTitle,
      practitionerFamilyName: practitionerFamilyName,
      locationId: locationId,
      appointmentDateForEmail:appointmentDateForEmail,
      appointmentTime: appointmentTime
    })
  })

  // We fetch the slot
  const { payload } = yield call(searchFhirResourceWorker, 'Slot', {
    _id: slotId
  })
  // Now we update the slot status from busy to free, as we have slots for each schedule
  // time point and not only for the busy ones
  if (payload.Slot && payload.Slot.length !== 0)
    {yield call(saveFhirResourceWorker, { ...payload.Slot[0], status: 'free' })}

  // In revanche, as we are using the status for the appointment and the
  // procedure request, we can update those resources instead of deleting them
  yield call(saveFhirResourceWorker, { ...appointment, status: 'cancelled' })

  // It can be that the booking Procedure Request is null as we might come
  // from the cancel teleconsultation, in this case the booking procedure request
  // was already set to completed
  if (procedureRequestToCancel != null)
    {yield call(saveFhirResourceWorker, {
      ...procedureRequestToCancel,
      status: 'cancelled'
    })}

  yield put(createNotification('Rendez-vous annulé', 'success'))
}

function* rootSaga() {
  yield takeEvery(
    [FETCHING_APPOINTMENT],
    searchFhirResourceWorker,
    'Appointment',
    ({ payload: p }) => ({ ...p })
  )
  yield takeEvery(CREATING_APPOINTMENT, createAppointmentWorker)
  yield takeEvery(CANCELLING_APPOINTMENT, cancelAppointmentWorker)
}

export default rootSaga
