import React, { useEffect } from 'react'
import {
  loadFhirResourceFromId,
  loadFhirResourceFrompatientID
} from '../../Shared/actions'
import { useDispatch, useSelector } from 'react-redux'
import { getMedicationFromSubjectId } from '../../MedicationStatement/selectors'
import InlineList from '../../Shared/components/InlineList'
import InlineListItem from '../../Shared/components/InlineListItem'
import { Trans } from '@lingui/macro'

const MedicationList = ({ patientID }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(loadFhirResourceFrompatientID('MedicationStatement', patientID))
  }, [patientID, dispatch])

  const medications = useSelector(s => getMedicationFromSubjectId(s, patientID))
  useEffect(() => {
    // Switch patient will clear any previous patient values
    dispatch(loadFhirResourceFromId('Patient', patientID))
  }, [patientID, dispatch])

  return (
    <InlineList
      items={medications}
      fallback={
        <div style={{ color: '#77808e' }}>
          <Trans>No medical statement found</Trans>
        </div>
      }
    >
      {(m, i) => {
        const display = m?.code?.coding?.[0]?.display
        // title attribute is used in the case of the display being to big
        // and not fitting in the Component, when the user will hover
        // the component it will show the full name in a prompt.
        return (
          <InlineListItem title={display} key={i}>
            {display}
          </InlineListItem>
        )
      }}
    </InlineList>
  )
}

export default MedicationList
