import { useSelector } from 'react-redux'
import { getPractitionersByOrganizationId } from '../selector'
import PractitionerTable, { Row } from '../components/PractitionerTable'
import { Trans } from '@lingui/macro'
import React from 'react'

const OutsideCollaboratorsTableHeaderRow = () => (
  <Row>
    <th />
    <th>
      <Trans>Name</Trans>
    </th>
    <th>
      <Trans>Profession</Trans>
    </th>
  </Row>
)

const OutsideCollaboratorsTable = ({ organization, children }) => {
  const outsideCollaborators = useSelector(state =>
    getPractitionersByOrganizationId(state, organization.id)
  )
  return (
    <div>
      {/* if all is ready */}
      {outsideCollaborators.length > 0 ? (
        <PractitionerTable
          practitioners={outsideCollaborators}
          headerRow={OutsideCollaboratorsTableHeaderRow}
        >
          {children}
        </PractitionerTable>
      ) : (
        <div>
          <Trans>This organization does not have any practitioner yet</Trans>
        </div>
      )}
    </div>
  )
}

export default OutsideCollaboratorsTable
