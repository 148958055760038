import React from 'react'
import { Trans } from '@lingui/macro'
import DevicesListForDistant from './DevicesListForDistant'
import styled from 'styled-components/macro'

const Container = styled.div`
  border-bottom: solid 1px ${p => p.theme.gray};
  padding-bottom: 1rem;
`

const ClinicalExam = ({ preconsultationId }) => {
  return (
    <Container>
      <h4>
        <Trans>Physical exam</Trans>
      </h4>
      <DevicesListForDistant preconsultationId={preconsultationId} />
    </Container>
  )
}

export default ClinicalExam
