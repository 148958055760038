import  { FC } from 'react'
import PlanDefinition from './PlanDefinition'
import { useSelector } from 'react-redux'
import { getLastEncounterFromSubjectIdBycode } from '../../Encounter/selectors'
import { MEDEO_ENCOUNTER_TYPE_BOOKING } from '../../utils/encounter-type'
import { filterActionsForPreconsultationWithConditions } from '../utils'
import {
  getItemByLinkId,
  getItemLinkIdFromCode
} from '../../Questionnaire/utils'
import { getIdByReference } from '../../Shared/utils'
import {
  KIOSK_MOTIVES_QUESTIONNAIRE_IDENTIFIER,
  MEDEO_CODE_FOR_CLINICAL_EXAM,
  MEDEO_CODE_FOR_MOTIVE,
  MEDEO_MOTIVES_QUESTIONNAIRE_IDENTIFIER
} from '../../utils/codes'
import { useSearch } from '../../Shared/hooks'
import {
  Encounter,
  Patient,
  PlanDefinitionAction,
  Practitioner,
  Questionnaire
} from 'fhir-stu3'
import { RouteComponentProps } from '@reach/router'
import { QuestionnaireResponse } from 'fhir-stu3/src'
import { Trans } from '@lingui/macro'

type UseSearchBundle = {
  data?: {
    Questionnaire?: Questionnaire[]
    QuestionnaireResponse?: QuestionnaireResponse[]
  }
  loading: boolean
}

interface PreconsultationPlanDefinitionProps extends RouteComponentProps {
  patient: Patient
  encounter: Encounter
  practitioner: Practitioner
}

const PreconsultationPlanDefinition: FC<PreconsultationPlanDefinitionProps> = props => {
  const { patient, encounter, practitioner } = props
  const bookingEncounter = useSelector(
    getLastEncounterFromSubjectIdBycode(
      patient!.id!,
      MEDEO_ENCOUNTER_TYPE_BOOKING
    )
  )

  const { data, loading } = useSearch('QuestionnaireResponse', {
    context: `Encounter/${bookingEncounter.id}`,
    _include: 'QuestionnaireResponse:questionnaire'
  }) as UseSearchBundle
  if (loading) {
    return <div><Trans>Loading...</Trans></div>
  }
  const motivesQuestionnaire = data?.Questionnaire?.find(q =>
    q.identifier?.find(
      i =>
        // The motive questionnaire can either be the one of kiosk or of medeo.care,
        // so that a booking performed on medeo.care can be ended here.
        // The codes used in both questionnaires are identical.
        i.value === KIOSK_MOTIVES_QUESTIONNAIRE_IDENTIFIER ||
        i.value === MEDEO_MOTIVES_QUESTIONNAIRE_IDENTIFIER
    )
  )

  // there are two QuestionnaireResponse from booking Encounter
  // the Motive Questionnaire and the SelectPatient Questionnaire
  // here we want to have the first one
  const motivesQuestionnaireResponse = data?.QuestionnaireResponse?.find(
    q =>
      getIdByReference(q.questionnaire?.reference) === motivesQuestionnaire?.id
  )

  let motive: string | null = null
  let clinicalExam = true
  // we put true to prevent error in utils for filterActionsForPreconsultationWithConditions.
  // In a classical teleconsultation process, we put a clinical exam
  // take motives from response
  if (motivesQuestionnaireResponse != null) {
    const motiveLinkId = getItemLinkIdFromCode(
      motivesQuestionnaire,
      MEDEO_CODE_FOR_MOTIVE
    )
    const motivesAnswer = getItemByLinkId(
      motivesQuestionnaireResponse,
      motiveLinkId
    )
    motive = motivesAnswer?.answer?.[0]?.valueCoding?.code

    const clinicalExamLinkId = getItemLinkIdFromCode(
      motivesQuestionnaire,
      MEDEO_CODE_FOR_CLINICAL_EXAM
    )

    // We support both the kiosk and medeo motives questionnaires here,
    // this is why the clinical exam linkId might be null here as this question
    // is not present in the kiosk motives questionnaire.
    if (clinicalExamLinkId != null) {
      const clinicalExamAnswer = getItemByLinkId(
        motivesQuestionnaireResponse,
        clinicalExamLinkId
      )

      clinicalExam = clinicalExamAnswer?.answer?.[0]?.valueBoolean
    }
  }

  const filterActions = (planDefinitionActions: PlanDefinitionAction) =>
    filterActionsForPreconsultationWithConditions(
      planDefinitionActions,
      motive,
      clinicalExam
    )

  return (
    <PlanDefinition
      patient={patient}
      practitioner={practitioner}
      encounter={encounter}
      filterActions={filterActions}
    />
  )
}

export default PreconsultationPlanDefinition
