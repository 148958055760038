import { MEDEO_NIR_IDENTIFIER_SYSTEM } from '../../utils/codes'
import { Trans } from '@lingui/macro'
import React from 'react'
import styled from 'styled-components/macro'
import defaultPractitionerImg from '../../img/defaultPractitionerHead.png'

import ProfilePicture from '../../Shared/components/ProfilePicture'

const Div = styled.div`
  color: ${p => p.theme.black};
  border: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  & > aside {
    margin-right: 1rem;
  }
  & > main {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }
  padding: 1rem;
`
const Uppercase = styled.span`
  text-transform: uppercase;
`
const IdentityDetails = styled.div`
  display: flex;
  flex-direction: row;
  font-size: ${p => p.theme.small};
  & > :first-child {
    color: ${p => p.theme.black};
  }
  & > :not(:last-child) {
    margin-right: 1rem;
  }
`

const Muted = styled.span`
  font-size: ${p => p.theme.small};
  color: ${p => p.theme.gray};
`

const FirstRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 0.25rem;
`

const LastRow = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  color: ${p => p.theme.gray};
`

const PatientDetails = ({ patient }) => {
  const given = patient.name?.[0]?.given
  const family = patient?.name?.[0]?.family
  const dob = patient.birthDate
  const nir = patient.identifier?.find?.(
    i => i.system === MEDEO_NIR_IDENTIFIER_SYSTEM
  )

  return (
    <Div>
      <aside>
        <ProfilePicture
          size="small"
          src={defaultPractitionerImg}
          alt={`${given} ${family} picture`}
        />
      </aside>
      <main>
        <FirstRow>
          <div>
            <span>{given}</span>
            &nbsp;
            <Uppercase data-testid="selectedPatientName">{family}</Uppercase>
          </div>
          <Muted>{patient.address?.[0]?.postalCode}</Muted>
        </FirstRow>
        <LastRow>
          <IdentityDetails>
            <span>{dob}</span>
            <span>
              {nir?.value ? `NIR: ${nir.value}` : <Trans>No NIR found</Trans>}
            </span>
          </IdentityDetails>
          <Muted>{patient.address?.[0]?.city}</Muted>
        </LastRow>
      </main>
    </Div>
  )
}

export default PatientDetails
