import { call, put, select, takeEvery } from 'redux-saga/effects'
import {
  EDITING_PATIENT,
  failToSearchPatients,
  PATIENT_RECEIVED,
  SEARCHING_PATIENTS,
  succeedToSearchPatients
} from './actions'
import {
  saveFhirResourceWorker,
  searchFhirResourceWorker,
  searchTotalFhirResourceWorker
} from '../Shared/sagas'
import { getCurrentOrganizationId } from '../Auth/selectors'
import { MEDEO_SELECT_PATIENT_QUESTIONNAIRE_IDENTIFIER } from '../utils/codes'
import { getResponseItemByItemCode } from '../Questionnaire/utils'
import { saveQuestionnaireResponse } from '../QuestionnaireResponse/actions'
import {
  getQuestionnaireById,
  getQuestionnaireByIdentifier
} from '../Questionnaire/selector'

/*
 * The following system and codes are used for identifying Practitioner in
 * the generalPractitioner field of a patient,
 * It is used in the saga responsible for creating a patient
 *
 */
export const MEDEO_GENERAL_PRACTITIONER_IDENTIFIER_SYSTEM =
  'https://medeo.io/fhir/Identifier/general-practitioner'
export const MEDEO_GENERAL_PRACTITIONER_IDENTIFIER_MEDECIN_TRAITANT_CODE =
  'medecin-traitant'
export const MEDEO_GENERAL_PRACTITIONER_IDENTIFIER_MEDECIN_DU_TERRITOIRE =
  'medecin-du-territoire'

function* searchResultPatient(action) {
  try {
    const organizationId = yield select(getCurrentOrganizationId)
    const data = yield call(searchFhirResourceWorker, 'Patient', {
      name: action.payload,
      _filter: `( organization eq ${organizationId} or general-practitioner eq Organization/${organizationId} )`
    })
    yield put(succeedToSearchPatients(data.payload.Patient))
  } catch (error) {
    yield put(failToSearchPatients(error))
  }
}

/**
 * This function is used to create a patient from the add patient questionnaire
 * and then create a questionnaire response for the select patient questionnaire,
 * what we cannot do before to have the patient id
 *
 * @param {*} action
 */
export function* extractPatientFromQuestionnaireResponse(action) {
  const {
    questionnaireResponse,
    practitionerID,
    encounterID,
    questionnaireId,
    organizationReference
  } = action.payload

  const organizationId = yield select(getCurrentOrganizationId)

  const questionnaire = yield select(getQuestionnaireById(questionnaireId))

  const given = getResponseItemByItemCode(
    'given',
    questionnaire,
    questionnaireResponse
  )?.answer?.[0]?.valueString

  const family = getResponseItemByItemCode(
    'family',
    questionnaire,
    questionnaireResponse
  )?.answer?.[0]?.valueString

  // We have to save the nir as a decimal as the fhir integer are 32-bits,
  // which is not enough for the nir range
  const nir = getResponseItemByItemCode(
    'INS-NIR',
    questionnaire,
    questionnaireResponse
  )?.answer?.[0]?.valueDecimal

  const birthDate = getResponseItemByItemCode(
    'birthDate',
    questionnaire,
    questionnaireResponse
  ).answer?.[0]?.valueDate

  const phone = getResponseItemByItemCode(
    'phone',
    questionnaire,
    questionnaireResponse
  ).answer?.[0]?.valueInteger

  const email = getResponseItemByItemCode(
    'email',
    questionnaire,
    questionnaireResponse
  ).answer?.[0]?.valueString

  const gender = getResponseItemByItemCode(
    'gender',
    questionnaire,
    questionnaireResponse
  ).answer?.[0]?.valueCode

  const patient = {
    resourceType: 'Patient',
    birthDate: birthDate,
    gender: gender,
    identifier: [
      {
        type: {
          coding: [
            {
              system:
                'http://interopsante.org/fhir/valueset/fr-patient-identifier-type',
              version: '1',
              code: 'INS-NIR',
              display: 'NIR'
            }
          ]
        },
        system: 'http://medeo.io/fhir/Identifier/patient-nir',
        value: parseInt(nir)
      }
    ],
    name: [
      {
        use: 'usual',
        family: family,
        given: [given]
      }
    ],
    telecom: [
      ...(email != null
        ? [
            {
              system: 'email',
              value: email
            }
          ]
        : []),
      ...(phone != null
        ? [
            {
              system: 'phone',
              value: phone,
              use: 'mobile'
            }
          ]
        : [])
    ],
    ...(organizationReference != null && {
      generalPractitioner: [
        {
          reference: organizationReference,
          identifier: {
            system: MEDEO_GENERAL_PRACTITIONER_IDENTIFIER_SYSTEM,
            value: MEDEO_GENERAL_PRACTITIONER_IDENTIFIER_MEDECIN_DU_TERRITOIRE
          }
        }
      ]
    }),
    managingOrganization: {
      reference: `Organization/${organizationId}`
    }
  }

  const response = yield call(saveFhirResourceWorker, patient)

  const selectPatientQuestionnaire = yield select(
    getQuestionnaireByIdentifier(MEDEO_SELECT_PATIENT_QUESTIONNAIRE_IDENTIFIER)
  )
  // If the patient was saved successfully, we create a select patient response
  // which will be needed later on in the teleconsultation process
  if (response != null) {
    const savedGiven = response?.name?.[0]?.given?.[0]
    const savedFamily = response?.name?.[0]?.family

    const responseToSelectPatientQuestionnaire = {
      item: [
        {
          linkId: '1',
          item: [
            {
              linkId: '1.1',
              answer: [{ valueInteger: response.id }]
            },
            {
              linkId: '1.2',
              answer: [{ valueString: savedFamily }]
            },
            {
              linkId: '1.3',
              answer: [{ valueString: savedGiven }]
            }
          ]
        }
      ]
    }

    yield put(
      saveQuestionnaireResponse(
        responseToSelectPatientQuestionnaire,
        practitionerID,
        response.id,
        selectPatientQuestionnaire.id,
        encounterID
      )
    )
    return response
  }
}

function* rootSaga() {
  const organizationId = yield select(getCurrentOrganizationId)

  yield takeEvery(SEARCHING_PATIENTS, searchResultPatient)

  yield takeEvery(EDITING_PATIENT, saveFhirResourceWorker, a => a.payload)

  yield takeEvery(PATIENT_RECEIVED, searchTotalFhirResourceWorker, 'Patient', {
    _summary: 'count',
    organization: organizationId
  })
}

export default rootSaga
