import isEqual from 'lodash/isEqual'
import { darken } from 'polished'
import { createContext, useEffect, useReducer, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useEffectOnce } from 'react-use'
import styled from 'styled-components/macro'
import { getDeviceByType } from '../../Device/selectors'
import { loadEncountersFromSubjectId } from '../../Encounter/actions'
import {
  getAllEncounters,
  getLastEncounterDateByCodeFromSubjectId
} from '../../Encounter/selectors'
import { MEDEO_ENCOUNTER_TYPE_PRE_TELECONSULTATION } from '../../utils/encounter-type'
import { getDeviceSelectionOptions } from '../../utils/video/helpersDevices'
import CommandPanel from '../components/CommandPanel'
import ScreenVideo from '../components/ScreenVideo'
import {
  PLUGGING_DEVICE,
  TELECONSULTATION_COMPLETED,
  TELECONSULTATION_UNSTARTED,
  videoReducer
} from '../reducer'
import {
  connectUser,
  handleDisconnect,
  handleSwitchAudioInput,
  handleSwitchVideo,
  updateDevicesOptions
} from '../utils'

const DivContainer = styled.div`
  width: 100%;
  height: 100%;
  min-height: 500px;
  display: flex;
  flex-direction: column;
  background-color: ${(p) => darken(0.37, p.theme.gray)};
`

export const VideoScreenContext = createContext({})

const init = ({
  identifier,
  organizationName,
  patientID,
  patientSide,
  stethoscope
}) => {
  return {
    status: patientSide ? 'loading' : 'unstarted', // waiting, in-progress, finishForDoctor, finishForPatient, cannotJoin
    patientSide,
    patientID,
    isConnected: false,
    isVideo: false,
    isAudio: false,
    teleconsultationMaterial: {},
    deviceOptions: {},
    nbOtherParticipant: 0,
    teleconsultationFhirResource: {},
    documentsDisplay: false,
    // Control pharmacist side
    isStethoscopeOn: false,
    // Control doctor side
    isListeningStethoscope: false,
    stethoscope,
    webSocket: null,
    openModal: false,
    pluggingDevice: false,
    // The organizationName is used in mixpanel to identify the users accurately
    organizationName,
    // The identifier for the twilio room
    identifier,
    observations: []
  }
}

/**
 * VideoSequenceManager is used to manage everything related to the visio
 * It uses an internal state and a reducer that update the status of the visio
 * status can either be todo waiting, in-progress, finishForPatient, finihForDoctor
 *
 *
 * When the status is finishForPatient, we set an interval to get the latest encounter
 * from the patient, so we can retrieve the document attached to it and display
 * them to the user.
 *
 * @param patientID
 * @param patientSide
 * @param organizationName
 * @param identifier
 * @param handleObservation is teh function called when an observation is added
 * @param displayReturnButton is boolean to display return button
 * @param displayStethoscopeButton is boolean to display stethoscope button
 * @returns {*}
 * @constructor
 */
const VideoSequenceManager = ({
  patientID,
  patientSide = false,
  organizationName,
  identifier,
  handleObservation = () => {},
  displayReturnButton = true,
  displayStethoscopeButton = true
}) => {
  const stethoscope = useSelector(getDeviceByType('Stethoscope'))

  const [videoState, dispatch] = useReducer(
    videoReducer,
    { identifier, organizationName, patientID, patientSide, stethoscope },
    init
  )

  // Whenever a media device is added or removed, update the list.
  useEffect(() => {
    if (videoState.isConnected && videoState.pluggingDevice) {
      getDeviceSelectionOptions().then((devicesSelectionOptions) => {
        updateDevicesOptions({ devicesSelectionOptions, videoState, dispatch })
        dispatch({ type: PLUGGING_DEVICE })
      })
    }
  }, [videoState])

  /** update video and audio  device when needed **/
  // these states are used as references to trigger the useEffect that update the tracks
  const currentAudioDeviceId = useRef(
    videoState.deviceOptions.selectedAudioInput?.value
  )
  const currentVideoDeviceId = useRef(
    videoState.deviceOptions.selectedVideoInput?.value
  )

  // during the first renderings, the ref is undefined, so we force it to a value
  // ... as soon as a value is set in the videoState. At this step we update
  // ... the track with the selected default value.
  useEffect(() => {
    if (currentAudioDeviceId.current == null) {
      currentAudioDeviceId.current =
        videoState.deviceOptions.selectedAudioInput?.value
    }
  }, [videoState, currentAudioDeviceId])
  useEffect(() => {
    if (currentVideoDeviceId.current == null) {
      currentVideoDeviceId.current =
        videoState.deviceOptions.selectedVideoInput?.value
    }
  }, [videoState, currentVideoDeviceId])

  // This effect updates the selected camera
  // ... only when a new device is selected
  useEffect(() => {
    if (videoState.isConnected) {
      // the condition checks that the value has changed. The value is compared with the local ref.
      // ... otherwise this effect would be run also when the var is updated with the same value.
      if (
        !isEqual(
          currentVideoDeviceId?.current,
          videoState?.deviceOptions?.selectedVideoInput?.value
        )
      ) {
        handleSwitchVideo({
          videoState,
          dispatch,
          localMediaContainer
        })
        // update the ref with the new value
        currentVideoDeviceId.current =
          videoState.deviceOptions.selectedVideoInput.value
      }
    }
  }, [videoState, currentVideoDeviceId, dispatch])

  // This effect updates the selected micro
  // ... only when a new device is selected
  useEffect(() => {
    if (videoState.isConnected) {
      // the condition checks that the value has changed. The value is compared with the local ref.
      // ... otherwise this effect would be run also when the var is updated with the same value.
      if (
        !isEqual(
          currentAudioDeviceId?.current,
          videoState?.deviceOptions?.selectedAudioInput?.value
        )
      ) {
        handleSwitchAudioInput({
          videoState,
          dispatch
        })
        // update the ref with the new value
        currentAudioDeviceId.current =
          videoState.deviceOptions.selectedAudioInput.value
      }
    }
  }, [videoState, currentAudioDeviceId, dispatch])

  const reduxDispatch = useDispatch()
  let remoteMediaContainer = useRef(null)
  let localMediaContainer = useRef(null)
  // we retrieve the last pre_teleconsultation encounter
  const preconsultation = useSelector((state) =>
    getLastEncounterDateByCodeFromSubjectId(
      state,
      MEDEO_ENCOUNTER_TYPE_PRE_TELECONSULTATION,
      patientID
    )
  )
  const preconsultationId = preconsultation != null ? preconsultation.id : null
  const teleconsultationEncounter = useSelector(getAllEncounters)?.find(
    (e) => e.partOf === 'Encounter/' + preconsultationId
  )

  useEffectOnce(() => {
    // If we are on patient side, we want the user to connect directly to
    // the teleconsultation room
    if (patientSide) {
      connectUser({
        identifier,
        remoteMediaContainer,
        localMediaContainer,
        videoState,
        dispatch,
        // 👇 used only for Mixpanel
        encounterID: preconsultationId,
        organizationName
      })
    }
  })

  // this useEffect is used when teleconsultation completed appears and we
  // don't start the real teleconsultation. So we force the status to be "unstarted"
  useEffect(() => {
    if (
      teleconsultationEncounter == null &&
      videoState.status === 'teleconsultationCompleted'
    ) {
      dispatch({
        type: TELECONSULTATION_UNSTARTED
      })
    }
  }, [teleconsultationEncounter, videoState.status])

  useEffect(() => {
    if (
      teleconsultationEncounter &&
      teleconsultationEncounter.status === 'finished'
    ) {
      dispatch({
        type: TELECONSULTATION_COMPLETED,
        payload: {
          teleconsultationEncounter,
          status: 'teleconsultationCompleted'
        }
      })
    }
  }, [teleconsultationEncounter])

  useEffect(() => {
    if (videoState.status === 'finishForPatient') {
      const interval = setInterval(() => {
        return reduxDispatch(loadEncountersFromSubjectId(patientID))
      }, 10000)
      return () => {
        clearInterval(interval)
      }
    }
  }, [videoState.status, patientID, reduxDispatch])

  useEffect(() => {
    // In a useEffect, if you return a function, it will be executed on componentDidUnmount
    // and it will disconnect the video
    const onUnload = () => {
      handleDisconnect({ videoState, dispatch })
    }
    // this is like a cleanup effect that works also when reload the page savagely
    window.onbeforeunload = () => {
      return onUnload()
    }
    return () => onUnload()

    //eslint-disable-next-line
  }, [videoState.teleconsultationMaterial])

  // the next effect get the last observation recieved from twilio data track
  // ... and put it into a global state
  useEffect(() => {
    if (videoState.observations?.length !== 0) {
      handleObservation(videoState.observations.slice(-1).pop())
    }
  }, [videoState.observations, handleObservation])
  // the listener below run also on page reload, contrary to the cleanup effect.
  window.onbeforeunload = () => {
    handleDisconnect({ videoState, dispatch })
  }

  return (
    <VideoScreenContext.Provider value={[videoState, dispatch]}>
      <DivContainer>
        <ScreenVideo
          remoteMediaContainer={remoteMediaContainer}
          localMediaContainer={localMediaContainer}
          displayReturnButton={displayReturnButton}
        />
        {videoState.isConnected && (
          <CommandPanel
            localMediaContainer={localMediaContainer}
            displayStethoscopeButton={displayStethoscopeButton}
          />
        )}
      </DivContainer>
    </VideoScreenContext.Provider>
  )
}

export default VideoSequenceManager
