import React, { useState } from 'react'
import styled from 'styled-components/macro'
import { Button, Form, Input, Label } from '../../Components'
import { Auth } from 'aws-amplify'
import { Trans } from '@lingui/macro'
import { createNotification } from '../../Notifications/actions'
import { useDispatch } from 'react-redux'
import Header from './Header'

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
`

const Left = styled.div`
  width: 45%;
  & > ${Label} {
    margin-top: 1rem;
  }
`
const Right = styled.div`
  width: 45%;
`
const Hint = styled.span`
  font-size: small;
  color: ${p => p.theme.gray};
`

//TODO replace by Card.Footer
const Footer = styled.div`
  margin-top: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid ${p => p.theme.gray};
`

const Security = () => {
  const dispatch = useDispatch()
  const [areMatching, setAreMatching] = useState(null)

  const handleSubmit = e => {
    e.preventDefault()
    const { elements } = e.currentTarget
    const formerPassword = elements['formerPassword'].value
    const newPassword = elements['newPassword'].value
    const confirmPassword = elements['confirmPassword'].value

    // Check that the passwords are matching
    if (newPassword === confirmPassword) {
      Auth.currentAuthenticatedUser()
        .then(user => {
          return Auth.changePassword(user, formerPassword, newPassword)
        })
        .then(() => {
          dispatch(
            createNotification(
              'Votre mot de passe a été correctement modifié',
              'success',
              5000
            )
          )
        })
        .catch(err => {
          dispatch(createNotification(err.message, 'error', 5000))
        })
    }
  }

  return (
    <>
      <Header>
        <Trans>Change password</Trans>
      </Header>
      <Form onSubmit={handleSubmit}>
        <Form.Row>
          <Left>
            <Input
              label={<Trans>Current password</Trans>}
              id="formerPassword"
              minLength={8}
              type="password"
              required
            />
          </Left>
        </Form.Row>
        <Row>
          <Left>
            <Input
              label={<Trans>New password</Trans>}
              id="newPassword"
              minLength={8}
              type="password"
            />
          </Left>
          <Right>
            <Hint>
              <Trans>
                Your new password should contain at least 8 characters.
              </Trans>
            </Hint>
          </Right>
        </Row>
        <Form.Row>
          <Left>
            <Input
              label={<Trans>Confirm your new password</Trans>}
              style={
                areMatching != null && !areMatching
                  ? { borderColor: 'red' }
                  : {}
              }
              type="password"
              id="confirmPassword"
              onChange={() => {
                if (
                  document.getElementById('confirmPassword').value !==
                  document.getElementById('newPassword').value
                )
                  {setAreMatching(false)}
                else {setAreMatching(true)}
              }}
            />
          </Left>
        </Form.Row>
        <Footer>
          <Button color="ocean" disabled={!areMatching}>
            <Trans>Validate</Trans>
          </Button>
        </Footer>
      </Form>
    </>
  )
}

export default Security
