import { useNavigate } from '@reach/router'
import { QuestionnaireItem, Slot } from 'fhir-stu3'
import { FC, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components/macro'
import { Form } from '../../Components'
import { createNotification } from '../../Notifications/actions'
import { MOTIVES_ACTION_VALUE } from '../../PlanDefinition/utils'
import { QuestionnaireResponseContext } from '../../Questionnaire/components/Questionnaire'
import QuestionnaireComponent from '../../Questionnaire/components/QuestionnaireComponent'
import useSearch from '../../Shared/hooks/useSearch'
import useBookingContext from '../containers/BookingContext'
import BookingFormFooter from '../containers/BookingFormFooter'
import LoadingCardContent from './LoadingCardContent'
const CustomForm = styled(Form)`
  margin: 0 2.5rem 0 2.5rem;
`

const MotiveForm: FC = () => {
  const { state: bookingContext, handleChangeMotive } = useBookingContext()
  const { data } = useSearch('Questionnaire', {
    identifier: MOTIVES_ACTION_VALUE
  })
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { data: slotData } = useSearch('Slot', {
    _id: bookingContext?.slot?.id
  })

  useEffect(() => {
    //@ts-ignore
    const slot = slotData?.Slot?.[0] as Slot
    if (slot?.status && slot?.status !== 'free') {
      dispatch(
        createNotification(
          "Désolé, ce créneau n'est plus disponible",
          'error',
          5000
        )
      )
      navigate('./slots')
    }
  }, [slotData, dispatch, navigate])

  //@ts-ignore
  const questionnaire = data?.Questionnaire?.[0]
  const [localState, setLocalState] = useState(
    bookingContext?.motiveResponse ?? {
      item: [],
      status: 'draft',
      ...(questionnaire?.id != null && {
        questionnaire: {
          reference: questionnaire.id
        }
      })
    }
  )

  const handleChange = (action: any) => {
    setLocalState((s: any) => ({ ...s, item: action.payload }))
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault()
    handleChangeMotive(localState)
    let dest = './patient'
    if (bookingContext?.patient?.id !== undefined) {
      dest = './recap'
    }
    await navigate(dest)
  }

  if (questionnaire == null) {
    return <LoadingCardContent />
  }

  return (
    <>
      <CustomForm id="booking" onSubmit={handleSubmit}>
        <QuestionnaireResponseContext.Provider
          value={[localState, handleChange]}
        >
          {questionnaire.item.map((i: QuestionnaireItem) => (
            //@ts-ignore
            <QuestionnaireComponent
              item={i}
              key={i.linkId}
              questionnaire={questionnaire}
            />
          ))}
        </QuestionnaireResponseContext.Provider>
      </CustomForm>
      <BookingFormFooter canNavigateNext={true} />
    </>
  )
}
export default MotiveForm
