/*eslint-disable*/
import { combineReducers, Reducer } from 'redux'
import {
  PATIENTS_RECEIVED,
  API_ERRORED,
  PATIENT_RECEIVED,
  TOTAL_PATIENT_RECEIVED,
  LOADING_MORE_PATIENTS,
  SEARCHING_PATIENTS,
  PATIENT_SEARCH_SUCCEEDED,
  PATIENT_SEARCH_FAILED,
  PATIENT_SEARCH_DELETED,
  GET_INITIAL_PATIENT_PAGE,
  LOADED_MORE_PATIENTS
} from './actions'
import { appendAllUniqueIds, reduceById } from '../Shared/ducks'
import { FHIR_RESOURCES_RECEIVED } from '../Shared/actions'
import { SIGN_OUT_REQUESTED } from '../Auth/actions'
import { Bundle, BundleLink, Patient } from 'fhir-stu3'
import { ById } from '../Shared/ducks/types'

export const allIds: Reducer<string[]> = (state = [], action) => {
  switch (action.type) {
    case PATIENT_RECEIVED:
      return appendAllUniqueIds(state, action.payload.patients)
    case FHIR_RESOURCES_RECEIVED:
    case PATIENTS_RECEIVED:
      return appendAllUniqueIds(state, action.payload.Patient)
    case API_ERRORED:
      return state
    case SIGN_OUT_REQUESTED:
      return []
    default:
      return state
  }
}

export const byId: Reducer<ById<Patient>> = (state = {}, action) => {
  switch (action.type) {
    case FHIR_RESOURCES_RECEIVED:
    case PATIENTS_RECEIVED:
      return reduceById(state, action.payload.Patient)
    case PATIENT_RECEIVED:
      return { ...state, [action.payload.patient.id]: action.payload.patient }
    case API_ERRORED:
      return state
    case SIGN_OUT_REQUESTED:
      return {}
    default:
      return state
  }
}
export const total: Reducer<number | null> = (state = null, action) => {
  switch (action.type) {
    case TOTAL_PATIENT_RECEIVED:
      return action.payload.total
    default:
      return state
  }
}

export const searchResultId: Reducer<{
  searchList: string[]
  loading: boolean
  display: boolean
}> = (state = { searchList: [], loading: false, display: false }, action) => {
  switch (action.type) {
    case SEARCHING_PATIENTS:
      return {
        ...state,
        searchList: [],
        loading: true,
        display: false
      }
    case PATIENT_SEARCH_SUCCEEDED:
      return {
        ...state,
        searchList: action.payload,
        loading: false,
        display: true
      }
    case PATIENT_SEARCH_FAILED:
      return {
        ...state,
        searchList: [],
        loading: false,
        display: false
      }
    case PATIENT_SEARCH_DELETED:
      return {
        ...state,
        searchList: [],
        loading: false,
        display: false
      }
    default:
      return state
  }
}

export default combineReducers({
  allIds,
  byId,
  total,
  searchResultId
})
