import React from 'react';
import styled from 'styled-components/macro';
import {
  filterObservationsByTypes,
  getAllObservationsType
} from '../../Observation/selectors';
import ObservationTypeView from '../../Observation/components/ObservationTypeView';

const Width = styled.div`
  padding: 1rem;

  background-color: white;
`;

const Main = styled.div`
  margin-bottom: ${props => props.theme.spacing.medium};
`;

const Container = styled.div`
  display: flex;
  align-items: stretch;
  flex-direction: column;
  padding-left: 0.5em;
`;

const Top = styled.div``;

const LiveObservationsView = ({ observations }) => {
  const alreadyDoneObservationsTypes = getAllObservationsType(observations);

  return (
    <Main>
      {observations != null && observations.length !== 0 && (
        <Width>
          {alreadyDoneObservationsTypes.map((observationType, i) => {
            return (
              <Container key={i} id={observationType}>
                <Top>
                  <ObservationTypeView
                    observations={filterObservationsByTypes(
                      observationType,
                      observations
                    )}
                    observationType={observationType}
                    isActive={null}
                    readOnly={true}
                  />
                </Top>
              </Container>
            );
          })}
        </Width>
      )}
    </Main>
  );
};

export default LiveObservationsView;
