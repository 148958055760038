import React from 'react'
import styled from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { Trans } from '@lingui/macro'
import { Button } from '../../Components'
import { useNavigate } from '@reach/router'
import Grid from '../../Shared/components/GridLayout'
import SettingsNav from './SettingsNav'

const CustomButton = styled(Button)`
  border: none;
  & > svg {
    margin-right: 0.5rem;
  }
`

const SettingsPage = ({ children }) => {
  const navigate = useNavigate()
  const handleClick = e => {
    e.preventDefault()
    navigate('/')
  }
  return (
    <>
      <Grid.Header variant="aside"></Grid.Header>
      <Grid.Main>{children}</Grid.Main>
      <Grid.Aside>
        <div style={{ paddingTop: '1rem' }}>
          <CustomButton
            color="ocean"
            variant="secondary"
            style={{ fontSize: 'small' }}
            onClick={handleClick}
          >
            <FontAwesomeIcon icon={faChevronLeft} />
            <Trans>Go back</Trans>
          </CustomButton>
        </div>

        <SettingsNav path="/" />
      </Grid.Aside>
    </>
  )
}

export default SettingsPage
