import { getAllEntities } from '../Shared/selectors'
import { ReduxState } from '../Shared/ducks/types'
import { Device } from 'fhir-stu3'

export const getAllDevices = (state: ReduxState): Device[] =>
  getAllEntities(state, 'Device')

export const getDeviceByType = (type: string) => (
  state: ReduxState
): Device | undefined => {
  return getAllDevices(state).find(device => {
    const typeOfDevice = device.type?.coding?.[0]?.display
    return typeOfDevice === type
  })
}

export const getDevicesByOrganizationId = (organizationId: string) => (
  state: ReduxState
): Device[] => {
  return getAllDevices(state).filter(
    device => device.owner?.reference === `Organization/${organizationId}`
  )
}
