import { takeEvery, put, call } from 'redux-saga/effects';
import {
  saveFhirResourceWorker,
  searchFhirResourceWorker
} from '../Shared/sagas';
import {
  EDITING_PRACTITIONER,
  CREATING_PRACTITIONER
} from '../Settings/actions';
import { SEARCH_GENERAL_PRACTITIONER, PRACTITIONER_REMOVED } from './actions';
import { createNotification } from '../Notifications/actions';
import { createPractitionerRole } from '../PractitionerRole/actions';
import { MEDEO_ADMINISTRATOR_CODE } from '../utils/codes';

function* editPractitioner(action) {
  const response = yield saveFhirResourceWorker(action.payload);
  if (response != null) {
    yield put(
      createNotification(
        'Utilisateur a été correctement modifié',
        'success',
        4000
      )
    );
  } else {
    yield put(
      createNotification(
        "Une erreur est survenue lors de la modification de l'utilisateur",
        'error',
        4000
      )
    );
  }
}

function* createPractitioner(action) {
  let { practitioner, medicalRole, administrationRole } = action.payload;

  const practitionerResource = yield call(
    saveFhirResourceWorker,
    {
      resourceType: 'Practitioner',
      identifier: practitioner.identifier,
      name: practitioner.name,
      telecom: practitioner.telecom,
      address: practitioner.address,
      photo: practitioner.photo
    },
    action
  );

  if (practitionerResource) {
    medicalRole = {
      ...medicalRole,
      identifier: [
        {
          type: {
            coding: [
              {
                system: 'http://medeo.io/fhir/PractitionerRole/medical',
                code: 'medical'
              }
            ]
          }
        }
      ],
      practitioner: {
        reference: `Practitioner/${practitionerResource.id}`
      }
    };
    administrationRole = {
      ...administrationRole,
      identifier: [
        {
          type: {
            coding: [
              {
                system:
                  'http://medeo.io/fhir/PractitionerRole/administration-medeo',
                code: MEDEO_ADMINISTRATOR_CODE
              }
            ]
          }
        }
      ],
      practitioner: {
        reference: `Practitioner/${practitionerResource.id}`
      }
    };
  }
  yield put(createPractitionerRole(administrationRole));
  yield put(createPractitionerRole(medicalRole));
}

export default function* watcherSaga() {
  // practitioners are loaded using the PractititionerRoles include parameters
  // we have loaded using the organizationId
  // yield call(searchFhirResourceWorker, 'Practitioner')
  yield takeEvery(CREATING_PRACTITIONER, createPractitioner);
  yield takeEvery(EDITING_PRACTITIONER, editPractitioner);
  yield takeEvery(PRACTITIONER_REMOVED, saveFhirResourceWorker, a => ({
    ...a.payload,
    active: false
  }));
  yield takeEvery(
    SEARCH_GENERAL_PRACTITIONER,
    searchFhirResourceWorker,
    'Practitioner',
    ({ payload: p }) => ({
      _id: p
    })
  );
}
