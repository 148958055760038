import React from 'react'
import styled from 'styled-components'
import { lighten } from 'polished'
import moment from 'moment'

const StyledSlotLabel = styled.label`
  background-color: white;
  display: block;
  text-align: center;
  border-radius: 0.3rem;
  border: 1px solid ${p => p.theme.ocean};
  color: ${p => p.theme.ocean};
  cursor: pointer;
  padding: 0.5rem 1rem;
  &:hover {
    color: black;
    background-color: ${lighten(0.25, '#0967D2')};
  }
`
const Wrapper = styled.div`
  position: relative;
  input:checked + ${StyledSlotLabel} {
    background-color: ${p => p.theme.ocean};
    color: white;
  }
  input {
    cursor: pointer;
  }
  input:hover + label {
    background-color: ${lighten(0.25, '#0967D2')};
  }
`

// this snippet is required if we want to display the validity message
// display: none or visibility: hidden set the input in an inaccessible state
// the position properties display the validity message correctly.
const HiddenRadioButton = styled.input.attrs({ type: 'radio' })`
  position: absolute;
  top: 50%;
  left: 50%;
  opacity: 0;
  transform: translateY(-50%) translateX(-50%);
`

const BetterSlot = ({ slot, onClick, checked }) => {
  const handleClick = () => {
    onClick(slot)
  }
  // a bug appeared when showing multiple practitioners w/ the same openingHours
  // slots were sharing the same id which causes the 2nd slot to be unselectable
  // slot.perfomerId-slot.start make a good uid that should mitigate the bug
  //const id = slot.start
  const id = `${slot.id}-${slot.start}`
  return (
    <Wrapper>
      <HiddenRadioButton
        data-testid="createSlot"
        id={id}
        name="slot"
        // when the slot is taken from someone else, we need to update the property so we keep this
        defaultChecked={checked}
        onClick={handleClick}
        required
      />
      <StyledSlotLabel htmlFor={id}>
        {moment(slot.start).format('HH:mm')}
      </StyledSlotLabel>
    </Wrapper>
  )
}

export default BetterSlot
