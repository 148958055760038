import React, { useContext } from 'react'
import styled from 'styled-components/macro'
import { Trans } from '@lingui/macro'
import { Button } from '../../../Components'
import { Link } from '@reach/router'
import YAMLFormInput from './YAMLFormInput'
import { ModelContext } from '../../../Yaml/utils'
import { preventSubmitOnKeyPressIfRequired } from '../../../Shared/utils'

const Form = styled.form`
  display: flex;
  flex-direction: column;
  & > * {
    flex: 1;
    margin-bottom: 1rem;
  }
`

const Footer = styled.footer`
  display: flex;
  border-top: 1px solid ${p => p.theme.nevada};
  padding-top: 2rem;
  margin-bottom: 0;
  justify-content: ${p => (p.hasTwoButtons ? 'space-between' : 'flex-end')};
  margin-top: ${p => p.theme.spacing.medium};
`

const FormPage = ({
  inputs,
  step,
  summary,
  consent,
  onSubmit,
  onChange,
  yamlFile
}) => {
  const [model] = useContext(ModelContext)
  const isSummary = summary != null
  const isConsent = consent != null

  const shouldShowPreviousButton =
    step > 1 || step === 'consent' || step === 'summary'

  const handlePreviousStep = () => {
    switch (step) {
      case 'summary':
        return 'consent'
      case 'consent':
        return yamlFile.pages.length
      case yamlFile.pages.length:
        return step - 1
      default:
        return step - 1
    }
  }

  const previousPath = shouldShowPreviousButton && handlePreviousStep()

  // condition to disable 'next' button
  let consentDisableCondition = false
  if (isConsent && model.$isConsentPrinted == null) {
    consentDisableCondition = true
  } else if (isConsent && model.$isConsentPrinted === false) {
    consentDisableCondition = true
  } else if (isConsent && model.$isConsentPrinted === true) {
    consentDisableCondition = false
  }

  // form has attribute autocomplete="off" even though chrome
  // is ignoring this instruction :(
  // we should leave it like this maybe in a future browser update
  // this will be restored
  return (
    <Form
      onSubmit={onSubmit}
      onChange={onChange}
      autoComplete="off"
      onKeyPress={e => preventSubmitOnKeyPressIfRequired(e)}
    >
      {inputs.map((input, index) => (
        <YAMLFormInput
          key={`s${step}i${index}`}
          yaml={input}
          readOnly={isSummary}
        />
      ))}

      <Footer hasTwoButtons={shouldShowPreviousButton}>
        {shouldShowPreviousButton && (
          <Link to={`../${previousPath}`}>
            <Button color="ocean" variant="outline">
              <Trans>Back</Trans>
            </Button>
          </Link>
        )}
        {/* disable if the page is the consent page and the print button has not been clicked */}
        <Button color="ocean" disabled={consentDisableCondition} id="next">
          {isSummary ? (
            summary.confirm || <Trans>Confirm</Trans>
          ) : (
            <Trans>Next</Trans>
          )}
        </Button>
      </Footer>
    </Form>
  )
}

export default FormPage
