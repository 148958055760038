import React, { useContext, useEffect, useState } from 'react'
import { Input } from '../../Components'
import { getDisplay, onChangeQuestionnaire, getItemByLinkId } from '../utils'
import { QuestionnaireResponseContext } from './Questionnaire'
import { MEDEO_QUESTIONNAIRE_ITEM_TYPE_CODE_SYSTEM } from '../../utils/codes'

const QuestionnaireInput = ({ item, readOnly, defaultValue }) => {
  const [questionnaireResponse, dispatchQuestionnaireResponse] = useContext(
    QuestionnaireResponseContext
  )
  const [inputDefaultValue, setInputDefaultValue] = useState(null)
  useEffect(() => {
    setInputDefaultValue(getItemByLinkId(questionnaireResponse, item.linkId))
  }, [questionnaireResponse, item])

  defaultValue =
    inputDefaultValue?.answer?.[0]?.valueInteger ||
    inputDefaultValue?.answer?.[0]?.valueDecimal ||
    inputDefaultValue?.answer?.[0]?.valueString

  const label = getDisplay(item)

  // In order to do the validation of the telephone input we identify the
  // item with a type code as FHIR doesnt support type 'tel' & 'email' etc...
  let type =
    item?.code?.find(
      c => c.system === MEDEO_QUESTIONNAIRE_ITEM_TYPE_CODE_SYSTEM
    )?.code ?? item.type

  switch (type) {
    case 'string':
      type = 'text'
      break
    case 'decimal':
    case 'integer':
      type = 'number'
      break
    case 'email':
      type = 'email'
      break
    case 'phone':
      type = 'tel'
      break
    default:
      break
  }

  return (
    <Input
      label={label}
      name={item.linkId}
      type={type}
      required={item.required}
      readOnly={readOnly}
      defaultValue={defaultValue}
      maxLength={item.maxLength}
      minLength={type === 'tel' && item.maxLength ? item.maxLength : undefined}
      data-test={item.linkId}
      onChange={e => {
        onChangeQuestionnaire({
          questionnaireResponse: questionnaireResponse,
          dispatchQuestionnaireResponse: dispatchQuestionnaireResponse,
          name: e.target.name,
          value: e.target.value,
          type: item.type
        })
      }}
      className="fs-block"
    />
  )
}

export default QuestionnaireInput
