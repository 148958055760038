/*eslint-disable*/
import { combineReducers, Reducer } from 'redux'
import {
  CONDITION_RECEIVED,
  CONDITIONS_RECEIVED,
  CONDITION_REMOVED
} from './actions'
import { reduceById, appendAllUniqueIds, removeById } from '../Shared/ducks'
import { FHIR_RESOURCES_RECEIVED } from '../Shared/actions/index.js'
import { ById, ReduxState } from '../Shared/ducks/types'
import { Condition } from 'fhir-stu3'

export const allIds: Reducer<string[]> = (state = [], action) => {
  const { type, payload } = action
  switch (type) {
    case FHIR_RESOURCES_RECEIVED:
    case CONDITIONS_RECEIVED:
      return appendAllUniqueIds(state, payload.Condition)
    case CONDITION_RECEIVED:
      return appendAllUniqueIds(state, payload.condition)
    case CONDITION_REMOVED:
      return state.filter(id => id !== payload.condition.id)
    default:
      return state
  }
}

export const byId: Reducer<ById<Condition>> = (state = {}, action) => {
  const { payload, type } = action
  switch (type) {
    case CONDITION_RECEIVED:
      return { ...state, [payload.condition.id]: payload.condition }
    case FHIR_RESOURCES_RECEIVED:
    case CONDITIONS_RECEIVED:
      return reduceById(state, payload.Condition)
    case CONDITION_REMOVED:
      return removeById(state, payload.condition)
    default:
      return state
  }
}

const getConditions = (state: ReduxState): Condition[] => {
  return state.conditions.allIds.map(id => state.conditions.byId[id])
}
export const getConditionsBypatientID = (patientID: string) => (
  state: ReduxState
) => {
  return getConditions(state).filter(
    condition => condition.subject === 'Patient/' + patientID
  )
}

export const getConditionsBypatientIDAndType = (
  patientID: string,
  type: string
) => (state: ReduxState) => {
  return getConditionsBypatientID(patientID)(state).filter(condition =>
    condition.identifier?.some(i => i.type?.coding?.some(c => c?.code === type))
  )
}

export const getMedicalHistoryBypatientID = (patientID: string) => (
  state: ReduxState
): Condition[] =>
  getConditionsBypatientIDAndType(patientID, 'medical-history')(state)

export const getSurgicalHistoryBypatientID = (patientID: string) => (
  state: ReduxState
): Condition[] =>
  getConditionsBypatientIDAndType(patientID, 'surgical-history')(state)

export const getFamilyHistoryBypatientID = (patientID: string) => (
  state: ReduxState
): Condition[] =>
  getConditionsBypatientIDAndType(patientID, 'family-history')(state)

export default combineReducers({
  allIds,
  byId
})
