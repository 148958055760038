import React, { useState, useEffect, createContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components/macro'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Trans } from '@lingui/macro'
import { Button, Card, Form, Input, Label } from '../../Components'
import { useNavigate } from '@reach/router'

import { set } from '../../utils/fhirpath'
import { editPatient } from '../actions'
import IdentifierInputRow from '../components/IdentifierInputRow'
import HumanNameInputsRow from '../components/HumanNameInputsRow'
import AddressInputsRow from '../components/AddressInputsRow'
import TelecomInputsRow from '../components/TelecomInputsRow'
import GeneralPractitionerInputRow from '../components/GeneralPractitionerInputRow'
import Grid from '../../Shared/components/GridLayout'
import AddGeneralPractitionerModal from './AddGeneralPractitionerModal'
import AllergyTagInput from '../../Allergy/containers/AllergyTagInput'
import ConditionTagInput from '../../Condition/containers/ConditionTagInput'
import MedicationTagInput from '../../Medication/containers/MedicationTagInput'
import MedicationList from '../../Medication/containers/MedicationList'
import { search } from '../../Shared/actions'
import TagView from '../../Shared/components/TagView'
import { getIdByReference } from '../../Shared/utils'
import { displayGender } from '../../Shared/display/entity'
import mixpanel, { MIXPANEL_PATIENT_EDITED } from '../../Shared/mixpanel'
import { getAllergiesBypatientID } from '../../Allergy/ducks'
import {
  getFamilyHistoryBypatientID,
  getMedicalHistoryBypatientID,
  getSurgicalHistoryBypatientID
} from '../../Condition/ducks'

const CustomCard = styled(Card)`
  margin: 0 auto;
  h4 {
    padding-bottom: 1rem;
    padding-top: 1rem;
    border-bottom: 1px solid ${p => p.theme.nevada};
  }
`

const Container = styled.div`
  height: 5rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

const Footer = styled.footer`
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  & > :not(:last-child) {
    margin-right: 1rem;
  }
`
const FormHeader = styled.div`
  font-size: ${props => props.theme.large};
  text-transform: capitalize;
  padding-bottom: 1rem;
  border-bottom: 1px solid ${p => p.theme.nevada};
`

const CustomSection = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`

const CustomButton = styled(Button)`
  border: none;
  & > svg {
    margin-right: 0.5rem;
  }
`

const BetterSelect = styled.select`
  width: 100%;
  padding: 0.5rem;
  border-radius: 0.25rem;
  border-color: ${p => p.theme.gray};
  height: 65%;

  &:focus,
  &:hover {
    outline: none;
    border-color: ${p => p.theme.ocean};
    cursor: pointer;
  }
`

const Required = styled.span`
  margin-left: 0.25rem;
  color: ${p => p.theme.ocean};
`

const Bold = styled.div`
  font-size: 1em;
  font-weight: 500;
  *line-height: 1.5;
`

export const EditPatientContext = createContext()

const EditPatientForm = ({ patient, isReadOnly }) => {
  const navigate = useNavigate()
  const [resource, setState] = useState(patient)
  const patientID = patient?.id

  useEffect(() => {
    setState(patient)
  }, [patient])

  const dispatch = useDispatch()

  const handleSubmit = e => {
    e.preventDefault()
    dispatch(editPatient(resource))
    navigate(`/patient/${patient.id}/show`)
  }

  const handleCancel = e => {
    e.preventDefault()
    navigate(`/patient/${patient.id}/history`)
  }

  const handleEditPatient = e => {
    e.preventDefault()
    navigate(`/patient/${patient.id}/edit`)
  }
  const handleSave = () => {
    mixpanel.track(MIXPANEL_PATIENT_EDITED)
  }
  useEffect(() => {
    dispatch(
      search('AllergyIntolerance', {
        patient: patientID
      })
    )
    dispatch(
      search('Condition', {
        subject: 'Patient/' + patientID
      })
    )
  }, [patientID, dispatch])

  const allergies = useSelector(getAllergiesBypatientID(patientID))
  const medicalHistory = useSelector(getMedicalHistoryBypatientID(patientID))
  const surgicalHistory = useSelector(getSurgicalHistoryBypatientID(patientID))
  const familyHistory = useSelector(getFamilyHistoryBypatientID(patientID))

  return (
    <EditPatientContext.Provider value={[resource, setState]}>
      <Grid.Header variant="aside">
        <Container>
          <CustomButton variant="outline" color="ocean" onClick={handleCancel}>
            <FontAwesomeIcon icon={faChevronLeft} />
            <Trans>Go back</Trans>
          </CustomButton>
        </Container>
      </Grid.Header>
      <Grid.Main variant="center">
        <CustomCard>
          <FormHeader>
            {isReadOnly === true ? (
              <Trans>Patient folder</Trans>
            ) : (
              <Trans>Edit the patient folder</Trans>
            )}
          </FormHeader>
          {/*<pre>{JSON.stringify(resource, null, 2)}</pre>*/}
          {/*All inputs have a readOnly property that is either true or false depending if user wants to edit the patient info or just to see it*/}
          <Form id="editForm" name="editForm" onSubmit={handleSubmit}>
            <section>
              <h4>
                <Trans>Identifiers</Trans>
              </h4>
              {resource?.identifier?.map((identifier, i) => {
                return identifier.system ===
                  'http://medeo.io/fhir/Identifier/patient-nir' ? (
                  <IdentifierInputRow
                    identifier={identifier}
                    index={i}
                    readOnly={isReadOnly}
                    key={i}
                  />
                ) : null
              })}
            </section>
            <section>
              <h4>
                <Trans>Information</Trans>
              </h4>
              <HumanNameInputsRow readOnly={isReadOnly} required />
              <Form.Row>
                <Input
                  name="birthDate"
                  type="date"
                  defaultValue={resource?.birthDate}
                  label="Date de naissance"
                  readOnly={isReadOnly}
                  onChange={e => {
                    setState(set(resource, 'birthDate', e.target.value))
                  }}
                  required
                />
                <div>
                  <Label>
                    <Trans>
                      Gender <Required>*</Required>
                    </Trans>
                  </Label>
                  {isReadOnly ? (
                    <Bold>{displayGender(resource)}</Bold>
                  ) : (
                    <BetterSelect
                      name="gender"
                      value={resource?.gender}
                      onChange={e => {
                        setState(set(resource, 'gender', e.target.value))
                      }}
                      readOnly={isReadOnly}
                    >
                      <option value="male">Homme</option>
                      <option value="female">Femme</option>
                      <option value="other">Autre</option>
                    </BetterSelect>
                  )}
                </div>
              </Form.Row>
              <h4>
                <Trans>Telecom</Trans>
              </h4>
              <TelecomInputsRow isPatient={true} readOnly={isReadOnly} />
            </section>
            <section>
              <h4>
                <Trans>Address</Trans>
              </h4>
              <AddressInputsRow readOnly={isReadOnly} isPatient={true} />
            </section>
            <CustomSection>
              <h4>
                <Trans>Access Rights Management</Trans>
              </h4>
              <Label>
                <Trans>
                  The following outside collaborators have access to this
                  patient file
                </Trans>
              </Label>
              {resource?.generalPractitioner?.map(
                (gp, index) =>
                  gp.reference &&
                  getIdByReference(gp.reference) != null && (
                    <GeneralPractitionerInputRow
                      generalPractitioner={gp}
                      index={index}
                      key={index}
                      readOnly={isReadOnly}
                    />
                  )
              )}
              {/* react-testing library doesn't support the Modal, so we don't use it in a test context */}
              {process.env.NODE_ENV !== 'test' && !isReadOnly && (
                <AddGeneralPractitionerModal />
              )}
            </CustomSection>
            <section>
              <h4>
                <Trans>Allergies and intolerances</Trans>
              </h4>
              {isReadOnly ? (
                <TagView resource={allergies} type={'allergy'} />
              ) : (
                <AllergyTagInput
                  name="allergies"
                  placeholder="Allergies et intolérances"
                  patientID={patientID}
                  readOnly={isReadOnly}
                />
              )}
            </section>
            <section>
              <h4>
                <Trans>Medical history and risk factors</Trans>
              </h4>
              {isReadOnly ? (
                <TagView resource={medicalHistory} type={'conditions'} />
              ) : (
                <ConditionTagInput
                  name="medical-history"
                  placeholder="Antécédents médicaux et facteurs de risque"
                  patientID={patientID}
                  readOnly={isReadOnly}
                />
              )}
            </section>
            <section>
              <h4>
                <Trans>Surgical and obstetric history</Trans>
              </h4>
              {isReadOnly ? (
                <TagView resource={surgicalHistory} type={'conditions'} />
              ) : (
                <ConditionTagInput
                  name="surgical-history"
                  placeholder="Antécédents chirurgicaux et obstétricaux"
                  patientID={patientID}
                  readOnly={isReadOnly}
                />
              )}
            </section>
            <section>
              <h4>
                <Trans>Family history</Trans>
              </h4>
              {isReadOnly ? (
                <TagView resource={familyHistory} type={'conditions'} />
              ) : (
                <ConditionTagInput
                  name="family-history"
                  placeholder="Antécédents familiaux"
                  patientID={patientID}
                  readOnly={isReadOnly}
                />
              )}
            </section>
            <section>
              <h4>
                <Trans>Medical Statements</Trans>
              </h4>
              {isReadOnly ? (
                <MedicationList patientID={patient?.id} />
              ) : (
                <MedicationTagInput patient={patient} />
              )}
            </section>
          </Form>
          {isReadOnly ? (
            <Footer>
              <Button color="ocean" onClick={handleEditPatient}>
                <Trans>Edit patient</Trans>
              </Button>
            </Footer>
          ) : (
            <Footer>
              <Button form="editForm" color="ocean" onClick={handleSave}>
                <Trans>Save</Trans>
              </Button>
            </Footer>
          )}
        </CustomCard>
      </Grid.Main>
    </EditPatientContext.Provider>
  )
}

export default EditPatientForm
