import React, { useState } from 'react'
import styled from 'styled-components/macro'
import { Button } from '../../Components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

const Container = styled.div`
  margin-top: 2rem;
  text-align: center;
`

const LoadMore = ({ loadMore, hasMore, isLoading, children }) => {
  const [internalLoading, setInternalLoading] = useState(false)

  if (!hasMore) {
    return null
  }

  const onClick = e => {
    e.preventDefault()
    e.target.blur() // blur the element as it's going to be a loading spinner soon

    // Apply a small timeout to show that the data is loading,
    // this is just a look&feel feature to make the loading feel
    // useful
    if (!internalLoading) {
      setInternalLoading(true)

      setTimeout(() => {
        loadMore()
        setInternalLoading(false)
      }, 300)
    }

    return false
  }

  return (
    <Container>
      <Button
        disabled={isLoading || internalLoading}
        variant="outline"
        color="ocean"
        onClick={onClick}
      >
        {isLoading || internalLoading ? (
          <FontAwesomeIcon icon={faSpinner} spin={true} />
        ) : (
          children
        )}
      </Button>
    </Container>
  )
}

export default LoadMore
