import React from 'react'
import moment from 'moment'
import styled from 'styled-components/macro'
import { Trans } from '@lingui/macro'
import { Label } from '../../Components'

const Container = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 2rem;
`

const Column = styled.div`
  flex: 50%;
  display: flex;
  flex-direction: column;
`

const ColumnTitle = styled.div`
  margin-bottom: 0.5rem;
`

const DateAndTime = ({ dateTime }) => {
  const date = moment(dateTime).format('dddd D MMMM YYYY')

  const time = moment(dateTime).format('HH:mm')

  return (
    <Container>
      <Column>
        <ColumnTitle>
          <Label>
            <Trans>Date</Trans>
          </Label>
        </ColumnTitle>
        <div>{date}</div>
      </Column>
      <Column>
        <ColumnTitle>
          <Label>
            <Trans>Time</Trans>
          </Label>
        </ColumnTitle>
        <div>{time}</div>
      </Column>
    </Container>
  )
}

export default DateAndTime
