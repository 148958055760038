import React, { useContext } from 'react'
import { Trans } from '@lingui/macro'
import { Button, Form, Input } from '../../Components'
import { EditPatientContext } from '../containers/EditPatientForm'
import { set } from '../../utils/fhirpath'

const AddressInputsRow = ({ isPatient, readOnly }) => {
  const [resource, setResource] = useContext(EditPatientContext)
  const address = isPatient
    ? resource?.address
    : [resource?.contact?.[0]?.address]
  // we want to know if we are modifying the patient's address or the patient's contact address
  const path = isPatient ? `address.item(0)` : 'contact.item(0).address'
  const hasAddress = address != null
  const addNewAddress = () => {
    setResource({
      ...resource,
      address: [
        {
          city: '',
          country: '',
          line: [''],
          postalCode: ''
        }
      ]
    })
  }

  return hasAddress ? (
    address.map((a, index) => (
      <div key={index}>
        <Form.Row>
          <Input
            readOnly={readOnly}
            label={<Trans>Street</Trans>}
            onChange={e =>
              setResource(set(resource, `${path}.line.item(0)`, e.target.value))
            }
            value={a?.line?.[0] ?? ''}
          />
        </Form.Row>
        <Form.Row>
          <Input
            readOnly={readOnly}
            onChange={e =>
              setResource(set(resource, `${path}.postalCode`, e.target.value))
            }
            label={<Trans>Postal code</Trans>}
            value={a?.postalCode ?? ''}
            minLength={5}
            maxLength={5}
          />
          <Input
            readOnly={readOnly}
            onChange={e =>
              setResource(set(resource, `${path}.city`, e.target.value))
            }
            label={<Trans>City</Trans>}
            value={a?.city ?? ''}
          />
        </Form.Row>
        <Form.Row>
          <Input
            readOnly={readOnly}
            onChange={e =>
              setResource(set(resource, `${path}.country`, e.target.value))
            }
            label={<Trans>Country</Trans>}
            value={a?.country ?? ''}
          />
        </Form.Row>
      </div>
    ))
  ) : (
    <>
      <p>
        <Trans>No information was added for this patient</Trans>
      </p>
      {!readOnly && (
        <Button onClick={addNewAddress}>
          <Trans>Add address</Trans>
        </Button>
      )}
    </>
  )
}

export default AddressInputsRow
