import { Trans } from '@lingui/macro'
import { useNavigate } from '@reach/router'
import { useReducer, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import sha256 from 'sha256'
import styled from 'styled-components/macro'
import { Button, Card, Form, Input, Label } from '../../Components'
import Header from '../../Organization/components/Header'
import {
  ASKING_FOR_PIN_CODE,
  PRACTITIONER_UNSELECTED
} from '../../Practitioner/actions'
import {
  MEDEO_CODE_PIN_SYSTEM,
  PIN_CODE_SALT
} from '../../Practitioner/containers/TypePinCodeForm'
import { getPractitionerById } from '../../Practitioner/ducks'
import { editPractitioner } from '../actions'

const Left = styled.div`
  width: 45%;
  & > ${Label} {
    margin-top: 1rem;
  }
`

const CenterRow = styled.div`
  display: flex;
  margin-top: 1rem;
  padding-bottom: 1rem;
`

const Message = styled.div`
  padding-top: 1rem;
  padding-bottom: 1rem;
  color: ${(p) => p.theme.gray};
`

const initialState = {
  areMatching: false,
  newPinValue: '',
  confirmPinValue: ''
}

const reducer = (state, action) => {
  const { type, payload } = action
  switch (type) {
    case 'checkMatch':
      return {
        areMatching: payload.areMatching,
        newPinValue: payload.newPinValue,
        confirmPinValue: payload.confirmPinValue
      }
    case 'clean':
      return { ...state, newPinValue: '', confirmPinValue: '' }
    default:
      return state
  }
}

const CreateOrChangePinCode = ({
  practitionerID,
  comesFromRequirePractitioner
}) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const reduxDispatch = useDispatch()
  const practitionerToUpdate = useSelector(getPractitionerById(practitionerID))
  const [messagePinUpdated, setMessagePinUpdated] = useState(false)
  const navigate = useNavigate()

  const handleSubmit = (e) => {
    e.preventDefault()
    // Check that the PINs are matching and update on fhir
    if (state.newPinValue === state.confirmPinValue) {
      const hash = sha256(`${state.newPinValue}${PIN_CODE_SALT}`)
      const newPinCodeIdentifier = {
        system: MEDEO_CODE_PIN_SYSTEM,
        value: hash
      }
      // find previous code PIN if exists, and remove
      const previousPinCodeIdentifier = practitionerToUpdate?.identifier?.find(
        (i) => i.system === MEDEO_CODE_PIN_SYSTEM
      )

      if (previousPinCodeIdentifier != null) {
        const pinCodeIndex = practitionerToUpdate.identifier.findIndex(
          (i) => i.system === MEDEO_CODE_PIN_SYSTEM
        )
        practitionerToUpdate.identifier.splice(pinCodeIndex)
      }
      // update with new PIN code
      if (practitionerToUpdate.identifier != null) {
        practitionerToUpdate.identifier.push(newPinCodeIdentifier)
      } else {
        practitionerToUpdate.identifier = [newPinCodeIdentifier]
      }
      reduxDispatch(editPractitioner(practitionerToUpdate))
      setMessagePinUpdated(true)
      // redirect if from RequirePractitioner
      if (comesFromRequirePractitioner) {
        reduxDispatch({ type: ASKING_FOR_PIN_CODE })
      }
    }
    dispatch({ type: 'clean' })
    navigate('..')
  }

  const handleOnChange = () => {
    const newPin = document.getElementById('newPIN')
    const confirmPin = document.getElementById('confirmPIN')

    if (newPin.value !== confirmPin.value || confirmPin.value.length !== 4) {
      dispatch({
        type: 'checkMatch',
        payload: {
          areMatching: false,
          newPinValue: newPin.value,
          confirmPinValue: confirmPin.value
        }
      })
    } else {
      dispatch({
        type: 'checkMatch',
        payload: {
          areMatching: true,
          newPinValue: newPin.value,
          confirmPinValue: confirmPin.value
        }
      })
    }
  }

  const handleBackButton = () => {
    reduxDispatch({ type: PRACTITIONER_UNSELECTED })
    navigate('/')
  }

  return comesFromRequirePractitioner ? (
    <Card>
      <h1>
        <Trans>PIN Code</Trans>
      </h1>
      <Header />
      <div>
        <Trans>
          Please, create a pin code in order to guarantee the safety of your
          patient's data.
        </Trans>
      </div>
      <div>
        <Trans>It will be asked for :</Trans>
        <ul>
          <li>
            <Trans>your connection on Medeo.care;</Trans>
          </li>
          <li>
            <Trans>changing profile;</Trans>
          </li>
          <li>
            <Trans>the end of a remote consultation</Trans>
          </li>
        </ul>
      </div>
      <Form onSubmit={handleSubmit}>
        <Form.Row>
          <Left>
            <Input
              label={
                <>
                  <Trans>New code PIN</Trans> (<Trans>4 characters</Trans>)
                </>
              }
              id="newPIN"
              type="password"
              maxLength="4"
              size="4"
              placeholder="_ _ _ _"
              onChange={handleOnChange}
              required={true}
            />
          </Left>
        </Form.Row>
        <Form.Row>
          <Left>
            <Input
              label={<Trans>Confirm your new code PIN</Trans>}
              style={!state.areMatching ? { borderColor: 'red' } : {}}
              type="password"
              maxLength="4"
              size="4"
              id="confirmPIN"
              placeholder="_ _ _ _"
              onChange={handleOnChange}
              required={true}
            />
          </Left>
        </Form.Row>
        {messagePinUpdated && (
          <Message>
            <Trans>The PIN code has been saved</Trans>
          </Message>
        )}
        <Card.Footer>
          <CenterRow style={{ justifyContent: 'space-between' }}>
            <Button variant="outline" color="ocean" onClick={handleBackButton}>
              <Trans>Back</Trans>
            </Button>
            <div>
              <Button
                color="ocean"
                disabled={!state.areMatching}
                style={{ marginRight: '2em' }}
              >
                <Trans>Save</Trans>
              </Button>
            </div>
          </CenterRow>
        </Card.Footer>
      </Form>
    </Card>
  ) : (
    <>
      <Header>
        <Trans>Change PIN Code</Trans>
      </Header>
      <Form onSubmit={handleSubmit}>
        <Form.Row>
          <Left>
            <Input
              label={
                <>
                  <Trans>New code PIN</Trans> (<Trans>4 characters</Trans>)
                </>
              }
              id="newPIN"
              type="password"
              maxLength="4"
              size="4"
              placeholder="_ _ _ _"
              onChange={handleOnChange}
              required={true}
            />
          </Left>
        </Form.Row>
        <Form.Row>
          <Left>
            <Input
              label={<Trans>Confirm your new code PIN</Trans>}
              style={
                !state.areMatching && state.confirmPinValue.length === 4
                  ? { borderColor: 'red' }
                  : {}
              }
              type="password"
              maxLength="4"
              size="4"
              id="confirmPIN"
              placeholder="_ _ _ _"
              onChange={handleOnChange}
              required={true}
            />
          </Left>
        </Form.Row>
        {messagePinUpdated && (
          <Message>
            <Trans>The PIN code has been saved</Trans>
          </Message>
        )}
        <Card.Footer>
          <CenterRow style={{ justifyContent: 'flex-start' }}>
            <div>
              <Button
                color="ocean"
                disabled={!state.areMatching}
                style={{ marginRight: '2em' }}
              >
                <Trans>Validate</Trans>
              </Button>
            </div>
          </CenterRow>
        </Card.Footer>
      </Form>
    </>
  )
}

export default CreateOrChangePinCode
