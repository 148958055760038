import React from 'react';
import styled from 'styled-components/macro';
import TeleconsultationWarnings from '../../Practitioner/containers/TeleconsultationWarnings';
import GridLayout from '../../Shared/components/GridLayout';
import Teleconsultations from '../containers/Teleconsultations';

const Container = styled.div`
  width: 100%;
`;

const WaitingRoomTeleconsultations = () => {
  return (
    <GridLayout.Main variant="full">
      <Container>
        {/* Alerts if RPPS, stripe or AM are missing for the practitioner */}
        <TeleconsultationWarnings />
        <Teleconsultations />
      </Container>
    </GridLayout.Main>
  );
};

export default WaitingRoomTeleconsultations;
