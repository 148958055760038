import React, { useContext } from 'react'
import { t, Trans } from '@lingui/macro'
import * as R from 'ramda'
import { mergeSimpleValue, ModelContext, ReadOnlyContext } from './utils'
import styled from 'styled-components/macro'
import { Label } from '../Components'
import { useI18n } from '../utils/I18nHookAdapter'

const Required = styled.span`
  margin-left: 0.25rem;
  color: ${p => p.theme.ocean};
`

const ReadOnly = styled.div`
  font-weight: 500;
`
const BetterSelect = styled.select`
  width: 100%;
  padding: 0.5rem;
  border-radius: 0.25rem;
  border-color: ${p => p.theme.gray};

  &:focus,
  &:hover {
    outline: none;
    border-color: ${p => p.theme.ocean};
    cursor: pointer;
  }
`
//YamlSelect relies on BetterSelect now, it will prevent some strange behavior
// such as display english value in the recap and so on
const YamlSelect = ({ yaml, ...rest }) => {
  const [model, setModel] = useContext(ModelContext)
  const readOnly = useContext(ReadOnlyContext)

  const lens = R.lensPath(yaml.value.split('.'))

  const handleChange = e => {
    setModel(mergeSimpleValue(lens, model, e))
  }

  const i18n = useI18n()
  const placeholder = i18n._(t`Select an option`)
  return (
    <div>
      <Label>
        {yaml.label} {yaml.required && <Required>*</Required>}
      </Label>
      {!readOnly && (
        <BetterSelect
          required={yaml.required}
          name={yaml.label}
          data-yaml-form={yaml.value}
          defaultValue={R.view(lens, model)}
          placeholder="Sélectionner une option"
          fallback={<Trans>No result found</Trans>}
          {...rest}
          onChange={handleChange}
          className="fs-block"
        >
          <option value="">{placeholder}</option>
          {yaml.options.map((o, i) => (
            <option key={i} value={o.value}>
              {o.children}
            </option>
          ))}
        </BetterSelect>
      )}
      {readOnly && (
        <ReadOnly>
          {/*look for an option to display with the good value*/}
          {yaml.options.find(o => o.value === R.view(lens, model))?.children ??
            '–'}
        </ReadOnly>
      )}
    </div>
  )
}

export default YamlSelect
