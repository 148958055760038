import React, { useContext } from 'react'
import styled from 'styled-components/macro'
import QuestionnaireRepeatableDocument from '../../Questionnaire/components/QuestionnaireRepeatableDocument'
import { MEDEO_ACTION_DEFINITION_SYSTEM } from './PlanDefinitionAction'
import { CurrentPractitionerRoleContext } from '../../PractitionerRole/containers/CurrentPractitionerRoleProvider'
import { GENERAL_PRACTITIONER_ESANTE } from '../../Permissions/roles'

const SectionForRow = styled.section`
  border-bottom: 1px solid ${p => p.theme.gray};
  margin: 2rem 0;
`

const SectionForColumn = styled.section`
  border-bottom: 1px solid ${p => p.theme.gray};
  margin: 2rem 0;
`

/*
 * Rows are used for Ordonnance
 */
const TwoColumnsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0.5rem;
`

const CustomH4 = styled.h4`
  margin: 0;
`

const PlanDefinitionActionDecoration = ({ children, action, readOnly }) => {
  const [currentPractitionerRole] = useContext(CurrentPractitionerRoleContext)
  // Display the right decoration
  const codeDeepLevel = action?.code?.[0]?.coding?.find(
    c => c.system === 'medeo-deep-level'
  )?.code

  switch (codeDeepLevel) {
    case 'section':
      const codeAction = action?.code?.[0]?.coding?.find(
        c => c.system === MEDEO_ACTION_DEFINITION_SYSTEM
      )?.code
      const shouldNotDisplay = codeAction === 'button' && readOnly
      if (shouldNotDisplay) {return <>{children}</>}
      else
        {return (
          <>
            {/* Here we add a label for documents on readOnly
          and also prevent the double divider style on tele-consultation */}
            {readOnly && <CustomH4>Documents</CustomH4>}
            <SectionForColumn>{children}</SectionForColumn>
          </>
        )}
    case 'section-row':
      return (
        <SectionForRow>
          <h4>{action.title}</h4>
          {action.action != null && (
            <TwoColumnsGrid>
              {action.action.map((a, i) => {
                return (
                  <QuestionnaireRepeatableDocument
                    action={a}
                    key={i}
                    name={action.title}
                    readOnly={readOnly}
                  />
                )
              })}
            </TwoColumnsGrid>
          )}
        </SectionForRow>
      )
    case 'page':
    default:
      // todo: find better solution for the below
      // add separation style to the below questionnaires
      // since pharmacist cannot see the diagnosis and the synthesis
      // we need to use the below roles, if not we will have separation borders
      return (action.title === 'Synthèse' &&
        currentPractitionerRole === GENERAL_PRACTITIONER_ESANTE) ||
        (action.title === 'Diagnostic' &&
          currentPractitionerRole === GENERAL_PRACTITIONER_ESANTE) ||
        action.title === 'Encaissement' ? (
        <SectionForColumn data-test={action.title}>{children}</SectionForColumn>
      ) : (
        children
      )
  }
}

export default PlanDefinitionActionDecoration
