import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { t, Trans } from '@lingui/macro'
import { Button, Input } from '../../Components'
import styled from 'styled-components/macro'
import { useLazySearch } from '../../Shared/hooks'
import { getSearchedPatients } from '../../Patient/selectors'
import PatientSearchDetails from '../../Patient/components/PatientSearchDetails'
import { lighten } from 'polished'
import { searchPatient } from '../../Patient/actions'
import { useI18n } from '../../utils/I18nHookAdapter'
import StaticErrorNotification, {
  ACTIVE_PROCEDURE_REQUEST
} from '../../Notifications/components/StaticErrorNotification'
import {
  MEDEO_PROCEDURE_REQUEST_BOOKING_APPOINTMENT_CODE,
  MEDEO_PROCEDURE_REQUEST_CODE_SYSTEM,
  MEDEO_PROCEDURE_REQUEST_REMOTE_CONSULTATION_CODE
} from '../../utils/codes'

const Title = styled.div`
  font-weight: bold;
  //to align with the rest
  padding-left: 1rem;
  margin-bottom: 1rem;
`
const SearchListInner = styled.div`
  overflow: scroll;
  max-height: 12rem;
  display: flex;
  flex-direction: column;
`

const SearchList = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: ${p => p.theme.boxShadow};
  margin-top: 1rem;
  border: 1px solid ${p => p.theme.gray};
  border-radius: 0.25rem;
  position: relative;

  & > ${SearchListInner} {
    padding-bottom: 3rem;
  }

  &::after {
    position: absolute;
    height: 3rem;
    border-radius: 0 0 0.25rem 0.25rem;
    left: 0;
    right: 0;
    bottom: 0;
    content: '';
    background: linear-gradient(0deg, white, transparent);
  }
`
const ListItem = styled.li.attrs({ role: 'button' })`
  list-style: none;
  border-radius: 0.25rem;

  &:hover {
    background-color: ${p => lighten(0.25, p.theme.ocean)};
  }

  &:active {
    background-color: ${p => lighten(0.33, p.theme.ocean)};
  }
`

const Container = styled.div`
  margin-top: 2rem;
`

/**
 * This component is to search for an existing patient and build
 * a questionnaire response out of the patient selected
 */
const QuestionnaireSearchPatient = ({ patient, onSelectChange }) => {
  const searchResult = useSelector(getSearchedPatients)
  const dispatch = useDispatch()

  // The state is composed of the questionnaire response built out of the selected patient
  // and of the patient info available in this very same response,
  // But just built so that it is easier to fetch to display it correctly
  const [state, setState] = useState({
    open: false,
    search: '',
    selected: patient,
    canGoNext: false
  })

  useEffect(() => {
    if (state.search?.length > 0) {dispatch(searchPatient(state.search))}
  }, [state.search, dispatch])

  // Here we prepare a query to get the active procedure request of the selected patient
  // We use the lazySearch in order to call it when we want, that is in this case
  // when a new patient was selected
  const [loadProcedureRequest, { data }] = useLazySearch('ProcedureRequest', {
    subject: `Patient/${state.selected?.id}`,
    _sort: '-_id',
    status: 'active'
  })

  const activeProcedureRequest = data?.ProcedureRequest?.find(
    procedureRequest => {
      const code = procedureRequest.code?.coding?.find(
        coding => coding.system === MEDEO_PROCEDURE_REQUEST_CODE_SYSTEM
      )?.code
      return (
        code === MEDEO_PROCEDURE_REQUEST_BOOKING_APPOINTMENT_CODE ||
        code === MEDEO_PROCEDURE_REQUEST_REMOTE_CONSULTATION_CODE
      )
    }
  )

  // When a new patient was selected
  useEffect(() => {
    if (state.selected == null) {return}

    onSelectChange({ patient: state.selected, cannotGoNext: false })

    // As a new patient was selected, we now load their procedure request
    loadProcedureRequest()
  }, [state.selected, onSelectChange, loadProcedureRequest])

  // When the procedure requests from a new patient got loaded
  useEffect(() => {
    if (activeProcedureRequest != null)
      // If it turns out that the just selected patient has already an ongoing procedure request,
      // we want to disable the user to go next
      {onSelectChange({ patient: state.selected, cannotGoNext: true })}
  }, [data, activeProcedureRequest, state.selected, onSelectChange])

  // Callback when the user clicks on a patient from the search displayed list
  const handleClick = patient => {
    setState(s => ({
      ...s,
      selected: patient
    }))
  }
  const i18n = useI18n()
  const placeholder = i18n._(t`Search your patient`)
  return (
    <Container>
      <Title>
        <Trans>Existing Patient</Trans>
      </Title>
      {state.selected != null ? (
        <>
          {activeProcedureRequest != null && (
            <div>
              <StaticErrorNotification
                patientID={state.selected.id}
                error={ACTIVE_PROCEDURE_REQUEST}
              />
            </div>
          )}
          <PatientSearchDetails patient={state.selected} />
          <Button
            data-testid="switchButton"
            variant="text"
            onClick={() => setState(s => ({ ...s, selected: null }))}
          >
            <Trans>Switch patient</Trans>
          </Button>
        </>
      ) : (
        <>
          <Input
            data-testid="searchPatientBar"
            value={state.search}
            label=""
            placeholder={placeholder}
            onChange={e => {
              const { value } = e.target
              // if we delete the search, hide the dropdown
              setState(s => ({ ...s, search: value, open: value !== '' }))
            }}
          />
          {state.open === true && (
            <SearchList>
              <SearchListInner>
                {searchResult.map(patient => (
                  <ListItem
                    key={patient.id}
                    data-testid={`patient_${patient.id}`}
                    onClick={() => handleClick(patient)}
                  >
                    <PatientSearchDetails
                      key={patient.id}
                      patient={patient}
                      onClick={handleClick}
                    />
                  </ListItem>
                ))}
              </SearchListInner>
            </SearchList>
          )}
        </>
      )}
    </Container>
  )
}

QuestionnaireSearchPatient.defaultProps = {
  onSelectChange: () => {},
  patient: null
}
export default QuestionnaireSearchPatient
