import * as Sentry from '@sentry/react'
import { API } from 'aws-amplify'
import { getTokenFromAmplify } from '../Auth/utils'

/**
 * Call the cerfa lambda and return a valueAttachement containing the url where the binary
 * was saved
 *
 * @param {*} patientID
 * @param {*} responseID - the id of saved fhir questionnaire response
 * @returns {Object}: {contentType, title, url}
 */
export const getCerfaBinaryId = async (patientID, responseID) => {
  const token = await getTokenFromAmplify()

  const apiName = 'cerfa'
  const path = '/build'

  const init = {
    headers: {
      Accept: 'application/pdf',
      'Content-Type': 'application/json'
    },
    queryStringParameters: {
      token: token,
      questionnaireResponseId: responseID
    }
  }
  try {
    const response = await API.get(apiName, path, init)
    return {
      contentType: 'application/pdf',
      title: response.fileName,
      url: response.binaryUrl
    }
  } catch (e) {
    // Here we check the status of the cerfa upload response
    // If the upload was unsuccessful, we send an error to Sentry
    console.warn(e)
    Sentry.captureException(
      new Error(
        `File could not be uploaded successfully for patient ${patientID}, response ${responseID}`
      )
    )
  }
}
