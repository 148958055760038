import { Router } from '@reach/router'
import { useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isEqual } from 'underscore'
import { PRACTITIONER_UNSELECTED } from '../actions'
import { getPractitionerAuthState } from '../selector'
import usePractitioner from '../usePractitioner'
import EditPinCodeForm from './EditPinCodeForm'
import SelectPractitionerForm from './SelectPractitionerForm'
import TypePinCodeForm from './TypePinCodeForm'

// steps for practitioner authentication
export const SELECT_PRACTITIONER_FORM = 'stepSelectPractitionerForm'
export const TYPE_CODE_PIN_FORM = 'stepTypeCodePinForm'
export const USER_AUTHORIZED = 'stepUserAuthorized'
export const CREATE_PIN_CODE_FORM = 'stepCreatePinCodeForm'

// Context for practitioner authentication reducer
export const CONTEXT_TELECONSULTATION_FINISHED =
  'contextTeleconsultationFinished'

const Auth = ({ location }) => {
  const currentPractitionerFromContext = usePractitioner()
  const practitionerAuthState = useSelector(getPractitionerAuthState, isEqual)
  const reduxDispatch = useDispatch()
  const fromLocation = location?.state?.from ?? '/'
  const from = useRef(fromLocation)

  // update the ReducerState when the currentPractitionerContext is updated to null
  const shouldDispatchPractitionerUnselected =
    currentPractitionerFromContext == null &&
    practitionerAuthState.step !== SELECT_PRACTITIONER_FORM &&
    practitionerAuthState.step !== TYPE_CODE_PIN_FORM &&
    practitionerAuthState.step !== CREATE_PIN_CODE_FORM
  if (shouldDispatchPractitionerUnselected) {
    reduxDispatch({ type: PRACTITIONER_UNSELECTED })
  }

  return (
    <Router>
      <EditPinCodeForm path=":practitionerID/pin/create" />
      <TypePinCodeForm path=":practitionerID/pin" from={from.current} />
      <SelectPractitionerForm default />
    </Router>
  )
}

export default Auth
