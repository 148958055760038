import React, { useContext } from 'react'
import styled from 'styled-components/macro'
import { Form, Input, Label } from '../../Components'
import { getDisplay, getItemByLinkId, onChangeQuestionnaire } from '../utils'
import { QuestionnaireResponseContext } from './Questionnaire'

const StyledInput = styled(Input)`
  &:hover {
    background: ${props => props.readOnly & ''};
  }
  ${props =>
    props.singlecheckbox
      ? 'border: none; display: flex; align-items: baseline; line-height: 1.5'
      : null};
`

const StyledFormRow = styled(Form.Row)`
  display: flex;
  flex-direction: column;
`

const Required = styled.span`
  margin-left: 0.25rem;
  color: ${p => p.theme.ocean};
`

/**
 * Component for display of checkbox questionnaire item
 * We assume that the options, and as a result the responses,
 * can only be of type valueCoding
 */
const QuestionnaireCheckbox = ({ item, readOnly }) => {
  const [questionnaireResponse, dispatchQuestionnaireResponse] = useContext(
    QuestionnaireResponseContext
  )
  // Now we only support valueCoding for every item of type "choice"
  // That is why we only look for valueCoding values
  const selectedValues =
    getItemByLinkId(questionnaireResponse, item.linkId)?.answer?.map?.(
      answer => answer.valueCoding
    ) ?? []

  // we check if the checkbox input is a single checkbox or a group of
  // checkbox if it's a single checkbox we don't need to see the label
  const singlecheckbox = item.code?.some?.(i => i.code === 'single-checkbox')

  return item.option != null ? (
    <StyledFormRow className="fs-block">
      {!singlecheckbox && (
        <Label>
          {getDisplay(item)} {item.required && <Required>*</Required>}
        </Label>
      )}
      {item.option.map(o => (
        <div key={o.valueCoding?.code}>
          <StyledInput
            singlecheckbox={singlecheckbox?.toString()} // we put it to string to avoid error in console
            key={o.valueCoding?.code}
            // We only want one of the checkboxes to be checked. As it is not possible to
            // use the require attribute for the group but only for every single one,
            // once one of the boxes is already checked we don't need this required attributes
            // for the others anymore
            required={item.required && selectedValues.length === 0}
            type="checkbox"
            label={o.valueCoding?.display}
            name={item.linkId}
            data-test={`${item.linkId}-${o.valueCoding?.code}`}
            value={o.valueCoding?.code}
            readOnly={readOnly}
            checked={selectedValues.some(
              value => o.valueCoding?.code.toString() === value.code.toString()
            )}
            onChange={e => {
              if (!readOnly) {
                onChangeQuestionnaire({
                  questionnaireResponse: questionnaireResponse,
                  dispatchQuestionnaireResponse: dispatchQuestionnaireResponse,
                  name: e.target.name,
                  value: o.valueCoding,
                  type: e.target.type,
                  checked: e.target.checked
                })
              }
            }}
          />
        </div>
      ))}
    </StyledFormRow>
  ) : null
}

export default QuestionnaireCheckbox
