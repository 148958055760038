import { getAllEntities } from '../Shared/selectors'
import { getTypeAndIdFromLocalReference } from '../utils/fhir'
import { GENERAL_PRACTITIONER_ESANTE } from '../Permissions/roles'
import { MEDEO_ADMINISTRATOR_CODE } from '../utils/codes'

export const getAllSpecificPractitionerRoles = (state, code = 'medical') => {
  return getAllEntities(state, 'practitionerRoles').filter(r => {
    return r?.identifier?.find(i => i?.type?.coding?.[0]?.code === code)
  })
}

export const filterByTypeCode = code => item =>
  item.identifier?.find?.(identifier =>
    identifier.type?.coding?.some?.(coding => coding.code === code)
  )

/**
 * return a subarray containing every roles with the current specialty code
 * @param {string} code - the specialty code used to filter
 * @return {function(*): *}
 */
export const filterBySpecialty = code => role =>
  role.specialty?.some?.(specialty =>
    specialty.coding?.some?.(coding => coding.code === code)
  )

/**
 * We try something different in this selector, instead of exposing two args
 * we compose functions calls:
 * this way the selector will fit more nicely in a useSelector:
 * @example
 * const orgId= '1234'
 * const roles = useSelector(getPractitionerRolesByOrganizationId(orgId))
 * // instead of
 * const roles =useSelector(s=> getPractitionerRolesByOrganizationId(s, orgId))
 * @param organizationId
 * @return {function(*): [PractitionerRole]}
 */
export const getRolesByOrganizationId = organizationId => state =>
  state.practitionerRoles.byOrganizationId?.[organizationId]?.map?.(
    id => state.practitionerRoles.byId[id]
  ) ?? []

export const getPractitionerRolesBypractitionerID = (state, practitionerID) =>
  state.practitionerRoles.bypractitionerID?.[practitionerID]?.map?.(
    id => state.practitionerRoles.byId[id]
  ) ?? []

export const getPractitionerRoleBypractitionerID = (
  state,
  practitionerID,
  code = 'medical'
) => {
  return (
    getPractitionerRolesBypractitionerID(state, practitionerID)?.filter(
      filterByTypeCode(code)
    ) ?? []
  )
}

const getId = str => getTypeAndIdFromLocalReference(str)[1]

// this selector is being use only to get the practitionerRole without having
// the id of the current practitioner for instance in the SelectPractitionerForm
export const getPractitionerRolesBypractitionerIDFromState = (
  state,
  code = 'medical'
) => {
  return getAllSpecificPractitionerRoles(state, code)
    .filter(e => e.practitioner != null)
    .reduce(
      (obj, role) => ({
        ...obj,
        [getId(role.practitioner.reference)]: obj[
          getId(role.practitioner.reference)
        ]
          ? obj[getId(role.practitioner.reference)].concat(role)
          : [role]
      }),
      {}
    )
}

export const getAdministratorRightBypractitionerID = (
  state,
  practitionerID,
  code = MEDEO_ADMINISTRATOR_CODE
) => {
  return getPractitionerRoleBypractitionerID(state, practitionerID, code).some(
    r => {
      return r?.specialty?.some(s => s.coding?.[0]?.code === 'administrator')
    }
  )
}

export const getPractitionerRolesByOrganizationId = (state, organizationId) => {
  return getAllSpecificPractitionerRoles(state, 'medical').filter(
    pr =>
      pr.organization.reference === `Organization/${organizationId}` &&
      pr.specialty.find(
        specialty => specialty.coding[0].code === GENERAL_PRACTITIONER_ESANTE
      )
  )
}
