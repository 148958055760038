import React, { useContext, useState } from 'react'
import { Button, Form, Input } from '../../Components'
import { EditPatientContext } from '../containers/EditPatientForm'
import { Trans } from '@lingui/macro'

const IdentifierInputRow = ({ identifier, index, readOnly, ...rest }) => {
  // If the patient has been recorded before december 2019 then the identifier
  // may not have any type defined. We show fallback message instead.

  // Keep track of the identifier so we can later use this state when we
  // save the edit.
  // const [state, setState] = useState(identifier)
  const [resource, setResource] = useContext(EditPatientContext)
  const [inputIsVisible, setInputIsVisible] = useState(false)

  const label = identifier?.type?.coding?.[0]?.display || 'Identifiant'

  const handleChange = e => {
    const { value } = e.currentTarget
    const previous = resource.identifier

    // identifier is updated with the value the user type in.
    const identifier = {
      ...previous[index],
      value
    }

    // we replace the current identifier
    const next = [
      ...previous.slice(0, index),
      identifier,
      ...previous.slice(index + 1)
    ]
    setResource({ ...resource, identifier: next })
  }

  return identifier.value ? (
    <Form.Row>
      <Input
        name={label}
        readOnly={readOnly}
        label={label}
        value={identifier.value}
        onChange={handleChange}
        {...rest}
      />
    </Form.Row>
  ) : inputIsVisible ? (
    <Form.Row>
      <Input
        name={label}
        {...rest}
        label={label}
        value={identifier.value}
        onChange={handleChange}
      />
    </Form.Row>
  ) : (
    <Form.Row>
      <Trans>No {label} was added</Trans>
      {!readOnly ? (
        <Button
          color="ocean"
          onClick={e => {
            e.preventDefault()
            setInputIsVisible(true)
          }}
        >
          <Trans>Add {label}</Trans>
        </Button>
      ) : null}
    </Form.Row>
  )
}

export default IdentifierInputRow
