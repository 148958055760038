import { useNavigate } from '@reach/router'
import { Table } from '../../Components'
import { Trans } from '@lingui/macro'
import PatientTableRow from './PatientTableRow'
import React from 'react'
import styled from 'styled-components/macro'
import { useSearch } from '../../Shared/hooks'
import { useSelector } from 'react-redux'
import { getCurrentOrganizationId } from '../../Auth/selectors'

const HeaderCell = styled.th``

const TotalHeaderCell = () => {
  const organizationId = useSelector(getCurrentOrganizationId)
  const { data } = useSearch('Patient', {
    _summary: 'count',
    _filter: `( organization eq ${organizationId} or general-practitioner eq Organization/${organizationId} )`
  })

  return (
    <HeaderCell>
      <Trans>Patients</Trans> {data && `(${data.total})`}
    </HeaderCell>
  )
}

const PatientsTable = ({ patients }) => {
  const navigate = useNavigate()

  return (
    <Table style={{ margin: 0 }}>
      <thead>
        <tr>
          <TotalHeaderCell />
          <HeaderCell>
            <Trans>Age</Trans>
          </HeaderCell>
          <HeaderCell>
            <Trans>Gender</Trans>
          </HeaderCell>
          <HeaderCell>
            <Trans>Last Visit</Trans>
          </HeaderCell>
        </tr>
      </thead>
      <tbody>
        {patients.map(patient => (
          <PatientTableRow
            key={patient.id}
            patient={patient}
            onClick={() => navigate(`/patient/${patient.id}`)}
          />
        ))}
      </tbody>
    </Table>
  )
}
export default PatientsTable
