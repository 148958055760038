import React from 'react';
import { useRef, useState } from 'react';
import { getUnitFromType, LOINC_BLOOD_PRESSURE_CODE } from '../../utils/loinc';
import { saveObservation } from '../actions';
import { useDispatch } from 'react-redux';
import styled from 'styled-components/macro';
import { Trans } from '@lingui/macro';
import usePatient from '../../Patient/usePatient';
import usePractitioner from '../../Practitioner/usePractitioner';

const Unit = styled.span`
  margin-left: ${props => props.theme.spacing.small};
`;

const CustomForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  width: 100%;
`;

const Container = styled.div`
  display: flex;
  align-items: baseline;
  width: 100%;
`;

const WarningMessage = styled.div`
  font-size: small;
  color: ${p => p.theme.gray};
`;

const CustomInput = styled.input`
  width: 100%;
`;

const EmptyValueQuantityView = ({ observationType }) => {
  if (observationType == null) {throw new Error('missing observationType');}
  const inputRef = useRef(null);
  const inputRefSys = useRef(null);
  const inputRefDia = useRef(null);
  const dispatch = useDispatch();
  const currentPractitioner = usePractitioner();
  const currentPatient = usePatient();

  const valueUnit = getUnitFromType(observationType);

  const [shouldDisplayWarning, setShouldDisplayWarning] = useState(false);

  const handleOnChange = e => {
    setShouldDisplayWarning(e.target.value !== '');
  };

  const handleSubmit = e => {
    e.preventDefault();
    let observation = {
      loincCoding: observationType,
      patientID: currentPatient.id,
      effectiveDateTime: new Date(),
      practitioner: currentPractitioner.id
    };
    if (observationType === LOINC_BLOOD_PRESSURE_CODE) {
      observation.systolicBloodPressure = inputRefSys.current.value;
      observation.diastolicBloodPressure = inputRefDia.current.value;
      //observation.pulse = inputRefPulse.current.value
    } else {
      observation.value = inputRef.current.value;
    }
    dispatch(saveObservation(observation));
  };

  return (
    <CustomForm onSubmit={handleSubmit}>
      {observationType !== LOINC_BLOOD_PRESSURE_CODE && (
        <>
          <Container>
            <CustomInput
              type={'number'}
              ref={inputRef}
              required
              onChange={handleOnChange}
            />
            <Unit>{valueUnit}</Unit>
          </Container>
          {shouldDisplayWarning && (
            <WarningMessage>
              <Trans>Press Enter to save your measure</Trans>
            </WarningMessage>
          )}
        </>
      )}
      {observationType === LOINC_BLOOD_PRESSURE_CODE && (
        <div>
          <Container>
            <CustomInput
              type={'number'}
              ref={inputRefSys}
              required
              style={{ width: '5rem' }}
              onChange={handleOnChange}
            />
            <Unit>{valueUnit}</Unit>
          </Container>
          <span style={{ marginLeft: '2rem' }}>/</span>
          <Container>
            <CustomInput
              type={'number'}
              ref={inputRefDia}
              required
              style={{ width: '5rem' }}
              onChange={handleOnChange}
            />
            <Unit>{valueUnit}</Unit>
          </Container>
          <input type={'submit'} style={{ display: 'none' }} />
          {/*}<input ref={inputRefPulse} required style={{ width: '2rem' }} />}*/}
          {shouldDisplayWarning && (
            <WarningMessage>
              <Trans>Press Enter to save your measure</Trans>
            </WarningMessage>
          )}
        </div>
      )}
    </CustomForm>
  );
};

export default EmptyValueQuantityView;
