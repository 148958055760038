// @ts-ignore
import { uniq } from 'lodash'
import {
  getAllEntities,
  getFhirResourceFromLocalReference
} from '../Shared/selectors'
import { createLocalReference } from '../utils/fhir'
import { sortByEndDate } from '../utils/dateUtils'
import { ReduxState } from '../Shared/ducks/types'
import { BundleLink, Encounter, EncounterParticipant } from 'fhir-stu3'

export const filterOutEnteredInErrorEncounter = (e: Encounter): boolean =>
  e.status !== 'entered-in-error'

export const getAllEncounters = (state: ReduxState) =>
  getAllEntities(state, 'encounters')

export const getEncountersFromSubjectId = (
  subjectId: string,
  filters: { (e: Encounter): boolean }[] = [() => true]
) => (state: ReduxState) => {
  if (subjectId == null) {return []}
  //create a reference object from subjectId.
  const reference = createLocalReference('Patient', subjectId)
  return getAllEncounters(state)
    .filter(
      (e: Encounter) => e.subject != null && e.subject.reference === reference
    )
    .filter((e: Encounter) => filters.every(filter => filter(e)))
}

export const getEncounterById = (encounterID: string) => (
  state: ReduxState
) => {
  return state.encounters.byId[encounterID]
}

export const getCurrentlyShownEncounters = (
  filters: { (e: Encounter): boolean }[] = [() => true]
) => (state: ReduxState) =>
  state.encounters.shown.items
    .map((id: string) => state.encounters.byId[id])
    .filter((e: Encounter) => e.status !== 'entered-in-error')
    .filter((e: Encounter) => filters.every(f => f(e)))

export const getShownEncounters = (state: ReduxState) => state.encounters.shown
export const getShownEncountersLink = (state: ReduxState) =>
  state.encounters.shown.link
export const getShownEncountersHasMore = (state: ReduxState) =>
  getShownEncountersLink(state).some(
    (link: BundleLink) => link.relation === 'next'
  )

export const getCurrentEncounter = (state: ReduxState) => {
  return state.encounters.currentId == null
    ? null
    : state.encounters.byId[state.encounters.currentId]
}

export const getEncounterFromId = (state: ReduxState, encounterID: string) => {
  return state.encounters.byId[encounterID]
}

export const getParticipantFromencounterID = (encounterID: string) => (
  state: ReduxState
) => {
  const encounter = getEncounterFromId(state, encounterID)
  if (
    Array.isArray(encounter.participant) &&
    encounter.participant.length > 0
  ) {
    return encounter.participant.map((p: EncounterParticipant) => {
      const reference = p.individual?.reference
      return getFhirResourceFromLocalReference(state, reference)
    })
  }
  return null
}

export const getEncountersDate = (encounters: Encounter[]) => {
  let encounterDates: string[] = []
  encounters.map(e => {
    if (e.period && e.period.end) {
      encounterDates.push(
        e.period.end.substring(8, 10) +
          '/' +
          e.period.end.substring(5, 7) +
          '/' +
          e.period.end.substring(0, 4)
      )
    }
    return null
  })
  return uniq(encounterDates)
}

export const filterEncounterByDates = (
  date: string,
  encounterList: Encounter[]
) => {
  return encounterList.filter(encounter => {
    return (
      date ===
      encounter.period?.end?.substring(8, 10) +
        '/' +
        encounter.period?.end?.substring(5, 7) +
        '/' +
        encounter.period?.end?.substring(0, 4)
    )
  })
}

/**
 * Returns a filter function to be used with an array of Encounter.
 * make sure the filtered encounter e has a type code of the code passed as parameter
 * @param code
 * @returns {function(*=): boolean}
 */
export const filterEncountersByTypeCode = (code: string) => (
  e: Encounter
): boolean => {
  const encounterCode = e.type?.[0]?.coding?.[0]?.code
  return encounterCode === code
}

/**
 * This selector can be upgraded with filters and sort functions
 * It return the most recent encounter according to the filter and the sort method
 * passed as parameters.
 *
 * It returns null if no encounter is found or if no encounter has passed the filters
 *
 * @param state
 * @param subjectId
 * @param filterFns
 * @param sortFn
 * @returns {*|null}
 */
export const getLastEncounterFromSubjectIdWithFilters = (
  state: ReduxState,
  subjectId: string,
  filterFns: { (e: Encounter): boolean }[] = [],
  sortFn = sortByEndDate
) =>
  getEncountersFromSubjectId(subjectId)(state)
    .filter((e: Encounter) => filterFns.every(filterFn => filterFn(e)))
    .sort(sortFn)
    .shift() || null

/**
 * @deprecated
 * @param state
 * @param code
 * @param subjectId
 */
export const getLastEncounterDateByCodeFromSubjectId = (
  state: ReduxState,
  code: string,
  subjectId: string
) =>
  getLastEncounterFromSubjectIdWithFilters(state, subjectId, [
    filterEncountersByTypeCode(code)
  ])

export const getLastEncounterFromSubjectIdBycode = (
  subjectId: string,
  code: string
) => (state: ReduxState) => {
  return getLastEncounterFromSubjectIdWithFilters(state, subjectId, [
    filterEncountersByTypeCode(code)
  ])
}

export const getEncounterByAppointmentId = (
  state: ReduxState,
  appointmentId: string
) => {
  getAllEncounters(state).filter(
    (e: Encounter) => e.appointment === `Appointment/${appointmentId}`
  )
}
