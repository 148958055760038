import { getTypeAndIdFromLocalReference } from '../../utils/fhir'
import pluralize from 'pluralize'
import camelCase from 'lodash/camelCase'

export const getAllEntities = (state, entityName) => {
  if (state[entityName] == null) {
    throw new Error(
      `${entityName} is not defined, make sure it is combined in the main reducer`
    )
  }
  return state[entityName].allIds.map(id => state[entityName].byId[id])
}
export const getFhirResourceFromSubjectId = (
  state,
  resourceType,
  subjectId
) => {
  return getAllEntities(state, resourceType).filter(
    r => r.subject && r.subject.reference === 'Patient/' + subjectId
  )
}

export const getFhirResourceFromencounterID = (
  state,
  resourceType,
  encounterID
) => {
  return getAllEntities(state, resourceType).filter(
    r => r.context && r.context.reference === 'Encounter/' + encounterID
  )
}
export const getFhirResourceFromLocalReference = (state, reference) => {
  const [type, id] = getTypeAndIdFromLocalReference(reference)
  // get the plural from the type and use camelCase.
  const entityName = pluralize(camelCase(type))
  if (state[entityName] != null && state[entityName].byId != null) {
    return state[entityName].byId[id]
  }
  // TODO maybe throw an error...
  return null
}
