import React from 'react'
import { useSelector } from 'react-redux'
import { Label } from '../../Components'
import { getIdByReference } from '../../Shared/utils'
import useSearch from '../../Shared/hooks/useSearch'
import { selectTLCDevices } from '../../Device/utils'
import DeviceItem from './DeviceItem'
import { getEncounterFromId } from '../../Encounter/selectors'
import styled from 'styled-components/macro'
import { Trans } from '@lingui/macro'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

/**
 * Display the list of devices owned by the requester organization
 *
 * @param {String} preconsultationId: The id of the preconsultation encounter
 * we will need it to fetch the location and its devices
 * @returns
 */
const DevicesListForDistant = ({ preconsultationId }) => {
  const preconsultationEncounter = useSelector(state =>
    getEncounterFromId(state, preconsultationId)
  )

  const appointmentId = getIdByReference(
    preconsultationEncounter?.appointment?.reference
  )

  const { data } = useSearch('Appointment', {
    _id: appointmentId,
    _include: 'Appointment:actor:Location'
  })

  const [location] = data?.Location ?? []

  const requesterOrgRef = location?.managingOrganization?.reference
  const requesterOrgId = getIdByReference(requesterOrgRef)

  const { data: orgDevices } = useSearch('Device', {
    organization: requesterOrgId
  })

  const tlcDevices = selectTLCDevices(orgDevices?.Device)

  return (
    <>
      {tlcDevices.length > 0 && (
        <Label>
          <Trans>Available medical devices</Trans>
        </Label>
      )}
      <Container>
        {tlcDevices.map((deviceCode, i) => (
          <DeviceItem key={i} number={i} deviceCode={deviceCode} />
        ))}
      </Container>
    </>
  )
}

export default DevicesListForDistant
