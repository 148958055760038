import React, { useReducer } from 'react'
import { Auth } from 'aws-amplify'
import { Input, Button } from '../../Components'
import styled from 'styled-components/macro'
import { Trans } from '@lingui/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'

const Form = styled.form`
  display: flex;
  flex-direction: column;
  & > ${Input}:not(:last-of-type) {
    margin-bottom: 1.5rem;
  }
  & > ${Button} {
    margin-top: 2.5rem;
    min-width: 14rem;

    align-self: center;
  }
`

const Hint = styled.span`
  margin-top: 0.5rem;
  font-size: small;
  display: flex;
  align-items: center;
  color: ${p => p.theme.nevada};
  & > svg {
    opacity: 0.5;
    width: 1.5rem !important;
    height: 1.5rem;
    margin-right: 0.5rem;
  }
`

const initialState = {
  step: 'forgotPassword',
  isLoading: false,
  error: null,
  email: null
}

// there are two steps in the amplify auth doc
// forgotPassword and forgotPasswordSubmit
const reducer = (state, action) => {
  switch (action.type) {
    case 'loading':
      return { ...state, loading: true }
    case 'succeeded':
      return {
        ...state,
        step: 'forgotPasswordSubmit',
        loading: false,
        error: null,
        email: action.payload.email
      }
    case 'failed':
      return {
        ...state,
        loading: false,
        error: action.payload.error
      }
    case 'mismatched':
      return {
        ...state,
        loading: false,
        error: { message: 'Les valeurs des mots de passe sont différentes' }
      }
    default:
      throw new Error()
  }
}

const CustomPasswordRecoveryForm = () => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <Form
      onSubmit={e => {
        e.preventDefault()
        dispatch({ type: 'loading' })
        const { elements } = e.currentTarget

        if (state.step === 'forgotPassword') {
          const email = elements.namedItem('email').value
          Auth.forgotPassword(email)
            .then(() => {
              dispatch({ type: 'succeeded', payload: { email } })
            })
            .catch(error => {
              dispatch({ type: 'failed', payload: { error } })
            })
        } else if (state.step === 'forgotPasswordSubmit') {
          const code = elements.namedItem('code').value
          const password = elements.namedItem('password').value
          const confirmation = elements.namedItem('confirmation').value
          if (password !== confirmation) {
            dispatch({ type: 'mismatched' })
            return
          }
          Auth.forgotPasswordSubmit(state.email, code, password)
            .then(() => {
              window.history.back()
            })
            .catch(error => {
              dispatch({ type: 'failed', payload: { error } })
            })
        }
      }}
    >
      {/*
        password recovery occurs in two steps, first we ask for the email address
        once the address is submitted, we display the inputs for the verification code and the new password.
      */}
      {state.step === 'forgotPassword' ? (
        <Input
          type="email"
          name="email"
          id="email"
          label={<Trans>Email address</Trans>}
          required
        />
      ) : (
        <>
          <span>
            <Trans>
              One more step... we have sent you a verification on your email
              address. Please type this code along with your new password.
            </Trans>
          </span>
          <br />
          <Input
            type="text"
            name="code"
            id="code"
            label={<Trans>Verification code</Trans>}
            required
          />
          <br />
          <Input
            type="password"
            name="password"
            id="password"
            minLength={8}
            label={<Trans>New password</Trans>}
            required
          />
          <Input
            type="password"
            name="confirmation"
            id="confirmation"
            minLength={8}
            label={<Trans>Confirm password</Trans>}
            required
          />
          <Hint>
            <FontAwesomeIcon icon={faInfoCircle} />
            <Trans>Your password must include more than 8 characters</Trans>
          </Hint>
        </>
      )}
      <Button disabled={state.isLoading === true}>
        {state.isLoading === true ? (
          <Trans>Please wait...</Trans>
        ) : state.step === 'forgotPassword' ? (
          <Trans>Send recovery mail</Trans>
        ) : (
          <Trans>Reset password</Trans>
        )}
      </Button>
      {state.error != null && <span>{state.error.message}</span>}
    </Form>
  )
}

export default CustomPasswordRecoveryForm
