import React, { useState, createContext, useContext } from 'react'

export const CurrentOrganizationIdContext = createContext([null, () => {}])

export const CurrentOrganizationIdProvider = ({
  children,
  initialState = null,
}) => {
  const [currentOrganization, setCurrentOrganization] = useState(initialState)
  return (
    <CurrentOrganizationIdContext.Provider
      value={[currentOrganization, setCurrentOrganization]}
    >
      {children}
    </CurrentOrganizationIdContext.Provider>
  )
}

const useCurrentOrganizationId = () => {
  const [orgId] = useContext(CurrentOrganizationIdContext)
  return orgId
}
export default useCurrentOrganizationId
