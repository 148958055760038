import { faUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from '../../Components'
import { Trans } from '@lingui/macro'
import { useNavigate } from '@reach/router'
import { useSelector } from 'react-redux'
import React from 'react'
import styled from 'styled-components/macro'
import { getAdministratorRightBypractitionerID } from '../../PractitionerRole/selector'
import Header from '../../Settings/components/Header'
import OrganizationsList from '../../Practitioner/containers/OrganizationsList'
import TeamTable from '../../Practitioner/containers/TeamTable'
import CollapsibleCard from '../../Shared/components/CollapsibleCard'
import OutsideCollaboratorsTable from '../../Practitioner/containers/OutsideCollaboratorsTable'
import OutsideCollaboratorRow from '../../Practitioner/components/OutsideCollaboratorRow'
import { MEDEO_ADMINISTRATOR_CODE } from '../../utils/codes'
import usePractitioner from '../../Practitioner/usePractitioner'

const CustomButton = styled(Button)`
  & > :first-child {
    margin-right: 0.5rem;
  }
`

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
const Section = styled.div`
  margin-bottom: 1rem;
`
const MessageDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const UsersPage = () => {
  const navigate = useNavigate()
  const currentPractitioner = usePractitioner()
  const isAdmin = useSelector(s =>
    getAdministratorRightBypractitionerID(
      s,
      currentPractitioner.id,
      MEDEO_ADMINISTRATOR_CODE
    )
  )

  return isAdmin ? (
    <>
      <Container>
        <h1>
          <Trans>Users</Trans>
        </h1>
        <CustomButton
          color="ocean"
          onClick={() => {
            navigate(`organization/practitioner/new/`)
          }}
          data-intercom-target="add-user"
        >
          <FontAwesomeIcon icon={faUser} />
          <Trans>Add a new user</Trans>
        </CustomButton>
      </Container>

      <Section>
        <Header>
          <Trans>Team</Trans>
        </Header>
        <TeamTable />
      </Section>
      <Section>
        <Header>
          <Trans>Outside collaborators</Trans>
        </Header>
        <OrganizationsList>
          {(o, i) => (
            <CollapsibleCard organization={o} key={i} title={o.name}>
              <OutsideCollaboratorsTable organization={o}>
                {practitioner => (
                  <OutsideCollaboratorRow
                    key={practitioner.id}
                    practitioner={practitioner}
                  />
                )}
              </OutsideCollaboratorsTable>
            </CollapsibleCard>
          )}
        </OrganizationsList>
      </Section>
    </>
  ) : (
    //prevent some "malin" to come with the url and not with button of SettingsNav
    <MessageDiv>
      <Trans>
        You don't have the right to access for this part of the Medeo
        Application. Check App's Administrator to get more information.
      </Trans>
    </MessageDiv>
  )
}

export default UsersPage
