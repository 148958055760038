import * as R from 'ramda';
import { createContext } from 'react';

export const ModelContext = createContext({});
export const ReadOnlyContext = createContext(false);

export const mergeSimpleValue = (lens, model, e) =>
  R.mergeDeepRight(model, R.set(lens, e.target.value, {}));

export const mergeObject = (model, object) => R.mergeDeepRight(model, object);
export const mergeArray = (lens, model, array) =>
  R.mergeDeepRight(model, R.set(lens, array, {}));

export const mergeMappedValue = (lens, model, index, name, e) => {
  let prop = R.view(lens, model);

  let array = model == null || !Array.isArray(prop) ? [] : prop;
  let currentItem = array[index];

  currentItem = {
    ...currentItem,
    [name]: e.target.value
  };

  array = [...array.splice(0, index), currentItem, ...array.splice(index + 1)];
  return R.mergeDeepRight(model, R.set(lens, array, {}));
};
