import React from 'react';
import { useSelector } from 'react-redux';
import {
  ESANTE_OCCUPATION_SYSTEM,
  THIRD_PARTY_PAYER_IDENTIFIER_SYSTEM
} from '../../utils/codes';
import StaticErrorNotification, {
  ACCEPT_THIRD_PARTY_PAYER_NOT_SET
} from '../../Notifications/components/StaticErrorNotification';
import { getPractitionerRoleBypractitionerID } from '../../PractitionerRole/selector';
import {
  GENERAL_PRACTITIONER_ESANTE,
  getSpecialtyCodingFromSystem
} from '../../Permissions/roles';
import usePractitioner from '../usePractitioner';

/**
 * Warning for general practitioner to inform on whether they accept
 * third party payer or not if they have not done it so far.
 */
const PractitionerThirdPartyPayerWarning = () => {
  const currentPractitioner = usePractitioner();

  const [medicalRole] = useSelector(s =>
    getPractitionerRoleBypractitionerID(s, currentPractitioner.id, 'medical')
  );
  const isDoctor =
    getSpecialtyCodingFromSystem(medicalRole, ESANTE_OCCUPATION_SYSTEM)
      ?.code === GENERAL_PRACTITIONER_ESANTE;

  // We display this alert for doctors only
  if (isDoctor === false) {return null;}

  const hasAcceptsThirdPartyPayerSet =
    currentPractitioner?.identifier?.find(
      identifier => identifier.system === THIRD_PARTY_PAYER_IDENTIFIER_SYSTEM
    )?.value != null;

  return (
    hasAcceptsThirdPartyPayerSet === false && (
      <StaticErrorNotification error={ACCEPT_THIRD_PARTY_PAYER_NOT_SET} />
    )
  );
};

export default PractitionerThirdPartyPayerWarning;
