import React from 'react'
import DataListItem from '../../Shared/components/DataListItem'

/**
 * Receives the item of the datalist from the TagInput
 * the display is adapted to the type of data we show in our case a FHIR Medication
 * @param item a FHIR Medication resource
 * @param onClick function to be called when the user click on this list item
 * @param rest
 * @returns {*}
 * @constructor
 */
const MedicationDataListItem = ({ item, onClick, ...rest }) => {
  // little hack here, | means OR in fhirpath, thus we get the three results
  // in a row...

  const { display: name, code: cis } = item?.code?.coding?.[0]
  const desc = item?.form?.coding?.[0]?.display

  const handleClick = () => onClick(item)
  return (
    <DataListItem {...rest} onClick={handleClick}>
      <span>{name}</span>
      <span>
        CIS:{cis} - {desc}
      </span>
    </DataListItem>
  )
}

export default MedicationDataListItem
