import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { search } from '../../Shared/actions'
import { MEDEO_SELECT_PATIENT_QUESTIONNAIRE_IDENTIFIER } from '../../utils/codes'
import { getResponseItemByItemCode } from '../../Questionnaire/utils'
import PatientSearchDetails from '../../Patient/components/PatientSearchDetails'
import { getQuestionnaireByIdentifier } from '../../Questionnaire/selector'
import { getPatientById } from '../../Patient/selectors'

const ExistingPatientRecap = ({ questionnaireResponse }) => {
  const dispatch = useDispatch()
  const selectPatientQuestionnaire = useSelector(
    getQuestionnaireByIdentifier(MEDEO_SELECT_PATIENT_QUESTIONNAIRE_IDENTIFIER)
  )
  const patientID = getResponseItemByItemCode(
    'patient-id',
    selectPatientQuestionnaire,
    questionnaireResponse.answer
  )?.answer?.[0].valueInteger

  const patient = useSelector(getPatientById(patientID))

  useEffect(() => {
    if (patient == null)
      {dispatch(
        search('Patient', {
          _id: patientID
        })
      )}
  }, [dispatch, patient, patientID, selectPatientQuestionnaire])

  if (patient == null) {return null}

  return <PatientSearchDetails patient={patient} />
}

export default ExistingPatientRecap
