import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import QuestionnaireCerfa from './QuestionnaireCerfa';
import {
  getQuestionnaireIdentifierByAction,
  ORDONNANCE_MEDICAMENTEUSE_ACTION_VALUE,
  ORDONNANCE_PARAMEDICALE_ACTION_VALUE
} from '../../PlanDefinition/utils';
import { getAllQuestionnairesByIdentifierAndSystem } from '../selector';
import { useEffectOnce } from 'react-use';
import { search } from '../../Shared/actions';
import QuestionnaireMedicationRequests from './QuestionnaireMedicationRequests';
import usePlanDefinition from '../../PlanDefinition/usePlanDefinition';

const computePlanDefinition = (byGroupId, questionnaireId) => plan => {
  const attachments = Object.values(byGroupId).map(slice => slice.attachment);

  // current index of the response within the planDefinition responses
  // we are not using refs here and internalResponseId
  // the component can be unmounted and therefore will lose the ref
  const index = plan.sequenceResponse.findIndex(
    r => r.questionnaireId === questionnaireId
  );

  // if there are no attachment we can remove the response from the sequence
  // this case happens when a user delete the last order from the list
  if (attachments.length === 0) {
    return {
      ...plan,
      sequenceResponse: plan.sequenceResponse.filter((r, i) => i !== index)
    };
  } else {
    const response = {
      type: 'questionnaire',
      questionnaireId,
      answer: {
        item: [
          {
            linkId: '1',
            answer: attachments.map(attachment => ({
              valueAttachment: attachment
            }))
          }
        ]
      },
      // little hack down here, we set  byGroupId state in the response
      // it's required to init QuestionnaireMedicationRequests' state
      // see QuestionnaireMedicationRequests for more details
      byGroupId
    };

    // general case, we update the item of the list of response using index
    // if index === -1 we simply push the response
    // if not we update in place using slice
    return {
      ...plan,
      sequenceResponse:
        index === -1
          ? [...plan.sequenceResponse, response]
          : [
              ...plan.sequenceResponse.slice(0, index),
              response,
              ...plan.sequenceResponse.slice(index + 1)
            ]
    };
  }
};
/**
 *
 * @param {PlanDefinition.Action} action
 * @param name
 * @returns {*}
 * @constructor
 */
const QuestionnaireRepeatableDocument = ({ action, name }) => {
  const { planDefinition, setPlanDefinition } = usePlanDefinition();
  const [questionnaireIdentifier] = getQuestionnaireIdentifierByAction(action);
  const selector = useCallback(
    state =>
      getAllQuestionnairesByIdentifierAndSystem(
        state,
        questionnaireIdentifier.code,
        questionnaireIdentifier.system
      ),
    [questionnaireIdentifier]
  );
  const [questionnaire] = useSelector(selector);
  const questionnaireId = questionnaire?.id ?? '';
  // fetch questionnaire by identifier

  const reduxDispatch = useDispatch();

  useEffectOnce(() => {
    if (questionnaireIdentifier.code && questionnaireId === '') {
      reduxDispatch(
        search('Questionnaire', { identifier: questionnaireIdentifier.code })
      );
    }
  });
  // current index of the response within the planDefinition responses
  // we are not using refs here and internalResponseId
  // the component can be unmounted and therefore will lose the ref
  const index = planDefinition.sequenceResponse.findIndex(
    r => r.questionnaireId === questionnaireId
  );

  // this function adapts the attachments to the existing planDefinition context

  const handleChange = useCallback(
    byGroupId => {
      // due to the structure of the context this part is too complicated
      // it is moved to computePlanDefinition to simplify the component
      // sequenceResponse is updated with the questionnaireId
      // questionnaireId acts as a reference to the response
      setPlanDefinition(computePlanDefinition(byGroupId, questionnaireId));
    },
    [setPlanDefinition, questionnaireId]
  );

  // Orders do not rely on a questionnaire as the logic is too complicated
  // we use specific components here, they handle MedicationRequest resources
  // It generates a pdf file in the component.
  // This document is accessible from the onChange handler
  if (
    questionnaireIdentifier.code === ORDONNANCE_MEDICAMENTEUSE_ACTION_VALUE ||
    questionnaireIdentifier.code === ORDONNANCE_PARAMEDICALE_ACTION_VALUE
  )
    {return (
      <QuestionnaireMedicationRequests
        action={action}
        onChange={handleChange}
        response={planDefinition.sequenceResponse?.[index]}
      />
    );}

  // The general case here is used for feuille-de-soins and arret-maladie
  // this two items relies on questionnaires
  return (
    <QuestionnaireCerfa
      action={action}
      questionnaire={questionnaire}
      name={name}
    />
  );
};

export default QuestionnaireRepeatableDocument;
