import React, { useState, useContext } from 'react'
import { v4 as uuid } from 'uuid'
import { TextArea } from '../../Components'
import { getDisplay, onChangeQuestionnaire, getItemByLinkId } from '../utils'
import { QuestionnaireResponseContext } from './Questionnaire'

const QuestionnaireTextarea = ({ item, readOnly }) => {
  const [questionnaireResponse, dispatchQuestionnaireResponse] = useContext(
    QuestionnaireResponseContext
  )
  const [id] = useState(uuid())
  const label = getDisplay(item)
  let defaultValue = ''

  defaultValue = getItemByLinkId(questionnaireResponse, item.linkId)
    ?.answer?.[0]?.valueString

  return (
    <TextArea
      label={label}
      id={id}
      name={item.linkId}
      aria-label={label}
      rows={'5'}
      cols={'50'}
      style={{ width: '100%', resize: 'none' }}
      required={item.required}
      readOnly={readOnly}
      defaultValue={defaultValue}
      invalid={'false'}
      onChange={e =>
        onChangeQuestionnaire({
          questionnaireResponse: questionnaireResponse,
          dispatchQuestionnaireResponse: dispatchQuestionnaireResponse,
          name: e.target.name,
          value: e.target.value,
          type: 'text'
        })
      }
      className="fs-block"
    />
  )
}

export default QuestionnaireTextarea
