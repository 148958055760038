import React from 'react'
import { Router } from '@reach/router'
import MainPage from '../components/MainPage'
import VisioPage from './VisioPage'
import TrainerVisioPage from './TrainerVisioPage'

/**
 * Holds the router for the devices part of the settings
 * @return {*}
 * @constructor
 */
const TestsPage = () => {
  return (
    <Router>
      <MainPage path="/" />
      <VisioPage path="/visio" />
      <TrainerVisioPage path="/visio/trainer" />
    </Router>
  )
}
export default TestsPage
