import { setupI18n } from '@lingui/core'
import { I18nProvider } from '@lingui/react'
import * as Sentry from '@sentry/react'
import moment from 'moment'
import { useEffectOnce } from 'react-use'
import { useSelector } from 'react-redux'
import React from 'react'
import styled from 'styled-components/macro'
import { getLanguage } from './I18n'
import { installMomentLocales } from './locale'
import { preventDragAndDropDefault } from './Attachment/utils'
import catalogEn from './locales/en/messages.js'
import catalogFr from './locales/fr/messages.js'
import { CurrentOrganizationIdProvider } from './Organization/containers/CurrentOrganizationIdProvider'
import { CurrentPractitionerProvider } from './Practitioner/usePractitioner'
import CurrentPractitionerRoleProvider from './PractitionerRole/containers/CurrentPractitionerRoleProvider'
import CurrentSearchBarValueProvider from './Shared/components/CurrentSearchBarValueProvider'
import FallbackCard from './Shared/components/FallbackCard'
import I18nHookAdapter from './utils/I18nHookAdapter'
import Layout from './Shared/components/Layout'
import Notification from './Notifications/containers/Notification'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'

const catalogs = { en: catalogEn, fr: catalogFr }

const FallbackLayout = styled.div`
  margin-top: 5rem;
  padding: 1rem;
`
export const i18n = setupI18n()

const queryClient = new QueryClient()

const Providers = ({ children }) => {
  const language = useSelector(getLanguage)
  useEffectOnce(() => {
    installMomentLocales()
    moment.locale(language)
  })

  // This function makes sure to preventDefault behavior of opening the file
  // in the browser when a file is dragged and dropped outside of a dropZone container
  preventDragAndDropDefault()

  return (
    <QueryClientProvider client={queryClient}>
      <I18nProvider language={language} catalogs={catalogs}>
        <I18nHookAdapter>
          <CurrentOrganizationIdProvider>
            <CurrentPractitionerRoleProvider>
              <CurrentPractitionerProvider>
                <CurrentSearchBarValueProvider>
                  <Layout>
                    <Sentry.ErrorBoundary
                      fallback={props => (
                        <FallbackLayout>
                          <FallbackCard {...props} />
                        </FallbackLayout>
                      )}
                    >
                      {children}
                    </Sentry.ErrorBoundary>
                    <Notification />
                  </Layout>
                </CurrentSearchBarValueProvider>
              </CurrentPractitionerProvider>
            </CurrentPractitionerRoleProvider>
          </CurrentOrganizationIdProvider>
        </I18nHookAdapter>
      </I18nProvider>
      {process.env.NODE_ENV === 'development' && (
        <ReactQueryDevtools position="bottom-right" />
      )}
    </QueryClientProvider>
  )
}

export default Providers
