import React, { useState } from 'react'
import { useEffectOnce, useLocation } from 'react-use'
import { Card } from '../../Components'
import { useNavigate } from '@reach/router'
import { SignUpContextProvider } from './SignUpContext'
import CustomConfirmSignUpForm from './CustomConfirmSignUpForm'
import CustomSignUpForm from './CustomSignUpForm'
import Container from './Container'
import CustomSignIn from './CustomSignIn'

function b64ToUTF8(str) {
  return decodeURIComponent(escape(window.atob(str)))
}

/*
 * {
 *  "email": "my@email.com",
 *  "organization": 123456789
 * }
 */
const useDecodeSignUpToken = token => {
  const [state, setState] = useState('')
  const navigate = useNavigate()
  useEffectOnce(() => {
    try {
      if (token == null) {throw new Error('no token provided')}
      const object = JSON.parse(b64ToUTF8(token))
      if (object == null) {throw new Error('unable to decode token')}
      if (object.email == null || object.organization == null)
        {throw new Error('token is missing required fields')}
      setState(object)
    } catch (err) {
      console.warn(err.message)
      console.warn('navigate back to sign in...')
      navigate('/')
    }
    // if no organizationId is set, we fail silently and go back to the index page
    // we should add another process to make sure people don't create accounts
    // later :)
    /* if (organizationId == null) {
      navigate('/')
    }
    setOrganization(organizationId)*/
  })
  return state
}

const SignUp = () => {
  // we are decoding the user data saved in the token and using it to display the project name
  const location = useLocation()
  const query = new URLSearchParams(location.search)
  const token = query.get('token')
  const { project, ...rest } = useDecodeSignUpToken(token)
  return (
    <Container>
      <Card>
        {project === 'Medeo and Kligo' ? (
          <h3>Medeo et Kligo</h3>
        ) : (
          <h3>{project}</h3>
        )}
        <SignUpContextProvider>
          <CustomConfirmSignUpForm />
          {/*custom signup form requires token and user data to display the inputs*/}
          <CustomSignUpForm token={token} project={project} {...rest} />
          {/* soit on reste dans le context signUp pour faire quelque chose avec les identifiants */}
          <CustomSignIn token={token} project={project} {...rest} />
        </SignUpContextProvider>
      </Card>
    </Container>
  )
}

export default SignUp
