import { FC } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisV, faPills } from '@fortawesome/free-solid-svg-icons'
import styled from 'styled-components/macro'
import { Trans } from '@lingui/macro'
import { Button } from '../../Components'
import Menu from './Menu'
import { MedicationRequest } from 'fhir-stu3'

const Flex = styled.div`
  display: flex;
  background: ${(p) => p.theme.cream};
  border-radius: 0.25rem;
  padding: 1rem;

  & > :not(:last-child) {
    margin-right: 1rem;
  }
  & > main {
    flex: 1;
  }
  & > :last-child {
    align-self: center;
  }
`

const Main = styled.main`
  // following code is used to ellipse text properly
  min-width: 0;
  & > * {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  & > :first-child {
    font-size: ${(p) => p.theme.large};
    margin-bottom: 0.5rem;
  }
`

const CustomButton = (props: any) => (
  <Button
    data-test={'medication-list-option-button'}
    color="white"
    variant="text"
    type="button"
    {...props}
  >
    <FontAwesomeIcon icon={faEllipsisV} />
  </Button>
)

/**
 * Display a MedicationRequest,
 * it uses the medicationReference and the dosageInstruction
 * user can interact with this component with two options:
 * - update
 * - delete
 * @param medicationRequest
 * @param onClickUpdate
 * @param onClickDelete
 * @return {JSX.Element}
 * @constructor
 */

interface MedicationRequestDisplayProps {
  medicationRequest: MedicationRequest
  onClickUpdate: (medicationRequest: MedicationRequest) => void
  onClickDelete: (medicationRequest: MedicationRequest) => void
}

const MedicationRequestDisplay: FC<MedicationRequestDisplayProps> = (
  props: MedicationRequestDisplayProps
) => {
  const { medicationRequest, onClickUpdate, onClickDelete } = props
  return (
    <Flex>
      <aside>
        <FontAwesomeIcon icon={faPills} />
      </aside>
      <Main>
        <div>
          {medicationRequest.medicationReference?.display ??
            medicationRequest.medicationCodeableConcept?.text}
        </div>
        <div>
          {
            medicationRequest.dosageInstruction?.find?.((d) => d.text != null)
              ?.text
          }
        </div>
      </Main>
      <aside>
        {/* @ts-ignore */}
        <Menu component={CustomButton}>
          <Menu.ListItem
            data-test={'medication-update-button'}
            onClick={() => onClickUpdate(medicationRequest)}
          >
            <Trans>Update</Trans>
          </Menu.ListItem>
          <Menu.ListItem
            data-test={'medication-delete-button'}
            onClick={() => onClickDelete(medicationRequest)}
          >
            <Trans>Delete</Trans>
          </Menu.ListItem>
        </Menu>
      </aside>
    </Flex>
  )
}

export default MedicationRequestDisplay
