import { combineReducers } from 'redux'

import allergies from './Allergy/ducks'
import Appointment from './Appointment/ducks'
import Location from './Location/ducks'
import auth from './Auth/ducks'
import careTeams from './CareTeam/ducks'
import clinicalImpressions from './ClinicalImpression/ducks'
import conditions from './Condition/ducks'
import Consent from './Consent/ducks'
import Device from './Device/ducks'
import documentReferences from './DocumentReference/ducks'
import encounters from './Encounter/ducks'
import { fhirClient } from './Shared/ducks'
import MedicationStatement from './MedicationStatement/ducks'
import { ducks as Medication } from './Medication'
import notification from './Notifications/ducks'
import observations from './Observation/ducks'
import { ducks as language } from './I18n'
import organizations from './Organization/ducks'
import patients from './Patient/ducks'
import PaymentNotice from './PaymentNotice/ducks'
import planDefinition from './PlanDefinition/ducks'
import practitioners from './Practitioner/ducks'
import practitionerRoles from './PractitionerRole/ducks'
import ProcedureRequest from './ProcedureRequest/ducks'
import questionnaire from './Questionnaire/ducks'
import QuestionnaireResponse from './QuestionnaireResponse/ducks'
import requestGroup from './RequestGroup/ducks'
import Schedule from './Schedule/ducks'
import Slot from './Slot/ducks'
import navigation from './Shared/ducks/navigation'

// ducks of fhir Resource type should start with an uppercase
// and by singular
// TODO refactor the main duck...
export default combineReducers({
  allergies,
  Appointment,
  auth,
  careTeams,
  clinicalImpressions,
  conditions,
  Consent,
  Device,
  documentReferences,
  encounters,
  fhirClient,
  language,
  Location,
  Medication,
  MedicationStatement,
  navigation,
  notification,
  observations,
  organizations,
  patients,
  PaymentNotice,
  planDefinition,
  practitioners,
  practitionerRoles,
  ProcedureRequest,
  questionnaire,
  QuestionnaireResponse,
  requestGroup,
  Schedule,
  Slot
})
