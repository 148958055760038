import React from 'react';

import { getCodingFromEncounter } from '../utils';
import {
  MEDEO_ENCOUNTER_TYPE_CLINICAL_EXAM,
  MEDEO_ENCOUNTER_TYPE_PRE_TELECONSULTATION,
  MEDEO_ENCOUNTER_TYPE_TELECONSULTATION_CONSULTATION
} from '../../utils/encounter-type';
import RequestGroup from '../../RequestGroup/components/RequestGroup';
import DefaultEncounterForm from '../components/DefaultEncounterForm';
import AddPatientEncounter from '../components/AddPatientEncounter';

export const EncounterMain = (props) => {
  // EncounterMain is dynamically loaded depending on the encounter type
  // There are two cases either the encounter is the result of an YAML form
  // or it's a clinical examination which means:
  //   - an exam taken with connected devices

  // We are loading the Default Encounter when the code contains clinical-exam
  // For the other encounters, the coding is related to the name of the .yml file
  // it has been loaded from.
  const coding = getCodingFromEncounter(props.encounter);
  switch (coding) {
    case MEDEO_ENCOUNTER_TYPE_PRE_TELECONSULTATION:
    case MEDEO_ENCOUNTER_TYPE_TELECONSULTATION_CONSULTATION:
      return <RequestGroup encounterID={props.encounter.id} patient={props.patient} />
    case MEDEO_ENCOUNTER_TYPE_CLINICAL_EXAM:
      return <DefaultEncounterForm {...props} />;
    case 'add_patient':
      return <AddPatientEncounter patient={props.patient} />;
    default:
      throw new Error(`${coding} not supported`);
  }
};
