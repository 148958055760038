import { takeEvery } from 'redux-saga/effects'
import {
  removeFhirResourceWorker,
  saveFhirResourceWorker,
} from '../Shared/sagas'
import { REMOVING_CONDITION, CREATING_CONDITION } from './actions'

export default function* watcherSaga() {
  yield takeEvery(
    REMOVING_CONDITION,
    removeFhirResourceWorker,
    ({ payload: p }) => ({ resourceType: 'Condition', id: p.id })
  )

  yield takeEvery(CREATING_CONDITION, saveFhirResourceWorker, a => a.payload)
}
