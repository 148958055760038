import {
  faAddressBook,
  faAddressCard,
  faCreditCard,
  faLaptop,
  faLock,
  faSave,
  faTools,
  faUsers
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from '@reach/router';
import { useSelector } from 'react-redux';
import React, { useContext } from 'react';
import styled from 'styled-components/macro';
import { css } from 'styled-components';
import { CurrentPractitionerRoleContext } from '../../PractitionerRole/containers/CurrentPractitionerRoleProvider';
import { GENERAL_PRACTITIONER_ESANTE } from '../../Permissions/roles';
import { getAdministratorRightBypractitionerID } from '../../PractitionerRole/selector';
import { hoverMixin } from '../../Shared/mixins';
import { MEDEO_ADMINISTRATOR_CODE } from '../../utils/codes';
import usePractitioner from '../../Practitioner/usePractitioner';

const Container = styled.nav`
  justify-content: center;
`;

const Title = styled.div`
  h4 {
    margin: 0;
  }
`;

const StyledLink = styled(Link)`
  width: 100%;
  padding: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  display: flex;
  align-items: baseline;
  text-decoration: none;
  ${p =>
    p.disabled === true
      ? css`
          opacity: 40%;
          cursor: not-allowed;
        `
      : hoverMixin}
`;

const Icon = styled.div`
  width: 28px;
  height: 28px;
`;

const Text = styled.p`
  font-size: ${props => props.theme.small};
  color: gray;
`;

const SettingsNav = () => {
  const navigationTab = [
    {
      icon: faAddressCard,
      title: 'Mon profil',
      text: 'Informations personnelles',
      link: 'edit',
      intercomName: 'edit-profile'
    },
    {
      icon: faCreditCard,
      title: 'Facturation',
      text: 'Paramètre des paiements',
      link: 'billing',
      intercomName: 'billing'
    },
    // uncomment the lines bellow to branch models on the app again
    {
      icon: faSave,
      title: 'Modèles',
      text: 'Personnaliser les documents',
      link: 'model',
      intercomName: 'user-model'
    },
    {
      icon: faLock,
      title: 'Sécurité',
      text: 'Identification et mots de passe',
      link: 'security',
      intercomName: 'security'
    },
    {
      icon: faUsers,
      title: 'Utilisateurs',
      text: "Gérer les membres de l'équipe",
      link: 'user',
      intercomName: 'team'
    },
    {
      icon: faLaptop,
      title: 'Équipements',
      text: 'Matériel et objets connectés',
      link: 'devices',
      intercomName: 'devices'
    },
    {
      icon: faAddressBook,
      title: 'Mon organisation',
      text: "Gérer les informations de l'organisation",
      link: 'organization',
      intercomName: 'organization'
    },
    {
      icon: faTools,
      title: 'Tests',
      text: 'Tester vos dispositifs et votre matériel',
      link: 'tests',
      intercomName: 'tests'
    }
  ];

  const currentPractitioner = usePractitioner();

  const [currentPractitionerRole] = useContext(CurrentPractitionerRoleContext);
  const isAdmin = useSelector(s =>
    getAdministratorRightBypractitionerID(
      s,
      currentPractitioner.id,
      MEDEO_ADMINISTRATOR_CODE
    )
  );
  let isDoctor = false;
  if (currentPractitionerRole === GENERAL_PRACTITIONER_ESANTE) {isDoctor = true;}

  return (
    <Container>
      {navigationTab.map(tab =>
        tab.title === 'Mon profil' ||
        tab.title === 'Sécurité' ||
        tab.title === 'Tests' ||
        (tab.title === 'Facturation' && isDoctor) ||
        (tab.title === 'Utilisateurs' && isAdmin) ||
        (tab.title === 'Équipements' && isAdmin) ||
        (tab.title === 'Mon organisation' && isAdmin) ||
        tab.title === 'Modèles' ? (
          <StyledLink
            to={tab.link}
            key={tab.title}
            getProps={({ isCurrent }) => {
              //need to find other solution at the end, we could use the props in styled component
              return {
                style: {
                  color: isCurrent ? '#0967D2' : 'black'
                }
              };
            }}
            data-intercom-target={tab.intercomName}
          >
            <Icon>
              <FontAwesomeIcon icon={tab.icon} />
            </Icon>
            <Title>
              <h4>{tab.title}</h4>
              <Text>{tab.text}</Text>
            </Title>
          </StyledLink>
        ) : null
      )}
    </Container>
  );
};

export default SettingsNav;
