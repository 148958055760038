import React, { useContext, createContext, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components/macro'
import { Trans } from '@lingui/macro'
import { Form, Button } from '../../Components'
import { useNavigate } from '@reach/router'
import { createPractitioner } from '../actions'
import ProfilePicture from '../components/ProfilePicture'
import IdentifierInputs from '../components/IdentifierInputs'
import AddressInputs from '../components/AddressInputs'
import TelecomInputs from '../components/TelecomInputs'
import NameInputs from '../components/NameInputs'
import { getDeprecatedPractitionerById } from '../../Practitioner/selector'
import {
  getAdministratorRightBypractitionerID,
  getPractitionerRoleBypractitionerID
} from '../../PractitionerRole/selector'
import { createNotification } from '../../Notifications/actions'
import AdministrationRoleInput from '../components/AdministrationRoleInput'
import MedicalRoleInput from '../components/MedicalRoleInput'
import useSave from '../../Shared/hooks/useSave'
import { computePractitionerIntoEntries } from '../utils'
import { MEDEO_ADMINISTRATOR_CODE } from '../../utils/codes'
import { CurrentPractitionerContext } from '../../Practitioner/usePractitioner'

const FormHeader = styled.header`
  font-weight: bold;
  font-size: larger;
  border-top: 1px solid ${p => p.theme.gray};
  padding-top: 1rem;
  margin-bottom: 1rem;
`

const Left = styled.div`
  margin-top: 3rem;
`

const Footer = styled.footer`
  display: flex;
  justify-content: center;
`

const CustomButton = styled(Button)`
  margin-bottom: 1rem;
`

export const EditPractitionerContext = createContext()

const EditPractitionerForm = ({ use, selectedpractitionerID }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [save] = useSave()
  const [currentPractitioner, setCurrentPractitioner] = useContext(
    CurrentPractitionerContext
  )
  const selectedPractitionerById = useSelector(s =>
    getDeprecatedPractitionerById(s, selectedpractitionerID)
  )
  // we use isAdmin to be able to change medical roles or admin permissions of other users
  const isAdmin = useSelector(s =>
    getAdministratorRightBypractitionerID(
      s,
      currentPractitioner.id,
      MEDEO_ADMINISTRATOR_CODE
    )
  )
  // This process allow the change of form data for practitioner. If it's the real user that he want to change his data
  // We prefill the form with his data (currentPractitioner). If it's the administrator of the app, we prefill with
  // practitioner data like that the admin can update them. If the admin want to add a new practitioner, we prefill the form with empty array
  // the practitioner variable will be use for initalState of editedPractitioner. Don't forget the useEffect for update profile picture too.
  let practitioner
  if (use === 'editUserAsAdmin') {
    practitioner = selectedPractitionerById
  } else if (use === 'editUserAsUser') {
    practitioner = currentPractitioner
  }

  let practitionerID
  if (practitioner) {
    practitionerID = practitioner.id
  }
  const medicalRole = useSelector(
    s => getPractitionerRoleBypractitionerID(s, practitionerID, 'medical')[0]
  )
  const administrationRole = useSelector(
    s =>
      getPractitionerRoleBypractitionerID(
        s,
        practitionerID,
        MEDEO_ADMINISTRATOR_CODE
      )[0]
  )
  const [resource, setResource] = useState({
    practitioner,
    medicalRole,
    administrationRole
  })
  let response

  // This useEffect is usefull for refresh the profile picture not the input in the form
  // For modify input of form you have to modify the initialeState of the editedPractitioner state
  useEffect(() => {
    setResource({ practitioner, medicalRole, administrationRole })
  }, [practitioner, medicalRole, administrationRole])

  const handleSubmit = async e => {
    e.preventDefault()
    const entriesToSave = computePractitionerIntoEntries(resource)

    switch (use) {
      // if the admin create a new user
      case 'createUser':
        dispatch(createPractitioner(resource))
        navigate(`/settings/user`)
        break
      // if the admin edit a user (admin or member)
      case 'editUserAsAdmin':
        response = await save({
          resourceType: 'Bundle',
          type: 'transaction',
          entry: entriesToSave
        })
        if (response.error != null)
          {dispatch(createNotification('Une erreur est survenue', 'error'))}
        else
          {dispatch(
            createNotification(
              'Utilisateur a été correctement modifié',
              'success'
            )
          )}
        break
      //if the user modify his profile in "profil page"
      case 'editUserAsUser':
        response = await save({
          resourceType: 'Bundle',
          type: 'transaction',
          entry: entriesToSave
        })
        if (response.error != null)
          {dispatch(createNotification('Une erreur est survenue', 'error'))}
        else
          {dispatch(
            createNotification(
              'Utilisateur a été correctement modifié',
              'success'
            )
          )}
        setCurrentPractitioner(resource.practitioner)
        break
      default:
        dispatch(createNotification('Une erreur est survenue', 'error', 2000))
    }
  }
  return (
    <EditPractitionerContext.Provider value={[resource, setResource]}>
      <Form
        id="editPractitioner"
        name="editPractitioner"
        onSubmit={handleSubmit}
      >
        <div>
          <FormHeader>
            <Trans>Information</Trans>
          </FormHeader>
          <Form.Row>
            <Left>
              <NameInputs />
            </Left>
            <ProfilePicture />
          </Form.Row>
          <Form.Row>
            <MedicalRoleInput readOnly={!isAdmin} />
          </Form.Row>
          <Form.Row>
            <AdministrationRoleInput readOnly={!isAdmin} />
          </Form.Row>
          <IdentifierInputs />
        </div>
        <div>
          <FormHeader>
            <Trans>Contacts</Trans>
          </FormHeader>
          <TelecomInputs readOnly={use !== 'createUser'} />
          <AddressInputs />
        </div>
      </Form>
      {/* The button to save the form is not present when is seen by an admin as there are others parameters like the pin code and the archive */}
      {use !== 'editUserAsAdmin' && (
        <Footer>
          <CustomButton form="editPractitioner" color="ocean" data-test="save">
            <Trans>Save</Trans>
          </CustomButton>
        </Footer>
      )}
    </EditPractitionerContext.Provider>
  )
}

export default EditPractitionerForm
