import { getIdByReference } from '../Shared/utils'

export const filterParticipantsByPatientGP = (resource, participants) => {
  // we prepare a array with only the id of user that
  // appears in the patient resource
  let array = []
  if (resource && resource.generalPractitioner)
    {array = resource.generalPractitioner
      .filter(gp => gp.reference != null)
      .map(gp => getIdByReference(gp.reference))}

  // here we filter all organizations that appears in careteam
  // we will keep only the organization that not appears in patient resource.
  const participantFiltered = participants.filter(org => {
    return array.findIndex(id => id === org.id) === -1
  })

  return participantFiltered
}
