import { API, Auth } from 'aws-amplify'
/**
 * ask the lambda to create the pdf file for an order
 * the order is identified with the groupIdentifier/encounterID
 * returns the url where the file is located
 * @param {string} encounterID
 * @param {string} groupIdentifier
 * @param {string} token
 * @return {string} url
 */
export const generateOrder = async ({ encounterID, groupIdentifier }) => {
  const response = await API.post('documents', '/order', {
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getAccessToken()
        .getJwtToken()}`,
    },
    body: {
      encounterID,
      groupIdentifier,
    },
  })
  return response?.url
}
