export const ALLERGIES_INTOLERANCES_RECEIVED = 'ALLERGIES_INTOLERANCES_RECEIVED'
export const ALLERGY_INTOLERANCE_RECEIVED = 'ALLERGY_INTOLERANCE_RECEIVED'
export const ALLERGY_INTOLERANCE_REMOVED = 'ALLERGY_INTOLERANCE_REMOVED'
export const REMOVING_ALLERGY_INTOLERANCE = 'REMOVING_ALLERGY_INTOLERANCE'
export const CREATING_ALLERGY_INTOLERANCE = 'CREATING_ALLERGY_INTOLERANCE'

export const createAllergyIntelorance = payload => ({
  type: CREATING_ALLERGY_INTOLERANCE,
  payload: payload,
})

export const removeAllergyIntelorance = payload => ({
  type: REMOVING_ALLERGY_INTOLERANCE,
  payload: payload,
})
