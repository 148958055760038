import { Organization } from 'fhir-stu3'
import { getCurrentOrganizationId } from '../Auth/selectors'
import { ReduxState } from '../Shared/ducks/types'

export const getCurrentOrganization = (
  state: ReduxState
): Organization | undefined => {
  const id = getCurrentOrganizationId(state)
  return state.organizations.byId[id]
}
export const getOrganizationById = (organizationId: string) => (
  state: ReduxState
): Organization | undefined => {
  return state.organizations.byId[organizationId]
}
