import { getTypeAndIdFromLocalReference } from '../utils/fhir/index'
import { MEDEO_PROCEDURE_REQUEST_REMOTE_CONSULTATION_CODE } from '../utils/codes'

export const getTotalOfPatientsForWaitingRoom = state => {
  return (
    state.ProcedureRequest.allIds
      // we get the patient for each request
      .map(i => state.ProcedureRequest.byId[i])
      // get only active procedureRequests
      .filter(i => i.status && i.status === 'active')
      // get only procedureRequest from the right code
      .filter(
        i =>
          i.code &&
          i.code.coding &&
          i.code.coding[0] &&
          i.code.coding[0].code ===
            MEDEO_PROCEDURE_REQUEST_REMOTE_CONSULTATION_CODE
      )
      .sort((a, b) => b.id - a.id)
      .map(
        i =>
          state.patients.byId[
            getTypeAndIdFromLocalReference(i.subject.reference)[1]
          ]
      )
      // delete duplicate patients
      .filter((e, i, a) => a.indexOf(e) === i)
      // sometimes there is an undefined in the array and we want to prevent it
      .filter(a => a != null).length
  )
}

/**
 * Select patients having an active procedure request and the corresponding
 * procedure request
 *
 * @param {*} state
 * @param {*} procedureCode
 * @param {*} patientLimit
 * @returns {Array} [{patient: Patient, procedureRequest: ProcedureRequest}]
 */
export const getAllPatientsWithProcedureRequestByProcedureCode = (
  state,
  procedureCode,
  patientLimit = 10
) => {
  const withRequestPatients = state.ProcedureRequest.allIds
    // we get the patient for each request
    .map(i => state.ProcedureRequest.byId[i])
    // get only active procedureRequests
    .filter(i => i.status && i.status === 'active')
    // get only procedureRequest from the right code
    .filter(
      i =>
        i.code &&
        i.code.coding &&
        i.code.coding[0] &&
        i.code.coding[0].code === procedureCode
    )
    .sort((a, b) => b.id - a.id)
    .map(i => ({
      patient:
        state.patients.byId[
          getTypeAndIdFromLocalReference(i.subject.reference)[1]
        ],
      procedureRequest: i
    }))
    // sometimes there is an undefined in the array and we want to prevent it
    .filter(a => a.patient != null)
    // delete duplicate patients
    .filter((e, i, a) => i === a.findIndex(t => t.patient.id === e.patient.id))

  return withRequestPatients.slice(0, patientLimit)
}

export const getProcedureRequestsFromencounterIDAndPerformerId = (
  state,
  currentencounterID,
  currentPractitionerID
) => {
  if (currentPractitionerID == null) {return []}

  return (
    state.ProcedureRequest.allIds
      .map(i => state.ProcedureRequest.byId[i])
      // get only procedureRequest of the current practitioner
      .filter(
        i =>
          i.performer &&
          i.performer.reference === 'Practitioner/' + currentPractitionerID
      )
      // get only procedureRequest of the current encounter
      .filter(
        i =>
          i.context && i.context.reference === 'Encounter/' + currentencounterID
      )
  )
}

/**
 * Get all patient procedure request, given optional filters
 *
 * @param {*} state
 * @param {*} patientID
 * @param {*} filters
 */
export const getPatientActiveProcedureRequests = (
  state,
  patientID,
  filters = [() => true]
) => {
  return state.ProcedureRequest.allIds
    .map(i => state.ProcedureRequest.byId[i])
    .filter(
      pr =>
        pr.subject?.reference === 'Patient/' + patientID &&
        pr.status === 'active' &&
        filters.every(f => f(pr))
    )
}
