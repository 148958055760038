import React from 'react';
import { Trans } from '@lingui/macro';
import DateAndTime from '../../Shared/components/DateAndTime';
import { Display } from '../../Questionnaire/components/QuestionnaireComponent';
import PerformerInfo from '../../Practitioner/components/PerformerInfo';

const SelectPerformerQuestionnaireResponse = ({ slot, performerId }) => {
  return (
    <>
      <Display>
        <Trans>Appointment</Trans>
      </Display>
      <PerformerInfo performerId={performerId} practitionerRole={null} />
      <DateAndTime dateTime={slot.start} />
    </>
  );
};

export default SelectPerformerQuestionnaireResponse;
