import { Trans, t } from '@lingui/macro'
import { Button } from '@mui/material'
import { Appointment, Practitioner, ProcedureRequest } from 'fhir-stu3'
import { FC } from 'react'
import { useDispatch } from 'react-redux'
import { cancelAppointment } from '../../Appointment/actions'
import { save } from '../../Shared/actions'
import { useI18n } from '../../utils/I18nHookAdapter'
type CancelAppointmentButtonProps = {
  appointment: Appointment
  practitioner: Practitioner
  procedureRequest: ProcedureRequest | undefined
}
const CancelAppointmentButton: FC<CancelAppointmentButtonProps> = ({ appointment, procedureRequest, practitioner }) => {
  const dispatch = useDispatch()
  const i18n: any = useI18n()
  const message = i18n._(t`You are about to cancel your patient appointment. Click OK to confirm.`)
  const cancelTeleconsultation = () => {
    const confirmed = window.confirm(message)
    if (!confirmed) {return}

    // Here we update the teleconsultation Procedure Request
    if (procedureRequest != null) {
      dispatch(save({ ...procedureRequest, status: 'cancelled' }))
    }
    // And here we cancel the linked appointment and update the
    // booking Procedure Request
    dispatch(cancelAppointment(appointment, practitioner))
  }
  return (
    <Button variant="outlined" onClick={cancelTeleconsultation}>
      <Trans>Cancel your booking</Trans>
    </Button>
  )
}

export default CancelAppointmentButton
