export const FETCHING_DOCUMENT_REFERENCES = 'FETCHING_DOCUMENT_REFERENCES'
export const DOCUMENT_REFERENCES_RECEIVED = 'DOCUMENT_REFERENCES_RECEIVED'
export const DOCUMENT_REFERENCE_RECEIVED = 'DOCUMENT_REFERENCE_RECEIVED'
export const DOCUMENT_REFERENCE_REMOVED = 'DOCUMENT_REFERENCE_REMOVED'
export const SAVING_DOCUMENT_REFERENCE = 'SAVING_DOCUMENT_REFERENCE'
export const SAVING_DOCUMENT_REFERENCE_FROM_BINARY =
  'SAVING_DOCUMENT_REFERENCE_FROM_BINARY'
export const API_ERRORED = 'API_ERRORED'
export const UPDATING_DOCUMENT_REFERENCE = 'UPDATING_DOCUMENT_REFERENCE'
export const CREATING_BINARY = 'CREATING_BINARY'
export const loadDocumentReferences = (encounterID, patientID) => ({
  type: FETCHING_DOCUMENT_REFERENCES,
  payload: {
    encounterID,
    patientID
  }
})

export const saveDocumentReference = (
  patientID,
  encounterID,
  practitionerID,
  reference,
  comment = ''
) => {
  return {
    type: SAVING_DOCUMENT_REFERENCE,
    payload: {
      patientID,
      encounterID,
      practitionerID,
      reference,
      comment
    }
  }
}

export const createBinary = (file, id) => ({
  type: CREATING_BINARY,
  payload: { file, id }
})
