import React from 'react'
import styled from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'

const Wrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 2rem;
  grid-template-rows: 1fr;
  border: 1px solid ${p => p.theme.gray};
  border-radius: 0.25rem;

  &:focus,
  &:hover {
    outline: none;
    border-color: ${p => p.theme.ocean};
    cursor: pointer;
  }
`
const Caret = styled.span`
  grid-column: 2;
  justify-self: center;
  align-self: center;
  grid-row: 1;
  background: white;
  // margin right is required to show the border of the Select
  margin-right: 2px;
  pointer-events: none;
  z-index: 1;
  padding: 0.5rem 1rem 0.5rem 0.5rem;
`

const CustomSelect = styled.select`
  line-height: 1.5;
  grid-column: 1 / 3;
  background: none;
  grid-row: 1;
  appearance: none;
  border: none;
  padding: 0.5rem 0.5rem 0.5rem 1rem;
  font-weight: 500;

  &:focus,
  &:hover {
    outline: none;
  }
`

const BetterSelect = ({ children, ...rest }) => {
  return (
    <Wrapper>
      <Caret>
        <FontAwesomeIcon icon={faCaretDown} />
      </Caret>
      <CustomSelect {...rest}>{children}</CustomSelect>
    </Wrapper>
  )
}

export default BetterSelect
