import React from 'react'
import styled from 'styled-components/macro'
import { Trans } from '@lingui/macro'

const ValueDiv = styled.div``

const StringAnswer = ({ value }) => {
  // We need this switch for now as booleans used to be saved as valueString
  // Below we declare a IIFE function (Immediately Invoked Function Expressions), which enables
  // to directly invoke the function as soon as it is created.
  const answer = (string => {
    switch (string) {
      case 'false':
        return <Trans>False</Trans>
      case 'true':
        return <Trans>True</Trans>
      default:
        return string
    }
  })(value)

  return <ValueDiv>{answer}</ValueDiv>
}

export default StringAnswer
