import React, { useContext } from 'react'
import { I18n } from '@lingui/react'
import { Input } from '../../Components'
import RadioGroup from '../../Shared/components/RadioGroup'
import { QuestionnaireResponseContext } from './Questionnaire'
import { getDisplay, onChangeQuestionnaire, getItemByLinkId } from '../utils'

/**
 * This component is used to display booleans,
 * i.e items for which the answer can only by either true or false
 *
 * @param {*} item: questionnaire item
 */
const QuestionnaireBoolean = ({ item }) => {
  const [questionnaireResponse, dispatchQuestionnaireResponse] = useContext(
    QuestionnaireResponseContext
  )

  const options = [
    {
      value: true,
      label: 'Yes'
    },
    { value: false, label: 'No' }
  ]

  const initialBoolean = item?.initialBoolean

  // For the selectedValue, we just take the first one as for booleans
  // We can only have one response
  let selectedValue = getItemByLinkId(questionnaireResponse, item.linkId)
    ?.answer?.[0]?.valueBoolean

  // This is in order to fill up the response with the initial response value by default
  // and check the default answer as on next run the selectedValue would get
  // the initialBoolean value
  if (selectedValue == null && initialBoolean != null) {
    selectedValue = initialBoolean
    onChangeQuestionnaire({
      questionnaireResponse: questionnaireResponse,
      dispatchQuestionnaireResponse: dispatchQuestionnaireResponse,
      name: item.linkId,
      value: selectedValue,
      type: 'boolean'
    })
  }

  return (
    <I18n>
      {({ i18n }) => (
        <RadioGroup
          required={item.required}
          label={getDisplay(item)}
          className="fs-block"
        >
          {options.map(o => (
            <Input
              key={`${item.linkId}_${o.label}`}
              type="radio"
              label={i18n._(o.label)}
              name={item.linkId}
              required={item.required}
              value={o.value}
              checked={o.value === selectedValue}
              data-test={`${item.linkId}-${o.label}`}
              onChange={e =>
                onChangeQuestionnaire({
                  questionnaireResponse: questionnaireResponse,
                  dispatchQuestionnaireResponse: dispatchQuestionnaireResponse,
                  name: e.target.name,
                  value: e.target.value,
                  type: 'boolean'
                })
              }
            />
          ))}
        </RadioGroup>
      )}
    </I18n>
  )
}

export default QuestionnaireBoolean
