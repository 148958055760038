import { Appearance } from '@stripe/stripe-js/types/stripe-js/elements-group'

const stripeAppearance: Appearance = {
  theme: 'stripe',
  variables: {
    colorTextPlaceholder: '#98a5b2',
    colorText: 'black',
    colorBackground: 'white',
    colorPrimary: '#0967D2',
    colorDanger: '#E12D39',
    borderRadius: '.25rem'
  }
}
export default stripeAppearance
