import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components/macro'
import { Trans } from '@lingui/macro'
import { Card } from '../Components'
import { useNavigate } from '@reach/router'
import { loadForm } from '../Shared/form/load'
import mixpanel, { MIXPANEL_PATIENT_ADDED } from '../Shared/mixpanel'
import YAMLFormPage from '../Shared/form/components/YAMLFormPage'
import YAMLFormSideMenu from '../Shared/form/components/YAMLFormSideMenu'
import NotFound from '../Shared/components/NotFound'
import { saveModel } from '../Shared/actions/db'
import useGraph from '../utils/yaml/useGraph'
import { getCurrentOrganizationId } from '../Auth/selectors'
import { ModelContext, ReadOnlyContext } from './utils'
import Grid from '../Shared/components/GridLayout'
import PatientsGoBackDialog from '../Shared/components/PatientsGoBackDialog'

const CustomCardHeader = styled(Card.Header)`
  padding: 0;
  & > h1 {
    font-size: 28px;
  }
  border-bottom-color: transparent;
`

const CustomCard = styled(Card)`
  margin-bottom: 2rem;
  margin-top: 2rem;
`
const initModel = organizationId => ({
  $patient: { $organization: organizationId },
  $consent: { $organization: organizationId }
})

const YAMLAddPatientForm = ({ step }) => {
  const organizationId = useSelector(getCurrentOrganizationId)
  const dispatch = useDispatch()

  const [yamlFile, setYamlFile] = useState(null)

  const fetchYamlFile = useCallback(async () => {
    const file = await loadForm("add_patient", organizationId)
    setYamlFile(file)
  }, [organizationId])

  // the following effect loads the yamlFile for the add_patient
  // it calls fetchYamlFile which is also called to reset the add_patient file
  // every time a patient is created.
  // there is an early return that prevent the component from rendering if the yamlFile
  // is null
  useEffect(() => {
    if (yamlFile === null) {
      fetchYamlFile()
    }
  }, [yamlFile, fetchYamlFile])

  // our step is a string and we want to make sure it's treated as a number if the step is not
  // 'summary' or 'consent', the parseInt parses a string argument and returns an integer
  // of the specified radix (the base in mathematical numeral systems)
  step = step === 'summary' || step === 'consent' ? step : parseInt(step, 10)

  const [model, setModel] = useState(initModel(organizationId))

  const graph = useGraph(yamlFile)

  // Enable the scroll at the top of the form when step change
  useEffect(() => {
    if (document && document.getElementById('topOfForm')) {
      document.getElementById('topOfForm').scrollIntoView()
    }
  }, [step])

  const navigate = useNavigate()

  // prevent component from rendering if the yamlFile is not yet loaded
  // fetching occurs in one of the useEffects above.
  if (yamlFile === null) {return 'chargement'}

  let page

  const handleSubmit = e => {
    e.preventDefault()
    dispatch(saveModel(graph, model, yamlFile, {}, '$root'))
    navigate(yamlFile.afterSuccess)
    mixpanel.track(MIXPANEL_PATIENT_ADDED, {
      // either it comes from the patient list or the booking view
      // from metadata should be : list or booking
      from: 'list'
    })

    // we need to reset model and reload the yamlFile after dispatching the saveModel action
    // otherwise some info from the previous patient could persist
    // it happened when trying to add two patients in a row the first one with
    // a gp set and second one without
    // the second patient had the practitioner from the first one :O
    setModel(initModel(organizationId))
    fetchYamlFile()
    return false
  }

  const handleNextStep = e => {
    e.preventDefault()
    // first case is the last yaml page
    // 'consent' and 'summary' are not treated as pages anymore
    switch (step) {
      case yamlFile.pages.length:
        return navigate(`./consent`)
      case 'consent':
        return navigate(`./summary`)
      default:
        return navigate(`./${step + 1}`)
    }
  }

  if (step === 'summary') {
    page = (
      <YAMLFormPage
        subtitle={yamlFile.summary.subtitle}
        inputs={yamlFile.pages.reduce(
          (arr, page) => arr.concat(page.inputs),
          []
        )}
        consentInput={yamlFile.consent.inputs}
        step={step}
        summary={yamlFile.summary}
        onSubmit={handleSubmit}
      />
    )
  } else if (step === 'consent') {
    page = (
      <YAMLFormPage
        inputs={yamlFile.consent.inputs}
        step={step}
        yamlFile={yamlFile}
        consent={yamlFile.consent}
        onSubmit={handleNextStep}
      />
    )
  } else {
    const pageConfiguration = yamlFile.pages[step - 1]
    page = pageConfiguration ? (
      <YAMLFormPage
        inputs={pageConfiguration.inputs}
        step={step}
        yamlFile={yamlFile}
        onSubmit={handleNextStep}
      />
    ) : (
      <NotFound />
    )
  }
  return (
    <>
      <Grid.Aside>
        <PatientsGoBackDialog hasCurrentEncounter={false} />

        <YAMLFormSideMenu pages={yamlFile.pages} />
      </Grid.Aside>
      <Grid.Main id="topOfForm">
        <CustomCard>
          <CustomCardHeader>
            {step === 'summary' ? (
              <h1>
                <Trans>Summary</Trans>
              </h1>
            ) : (
              <h1>{yamlFile.title}</h1>
            )}
          </CustomCardHeader>
          {/*<pre>{JSON.stringify(model, null, 2)}</pre>*/}
          <ModelContext.Provider value={[model, setModel]}>
            <ReadOnlyContext.Provider value={step === 'summary'}>
              {page}
            </ReadOnlyContext.Provider>
          </ModelContext.Provider>
        </CustomCard>
      </Grid.Main>
    </>
  )
}

export default YAMLAddPatientForm
