/*eslint-disable*/
import { combineReducers, Reducer } from 'redux'
import {
  ENCOUNTER_RECEIVED,
  ENCOUNTERS_RECEIVED,
  ENCOUNTER_SELECTED,
  ENCOUNTER_DESELECTED,
  MOST_RECENT_ENCOUNTER_RECEIVED,
  ENCOUNTER_PAGE_CHANGED,
  LOAD_MORE_ENCOUNTERS,
  CLEARING_ENCOUNTER_PAGE,
  GET_INITIAL_ENCOUNTER_PAGE,
  TOTAL_ENCOUNTER_RECEIVED,
  STALE_ENCOUNTER_PAGE,
  STOPPING_ENCOUNTER
} from './actions'
import { reduceById, appendAllUniqueIds } from '../Shared/ducks'
import * as paginated from '../Shared/ducks/paginated'
import { FHIR_RESOURCES_RECEIVED } from '../Shared/actions'
import {
  CERFAS_AND_DOCUMENTS_SAVED,
  SAVING_CERFA_AND_DOCUMENTS
} from '../QuestionnaireResponse/actions'
import { ById } from '../Shared/ducks/types'
import { Encounter } from 'fhir-stu3'

export const allIds: Reducer<string[]> = (state = [], action) => {
  const { type, payload } = action
  switch (type) {
    case ENCOUNTERS_RECEIVED:
    case FHIR_RESOURCES_RECEIVED:
      return appendAllUniqueIds(state, payload.Encounter)
    case MOST_RECENT_ENCOUNTER_RECEIVED:
    case ENCOUNTER_RECEIVED:
      return appendAllUniqueIds(state, payload.encounter)
    default:
      return state
  }
}

export const byId: Reducer<ById<Encounter>> = (state = {}, action) => {
  const { payload, type } = action
  switch (type) {
    case MOST_RECENT_ENCOUNTER_RECEIVED:
    case ENCOUNTER_RECEIVED:
      return { ...state, [payload.encounter.id]: payload.encounter }
    case FHIR_RESOURCES_RECEIVED:
    case ENCOUNTERS_RECEIVED:
      return reduceById(state, payload.Encounter)
    default:
      return state
  }
}

export const currentId: Reducer<string | null> = (state = null, action) => {
  const { payload, type } = action
  switch (type) {
    case ENCOUNTER_DESELECTED:
      return null
    case ENCOUNTER_SELECTED:
      return payload.encounterID
    default:
      return state
  }
}

export const total: Reducer<number> = (state = 0, action) => {
  const { payload, type } = action
  switch (type) {
    case TOTAL_ENCOUNTER_RECEIVED:
      return payload
    default:
      return state
  }
}

export const shown: Reducer<{
  items: any
  link: any
  config: any
  isLoading: boolean
}> = (state = paginated.DEFAULT_STATE, action) => {
  const { type } = action
  switch (type) {
    case CLEARING_ENCOUNTER_PAGE:
      return {
        ...paginated.DEFAULT_STATE
      }
    default:
      return paginated.reducer(
        GET_INITIAL_ENCOUNTER_PAGE,
        LOAD_MORE_ENCOUNTERS,
        ENCOUNTER_PAGE_CHANGED,
        STALE_ENCOUNTER_PAGE
      )(state, action)
  }
}

export const cerfasUploaded: Reducer<boolean> = (state = true, action) => {
  const { type } = action
  switch (type) {
    case STOPPING_ENCOUNTER:
    case CERFAS_AND_DOCUMENTS_SAVED:
      return true
    case SAVING_CERFA_AND_DOCUMENTS:
      return false
    default:
      return state
  }
}

export default combineReducers({
  allIds,
  byId,
  currentId,
  total,
  shown,
  cerfasUploaded
})
