import React, { useReducer, useEffect, useRef, useContext } from 'react'
import styled from 'styled-components/macro'
import { Trans } from '@lingui/macro'
import { Button } from '../../Components'
import { EditPractitionerContext } from '../container/EditPractitionerForm'
import { toBase64 } from '../../DocumentReference/sagas'
import { byMessage, init } from '../ducks'
import * as actions from '../actions'

const HeadImage = styled.img`
  position: absolute;
  border: 0.25rem solid #f1f0ee;
  box-sizing: border-box;
  top: 0;
  left: 0;
  width: 100%;
  border-radius: 50%;
  height: 100%;
  object-fit: cover;
`

const HeadButton = styled(Button)`
  margin-top: 1rem;
  width: max-content;
`

const CustomButton = styled.button.attrs({ type: 'button' })`
  background: transparent;
  border: none;
  font-size: small;
  color: ${p => p.theme.ocean};
  width: max-content;
  cursor: pointer;
  margin-top: 0.5rem;
`

const Flex = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  & > :not(:last-child) {
    margin-bottom: 0.5rem;
  }
`
const Video = styled.video`
  border: 0.25rem solid #f1f0ee;
  width: 100%;
  box-sizing: border-box;
  border-radius: 50%;
  height: 100%;
  object-fit: cover;
  background: ${p => p.theme.nevada};
`
const Canvas = styled.canvas`
  position: absolute;
  visibility: hidden;
  width: 100%;
  border-radius: 50%;
  height: 100%;
  object-fit: fill;
`

const Container = styled.div`
  position: relative;
  width: 10rem;
  height: 10rem;
`

const HiddenInput = styled.input.attrs({ type: 'file' })`
  display: none;
`

const initialState = {
  isConverted: false,
  isPlaying: false,
  data: null,
  id: null,
  shouldDisplayFeedBack: false,
  isLoading: false,
  file: null,
  message: 'prendre la photo'
}

const removeByKey = (myObj, deleteKey) => {
  return Object.keys(myObj)
    .filter(key => key !== deleteKey)
    .reduce((result, current) => {
      result[current] = myObj[current]
      return result
    }, {})
}

const ProfilePicture = () => {
  const [resource, setResource] = useContext(EditPractitionerContext)
  let [state, dispatch] = useReducer(byMessage, initialState, init)

  const photo = resource.practitioner?.photo?.[0]?.data
  const type = resource.practitioner?.photo?.[0]?.contentType
  const streamRef = useRef(null)
  const video = useRef(null)
  const canvas = useRef(null)
  const input = useRef(null)
  const isBinaryCreated = false

  if (photo != null) {
    state.data = `data:${type};base64,${photo}`
    state.message = 'supprimer la photo'
  }

  // when we change resource.practitioner
  useEffect(() => {
    dispatch({ type: actions.RESET })
  }, [resource])

  useEffect(() => {
    if (
      video.current != null &&
      navigator.mediaDevices &&
      navigator.mediaDevices.getUserMedia
    ) {
      if (state.isPlaying === true) {
        // Not adding `{ audio: true }` since we only want video now
        navigator.mediaDevices
          .getUserMedia({ video: true })
          .then(function(stream) {
            streamRef.current = stream
            video.current.srcObject = stream
            video.current.play()
          })
      } else {
        if (streamRef.current != null) {
          streamRef.current.getTracks().forEach(track => track.stop())
        }
        video.current.srcObject = null
      }
    }
  }, [state.isPlaying])

  useEffect(() => {
    const file = state.file

    if (file != null && !state.isConverted) {
      if (file.size > 500000) {
        alert(
          'Votre image dépasse la taille réglementaire. Veuillez réduire son poids ou choisir une image de500kB ou moins '
        )
        dispatch({ type: actions.DELETING_PICTURE })
      } else {
        toBase64(file).then(newFile => {
          const picture = { contentType: file.type, data: newFile }
          state.isConverted = true
          setResource({
            ...resource,
            practitioner: {
              ...resource.practitioner,
              photo: [picture]
            }
          })
        })
      }
    }
  }, [isBinaryCreated, resource, setResource, state])

  const handleClick = e => {
    e.preventDefault()
    if (input.current != null) {
      input.current.click()
    }
  }

  const handleChange = e => {
    const [file] = e.target.files
    if (file == null) {
      console.warn('no file')
      return
    }
    dispatch({ type: actions.UPLOADING_PICTURE, payload: { file } })
  }

  return (
    <Flex>
      <Container>
        <Canvas ref={canvas} width={160} height={120} />
        <Video ref={video} width={160} height={120} />
        {state.data != null && <HeadImage src={state.data} />}
      </Container>
      {state.isPlaying === true ? (
        <HeadButton
          type="button"
          color="emerald"
          variant="outline"
          onClick={() => {
            if (canvas.current != null) {
              const context = canvas.current.getContext('2d')
              // Trigger photo take
              context.drawImage(video.current, 0, 0, 160, 120)
              dispatch({
                type: actions.PICTURE_TAKEN,
                payload: { data: canvas.current.toDataURL() }
              })
              const [, payload] = canvas.current.toDataURL().split(',')
              const picture = { contentType: 'image/png', data: payload }
              setResource({
                ...resource,
                practitioner: {
                  ...resource.practitioner,
                  photo: [picture]
                }
              })
            }
          }}
        >
          Ok
        </HeadButton>
      ) : (
        <div>
          <HeadButton
            type="button"
            color="ocean"
            variant="outline"
            onClick={() => {
              switch (state.message) {
                case 'supprimer la photo':
                  dispatch({ type: actions.DELETING_PICTURE })
                  const newEditPractitioner = removeByKey(
                    resource.practitioner,
                    'photo'
                  )
                  setResource({
                    ...resource,
                    practitioner: newEditPractitioner
                  })
                  break

                default:
                  dispatch({ type: actions.STARTING_CAMERA })
                  break
              }
            }}
          >
            {state.message}
          </HeadButton>
          {state.data == null && state.file == null && (
            <div>
              <CustomButton onClick={handleClick}>
                <Trans>Import a picture</Trans>
              </CustomButton>
              <HiddenInput
                ref={input}
                accept="image/*"
                onChange={handleChange}
              />
            </div>
          )}
        </div>
      )}
    </Flex>
  )
}

export default ProfilePicture
