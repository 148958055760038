import React from 'react'
import { Trans } from '@lingui/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStripeS } from '@fortawesome/free-brands-svg-icons'
import { generateCreationStripeAccountLink } from '../utils/utils'
import useCurrentOrganizationId from '../../Organization/containers/CurrentOrganizationIdProvider'
import styled from 'styled-components/macro'
import usePractitioner from '../../Practitioner/usePractitioner'
import { getOrganizationById } from '../../Organization/selector'
import { useSelector } from 'react-redux'

const Header = styled.header`
  font-weight: bold;
  font-size: larger;
  border-top: 1px solid ${p => p.theme.gray};
  padding-top: 1rem;
  margin-bottom: 1rem;
`

const Label = styled.div`
  color: ${props => props.theme.nevada};
  margin-right: ${props => props.theme.spacing.medium};
  margin-bottom: ${p => p.theme.spacing.small};
`

const CustomLink = styled.a`
  background-color: ${props => props.theme.ocean};
  color: white;
  height: 3rem;
  width: 15rem;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  text-decoration: none;
  border-radius: 5px;
  font-weight: bold;
`

const StripeCreateAccountButton = () => {
  const currentPractitioner = usePractitioner()
  const organizationId = useCurrentOrganizationId()
  const currentOrganization = useSelector(getOrganizationById(organizationId))
  // we generate an url with practitioner and organization data
  // to prefill them during the creation of Stripe Account.
  const stripeExpressUrl = generateCreationStripeAccountLink(
    currentPractitioner,
    currentOrganization
  )
  return (
    <React.Fragment>
      <Header>
        <Trans>Create my Stripe account</Trans>
      </Header>
      <Label>
        <Trans>
          Click on this button and follow step to create your stripe account
        </Trans>
      </Label>
      <CustomLink href={stripeExpressUrl}>
        <FontAwesomeIcon icon={faStripeS} />
        &nbsp;
        <Trans>Create my Stripe account</Trans>
      </CustomLink>
    </React.Fragment>
  )
}

export default StripeCreateAccountButton
