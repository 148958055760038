import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Trans } from '@lingui/macro'
import * as R from 'ramda'
import { useContext } from 'react'
import styled from 'styled-components/macro'
import { Input } from '../Components'
import { ModelContext, ReadOnlyContext } from './utils'

const IconContainer = styled.span`
  margin-right: 0.5rem;
  display: inline;
`

const Checkbox = styled.div`
  display: flex;
  font-size: ${p => p.theme.smaller};
`

const CheckboxInput = styled(Input)`
  margin-right: ${p => p.theme.spacing.small};
  border: none;
  display: flex;
  align-items: baseline;

  font-size: 14px;
  line-height: 20px;
`
const Container = styled.div`
  display: flex;
  align-items: baseline;
  font-size: 14px;
  line-height: 20px;
`
const YamlConsentInput = ({ yaml }) => {
  const readOnly = useContext(ReadOnlyContext)
  const [model, setModel] = useContext(ModelContext)
  const lens = R.lensPath(yaml.name.split('.'))

  const mergeConsentValue = (lens, model) => {
    return R.mergeDeepRight(model, R.set(lens, true, {}))
  }
  const unmergeConsentValue = (lens, model) => {
    return R.mergeDeepRight(model, R.set(lens, false, {}))
  }

  const handleChange = e => {
    if (e.target.checked === true) {
      setModel(mergeConsentValue(lens, model))
    } else {
      setModel(unmergeConsentValue(lens, model))
    }
  }

  let consent = null
  if (readOnly) {
    consent = (
      <Container>
        <IconContainer>
          <FontAwesomeIcon icon={faCheck} />
        </IconContainer>
        <div>
          <Trans>
            Je certifie sur l’honneur avoir procédé au recueil du consentement
            du patient pour l’utilisation de la solution. Les professionnels de
            santé pourront accéder aux données du patient. J’ai imprimé le
            consentement en deux exemplaires dont un pour le patient et je
            conserve un exemplaire en cas de contrôle.
          </Trans>
        </div>
      </Container>
    )
  } else if (!readOnly) {
    consent = (
      <Checkbox>
        <CheckboxInput
          type="checkbox"
          id="consent"
          name="consent"
          label={yaml.label}
          required
          onChange={handleChange}
          defaultChecked={model.$isConsentPrinted}
        />
      </Checkbox>
    )
  }

  return consent
}

export default YamlConsentInput
