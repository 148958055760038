/*eslint-disable*/
import { combineReducers, Reducer } from 'redux'
import {
  API_ERRORED,
  DOCUMENT_REFERENCES_RECEIVED,
  DOCUMENT_REFERENCE_RECEIVED,
  DOCUMENT_REFERENCE_REMOVED
} from './actions'
import { appendAllUniqueIds, reduceById, removeById } from '../Shared/ducks'
import { FHIR_RESOURCES_RECEIVED } from '../Shared/actions'
import { ById, ReduxState } from '../Shared/ducks/types'
import { DocumentReference } from 'fhir-stu3'

export const allIds: Reducer<string[]> = (state = [], action) => {
  const { type, payload } = action
  switch (type) {
    case FHIR_RESOURCES_RECEIVED:
    case DOCUMENT_REFERENCES_RECEIVED:
      return appendAllUniqueIds(state, payload.DocumentReference)
    case DOCUMENT_REFERENCE_RECEIVED:
      return appendAllUniqueIds(state, payload.documentReference)
    case DOCUMENT_REFERENCE_REMOVED:
      return state.filter(id => id !== payload.documentReference.id)
    case API_ERRORED:
      return state
    default:
      return state
  }
}

export const byId: Reducer<ById<DocumentReference>> = (state = {}, action) => {
  const { type, payload } = action
  switch (type) {
    case FHIR_RESOURCES_RECEIVED:
    case DOCUMENT_REFERENCES_RECEIVED:
      return reduceById(state, payload.DocumentReference)
    case DOCUMENT_REFERENCE_RECEIVED:
      return {
        ...state,
        [payload.documentReference.id]: payload.documentReference
      }
    case DOCUMENT_REFERENCE_REMOVED:
      return removeById(state, payload.documentReference)
    case API_ERRORED:
      return state
    default:
      return state
  }
}

export const getActiveDocumentsByencounterID = (encounterID: string) => (
  state: ReduxState
) => {
  return state.documentReferences.allIds
    .map(id => state.documentReferences.byId[id])
    ?.filter(
      ref => ref.context?.encounter?.reference === 'Encounter/' + encounterID
    )
    ?.filter(ref => ref.status !== 'entered-in-error')
}

export default combineReducers({
  allIds,
  byId
})
