import React, { useReducer } from 'react'
import styled from 'styled-components/macro'
import BetterSlotsView from '../../Appointment/containers/BetterSlotsView'
import moment from 'moment'
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useUpdateEffect } from 'react-use'
import { darken, lighten } from 'polished'
import PerformerInfo from '../../Practitioner/components/PerformerInfo'

const Container = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  & > :not(:last-child) {
    margin-bottom: 1rem;
  }
`
const Flex = styled.div`
  display: flex;
  & > nav {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    // virtually align the item using the margin
    margin-top: 1.5rem;
    flex: 0 0 3rem;
    & > button {
      &:hover,
      &:focus {
        cursor: pointer;
        outline: none;
        background: ${p => lighten(0.25, p.theme.ocean)};
        color: ${p => darken(0.25, p.theme.ocean)};
        border-color: ${p => darken(0.25, p.theme.ocean)};
      }
      background: none;
      border: 1px solid ${p => p.theme.ocean};
      border-radius: 1rem;
      height: 2rem;
      width: 2rem;
      color: ${p => p.theme.ocean};
    }
  }
`

const BookingViewNav = ({ disabled, onClick, icon }) => (
  <nav>
    {!disabled && (
      <button type="button" onClick={onClick} disabled={disabled}>
        <FontAwesomeIcon icon={icon} />
      </button>
    )}
  </nav>
)

BookingViewNav.defaultProps = {
  disabled: false,
  onClick: () => {}
}

const initialState = {
  start: moment().format('YYYY-MM-DD'),
  end: moment()
    .add(5, 'days')
    .format('YYYY-MM-DD')
}

/**
 * Select the period according to the slot
 * This method makes sure the slot is inside the date range
 * It is useful when a user navigate back to the booking view.
 * It allows the component to show directly the correct period
 * @param state
 * @param {Slot} slot
 * @return {Period} period
 */
const computePeriod = (state, slot) => {
  let period = { ...state }
  while (moment(slot.start).isAfter(period.end)) {
    period = {
      start: moment(period.start)
        .add(5, 'days')
        .format('YYYY-MM-DD'),
      end: moment(period.end)
        .add(5, 'days')
        .format('YYYY-MM-DD')
    }
  }
  return period
}

const reducer = (state, action) => {
  const { type, payload } = action
  switch (type) {
    case 'next':
      return {
        start: moment(state.start)
          .add(5, 'days')
          .format('YYYY-MM-DD'),
        end: moment(state.end)
          .add(5, 'days')
          .format('YYYY-MM-DD')
      }
    case 'changed':
      // if the current slot as changed
      return { ...state, ...computePeriod(state, payload) }
    case 'previous':
      return {
        start: moment(state.start)
          .subtract(5, 'days')
          .format('YYYY-MM-DD'),
        end: moment(state.end)
          .subtract(5, 'days')
          .format('YYYY-MM-DD')
      }
    default:
      return state
  }
}
const BookingView = ({ performerID, slots, slot, onChange, children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  // when the BookingView is mounted, we may already have a slot selected,
  // if this is the case, we want to jump straight to the correct period
  // the "change" action will update the state accordingly
  /*React.useEffect(() => {
    if (slot != null && slots.find(s => s.id === slot.id))
      dispatch({ type: 'changed', payload: slot });
  }, [slot, slots]);*/

  const handleNext = () => {
    dispatch({ type: 'next' })
  }
  const handlePrevious = () => {
    dispatch({ type: 'previous' })
  }
  useUpdateEffect(() => {
    onChange(state)
  }, [state])

  // prevent user to go in the past
  // state represent the number of weeks from now
  const disabledPrevious = moment(state.start).isSame(moment(), 'day')

  // having the practitionerRoles we can get all the practitioners ids
  // and show each practitioner by separate with their schedule
  return (
    <Container>
      <PerformerInfo performerId={performerID} />
      <Flex>
        <BookingViewNav
          onClick={handlePrevious}
          icon={faArrowLeft}
          disabled={disabledPrevious}
        />
        <BetterSlotsView period={state} slots={slots} slot={slot}>
          {children}
        </BetterSlotsView>
        <BookingViewNav onClick={handleNext} icon={faArrowRight} />
      </Flex>
    </Container>
  )
}
BookingView.defaultProps = {
  onChange: () => {},
  slot: null
}
// React.memo will reduce the number of rerender
// it won't trigger a render until the props change
export default React.memo(BookingView)
