import styled from 'styled-components/macro'

const InlineListItem = styled.li`
  padding: 0.5rem;
  color: ${p => p.theme.white};
  font-size: small;
  border-radius: 0.25rem;
  background: ${p => p.theme[p.color]};
  box-shadow: ${p => p.theme.boxShadow};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
`

InlineListItem.defaultProps = {
  color: 'gray',
}

export default InlineListItem
