import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import Amplify from 'aws-amplify'
import store, { persistor } from './store/index'
import awsconfig from './aws-exports'
import { initAnalytics } from './Shared/analytics'
import App from './App'
// amplify needs to be configured otherwise it will complain on the console
// see https://docs.amplify.aws/lib/auth/getting-started/q/platform/js#configure-your-application
Amplify.configure(awsconfig)

// configure Analytics as soon as possible
initAnalytics()

const container = document.getElementById('root')
const root = createRoot(container!)
root.render(
  // @ts-ignore
  <Provider store={store}> 
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>
)
