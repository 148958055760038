import * as yaml from 'js-yaml'

export const loadForm = async formName => {
  let fileContents

  require('isomorphic-fetch')
  const url = `/form/${formName}.yml`
  const resp = await fetch(url)
  fileContents = await resp.text()

  return yaml.safeLoad(fileContents)
}
