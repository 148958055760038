import styled from 'styled-components/macro'
import React, { useEffect, useState, useCallback } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy } from '@fortawesome/free-regular-svg-icons'
import { Trans } from '@lingui/macro'

const HoverDiv = styled.div`
  display: flex;
  & > div {
    margin-right: 0.5rem;
  }
  & > svg {
    opacity: 0;
  }
  &:hover,
  &:active {
    cursor: pointer;
    color: ${p => p.theme.ocean};
  }
  &:hover > svg {
    opacity: 1;
  }
`

const useFeedback = () => {
  const [state, setState] = useState(false)
  useEffect(() => {
    let timeout = setTimeout(() => {
      setState(false)
    }, 2000)
    return () => {
      clearTimeout(timeout)
    }
  }, [state])
  const setActive = useCallback(() => {
    setState(true)
  }, [])
  return {
    active: state,
    setActive
  }
}

/**

 * @param children
 * @returns {JSX.Element}
 * @constructor
 */
const CopyableDiv = ({ children }) => {
  //Turns out lots of users are copying the patients details
  // they paste the patient info in their other Health Record
  // this component paste the content of children in the pastebin
  // this will save some click :)

  // useFeedback is a simple hook that will display a micro interaction
  // this would let the user know when they have copied the data
  const { active, setActive } = useFeedback()
  const handleClick = () => {
    // Children could be empty or null.
    let text = ''
    // remove all white spaces (from phone numbers or NIR)
    // hypothetically this way it will be easier to be read from another input
    if (typeof children === "string") {
      text = children.replace(/\s/g, '')
    }
    navigator.clipboard.writeText(text).then(() => {
      setActive()
    })
  }

  return (
    <HoverDiv onClick={handleClick}>
      <div>{children}</div>
      <FontAwesomeIcon icon={faCopy} />
      {active && (
        <div style={{ marginLeft: '.5rem', color: '#77808e' }}>
          <Trans>copied</Trans>!
        </div>
      )}
    </HoverDiv>
  )
}

export default CopyableDiv
