import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

// Analytics
// - Sentry: bugs and crashlytics
// - Mixpanel: events reporting

export const initSentry = () => {
  if (process.env.REACT_APP_SENTRY_DSN == null) {
    console.error(`[Medeo] REACT_APP_SENTRY_DSN is not configured...
please set REACT_APP_SENTRY_DSN in your .env
`)
    return
  }
  if (process.env.REACT_APP_SENTRY_ORG_SLUG == null) {
    console.error(`[Medeo] REACT_APP_SENTRY_ORG_SLUG is not set...
Please set REACT_APP_SENTRY_ORG_SLUG in your .env
`)
    return
  }

  // set up sentry with both error and performance data
  // see https://sentry.io/medeo/react/getting-started/javascript-react/
  Sentry.init({
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT ?? 'production',
    dsn: process.env.REACT_APP_SENTRY_DSN,
    // REACT_APP_SENTRY_RELEASE is generated during build using the commit revId
    // this env var is not meant to be on your .env
    release: process.env.REACT_APP_SENTRY_RELEASE ?? 'develop',
    integrations: [new Integrations.BrowserTracing()],
    // limit depth as higher values cause the breadcrumbs to be over 100kB
    // this results in the bug not being sent to sentry !
    normalizeDepth: 3,
    // we don't want to send console logs to sentry.
    beforeBreadcrumb: (breadcrumb) => {
      if (breadcrumb.category === 'console') {
        return null
      }
      return breadcrumb
    },
    tracesSampleRate: 0.1
  })
}

export const initAnalytics = () => {
  initSentry()
}
export const configureAnalytics = (practitioner) => {
  if (practitioner == null) {
    return
  }
  Sentry.configureScope(function(scope) {
    const email = practitioner.telecom?.find?.((t) => t.system === 'email')
      ?.value
    const [name] = practitioner.name ?? []
    const username = `${name?.family} ${name?.given?.[0]}`
    scope.setUser({ email: email, id: practitioner.id, username: username })
  })
}
