import { getInterpretationCodingsFromObservation } from '../../utils/fhir'
import React from 'react'
import styled from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-solid-svg-icons'

const Circle = styled.span`
  position: relative;
  margin-top: 0.1em;
  margin-right: ${p => p.theme.spacing.small};
  width: 1em;
  height: 1em;
`

const InterpretationView = ({ observation }) => {
  const [firstCoding] = getInterpretationCodingsFromObservation(observation)

  if (!firstCoding || firstCoding.code === 'N') {
    return (
      <Circle>
        <FontAwesomeIcon icon={faCircle} color="#57DA92" />
      </Circle>
    )
  }

  return (
    <Circle>
      <FontAwesomeIcon icon={faCircle} color="#F7B029" />
    </Circle>
  )
}

export default InterpretationView
