// Fetching PatientsList
// If it's OK
export const PATIENTS_RECEIVED = 'PATIENTS_RECEIVED'
// If it's Error
export const API_ERRORED = 'API_ERRORED'

// Search Patients
export const SEARCHING_PATIENTS = 'SEARCHING_PATIENTS'
// Search Result returned by server
export const PATIENT_SEARCH_SUCCEEDED = 'PATIENT_SEARCH_SUCCEEDED'
export const PATIENT_SEARCH_FAILED = 'PATIENT_SEARCH_FAILED'
export const PATIENT_SEARCH_DELETED = 'PATIENT_SEARCH_DELETED'

//Edit patient
export const EDITING_PATIENT = 'EDITING_PATIENT'

// If validation Practitioner it's Error

export const PATIENT_RECEIVED = 'PATIENT_RECEIVED'

export const TOTAL_PATIENT_RECEIVED = 'TOTAL_PATIENT_RECEIVED'

export const GET_INITIAL_PATIENT_PAGE = 'GET_INITIAL_PATIENT_PAGE'
export const LOADING_MORE_PATIENTS = 'LOADING_MORE_PATIENTS'
export const LOADED_MORE_PATIENTS = 'LOADED_MORE_PATIENTS'
export const FAIL_RECEIVING_PATIENT = 'FAIL_RECEIVING_PATIENT'

export const editPatient = payload => ({
  type: EDITING_PATIENT,
  payload: payload
})

export const searchPatient = name => ({
  type: SEARCHING_PATIENTS,
  payload: name
})

export const deletePatient = () => ({
  type: PATIENT_SEARCH_DELETED,
  payload: []
})

export const succeedToSearchPatients = payload => {
  if (payload != null) {
    const patientsId = payload.map(patient => {
      return patient.id
    })
    return { type: PATIENT_SEARCH_SUCCEEDED, payload: patientsId }
  } else {
    return { type: PATIENT_SEARCH_SUCCEEDED, payload: [] }
  }
}

export const failToSearchPatients = error => ({
  type: PATIENT_SEARCH_FAILED,
  payload: error
})
