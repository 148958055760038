import React from 'react'
import { Link } from '@reach/router'
import { Trans } from '@lingui/macro'


const SignInMedeo = () => {

    return(
        <div>
            <h1><Trans>Your account has been successfully created</Trans></h1>
            <p><Trans>Your email address and the password you have just created will be used to connect to Medeo.care</Trans></p>
            <p><Trans>You can now go to</Trans><Link to="/"> <Trans>your Medeo account</Trans></Link></p> 
            <h3><Trans>Thank you for your trust!</Trans></h3>
        </div>
    )
}
export default SignInMedeo