import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components/macro'
import { Trans } from '@lingui/macro'
import { Button, Card, Spinner } from '../../Components'
import { saveEncounterSequence } from '../actions'
import PlanDefinitionActionDecoration from '../../PlanDefinition/containers/PlanDefinitionActionDecoration'
import PlanDefinitionAction from '../../PlanDefinition/containers/PlanDefinitionAction'
import usePlanDefinition from '../../PlanDefinition/usePlanDefinition'
import { getObservationsFromencounterID } from '../../Observation/selectors'
import { useNavigate } from '@reach/router'
import { getActivePreconsultationBypatientID } from '../../ProcedureRequest/ducks'
import { API, Auth } from 'aws-amplify'
import { search } from '../../Shared/actions'
import { useState } from 'react'

const Center = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: 1px solid ${p => p.theme.nevada};
  padding-top: 2rem;
`

const CustomFooter = styled(Card.Footer)`
  border-top: none;
`

const Borderer = styled.div`
  & > div {
    border-top: 1px solid ${p => p.theme.gray};
    padding-bottom: 1rem;
    padding-top: 1rem;
  }

  & > div:first-child {
    border-top: none;
    padding-top: 0;
  }
`

/**
 * This Component display an encounter in a single page
 * it has to be used within an EncounterSequenceManagerContext
 * @param patient
 * @param practitioner
 * @param encounter
 * @returns {*}
 * @constructor
 */
const EncounterRecap = ({ patient, practitioner, encounter }) => {
  const { planDefinition, goBack } = usePlanDefinition()
  const encounterType = encounter?.type?.[0]?.coding?.[0]?.code
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [isSubmitting, setIsSubmitting] = useState(false)

  // Here we fetch the last booking procedure request
  // For now, we assume that there can only be one booking-appointment
  // procedure request opened for a same patient at a time

  const bookingProcedureRequest = useSelector(
    getActivePreconsultationBypatientID(patient?.id)
  )

  const observations = useSelector(s =>
    getObservationsFromencounterID(s, encounter?.id)
  )

  const handleFinish = async () => {
    setIsSubmitting(true)
    const token = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken()
    // here we pass the sequenceWorkThrough and the planDefinitionId to create the RequestGroup
    dispatch(
      saveEncounterSequence(
        patient.id,
        practitioner.id,
        planDefinition.sequenceResponse,
        planDefinition.sequenceWorkThrough.action,
        planDefinition.planDefinitionId
      )
    )
    API.post('booking', `/booking/start/${bookingProcedureRequest.id}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: {
        encounterID: encounter.id
      }
    }).then(() => {
      dispatch(
        search('ProcedureRequest', {
          subject: 'Patient/' + patient.id,
          _sort: '-_id',
          _include: 'ProcedureRequest:performer'
        })
      )
    })
      .then(() => {
        dispatch(
          search('Encounter', {
            subject: `Patient/${patient.id}`,
            _sort: '-_id'
          })
        )
      })
      .then(() => {
        navigate(`/patient/${patient.id}/history`)
      }).then(() => {
        setIsSubmitting(false)
      })
      .catch(err => {
        console.error(err)
      })
  }
  if (isSubmitting) {
    return <Spinner />
  }
  return (
    <Card>
      <Card.Header>
        <h1>
          <Trans>Preconsultation summary</Trans>
        </h1>
      </Card.Header>
      <Borderer>
        {planDefinition.sequenceWorkThrough.action.map(a => (
          <PlanDefinitionActionDecoration key={a.title} action={a}>
            <PlanDefinitionAction
              action={a}
              encounter={encounter}
              patient={patient}
              observations={observations}
              readOnly={true}
            />
          </PlanDefinitionActionDecoration>
        ))}
      </Borderer>
      <CustomFooter>
        <Center>
          <Button onClick={goBack} color="ocean" variant="secondary">
            <Trans>Previous</Trans>
          </Button>
          {encounterType != null && (
            // This case is for the end of a pre-teleconsultation
            <Button
              data-test={'finishPreconsultation'}
              color="ocean"
              onClick={handleFinish}
            >
              <Trans>Finish preconsultation</Trans>
            </Button>
          )}
        </Center>
      </CustomFooter>
    </Card>
  )
}

export default EncounterRecap
