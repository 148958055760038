import { useSelector } from 'react-redux'
import {
  getCurrentCognitoGroups,
  getCurrentOrganizationId
} from '../../Auth/selectors'
import { getDevicesByOrganizationId } from '../../Device/selectors'
import { getOrganizationById } from '../../Organization/selector'
import usePractitioner from '../../Practitioner/usePractitioner'
import {
  getAdministratorRightBypractitionerID,
  getPractitionerRolesBypractitionerIDFromState
} from '../../PractitionerRole/selector'
import { MEDEO_ADMINISTRATOR_CODE } from '../../utils/codes'
import { displayFamilyName, displayGivenName } from '../display/entity'
import {
  displayPractitionerActiveRole,
  getPractitionerActiveCode
} from '../display/practitionerRole'

const Intercom = (props) => {
  const organizationId = useSelector(getCurrentOrganizationId)
  const practitionerRolesById = useSelector((s) =>
    getPractitionerRolesBypractitionerIDFromState(s, 'medical')
  )
  const practitioner = usePractitioner()
  const practitionerID = practitioner?.id
  const telecoms = practitioner?.telecom ?? []
  const phone = telecoms.find((e) => e.system === 'phone')
  const email = telecoms.find((e) => e.system === 'email')
  const practitionerRole = displayPractitionerActiveRole(
    practitionerRolesById[practitionerID]
  )
  const practitionerCode = getPractitionerActiveCode(
    practitionerRolesById[practitionerID]
  )

  const cognitoGroups = useSelector(getCurrentCognitoGroups)
  const isAdmin = useSelector((s) =>
    getAdministratorRightBypractitionerID(
      s,
      practitionerID,
      MEDEO_ADMINISTRATOR_CODE
    )
  )

  const orgaName = useSelector(getOrganizationById(organizationId))?.name
  // here we create an array equal to ["Manufacturer - Model, "Manufacturer - Model"]
  const devices = useSelector(
    getDevicesByOrganizationId(organizationId)
  )?.map?.((device) => {
    let manufacturer = 'Non défini'
    let model = 'Non défini'
    if (device && device.manufacturer) {
      manufacturer = device.manufacturer
    }
    if (device && device.model) {
      model = device.model
    }
    return `${manufacturer} - ${model}`
  })

  const intercomBody =
    props.fromKligo != null
      ? props.fromKligo
      : {
          app_id: 'xyqjec0b',
          user_id: practitioner ? practitioner.id : null,
          name: practitioner
            ? `${displayGivenName(practitioner)} ${displayFamilyName(
                practitioner
              )}`
            : null,
          email: email ? email.value : null,
          phone: phone ? phone.value : null,
          practitionerRole: practitionerRole.props.id, //here the id is equal to the name of Role
          practitionerCode: practitionerCode,
          company: {
            id: organizationId,
            name: orgaName
          },
          services: cognitoGroups.join(),
          isAdmin: isAdmin,
          devices: devices.join(),
          applicationUsed: 'Medeo',
          occupation: 'occupation',
          specialty: 'specialty'
        }

  if (window.Intercom && intercomBody) {
    // Intercom is the live chat, here we path userdata to identify the user. In the future we'll have to
    // send the company name in the company object { name : nameOfTheCompany }. We could use "plan" for the
    // next administration of medeo.
    // The condition is here to path Patients test
    window.Intercom('boot', intercomBody)
  }

  // }
  // we return nothing because the "window.Intercom" create already the Intercom messenger and identification
  return null
}

export default Intercom
