import {
  displayGivenName,
  displayFamilyName,
  displayPhoneNumber,
  displayEmail
} from '../../Shared/display/entity'
export const generateCreationStripeAccountLink = (
  practitioner,
  organization
) => {
  let url = new URL('https://dashboard.stripe.com/express/oauth/authorize')
  url.searchParams.append('response_type', 'code')
  url.searchParams.append('client_id', process.env.REACT_APP_STRIPE_CLIENT_ID)
  url.searchParams.append('scope', 'read_write')
  url.searchParams.append('suggested_capabilities[]', 'transfers')
  url.searchParams.append('stripe_user[business_type]', 'company')
  url.searchParams.append('stripe_user[email]', displayEmail(practitioner))
  url.searchParams.append(
    'stripe_user[phone_number]',
    displayPhoneNumber(practitioner)
  )
  url.searchParams.append(
    'stripe_user[first_name]',
    displayGivenName(practitioner)
  )
  url.searchParams.append(
    'stripe_user[last_name]',
    displayFamilyName(practitioner)
  )
  url.searchParams.append('stripe_user[url]', 'https://medeo.care/')
  url.searchParams.append('stripe_user[business_name]', organization.name)
  return decodeURIComponent(url.toString())
}
