import React, { useContext, useEffect, useReducer, useState } from 'react'
import AttachmentsList from '../../Attachment/components/AttachmentsList'
import Attachment from '../../Attachment/components/Attachment'
import AttachmentArchiveControl from '../../Attachment/components/AttachmentArchiveControl'
import AttachmentViewControl from '../../Attachment/components/AttachmentViewControl'
import styled from 'styled-components/macro'
import { getItemByLinkId, onChangeQuestionnaire } from '../utils'
// rename the import because it is looooong !
import { QuestionnaireResponseContext as ResponseContext } from './Questionnaire'
import {
  FHIR_EXTENSION_MIME_TYPE,
  FHIR_EXTENSION_MAX_OCCURS
} from '../../utils/codes'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  & > :not(:last-child) {
    margin-bottom: 1rem;
  }
`

const reducer = (state, action) => {
  const { type, payload } = action
  switch (type) {
    // when user decide to remove the file in the questionnaire
    // we remove it from QuestionnaireAttachment internal state
    case 'archive':
      return {
        ...state,
        // remove the element based on the index of the item
        list: [...state.list.filter((a, i) => i !== payload)]
      }
    case 'uploading':
      return {
        ...state,
        current: payload
      }
    case 'uploaded':
      return {
        ...state,
        progress: 0,
        current: null,
        list: [...state.list, payload]
      }
    case 'progress':
      return {
        ...state,
        progress: payload
      }
    case 'reset':
      return init(payload)
    default:
      return state
  }
}

// lazy init for the internal state
const init = ({ item, questionnaireResponse }) => {
  // defaultValue for the list of attachment is computed from the
  // questionnaireResponse passed as a parameter
  const questionnaireResponseItem = getItemByLinkId(
    questionnaireResponse,
    item.linkId
  )
  const list = questionnaireResponseItem?.answer?.map(a => a?.valueAttachment) ?? []
  return {
    linkId: item.linkId,
    list,
    current: null,
    progress: 0
  }
}

const QuestionnaireItemAttachment = ({ item, readOnly = false }) => {
  const [questionnaireResponse, dispatchQuestionnaireResponse] = useContext(
    ResponseContext
  )
  const [feedBackMessage, setFeedBackMessage] = useState(null)
  const [state, dispatch] = useReducer(
    reducer,
    { item, questionnaireResponse },
    init
  )

  const maxOccurs = item.extension?.find(
    e => e.url === FHIR_EXTENSION_MAX_OCCURS
  )?.valueInteger
  const fileType = item.extension?.find(e => e.url === FHIR_EXTENSION_MIME_TYPE)
    ?.valueCode

  // this is for updating state after questionnaireResponse is searched.
  // we need this useEffect because when the reducer is initialized,
  // the questionnaireResponse is not loaded and the init function returned an empty state
  useEffect(() => {
    dispatch({
      type: 'reset',
      payload: { item, questionnaireResponse }
    })
  }, [questionnaireResponse, item])

  // main difference from the DocumentReference version
  // is the handle change is not gonna call redux here,
  // we are going to update the QuestionnaireResponse instead.
  const handleChange = attachment => {
    if (attachment.url == null)
      {return dispatch({ type: 'uploading', payload: attachment })}

    dispatch({ type: 'uploaded', payload: attachment })
    onChangeQuestionnaire({
      questionnaireResponse: questionnaireResponse,
      dispatchQuestionnaireResponse: dispatchQuestionnaireResponse,
      name: item.linkId,
      value: attachment,
      type: 'file'
    })
  }
  const handleProgress = progress => {
    dispatch({ type: 'progress', payload: progress })
  }

  const handleArchive = i => {
    dispatch({
      type: 'archive',
      payload: i
    })
    onChangeQuestionnaire({
      questionnaireResponse: questionnaireResponse,
      dispatchQuestionnaireResponse: dispatchQuestionnaireResponse,
      name: item.linkId,
      type: 'deleteFile',
      index: i
    })
  }

  const onError = message => {
    setFeedBackMessage(message)
  }
  return (
    <Wrapper>
      {/*<pre>{JSON.stringify(item, null, 2)}</pre>*/}
      <AttachmentsList
        attachments={state.list}
        progress={state.progress}
        current={state.current}
        onChange={handleChange}
        onProgress={handleProgress}
        readOnly={readOnly}
        maxOccurs={maxOccurs}
        feedBackMessage={feedBackMessage}
        onError={onError}
        fileType={fileType}
      >
        {/* some comment here*/}
        {(attachment, progress, index) => (
          <Attachment attachment={attachment} progress={progress}>
            <AttachmentViewControl attachment={attachment} />
            <AttachmentArchiveControl onClick={() => handleArchive(index)} />
          </Attachment>
        )}
      </AttachmentsList>
      {feedBackMessage}
    </Wrapper>
  )
}

// item can be passed from the questionnaire or
// a questionnaire response...
QuestionnaireItemAttachment.defaultProps = {
  item: null
}

export default QuestionnaireItemAttachment
