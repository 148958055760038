import { Redirect, Router } from '@reach/router'
import { useEffect } from 'react'
import styled from 'styled-components/macro'
import { Patients } from './Patient/index'
import PatientPageContainer from './Patient/containers/PatientPageContainer'
import Providers from './Providers'
import Auth from './Practitioner/containers/Auth'
import BillingPage from './Settings/components/BillingPage'
import BookingCalendar from './Appointment/components/BookingCalendar'
import BookingPage from './Appointment/components/BookingPage'
import BookingRouterWrapper from './Appointment/components/BookingRouterWrapper'
import CreateUserPage from './Settings/components/CreateUserPage'
import Devices from './Device/containers/Devices'
import EditPatientPage from './Patient/components/EditPatientPage'
import EditOrganizationForm from './Organization/containers/EditOrganizationForm'
import MedeoBar from './Shared/components/MedeoBar'
import ModelForCustomDocumentPage from './Settings/components/ModelForCustomDocumentPage'
import NotFound from './Shared/components/NotFound'
import Notification from './Notifications/containers/Notification'
import PasswordRecovery from './Auth/components/PasswordRecovery'
import PractitionerPageInSettings from './Settings/components/PractitionerPageInSettings'
import RequirePractitioner from './Practitioner/containers/RequirePractitioner'
import RequireSignIn from './Auth/components/RequireSignIn'
import Schedule from './Schedule/components/Schedule'
import Security from './Settings/components/SecurityPage'
import SettingsPage from './Settings/components/SettingsPage'
import SignUp from './Auth/components/SignUp'
import SupportPage from './Support/component/SupportPage'
import TeleconsultationForPatient from './Teleconsultation/components/TeleconsultationForPatient'
import TeleconsultationPageForDistant from './Teleconsultation/components/TeleconsultationPageForDistant'
import UsersPage from './Organization/containers/UsersPage'
import UserPageForAdmin from './Settings/components/UserPageForAdmin'
import WaitingRoomTeleconsultations from './Teleconsultation/components/WaitingRoomTeleconsultations'
import YAMLAddPatientForm from './Yaml/YAMLAddPatientForm'
import Grid from './Shared/components/GridLayout'
import TestsPage from './Tests/container/TestsPage'
import CheckoutRouter from './Checkout/CheckoutRouter'

const StyledRouter = styled.div`
  display: contents;
  // hack to prevent reach/router from breaking the grid layout
  // when navigating through a subrouter <div tabindex="1"/> will pop
  // this happens on settings/* and on patient/* routes
  & > div[tabindex='-1'] {
    display: contents;
  }
`

/**
 * The following component returns the app accessible once signed
 * @return {*}
 * @constructor
 */
const SignedRoutes = () => {
  return (
    <Grid>
      <MedeoBar />
      <Router component={StyledRouter} id="scrollContainer">
        <Auth path="/auth/*" />
        <CheckoutRouter
          component={StyledRouter}
          path="/checkout/:appointmentId/*"
        />
        <RequirePractitioner path="/">
          <BookingPage path="booking/:locationId/">
            <BookingCalendar path="schedule" />
            <BookingRouterWrapper path="booking/*" />
          </BookingPage>
          <Patients path="/patients" />
          {/* the wild card * in the following line is necessary for the subrouter of PatientPage to handle correctly the routes */}
          <PatientPageContainer path="patient/:patientID/*" />
          {/* We use the same component two times, one of them is readOnly and the other is to edit the patient info */}
          <EditPatientPage path="patient/:patientID/show" readOnly />
          <EditPatientPage path="patient/:patientID/edit" readOnly={false} />
          <YAMLAddPatientForm path="patient/add/:step" />
          <WaitingRoomTeleconsultations path="/teleconsultation" />
          <TeleconsultationPageForDistant path="/teleconsultation/:patientID/:preconsultationId" />
          <SettingsPage path="settings">
            <Redirect from="settings" to="settings/edit" />
            <Devices path="devices/*" />
            <PractitionerPageInSettings path="edit" />
            <ModelForCustomDocumentPage path="model" />
            <BillingPage path="billing" />
            <Security path="security" />
            <UsersPage path="user/*" />
            <UserPageForAdmin
              path="organization/practitioner/:practitionerID"
              comesFromMainApp={true}
            />
            <CreateUserPage path="organization/practitioner/new" />
            <EditOrganizationForm path="organization" />
            <TestsPage path="tests/*" />
          </SettingsPage>
          <Schedule path="schedule/:practitionerID" />
          {/* by default redirects to /patients */}
          <NotFound default />
        </RequirePractitioner>
      </Router>
      <Notification />
    </Grid>
  )
}

const App = () => {
  useEffect(() => {
    if (window.Intercom) {
      // here it's the component for live chat, we put the same component with userData in Patient.jsx
      // for identify the user. The rest of the Intercom is in index.html in header part.
      window.Intercom('boot', {
        app_id: 'xyqjec0b'
      })
      window.Intercom('update') //for refresh Intercom chat
    }
  }, [])

  return (
    <Providers>
      {/*
       cannot use App as <Router component={App} />because it triggers rerender
       c.f. https://medeohealth.atlassian.net/browse/MED-2016 for more details
       rerender of the App component will make the screen flickering
      */}
      <Router primary={false}>
        {/*☝️ Setting primary to false stops Reach trying to control the page position/scroll */}
        <SignUp path="/signup" />
        <SupportPage path="/support-kligo" />
        <PasswordRecovery path="/recover" />
        <TeleconsultationForPatient path="patient/:patientID/teleconsultation/:preconsultationId/identifier/:identifier/organizationName/:organizationName" />
        <RequireSignIn path="/">
          <SignedRoutes path="/*" />
        </RequireSignIn>
      </Router>
    </Providers>
  )
}

export default App
