import { takeEvery, call, select } from 'redux-saga/effects';
import {
  searchFhirResourceWorker,
  saveFhirResourceWorker
} from '../Shared/sagas';
import {
  CREATING_PROCEDURE_REQUEST,
  FETCHING_PROCEDURE_REQUESTS_FROM_PERFORMER,
  UPDATING_PROCEDURE_REQUEST,
  FETCHING_LAST_PROCEDURE_REQUESTS_ACTIVE,
  FETCHING_PROCEDURE_REQUEST
} from './actions.js';
import { MEDEO_PROCEDURE_REQUEST_CODE_SYSTEM } from '../utils/codes';
import { getCurrentOrganizationId } from '../Auth/selectors';

// add a request for a second opinion in the database
function* addProcedureRequest(action) {
  const { payload: info } = action;
  const performerReference = `Organization/${info.performerId}`;
  yield call(
    saveFhirResourceWorker,
    {
      resourceType: 'ProcedureRequest',
      status: 'active',
      intent: 'proposal',
      code: {
        coding: [
          {
            system: MEDEO_PROCEDURE_REQUEST_CODE_SYSTEM,
            code: info.procedureCode
          }
        ]
      },
      context: { reference: 'Encounter/' + info.encounterID },
      performer: { reference: performerReference },
      subject: { reference: 'Patient/' + info.patientID },
      requester: info.requester,
      authoredOn: new Date().toISOString()
    },
    action
  );
}

function* fetchActiveProcedureRequestsFromPerformerWithTheirPatient(action) {
  yield call(
    // look for procedureRequest (for second opinion for example) when the user consult the patient list
    searchFhirResourceWorker,
    'ProcedureRequest',
    action => ({
      performer: action.payload.performerId,
      // _include: 'ProcedureRequest:subject',
      status: 'active'
    }),
    action
  );
}

export default function* watcherSaga() {
  const currentOrganizationId = yield select(getCurrentOrganizationId);
  yield takeEvery(
    [FETCHING_PROCEDURE_REQUESTS_FROM_PERFORMER],
    fetchActiveProcedureRequestsFromPerformerWithTheirPatient
  );
  yield takeEvery(
    [FETCHING_LAST_PROCEDURE_REQUESTS_ACTIVE],
    searchFhirResourceWorker,
    'ProcedureRequest',
    {
      performer: `Organization/${currentOrganizationId}`,
      _include: {
        $and: ['ProcedureRequest:subject', 'ProcedureRequest:encounter']
      },
      _sort: '-_id',
      _count: 20
    }
  );
  yield takeEvery([CREATING_PROCEDURE_REQUEST], addProcedureRequest);
  yield takeEvery(
    [UPDATING_PROCEDURE_REQUEST],
    saveFhirResourceWorker,
    a => a.payload
  );

  yield takeEvery(
    [FETCHING_PROCEDURE_REQUEST],
    searchFhirResourceWorker,
    'ProcedureRequest',
    ({ payload: p }) => ({ ...p })
  );
}
