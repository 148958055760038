import React from 'react'
import { useDispatch } from 'react-redux'
import { Button } from '../../Components'
import useSave from '../../Shared/hooks/useSave'
import styled from 'styled-components/macro'
import { Trans } from '@lingui/macro'
import moment from 'moment'
import {
  MEDEO_PROCEDURE_REQUEST_BOOKING_APPOINTMENT_CODE,
  MEDEO_PROCEDURE_REQUEST_CODE_SYSTEM,
  MEDEO_PROCEDURE_REQUEST_REMOTE_CONSULTATION_CODE
} from '../../utils/codes'
import { cancelAppointment } from '../../Appointment/actions'
import Modal from '../../Shared/components/Modal'
import usePractitioner from '../../Practitioner/usePractitioner'

const Container = styled.div`
  padding: 2rem 2rem 0 2rem;
`

const Separator = styled.div`
  margin: 2rem 0;
  border-bottom: 1px solid ${p => p.theme.nevada};
`

const Footer = styled.footer`
  display: flex;
  justify-content: flex-end;

  & > :not(:last-child) {
    margin-right: 0.5rem;
  }
`

const CancelTeleconsultationModal = ({ procedureRequest, appointment }) => {
  const dispatch = useDispatch()
  const currentPractitioner = usePractitioner()
  const [save] = useSave()

  const date = moment(appointment?.start).format('DD MMMM YYYY')

  const procedureRequestType = procedureRequest?.code?.coding?.find(
    coding => coding.system === MEDEO_PROCEDURE_REQUEST_CODE_SYSTEM
  )?.code

  const cancelTeleconsultation = async () => {
    // Here we update the teleconsultation Procedure Request
    await save({ ...procedureRequest, status: 'cancelled' })

    // And here we cancel the linked appointment and update the
    // booking Procedure Request
    dispatch(cancelAppointment(appointment, currentPractitioner))
  }

  if (
    procedureRequestType !== MEDEO_PROCEDURE_REQUEST_REMOTE_CONSULTATION_CODE &&
    procedureRequestType !== MEDEO_PROCEDURE_REQUEST_BOOKING_APPOINTMENT_CODE
  )
    {return null}

  const onClick = () => {
    switch (procedureRequestType) {
      case MEDEO_PROCEDURE_REQUEST_BOOKING_APPOINTMENT_CODE:
        // In the case the open procedure request is the booking one, i.e the pre tlc was not performed
        dispatch(cancelAppointment(appointment, currentPractitioner))
        break
      default:
        // In the case the procedure request is the teleconsultation one, we need to update it on top of cancelling
        // the appointment
        cancelTeleconsultation()
        break
    }
  }

  const text = (
    <Trans>
      You had a teleconsultation planned on the {date}. Cancelling it will
      delete your request and enable you to start a new remote consultation
      process. Do you want to confirm?
    </Trans>
  )

  const buttonText = <Trans>Cancel the Teleconsultation</Trans>

  return (
    <Modal
      component={props => (
        <Button color="ocean" variant="outline" {...props}>
          <Trans>Cancel the Teleconsultation</Trans>
        </Button>
      )}
      title={<Trans>Cancel remote consultation</Trans>}
    >
      <Container>
        {text}
        <Separator />
        <Footer>
          <Button color="ocean" variant="outline">
            <Trans>Go back</Trans>
          </Button>
          <Button color="ocean" onClick={onClick}>
            {buttonText}
          </Button>
        </Footer>
      </Container>
    </Modal>
  )
}

export default CancelTeleconsultationModal
