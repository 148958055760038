import React from 'react'

import { TextArea } from '../../Components'
import { Trans } from '@lingui/macro'
import ExtraRow from './ExtraRow'

const Comment = ({ inputRef, onChange, readOnly, defaultValue, inputId }) => {
  const handleBlur = e => {
    onChange(e.target.value)
  }

  const onKeyDown = e => {
    if (e.which === 13) {
      e.target.blur()
    }
  }

  return (
    <ExtraRow hidden={readOnly && !defaultValue}>
      <TextArea
        id={inputId}
        ref={inputRef}
        onBlur={handleBlur}
        onKeyDown={onKeyDown}
        readOnly={readOnly}
        defaultValue={defaultValue}
        placeholder="Saisir un commentaire"
        label={<Trans>Comment</Trans>}
      />
    </ExtraRow>
  )
}

export default Comment
