import Video from 'twilio-video';
import { getParamFromUrl } from '../../Shared/utils';
import { IPM_KIOSK_ORIGIN } from '../codes';

/**
 * Get the list of available media devices of the given kind.
 * @param {Array<MediaDeviceInfo>} deviceInfos
 * @param {string} kind - One of 'audioinput', 'audiooutput', 'videoinput'
 * @returns {Array<MediaDeviceInfo>} - Only those media devices of the given kind
 */
export function getDevicesOfKind(deviceInfos, kind) {
  return deviceInfos.filter(function(deviceInfo) {
    return deviceInfo.kind === kind;
  });
}

export function getVideoTrackFromInputDeviceSelection(deviceId) {
  return Video.createLocalVideoTrack({
    deviceId: deviceId
  });
}

/**
 * This function creates the audioTrack for the TLC.
 * depending on the audioInput selected, we apply specific options
 * for instance noise suppression should be disable for RNK stethoscopes
 * we apply these parameters using the audioInput details
 * @param audioInput
 * @return {Promise<LocalAudioTrack>}
 */
export function getAudioTrackFromInputDeviceSelection(audioInput) {
  const options =
    audioInput.label === 'Stéthoscope'
      ? {
          deviceId: audioInput.value,
          // We add those parameters here to prevent to browser from
          // auto adjusting the input sound, which would spoil the use
          // of the stethoscope (RNK)
          autoGainControl: false,
          noiseSuppression: false,
          echoCancellation: false
        }
      : {
          deviceId: audioInput.value,
          // We set the options back to normal
          autoGainControl: true,
          noiseSuppression: true,
          echoCancellation: true
        };
  return Video.createLocalAudioTrack(options);
}

/**
 * Get the list of available media devices.
 * @returns {Promise<DeviceSelectionOptions>}
 * @typedef {object} DeviceSelectionOptions
 * @property {Array<MediaDeviceInfo>} audioinput
 * @property {Array<MediaDeviceInfo>} audiooutput
 * @property {Array<MediaDeviceInfo>} videoinput
 */
export const getDeviceSelectionOptions = async () => {
  try {
    if (navigator.mediaDevices == null) {return;}

    await navigator.mediaDevices.getUserMedia({ audio: true, video: true });

    const deviceInfos = await navigator.mediaDevices.enumerateDevices();
    const kinds = ['audioinput', 'audiooutput', 'videoinput'];

    let kindToOptions = kinds.reduce(function(deviceSelectionOptions, kind) {
      deviceSelectionOptions[kind] = getDevicesOfKind(deviceInfos, kind);
      return deviceSelectionOptions;
    }, {});

    // We want to exclude the IPM microphone as it is not working.
    // Therefore, if we are coming from kiosk, we remove the integrated microphone
    // from the list.
    const origin = getParamFromUrl('origin');

    if (origin === IPM_KIOSK_ORIGIN) {
      kindToOptions = {
        ...kindToOptions,
        audioinput: kindToOptions.audioinput.filter(i => {
          return !isIntegratedMicrophoneLabel(i.label);
        })
      };
    }

    return kindToOptions;
  } catch (e) {
    console.log(e);
  }
};

export const isIntegratedMicrophoneLabel = label => {
  return (
    label.includes('Realtek High Definition Audio') ||
    label.startsWith('Microphone Array') ||
    label.startsWith('Mikrofonarray') ||
    label.startsWith('Micro MacBook Pro')
  );
};
