import React, { createContext, useEffect, useReducer, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components/macro'
import { Trans } from '@lingui/macro'
import { Button, Form, Spinner } from '../../Components'
import QuestionnaireComponent from './QuestionnaireComponent'
import { isQuestionnaireOfType } from '../utils'
import {
  saveQuestionnaireResponse,
  updateQuestionnaireResponse
} from '../../QuestionnaireResponse/actions'
import { MEDEO_CODE_FOR_PRACTITIONER_DOCUMENT_MODEL_QUESTIONNAIRE } from '../../utils/codes'
import usePractitioner from '../../Practitioner/usePractitioner'
import { getQuestionnaireById } from '../selector'

const ContainerBtn = styled.div`
  margin-top: 2em;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`

const CustomForm = styled(Form)`
  width: 100%;
`

const CustomSpinner = styled(Spinner)`
  height: 2rem;
  width: 2rem;
  display: block;
  margin: 3rem auto;
`

export const QuestionnaireResponseContext = createContext()

export const ADDING_RESPONSE_ITEM = 'ADDING_RESPONSE_ITEM'
export const SETTING_QUESTIONNAIRE_RESPONSE = 'SETTING_QUESTIONNAIRE_RESPONSE'

export const questionnaireResponseReducer = (state, action) => {
  switch (action.type) {
    case SETTING_QUESTIONNAIRE_RESPONSE:
      return action.payload
    case ADDING_RESPONSE_ITEM:
      return { ...state, item: action.payload }
    default:
      return state
  }
}

export const initQuestionnaireResponse = response => {
  if (response == null) {return { item: [] }}
  else {return response}
}

const Questionnaire = ({ id, response, readOnly }) => {
  const questionnaire = useSelector(getQuestionnaireById(id))
  const [questionnaireResponse, dispatchQuestionnaireResponse] = useReducer(
    questionnaireResponseReducer,
    response,
    initQuestionnaireResponse
  )
  const practitioner = usePractitioner()

  // it is used for multi-pages questionnaire
  const [indexOfPage] = useState(0)
  const [readOnlyContext, setReadOnlyContext] = useState(readOnly)
  const dispatch = useDispatch()

  useEffect(() => {
    if (response) {
      dispatchQuestionnaireResponse({
        type: SETTING_QUESTIONNAIRE_RESPONSE,
        payload: response
      })

      // we want to have readonly for all questionnaire response except ModelQuestionnaireReponse
      if (
        !isQuestionnaireOfType(
          questionnaire,
          MEDEO_CODE_FOR_PRACTITIONER_DOCUMENT_MODEL_QUESTIONNAIRE
        )
      ) {
        setReadOnlyContext(true)
      }
    }
  }, [id, questionnaire, response])

  const isQuestionnaireUpdatableReadOnly =
    response &&
    isQuestionnaireOfType(
      questionnaire,
      MEDEO_CODE_FOR_PRACTITIONER_DOCUMENT_MODEL_QUESTIONNAIRE
    )

  const handleSubmit = e => {
    e.preventDefault()
    // If the Questionnaire is part of Modèles and already have a response we update it
    if (
      response &&
      isQuestionnaireOfType(
        questionnaire,
        MEDEO_CODE_FOR_PRACTITIONER_DOCUMENT_MODEL_QUESTIONNAIRE
      )
    ) {
      dispatch(updateQuestionnaireResponse(questionnaireResponse))
    } else {
      dispatch(
        saveQuestionnaireResponse(
          questionnaireResponse,
          practitioner.id,
          0,
          id,
          // if the questionnaire is part of Model we add the type in the request
          isQuestionnaireOfType(
            questionnaire,
            MEDEO_CODE_FOR_PRACTITIONER_DOCUMENT_MODEL_QUESTIONNAIRE
          )
            ? MEDEO_CODE_FOR_PRACTITIONER_DOCUMENT_MODEL_QUESTIONNAIRE
            : null
        )
      )
    }
  }
  // we get the questionnaire from the state. We use a stringify to create two different object, not a reference
  return !questionnaire || questionnaire.length === 0 ? (
    <CustomSpinner />
  ) : (
    <QuestionnaireResponseContext.Provider
      value={[questionnaireResponse, dispatchQuestionnaireResponse]}
    >
      <CustomForm
        id="saveQuestionnaireResponse"
        name="saveQuestionnaireResponse"
        onSubmit={handleSubmit}
      >
        {/*<pre>{JSON.stringify(questionnaireResponse, null, 2)}</pre>*/}
        {questionnaire.item.length === 1 || readOnly === true
          ? questionnaire.item.map(i => (
              <QuestionnaireComponent
                item={i}
                key={i.linkId}
                readOnly={readOnlyContext}
              />
            ))
          : questionnaire.item[indexOfPage].item.map(i => (
              <QuestionnaireComponent
                item={i}
                key={i.linkId}
                readOnly={readOnlyContext}
              />
            ))}
        {(isQuestionnaireUpdatableReadOnly || response === undefined) && (
          <ContainerBtn>
            <Button color="ocean">
              <Trans>Save</Trans>
            </Button>
          </ContainerBtn>
        )}
      </CustomForm>
    </QuestionnaireResponseContext.Provider>
  )
}

export default Questionnaire
