import { Trans } from '@lingui/macro'
import { useNavigate } from '@reach/router'
import { Patient } from 'fhir-stu3'
import { FC, SyntheticEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import PatientSearchDetails from '../../Patient/components/PatientSearchDetails'
import { MOTIVES_ACTION_VALUE } from '../../PlanDefinition/utils'
import { getQuestionnaireByIdentifier } from '../../Questionnaire/selector'
import QuestionnaireResponse from '../../QuestionnaireResponse/components/QuestionnaireResponse'
import SelectPerformerQuestionnaireResponse from '../../QuestionnaireResponse/components/SelectPerformerQuestionnaireResponse'
import { search } from '../../Shared/actions'
import LoadingCardContent from '../components/LoadingCardContent'
import useBookingContext from './BookingContext'
import BookingFormFooter from './BookingFormFooter'
const Section = styled.div`
  margin: 2rem 0;
  border-top: 1px solid ${(p) => p.theme.gray};
`

const CustomForm = styled.form`
  margin: 0 2.5rem 0 2.5rem;
`

const Recap: FC = () => {
  const { state, book } = useBookingContext()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const motiveQuestionnaire = useSelector(
    getQuestionnaireByIdentifier(MOTIVES_ACTION_VALUE)
  )
  if (state == null || state.loading) {
    return <LoadingCardContent />
  }
  const patient: Patient = state.patient as Patient
  if (patient === undefined || patient === null) {
    return <LoadingCardContent />
  }
  const handleSubmit: React.EventHandler<SyntheticEvent> = async (e) => {
    e.preventDefault()
    book()
      .then((response) => {
        dispatch(
          search('ProcedureRequest', {
            subject: 'Patient/' + patient.id,
            _sort: '-_id',
            _include: 'ProcedureRequest:performer'
          })
        )
      })
      .then(() => {
        dispatch(
          search('Encounter', {
            subject: `Patient/${patient.id}`,
            _sort: '-_id'
          })
        )
        dispatch(
          search('Appointment', {
            patient: `Patient/${patient.id}`,
            _sort: '-_id'
          })
        )
      })
      .then(() => {
        // @todo get back patient id after creation.
        const dest = patient?.id ? `/patient/${patient.id}` : '/patients'
        navigate(dest)
      })
  }

  return (
    <>
      <CustomForm id="booking" onSubmit={handleSubmit}>
        {state.slot != null && state.performer != null && (
          <SelectPerformerQuestionnaireResponse
            slot={state.slot}
            performerId={state.performer.id}
          />
        )}
        <Section>
          <QuestionnaireResponse
            questionnaire={motiveQuestionnaire}
            questionnaireResponse={state.motiveResponse}
          />
        </Section>
        <Section>
          <h4>
            <Trans>Patient Information</Trans>
          </h4>
          <PatientSearchDetails patient={patient} />
        </Section>
      </CustomForm>
      <BookingFormFooter canNavigateNext={true} />
    </>
  )
}

export default Recap
