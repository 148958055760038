import { combineReducers } from 'redux'
import { FHIR_RESOURCES_RECEIVED } from '../Shared/actions'
import { appendAllUniqueIds, reduceById } from '../Shared/ducks'
import { Reducer } from 'redux'
import { ById } from '../Shared/ducks/types'
import { PlanDefinition } from 'fhir-stu3'

export const allIds: Reducer<string[]> = (state = [], action) => {
  switch (action.type) {
    case FHIR_RESOURCES_RECEIVED:
      return appendAllUniqueIds(state, action.payload.PlanDefinition)
    default:
      return state
  }
}

export const byId: Reducer<ById<PlanDefinition>> = (state = {}, action) => {
  switch (action.type) {
    case FHIR_RESOURCES_RECEIVED:
      return reduceById(state, action.payload.PlanDefinition)
    default:
      return state
  }
}

export default combineReducers({ allIds, byId })
