import { MEDEO_SELECT_PATIENT_QUESTIONNAIRE_IDENTIFIER } from '../utils/codes'
import {
  MEDEO_ENCOUNTER_TYPE_BOOKING,
  MEDEO_ENCOUNTER_TYPE_PRE_TELECONSULTATION,
  MEDEO_ENCOUNTER_TYPE_TELECONSULTATION_CONSULTATION
} from '../utils/encounter-type'

export const SYMPTOMS_AND_MOTIVES_ACTION_VALUE = 'symptoms-and-motives'
export const MOTIVES_ACTION_VALUE = 'motives'
export const DIAGNOSTIC_ACTION_VALUE = 'diagnosis'
export const SYNTHESE_ACTION_VALUE = 'synthesis'
export const ARRET_TRAVAIL_ACTION_VALUE = 'sick-leave'
export const FEUILLE_SOIN_ACTION_VALUE = 'caresheet-v2'
export const TOTAL_PRICE_ACTION_VALUE = 'totalPrice'
export const ORDONNANCE_MEDICAMENTEUSE_ACTION_VALUE = 'drug-prescription'
export const ORDONNANCE_PARAMEDICALE_ACTION_VALUE = 'paramedical-prescription'
export const PREAMBLE_ACTION_VALUE = 'preamble'
export const PREAMBLE_ACTION_VALUE_V2 = 'preamble-v2'
export const DOCUMENT_TELECONSULTATION_ACTION_VALUE = 'document'
export const ORDER_ACTION_VALUE = 'order'
export const OTHER_SYMPTOMS_ACTION_VALUE = 'other'
export const ORDER_RENEWAL_ACTION_VALUE = 'order-renewal'
export const URINARY_DISORDER_ACTION_VALUE = 'urinary-disorder'
export const GRIPPE_SYMPTOM_ACTION_VALUE = 'symptome-grippal'
export const RESPIRATORY_DISORDER_ACTION_VALUE = 'respiratory-disorder'
export const THROAT_PAIN_ACTION_VALUE = 'throat-pain'
export const DIARRHEA_VOMITING_ACTION_VALUE = 'diarrhea-vomiting'
export const SKIN_PROBLEM_ACTION_VALUE = 'skin-problem'
export const FEVER_ACTION_VALUE = 'fever'
export const AUTONOMOUS_ACTION_CONDITION_EXPRESSION = 'autonome'
export const ASSISTED_ACTION_CONDITION_EXPRESSION = 'assisté'
export const MOTIVE_ACTION_CONDITION_DESCRIPTION = 'motive-selected'
export const SYMPTOMS_QUESTIONNAIRE_SYSTEM =
  'http://medeo.io/fhir/Identifier/symptoms'
export const PRECONSULTATION_QUESTIONNAIRE_SYSTEM =
  'http://medeo.io/fhir/Identifier/questionnaire-preconsultation'
export const TELECONSULTATION_QUESTIONNAIRE_SYSTEM =
  'http://medeo.io/fhir/Identifier/questionnaire-teleconsultation'
export const PLAN_DEFINITION_IDENTIFIER_SYSTEM =
  'http://medeo.io/fhir/Identifier/plan-definition'
export const BOOKING_QUESTIONNAIRE_SYSTEM =
  'http://medeo.io/fhir/Identifier/questionnaire-booking'
export const TELE_EXPERTISE_QUESTIONNAIRE_SYSTEM =
  'http://medeo.io/fhir/Identifier/questionnaire-tele-expertise'
export const QUESTIONNAIRE_IDENTIFIER_SYSTEM =
  'http://medeo.io/fhir/Identifier/questionnaire'
export const PRE_CONSULTATION_PLAN_DEFINITION_VALUE = 'pre-consultation-v3'
export const TELECONSULTATION_PLAN_DEFINITION_VALUE = 'teleconsultation-v2'

export const PAYMENT_METHOD_ACTION_VALUE = 'payment-method'

export const getQuestionnaireIdentifierByAction = (action) => {
  // we use array here to comply with the previous implementation of this func
  // it was using evaluate and use to return arrays :/
  for (let test of action?.code) {
    for (let coding of test?.coding) {
      if (
        coding.system === PRECONSULTATION_QUESTIONNAIRE_SYSTEM ||
        coding.system === TELECONSULTATION_QUESTIONNAIRE_SYSTEM ||
        coding.system === BOOKING_QUESTIONNAIRE_SYSTEM ||
        coding.system === SYMPTOMS_QUESTIONNAIRE_SYSTEM ||
        coding.system === TELE_EXPERTISE_QUESTIONNAIRE_SYSTEM
      ) {
        return [coding]
      }
    }
  }
  return []
}

export const isActionIdentifierOfValue = (identifier, value) => {
  return identifier.code === value
}

/**
 * Here we select the plan identifier depending on the encounter type
 * On top of the encounter type, we need the information about whether we have a patient
 * or not, as the booking appointment plan definition won't be the same
 * (there is an additional action to create a patient if there is none yet)
 *
 * @param {*} encounterType
 */
export const getPlanDefinitionIdentifierValueFromEncounterType = (
  encounterType
) => {
  switch (encounterType) {
    case MEDEO_ENCOUNTER_TYPE_PRE_TELECONSULTATION:
      return PRE_CONSULTATION_PLAN_DEFINITION_VALUE
    case MEDEO_ENCOUNTER_TYPE_TELECONSULTATION_CONSULTATION:
      return TELECONSULTATION_PLAN_DEFINITION_VALUE
    default:
      throw new Error(
        `the encounterType ${encounterType} has no corresponding planDefinition value`
      )
  }
}

export const getQuestionnaireIdentifierValuesInStringFromEncounterType = (
  encounterType
) => {
  switch (encounterType) {
    case MEDEO_ENCOUNTER_TYPE_PRE_TELECONSULTATION:
      return `${PREAMBLE_ACTION_VALUE_V2},${PREAMBLE_ACTION_VALUE},${PAYMENT_METHOD_ACTION_VALUE},${OTHER_SYMPTOMS_ACTION_VALUE},${ORDER_RENEWAL_ACTION_VALUE},${URINARY_DISORDER_ACTION_VALUE},${GRIPPE_SYMPTOM_ACTION_VALUE},${RESPIRATORY_DISORDER_ACTION_VALUE},${THROAT_PAIN_ACTION_VALUE},${DIARRHEA_VOMITING_ACTION_VALUE},${SKIN_PROBLEM_ACTION_VALUE},${FEVER_ACTION_VALUE}`
    case MEDEO_ENCOUNTER_TYPE_TELECONSULTATION_CONSULTATION:
      return `${DIAGNOSTIC_ACTION_VALUE},${SYNTHESE_ACTION_VALUE},${ARRET_TRAVAIL_ACTION_VALUE},${FEUILLE_SOIN_ACTION_VALUE},${ORDER_ACTION_VALUE},${DOCUMENT_TELECONSULTATION_ACTION_VALUE},${TOTAL_PRICE_ACTION_VALUE}`
    case MEDEO_ENCOUNTER_TYPE_BOOKING:
      return `${MOTIVES_ACTION_VALUE}, ${MEDEO_SELECT_PATIENT_QUESTIONNAIRE_IDENTIFIER}`
    default:
      return ''
  }
}

/**
 * This function filter action List to keep only actions which are according to
 * motive and clinicalExam. If clinicalExam is false we keep preamble and payment method
 * In the case where clinicalExam is equal to true we keep all steps and filter symptoms questionnaires
 * @param {array} actionList
 * @param {string | null} motive // boolean in string (take care of that)
 * @param {boolean} clinicalExam
 */
export const filterActionsForPreconsultationWithConditions = (
  actionList,
  motive,
  clinicalExam = true
) => {
  // we prevent if motive is equal to null or undefined
  // in our case we have to wait selector to get motive
  // we put other for motive because it's the most classic symptom questionnaire
  // in our case the motive could be a value null sent by PlanDefinition
  // that why we have to prevent this situation with these conditions and not in arguments of the function
  if (motive == null) {
    motive = OTHER_SYMPTOMS_ACTION_VALUE
  }
  let newActionList

  if (clinicalExam === false) {
    //boolean is a string because it come form questionnaireResponse as valueString
    newActionList = actionList.filter(
      (a) =>
        a.condition?.some(
          (c) => c.expression === AUTONOMOUS_ACTION_CONDITION_EXPRESSION
        ) ?? false
    )
  } else if (clinicalExam === true) {
    // boolean is a string because it come form questionnaireResponse as valueString
    newActionList = actionList.filter((a) => {
      // 2 cases handled here:
      // - 1 the action has no condition of type MOTIVE_ACTION_CONDITION
      // - 2 the action has a condition of type MOTIVE_ACTION_CONDITION

      const motiveCondition = a.condition?.find(
        (c) => c.description === MOTIVE_ACTION_CONDITION_DESCRIPTION
      )
      if (motiveCondition == null) {
        return true
      }

      // starting from here we know there is a motive condition
      // we got to filter the actions that does not have the correct expression
      return motiveCondition?.expression === motive
    })
  }

  return newActionList
}
