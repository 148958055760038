import React, { useContext } from 'react'
import { useDispatch } from 'react-redux'
import { Auth } from 'aws-amplify'
import styled from 'styled-components/macro'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Trans } from '@lingui/macro'
import { Input, Button, Form } from '../../Components'
import SignUpContext from './SignUpContext'
import { createNotification } from '../../Notifications/actions'
import { signOut } from '../actions'

const CustomForm = styled.form`
  display: flex;
  flex-direction: column;
  & > ${Button} {
    margin-top: 2rem;
  }
`

const Hint = styled.span`
  margin-top: 0.5rem;
  font-size: small;
  display: flex;
  align-items: center;
  color: ${p => p.theme.nevada};
  & > svg {
    opacity: 0.5;
    width: 1.5rem !important;
    height: 1.5rem;
    margin-right: 0.5rem;
  }
`

const CGU = styled.div`
  input {
    margin-right: 1rem;
  }
  label {
    font-size: small;
    line-height: 1.5;
  }
`
const CustomSignUpForm = ({ token, organization, email, ...props }) => {
  const [, setValue] = useContext(SignUpContext)
  const dispatch = useDispatch()
  let error

  return props.authState === 'signUp' ? (
    <CustomForm
      onSubmit={async e => {
        e.preventDefault()
        const { elements } = e.currentTarget
        const password = elements.namedItem('password').value
        const confirmation = elements.namedItem('confirmation').value
        if (password !== confirmation) {
          error = 'Les valeurs des mots de passe sont différentes'
          return
        }

        // set context value so that we can use it in the confirmSignUp step
        setValue({ email: elements.namedItem('email').value })

        if (token === '') {return}

        const user = await Auth.signIn({
          username: elements.namedItem('email').value,
          password: token
        })

        if (user.challengeName !== 'NEW_PASSWORD_REQUIRED') {return}

        await Auth.completeNewPassword(user, password, {
          email: elements.namedItem('email').value,
          'custom:orgId': elements.namedItem('organization').value
        })
        try {
          props.onStateChange('signIn')
          dispatch(signOut())
        } catch (err) {
          dispatch(createNotification(err.message, 'error', 5000))
        }
      }}
    >
      <input
        type="hidden"
        name="organization"
        id="organization"
        value={organization}
      />
      <Form.Row>
        <Input
          type="email"
          name="email"
          id="email"
          label={<Trans>Email address</Trans>}
          required
          value={email}
          readOnly
        />
      </Form.Row>
      <Form.Row>
        <Input
          type="password"
          minLength="8"
          name="password"
          id="password"
          label={<Trans>Create your password</Trans>}
          required
        />
      </Form.Row>
      <Form.Row>
        <Input
          type="password"
          minLength="8"
          name="confirmation"
          id="confirmation"
          label={<Trans>Confirm password</Trans>}
          required
        />
      </Form.Row>
      <Form.Row>
        <Hint>
          <FontAwesomeIcon icon={faInfoCircle} />
          <Trans>Your password must include more than 8 characters</Trans>
        </Hint>
      </Form.Row>
      <CGU>
        <input name="cgu" id="cgu" type="checkbox" required />
        <label htmlFor="cgu">
          <Trans>
            By signing up, I accept the{' '}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.medeo-health.com/mentions-legales#CGU-Medeo"
            >
              Medeo Terms of Service
            </a>{' '}
            and acknowledge the Privacy Policy
          </Trans>
        </label>
      </CGU>
      <Form.Row>{error != null && <span>{error}</span>}</Form.Row>
      <Button>
        <Trans>Sign up</Trans>
      </Button>
    </CustomForm>
  ) : null
}

export default CustomSignUpForm
