import React, { useRef, useState } from 'react'
import useOnClickOutside from '../../Shared/hooks/useOnClickOutside'
import styled from 'styled-components/macro'
import { Card } from '../../Components'
import { hoverMixin } from '../../Shared/mixins'

const Relative = styled.div`
  justify-self: flex-start;
  position: relative;
  display: inline-block;
`
const Absolute = styled.div`
  position: absolute;
  left: -0.5rem;
  top: 0;
  // this transformation works well for the menu in the MedeoBar
  transform: translateX(-100%);
`

const List = styled.ul`
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
  flex-direction: column;
  width: max-content;
  text-align: left;
  & > li {
    flex: 1;
    padding: 0.5rem;
  }
`

const CustomCard = styled(Card)`
  padding: 0.5rem;
`
const Separator = styled.li`
  content: '';
  border-bottom: 1px solid ${p => p.theme.gray};
  padding: 0 !important;
  margin: 0.25rem 0;
`

const ListItem = styled.li`
  cursor: pointer;
  border-radius: 0.25rem;
  border: 1px solid transparent;
  ${hoverMixin};
  color: ${p => p.theme.black};
  & a {
    text-decoration: none;
    color: ${p => p.theme.black};
  }
  padding: 0;
`

/**
 * This component is a simplified version of the Shared/component/Menu

 * @param {React.Component} Component
 * @param {React.Children} children
 * @param {string} the url param that controls the opening of the menu
 * @return {JSX.Element}
 * @constructor
 */
const Menu = ({ component: Component, children }) => {
  const [open, setOpen] = useState(false)
  // the ref is used to catch any click outside
  // if the user click outside, navigate to the url without the control params
  const ref = useRef()
  useOnClickOutside(ref, () => {
    setOpen(false)
  })
  return (
    <Relative>
      {/* if the user clicks on the menu button, switch the param's state in the url */}
      <Component
        onClick={() => {
          setOpen(s => !s)
        }}
      />
      {open && (
        <Absolute ref={ref}>
          <CustomCard>
            <nav onClick={() => setOpen(false)}>
              <List>{children}</List>
            </nav>
          </CustomCard>
        </Absolute>
      )}
    </Relative>
  )
}
// we expose Separator and ListItem as compound components
Menu.Separator = Separator
Menu.ListItem = ListItem

export default Menu
