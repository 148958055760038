import createSimpleReducers from '../Shared/ducks/createSimpleReducers'
import { ReduxState } from '../Shared/ducks/types'
import { RequestGroup } from 'fhir-stu3'

export const getRequestGroupByencounterID = (encounterID: string) => (
  state: ReduxState
): RequestGroup | undefined => {
  return state.requestGroup.allIds
    .map(id => state.requestGroup.byId[id])
    .find(requestGroup => requestGroup?.context?.reference === `Encounter/${encounterID}`)
}
export default createSimpleReducers('RequestGroup')
