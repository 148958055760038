import { useDispatch, useSelector } from 'react-redux'
import { useClickAway } from 'react-use'
import { useLocation, useNavigate } from '@reach/router'
import moment from 'moment'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import React, { createContext, useEffect, useRef, useState } from 'react'
import { v4 as uuid } from 'uuid'
import useLazySearch from '../../Shared/hooks/useLazySearch'
import { search } from '../../Shared/actions'

import styled from 'styled-components/macro'
import 'react-big-calendar/lib/css/react-big-calendar.css'

import {
  findCollision,
  getPeriodFromView,
  getPractitionerType,
  hasEventsWithin,
  keepFreeSlots,
  MEDEO_SCHEDULE_TYPE_SYSTEM,
  MEDEO_SCHEDULE_TYPE_TELECONSULTATION,
  transformAppointmentResourceInEvent,
  transformScheduleResourceInEvent,
  usePollAppointment
} from '../utils'
import { FHIR_DATE_FORMAT } from '../../utils/dateUtils'
import {
  getPlanningByFirstAndLastDay,
  getSchedulesByActorId
} from '../selectors'
import { removeSchedule } from '../actions'
import {
  getAppointmentsByLocation,
  getAppointmentsBypractitionerID
} from '../../Appointment/selectors'
import { createGlobalStyle } from 'styled-components'
import EventDetails from '../components/EventDetails'
import { getCurrentOrganizationId } from '../../Auth/selectors'
import { getLanguage } from '../../I18n'
import useSave from '../../Shared/hooks/useSave'
import { getPractitionerRoleBypractitionerID } from '../../PractitionerRole/selector'
import {
  FHIR_EXTENSION_NUMBER_OF_PARTICIPANTS,
  MEDEO_APPOINTMENT_TYPE_TELECONSULTATION,
  MEDEO_ORGANIZATION_TYPE_IDENTIFIER_SYSTEM
} from '../../utils/codes'
import { getLocationByOrganizationId } from '../../Location/selectors'
import { getOrganizationById } from '../../Organization/selector'
import CalendarHeader from '../components/CalendarHeader'
import CalendarToolbar from '../components/CalendarToolbar'
import usePractitioner from '../../Practitioner/usePractitioner'

// utils from react big calendar, uncomment for use it
// import * as datesUtility from 'react-big-calendar/lib/utils/dates'

// to get the localizer for the calendar view
const localizer = momentLocalizer(moment)

// we cannot properly style the react-big calendar components
// here we create a global style to customize the rbc classes
// this is located here to be as close as possible to the calendar
// just like regular styled-components stuff
const EventStyle = createGlobalStyle`
  // there is no way to remove the time label other than this
  //https://github.com/jquense/react-big-calendar/issues/869
  .rbc-event-label {
	display: none !important;
  }

  .rbc-calendar {
	height: calc(100% - 3rem) !important;
  }

  .rbc-day-slot .rbc-event {
	border: none !important;
	color: white !important;
  }

  .rbc-toolbar {
	margin-top: -2.7rem;
	margin-bottom: 2rem !important;
  }

  .rbc-header {
	border-bottom-color: transparent !important;
	margin-top: 1rem;
	margin-bottom: 1rem;
  }

  .rbc-time-header {
	border-right-color: transparent !important;
  }

  .rbc-header + .rbc-header,
  .rbc-time-header-content {
	border-left-color: transparent !important;
  }

  .rbc-allday-cell {
	display: none;
  }

  .rbc-toolbar-label {
	display: flex;
	margin-left: .5rem;
	color: ${p => p.theme.ocean} !important;
  }

  .rbc-btn-group > :not(:last-child) {
	margin-right: 0.5rem !important;
  }

  .rbc-event, .rbc-day-slot .rbc-background-event {
	background-color: transparent !important;
	padding: 0 !important;
  }

  .rbc-day-slot .rbc-event {
	background: transparent !important;
	border: 3px solid transparent !important;

  }

  .rbc-day-slot .rbc-event, .rbc-day-slot .rbc-background-event {
	border: 1px solid transparent;
  }

  .rbc-today {
	background-color: white !important;
	color: ${p => p.theme.ocean} !important;
  }

  .rbc-time-view {
	background: white;
	box-shadow: ${p => p.theme.boxShadow};
	border-radius: .5rem;
	border-color: transparent !important;
  }

  .rbc-time-content {
	border-top-width: 1px;
  }

  .rbc-timeslot-group:nth-child(odd) {
	border-bottom-color: transparent;
  }


  .rbc-timeslot-group:nth-child(even) {
	border-bottom-style: dashed;
	border-bottom-color: #c7cbd0;
  }

  .rbc-time-gutter .rbc-timeslot-group:nth-child(even) {
	visibility: hidden;
  }

  .rbc-time-gutter {
	padding-left: 1rem;
	padding-right: 1rem;
  }

  .rbc-time-gutter .rbc-timeslot-group:nth-child(odd) {
	transform: translateY(-33%);
  }

  .rbc-day-slot .rbc-time-slot {
	border-top: 1px dotted #c7cbd088;

  }

  .rbc-timeslot-group:nth-child(odd) .rbc-time-slot:first-child {
	border-top: 0 solid transparent;
  }


  .rbc-day-slot .rbc-events-container {
	margin-right: 0;
  }

`

const Main = styled.div`
  display: flex;
  padding-top: 2rem;
  padding-bottom: 2rem;
  flex-direction: column;
  width: 100%;
`

const Flex = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  height: calc(100vh - 1rem);
`

// React-big-calendar offers a way to pass specific components
// here this is the event component used in all the view
const Event = styled.div.attrs(({ event }) => ({
  children: event.title
}))`
  background: ${p =>
    getBackgroundColorByType(p.event.isAppointment, p.event.status)};
  color: ${p => (p.event.isAppointment ? 'white' : 'black')};
  padding-left: 0.25rem;
  font-size: small;
  display: flex;
  align-items: center;
  border-left: ${p => getBorderColorByType(p.event.type)};
  height: 100%;
  &:hover {
    background: ${p => p.theme.ocean};
  }
`

const getBorderColorByType = type => {
  switch (type) {
    case MEDEO_APPOINTMENT_TYPE_TELECONSULTATION:
      return '0.25rem solid #0967D2'
    default:
      return 'none'
  }
}

const getBackgroundColorByType = (isAppointment, status) => {
  if (isAppointment) {
    return status === 'booked' ? '#5d6877' : '#c5cbd2'
  } else {
    return '#b3d6f7'
  }
}

export const CalendarViewContext = createContext({})
/**
 *
 * @param {string} start
 * @param {string} end
 * @param {string} schedule
 * @param {integer} duration of the slots in minutes
 * @return {{start: string, end: string, resourceType: string}[]}
 */
const computeSlots = (start, end, schedule, numberOfAppointment = 1) => {
  const duration = 15
  const delta = moment.duration(moment(end).diff(moment(start))).asMinutes()
  const length = delta / duration
  return Array.from({ length }).map((slot, i) => ({
    resourceType: 'Slot',
    status: 'free',
    schedule: {
      reference: schedule
    },
    start: moment(start)
      .add(i * 15, 'minutes')
      .format(FHIR_DATE_FORMAT),
    end: moment(start)
      .add((i + 1) * 15, 'minutes')
      .format(FHIR_DATE_FORMAT),
    extension: [
      {
        url: FHIR_EXTENSION_NUMBER_OF_PARTICIPANTS,
        valueInteger: numberOfAppointment
      }
    ]
  }))
}

const computeScheduleForPractitioner = (start, end, practitioner, role) => {
  const startDate = moment(start).format(FHIR_DATE_FORMAT)
  const endDate = moment(end).format(FHIR_DATE_FORMAT)
  const practitionerCode = role?.specialty?.[0]?.coding?.[0]?.code

  return {
    resourceType: 'Schedule',
    identifier: [
      {
        system: MEDEO_SCHEDULE_TYPE_SYSTEM,
        value: MEDEO_SCHEDULE_TYPE_TELECONSULTATION
      }
    ],
    active: true,
    specialty: [
      {
        coding: [
          {
            code: practitionerCode,
            system: 'http://snomed.info/sct'
          }
        ]
      }
    ],
    actor: [
      { reference: 'Practitioner/' + practitioner.id },
      { reference: 'PractitionerRole/' + role.id }
    ],
    planningHorizon: {
      start: startDate,
      end: endDate
    }
  }
}

/*const initialTime = moment().set({
   hour: 7,
  minute: 0,
  second: 0,
  millisecond: 0
}) */

const initialTime = moment().subtract(1, 'hours')

const CalendarView = ({ initialState = null, calendarType = 'schedule' }) => {
  const ref = useRef(null)
  const [eventType, setEventType] = useState('appointment')
  const [position, setPosition] = useState(null)
  let calendarDate = moment().format('YYYY-MM-DD')
  let calendarView = 'week'
  const reduxDispatch = useDispatch()
  const navigate = useNavigate()
  const organizationId = useSelector(getCurrentOrganizationId)
  const organization = useSelector(getOrganizationById(organizationId))
  const practitioner = usePractitioner()
  const language = useSelector(getLanguage)
  const location = useLocation()
  const params = new URL(location.href).searchParams
  const propsDate = params.get('date') // we will take this date like reference for week or month view
  const propsView = params.get('view') // agenda view it's not manage, only : day, week, month
  const [state, setState] = useState(initialState)
  const [save] = useSave()
  const [lazySearch] = useLazySearch()

  const [role] = useSelector(state =>
    getPractitionerRoleBypractitionerID(state, practitioner.id)
  )
  const locationFhir = useSelector(s =>
    getLocationByOrganizationId(s, organizationId)
  )
  const locationId = locationFhir?.id
  const practitionerType = getPractitionerType(role)
  const organizationType = organization?.identifier?.find(
    identifier =>
      identifier.system === MEDEO_ORGANIZATION_TYPE_IDENTIFIER_SYSTEM
  )?.value
  // here we have 2 conditions to prevent the crash of page. If the date or the view
  // doesn't have the good format to give information at Calendar component we take by default
  // the date of the day and the week view.
  if (propsView === 'day' || propsView === 'week') {calendarView = propsView}
  if (propsDate === moment(propsDate).format('YYYY-MM-DD'))
    {calendarDate = moment(propsDate).format('YYYY-MM-DD')}

  useEffect(() => {
    if (practitioner != null) {
      const { start, end } = getPeriodFromView(calendarDate, calendarView)
      reduxDispatch(
        search('Schedule', {
          actor: practitioner.id,
          _count: 200,
          date: { $ge: start, $le: end }
        })
      )
    }
    if (locationId != null) {
      const { start, end } = getPeriodFromView(calendarDate, calendarView)
      reduxDispatch(
        search('Schedule', {
          actor: locationId,
          _count: 200,
          date: { $ge: start, $le: end }
        })
      )
    }
  }, [practitioner, reduxDispatch, calendarDate, calendarView, locationId])

  const schedules = useSelector(
    getSchedulesByActorId(
      calendarType === 'booking' ? locationId : practitioner.id
    )
  )
  //  we only show the schedules that are active
  //  however we need the inactive schedules as there might be attached slots to it
  const activeSchedules = schedules.filter(s => s.active === true)

  // here we get all schedules to build the planning,
  // we will get only schedule that we need, it depends of the view (day week or month)
  const schedulesFiltered = getPlanningByFirstAndLastDay(
    calendarDate,
    calendarView,
    activeSchedules
  )
  //here we fetch the appointments on the schedule every minute
  usePollAppointment(propsDate)

  // transform schedules from FHIR into big calendar readable events
  const scheduleEvents = transformScheduleResourceInEvent(schedulesFiltered)

  const handleSelect = async ({ start, end }) => {
    // first condition means that we have the popup open
    // thus we dont trigger if the user is clicking away the popup
    if (state != null) {return}
    // prevent user to create schedule while showing the appointment
    if (eventType === 'appointment') {return}

    // make sure the user is not selecting an event that overlap with another
    // when 2 schedules overlap it leads to duplicated slots in the booking view
    const isColliding = scheduleEvents.some(e => findCollision(start, end, e))
    if (
      !isColliding &&
      moment(end).isAfter(moment()) &&
      window.confirm('Souhaitez-vous créer un créneau de téléconsultation ?')
    ) {
      // fullUrl is used as a conditional reference inside the bundle
      // we create the schedule and want the slots to refer to it
      const fullUrl = `urn:uuid:${uuid()}`
      const schedule = computeScheduleForPractitioner(
        start,
        end,
        practitioner,
        role
      )
      const slots = computeSlots(start, end, fullUrl)

      // Here we load the appointments which were accepted by the doctor
      // in order to not create a free slot on a time already taken by an appointment,
      const appointmentsData = await lazySearch('Appointment', {
        practitioner: practitioner.id,
        status: 'booked',
        date: {
          $ge: moment(start).format(FHIR_DATE_FORMAT),
          $le: moment(end).format(FHIR_DATE_FORMAT)
        }
      })

      const takenAppointments = appointmentsData?.Appointment

      const freeSlots = keepFreeSlots(slots, takenAppointments)

      const scheduleEntry = {
        fullUrl,
        resource: schedule,
        request: {
          method: 'POST',
          url: 'Schedule'
        }
      }
      const toEntry = slot => ({
        resource: slot,
        request: {
          method: 'POST',
          url: 'Slot'
        }
      })
      const bundle = {
        resourceType: 'Bundle',
        type: 'transaction',
        entry: [scheduleEntry, ...freeSlots.map(toEntry)]
      }
      await save(bundle)
    }
  }

  // here we get appointment resource from fhir
  const appointmentsFhirFromPractitioner = useSelector(
    getAppointmentsBypractitionerID(practitioner.id)
  )

  const appointmentsFhirFromLocation = useSelector(
    getAppointmentsByLocation(locationId)
  )

  // Once we get appointment from practitioner and location we concat them in on array
  let appointmentsFhir = []
  appointmentsFhir = appointmentsFhirFromPractitioner
    .concat(appointmentsFhirFromLocation) // we concat to create only one array of appointment
    .filter((appointment, index, self) => self.indexOf(appointment) === index) // we filter the array to keep only unique element

  // we transform our appointment resource from fhir to feet at event for Big Calendar,
  // In this event format we will add start, end, the patient id and the title.
  // We can add every custom variables that we need. star and end are obligatory
  const appointmentEvents = transformAppointmentResourceInEvent(
    appointmentsFhir,
    []
  )

  const handleNavigate = date => {
    // date is given by react-big-calendar like view and action
    // we convert the date to get same format for url like 2020-05-20
    // we keep the view that we have on url and change only the date
    const urlDate = moment(date).format('YYYY-MM-DD')
    navigate(`?date=${urlDate}&view=${calendarView}`)
  }

  const handleView = view => {
    // view is given by react-big-calendar like date and action
    // here we change the view of calendar and we keep the actual date in url
    // view could be : day, week, month (agenda it doesn't use for this moment)
    navigate(`?date=${calendarDate}&view=${view}`)
  }

  const handleSelectEvent = (event, e) => {
    if (event.isAppointment) {
      const element = e.currentTarget

      //here we get the position of the appointment in calendar view
      //we will use in EventDetails.jsx to put a pop up for have more details
      // the popup is moved 8px from the end of the bounding box.
      // TODO: show the popup on the right if there is not enough space
      setPosition([
        element.getBoundingClientRect().left + element.offsetWidth + 8,
        element.getBoundingClientRect().top
      ])
      setState(event)
    }
    if (eventType !== 'appointment') {
      // we check if some appointments were to take place within the time
      // of this schedule
      const precision = hasEventsWithin(event, appointmentEvents)
        ? '\n\nLes rendez-vous pris sur ce créneau ne seront pas annulés.'
        : ''

      const confirmText = `Souhaitez-vous supprimer ce créneau de téléconsultation ? ${precision}`

      if (window.confirm(confirmText)) {
        // to remove the schedule
        // On successful deletion, this will dispatch an action SCHEDULE_REMOVED,
        // to which the shared reducers defined in shared ducks will react,
        // trigerring the update of the store
        reduxDispatch(removeSchedule(event.id))
      }
    }
  }

  // if the popup is open, close it if the user click away
  useClickAway(ref, () => {
    setState(null)
  })

  return (
    <CalendarViewContext.Provider value={[state, setState]}>
      <Main>
        <Flex>
          <EventStyle />

          <Calendar
            data-test="calendar-component"
            popup
            selectable
            localizer={localizer}
            culture={language}
            // amount of available slots between two displayed times
            timeslots={2}
            // step for a slot selection in minutes
            step={15}
            events={eventType === 'appointment' ? appointmentEvents : []}
            backgroundEvents={scheduleEvents}
            //startAccessor="start"
            //endAccessor="end"
            defaultView={calendarView}
            defaultDate={new Date(calendarDate)}
            views={['week']}
            //views={['week', 'day']} // if you add month view you have to update condition at l131 of this file
            onNavigate={handleNavigate}
            onView={handleView}
            scrollToTime={initialTime.toDate()}
            onSelectSlot={handleSelect}
            onSelectEvent={handleSelectEvent}
            components={{
              toolbar: props => (
                <CalendarToolbar
                  {...props}
                  eventType={eventType}
                  onChangeEventType={setEventType}
                  practitionerType={practitionerType}
                  organizationType={organizationType}
                />
              ),
              week: {
                header: CalendarHeader,
                event: Event
              }
            }}
            messages={
              language === 'fr'
                ? {
                    month: 'Mois',
                    day: 'Jour',
                    today: "Aujourd'hui",
                    next: '>',
                    previous: '<',
                    week: 'Semaine'
                  }
                : {
                    month: 'Month',
                    day: 'Day',
                    today: 'Today',
                    next: '>',
                    previous: '<',
                    week: 'Week'
                  }
            }
          />
          {/* this condition is here to prevent display of EventDetails when we are
        on month or day view. Otherwise we get an error for the scroll process */}
          {calendarView === 'week' && state && (
            <EventDetails
              forwaredRef={ref}
              position={position}
              calendarType={calendarType}
            />
          )}
        </Flex>
      </Main>
    </CalendarViewContext.Provider>
  )
}

export default CalendarView
