import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Trans } from '@lingui/macro'
import { useLocation, useNavigate } from '@reach/router'
import * as Sentry from '@sentry/react'
import { darken } from 'polished'
import { useCallback, useContext, useEffect } from 'react'
import styled from 'styled-components/macro'
import { Button } from '../../Components'
import { IPM_KIOSK_ORIGIN, KIOSK_ORIGIN } from '../../utils/codes'
import { getLoincCodeFromBluetooth, LOINC_SYSTEM_URL } from '../../utils/loinc'
import { VideoScreenContext } from '../containers/VideoSequenceManager'
import { FHIR_OBSERVATION } from '../utils'
import TeleconsultationStatusScreen from './TeleconsultationStatusScreen'

const Screen = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  max-height: calc(100vh - 92px);
  flex-direction: column;
`

const Top = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const ButtonText = styled.span`
  margin-left: ${(props) => props.theme.small};
`

const BackButton = styled(Button)`
  margin-top: 1rem;
  margin-left: 3rem;
`

const LocalVideo = styled.div`
  width: ${(props) => (props.patientSide ? '250px' : '150px')};
  height: ${(props) => (props.patientSide ? '150px' : '100px')};
  position: absolute;
  top: 1rem;
  right: 1rem;
  border-radius: 0.25rem;
  box-shadow: ${(p) => p.theme.boxShadow};
  overflow: hidden;
  background-color: ${(p) =>
    p.isConnected ? darken(0.75, p.theme.gray) : 'none'};
  display: flex;
  transform: scale(-1, 1);
`

const ScreenVideo = ({
  remoteMediaContainer,
  localMediaContainer,
  displayReturnButton = true
}) => {
  const [videoState] = useContext(VideoScreenContext)

  const location = useLocation()
  const query = new URLSearchParams(location.search)
  const origin = query.get('origin')

  // the code bellow receive observations (from the kligo socket)
  //... and send it through the twilio data track to the doctor's side.
  const sendData = useCallback(
    ({ observation }) => {
      const dataTrack = videoState.teleconsultationMaterial?.dataTrack
      if (dataTrack != null) {
        dataTrack.send(
          JSON.stringify({
            type: FHIR_OBSERVATION,
            payload: observation
          })
        )
      }
    },
    [videoState.teleconsultationMaterial.dataTrack]
  )
  const navigate = useNavigate()
  const backButtonHandleOnClick = () => {
    navigate('/auth', {
      state: {
        from: '/patient/' + videoState.patientID + '/history'
      }
    })
  }

  // connect to the kligo socket
  useEffect(() => {
    let ws
    ws = new WebSocket('ws://localhost:63336/v3/Observation')

    ws.onmessage = function data(event) {
      try {
        const observation = JSON.parse(event.data)
        // Send observations to the doctor side
        sendData({
          observation: {
            ...observation,
            // We update the code the same way we do for coming observations
            // on the Kligo socket so that it will get displayed the way observations
            // are within a clinical exam
            code: {
              ...observation.code,
              coding: observation.code.coding.map((coding) =>
                coding.system === LOINC_SYSTEM_URL
                  ? {
                      ...coding,
                      code: getLoincCodeFromBluetooth(coding.display)
                    }
                  : coding
              )
            }
          }
        })
      } catch (e) {
        console.warn(e.message)
        Sentry.captureException(e)
      }
    }
    return () => {
      ws.close()
    }
  }, [sendData])

  return (
    <Screen ref={remoteMediaContainer}>
      <Top>
        {videoState.patientSide &&
          !videoState.isConnected &&
          origin !== KIOSK_ORIGIN &&
          origin !== IPM_KIOSK_ORIGIN &&
          displayReturnButton && (
            <BackButton
              onClick={backButtonHandleOnClick}
              variant="outline"
              color="aqua"
              data-testid="backButton"
            >
              <FontAwesomeIcon icon={faChevronLeft} />
              <ButtonText>
                <Trans>Back to patient's file</Trans>
              </ButtonText>
            </BackButton>
          )}
        <LocalVideo
          ref={localMediaContainer}
          patientSide={videoState.patientSide}
          isConnected={videoState.isConnected}
        />
      </Top>
      {videoState.status !== 'in-progress' && (
        <TeleconsultationStatusScreen
          remoteMediaContainer={remoteMediaContainer}
          localMediaContainer={localMediaContainer}
        />
      )}
    </Screen>
  )
}

export default ScreenVideo
