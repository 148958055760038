import React from 'react'
import styled from 'styled-components/macro'
import {
  displayAge,
  displayDob,
  displayFullNameWithTitle,
} from '../../Shared/display/entity'

const Name = styled.div`
  margin-bottom: 0.5rem;
  font-weight: ${props => props.theme.normal};
  color: ${props => props.theme.ebony};
  font-size: ${props => props.theme.large};
  overflow-wrap: break-word;
`

const Age = styled.span`
  font-weight: ${props => props.theme.bold};
  color: ${props => props.theme.ebony};
`

const DateOfBirth = styled.span`
  font-size: ${props => props.theme.small};
  display: inline-block;
`

const PatientIntro = ({ patient, ...rest }) => {
  return (
    <div {...rest}>
      <Name className="fs-block">{displayFullNameWithTitle(patient)}</Name>
      <div>
        <Age>{displayAge(patient)}</Age>&nbsp;
        <DateOfBirth>{displayDob(patient)}</DateOfBirth>
      </div>
    </div>
  )
}

export default PatientIntro
