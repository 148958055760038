import { QueryFunction, useQuery } from 'react-query'
import { Bundle, PaymentNotice } from 'fhir-stu3'
import { getTokenFromAmplify } from '../../Auth/utils'
import { FHIR_BASE_URL } from '../../_common/config'
export type PaymentNoticeByAppointment = [
  'PaymentNotice',
  'details',
  { appointmentId: string }
]

const getPaymentNoticeByAppointment: QueryFunction<
  PaymentNotice,
  PaymentNoticeByAppointment
> = async ({ queryKey }) => {
  const [, , { appointmentId }] = queryKey
  const url = new URL('PaymentNotice', FHIR_BASE_URL)
  if (appointmentId == null) {throw new Error('appointmentId should be defined')}
  url.searchParams.append('request', appointmentId)
  const token = await getTokenFromAmplify()
  const res = await fetch(url.toString(), {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  })
  if (res.status >= 400) {
    const message = await res.text()
    throw new Error(message)
  }
  const bundle: Bundle = await res.json()

  return bundle.entry?.find(e => e.resource?.resourceType === 'PaymentNotice')
    ?.resource as PaymentNotice
}

const useQueryPaymentNoticeByAppointmentId = (appointmentId: string) => {
  return useQuery(
    ['PaymentNotice', 'details', { appointmentId }],
    getPaymentNoticeByAppointment
  )
}

export default useQueryPaymentNoticeByAppointmentId
