import React, { useContext } from 'react'
import styled from 'styled-components/macro'
import { Input } from '../../Components'
import { getDisplay, onChangeQuestionnaire, getItemByLinkId } from '../utils'
import { QuestionnaireResponseContext } from './Questionnaire'
import RadioGroup from '../../Shared/components/RadioGroup'

const StyledInput = styled(Input)`
  &:hover {
    background: ${props => props.readOnly & ''};
  }
`

const QuestionnaireRadio = ({ item, readOnly }) => {
  const [questionnaireResponse, dispatchQuestionnaireResponse] = useContext(
    QuestionnaireResponseContext
  )

  // get values from context
  const selectedValue = getItemByLinkId(questionnaireResponse, item.linkId)
    ?.answer?.[0]?.valueCoding

  // we check if we have a initalCoding
  // if it's the case initialValue take the default value and change the
  // value of the item in the questionnaire
  const initialValue = item.initialCoding?.code

  // populate context with default value if no previous answer
  if (selectedValue == null && initialValue != null) {
    onChangeQuestionnaire({
      questionnaireResponse: questionnaireResponse,
      dispatchQuestionnaireResponse: dispatchQuestionnaireResponse,
      name: item.linkId,
      value: item.initialCoding,
      type: 'radio'
    })
  }

  const orientation = item.option?.length > 3 ? 'column' : 'row'

  return (
    <RadioGroup
      readOnly={readOnly}
      required={item.required}
      label={getDisplay(item)}
      value={selectedValue}
      orientation={orientation}
      className="fs-block"
    >
      {item.option?.map(o => (
        <StyledInput
          type="radio"
          label={o.valueCoding?.display}
          name={item.linkId}
          value={o.valueCoding?.code}
          key={o.valueCoding?.code}
          data-test={`${item.linkId}-${o.valueCoding?.code}`}
          checked={
            o.valueCoding?.code?.toString() === selectedValue?.code?.toString()
          }
          readOnly={readOnly}
          onChange={e =>
            onChangeQuestionnaire({
              questionnaireResponse: questionnaireResponse,
              dispatchQuestionnaireResponse: dispatchQuestionnaireResponse,
              name: e.target.name,
              value: o.valueCoding,
              type: e.target.type
            })
          }
          required={item.required}
        />
      ))}
    </RadioGroup>
  )
}

export default QuestionnaireRadio
