import React, { useContext } from 'react'
import styled from 'styled-components/macro'
import { VideoScreenContext } from '../containers/VideoSequenceManager'
import Indications from './Indications'
import Controls from '../containers/TeleconsultationControls'
import Documents from '../containers/Documents'

const Div = styled.div`
  flex-direction: column;
  justify-content: center;
  flex-grow: 2;
  display: flex;
  align-items: center;
`

/**
 * This component should be used inside the VideoScreenContext in order
 * to work properly.
 * It shows indications to the user depending on the current state of the
 * remote consultation.
 * It will display the documents authored by the practitioner once the remote
 * consultation done.
 *
 *
 * In order to test this component easily you can paste this code in the MainApp
 *
const TestTeleconsultationScreen = ({ encounterID, location }) => {
  const params = new URL(location.href).searchParams
  const status = params.get('status')
  return (
    <VideoScreenContext.Provider
      value={[{ status, teleconsultationFhirResource: { id: encounterID } }]}
    >
      <div style={{ background: 'black' }}>
        <pre>{JSON.stringify(location, null, 2)}</pre>
        <TeleconsultationStatusScreen />
      </div>
    </VideoScreenContext.Provider>
  )
}
 * and refers to it with a route <TestTeleconsultationScreen path="/test/:encounterID" />
*
 * @returns {*}
 * @constructor
 */
const TeleconsultationStatusScreen = ({
  remoteMediaContainer,
  localMediaContainer,
}) => {
  // destructure the VideoScreenContext state
  // this screen will change depending on the status of the
  // remote consultation, unstarted, error, teleconsultationCompleted, and so on...
  const [
    { status, teleconsultationFhirResource: encounter, isConnected, patientID },
  ] = useContext(VideoScreenContext)

  return (
    <Div data-test="teleconsultationScreen">
      <Indications />
      {/*
        if the remote consultation is completed, we can display the documents
        generated by the practitioner to the patient.
      */}
      {status === 'teleconsultationCompleted' && (
        <>
          {/* Documents is for QuestionnaireResponses */}
          {/* uncomment the line above to enable QuestionnaireResponse like caresheets and orders */}
          <Documents encounter={encounter} patientID={patientID} />
        </>
      )}
      {/* remote + localMedia handling */}
      {/* if isConnected is false we show the button to join remote consultation except
      if the teleconsultation is completed in this case the patient just need to see his
      documents and price.*/}
      {!isConnected &&
        status !== 'teleconsultationCompleted' &&
        status !== 'cannotJoin' && (
          <Controls
            remoteMediaContainer={remoteMediaContainer}
            localMediaContainer={localMediaContainer}
          />
        )}
    </Div>
  )
}

export default TeleconsultationStatusScreen
