import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { t } from '@lingui/macro'
import { faEye } from '@fortawesome/free-solid-svg-icons'
import AttachmentControl from './AttachmentControl'
import { useToken } from '../utils'
import { useI18n } from '../../utils/I18nHookAdapter'

const AttachmentViewControl = ({ attachment }) => {
  const token = useToken()

  // here we download the file using fetch for the reason detailed in the
  // AttachmentDownloadControl source file
  // we open a new window that point to the local file
  const handleClick = e => {
    e.preventDefault()
    fetch(attachment.url, {
      method: 'get',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(res => res.blob())
      .then(blob => {
        // create a local version of the file from a blob
        // we append the content type like "image/jpeg" to the blob
        // to indicates to your browser how to display it
        // see https://developer.mozilla.org/en-US/docs/Web/API/Blob/slice for more details
        const file = window.URL.createObjectURL(
          blob.slice(0, blob.size, attachment.contentType)
        )
        // open a child window here that will use the browser viewer to display
        // the file
        window.open(file, '_blank', 'width=720,height=1080,menubar=no')
        window.URL.revokeObjectURL(file)
      })
  }
  // we are using @lingui/core@2.9 which does not provide the useLingui hook
  // we need to use a custom hook instead:
  const i18n = useI18n()
  const title = i18n._(t`View`)

  return (
    token != null && (
      <AttachmentControl
        as="a"
        href={attachment.url}
        onClick={handleClick}
        title={title}
        aria-label={title}
      >
        <FontAwesomeIcon icon={faEye} />
      </AttachmentControl>
    )
  )
}

export default AttachmentViewControl
