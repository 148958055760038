import { getSchedulesByActorId } from '../Schedule/selectors'
import moment from 'moment'

export const getSlotsBySchedulesId = schedulesId => state =>
  schedulesId
    .flatMap(id => state.Slot.bySchedule[id] ?? [])
    .map(id => state.Slot.byId[id]) ?? []

export const getSlotsByPerformerId = performerId => state => {
  const schedules = getSchedulesByActorId(performerId)(state)
  return getSlotsBySchedulesId(schedules.map(s => s.id))(state)
}

/**
 * returns an object with practitionerID as keys and slots as values
 *
 * @param {Practitioner[]} performers
 * @param {[Function]} filters: filters to be applied to the returned slot
 * @return {Object.<string, Slot[]>}
 */
export const getSlotsByPerformers = (
  performers = [],
  filters = [() => true]
) => state =>
  performers.reduce(
    (slots, performer) => ({
      ...slots,
      [performer.id]: getSlotsByPerformerId(performer.id)(state)
        .filter(e => filters.every(f => f(e)))
        .sort((a, b) => moment(a.start).diff(moment(b.start))),
    }),
    {}
  )
