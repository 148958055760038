import { FC } from 'react'
import PlanDefinition from './PlanDefinition'
import { Encounter, Patient, Practitioner, ProcedureRequest } from 'fhir-stu3'
import { RouteComponentProps } from '@reach/router'

interface TeleconsultationPlanDefinitionProps extends RouteComponentProps {
  patient: Patient
  encounter: Encounter
  practitioner: Practitioner
  procedureRequest?: ProcedureRequest
  preconsultationencounterID?: string
  saveState?: (state: any) => void
  defaultState?: any
}

const TeleconsultationPlanDefinition: FC<TeleconsultationPlanDefinitionProps> = (
  props
) => {
  return <PlanDefinition {...props} />
}

export default TeleconsultationPlanDefinition
