import React from 'react'
import CalendarView from '../containers/CalendarView'
import GridLayout from '../../Shared/components/GridLayout'

const Schedule = () => {
  return (
    <GridLayout.Main variant="full">
      <CalendarView calendarType={'schedule'} />
    </GridLayout.Main>
  )
}

export default Schedule
