import { Trans } from '@lingui/macro'
import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components/macro'
import { getCurrentOrganizationId } from '../../Auth/selectors'
import { Button, Spinner } from '../../Components'
import useLazySearch from '../../MedicationRequest/useLazySearch'
import OrganizationEmailWarning from '../../Organization/containers/OrganizationEmailWarning'
import RequirePermission from '../../Permissions/containers/RequirePermission'
import PractitionerThirdPartyPayerWarning from '../../Practitioner/containers/PractitionerThirdPartyPayerWarning'
import Grid from '../../Shared/components/GridLayout'
import Intercom from '../../Shared/intercom/Intercom'
import SearchPatientsForm from '../containers/SearchPatientsForm'
import AddPatientButton from './AddPatientButton'
import PatientsTable from './PatientsTable'

const Footer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`
const Header = styled.div`
  position: sticky;
  top: 0;
  padding-top: 2rem;
  padding-bottom: 2rem;
  background: #ebf2fa;
  display: flex;
  justify-content: space-between;
  margin-bottom: ${props => props.theme.spacing.large};
`

const Highlight = styled.span`
  color: ${p => p.theme.ocean};
`

const FallbackMessage = styled.div`
  display: flex;
  min-height: 10rem;
  justify-content: center;
  align-items: center;
`

/**
 * This hook will fetch the patient list; if a name is specified in value
 * it will narrow the search to patients where the name contains the value
 * @param value
 * @return {{data: *, fetchMore, loading: boolean}}
 */
const useSearchPatientWithName = value => {
  const organizationId = useSelector(getCurrentOrganizationId)
  const [search, { data, loading, fetchMore }] = useLazySearch()

  useEffect(() => {
    const params = {
      _sort: '-_id',
      _revinclude: 'Encounter:patient',
      _filter: `( organization eq ${organizationId} or general-practitioner eq Organization/${organizationId} )`,
      _count: 15,
    }
    if (value && value.length > 2) {
      params['name:contains'] = value
      params._count = 100
    }
    search('Patient', 
      params
    )
  }, [organizationId, value, search])

  return { data, loading, fetchMore }
}

/*
 * There are two types of patients we want to load:
 * - The patient from the current managing organization.
 *   i.e. created by the current user's organization
 * - The patient addressed to the current organization
 *   i.e. created by another organization but the current organization is
 *   identified as a general-practitioner of these patients
 *
 * FHIR provides a way to make a corresponding request for this using the
 * _filter searchParam. It allows us to ask the server combined Patients
 * from different sources.
 *
 * The next steps would be to load the last encounter from these patients
 * in the same request. That's a though challenge and it seems that it is not
 * yet possible using the FHIR server
 *
 * The last attempts used the _has parameter in requests like
 *  Patient?_has:Encounter:patient:status=finished&_revinclude=Encounter:patient
 * The request above returns what we want but also include every Encounter from
 * the requested patients. It seems there is no way to limit the number of element
 * that match the _revinclude parameters.
 */
const Patients = () => {
  const [value, setValue] = useState('')
  const { data, loading, fetchMore } = useSearchPatientWithName(value)

  const handleChange = useCallback(value => {
    setValue(value)
  }, [])

  const patients = data?.Patient ?? []

  return (
    <>
      {process.env.NODE_ENV === 'production' && <Intercom />}
      <Grid.Main variant="full">
        <div style={{ margin: 'auto', width: '100%', position: 'relative' }}>
          <Header id={'header'}>
            {/* filters the list of patients*/}
            {/* it will call the search  depending on what the user typed in */}
            {/* A debouncer triggers onChange when the user stop typing */}
            <SearchPatientsForm onChange={handleChange} disabled={loading} />
            <RequirePermission action="add" resource="patient">
              <AddPatientButton />
            </RequirePermission>
          </Header>
          <OrganizationEmailWarning />
          <PractitionerThirdPartyPayerWarning />
          {/* shows a spinner when having an empty list so users know smth happens*/}
          {loading && patients.length === 0 && <Spinner />}
          {/* as soon as we have results we show the table */}
          {patients.length > 0 && <PatientsTable patients={patients} />}
          {/*when the user type an incorrect search we show this fallback*/}
          {!loading && patients.length === 0 && (
            <FallbackMessage>
              <div>
                <Trans>No patients match your current filter</Trans>{' '}
                <Highlight>{value}</Highlight>
              </div>
            </FallbackMessage>
          )}
          {/* if there are some paginations going on we show the fetch more button */}
          {/* fetchMore is either a function|undefined depending on the last bundle */}
          {/* if there is nothing more to load we simply hide the footer*/}
          {fetchMore && patients.length > 0 && (
            <Footer>
              <Button
                onClick={() => {
                  fetchMore()
                }}
                disabled={loading}
              >
                <Trans>Fetch More</Trans>
              </Button>
            </Footer>
          )}
        </div>
      </Grid.Main>
    </>
  )
}

export default Patients
