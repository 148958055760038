/*eslint-disable*/
import React from 'react';

export const getGeneralPractitioner = patient => {
  const firstGP = (patient != null && patient.generalPractitioner != null
    ? patient.generalPractitioner
    : [])[0];

  const noGP = { name: null, telecom: [] };

  if (!firstGP || !firstGP.display) {
    return noGP;
  }

  const isGpObject = firstGP.display.startsWith('{');

  return isGpObject ? JSON.parse(firstGP.display) : firstGP.display;
};
