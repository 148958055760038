import { getAllEntities } from '../Shared/selectors'

export const getAllClinicalImpressions = state =>
  getAllEntities(state, 'clinicalImpressions')

export const getClinicalImpressionsFromencounterID = (
  state,
  encounterID,
  filters = [() => true]
) => {
  if (encounterID == null) {return null}
  return getAllClinicalImpressions(state)
    .filter(o => o.context.reference === `Encounter/${encounterID}`)
    .filter(o => filters.every(f => f(o)))
}
