import React from 'react'
import { Trans } from '@lingui/macro'
import moment from 'moment'
import { capitalize } from 'lodash'
import {
  MEDEO_AM_IDENTIFIER_SYSTEM,
  MEDEO_FINESS_IDENTIFIER_SYSTEM,
  MEDEO_NIR_IDENTIFIER_SYSTEM,
  MEDEO_RPPS_IDENTIFIER_SYSTEM
} from '../../utils/codes'

const FHIR_DATE_FORMAT = 'YYYY-MM-DD'
const FHIR_GENDER_FEMALE = 'female'
const FHIR_GENDER_MALE = 'male'
const FHIR_GENDER_OTHER = 'other'

export const usualName = entity => {
  if (entity == null) {return null}
  if (entity && entity.name && entity.name.length === 1) {return entity.name[0]}
  return (entity.name || []).find(name => name.use === 'usual') || null
}

// TODO : Add test for check a nameless situation OFF-214
// TODO(shall): Add support for i18n for display names

export const displayFamilyName = entity => {
  const usual = usualName(entity)

  if (
    usual == null ||
    usual.family == null ||
    typeof usual.family != 'string'
  ) {
    return null
  }

  return usual.family.toUpperCase()
}

export const displayGivenName = entity => {
  const usual = usualName(entity)

  if (usual == null || usual.given == null) {
    return null
  }

  return usual.given[0]
}
export const displayEmail = entity => {
  const email = entity?.telecom?.find(t => t.system === 'email')?.value
  return email
}

export const displayAge = patient => {
  const m = moment(patient.birthDate, FHIR_DATE_FORMAT)
  const now = moment()

  const years = now.diff(m, 'years')

  if (years === 0) {
    const months = now.diff(m, 'months')
    return (
      <Trans>
        {years} years and {months} months
      </Trans>
    )
  } else if (years > 0) {
    return <Trans>{years} years</Trans>
  } else {
    return ' '
  }
}

export const displayGender = patient => {
  if (patient.gender === FHIR_GENDER_FEMALE) {
    return <Trans>Female</Trans>
  } else if (patient.gender === FHIR_GENDER_MALE) {
    return <Trans>Male</Trans>
  } else if (patient.gender === FHIR_GENDER_OTHER) {
    return <Trans>Other</Trans>
  }
}

export const displayLastVisitDate = encounter => {
  if (encounter && encounter.period) {
    return <span>{moment(encounter.period.start).format('DD MMMM YYYY')}</span>
  } else {
    return <Trans>No Previous Visit</Trans>
  }
}

export const displayDateFromProcedureRequest = procedureRequest => {
  if (procedureRequest?.authoredOn) {
    return (
      <span>{moment(procedureRequest.authoredOn).format('DD MMMM YYYY')}</span>
    )
  }
  return null
}

export const displayDateAndHourFromProcedureRequest = procedureRequest => {
  if (procedureRequest?.authoredOn) {
    return moment(procedureRequest.authoredOn).format('LLL')
  }
  return null
}

export const displayTitle = person => {
  const prefix = person?.name?.[0]?.prefix
  if (prefix != null) {
    return capitalize(prefix)
  }
  if (person.gender === FHIR_GENDER_MALE) {
    return <Trans>Mr.</Trans>
  } else if (person.gender === FHIR_GENDER_FEMALE) {
    return <Trans>Ms.</Trans>
  } else {
    return ''
  }
}

export const displayFullNameWithTitle = patient => {
  const usual = usualName(patient)

  if (usual == null || usual.family == null) {
    return null
  }

  return (
    <React.Fragment>
      {displayTitle(patient)} {displayFamilyName(patient).toUpperCase()}{' '}
      {displayGivenName(patient)}
    </React.Fragment>
  )
}

export const displayFullName = patient => {
  const usual = usualName(patient)

  if (usual == null || usual.family == null) {
    return null
  }

  return (
    <React.Fragment>
      {displayFamilyName(patient).toUpperCase()} {displayGivenName(patient)}
    </React.Fragment>
  )
}

export const displayDob = patient => {
  return moment(patient.birthDate, FHIR_DATE_FORMAT).format('DD/MM/YYYY')
}

export const displayAddressSingleLine = patient => {
  const address =
    patient.address && patient.address.length > 0 ? patient.address[0] : null

  if (!address) {
    return <Trans>No address found</Trans>
  }

  return `${address.line}, ${address.postalCode || ''} ${
    address.city ? address.city.toUpperCase() : ''
  } ${address.country || ''}`
}

export const displaySimplifiedAddress = patient => {
  const address =
    patient.address && patient.address.length > 0 ? patient.address[0] : null

  if (!address) {
    return <Trans>No address found</Trans>
  }

  return (
    <>
      <div>{address.postalCode || ''}</div>
      <div>{address.city ? address.city.toUpperCase() : ''}</div>
    </>
  )
}

export const displayPhoneNumber = entity => {
  const phone = (entity.telecom || []).find(t => t.system === 'phone')

  if (!phone || !phone.value) {
    return <Trans>No phone number found</Trans>
  }
  if (phone.value.length === 10) {
    return `${phone.value.substring(0, 2)} ${phone.value.substring(
      2,
      4
    )} ${phone.value.substring(4, 6)} ${phone.value.substring(
      6,
      8
    )} ${phone.value.substring(8, 10)}`
  }

  return phone.value
}

export const displayManagingOrganization = patient => {
  if (patient.managingOrganization == null) {return null}
  return patient.managingOrganization.display
}

export const displayNir = patient => {
  const emptyNIR = <Trans>No NIR found</Trans>
  if (patient.identifier == null || patient.identifier.length === 0)
    {return emptyNIR}
  let nir = patient.identifier?.find?.(
    i => i.system === MEDEO_NIR_IDENTIFIER_SYSTEM
  )?.value
  // Some patients don't have a NIR
  if (nir === undefined || nir === null) {
    return emptyNIR
  }

  // if the NIR is entered with 13 digits we can compute the key
  // so that we can display it with the 15 digits
  // if the nir contains less or more than 13 digits we don't tampered with
  // previous version of patients in Medeo might have 15 digits set by default
  if (nir.length === 13) {
    const gender = nir.substring(0, 1)
    const year = nir.substring(1, 3)
    const month = nir.substring(3, 5)
    const dept = nir
      .substring(5, 7)
      .replace('2A', '19')
      .replace('2B', '18')
    const city = nir.substring(7, 10)
    const order = nir.substring(10, 13)
    let number = parseInt(`${gender}${year}${month}${dept}${city}${order}`, 10)
    let key = 97 - (number % 97)
    nir = `${gender} ${year} ${month} ${dept} ${city} ${order}  ${key}`
  }
  return nir ?? emptyNIR
}

export const displayPhoneNumberWithSpaces = number => {
  let phoneNumber = number
  // we format the number with spaces only if the number has 10 digits
  if (number.length === 10) {
    // it will split the number and make an array 0899889988 => ["08", "99", "88", "99", "88"]
    const array = number.match(/.{1,2}/g)
    // join the array adding an space between every element
    phoneNumber = array.join(' ') // "08 99 88 99 88"
  }
  return phoneNumber
}

export const displayRpps = practitioner => {
  return practitioner.identifier?.find(
    i => i.system === MEDEO_RPPS_IDENTIFIER_SYSTEM
  )?.value
}

export const displayAmOrFiness = practitioner => {
  return (
    practitioner.identifier?.find(
      i =>
        i.system === MEDEO_AM_IDENTIFIER_SYSTEM ||
        i.system === MEDEO_FINESS_IDENTIFIER_SYSTEM
    )?.value ?? 'Not given'
  )
}
