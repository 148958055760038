import React from 'react'
import { Input } from '../../Components'

const ValueTimeView = ({ valueTime, labelText }) => (
  <Input
    defaultValue={valueTime}
    label={labelText}
    readOnly={true}
    type="time"
  />
)
export default ValueTimeView
