import React, { useCallback } from 'react'
import { getQuestionnaireIdentifierByAction } from '../../PlanDefinition/utils'
import { v4 as uuid } from 'uuid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getIconByName } from '../utils'
import QuestionnaireSquareItem from './QuestionnaireSquareItem'
import styled from 'styled-components/macro'
import { Button } from '../../Components'
import usePlanDefinition from '../../PlanDefinition/usePlanDefinition'

const Icon = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 0.5rem;
`

const Text = styled.div`
  padding-left: 0.5rem;
`

const SquareDiv = styled.div`
  display: grid;
  grid-gap: 0.5rem;
  grid-template-columns: repeat(2, 1fr);
`

const CustomButton = styled(Button)`
  padding: 0 0.5rem 0 0;
  margin: 0 0 1rem 0;
  border: none;
  text-transform: none;
`

const Bloc = styled.div`
  margin-bottom: 1rem;
`

const QuestionnaireCerfa = ({ action, questionnaire, name }) => {
  const { planDefinition, setPlanDefinition } = usePlanDefinition()
  const [questionnaireIdentifier] = getQuestionnaireIdentifierByAction(action)

  // responses are used in the default case: arret-maladie and feuille-de-soin
  const responses = planDefinition.sequenceResponse.filter(
    sr => sr?.questionnaireId === questionnaire?.id
  )

  const codeIcon = action?.code?.find(
    c => c.coding?.[0]?.system === 'medeo-icon'
  )?.coding?.[0]?.code
  const onEditQuestionnaireResponse = internalResponseId => {
    setPlanDefinition(p => ({
      ...p,
      isIsolatedElementView: true,
      questionnaireIdentifier: questionnaireIdentifier,
      internalResponseId: internalResponseId
    }))
  }

  const onDeleteQuestionnaireResponse = internalResponseId => {
    // DELETE
    // here we remove the form with a filter based on the internalResponseId
    setPlanDefinition(p => ({
      ...p,
      sequenceResponse: p.sequenceResponse.filter(
        sr => sr?.internalResponseId !== internalResponseId
      )
    }))
  }

  const handleAdd = useCallback(() => {
    setPlanDefinition(p => ({
      ...p,
      isIsolatedElementView: true,
      questionnaireIdentifier,
      internalResponseId: uuid(),
      // removing readOnly will break the display in QuestionnaireSequencer
      // TODO: sort this out
      readOnly: false
    }))
  }, [setPlanDefinition, questionnaireIdentifier])

  return (
    <Bloc>
      <Icon>
        <FontAwesomeIcon icon={getIconByName(codeIcon)} />
        <Text>{action.title}</Text>
      </Icon>
      <CustomButton
        color="ocean"
        variant="outline"
        noborder={true}
        onClick={handleAdd}
        data-test={'add-' + name}
      >
        {name === 'Document' ? (
          <p>+ Ajouter un {name}</p>
        ) : (
          <p>+ Ajouter une {name}</p>
        )}
      </CustomButton>
      <SquareDiv>
        {responses.map((sr, i) => (
          <QuestionnaireSquareItem
            key={i}
            internalResponseId={sr.internalResponseId}
            codeIcon={codeIcon}
            name={name}
            onEditQuestionnaireResponse={onEditQuestionnaireResponse}
            onDeleteQuestionnaireResponse={onDeleteQuestionnaireResponse}
          />
        ))}
      </SquareDiv>
    </Bloc>
  )
}

export default QuestionnaireCerfa
