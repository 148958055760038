export const start = action => (count = 10, args) => ({
  type: action,
  payload: {
    count,
    ...args,
  },
})

export const loadMore = actionName => link => ({
  type: actionName,
  payload: { link },
})

export const loadedMore = actionName => (items, link, reset = false) => {
  return {
    type: actionName,
    payload: { items, link, reset },
  }
}

export const clear = actionName => () => ({
  type: actionName,
})

export const stale = actionName => () => ({
  type: actionName,
})
