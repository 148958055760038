export const CREATING_SCHEDULE = 'CREATING_SCHEDULE'
export const EXTRACTING_AND_SAVING_RESPONSE_FOR_SCHEDULE =
  'EXTRACTING_AND_SAVING_RESPONSE_FOR_SCHEDULE'
export const REMOVING_SCHEDULE = 'REMOVING_SCHEDULE'

export const removeSchedule = scheduleId => ({
  type: REMOVING_SCHEDULE,
  payload: scheduleId,
})

export const extractQuestionnaireResponseAndCreateSchedule = (
  practitionerID,
  questionnaireResponse
) => ({
  type: EXTRACTING_AND_SAVING_RESPONSE_FOR_SCHEDULE,
  payload: { practitionerID, questionnaireResponse },
})
