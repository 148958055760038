import React, { useRef } from 'react'
import { v4 as uuid } from 'uuid'
import styled from 'styled-components/macro'
import { Button, Card } from '../Components'
import QuestionnaireMedicationStatementForm from './QuestionnaireMedicationStatementForm'
import { Trans } from '@lingui/macro'
import { getHeaderFromEncounter } from '../Encounter/utils'
import { useSelector } from 'react-redux'
import { getAllQuestionnairesByIdentifierAndSystem } from '../Questionnaire/selector'
import {
  MEDEO_LINK_ID_FOR_SELECT_PATIENT_RESPONSE,
  MEDEO_SELECT_PATIENT_QUESTIONNAIRE_IDENTIFIER
} from '../utils/codes'
import { QUESTIONNAIRE_IDENTIFIER_SYSTEM } from '../PlanDefinition/utils'
import { getItemByLinkId, getItemLinkIdFromCode } from '../Questionnaire/utils'
import usePlanDefinition from '../PlanDefinition/usePlanDefinition'

const CustomFooter = styled(Card.Footer)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
const QuestionnaireMedicationStatement = ({ patient, readOnly, encounter }) => {
  const { planDefinition, goBack, goNext } = usePlanDefinition()
  const [selectPatientQuestionnaire] = useSelector(s =>
    getAllQuestionnairesByIdentifierAndSystem(
      s,
      MEDEO_SELECT_PATIENT_QUESTIONNAIRE_IDENTIFIER,
      QUESTIONNAIRE_IDENTIFIER_SYSTEM
    )
  )

  // when we are doing the TLX with no patient provided at the start, we have a select patient juste before this component
  // if the patient is already in the DB, we need to retrieve his MedicationStatement
  // we will do it thanks to this var
  const patientFromSelect = { id: '' }

  // in the case of the TLX from the waiting room, we need to get the patienId
  if (patient == null) {
    const selectPatientResponse = planDefinition.sequenceResponse.find(
      response => response.questionnaireId === selectPatientQuestionnaire?.id
    )
    if (selectPatientResponse != null) {
      const patientResponse = getItemByLinkId(
        selectPatientResponse.answer,
        getItemLinkIdFromCode(
          selectPatientQuestionnaire,
          MEDEO_LINK_ID_FOR_SELECT_PATIENT_RESPONSE
        )
      )?.answer?.[0]?.valueInteger
      patientFromSelect.id = patientResponse
    }
  }

  // handleSubmit is called from the button in the footer
  // it updates the response and the index
  const handleSubmit = () => {
    goNext()
  }

  // ref here is used to create a unique id that will be the same until the
  // component is unmounted.
  // this id allows the button in the footer to submit the form even though
  // it is not a direct child of it. That's a neat trick
  const ref = useRef(uuid())

  // Medication Statement are available on the patient info, therefore
  // this component is not shown in the recap, i.e. readOnly === true
  return (
    readOnly === false && (
      <>
        <Card.Header>
          <h1>{getHeaderFromEncounter(encounter)}</h1>
        </Card.Header>
        <h3>
          <Trans>Medical Statements</Trans>
        </h3>
        <QuestionnaireMedicationStatementForm
          patient={patient ?? patientFromSelect}
          id={ref.current}
          onSubmit={handleSubmit}
        />
        <CustomFooter>
          <Button role="button" onClick={goBack} variant="secondary">
            <Trans>Previous</Trans>
          </Button>
          <Button form={ref.current} data-test="next-btn">
            <Trans>Next</Trans>
          </Button>
        </CustomFooter>
      </>
    )
  )
}

export default QuestionnaireMedicationStatement
