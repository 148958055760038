import React, { useContext } from 'react'
import MultipleSearchInput from '../../Shared/components/MultipleSearchInput'
import {
  onChangeQuestionnaire,
  getItemByLinkId,
  removeItemInResponse,
} from '../utils'
import { QuestionnaireResponseContext } from './Questionnaire'

const QuestionnaireTag = ({ item }) => {
  const [questionnaireResponse, dispatchQuestionnaireResponse] = useContext(
    QuestionnaireResponseContext
  )
  const filledValues = getItemByLinkId(
    questionnaireResponse,
    item.linkId
  )?.answer?.map(answer => answer.valueString)

  const handleChange = e => {
    const { selectedOptions } = e.target
    if (selectedOptions.length > 0) {
      onChangeQuestionnaire({
        questionnaireResponse: questionnaireResponse,
        dispatchQuestionnaireResponse: dispatchQuestionnaireResponse,
        name: item.linkId,
        value: selectedOptions,
        type: 'tag',
      })
    } else {
      removeItemInResponse(
        questionnaireResponse,
        item.linkId,
        dispatchQuestionnaireResponse
      )
    }
  }

  return (
    <MultipleSearchInput
      name="multiple_search_input"
      label={item.text}
      value={filledValues}
      onChange={handleChange}
      selectOnEnterPress={true}
    />
  )
}

export default QuestionnaireTag
