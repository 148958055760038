import { getCurrentOrganizationId } from '../Auth/selectors';
import { call, select, takeEvery } from 'redux-saga/effects';
import { searchFhirResourceWorker } from '../Shared/sagas';
import { PRACTITIONER_SELECTED } from '../Practitioner/actions';

// watch out in @medeo/cli not to update those fields
export const MEDEO_CATEGORY_SYSTEM = 'http://medeo.io/fhir/Category';
export const MEDEO_OUTSIDE_COLLABORATOR_CARETEAM_CODE =
  'outside-collaborator-careteam';

function* fetchCareTeamAndOutsideCollaborator() {
  const orgId = yield select(getCurrentOrganizationId);
  // cannot fetch careTeams using managingOrganization,
  // here we fetch the careTeams where the organization is a participant
  // and the type is corresponding to outside collaborator instead
  const { payload } = yield call(searchFhirResourceWorker, 'CareTeam', {
    participant: `Organization/${orgId}`,
    category: `${MEDEO_CATEGORY_SYSTEM}|${MEDEO_OUTSIDE_COLLABORATOR_CARETEAM_CODE}`,
    _include: 'CareTeam:participant'
  });

  // for every other organizations in the careTeam we fetch the practitioners and
  // their roles
  // the condition above is to prevent an error when no careTeam exists
  if (payload.Organization) {
    yield call(searchFhirResourceWorker, 'PractitionerRole', {
      active: 'true',
      _include: 'PractitionerRole:practitioner',
      _count: 200,
      organization: {
        $or: payload.Organization.filter(o => o.id !== orgId).map(o => o.id)
      }
    });
  }
}

function* rootSaga() {
  // we assume practitioner won't change often so it's okay to fetch every time it's selected
  yield takeEvery(PRACTITIONER_SELECTED, fetchCareTeamAndOutsideCollaborator);
}

export default rootSaga;
