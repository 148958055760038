import {
  ESANTE_OCCUPATION_SYSTEM,
  THIRD_PARTY_PAYER_IDENTIFIER_SYSTEM,
} from '../utils/codes'
import { t } from '@lingui/macro'
import { setupI18n } from '@lingui/core'
import englishMessages from '../locales/en/messages.js'
import frenchMessages from '../locales/fr/messages.js'

// setup the i18n object with active language and catalogs
let i18n = setupI18n({
  language: 'fr',
  catalogs: {
    en: englishMessages,
    fr: frenchMessages,
  },
})

export const filterGeneralPractitionerRoles = practitionerRole =>
  practitionerRole.practitioner?.reference != null &&
  // the practitioner is a doctor
  practitionerRole.specialty?.some(
    s =>
      s.coding?.[0]?.system === ESANTE_OCCUPATION_SYSTEM &&
      // e-sante code for general practitioner
      s.coding?.[0]?.code === '10'
  ) &&
  // either the doctor has no specialty (length = 1), either they have a generalist specialty
  (practitionerRole.specialty?.length === 1 ||
    practitionerRole.specialty?.some(
      // either a e-sante specialty, either a snomed specialty
      s =>
        (s.coding?.[0]?.system === ESANTE_OCCUPATION_SYSTEM &&
          // GENERAL_MEDECINE_SPECIALIST_SPECIALTY = 'SM53'
          (s.coding?.[0]?.code === 'SM53' ||
            // GENERAL_MEDECINE_SPECIALTY = 'SM54'
            s.coding?.[0]?.code === 'SM54' ||
            // GENERAL_MEDICINE_QUALIFIED_SPECIALTY = 'SM26'
            s.coding?.[0]?.code === 'SM26')) ||
        // snomed specialty
        (s.coding?.[0]?.system === 'http://snomed.info/sct' &&
          s.coding?.[0]?.code === '62247001')
    ))

/**
 * Get the display of whether the practitioner accepts third party payer
 * or not, raccording to its identifier value
 *
 * @param {Practitioner} practitioner
 * @returns {String} - the string to be displayed
 */
export const getAcceptThirdPartyPayerDisplay = practitioner => {
  const acceptsThirdPartyPayer = getThirdPartyPayerDetails(practitioner)

  switch (acceptsThirdPartyPayer) {
    case 'full':
      return i18n._(t`Accepts full third party payer`)
    case 'am-part':
      return i18n._(t`Accepts am-part third party payer`)
    case 'none':
      return i18n._(t`Does not accept third party payer`)
    default:
      return 'no information'
  }
}

/**
 * Check whether a practitioner accepts third party payer or not
 *
 * @param {Practitioner} practitioner
 * @returns boolean
 */
export const getThirdPartyPayerDetails = practitioner => {
  return practitioner.identifier?.find(
    identifier => identifier.system === THIRD_PARTY_PAYER_IDENTIFIER_SYSTEM
  )?.value
}
