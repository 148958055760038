export const SIGN_IN_REQUESTED = 'SIGN_IN_REQUESTED'
export const SIGN_OUT_REQUESTED = 'SIGN_OUT_REQUESTED'

export const OPEN_ID_TOKEN_RECEIVED = 'OPEN_ID_TOKEN_RECEIVED'

export const signOut = () => ({
  type: SIGN_OUT_REQUESTED,
})

export const succeedToReceiveAccessToken = (
  token,
  attributes,
  cognitoGroups
) => ({
  type: OPEN_ID_TOKEN_RECEIVED,
  payload: {
    token,
    attributes,
    cognitoGroups,
  },
})
