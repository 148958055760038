import React from 'react'
import styled from 'styled-components/macro'
import { Trans } from '@lingui/macro'
import {
  findComponentByLoincCode,
  LOINC_DIASTOLIC_BLOOD_PRESSURE_CODE,
  LOINC_SYSTOLIC_BLOOD_PRESSURE_CODE,
  LOINC_HEART_RATE_CODE,
} from '../../utils/loinc'
import ValueQuantityView from './ValueQuantityView'
import InterpretationView from './InterpretationView'

const Type = styled.div`
  width: 2rem;
  box-sizing: content-box;
  text-align: center;
  margin-top: ${props => props.theme.spacing.small};
  padding-right: ${props => props.theme.spacing.medium};
  font-size: ${props => props.theme.small};
`

const Top = styled.div`
  align-items: center;
  width: 100%;
  flex-direction: column;
  display: grid;
  grid-template-columns: 20% 80%;
  grid-template-rows: repeat(auto-fill, 1fr);
`
const InterpretationDiv = styled.div`
  align-self: center;
  grid-row: 1;
  grid-column: 1;
`

const ValueDiv = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: 2;
`

const BloodPressureValueQuantityView = ({ observation }) => {
  if (observation.component == null || observation.component.length < 1)
    {throw new Error('missing component')}
  const sys = findComponentByLoincCode(
    observation,
    LOINC_SYSTOLIC_BLOOD_PRESSURE_CODE
  )
  const dia = findComponentByLoincCode(
    observation,
    LOINC_DIASTOLIC_BLOOD_PRESSURE_CODE
  )
  const pulse = findComponentByLoincCode(observation, LOINC_HEART_RATE_CODE)

  if (sys == null || dia == null) {throw new Error('missing component')}
  if (pulse != null) {
    return (
      <div>
        <Type>
          <Trans>Measure</Trans>
        </Type>
        <Top>
          <InterpretationDiv>
            <InterpretationView observation={observation} />
          </InterpretationDiv>
          <ValueDiv>
            <ValueQuantityView
              valueQuantity={{
                value: `${sys.valueQuantity.value}/${dia.valueQuantity.value}`,
                unit: sys.valueQuantity.unit,
              }}
            />
          </ValueDiv>
          <ValueDiv>
            <ValueQuantityView
              valueQuantity={{
                value: pulse.valueQuantity.value,
                unit: pulse.valueQuantity.unit,
              }}
            />
          </ValueDiv>
        </Top>
      </div>
    )
  }

  return (
    <div>
      <Type>
        <Trans>Measure</Trans>
      </Type>
      <Top>
        <InterpretationView observation={observation} />
        <ValueQuantityView
          valueQuantity={{
            value: `${sys.valueQuantity.value}/${dia.valueQuantity.value}`,
            unit: sys.valueQuantity.unit,
          }}
        />
      </Top>
    </div>
  )
}

export default BloodPressureValueQuantityView
