import { shallowEqual, useSelector } from 'react-redux';
import {
  filterByTypeCode,
  getPractitionerRolesBypractitionerID
} from '../../PractitionerRole/selector';
import { useNavigate } from '@reach/router';
import { t, Trans } from '@lingui/macro';
import ProfilePicture from '../../Shared/components/ProfilePicture';
import { displayPractitionerImg } from '../../Shared/display/practitioner';
import {
  displayFamilyName,
  displayGivenName
} from '../../Shared/display/entity';
import { displayPractitionerActiveRole } from '../../Shared/display/practitionerRole';
import React from 'react';
import { Row } from './PractitionerTable';
import { MEDEO_ADMINISTRATOR_CODE } from '../../utils/codes';

/**
 *
 * @param {Practitioner} practitioner
 * @param isOC
 * @return {JSX.Element}
 * @constructor
 */
const PractitionerRow = ({ practitioner }) => {
  // shallow equal prevent re-renders when selectors returns object
  const roles = useSelector(
    s => getPractitionerRolesBypractitionerID(s, practitioner.id),
    shallowEqual
  );
  const medicalRole = roles.find(filterByTypeCode('medical'));
  const adminRole = roles.find(filterByTypeCode(MEDEO_ADMINISTRATOR_CODE));
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`organization/practitioner/${practitioner.id}`).then();
  };

  const messages = {
    Administrator: t`Administrator`,
    Member: t`Member`
  };
  return medicalRole && adminRole ? (
    <Row role="button" onClick={handleClick}>
      <td>
        <ProfilePicture
          size="small"
          src={displayPractitionerImg(practitioner)}
          alt={`${displayGivenName(practitioner)} ${displayFamilyName(
            practitioner
          )} picture`}
        />
      </td>
      <td>
        {displayGivenName(practitioner)} {displayFamilyName(practitioner)}
      </td>
      <td>{displayPractitionerActiveRole([medicalRole])}</td>
      <td>
        {/*defensive coding with optionnal chaining suffice here*/}

        {adminRole && (
          <Trans
            id={messages[adminRole.specialty?.[0]?.coding?.[0]?.display]}
          />
        )}
      </td>
    </Row>
  ) : null;
};
export default PractitionerRow;
