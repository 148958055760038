import { searchFhirResourceWorker } from '../Shared/sagas'
import { call, select } from '@redux-saga/core/effects'
import { getCurrentOrganizationId } from '../Auth/selectors'

function* rootSaga() {
  const organizationId = yield select(getCurrentOrganizationId)
  yield call(searchFhirResourceWorker, 'Location', {
    organization: organizationId,
  })
}

export default rootSaga
