import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components/macro'
import ProfilePicture from '../../Shared/components/ProfilePicture'
import { displayPractitionerImg } from '../../Shared/display/practitioner'
import {
  displayFamilyName,
  displayGivenName
} from '../../Shared/display/entity'
import {
  getSpecialtyCodingFromSystem,
  getSpecialtyTransFromOccupation
} from '../../Permissions/roles'
import {
  ESANTE_OCCUPATION_SYSTEM,
  ESANTE_SPECIALTY_SYSTEM
} from '../../utils/codes'
import { getDeprecatedPractitionerById } from '../selector'
import { getAcceptThirdPartyPayerDisplay } from '../utils'
import { getPractitionerRolesBypractitionerID } from '../../PractitionerRole/selector'
import { Trans } from '@lingui/macro'

const Div = styled.div`
  color: ${p => p.theme.black};
  border: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.5rem;

  & > aside {
    margin-right: 1rem;
  }

  & > main {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }
`
const Uppercase = styled.span`
  text-transform: uppercase;
`
const IdentityDetails = styled.div`
  display: flex;
  flex-direction: row;
  font-size: ${p => p.theme.small};

  & > :not(:last-child) {
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
  }
`

const BlueDetail = styled.span`
  color: ${p => p.theme.ocean};
  font-size: 0.8rem;
`

const GrayDetail = styled.div`
  color: ${p => p.theme.gray};
  font-size: 0.8rem;
`

const Muted = styled.span`
  font-size: ${p => p.theme.small};
  color: ${p => p.theme.gray};
`

const FirstRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 0.5rem;
`

const LastRow = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  color: ${p => p.theme.gray};
`
const PerformerInfo = ({ performerId }) => {
  // we get the OC's practitioner resource using the practitionerID
  // remember this practitionerID is not the same as the currentPractitioner id
  // but the id of each outsideCollaborator available to do a remote consultation
  const performer = useSelector(s =>
    getDeprecatedPractitionerById(s, performerId)
  )

  const roles = useSelector(s =>
    getPractitionerRolesBypractitionerID(s, performerId)
  )

  const given = performer?.name?.[0]?.given?.[0] ?? ''
  const family = performer?.name?.[0]?.family ?? ''
  const role = roles?.find(
    r => r?.identifier?.[0]?.type?.coding?.[0]?.code === 'medical'
  )
  const occupationCoding = getSpecialtyCodingFromSystem(
    role,
    ESANTE_OCCUPATION_SYSTEM
  )
  const specialtyCoding = getSpecialtyCodingFromSystem(
    role,
    ESANTE_SPECIALTY_SYSTEM
  )

  return (
    performer != null && (
      <Div>
        <aside>
          <ProfilePicture
            size="small"
            src={displayPractitionerImg(performer)}
            alt={`${displayGivenName(performer)} ${displayFamilyName(
              performer
            )} picture`}
          />
        </aside>
        <main>
          <FirstRow>
            <div>
              <span>{given}</span>
              &nbsp;
              <Uppercase>{family}</Uppercase>
              &nbsp;
              <BlueDetail>
                {getSpecialtyTransFromOccupation(
                  occupationCoding?.code,
                  specialtyCoding?.code
                )}
              </BlueDetail>
            </div>
            <Muted>{performer.address?.[0]?.postalCode}</Muted>
          </FirstRow>
          <LastRow>
            <IdentityDetails>
              <GrayDetail>
                <Trans>Tier payant</Trans>:&nbsp;
                {getAcceptThirdPartyPayerDisplay(performer)}
              </GrayDetail>
            </IdentityDetails>
            <Muted>{performer.address?.[0]?.city}</Muted>
          </LastRow>
        </main>
      </Div>
    )
  )
}

// prevent extra render with React.memo
export default React.memo(PerformerInfo)
