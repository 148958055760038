import defaultPractitionerImg from '../../img/defaultPractitionerHead.png'

export const displayPractitionerImg = practitioner => {
  let photo = []
  let type = []
  if (practitioner) {
    photo = practitioner?.photo?.[0]?.data
    type = practitioner?.photo?.[0]?.contentType
  }

  if (!photo) {
    return defaultPractitionerImg
  }

  return `data:${type};base64,${photo}`
}
