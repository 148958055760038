import { t, Trans } from '@lingui/macro';
import {
  faHeartbeat as farHeartbeat,
  faRulerVertical,
  faRulerHorizontal,
  faSmoking,
  faDrumstickBite,
  faChild
} from '@fortawesome/free-solid-svg-icons';
import {
  faHeart as fasHeartbeat,
  faThermometerThreeQuarters,
  faWeightHanging,
  faTint,
  faLungs,
  faWind,
  faChartBar
} from '@fortawesome/free-solid-svg-icons';

export const LOINC_SYSTEM_URL = 'http://loinc.org';

export const LOINC_BLOOD_PRESSURE_CODE = '85354-9';
export const LOINC_SYSTOLIC_BLOOD_PRESSURE_CODE = '8480-6';
export const LOINC_DIASTOLIC_BLOOD_PRESSURE_CODE = '8462-4';
export const LOINC_BODY_TEMPERATURE_CODE = '8310-5';
export const LOINC_OXYGEN_SATURATION_CODE = '59408-5';
export const LOINC_BODY_HEIGHT_CODE = '8302-2';
export const LOINC_BLOOD_GLUCOSE_CODE = '41653-7';
export const LOINC_BODY_WEIGHT_CODE = '29463-7';
export const LOINC_BODY_MASS_INDEX_CODE = '39156-5';
export const LOINC_HEART_RATE_CODE = '8867-4';
export const LOINC_RESPIRATORY_RATE_CODE = '9279-1';
export const LOINC_WAIST_SIZE_CODE = '56115-9';
export const LOINC_LAST_MEAL_CODE = '55420-4';
export const LOINC_CIGARETTES_SMOKED_TOTAL = '8664-5';
export const LOINC_HEART_SOUND = '80276-9';
export const LOINC_BREATH_SOUND = '80319-7';
export const LOINC_COMMENT_CODE = '77202-0';
// Loinc codde for Urinalysis panel
export const LOINC_URINALYSIS_PANEL_CODE = '24357-6';
export const LOINC_BILIRUBIN_PRESENCE_CODE = '5770-3';
export const LOINC_UROBILINOGEN_PRESENCE_CODE = '5818-0';
export const LOINC_KETONES_PRESENCE_CODE = '2514-8';
export const LOINC_GLUCOSE_PRESENCE_CODE = '25428-4';
export const LOINC_PROTEIN_PRESENCE_CODE = '20454-5';
export const LOINC_HEMOGLOBIN_PRESENCE_CODE = '5794-3';
export const LOINC_PH_CODE = '5803-2';
export const LOINC_NITRITE_PRESENCE_CODE = '5802-4';
export const LOINC_LEUKOCYTE_PRESENCE_CODE = '5799-2';
export const LOINC_SPECIFIC_GRAVITY = '5811-5';
export const LOINC_SPIROMETRY_PANEL = '81459-0'; // or 18759-1 ? Is it the true code ? I took it from kligo but I didn't check with Damien
// Other code for spirometry
export const CODE_CAUCASIAN = '2106';
export const CODE_AFRICAN = '2054';
export const CODE_ASIAN = '2028';
export const CODE_CURRENT_EVERY_DAY_SMOKER = '449868002';
export const CODE_CURRENT_SOME_DAY_SMOKER = '428041000124106';
export const CODE_FORMER_SMOKER = '8517006';
export const CODE_NEVER_SMOKER = '266919005';
export const CODE_SMOKER_STATUS_UNKNOWN = '77176002';
export const CODE_UNKNOWN_IF_EVER_SMOKED = '266927001';
export const CODE_CURRENT_HEAVY_SMOKER = '428071000124103';
export const CODE_CURRENT_LIGHT_SMOKER = '428061000124105';
export const CODE_PAQUET_ANNEE = '782516008';
export const CODE_DYSPNEE = '267036007';

export const DISPLAY_LOINCS = [
  LOINC_BLOOD_PRESSURE_CODE,
  LOINC_BODY_TEMPERATURE_CODE,
  LOINC_OXYGEN_SATURATION_CODE,
  LOINC_BODY_HEIGHT_CODE,
  LOINC_BLOOD_GLUCOSE_CODE,
  LOINC_BODY_WEIGHT_CODE,
  LOINC_HEART_RATE_CODE,
  LOINC_RESPIRATORY_RATE_CODE,
  LOINC_WAIST_SIZE_CODE,
  LOINC_URINALYSIS_PANEL_CODE
];

export const getLoincCodingFromObservation = observation => {
  if (
    observation == null ||
    observation.code == null ||
    observation.code.coding.length < 1
  )
    {return null;}
  const { coding } = observation.code;
  return coding.find(c => c.system === LOINC_SYSTEM_URL);
};

export const findComponentByLoincCode = (observation, code) => {
  if (
    observation == null ||
    observation.code == null ||
    observation.code.coding.length < 1
  )
    {return null;}

  // go through each component to find the first one which match with our criteria
  return observation.component.find(c => {
    // exit if there is no coding
    if (c.code == null || c.code.coding == null || c.code.coding.length < 1)
      {return false;}

    // look for LOINC Coding which match with the given code..
    return c.code.coding.find(
      c1 => c1.system === LOINC_SYSTEM_URL && c1.code === code
    );
  });
};

export const getLabelFromLoincCode = code => {
  switch (code) {
    case LOINC_BLOOD_PRESSURE_CODE:
      return t`Blood Pressure`;
    case LOINC_BODY_TEMPERATURE_CODE:
      return t`Temperature`;
    case LOINC_BODY_HEIGHT_CODE:
      return t`Height`;
    case LOINC_OXYGEN_SATURATION_CODE:
      return t`Oxygen Saturation`;
    case LOINC_BLOOD_GLUCOSE_CODE:
      return t`Blood Glucose`;
    case LOINC_BODY_WEIGHT_CODE:
      return t`Weight`;
    case LOINC_HEART_RATE_CODE:
      return t`Heart Rate`;
    case LOINC_URINALYSIS_PANEL_CODE:
      return t`Urine Test`;
    case LOINC_RESPIRATORY_RATE_CODE:
      return t`Respiratory Rate`;
    case LOINC_WAIST_SIZE_CODE:
      return t`Waist Size`;
    case LOINC_LAST_MEAL_CODE:
      return t`Last Meal`;
    case LOINC_BILIRUBIN_PRESENCE_CODE:
      return t`Bilirubin`;
    case LOINC_UROBILINOGEN_PRESENCE_CODE:
      return t`Urobilinogen`;
    case LOINC_KETONES_PRESENCE_CODE:
      return t`Ketones`;
    case LOINC_GLUCOSE_PRESENCE_CODE:
      return t`Glucose`;
    case LOINC_PROTEIN_PRESENCE_CODE:
      return t`Protein`;
    case LOINC_HEMOGLOBIN_PRESENCE_CODE:
      return t`Hemoglobin`;
    case LOINC_PH_CODE:
      return t`pH`;
    case LOINC_NITRITE_PRESENCE_CODE:
      return t`Nitrite`;
    case LOINC_LEUKOCYTE_PRESENCE_CODE:
      return t`Leukocyte`;
    case LOINC_SPECIFIC_GRAVITY:
      return t`Specifig gravity`;
    case CODE_PAQUET_ANNEE:
    case LOINC_CIGARETTES_SMOKED_TOTAL:
      return t`Packets of cigarettes`;
    case LOINC_SPIROMETRY_PANEL:
      return t`Spirometric Measurement`;
    case CODE_CAUCASIAN:
      return t`Caucasian, White`;
    case CODE_AFRICAN:
      return t`African American, Black`;
    case CODE_ASIAN:
      return t`Asian, Oriental`;
    case CODE_CURRENT_EVERY_DAY_SMOKER:
      return t`Current every day smoker`;
    case CODE_CURRENT_SOME_DAY_SMOKER:
      return t`Current some day smoker`;
    case CODE_FORMER_SMOKER:
      return t`Former smoker`;
    case CODE_NEVER_SMOKER:
      return t`Never smoker`;
    case CODE_SMOKER_STATUS_UNKNOWN:
      return t`Smoker status unknown`;
    case CODE_UNKNOWN_IF_EVER_SMOKED:
      return t`Unknown if ever smoked`;
    case CODE_CURRENT_HEAVY_SMOKER:
      return t`Current heavy smoker`;
    case CODE_CURRENT_LIGHT_SMOKER:
      return t`Current light smoker`;
    case CODE_DYSPNEE:
      return t`EVA dyspnee`;
    default:
      return 'inconnu';
  }
};

export const getDisplayInfoFromLoincCode = code => {
  switch (code) {
    case LOINC_BLOOD_PRESSURE_CODE:
      return { children: <Trans>Blood Pressure</Trans>, icon: fasHeartbeat };
    case LOINC_BODY_TEMPERATURE_CODE:
      return {
        children: <Trans>Temperature</Trans>,
        icon: faThermometerThreeQuarters
      };
    case LOINC_BODY_HEIGHT_CODE:
      return { children: <Trans>Height</Trans>, icon: faRulerVertical };
    case LOINC_OXYGEN_SATURATION_CODE:
      return { children: <Trans>Oxygen Saturation</Trans>, icon: faLungs };
    case LOINC_BLOOD_GLUCOSE_CODE:
      return { children: <Trans>Blood Glucose</Trans>, icon: faTint };
    case LOINC_BODY_WEIGHT_CODE:
      return { children: <Trans>Weight</Trans>, icon: faWeightHanging };
    case LOINC_BODY_MASS_INDEX_CODE:
      return { children: <Trans>BMI</Trans>, icon: faChild };
    case LOINC_HEART_RATE_CODE:
      return { children: <Trans>Heart Rate</Trans>, icon: farHeartbeat };
    case LOINC_URINALYSIS_PANEL_CODE:
      return { children: <Trans>Urine Test</Trans>, icon: faChartBar };
    case LOINC_RESPIRATORY_RATE_CODE:
      return { children: <Trans>Respiratory Rate</Trans>, icon: faWind };
    case LOINC_WAIST_SIZE_CODE:
      return { children: <Trans>Waist Size</Trans>, icon: faRulerHorizontal };
    case LOINC_LAST_MEAL_CODE:
      return { children: <Trans>Last Meal</Trans>, icon: faDrumstickBite };
    case LOINC_BILIRUBIN_PRESENCE_CODE:
      return { children: <Trans>Bilirubin</Trans>, icon: faChartBar };
    case LOINC_UROBILINOGEN_PRESENCE_CODE:
      return { children: <Trans>Urobilinogen</Trans>, icon: faChartBar };
    case LOINC_KETONES_PRESENCE_CODE:
      return { children: <Trans>Ketones</Trans>, icon: faChartBar };
    case LOINC_GLUCOSE_PRESENCE_CODE:
      return { children: <Trans>Glucose</Trans>, icon: faChartBar };
    case LOINC_PROTEIN_PRESENCE_CODE:
      return { children: <Trans>Protein</Trans>, icon: faChartBar };
    case LOINC_HEMOGLOBIN_PRESENCE_CODE:
      return { children: <Trans>Hemoglobin</Trans>, icon: faChartBar };
    case LOINC_PH_CODE:
      return { children: <Trans>pH</Trans>, icon: faChartBar };
    case LOINC_NITRITE_PRESENCE_CODE:
      return { children: <Trans>Nitrite</Trans>, icon: faChartBar };
    case LOINC_LEUKOCYTE_PRESENCE_CODE:
      return { children: <Trans>Leukocyte</Trans>, icon: faChartBar };
    case LOINC_SPECIFIC_GRAVITY:
      return { children: <Trans>Specifig gravity</Trans>, icon: faChartBar };
    case CODE_PAQUET_ANNEE:
      return {
        children: <Trans>Packets of cigarettes</Trans>,
        icon: faSmoking
      };
    case LOINC_SPIROMETRY_PANEL:
      return {
        children: <Trans>Spirometric Measurement</Trans>,
        icon: faWind
      };
    case CODE_CAUCASIAN:
      return <Trans>Caucasian, White</Trans>;
    case CODE_AFRICAN:
      return <Trans>African American, Black</Trans>;
    case CODE_ASIAN:
      return <Trans>Asian, Oriental</Trans>;
    case CODE_CURRENT_EVERY_DAY_SMOKER:
      return <Trans>Current every day smoker</Trans>;
    case CODE_CURRENT_SOME_DAY_SMOKER:
      return <Trans>Current some day smoker</Trans>;
    case CODE_FORMER_SMOKER:
      return <Trans>Former smoker</Trans>;
    case CODE_NEVER_SMOKER:
      return <Trans>Never smoker</Trans>;
    case CODE_SMOKER_STATUS_UNKNOWN:
      return <Trans>Smoker status unknown</Trans>;
    case CODE_UNKNOWN_IF_EVER_SMOKED:
      return <Trans>Unknown if ever smoked</Trans>;
    case CODE_CURRENT_HEAVY_SMOKER:
      return <Trans>Current heavy smoker</Trans>;
    case CODE_CURRENT_LIGHT_SMOKER:
      return <Trans>Current light smoker</Trans>;
    case CODE_DYSPNEE:
      return { children: <Trans>EVA dyspnee</Trans>, icon: faChartBar };
    default:
      return { name: code };
  }
};

export const getLoincCoding = code => {
  return {
    code: code,
    system: LOINC_SYSTEM_URL
  };
};

export const getUnitFromType = type => {
  switch (type) {
    case LOINC_BODY_HEIGHT_CODE:
    case LOINC_WAIST_SIZE_CODE:
      return 'cm';
    case LOINC_RESPIRATORY_RATE_CODE:
    case LOINC_HEART_RATE_CODE:
      return '/min';
    case LOINC_BLOOD_PRESSURE_CODE:
    case LOINC_DIASTOLIC_BLOOD_PRESSURE_CODE:
    case LOINC_SYSTOLIC_BLOOD_PRESSURE_CODE:
      return 'mmHg';
    case LOINC_BLOOD_GLUCOSE_CODE:
      return 'mg/dL';
    case LOINC_BODY_WEIGHT_CODE:
      return 'kg';
    case LOINC_BODY_MASS_INDEX_CODE:
      return 'kg/m²';
    case LOINC_BODY_TEMPERATURE_CODE:
      return '°C';
    case LOINC_OXYGEN_SATURATION_CODE:
      return '%';
    case CODE_PAQUET_ANNEE:
      return <Trans>Packets.year</Trans>;
    case CODE_DYSPNEE:
      return '(0-10)';
    default:
      return '';
  }
};

export const getFieldsOfUrineTest = () => {
  return [
    {
      value: 'bili',
      placeholder: getDisplayInfoFromLoincCode(LOINC_BILIRUBIN_PRESENCE_CODE)
        .children,
      options: ['neg.', '+', '++', '+++']
    },
    {
      value: 'urobi',
      placeholder: getDisplayInfoFromLoincCode(LOINC_UROBILINOGEN_PRESENCE_CODE)
        .children,
      options: ['norm.', '2', '4', '6', '8']
    },
    {
      value: 'keto',
      placeholder: getDisplayInfoFromLoincCode(LOINC_KETONES_PRESENCE_CODE)
        .children,
      options: ['neg.', 'trace', '+', '++', '+++']
    },
    {
      value: 'gluc',
      placeholder: getDisplayInfoFromLoincCode(LOINC_GLUCOSE_PRESENCE_CODE)
        .children,
      options: ['norm.', '50', '100', '250', '500', '> 1000']
    },
    {
      value: 'prot',
      placeholder: getDisplayInfoFromLoincCode(LOINC_PROTEIN_PRESENCE_CODE)
        .children,
      options: ['neg.', 'trace', '30', '100', '500']
    },
    {
      value: 'hemo',
      placeholder: getDisplayInfoFromLoincCode(LOINC_HEMOGLOBIN_PRESENCE_CODE)
        .children,
      options: [
        'neg.',
        '+ca. 5-10',
        '++ca. 50',
        '+++ca. 300',
        'ca. 5-10',
        'ca. 50',
        'ca. 300'
      ]
    },
    {
      value: 'pH',
      placeholder: getDisplayInfoFromLoincCode(LOINC_PH_CODE).children,
      options: ['5', '6', '6.5', '7', '8', '9']
    },
    {
      value: 'nitrite',
      placeholder: getDisplayInfoFromLoincCode(LOINC_NITRITE_PRESENCE_CODE)
        .children,
      options: ['neg.', 'pos.']
    },
    {
      value: 'leuko',
      placeholder: getDisplayInfoFromLoincCode(LOINC_LEUKOCYTE_PRESENCE_CODE)
        .children,
      options: ['neg.', 'ca. 25', 'ca. 75', 'ca. 500']
    },
    {
      value: 'spec_grav',
      placeholder: getDisplayInfoFromLoincCode(LOINC_SPECIFIC_GRAVITY).children,
      options: ['1.000', '1.005', '1.010', '1.015', '1.020', '1.025', '1.030']
    }
  ];
};

export const getLoincCodeFromBluetooth = deviceType => {
  switch (deviceType) {
    case 'body_temperature':
      return '8310-5';
    case 'blood_pressure_monitor':
    case 'blood_pressure':
      return '85354-9';
    case 'glucometer':
      return '41653-7';
    case 'body_scale':
      return '29463-7';
    case 'oxygen_saturation':
      return '59408-5';
    case 'heart_rate':
      return '8867-4';
    case 'body_weight':
      return '29463-7';
    case 'blood_glucose':
      return '41653-7';
    case 'body_height':
      return '8302-2';
    case 'body_mass_index':
      return '39156-5';
    default:
      return null;
  }
};
