export const REMOVING_SLOT = 'REMOVING_SLOT'
export const SLOT_REMOVED = 'SLOT_REMOVED'
export const CREATING_SLOT = 'CREATING_SLOT'
export const SLOT_SELECTED = 'SLOT_SELECTED'
export const SLOT_DESELECTED = 'SLOT_DESELECTED'
export const SLOT_RECEIVED = 'SLOT_RECEIVED'
export const SLOTS_RECEIVED = 'SLOTS_RECEIVED'

export const selectBookedSlot = slot => ({
  type: SLOT_SELECTED,
  payload: { slot },
})
