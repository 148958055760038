import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getNotifications } from '../selectors'
import { deleteNotification } from '../actions'
import styled from 'styled-components/macro'
import { keyframes } from 'styled-components'
import DeleteNotificationButton from './DeleteNotificationButton'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
/**
 * @param {*} props
 * @param {string} props.type
 * @param {string} props.theme
 */

const animationBar = keyframes`
  0% {
   transform: translateX(50%);
   opacity: 0;
  }
  100% {
  opacity: 1;
    transform: translateX(0%);
  }
  `

const getNotificationColorFromProps = props => {
  switch (props.type) {
    case 'success':
      return props.theme.emerald
    case 'warning':
      return props.theme.mustard
    case 'information':
      return props.theme.ocean
    case 'error':
      return props.theme.scarlett
    default:
      return null
  }
}

const Container = styled.div`
  position: fixed;
  display: flex;
  align-items: start;
  flex-direction: column;
  top: 0.625rem;
  z-index: 99;
  right: 0.625rem;
  width: 26.875rem;
  & > *:not(:last-child) {
    margin-bottom: 1rem;
  }
`
const Icon = styled.div`
  color: ${p => getNotificationColorFromProps(p)};
`

const NotificationItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.9rem;
  padding: 1rem;
  box-shadow: ${p => p.theme.boxShadow};
  border-radius: 0.625rem;
  background-color: white;
  text-align: center;
  min-width: 26.875rem;
  animation: ${animationBar} 333ms ease-in;
  & > :not(:last-child) {
    margin-right: 0.5rem;
  }
`
const Message = styled.div`
  display: flex;
  vertical-align: super;
  flex: 1;
`

const Notification = () => {
  const dispatch = useDispatch()
  const notifications = useSelector(getNotifications)

  useEffect(() => {
    if (notifications.length > 0) {
      const timer = setTimeout(() => {
        dispatch(deleteNotification())
        // all notifications will have a duration of 5s
      }, '5000')
      return () => clearTimeout(timer)
    }
  }, [notifications, dispatch])

  return (
    <Container>
      {notifications.map((n, i) => (
        <NotificationItem key={i}>
          <Icon type={n.notificationType}>
            <FontAwesomeIcon size="2x" icon={faInfoCircle} />
          </Icon>
          <Message>{n.message}</Message>
          <DeleteNotificationButton />
        </NotificationItem>
      ))}
    </Container>
  )
}

export default Notification
