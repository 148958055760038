import React, { createContext, FC, ReactNode, useContext } from 'react'
import { getPatientById } from './selectors'
import { useSelector } from 'react-redux'
import { Patient } from 'fhir-stu3'

/**
 * @deprecated, it should only be used for tests purpose
 * use the PatientContextProvider for the common cases
 */
export const PatientContext = createContext<Patient | null>(null)

type PatientContextProviderProps = {
  patientID: string
  children: ReactNode
}

export const PatientContextProvider: FC<PatientContextProviderProps> = ({
  children,
  patientID
}) => {
  const patient = useSelector(getPatientById(patientID)) ?? null

  return (
    <PatientContext.Provider value={patient}>
      {children}
    </PatientContext.Provider>
  )
}

const usePatient = (): Patient => {
  const patient = useContext(PatientContext)
  if (patient == null) {throw new Error('patient is not defined')}
  return patient
}

export default usePatient
