import { MEDEO_ACTION_DEFINITION_SYSTEM } from '../../PlanDefinition/containers/PlanDefinitionAction'
import { MEDEO_ENCOUNTER_TYPE_CLINICAL_EXAM } from '../../utils/encounter-type'
import QuestionnaireResponseAction from './QuestionnaireResponseAction'
import ReadOnlyEncounterAction from './ReadOnlyEncounterAction'
/**
 * ReadOnly actions coming from the requestGroup
 * This component calls the right child component depending on the given request group action type
 */
const RequestGroupAction = ({ action, requestGroup, patient, encounterID }) => {
  const actionCode = action.code?.find(
    code => code.coding?.[0].system === MEDEO_ACTION_DEFINITION_SYSTEM
  )?.coding[0].code

  switch (actionCode) {
    // the totalPrice action actually behaves as a questionnaire,
    // but the distinction was done for the edit of it as it needs to get updated particularly
    case 'totalPrice':
    case 'questionnaire':
      return (
        <QuestionnaireResponseAction
          encounterID={encounterID}
          action={action}
          requestGroup={requestGroup}
          patient={patient}
        />
      )
    case MEDEO_ENCOUNTER_TYPE_CLINICAL_EXAM:
      return (
        <ReadOnlyEncounterAction requestGroup={requestGroup} action={action} patient={patient} />
      )

    // This is how the repeatable documents are distinguished in the plan definition and in
    // the request group. For the cerfas, which did not fall in the 'section-row' case,
    // but are both saved in their own questionnaire response on top of the document questionnaire response one,
    // we need this condition not to display the questionnaire response on top of the attached document
    case 'button':
      return null
    default:
      if (action.action) {
        return action.action.map((a, i) => (
          <RequestGroupAction
            encounterID={encounterID}
            action={a}
            requestGroup={requestGroup}
            key={"sub" + requestGroup.id + i}
            patient={patient}
          />
        ))
      }
      return null
  }
}

export default RequestGroupAction
