import React from 'react'
import StripeCreateAccountButton from '../containers/StripeCreateAccountButton'
import StripeAccessDashboardButton from '../containers/StripeAccessDashboardButton'

/**
 * Dumb component that will take care of displaying the right component
 * depending if the user has a StripeId or not
 * @param stripeId: stripe connected account id
 * @returns {*}
 * @constructor
 */
const StripeAccount = ({ stripeId }) => {
  return stripeId ? (
    <StripeAccessDashboardButton stripeId={stripeId} />
  ) : (
    <StripeCreateAccountButton />
  )
}

export default StripeAccount
