import GridLayout from '../../Shared/components/GridLayout';
import React, { FC } from 'react';
import CreateOrChangePinCode from '../../Settings/container/CreateOrChangePinCode';

const EditPinCodeForm: FC<{ practitionerID: string }> = ({
  practitionerID
}) => {
  return (
    <GridLayout.Main variant="full">
      <CreateOrChangePinCode
        practitionerID={practitionerID}
        comesFromRequirePractitioner={true}
      />
    </GridLayout.Main>
  );
};

export default EditPinCodeForm;
