import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Trans } from '@lingui/macro'
import { Medication, MedicationRequest } from 'fhir-stu3'
import { FC, useEffect, useReducer } from 'react'
import styled from 'styled-components/macro'
import { Button, Form, Input } from '../../Components'
import useSave from '../../Shared/hooks/useSave'
import { MEDEO_ALD_TYPE_SYSTEM, MEDEO_ALD_TYPE_VALUE } from '../codes'
import useLazySearch from '../useLazySearch'
import SearchMedicationInput from './SearchMedicationInput'
const Row = styled.div`
  display: flex;
  align-items: flex-end;
  & > :first-child {
    flex: 1;
    margin-right: 1rem;
  }
`
const CustomCheckbox = styled(Input).attrs({ type: 'checkbox' })`
  border-color: transparent;
  padding: 0.75rem;
  margin-bottom: 0;
`
const CustomForm = styled(Form)`
  display: flex;
  padding: 0 2rem;
  flex-direction: column;
  header > h1 {
    margin: 0;
    font-size: ${(p) => p.theme.medium};
  }
  & > :not(:last-child) {
    margin-bottom: 1rem;
  }
  & > ${Button} {
    align-self: center;
  }
`
interface MedicationRequestFormState {
  ald: boolean
  medication: Medication | null
  medicationRequest: MedicationRequest
}

const getInitialState = (): MedicationRequestFormState => {
  return {
    ald: false, // redundant, but easier way to keep track of the checkbox state
    medication: null,
    medicationRequest: {
      subject: {},
      resourceType: 'MedicationRequest',
      intent: 'order',
      dosageInstruction: [
        {
          text: ''
        }
      ]
    }
  }
}

const reducer = (
  state: MedicationRequestFormState,
  action: { type: string; payload?: any }
): MedicationRequestFormState => {
  const { type, payload } = action
  const newState = state
  if (newState.medicationRequest == null) {
    newState.medicationRequest = getInitialState().medicationRequest
  }
  switch (type) {
    case 'changeDosage':
      newState.medicationRequest.dosageInstruction = [
        {
          text: payload
        }
      ]
      return newState
    case 'changeMedication':
      newState.medication = payload
      newState.medicationRequest.medicationReference = {
        display: payload.code?.coding.find((c: any) => c.display)?.display
      }
      return newState
    case 'changeType':
      // As we might change from ALD to none ALD type, we do not copy the
      // the identifier property at first
      newState.ald = payload
      newState.medicationRequest.identifier = []
      if (payload === true) {
        newState.medicationRequest.identifier = [
          {
            type: {
              coding: [
                {
                  system: MEDEO_ALD_TYPE_SYSTEM,
                  code: MEDEO_ALD_TYPE_VALUE
                }
              ]
            }
          }
        ]
      }
      return newState
    case 'changeSelected':
      newState.medicationRequest = payload
      return newState
    case 'reset':
      return getInitialState()
    default:
      return newState
  }
}
interface EditMedicationRequestFormProps {
  onChange: (medicationRequest: MedicationRequest) => void
  onSubmit: (medicationRequest: MedicationRequest) => void
  medicationRequest: MedicationRequest | null
}

const EditMedicationRequestForm: FC<EditMedicationRequestFormProps> = (
  props: EditMedicationRequestFormProps
) => {
  const { onChange, onSubmit, medicationRequest } = props
  const [state, dispatch] = useReducer(reducer, getInitialState())

  // save and search are used in this form onSubmit handler
  // some medications need to be saved before creating medicationRequests
  // see handleSubmit for details
  const [save, { loading }] = useSave()
  const [search] = useLazySearch()

  // the following effect syncs props with state
  // usually it will occur when updating a medicationRequest
  useEffect(() => {
    // if (!medicationRequest) {
    //   return
    // }
    dispatch({
      type: 'changeSelected',
      payload: medicationRequest
    })
  }, [medicationRequest])
  // Also notify parent component.
  // useEffect(() => {
  //   onChange(state.medicationRequest)
  // }, [onChange, state])

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    const medicationRequest = state.medicationRequest

    // the process here is similar to the one for saving MedicationStatement
    // see saveMedicationAndMedicationStatementWorker
    // to create a MedicationRequest we need a ref to a medication
    // two cases: either the database knows the medication or not
    // in addition to that this form handle MedicationRequest update
    // we end up with the following process

    // Are we creating a MedicationRequest ?
    // if this is the case there won't be a reference to a medication yet
    // this also happens when updating the medication of a MedicationRequest
    if (medicationRequest?.medicationReference?.reference == null) {
      // SearchMedicationInput should have populate the state with a medication
      // we can extract code and display to create the MedicationReference

      const { medication } = state
      const code = medication?.code?.coding?.find((c: any) => c.code)?.code
      const display = medication?.code?.coding?.find((c: any) => c.display)
        ?.display

      if (code != null) {
        // here we have a code: this means the medication comes from the BDPM
        // now we need to check if this medication exists in the FHIR db
        const data = await search('Medication', {
          code
        })
        // two cases:
        // - if it exist simply refer to its id
        // - otherwise create it then point to its id

        // NOTE(charles): we could have used something like
        // {
        //    reference: 'Medication?code=12345678'
        //    display: 'some-medication'
        // }
        // but it only works if there is only one item corresponding to the code
        // I noticed that some medications appears several times in the database
        // so back to referring using id `Medication/7654321`
        if (data.Medication != null) {
          const [medication] = data.Medication
          medicationRequest.medicationReference = {
            reference: `Medication/${medication.id}`,
            display
          }
        } else {
          const { data: medication } = await save(state.medication)
          medicationRequest.medicationReference = {
            reference: `Medication/${medication.id}`,
            display
          }
        }
      } else {
        // if the code is null means the user has typed an unknown medication
        // we need to create this medication in the database
        const { data: medication } = await save(state.medication)
        medicationRequest.medicationReference = {
          reference: `Medication/${medication.id}`,
          display
        }
      }
    }

    // the previous branch is not run if the user update only the dosage or ald
    onSubmit(medicationRequest)
    dispatch({ type: 'reset' })
  }
  return (
    <CustomForm onSubmit={handleSubmit}>
      <header>
        <h1>
          <Trans>Add a Medication</Trans>
        </h1>
      </header>
      <SearchMedicationInput
        value={state.medicationRequest?.medicationReference}
        onChange={(medication: Medication) => {
          dispatch({ type: 'changeMedication', payload: medication })
          onChange(state.medicationRequest)
        }}
      />
      <Row>
        <Input
          label={<Trans>Dosage</Trans>}
          required={true}
          data-test={'medication-request-dosage'}
          value={state?.medicationRequest?.dosageInstruction?.[0]?.text}
          onChange={(e: Event) => {
            const target = e.target as HTMLInputElement
            dispatch({ type: 'changeDosage', payload: target?.value })
            onChange(state.medicationRequest)
          }}
        />
        <CustomCheckbox
          type="checkbox"
          label={<Trans>long-term illness</Trans>}
          checked={state.ald}
          data-test={'medication-request-checkboxdosage'}
          onChange={(e: Event) => {
            const target = e.target as HTMLInputElement
            dispatch({ type: 'changeType', payload: target.checked === true })
            onChange(state.medicationRequest)
          }}
        />
      </Row>
      {/*
        the button will only be disabled when saving a new medication
        - either when the user type an unknown medication (i.e. not in BPDM)
        - or when the medication is not in our FHIR database yet
        see handleSubmit for more details
      */}
      <Button data-test={'add-medication'} disabled={loading === true}>
        <FontAwesomeIcon icon={faPlus} />
        &nbsp;<Trans>Add</Trans>
      </Button>
    </CustomForm>
  )
}

export default EditMedicationRequestForm
