import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Trans } from '@lingui/macro'
import { MedicationRequest } from 'fhir-stu3'
import { FC, useEffect, useState } from 'react'
import QuestionnaireTextButton from '../../Questionnaire/components/QuestionnaireTextButton'
import Modal from '../../Shared/components/Modal'
import EditGroupedMedicationRequestsForm from './EditGroupedMedicationRequestsForm'

interface MedicationRequestsModalProps {
  patientID: string
  encounterID: string
  practitionerID: string
  onSubmit: (medicationRequests: MedicationRequest[]) => Promise<void>
  medicationRequests: MedicationRequest[]
  onChange: (medicationRequests: MedicationRequest[]) => void
  open: boolean
  isSubmitting: boolean
}

const MedicationRequestsModal: FC<MedicationRequestsModalProps> = (
  props: MedicationRequestsModalProps
) => {
  const {
    patientID,
    encounterID,
    practitionerID,
    onSubmit,
    medicationRequests,
    onChange,
    open,
    isSubmitting
  } = props
  const [state, setState] = useState(medicationRequests)
  useEffect(() => {
    setState(medicationRequests)
  }, [medicationRequests])
  return (
    <Modal
      // @ts-ignore
      title={<Trans>Medication Requests</Trans>}
      // @ts-ignore
      onChange={onChange}
      open={open}
      component={({ onClick }) => (
        <QuestionnaireTextButton
          onClick={() => {
            setState([])
            onClick()
          }}
          data-test={'create-prescription-medicamenteuse'}
        >
          <FontAwesomeIcon icon={faPlus} />
          &nbsp;<Trans>Create a prescription</Trans>
        </QuestionnaireTextButton>
      )}
    >
      {(close: any) => (
        <EditGroupedMedicationRequestsForm
          encounterID={encounterID}
          practitionerID={practitionerID}
          patientID={patientID}
          medicationRequests={state}
          onAfterSubmit={async (medicationRequests) => {
            await onSubmit(medicationRequests)
            close()
          }}
          isSubmitting={isSubmitting}
        />
      )}
    </Modal>
  )
}
MedicationRequestsModal.defaultProps = {
  medicationRequests: []
}

export default MedicationRequestsModal
