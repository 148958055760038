import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import AttachmentControl from './AttachmentControl'

const ControlButton = ({ icon, title, onClick }) => (
  <AttachmentControl title={title} onClick={onClick}>
    <FontAwesomeIcon icon={icon} />
  </AttachmentControl>
)

export default ControlButton
