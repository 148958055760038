export const LANGUAGE_SWITCHED = 'LANGUAGE_SWITCHED'

export const switchLanguage = () => ({
  type: LANGUAGE_SWITCHED,
})

export const ducks = (state = 'fr', action) => {
  switch (action.type) {
    case LANGUAGE_SWITCHED:
      return state === 'fr' ? 'en' : 'fr'
    default:
      return state
  }
}

export const getLanguage = state => state.language
