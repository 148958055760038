import createSimpleReducers from '../Shared/ducks/createSimpleReducers'
import { ReduxState } from '../Shared/ducks/types'
import { ProcedureRequest } from 'fhir-stu3'
import {
  MEDEO_PROCEDURE_REQUEST_BOOKING_APPOINTMENT_CODE,
  MEDEO_PROCEDURE_REQUEST_REMOTE_CONSULTATION_CODE
} from '../utils/codes'
const ducks = createSimpleReducers('ProcedureRequest')

const getAllProcedureRequest = (state: ReduxState): ProcedureRequest[] => {
  return state.ProcedureRequest.allIds.map(
    id => state.ProcedureRequest.byId[id]
  )
}

export const getActiveProcedureRequestsBypatientID = (patientID: string) => (
  state: ReduxState
): ProcedureRequest[] => {
  return getAllProcedureRequest(state)
    .filter(request => request.subject.reference === 'Patient/' + patientID)
    .filter(request => request.status === 'active')
    .sort((a, b) => {
      return parseInt(b?.id ?? '', 10) - parseInt(a?.id ?? '', 10)
    })
}
export const getActiveTeleconsultationBypatientID = (patientID: string) => (
  state: ReduxState
): ProcedureRequest | undefined => {
  return getActiveProcedureRequestsBypatientID(patientID)(state).find(request =>
    request.code?.coding?.some(
      c => c.code === MEDEO_PROCEDURE_REQUEST_REMOTE_CONSULTATION_CODE
    )
  )
}

export const getActivePreconsultationBypatientID = (patientID: string) => (
  state: ReduxState
): ProcedureRequest | undefined => {
  return getActiveProcedureRequestsBypatientID(patientID)(state).find(request =>
    request.code?.coding?.some(
      c => c.code === MEDEO_PROCEDURE_REQUEST_BOOKING_APPOINTMENT_CODE
    )
  )
}

export default ducks
