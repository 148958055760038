import moment from 'moment'
import { getPractitionerRoleBypractitionerID } from '../PractitionerRole/selector'
import {
  specialtySecondRole,
  specialtyThirdRole
} from '../Permissions/permissions'
import { getIdByReference } from '../Shared/utils'
import { getReferenceFromParticipantCode } from './utils'
import {
  ESANTE_OCCUPATION_SYSTEM,
  MEDEO_APPOINTMENT_IDENTIFIER_SYSTEM,
  MEDEO_APPOINTMENT_TYPE_TELECONSULTATION
} from '../utils/codes'
import { ReduxState } from '../Shared/ducks/types'
import { Appointment } from 'fhir-stu3'
import { CodeableConcept } from 'fhir-stu3/src'

export const getAllAppointments = (state: ReduxState): Appointment[] => {
  return state.Appointment.allIds.map(id => state.Appointment.byId[id])
}

export const getAppointmentById = (appointmentId: string) => (
  state: ReduxState
): Appointment | undefined => {
  return state.Appointment.byId[appointmentId]
}

export const getAppointmentsBypractitionerID = (practitionerID: string) => (
  state: ReduxState
): Appointment[] => {
  // we take the practitioner role to know what the code that we need for filter appointment
  // an appointment is composed by 4 participants : patient, practitioner, taking-appointment
  // (pharmacist) and location. Here the process is for doctor and pharmacist
  const [medicalRole] = getPractitionerRoleBypractitionerID(
    state,
    practitionerID
  )
  const specialty = medicalRole?.specialty?.find(
    (s: CodeableConcept) => s.coding?.[0]?.system === ESANTE_OCCUPATION_SYSTEM
  )?.coding?.[0]?.code
  let code = 'practitioner'
  if (
    specialtyThirdRole.some(spe => spe === specialty) ||
    specialtySecondRole.some(spe => spe === specialty)
  )
    {code = 'taking-appointment'}
  return getAllAppointments(state).filter(appointment => {
    return (
      (appointment.status === 'fulfilled' || appointment.status === 'booked') &&
      getIdByReference(getReferenceFromParticipantCode(appointment, code)) ===
        practitionerID
    )
  })
}

export const getAppointmentsByLocation = (locationId: string) => (
  state: ReduxState
): Appointment[] => {
  return getAllAppointments(state).filter(appointment => {
    return (
      (appointment.status === 'fulfilled' || appointment.status === 'booked') &&
      getIdByReference(
        getReferenceFromParticipantCode(appointment, 'location')
      ) === locationId
    )
  })
}

export const getAppointmentsBypatientID = (
  patientID: string,
  filters: { (a: Appointment): boolean }[] = [() => true]
) => (state: ReduxState): Appointment[] => {
  return getAllAppointments(state).filter(appointment => {
    return (
      appointment.status === 'booked' &&
      getIdByReference(
        getReferenceFromParticipantCode(appointment, 'patient')
      ) === patientID &&
      filters.every(f => f(appointment))
    )
  })
}

export const getFutureAppointments = (
  patientID: string,
  type = MEDEO_APPOINTMENT_TYPE_TELECONSULTATION
) => (state: ReduxState): Appointment[] => {
  // we make sure that appointments are the one which happens today or in the future
  return getAllAppointments(state).filter(appointment => {
    return (
      appointment.status === 'booked' &&
      getIdByReference(
        getReferenceFromParticipantCode(appointment, 'patient')
      ) === patientID &&
      // Today or after
      moment(appointment.start).isSameOrAfter(moment(), 'day') &&
      // For this appointment type
      appointment.identifier?.find(
        identifier => identifier.system === MEDEO_APPOINTMENT_IDENTIFIER_SYSTEM
      )?.value === type
    )
  })
}
