import {
  getPlanDefinitionIdentifierValueFromEncounterType,
  PLAN_DEFINITION_IDENTIFIER_SYSTEM
} from './utils'
import { getAllEntities } from '../Shared/selectors'
import { ReduxState } from '../Shared/ducks/types'
import { PlanDefinition } from 'fhir-stu3'

export const getAllPlanDefinitions = (state: ReduxState): PlanDefinition[] =>
  getAllEntities(state, 'planDefinition')

export const getPlanDefinitionByEncounterType = (encounterType: string) => (
  state: ReduxState
) => {
  return getAllPlanDefinitions(state).find(
    (o: PlanDefinition) =>
      o?.identifier?.find(i => i.system === PLAN_DEFINITION_IDENTIFIER_SYSTEM)
        ?.value ===
      getPlanDefinitionIdentifierValueFromEncounterType(encounterType)
  )
}
export const getPlanDefinitionById = (planDefinitionId: string) => (
  state: ReduxState
): PlanDefinition => state.planDefinition.byId[planDefinitionId]
