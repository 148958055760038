export const CONDITIONS_RECEIVED = 'CONDITIONS_RECEIVED'
export const CONDITION_RECEIVED = 'CONDITION_RECEIVED'
export const CONDITION_REMOVED = 'CONDITION_REMOVED'
export const REMOVING_CONDITION = 'REMOVING_CONDITION'
export const CREATING_CONDITION = 'CREATING_CONDITION'

export const createCondition = payload => ({
  type: CREATING_CONDITION,
  payload,
})

export const removeCondition = payload => ({
  type: REMOVING_CONDITION,
  payload,
})
