import React, { useState } from 'react'
import { Button, Input, Spinner } from '../../Components'
import { Trans } from '@lingui/macro'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'

import styled from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { lighten } from 'polished'
import { createLoginLink } from '../utils/lambdas'

const ExternalPageIcon = styled(FontAwesomeIcon)`
  margin-left: 1rem;
`

const CustomButton = styled(Button)`
  display: flex;
  color: ${p => p.theme.white};
  align-items: center;
  &:hover {
    background-color: ${p => lighten(0.25, p.theme.ocean)};
  }
`

const Container = styled.div`
  display: flex;
  justify-content: space-between;
`

const CustomSpinner = styled(Spinner)`
  margin: 5rem auto;
  color: ${p => p.theme.white};
`

/**
 * When the user visits their Express dashboard, they can modify account information,
 * view recent and upcoming payouts, and perform other relevant tasks.
 * Instead of logging in to a dedicated Stripe account,
 * users reach their dashboard through Medeo platform.
 *
 * In this component we will dispatch an action that will call a lambda
 * which will retrieve an URL from the login_link_API
 *
 * This flow is necessary as the URL expires after a brief time
 * and then the user would have to ask another access
 * @param setIsLoading
 * @param stripeId : stripe connected account id
 * @returns {*}
 * @constructor
 */
const StripeAccessDashboardButton = ({ stripeId }) => {
  const [isLoading, setIsLoading] = useState(false)

  const handleClick = async () => {
    setIsLoading(true)
    try {
      const { dashboardAccess } = await createLoginLink(stripeId)
      window.open(dashboardAccess.url, '_blank')
    } catch (e) {
      console.log(e)
    } finally {
      setIsLoading(false)
    }
  }

  return isLoading ? (
    <CustomSpinner />
  ) : (
    <Container>
      <Input
        label={<Trans>Stripe Identifier</Trans>}
        value={stripeId}
        readOnly
      />
      <CustomButton onClick={handleClick}>
        <Trans>Access my Stripe account</Trans>
        <ExternalPageIcon icon={faExternalLinkAlt} />
      </CustomButton>
    </Container>
  )
}

export default StripeAccessDashboardButton
