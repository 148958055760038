import React from 'react'
import { Card } from '../../Components'
import { Trans } from '@lingui/macro'
import EditPractitionerForm from '../container/EditPractitionerForm'

const CreateUserPage = () => {
  return (
    <Card>
      <h1>
        <Trans>Create an user</Trans>
      </h1>
      <EditPractitionerForm readOnly={false} use={'createUser'} />
    </Card>
  )
}

export default CreateUserPage
