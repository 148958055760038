import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components/macro'
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons'

const SampleButton = styled.button`
  border: 0;
  outline: 0;
  margin-left: ${props => props.theme.spacing.medium};
  background: none;
  cursor: pointer;
`

const CollapseButton = ({ collapsed, onClick }) => {
  return (
    <SampleButton onClick={onClick}>
      <FontAwesomeIcon icon={collapsed ? faChevronDown : faChevronUp} />
    </SampleButton>
  )
}

export default CollapseButton
