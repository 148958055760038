export const NOTIFICATION_CREATED = 'NOTIFICATION_CREATED'
export const NOTIFICATION_DELETED = 'NOTIFICATION_DELETED'

export const createNotification = (message, notificationType, timeout) => ({
  type: NOTIFICATION_CREATED,
  payload: {
    message,
    notificationType,
    timeout,
  },
})

export const deleteNotification = () => ({
  type: NOTIFICATION_DELETED,
})
