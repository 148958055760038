import * as pagination from '../Shared/actions/pagination'

// dispatched when encounters are received from the backend.
export const STARTING_ENCOUNTER = 'STARTING_ENCOUNTER'
export const STARTING_BLUETOOTH_ENCOUNTER = 'STARTING_BLUETOOTH_ENCOUNTER'

export const ENCOUNTERS_RECEIVED = 'ENCOUNTERS_RECEIVED'
export const ENCOUNTER_RECEIVED = 'ENCOUNTER_RECEIVED'
export const FETCHING_ENCOUNTERS = 'FETCHING_ENCOUNTERS'
export const STOPPING_ENCOUNTER = 'STOPPING_ENCOUNTER'
export const STOPPING_PRETELECONSULTATION_ENCOUNTER =
  'STOPPING_PRETELECONSULTATION_ENCOUNTER'
export const ENCOUNTER_DESELECTED = 'ENCOUNTER_DESELECTED'
export const ENCOUNTER_SELECTED = 'ENCOUNTER_SELECTED'
export const UPDATING_ENCOUNTER = 'UPDATING_ENCOUNTER'
export const CANCELING_ENCOUNTER = 'CANCELING_ENCOUNTER'
export const FAIL_RECEIVING_ENCOUNTER = 'FAIL_RECEIVING_ENCOUNTER'
export const STOP_BLUETOOTH_ENCOUNTER = 'STOP_BLUETOOTH_ENCOUNTER'

export const GET_TOTAL_ENCOUNTER = 'GET_TOTAL_ENCOUNTER'
export const GET_INITIAL_ENCOUNTER_PAGE = 'GET_INITIAL_ENCOUNTER_PAGE'
export const LOAD_MORE_ENCOUNTERS = 'LOAD_MORE_ENCOUNTERS'
export const ENCOUNTER_PAGE_CHANGED = 'ENCOUNTER_PAGE_CHANGED'
export const CLEARING_ENCOUNTER_PAGE = 'CLEARING_ENCOUNTER_PAGE'
export const TOTAL_ENCOUNTER_RECEIVED = 'TOTAL_ENCOUNTER_RECEIVED'

export const MOST_RECENT_ENCOUNTER_RECEIVED = 'MOST_RECENT_ENCOUNTER_RECEIVED'
export const STALE_ENCOUNTER_PAGE = 'STALE_ENCOUNTER_PAGE'

export const SAVING_ENCOUNTER_SEQUENCE = 'SAVING_ENCOUNTER_SEQUENCE'

export const loadEncountersFromSubjectId = (subjectId, type) => ({
  type: FETCHING_ENCOUNTERS,
  payload: {
    subjectId,
    type
  }
})

/**
 * We create a new encounter
 * if this one is created for teleconsultation, we need to add the preconsultation id
 *
 * @param {string} patientID
 * @param {string} practitionerID
 * @param {CodeableConcept} type
 * @param {string} preconsultationId to tell whether the encounter is partOf another one or not
 * @returns {{payload: {Object}, type: string}}
 */
export const createEncounter = (
  patientID,
  practitionerID,
  type,
  partOfencounterID
) => ({
  type: STARTING_ENCOUNTER,
  payload: {
    patientID,
    practitionerID,
    type,
    partOfencounterID
  }
})

// Create special function in order to start Bluetooth when you are doing a clinical exam and not any other encounter
export const createBluetoothEncounter = (patientID, practitionerID, type) => ({
  type: STARTING_BLUETOOTH_ENCOUNTER,
  payload: {
    patientID,
    practitionerID,
    type
  }
})

export const stopEncounter = (
  encounterID,
  patientID,
  organizationId,
  references = []
) => ({
  type: STOPPING_ENCOUNTER,
  payload: {
    encounterID,
    patientID,
    organizationId,
    references
  }
})

export const stopPreTeleconsultationEncounter = (encounterID, patientID) => ({
  type: STOPPING_PRETELECONSULTATION_ENCOUNTER,
  payload: {
    encounterID,
    patientID
  }
})

export const cancelEncounter = encounter => ({
  type: CANCELING_ENCOUNTER,
  payload: encounter
})

export const updateEncounter = encounter => ({
  type: UPDATING_ENCOUNTER,
  payload: encounter
})
export const selectEncounter = encounterID => ({
  type: ENCOUNTER_SELECTED,
  payload: { encounterID }
})
export const deselectEncounter = encounterID => ({
  type: ENCOUNTER_DESELECTED,
  payload: { encounterID }
})

export const getInitialEncounterPage = pagination.start(
  GET_INITIAL_ENCOUNTER_PAGE
)

export const getTotalEncounter = subjectId => ({
  type: GET_TOTAL_ENCOUNTER,
  payload: subjectId
})

export const saveEncounterSequence = (
  patientID,
  practitionerID,
  answers,
  actions,
  planDefinitionId,
  performer
) => ({
  type: SAVING_ENCOUNTER_SEQUENCE,
  payload: {
    patientID,
    practitionerID,
    answers,
    actions,
    planDefinitionId,
    performer
  }
})

export const loadMoreEncounters = pagination.loadMore(LOAD_MORE_ENCOUNTERS)
export const encounterPageChanged = pagination.loadedMore(
  ENCOUNTER_PAGE_CHANGED
)
export const clearEncounters = pagination.clear(CLEARING_ENCOUNTER_PAGE)

export const encountersPaginationStale = pagination.stale(STALE_ENCOUNTER_PAGE)
