import React, { useState, createContext } from 'react'

export const CurrentPractitionerRoleContext = createContext([null, () => {}])

const CurrentPractitionerRoleProvider = ({ children, initialState = null }) => {
  const [currentPractitionerRole, setCurrentPractitionerRole] = useState(
    initialState
  )
  return (
    <CurrentPractitionerRoleContext.Provider
      value={[currentPractitionerRole, setCurrentPractitionerRole]}
    >
      {children}
    </CurrentPractitionerRoleContext.Provider>
  )
}
export default CurrentPractitionerRoleProvider
