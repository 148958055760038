import React, { ChangeEvent, FC, useContext } from 'react'
import {
  allAdministrationRoles,
  getMedeoRoleFromGouvCode
} from '../../Permissions/roles'
import { t, Trans } from '@lingui/macro'
import { EditPractitionerContext } from '../container/EditPractitionerForm'
import BetterSelect from '../../Shared/components/BetterSelect'
import RequiredLabel from '../../Shared/components/RequiredLabel'
import useTranslateRole from '../useTranslateRole'
import { useI18n } from '../../utils/I18nHookAdapter'
import { Practitioner, PractitionerRole } from 'fhir-stu3'

type ContextResource = {
  administrationRole: PractitionerRole
  practitioner: Practitioner
}
type EditContextType = [
  ContextResource,
  (
    resource: ContextResource | ((r: ContextResource) => ContextResource)
  ) => void
]
const Option: FC<{ role: string }> = ({ role }) => {
  const trans = useTranslateRole(role)
  return <option value={role}>{trans}</option>
}

const AdministrationRoleInput: FC<{ readOnly: boolean }> = ({ readOnly }) => {
  const [resource, setResource] = useContext<EditContextType>(
    EditPractitionerContext
  )
  const i18n: any = useI18n()

  const specialtyCode =
    resource.administrationRole?.specialty?.[0]?.coding?.[0]?.code

  const handleChangeCode = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    setResource((r: any) => ({
      ...r,
      administrationRole: {
        ...r.administrationRole,
        specialty: [
          {
            coding: [
              {
                system:
                  'http://medeo.io/fhir/PractitionerRole/administration-medeo',
                code: value,
                display: getMedeoRoleFromGouvCode(value).props.id
              }
            ]
          }
        ]
      }
    }))
  }

  return (
    <div>
      <RequiredLabel required>
        <Trans>Role</Trans>
      </RequiredLabel>
      <BetterSelect
        name="administration"
        defaultValue={specialtyCode}
        readOnly={readOnly}
        required
        onChange={handleChangeCode}
      >
        <option value="">{i18n._(t`Select an option`)}</option>
        {allAdministrationRoles.map(role => (
          <Option role={role} key={role} />
        ))}
      </BetterSelect>
    </div>
  )
}

export default AdministrationRoleInput
