import React, { useContext, useEffect, useState } from 'react'
import { useEffectOnce } from 'react-use'
import { Input } from '../../Components'
import { getDisplay, onChangeQuestionnaire, getItemByLinkId } from '../utils'
import { QuestionnaireResponseContext } from './Questionnaire'

const QuestionnaireDate = ({ item, readOnly, defaultValue }) => {
  const [questionnaireResponse, dispatchQuestionnaireResponse] = useContext(
    QuestionnaireResponseContext
  )
  const [inputDefaultValue, setInputDefaultValue] = useState(null)
  const label = getDisplay(item)
  let maxDate = '9999-12-31'
  const code = item?.code?.find(
    c => c.system === 'http://medeo.io/fhir/Code/questionnaire-codes'
  )?.code

  if (code === 'date-not-in-future') {
    const isoDate = new Date().toISOString()
    maxDate = isoDate.split('T')[0]
  }
  const definition = item.definition
  const isTimePicker = definition === 'timePicker'

  useEffectOnce(() => {
    // In case we have a default value, we save it on the first render
    // as otherwise no value will be saved at all if it does not get changed
    if (defaultValue != null) {
      onChangeQuestionnaire({
        questionnaireResponse: questionnaireResponse,
        dispatchQuestionnaireResponse: dispatchQuestionnaireResponse,
        name: item.linkId,
        value: defaultValue,
        type: isTimePicker ? 'time' : 'date'
      })
    }
  })

  useEffect(() => {
    setInputDefaultValue(getItemByLinkId(questionnaireResponse, item.linkId))
  }, [questionnaireResponse, item])
  if (
    inputDefaultValue &&
    inputDefaultValue.answer &&
    inputDefaultValue.answer[0] &&
    inputDefaultValue.answer[0].valueDate
  )
    {defaultValue = inputDefaultValue?.answer?.[0]?.valueDate}

  return isTimePicker ? (
    <Input
      label={label}
      name={item.linkId}
      data-test={item.linkId}
      type="time"
      required={item.required}
      readOnly={readOnly}
      onChange={e => {
        onChangeQuestionnaire({
          questionnaireResponse: questionnaireResponse,
          dispatchQuestionnaireResponse: dispatchQuestionnaireResponse,
          name: e.target.name,
          value: e.target.value,
          type: e.target.type
        })
      }}
      className="fs-block"
    />
  ) : (
    <Input
      label={label}
      name={item.linkId}
      data-test={item.linkId}
      type="date"
      required={item.required}
      readOnly={readOnly}
      defaultValue={defaultValue}
      min="1880-01-01"
      max={maxDate}
      onChange={e => {
        onChangeQuestionnaire({
          questionnaireResponse: questionnaireResponse,
          dispatchQuestionnaireResponse: dispatchQuestionnaireResponse,
          name: e.target.name,
          value: e.target.value,
          type: e.target.type
        })
      }}
      className="fs-block"
    />
  )
}

export default QuestionnaireDate
