import React from 'react'
import styled from 'styled-components/macro'
import { getDisplayInfoFromLoincCode } from '../../utils/loinc'
import ValueQuantityView from './ValueQuantityView'
import ValueStringView from './ValueStringView'
import InterpretationView from './InterpretationView'

const View = styled.div`
  margin-bottom: 1em;
`
const Type = styled.div`
  width: 2rem;
  box-sizing: content-box;
  text-align: center;
  margin-top: ${props => props.theme.spacing.small};
  padding-right: ${props => props.theme.spacing.medium};
  font-size: ${props => props.theme.small};
`

const Down = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`

const UrinalysisValueQuantityView = ({ observation }) => {
  if (observation.component != null) {
    return (
      <div>
        {observation.component.map((value, i) =>
          value.valueQuantity != null ? (
            <View key={i}>
              <Type>
                {
                  getDisplayInfoFromLoincCode(value.code.coding[0].code)
                    .children
                }
              </Type>
              <Down>
                <InterpretationView observation={value} />
                <ValueQuantityView
                  valueQuantity={{
                    value: value.valueQuantity.value,
                    unit: value.valueQuantity.unit,
                  }}
                />
              </Down>
            </View>
          ) : (
            <View key={i}>
              <Type>
                {
                  getDisplayInfoFromLoincCode(value.code.coding[0].code)
                    .children
                }
              </Type>
              <Down>
                <InterpretationView observation={value} />
                <ValueStringView valueString={value.valueString} />
              </Down>
            </View>
          )
        )}
      </div>
    )
  }
  return null
}

export default UrinalysisValueQuantityView
