import React, { useReducer, useEffect } from 'react'
import { Trans } from '@lingui/macro'
import styled from 'styled-components/macro'
import { Button } from '../../Components'
import { Link } from '@reach/router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-regular-svg-icons'
import {
  displayManagingOrganization,
  displayPhoneNumber,
  displayNir
} from '../../Shared/display/entity'
import RequirePermission from '../../Permissions/containers/RequirePermission'
import CollapseButton from '../../Encounter/containers/CollapseButton'
import GeneralPractitionerSectionPart from './GeneralPractitionerSectionPart'
import MedicationList from '../../Medication/containers/MedicationList'
import { search } from '../../Shared/actions'
import TagView from '../../Shared/components/TagView'
import { useDispatch, useSelector } from 'react-redux'
import { I18n } from '@lingui/react'
import ReactTooltip from 'react-tooltip'
import CopyableDiv from './CopyablePatientDetails'
import mixpanel, { MIXPANEL_EDITING_PATIENT } from '../../Shared/mixpanel'
import { getAllergiesBypatientID } from '../../Allergy/ducks'
import {
  getFamilyHistoryBypatientID,
  getMedicalHistoryBypatientID,
  getSurgicalHistoryBypatientID
} from '../../Condition/ducks'

const PatientInfo = styled.div`
  font-size: 14px;
`

const Section = styled.div``

const SeeMore = styled.div`
  color: ${props => props.theme.ocean};
`

const SectionHeader = styled.div`
  font-weight: ${props => props.theme.bold};
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  padding: ${props => props.theme.spacing.small} 0;
`

export const Label = styled.div`
  color: #77808e;
  margin-bottom: 0.25rem;
`
const CollapseInfoButton = styled(CollapseButton)`
  cursor: pointer;
`

export const SectionPart = styled.div`
  margin-top: ${props => props.theme.spacing.small};
  overflow-wrap: break-word;
`

const InformationArea = styled.div`
  display: ${props => (props.collapsed ? 'none' : 'block')};
  margin-top: -0.5rem;
  margin-bottom: 1rem;
  font-size: small;
`

// initialState of the info true means expanded and false collapsed
// by default everything is expanded.
const initialState = {
  information: false, // basic info
  gp: false, // medecin traitant
  allergies: false,
  medication: false, // medication statement
  medicalHistory: false,
  surgicalHistory: false,
  familyHistory: false
}
const reducer = (state, action) => {
  const { type, payload } = action
  // very simple reducer, expect only one action looking like:
  // {
  //    type: "toggle",
  //    payload: "gp"
  //}
  if (type === 'toggle') {return { ...state, [payload]: !state[payload] }}
  return state
}

const PatientInfoWrapper = ({ patient }) => {
  const reduxDispatch = useDispatch()
  const [state, dispatch] = useReducer(reducer, initialState)
  const patientID = patient?.id
  const toggle = name => dispatch({ type: 'toggle', payload: name })

  useEffect(() => {
    reduxDispatch(
      search('AllergyIntolerance', {
        patient: patientID
      })
    )
    reduxDispatch(
      search('Condition', {
        subject: 'Patient/' + patientID
      })
    )
  }, [patientID, reduxDispatch])

  const allergies = useSelector(getAllergiesBypatientID(patientID))

  const medicalHistory = useSelector(getMedicalHistoryBypatientID(patientID))

  const surgicalHistory = useSelector(getSurgicalHistoryBypatientID(patientID))

  const familyHistory = useSelector(getFamilyHistoryBypatientID(patientID))

  return patient !== undefined ? (
    <PatientInfo>
      <Section>
        <SectionHeader onClick={() => toggle('information')}>
          <Trans>Information</Trans>
        </SectionHeader>
        {patient.managingOrganization != null && (
          <SectionPart>{displayManagingOrganization(patient)}</SectionPart>
        )}
        {patient.identifier != null && (
          <SectionPart>
            <Label>
              <Trans>Social security number</Trans>
            </Label>
            <CopyableDiv>{displayNir(patient)}</CopyableDiv>
          </SectionPart>
        )}
        <SectionPart>
          <Label>
            <Trans>Phone number</Trans>
          </Label>
          <CopyableDiv>{displayPhoneNumber(patient)}</CopyableDiv>
        </SectionPart>
        {patient.address?.[0] && (
          <SectionPart>
            <Label>
              <Trans>Address</Trans>
            </Label>
            <div>
              <div>{patient.address?.[0]?.line}</div>
              <div>
                {patient.address?.[0]?.postalCode} {patient.address?.[0]?.city}
              </div>
            </div>
          </SectionPart>
        )}
        <GeneralPractitionerSectionPart patient={patient} />

        <SectionPart>
          <Link
            to={`/patient/${patient.id}/show`}
            style={{
              marginTop: '1rem',
              display: 'block',
              color: '#0967D2',
              textDecoration: 'none'
            }}
          >
            <SeeMore>
              <Trans>See more</Trans>...
            </SeeMore>
          </Link>
        </SectionPart>
      </Section>
      <Section>
        <SectionHeader onClick={() => toggle('medication')}>
          <Trans>Medical Statements</Trans>
          <CollapseInfoButton collapsed={state.medication} />
        </SectionHeader>
        <InformationArea collapsed={state.medication}>
          <SectionPart>
            <MedicationList patientID={patient.id} />
          </SectionPart>
        </InformationArea>
      </Section>
      <Section>
        <SectionHeader onClick={() => toggle('allergies')}>
          <Trans>Allergies and intolerances</Trans>
          <CollapseInfoButton collapsed={state.allergies} />
        </SectionHeader>
        <InformationArea collapsed={state.allergies}>
          <SectionPart>
            <TagView resource={allergies} type={'allergy'} />
          </SectionPart>
        </InformationArea>
      </Section>
      <Section>
        <SectionHeader onClick={() => toggle('medicalHistory')}>
          <Trans>Medical history</Trans>
          <CollapseInfoButton collapsed={state.medicalHistory} />
        </SectionHeader>
        <InformationArea collapsed={state.medicalHistory}>
          <SectionPart>
            <TagView resource={medicalHistory} type={'conditions'} />
          </SectionPart>
        </InformationArea>
      </Section>
      <Section>
        <SectionHeader onClick={() => toggle('surgicalHistory')}>
          <Trans>Surgical and obstetric history</Trans>
          <CollapseInfoButton collapsed={state.surgicalHistory} />
        </SectionHeader>
        <InformationArea collapsed={state.surgicalHistory}>
          <SectionPart>
            <TagView resource={surgicalHistory} type={'conditions'} />
          </SectionPart>
        </InformationArea>
      </Section>
      <Section>
        <SectionHeader onClick={() => toggle('familyHistory')}>
          <Trans>Family history</Trans>
          <CollapseInfoButton collapsed={state.familyHistory} />
        </SectionHeader>
        <InformationArea collapsed={state.familyHistory}>
          <SectionPart>
            <TagView resource={familyHistory} type={'conditions'} />
          </SectionPart>
        </InformationArea>
      </Section>
      <Section>
        <SectionPart>
          <RequirePermission resource={'patient'} action={'add'}>
            <I18n>
              {({ i18n }) => (
                <Link
                  to={`/patient/${patient.id}/edit`}
                  data-tip={i18n._('Edit patient')}
                >
                  <Button
                    color="ocean"
                    variant="outline"
                    onClick={() => {
                      mixpanel.track(MIXPANEL_EDITING_PATIENT)
                    }}
                  >
                    <FontAwesomeIcon icon={faEdit} />
                    <ReactTooltip effect="solid" place="right" />
                  </Button>
                </Link>
              )}
            </I18n>
          </RequirePermission>
        </SectionPart>
      </Section>
    </PatientInfo>
  ) : (
    <div>Aucune information sur le patient</div>
  )
}

export default PatientInfoWrapper
