import { ThemeProvider } from '@mui/material'
import CssBaseline from '@mui/material/CssBaseline'
import React from 'react'
import theme from './muiTheme'

// replace @medeo/component and theme with react material components
// which provide a more stable component library than our homemade one.

const MUIThemeProvider = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      {children} <CssBaseline />
    </ThemeProvider>
  )
}

export default MUIThemeProvider
