import { Trans, t } from '@lingui/macro'
import { Button } from '../../Components'
import React from 'react'
import Modal from '../../Shared/components/Modal'
import MediaDevices from './MediaDevices'
import { useI18n } from '../../utils/I18nHookAdapter'
import { CLOSING_MODAL } from '../reducer'

const MediaDevicesModal = ({ open, dispatch }) => {
  const i18n = useI18n()
  const handleClose = () => {
    dispatch({ type: CLOSING_MODAL })
  }
  return (
    <Modal
      open={open}
      title={<Trans>Video-conference parameters</Trans>}
      component={({ onClick }) => (
        <Button
          variant="outline"
          color="ocean"
          style={{ fontSize: 'small' }}
          data-tooltip={i18n._(t`Video parameters`)}
          onClick={onClick}
        >
          <Trans>Medical devices</Trans>
        </Button>
      )}
    >
      <MediaDevices onAfterSubmit={handleClose} />
    </Modal>
  )
}
export default MediaDevicesModal
