export const FETCHING_MEDICATION_STATEMENTS = 'FETCHING_MEDICATION_STATEMENTS'
export const UPDATING_MEDICATION_STATEMENTS = 'UPDATING_MEDICATION_STATEMENTS'
export const UPDATING_MEDICATION_STATEMENT = 'UPDATING_MEDICATION_STATEMENT'

/**
 * This action is called form the MedicationStatementForm and should trigger
 * sagas in Medication and in MedicationStatement
 * c.f. these sagas for more details
 * @type {{addedMedications: *, deletedStatements: *, patient}}
 */
export const updateMedicationStatements = ({
  addedMedications = [],
  deletedStatements = [],
  patient,
}) => ({
  type: UPDATING_MEDICATION_STATEMENTS,
  payload: {
    addedMedications,
    deletedStatements,
    patient,
  },
})
