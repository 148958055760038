import { Button, Card, Form, Input } from '../../Components'
import { Trans } from '@lingui/macro'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components/macro'
import { set } from '../../utils/fhirpath'
import { updateOrganization } from '../actions'
import { getAdministratorRightBypractitionerID } from '../../PractitionerRole/selector'
import { MEDEO_ADMINISTRATOR_CODE } from '../../utils/codes'
import { getCurrentOrganization } from '../selector'
import usePractitioner from '../../Practitioner/usePractitioner'

const FormHeader = styled.h1`
  font-weight: bold;
  font-size: larger;
  border-bottom: 1px solid ${p => p.theme.gray};
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
`

const EditOrganizationForm = () => {
  const dispatch = useDispatch()
  const currentPractitioner = usePractitioner()
  const isAdmin = useSelector(s =>
    getAdministratorRightBypractitionerID(
      s,
      currentPractitioner.id,
      MEDEO_ADMINISTRATOR_CODE
    )
  )
  const organization = useSelector(getCurrentOrganization)
  const [resource, setResource] = useState(organization)
  const [organizationAddress] = resource.address ?? []
  const [line] = organizationAddress?.line ?? []
  const postalCode = organizationAddress?.postalCode
  const city = organizationAddress?.city
  const country = organizationAddress?.country
  const organizationContact = resource.telecom
  const phone = organizationContact?.find(({ system }) => system === 'phone')
    ?.value
  const mail = organizationContact?.find(({ system }) => system === 'email')
    ?.value

  const handleAddressChange = e => {
    const { value, name } = e.currentTarget
    if (name === 'line') {
      setResource(set(resource, `address.item(0).line.item(0)`, value))
    } else {
      setResource(set(resource, `address.item(0).${name}`, value))
    }
  }

  const handleSubmit = e => {
    e.preventDefault()
    dispatch(updateOrganization(resource))
  }
  return isAdmin ? (
    <Card>
      <FormHeader>
        <Trans>My organization</Trans>
      </FormHeader>
      <Form id="editOrganization" name="editOrganization">
        <Form.Row>
          <Input
            label={<Trans>Organization's name</Trans>}
            name="name"
            defaultValue={resource.name}
            readOnly={true}
          />
        </Form.Row>
        <Form.Row>
          <Input
            label={<Trans>phone</Trans>}
            data-test="phone"
            name="phone"
            defaultValue={phone}
            type="tel"
            maxLength="10"
            minLength="10"
            onChange={e => {
              const { value } = e.currentTarget
              if (organizationContact == null) {
                setResource({
                  ...resource,
                  telecom: [{ system: 'phone', value: value }]
                })
              } else if (
                organizationContact.some(({ system }) => system === 'phone')
              ) {
                organizationContact.find(
                  ({ system }) => system === 'phone'
                ).value = value
                setResource(resource)
              } else {
                organizationContact.push({ system: 'phone', value: value })
                setResource(resource)
              }
            }}
          />
          <Input
            label={<Trans>Email</Trans>}
            name="mail"
            defaultValue={mail}
            required={true}
            onChange={e => {
              const { value } = e.currentTarget
              if (organizationContact == null) {
                setResource({
                  ...resource,
                  telecom: [{ system: 'email', value: value }]
                })
              } else if (
                organizationContact.some(({ system }) => system === 'email')
              ) {
                organizationContact.find(
                  ({ system }) => system === 'email'
                ).value = value
                setResource(resource)
              } else {
                organizationContact.push({ system: 'email', value: value })
                setResource(resource)
              }
            }}
          />
        </Form.Row>
        <Form.Row>
          <Input
            name="line"
            label={<Trans>Street</Trans>}
            defaultValue={line}
            onChange={handleAddressChange}
          />
          <Input
            name="postalCode"
            label={<Trans>Postal code</Trans>}
            defaultValue={postalCode}
            onChange={handleAddressChange}
          />
        </Form.Row>
        <Form.Row>
          <Input
            name="city"
            label={<Trans>City</Trans>}
            defaultValue={city}
            onChange={handleAddressChange}
          />
          <Input
            name="country"
            label={<Trans>Country</Trans>}
            defaultValue={country}
            onChange={handleAddressChange}
          />
        </Form.Row>
        <footer>
          <Button onClick={handleSubmit}>
            <Trans>Save</Trans>
          </Button>
        </footer>
      </Form>
    </Card>
  ) : (
    <p>
      <Trans>
        Ooops it seems like you are not an administrator and you cannot see this
        page
      </Trans>
    </p>
  )
}

export default EditOrganizationForm
