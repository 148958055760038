import React from 'react'
import { Trans } from '@lingui/macro'
import styled from 'styled-components/macro'

const Header = styled.header`
  font-size: small;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  padding: 0.5rem;

  // in the inline input is not shown
  // we want to have some padding for the default div...
  & > div {
    padding: 0.25rem;
  }
`

const InlineInput = styled.input.attrs({
  type: 'text',
})`
  background: transparent;
  border: none;
  font-size: small;
  padding: 0.25rem;
  width: 100%;
  line-height: initial; // to prevent characters from being cut
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;

  &:disabled {
    color: ${p => p.theme.black};
  }
  &:not(:disabled):focus,
  &:not(:disabled):hover {
    background: ${p => p.theme.white};
  }
`

/**
 * Shows the title of the current attachment in an <input type="text"/>
 * When the user hovers this component it will be highlighted.
 * the onChange handler is called when the user change the name
 *
 * Note: the title of the attachment is not necessarily the name of the file
 *
 * @param attachment
 * @param onChange
 * @returns {*}
 * @constructor
 */
const AttachmentHeader = ({ attachment, onChange }) => {
  return attachment != null ? (
    <Header title={attachment.title}>
      {/*
       disabled is temporary fixed to true here because we don't handle
       attachment title update yet. You can remove the disabled once this
       feature is implemented.
      */}
      <InlineInput value={attachment.title} onChange={onChange} disabled />
    </Header>
  ) : (
    <Header>
      <div>
        <Trans>loading</Trans>
      </div>
    </Header>
  )
}

AttachmentHeader.defaultProps = {
  onChange: () => {},
}

export default AttachmentHeader
