import React from 'react';

import TeleconsulationEventDetails from './TeleconsultationEventDetails';

const EventDetails = ({ forwaredRef, position, calendarType }) => {
  return (
    <TeleconsulationEventDetails
      forwaredRef={forwaredRef}
      position={position}
      calendarType={calendarType}
    />
  );
};

export default EventDetails;
