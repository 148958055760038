import React, { useContext, useState } from 'react'
import { useSelector } from 'react-redux'
import { Trans } from '@lingui/macro'
import { Button, Form, Input } from '../../Components'
import { EditPractitionerContext } from '../container/EditPractitionerForm'
import {
  ESANTE_OCCUPATION_SYSTEM,
  MEDEO_AM_IDENTIFIER_SYSTEM,
  MEDEO_FINESS_IDENTIFIER_SYSTEM,
  MEDEO_RPPS_IDENTIFIER_SYSTEM,
  MEDEO_STRIPE_IDENTIFIER_SYSTEM
} from '../../utils/codes'
import { pushIdentifierChange } from '../utils'
import { getPractitionerRoleBypractitionerID } from '../../PractitionerRole/selector'
import {
  GENERAL_PRACTITIONER_ESANTE,
  getSpecialtyCodingFromSystem
} from '../../Permissions/roles'
import ThirdPartyPayerSelect from './ThirdPartyPayerSelect'

const initIdentifierInputs = (identifiers = []) => {
  const defaultInputs = [
    {
      label: 'N° RPPS',
      name: 'RPPS',
      value: identifiers.find(
        identifier => identifier.system === MEDEO_RPPS_IDENTIFIER_SYSTEM
      )?.value
    },
    {
      label: 'Id Stripe',
      name: 'stripe',
      value: identifiers.find(
        identifier => identifier.system === MEDEO_STRIPE_IDENTIFIER_SYSTEM
      )?.value
    }
  ]

  // Checks whether the practitioner has a FINESS or AM identifier
  // if they do it will add it to the array of inputs
  identifiers.forEach(identifier => {
    if (identifier.system === MEDEO_FINESS_IDENTIFIER_SYSTEM) {
      defaultInputs.push({
        label: 'FINESS',
        name: 'FINESS',
        value: identifier.value
      })
    } else if (identifier.system === MEDEO_AM_IDENTIFIER_SYSTEM) {
      defaultInputs.push({
        label: 'AM',
        name: 'AM',
        value: identifier.value
      })
    }
  })

  return defaultInputs
}

const IdentifierInputs = () => {
  const [resource, setResource] = useContext(EditPractitionerContext)
  const [showButton, setShowButton] = useState(false)

  // Initiate the identifiers text inputs
  const [identifierInputs, setIdentifierInputs] = useState(
    initIdentifierInputs(resource?.practitioner?.identifier)
  )

  const identifiers = resource?.practitioner?.identifier ?? []

  // Check whether the current practitioner is a doctor or not
  const [medicalRole] = useSelector(s =>
    getPractitionerRoleBypractitionerID(
      s,
      resource?.practitioner?.id,
      'medical'
    )
  )
  const isDoctor =
    getSpecialtyCodingFromSystem(medicalRole, ESANTE_OCCUPATION_SYSTEM)
      ?.code === GENERAL_PRACTITIONER_ESANTE

  const handleChange = e => {
    const { value, name } = e.currentTarget

    const index = identifiers.findIndex(i => {
      return i.type?.coding?.[0]?.code === name
    })
    if (index >= 0) {
      identifiers[index].value = value
    } else {
      pushIdentifierChange({ identifiers, name, value })
    }
    setResource({
      ...resource,
      practitioner: {
        ...resource.practitioner,
        identifier: identifiers
      }
    })
  }

  const handleInputCreation = (e, inputName) => {
    e.preventDefault()
    if (inputName === 'finess') {
      const updatedInputs = identifierInputs.concat({
        label: 'FINESS',
        name: 'FINESS',
        value: ''
      })
      setIdentifierInputs(updatedInputs)
    } else {
      const updatedInputs = identifierInputs.concat({
        label: 'AM',
        name: 'AM',
        value: ''
      })
      setIdentifierInputs(updatedInputs)
    }
    setShowButton(false)
  }

  // if practitioner was created through the CLI it will return true
  // if is false, this condition will be used to show a button to create the input
  const hasActivityIdentifier = identifierInputs.find(i => {
    return i.name === 'FINESS' || i.name === 'AM'
  })

  return (
    <>
      {identifierInputs.map((input, i) => (
        <Form.Row key={i}>
          <Input
            name={input.name}
            label={input.label}
            defaultValue={input.value}
            onChange={handleChange}
            data-intercom-target={input.label}
            data-test={input.name}
          />
        </Form.Row>
      ))}
      {!hasActivityIdentifier && !showButton && (
        <Form.Row>
          <Button variant="outline" onClick={() => setShowButton(!showButton)}>
            <Trans>Add FINESS or AM code</Trans>
          </Button>
        </Form.Row>
      )}
      {showButton && (
        <Form.Row>
          <Button onClick={e => handleInputCreation(e, 'finess')}>
            N° FINESS
          </Button>
          <Button onClick={e => handleInputCreation(e, 'am')}>N° AM</Button>
        </Form.Row>
      )}

      {/* Display third party payer options for doctors only*/}
      {isDoctor && <ThirdPartyPayerSelect />}
    </>
  )
}

export default IdentifierInputs
