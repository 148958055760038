import React, { useReducer, useRef, useEffect, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components/macro'
import { v4 as uuid } from 'uuid'
import { faDownload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Trans } from '@lingui/macro'
import { Button, Label } from '../../Components'
import { QuestionnaireResponseContext } from './Questionnaire'
import * as fromDocumentReferences from '../../DocumentReference/selectors'
import { createBinary } from '../../DocumentReference/actions'
import { downloadBase64 } from '../../utils/browser'
import {
  getBinary,
  getBinaryIdFromReferenceUrl
} from '../../Shared/fhir/getBinary'
import { onChangeQuestionnaire, getItemByLinkId } from '../utils'
import { useToken } from '../../Attachment/utils'

const HiddenInput = styled.input.attrs({ type: 'file' })`
  display: none;
`

const Feedback = styled.span``

const Container = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
`
const Header = styled.div`
  margin-bottom: 0.5rem;
`

const Title = styled.div`
  margin: 1rem 0;
`

const Padding = styled.div`
  padding: 0 0.4rem;
  display: inline;
`

/*
 * Upload directly the binary
 */

const initialState = {
  message: null,
  shouldDisplayFeedBack: false,
  isLoading: false,
  file: null,
  id: null
}

const reducer = (state, action) => {
  const { type, payload } = action
  switch (type) {
    case 'uploading':
      return {
        ...state,
        ...payload,
        isLoading: true,
        shouldDisplayFeedback: true,
        message: <Trans>waiting</Trans>
      }
    case 'uploaded':
      return {
        ...state,
        isLoading: false,
        message: state.file.name
      }

    default:
      return state
  }
}

const ReadOnlyComponent = ({ doc, onClick }) => {
  // if there is no document, simply return an empty component
  let titleOfDocument = 'Document'
  if (doc != null) {
    titleOfDocument = doc.title
    if (titleOfDocument.length > 70)
      {titleOfDocument = `${titleOfDocument.substring(
        0,
        65
      )} [...] ${titleOfDocument.substring(
        titleOfDocument.length - 4,
        titleOfDocument.length
      )}`}
  }
  return (
    doc != null && (
      <Container>
        <Header>
          <Trans>Document</Trans>
        </Header>
        <Title data-tooltip={doc.title}>{titleOfDocument}</Title>
        <div>
          <Button color="ocean" onClick={onClick}>
            <FontAwesomeIcon icon={faDownload} />
            <Padding />
            <Trans>Download</Trans>
          </Button>
        </div>
      </Container>
    )
  )
}

/**
 * @deprecated
 * @param item
 * @param response
 * @param readOnly
 * @returns {*}
 * @constructor
 */
const QuestionnaireFile = ({ item, readOnly }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const [questionnaireResponse, dispatchQuestionnaireResponse] = useContext(
    QuestionnaireResponseContext
  )

  // let readOnly = false
  // we suppose to get readonly in the context
  const input = useRef(null)
  const docRef = useRef(null)
  const id = useRef(uuid())
  const reduxDispatch = useDispatch()
  const isBinaryCreated = useSelector(s =>
    fromDocumentReferences.isBinaryCreated(s, id.current)
  )

  docRef.current = getItemByLinkId(
    questionnaireResponse,
    item.linkId
  )?.answer?.[0]?.valueAttachment

  useEffect(() => {
    if (isBinaryCreated === true) {
      dispatch({ type: 'uploaded' })
    }

    const url = `urn:uuid:${state.id}`
    const file = state.file

    if (file != null) {
      docRef.current = {
        title: file.name,
        url,
        contentType: file.type,
        creation: new Date().toISOString()
      }
      onChangeQuestionnaire({
        questionnaireResponse: questionnaireResponse,
        dispatchQuestionnaireResponse: dispatchQuestionnaireResponse,
        name: item.linkId,
        value: docRef.current,
        type: 'file'
      })
    }

    // eslint-disable-next-line
  }, [isBinaryCreated])
  const token = useToken()

  const handleClick = e => {
    e.preventDefault()
    if (input.current != null) {
      input.current.click()
    }
  }

  const handleChange = e => {
    const [file] = e.target.files
    if (file == null) {
      console.warn('no file')
      return
    }
    reduxDispatch(createBinary(file, id.current))
    dispatch({ type: 'uploading', payload: { file, id: id.current } })
  }

  const onClick = async e => {
    e.preventDefault()
    if (docRef.current != null) {
      const binary = await getBinary(
        getBinaryIdFromReferenceUrl(docRef.current.url),
        token
      )
      downloadBase64(docRef.current.title, binary.data, binary.contentType)
    }
  }

  let titleOfDocument
  if (state.message != null) {
    if (state.message.length > 50)
      {titleOfDocument = `${state.message.substring(
        0,
        45
      )} [...] ${state.message.substring(
        state.message.length - 4,
        state.message.length
      )}`}
  }
  return docRef.current != null || readOnly === true ? (
    <ReadOnlyComponent doc={docRef.current} onClick={onClick} />
  ) : (
    <div>
      <Label>{item.text}</Label>
      <Button
        variant="outline"
        color="ocean"
        onClick={handleClick}
        isDisabled
        required={item.required}
      >
        <Trans>Choose a file</Trans>
      </Button>
      {state.shouldDisplayFeedback === true && (
        <Feedback data-tooltip={state.message}>
          {titleOfDocument != null ? titleOfDocument : state.message}
        </Feedback>
      )}
      <HiddenInput ref={input} onChange={handleChange} />
    </div>
  )
}

export default QuestionnaireFile
