import { useEffect } from 'react'
import styled from 'styled-components'
import { t, Trans } from '@lingui/macro'
import { I18n } from '@lingui/react'
import { Form, Input } from '../../Components'
import RadioGroup from '../../Shared/components/RadioGroup'
import cloneDeep from 'lodash/cloneDeep'
import useBookingContext from '../containers/BookingContext'

const initialPatient = {
  resourceType: 'Patient',
  identifier: [
    {
      type: {
        coding: [
          {
            system:
              'http://interopsante.org/fhir/valueset/fr-patient-identifier-type',
            version: '1',
            code: 'INS-NIR',
            display: 'NIR'
          }
        ]
      },
      system: 'http://medeo.io/fhir/Identifier/patient-nir',
      value: null
    }
  ],
  gender: null,
  name: [
    {
      use: 'usual',
      family: '',
      given: ['']
    }
  ],
  telecom: [
    {
      system: 'email',
      value: null
    },
    {
      system: 'phone',
      value: null,
      use: 'mobile'
    }
  ],
  birthDate: null
}

const updatePatient = (patient, input, type) => {
  const updatedPatient = cloneDeep(patient)

  switch (type) {
    case 'family':
      updatedPatient.name[0].family = input
      break
    case 'given':
      updatedPatient.name[0].given = [input]
      break
    case 'email':
      updatedPatient.telecom.find(
        telecom => telecom.system === 'email'
      ).value = input
      break
    case 'phone':
      updatedPatient.telecom.find(
        telecom => telecom.system === 'phone'
      ).value = input
      break
    case 'nir':
      updatedPatient.identifier[0].value = input
      break
    case 'birthDate':
      updatedPatient.birthDate = input
      break
    case 'gender':
      updatedPatient.gender = input
      break
    default:
      break
  }

  return updatedPatient
}

const Section = styled.div`
  margin: 2rem 0;
  border-top: 1px solid ${p => p.theme.gray};
`

const Indication = styled.div`
  font-size: ${p => p.theme.small};
  margin-bottom: 1rem;
`

const SimpleAddPatient = ({ onChange }) => {
  const { state, handleChangePatient } = useBookingContext()
  const patient = state.patient || initialPatient
  const handleChange = e => {
    const updatedPatient = updatePatient(patient, e.target.value, e.target.name)
    handleChangePatient(updatedPatient)
  }

  useEffect(() => {
    // here we want to enable the user to go next only when either the email or
    // the phone was defined
    const cannotGoNext =
      patient == null ||
      patient.telecom.find(
        telecom => telecom.value != null && telecom.value !== ''
      ) == null
    onChange({ patient: patient, cannotGoNext: cannotGoNext })
  }, [patient, onChange])

  return (
    <I18n>
      {({ i18n }) => (
        <>
          <Section>
            <h4>
              <Trans>Patient Information</Trans>
            </h4>
            <Form.Row>
              <Input
                label={i18n._(t`First Name`)}
                name="given"
                data-test="given-name"
                onChange={handleChange}
                defaultValue={patient?.name?.[0]?.given?.[0]}
                required
              />
              <Input
                label={i18n._(t`Name`)}
                name="family"
                data-test="family"
                onChange={handleChange}
                defaultValue={patient?.name?.[0]?.family}
                required
              />
            </Form.Row>
            <Form.Row>
              <Input
                label={i18n._(t`Date of Birth`)}
                name="birthDate"
                data-test="birthDate"
                type="date"
                max="9999-12-31"
                onChange={handleChange}
                defaultValue={patient.birthDate}
                required
              />
              <RadioGroup required={true} label="Genre">
                <Input
                  type="radio"
                  value="female"
                  name="gender"
                  data-test="gender-female"
                  label={i18n._(t`Female`)}
                  defaultChecked={patient.gender === 'female'}
                  onChange={handleChange}
                />
                <Input
                  type="radio"
                  value="male"
                  name="gender"
                  data-test="gender-male"
                  defaultChecked={patient.gender === 'male'}
                  label={i18n._(t`Male`)}
                  onChange={handleChange}
                />
                <Input
                  type="radio"
                  value="other"
                  name="gender"
                  data-test="gender-other"
                  defaultChecked={patient.gender === 'other'}
                  label={i18n._(t`Other`)}
                  onChange={handleChange}
                />
              </RadioGroup>
            </Form.Row>
            <Form.Row>
              <Input
                label="Numéro de sécurité sociale (13 chiffres)"
                name="nir"
                data-test="nir"
                pattern="[0-9]{13}"
                defaultValue={patient?.identifier?.[0]?.value}
                onChange={handleChange}
                required
              />
            </Form.Row>
          </Section>
          <Section>
            <h4>
              <Trans>Contact</Trans>
            </h4>
            <Indication>
              <Trans>
                Please fill out at least the email or the phone number
              </Trans>
            </Indication>
            <Form.Row>
              <Input
                type="email"
                name="email"
                data-test="email"
                onChange={handleChange}
                defaultValue={
                  patient.telecom.find(telecom => telecom.system === 'email')
                    ?.value
                }
                label={i18n._(t`Patient's email`)}
              />
            </Form.Row>
            <Form.Row>
              <Input
                type="tel"
                name="phone"
                data-test="phone"
                onChange={handleChange}
                defaultValue={
                  patient.telecom.find(telecom => telecom.system === 'phone')
                    ?.value
                }
                label={i18n._(t`Patient's mobile phone`)}
              />
            </Form.Row>
          </Section>
        </>
      )}
    </I18n>
  )
}

export default SimpleAddPatient
