import { FC } from 'react'
import { Redirect, RouteComponentProps, Router } from '@reach/router'
// @ts-ignore
import GridLayout from '../Shared/components/GridLayout'
import { usePaymentSecret } from './hooks'
import AfterValidationContainer from './AfterValidationContainer'
import MUIThemeProvider from '../Shared/MUIThemeProvider'
import CheckoutContainer from './CheckoutContainer'
import { loadStripe, StripeElementsOptions } from '@stripe/stripe-js'
import appearance from './stripeAppearance'
import { Elements } from '@stripe/react-stripe-js'
import CustomCard from './CustomCard'
import CustomCardHeader from './CustomCardHeader'
import LoadingCheckoutCardContent from './LoadingCheckoutCardContent'

// Make sure to call `loadStripe` outside a component’s render to avoid
// recreating the `Stripe` object on every render.
if (process.env.REACT_APP_STRIPE_API_KEY == null) {
  throw new Error('REACT_APP_STRIPE_API_KEY is not defined')
}
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY, {
  locale: 'fr'
})

interface CheckoutRouterProps extends RouteComponentProps {
  appointmentId?: string
}

const NotFound: FC<RouteComponentProps> = () => <Redirect to="./" noThrow />

const CheckoutRouter: FC<CheckoutRouterProps> = ({ appointmentId }) => {
  if (appointmentId == null) {
    throw new Error('appointmentId should be defined')
  }
  const { isLoading, isIdle, data: secret } = usePaymentSecret(appointmentId)
  if (isLoading || isIdle) {
    return (
      <MUIThemeProvider>
        <GridLayout.Main variant="full">
          <CustomCard>
            <CustomCardHeader />
            <LoadingCheckoutCardContent />
          </CustomCard>
        </GridLayout.Main>
      </MUIThemeProvider>
    )
  }

  if (secret == null) {
    throw new Error('client secret should not be null')
  }

  const options: StripeElementsOptions = {
    clientSecret: secret,
    appearance: appearance
  }

  return (
    <MUIThemeProvider>
      <Elements stripe={stripePromise} options={options}>
        <GridLayout.Main variant="full">
          <CustomCard>
            <CustomCardHeader />
            <Router>
              <AfterValidationContainer
                path="done"
                appointmentId={appointmentId}
              />
              <CheckoutContainer path="/" appointmentId={appointmentId} />
              <NotFound path="*" />
            </Router>
          </CustomCard>
        </GridLayout.Main>
      </Elements>
    </MUIThemeProvider>
  )
}

export default CheckoutRouter
