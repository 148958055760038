import { faSave } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Trans } from '@lingui/macro'
import omit from 'lodash/omit'
import moment from 'moment'
import { useEffect, useReducer } from 'react'
import styled from 'styled-components/macro'
import { v4 as uuid } from 'uuid'
import { Button, Form, Input, Spinner, TextArea } from '../../Components'
import useSave from '../../Shared/hooks/useSave'
import {
  MEDEO_ALD_TYPE_SYSTEM,
  MEDEO_ALD_TYPE_VALUE,
  MEDEO_MEDICATION_REQUEST_GROUP_IDENTIFIER_NAME
} from '../codes'

const Separator = styled.div`
  margin: 0 2rem;
  border-bottom: 1px solid ${p => p.theme.nevada};
`

const CustomCheckbox = styled(Input).attrs({ type: 'checkbox' })`
  border-color: transparent;
  padding: 0.75rem;
  margin-bottom: 0;
  margin-top: 0.5rem;
`
const Overflow = styled.div`
  overflow-y: scroll;
  flex: 1;
`
const SubmitButton = styled(Button)`
  margin-top: 2rem;
`

const CustomForm = styled(Form)`
  margin: 2rem;
`
const SpinnerContainer = styled.div`
  margin-top: 2rem;
  display: flex;
  align-items: center;
  margin-left: 1rem;
`

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  margin: auto;
`

const IndicationDiv = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: ${p => p.theme.small};
  font-style: italic;
  margin-top: 1rem;
`

const reducer = (state, action) => {
  const { type, payload } = action
  switch (type) {
    case 'changeTitle':
      return {
        ...state,

        groupIdentifier: {
          extension: [
            {
              url: MEDEO_MEDICATION_REQUEST_GROUP_IDENTIFIER_NAME,
              valueString: payload
            }
          ],
          value: state.groupIdentifier.value
        }
      }
    case 'changeType':
      // As we might change from ALD to none ALD type, we do not copy the
      // the identifier property at first
      const { ...rest } = omit(state, ['identifier'])

      return {
        ...rest,
        ...(payload === true && {
          identifier: [
            {
              type: {
                coding: [
                  {
                    system: MEDEO_ALD_TYPE_SYSTEM,
                    code: MEDEO_ALD_TYPE_VALUE
                  }
                ]
              }
            }
          ]
        })
      }
    case 'changeMedication':
      return {
        ...state,
        medicationCodeableConcept: {
          text: payload
        }
      }

    case 'reset':
      // reset order with the same patient encounter and practitioner
      return {
        ...payload
      }
    default:
      return state
  }
}
const init = ({ encounter, patient, practitioner, medicationRequest }) => {
  if (medicationRequest != null) {
    return { ...medicationRequest }
  }
  return {
    resourceType: 'MedicationRequest',
    subject: {
      reference: `Patient/${patient.id}`
    },
    requester: {
      agent: {
        reference: `Practitioner/${practitioner.id}`
      }
    },
    context: {
      reference: `Encounter/${encounter.id}`
    },
    medicationCodeableConcept: {
      text: ''
    },
    authoredOn: moment().format('YYYY-MM-DD'),
    groupIdentifier: {
      value: uuid(),
      extension: [
        {
          url: MEDEO_MEDICATION_REQUEST_GROUP_IDENTIFIER_NAME,
          valueString: ''
        }
      ]
    }
  }
}

const Div = styled.div`
  padding: 0 2rem;
  display: flex;
  & > :first-child {
    flex: 1;
    margin-right: 1rem;
  }
  & > :last-child {
    flex: 0;
  }
  margin: 2rem 0;
`

const EditParamedicalMedicationRequestForm = ({
  encounter,
  patient,
  practitioner,
  medicationRequest,
  onAfterSubmit,
  isSubmitting = false
}) => {
  useEffect(() => {
    if (medicationRequest != null) {
      dispatch({
        type: 'reset',
        payload: medicationRequest
      })
    }
  }, [medicationRequest])
  // medicationRequests represents "one line" of an order
  // to tied them together we use the groupIdentifier attribute,
  // when this component is initialized we generate a uuid.
  // it is shared to all MedicationRequests edited within this components
  const [state, dispatch] = useReducer(
    reducer,
    {
      encounter,
      patient,
      practitioner,
      medicationRequest
    },
    init
  )
  const [save, { loading }] = useSave()

  const handleSubmit = async () => {
    const { data: medicationRequest } = await save(state)
    // medicationRequest is put in an array to provide the same signature
    // as EditMedicationRequestsForm
    onAfterSubmit([medicationRequest])
  }
  // we look if there is an ALD identifier in the state medicationRequest
  // this condition is used for the ALD checkbox below.
  const checked =
    state.identifier?.some?.(i =>
      i.type?.coding?.some?.(c => c.code === MEDEO_ALD_TYPE_VALUE)
    ) ?? false

  const disabled =
    state.groupIdentifier?.extension?.[0]?.valueString === '' ||
    state.medicationCodeableConcept.text === '' ||
    isSubmitting ||
    loading

  return (
    <>
      <Overflow>
        <Div>
          <Input
            label={<Trans>Paramedical request title</Trans>}
            value={state.groupIdentifier?.extension?.[0]?.valueString}
            onChange={e => {
              const { value } = e.target
              dispatch({ type: 'changeTitle', payload: value })
            }}
            data-test={'paramedical-request-title'}
          />
          <Input
            label={<Trans>Date</Trans>}
            value={state.authoredOn}
            type="date"
            disabled={true}
          />
        </Div>
        <CustomForm action="">
          <TextArea
            label=""
            rows="8"
            value={state.medicationCodeableConcept.text}
            onChange={e => {
              const { value } = e.target
              dispatch({ type: 'changeMedication', payload: value })
            }}
            data-test={'paramedical-request-textarea'}
          />
          <CustomCheckbox
            type="checkbox"
            label={<Trans>long-term illness</Trans>}
            defaultChecked={checked}
            onChange={e => {
              const { checked } = e.target
              dispatch({ type: 'changeType', payload: checked })
            }}
            data-test={'paramedical-request-checkbox'}
          />{' '}
        </CustomForm>
      </Overflow>
      <Separator />
      <Footer>
        <SubmitButton
          onClick={handleSubmit}
          disabled={disabled}
          data-test={'paramedical-submit-button'}
        >
          <FontAwesomeIcon icon={faSave} />
          &nbsp;<Trans>Save</Trans>
        </SubmitButton>
        {isSubmitting && (
          <SpinnerContainer>
            <Spinner />
          </SpinnerContainer>
        )}
      </Footer>
      {isSubmitting && (
        <IndicationDiv>
          <span>
            <Trans>Please wait while your order is being saved...</Trans>
          </span>
        </IndicationDiv>
      )}
    </>
  )
}

EditParamedicalMedicationRequestForm.defaultProps = {
  onAfterSubmit: () => {}
}
export default EditParamedicalMedicationRequestForm
