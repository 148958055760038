import { call, select, takeEvery, put } from 'redux-saga/effects';
import {
  saveFhirResourceWorker,
  searchFhirResourceWorker
} from '../Shared/sagas';
import { getCurrentOrganizationId } from '../Auth/selectors';
import { PRACTITIONER_REMOVED } from '../Practitioner/actions';
import { getPractitionerRoleBypractitionerID } from './selector';
import {
  FETCHING_PRACTITIONER_ROLE,
  EDITING_PRACTITIONER_ROLE,
  CREATING_PRACTITIONER_ROLE
} from './actions';
import { createNotification } from '../Notifications/actions';

function* removeRoleWorker(action) {
  const [role] = yield select(
    getPractitionerRoleBypractitionerID,
    action.payload.id,
    'medical'
  );
  if (role != null) {
    yield call(saveFhirResourceWorker, {
      ...role,
      active: false
    });
    yield put(
      createNotification(
        'Utilisateur a été correctement archivé',
        'success',
        4000
      )
    );
  } else {
    console.warn(`No role associated with Practitioner ${action.payload.id}`);
  }
}

function* editPractitionerRole(action) {
  const response = yield saveFhirResourceWorker(action.payload);
  if (response != null) {
    yield put(
      createNotification(
        'Utilisateur a été correctement modifié',
        'success',
        4000
      )
    );
  } else {
    yield put(
      createNotification(
        "Une erreur est survenue lors de la modification de l'utilisateur",
        'error',
        4000
      )
    );
  }
}

function* createPractitionerRole(action) {
  const { payload } = action;
  const organizationId = yield select(getCurrentOrganizationId);
  const response = yield call(
    saveFhirResourceWorker,
    {
      resourceType: 'PractitionerRole',
      active: true,
      identifier: payload.identifier,
      practitioner: payload.practitioner,
      organization: {
        reference: `Organization/${organizationId}`
      },
      specialty: payload.specialty
    },
    action
  );

  if (response != null) {
    yield put(
      createNotification(
        "L'utilisateur a été correctement créé",
        'success',
        4000
      )
    );
  } else {
    yield put(
      createNotification(
        "Une erreur est survenue lors de la création de l'utilisateur",
        'error',
        4000
      )
    );
  }
}

export default function* watcherSaga() {
  const organizationId = yield select(getCurrentOrganizationId);
  // Filter practitionerRole by OrganizationId
  // The include parameter let Practitioner and PractitionerRole to be loaded together.
  yield call(searchFhirResourceWorker, 'PractitionerRole', {
    organization: organizationId,
    // TODO: require only active practitionerRole, when all roles will be updated
    // active: 'true',
    _include: 'PractitionerRole:practitioner',
    _count: 200
  });

  yield takeEvery(
    [FETCHING_PRACTITIONER_ROLE],
    searchFhirResourceWorker,
    'PractitionerRole',
    ({ payload: p }) => ({ practitioner: 'Practitioner/' + p.practitionerID })
  );

  yield takeEvery(CREATING_PRACTITIONER_ROLE, createPractitionerRole);
  yield takeEvery(EDITING_PRACTITIONER_ROLE, editPractitionerRole);
  //Every time a practitioner is removed we need to remove the role associated
  yield takeEvery(PRACTITIONER_REMOVED, removeRoleWorker);
}
