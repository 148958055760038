import React, { useEffect, useState } from 'react'
import Menu from '../components/Menu'
import { useLocation, useNavigate } from '@reach/router'

const UrlControlledMenu = ({ component, children, controlUrlParam }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const [isOpen, setIsOpen] = useState(false)

  /**
   * This useEffect checks in the url if the menu should open or not.
   * Then it update the state of the menu.
   **/
  useEffect(() => {
    // get params form URL
    const params = new URLSearchParams(location.search)
    // the value 'true' below is set in the onclick function defined bellow in Component
    const shouldOpen = params.get(controlUrlParam) === 'true'
    setIsOpen(shouldOpen)
  }, [location, controlUrlParam])

  const onClickMenu = () => {
    const url = new URL(location.href)
    if (isOpen) {
      url.searchParams.delete(controlUrlParam)
    } else {
      url.searchParams.append(controlUrlParam, 'true')
    }
    navigate(url.toString())
  }

  const onClickOutside = () => {
    const url = new URL(location.href)
    url.searchParams.delete(controlUrlParam)
    navigate(url.toString())
  }

  return (
    <Menu
      component={component}
      open={isOpen}
      onClickMenu={onClickMenu}
      onClickOutside={onClickOutside}
    >
      {children}
    </Menu>
  )
}

/** we expose ListItem as compound components.
 * We overwrite the ListItem component here, to update the url before running the onClick.
 * In case the second onClick has a navigate inside, it will work correctly. Otherwise
 * this second navigate would be ignored.
 **/
const UrlControlledMenuListItem = ({
  children,
  onClick,
  controlUrlParam,
  hasNavigationOnClick = false,
}) => {
  const navigate = useNavigate()
  const location = useLocation()

  // this is a function that removes the param in the url on click
  const onClickChangeUrl = controlUrlParam => {
    const url = new URL(location.href)
    url.searchParams.delete(controlUrlParam)
    navigate(url.toString())
  }

  return (
    <Menu.ListItem
      onClick={() => {
        // We shouldn't change the url if there is another navigate() triggered somewhere when the user clicks.
        if (hasNavigationOnClick === false) {onClickChangeUrl(controlUrlParam)}
        // else we remove the param, and run the onClick function defined for this item, if exists.
        if (onClick != null) {
          onClick()
        }
      }}
    >
      {children}
    </Menu.ListItem>
  )
}

UrlControlledMenu.ListItem = UrlControlledMenuListItem

export default UrlControlledMenu
