import React from 'react'
import { createGlobalStyle, ThemeProvider } from 'styled-components'

import { modernNormalize } from 'styled-modern-normalize'
import { mixins, theme } from '../../Components'
const ModernNormalize = createGlobalStyle`
  ${modernNormalize},
  body {
	background-color: ${props => props.theme.alabaster};
	font-family: 'Manrope', 'Inter', sans-serif;
	font-weight: ${props => props.theme.lighter};
	font-size: 16px;
	box-sizing: border-box;
	overflow-x: hidden;
	color: ${props => props.theme.ebony};
	@media print {
	  background-color: white;
	}
  }

  @supports (font-variation-settings: normal) {
	html {
	  font-family: 'Inter var', sans-serif;
	}
  }

  // https://github.com/reach/router/issues/63#issuecomment-428050999
  div[role="group"][tabindex] {
	flex: 1;
  }

  ${mixins.tooltip}
`
const Layout = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <>
        <ModernNormalize />
        {children}
      </>
    </ThemeProvider>
  )
}

export default Layout
