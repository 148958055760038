import { useState, useEffect } from 'react'

export const useKligoSocket = () => {
  const [data, setData] = useState([])
  useEffect(() => {
    const ws = new WebSocket('ws://localhost:63336/v3/Observation')
    ws.onmessage = function data(event) {
      const observation = JSON.parse(event.data)
      setData(data => [...data, observation])
    }
    return () => ws.close()
  }, [])
  return data
}
