import React from 'react'
import styled from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserPlus } from '@fortawesome/free-solid-svg-icons'
import { Button } from '../../Components'
import { Trans } from '@lingui/macro'
import { useNavigate } from '@reach/router'
import mixpanel, { MIXPANEL_ADDING_PATIENT } from '../../Shared/mixpanel'

const ButtonText = styled.span`
  margin-left: ${props => props.theme.spacing.small};
`

const AddPatientButton = () => {
  const navigate = useNavigate()
  const handleClick = () => {
    mixpanel.track(MIXPANEL_ADDING_PATIENT, {
      // either it comes from the patient list or the booking view
      // from metadata should be : list or booking
      from: 'list'
    })

    navigate('/patient/add/1')
  }
  return (
    <Button
      color="ocean"
      data-intercom-target="add-patient"
      onClick={handleClick}
    >
      <FontAwesomeIcon icon={faUserPlus} />
      <ButtonText data-test={'add-patient-button'}>
        <Trans>Add a patient</Trans>
      </ButtonText>
    </Button>
  )
}

export default AddPatientButton
