import React from 'react'
import styled from 'styled-components/macro'
import { Form } from '../../Components'
import QuestionnaireComponent from './QuestionnaireComponent'

const Div = styled.div`
  flex-direction: column;
  display: flex;
`

const Section = ({ item, ...rest }) => {
  const children = item?.item ?? []

  let type = item?.extension?.find(
    e =>
      e.url ===
      'http://hl7.org/fhir/StructureDefinition/questionnaire-itemControl'
  )?.valueCodeableConcept?.coding?.code

  return type === 'gtable' ? (
    <Form.Row>
      {children.map(child => (
        <QuestionnaireComponent item={child} key={child.linkId} {...rest} />
      ))}
    </Form.Row>
  ) : (
    <Div>
      {children.map(child => (
        <QuestionnaireComponent item={child} key={child.linkId} {...rest} />
      ))}
    </Div>
  )
}

export default Section
