import { Patient } from 'fhir-stu3'
import { DefaultValuesOption } from '@stripe/stripe-js/types/stripe-js/elements/payment'

export const getBillingDetailsFromPatient = (
  patient: Patient
): DefaultValuesOption['billingDetails'] => {
  return {
    name: `${patient?.name?.[0]?.family} ${patient?.name?.[0]?.given?.[0]}`,
    email: patient?.telecom?.find(t => t.system === 'email')?.value,
    phone: patient?.telecom?.find(t => t.system === 'phone')?.value,
    address: {
      country: patient?.address?.[0]?.country,
      postal_code: patient?.address?.[0]?.postalCode,
      city: patient?.address?.[0]?.city,
      line1: patient?.address?.[0]?.line?.[0]
    }
  }
}
