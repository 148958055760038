//@ts-nocheck
import { FC, useEffect } from 'react'
import { connect } from 'react-redux'
import { useEffectOnce } from 'react-use'
import { bindActionCreators } from 'redux'
import styled from 'styled-components/macro'
import { Trans } from '@lingui/macro'
import { Spinner } from '../../Components'
import EncounterDateView from './EncounterDateView'
import Centered from '../../Shared/components/Centered'
import LoadMore from '../../Shared/components/LoadMore'
import {
  filterEncounterByDates,
  getCurrentEncounter,
  getCurrentlyShownEncounters,
  getEncountersDate,
  getShownEncountersHasMore,
  getShownEncountersLink
} from '../selectors'
import { filterBetweenDates, sortByEndDate } from '../../utils/dateUtils'
import * as fromEncounters from '../actions'
import ScrollAtTopView from '../../Shared/components/ScrollAtTopView'
import { RouteComponentProps } from '@reach/router'
import FutureEventsContainer from './FutureEventsContainer'
import { Encounter, Patient } from 'fhir-stu3'

const Container = styled.div``

const Footer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`
const CustomSpinner = styled(Spinner)`
  height: 2rem;
  width: 2rem;
  margin: auto;
`

/**
 * Container for showing a list of Encounters. Used in both the patient page
 * and in the export dialog.
 */
interface EncounterListProps extends RouteComponentProps {
  patient: Patient
  pagination: number
  loadMoreEncounters: typeof fromEncounters.loadMoreEncounters
  encountersLink: string | null
  isLoading: boolean
  clearEncounters: typeof fromEncounters.clearEncounters
  hasMore: boolean
  shownEncounters: Encounter[]
  getInitialEncounterPage: typeof fromEncounters.getInitialEncounterPage
  hideDownloadButton: boolean
}
const EncounterList: FC<EncounterListProps> = (props: EncounterListProps) => {
  const {
    patient,
    pagination,
    loadMoreEncounters,
    encountersLink,
    isLoading,
    clearEncounters,
    hasMore,
    shownEncounters,
    getInitialEncounterPage,
    hideDownloadButton
  } = props
  // State used for CollapseAll context
  let encounterDates: string[] = []
  if (shownEncounters) {
    encounterDates = getEncountersDate(shownEncounters)
  }

  useEffectOnce(() => {
    getInitialEncounterPage(pagination, { subjectId: patient.id })
  })

  // When the component is unmounted, clear any shown encounters.
  useEffect(() => {
    return () => {
      clearEncounters()
    }
  }, [clearEncounters, patient])
  if ((!shownEncounters || shownEncounters.length === 0) && isLoading) {
    return (
      <Centered>
        <CustomSpinner />
      </Centered>
    )
  }
  if (
    (!shownEncounters || shownEncounters.length === 0) &&
    isLoading === false
  ) {
    return (
      <Centered>
        <Trans>No history for this patient</Trans>
      </Centered>
    )
  }

  return (
    <Container>
      <FutureEventsContainer
        shownEncounters={shownEncounters}
        patient={patient}
      />
      {encounterDates.map((date, id) => (
        <EncounterDateView
          key={id}
          date={date}
          encounterList={filterEncounterByDates(date, shownEncounters)}
          hideDownloadButton={hideDownloadButton}
          patient={patient}
        />
      ))}

      {pagination > 0 && (
        <Footer>
          <LoadMore
            loadMore={() => loadMoreEncounters(encountersLink)}
            isLoading={isLoading}
            hasMore={hasMore}
          >
            <Trans>Load more observations</Trans>
          </LoadMore>
          <ScrollAtTopView root={'header'} />
        </Footer>
      )}
    </Container>
  )
}

const mapState = (state, ownProps) => {
  return {
  shownEncounters: getCurrentlyShownEncounters([
    filterBetweenDates(ownProps.period)
  ])(state).sort(sortByEndDate),
  currentEncounter: getCurrentEncounter(state),
  encountersLink: getShownEncountersLink(state),
  hasMore: getShownEncountersHasMore(state),
  isLoading: state.encounters.shown.isLoading
}
}

const mapDispatch = (dispatch) =>
  bindActionCreators(
    {
      getInitialEncounterPage: fromEncounters.getInitialEncounterPage,
      loadMoreEncounters: fromEncounters.loadMoreEncounters,
      clearEncounters: fromEncounters.clearEncounters,
      getTotalEncounter: fromEncounters.getTotalEncounter
    },
    dispatch
  )

export default connect(mapState, mapDispatch)(EncounterList)
