import React from 'react'
import QuestionnaireResponseItem from '../components/QuestionnaireResponseItem'
import { mergeWithQuestionnaire } from '../utils'
/**
 * This Component is meant to display both the questionnaire and its response
 * In the case of readOnly
 * It will iterate over all the items of the questionnaire and display their corresponding response
 *
 */
const QuestionnaireResponse = ({ questionnaire, questionnaireResponse }) => {
  // We merge the response with its questionnaire in order to have a single resource containing
  // all the information we need for display. For more details, please refer to the file QuestionnaireResponse/utils.js
  const mergedResponse = mergeWithQuestionnaire(
    {},
    questionnaire,
    questionnaireResponse
  )
  return (
    <div>
      {mergedResponse?.item.map(responseItem => {
        return (
          <QuestionnaireResponseItem
            key={`item_${responseItem.linkId}`}
            responseItem={responseItem}
          />
        )
      })}
    </div>
  )
}

export default QuestionnaireResponse
