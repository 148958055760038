import React, { useEffect, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useEffectOnce } from 'react-use'
import styled from 'styled-components/macro'
import { Spinner } from '../../Components'
import { search } from '../../Shared/actions'
import { Trans } from '@lingui/macro'
import {
  ORDONNANCE_MEDICAMENTEUSE_ACTION_VALUE,
  ORDONNANCE_PARAMEDICALE_ACTION_VALUE,
  TELECONSULTATION_QUESTIONNAIRE_SYSTEM,
  DOCUMENT_TELECONSULTATION_ACTION_VALUE,
  PAYMENT_METHOD_ACTION_VALUE,
  TOTAL_PRICE_ACTION_VALUE
} from '../../PlanDefinition/utils'
import { getTypeAndIdFromLocalReference } from '../../utils/fhir'
import QuestionnaireSquareItem from '../../Questionnaire/components/QuestionnaireSquareItem'
import PriceIndication from '../components/PriceIndication'
import { extractDocumentsFromTeleconsultation } from '../utils'
import QuestionnaireResponseItem from '../../QuestionnaireResponse/components/QuestionnaireResponseItem'
import {
  MEDEO_PAYMENT_AMOUNT_SYSTEM,
  MEDEO_PAYMENT_STATUS_SYSTEM,
  MEDEO_PAYMENT_METHOD_SYSTEM
} from '../../utils/codes'
import { VideoScreenContext } from './VideoSequenceManager'
import { DOCUMENTS_RECEIVED } from '../reducer'
import { MediumMessage } from '../components/Indications'
import { getIdByReference } from '../../Shared/utils'
import { getActiveTeleconsultationBypatientID } from '../../ProcedureRequest/ducks'
import { getPaymentNoticeByProcedureRequestId } from '../../PaymentNotice/ducks'
import { getResponsesByencounterID } from '../../QuestionnaireResponse/ducks'
import { getQuestionnaireByIdentifier } from '../../Questionnaire/selector'

const Div = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  & > :not(:last-child) {
    margin-bottom: 2rem;
  }
`

const DocumentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 2rem;
  margin-bottom: 2rem;
  width: 100%;
`
export const SmallMessage = styled.div`
  margin-top: 2rem;
  margin-bottom: 2rem;
  color: white;
  text-align: center;
  font-size: ${props => props.theme.small};
`

const Container = styled.div`
  margin: auto;
  display: flex;
  align-content: center;
  flex-direction: column;
  justify-content: center;
  height: 80%;
`
const AttachmentContainer = styled.div`
  margin-top: 0.5rem;
`

const questionnaireIdentifier = [
  ORDONNANCE_MEDICAMENTEUSE_ACTION_VALUE,
  ORDONNANCE_PARAMEDICALE_ACTION_VALUE,
  DOCUMENT_TELECONSULTATION_ACTION_VALUE,
  PAYMENT_METHOD_ACTION_VALUE,
  TOTAL_PRICE_ACTION_VALUE
]

const Documents = ({ encounter, patientID }) => {
  const reduxDispatch = useDispatch()
  const [videoState, dispatch] = useContext(VideoScreenContext)
  // here we create a process to get questionnaireResponse when the videoState.status is
  // equal to "teleconsultationCompleted". First time we filter QuestionnaireResponse to get only the QR related to
  // teleconsultation like feuille de soin, arret du travail or ordonnance. Second time we map on
  // QR filtered and we check the identifier of the questionnaire, like that we can change the variable
  // of the name and the code of icon. Those variable we send those variables in the component of QuestionnaireSquareVisual

  // here we get teleconsultationencounterID to fetch questionnaireResponse related to this encounter
  const encounterID = encounter?.id
  const preconsultationPartOf = encounter?.partOf?.reference
  const preconsultationencounterID = getIdByReference(preconsultationPartOf)

  // onPrintQuestionnaire is duplicated from QuestionnaireRepeatableDocument
  //TODO: refactor this component once the Questionnaire/QuestionnaireResponse
  // $extract and $populate method are implemented
  const onPrintQuestionnaireResponse = (
    responseId,
    questionnaireIdentifier,
    download
  ) => {
    // openPrintPopup creates a new popup window
    // and navigate at the given url
    // The popup window will close after the user utilizes the print dialog.
    // note: window.print should not be called here
    const openPrintPopup = url => {
      const origin = window.location.origin
      // origin will dynamically be:
      // - https://develop.medeo.care
      // - https://medeo.care
      // - http://localhost
      const printWindow = window.open(
        `${origin}${url}`,
        '_blank',
        'width=720,height=1080,menubar=no'
      )
      // window.print should be triggered from components
      // mounted at the target url
      // here we listen to the after print event
      // which is fired when the user close the print dialog
      // either if the user choose to print, save as pdf or cancel the print
      printWindow.onafterprint = () => {
        printWindow.close()
      }
      // call print window 2 secs after the page has loaded the html content
      // this should give React enough time to set everything up
      // NOTE(charles): This won't be used for long, orders should be generated as a pdf
      // and used as attachment
      printWindow.addEventListener('load', () => {
        setTimeout(() => {
          printWindow.print()
        }, 2000)
      })
    }

    switch (questionnaireIdentifier.code) {
      case ORDONNANCE_MEDICAMENTEUSE_ACTION_VALUE:
      case ORDONNANCE_PARAMEDICALE_ACTION_VALUE:
        // for the moment we cannot download Orders because they
        // are generated in the client side as an html webpage.
        // thus if download is true we fallthrough the default case
        // and display the disclaimer
        if (download !== true) {
          openPrintPopup(`/order/${responseId}`)
          break
        }
      // here we intentionally go to the default case
      //eslint-disable-next-line
      default:
        window.alert("Cette fonctionnalité n'est pas encore disponible")
        break
    }
  }

  // fetch questionnaire by identifier
  useEffectOnce(() => {
    questionnaireIdentifier.map(identifierValue =>
      reduxDispatch(search('Questionnaire', { identifier: identifierValue }))
    )
    reduxDispatch(
      search('QuestionnaireResponse', { context: preconsultationencounterID })
    )
  })

  // fetch questionnaireResponse when we received the status "teleconsultationCompleted"
  useEffect(() => {
    setTimeout(() => {
      reduxDispatch(search('QuestionnaireResponse', { context: encounterID }))
      reduxDispatch(
        search('ProcedureRequest', {
          subject: 'Patient/' + patientID,
          context: preconsultationencounterID,
          _sort: '-_id'
        })
      )
    }, 5000)
  }, [reduxDispatch, encounterID, patientID, preconsultationencounterID])
  const procedureRequest = useSelector(
    getActiveTeleconsultationBypatientID(patientID)
  )
  const paymentNotice = useSelector(
    getPaymentNoticeByProcedureRequestId(procedureRequest?.id)
  )

  //now the PaymentNotice save the payment method and the price instead of using two different QuestionnaireResponse

  // we retrieve informations from PaymentNotice
  const price = paymentNotice?.paymentStatus?.coding?.find(
    c => c.system === MEDEO_PAYMENT_AMOUNT_SYSTEM
  )?.code
  const paymentStatus = paymentNotice?.paymentStatus?.coding?.find(
    c => c.system === MEDEO_PAYMENT_STATUS_SYSTEM
  )?.code
  const paymentMethod = paymentNotice?.paymentStatus?.coding?.find(
    c => c.system === MEDEO_PAYMENT_METHOD_SYSTEM
  )?.code

  useEffect(() => {
    if (procedureRequest != null && paymentNotice == null) {
      reduxDispatch(
        search('PaymentNotice', {
          request: `ProcedureRequest/${procedureRequest.id}`
        })
      )
    }
  }, [procedureRequest, paymentNotice, reduxDispatch])

  // we receive questionnaireResponse of teleconsultation
  const responses = useSelector(getResponsesByencounterID(encounterID))
  const prescriptionQuestionnaire = useSelector(
    getQuestionnaireByIdentifier(ORDONNANCE_MEDICAMENTEUSE_ACTION_VALUE)
  )
  const paramedicQuestionnaire = useSelector(
    getQuestionnaireByIdentifier(ORDONNANCE_PARAMEDICALE_ACTION_VALUE)
  )
  const documentQuestionnaire = useSelector(
    getQuestionnaireByIdentifier(DOCUMENT_TELECONSULTATION_ACTION_VALUE)
  )

  // We want to display all the docs created by the practitioner during the remote
  // consultation.
  // As of today we fetch the questionnaireResponses and use them to construct the documents
  // Each response needs to be associated with the source questionnaire so we now
  // interpret the items in the response.
  // the following maps and filters do this process and adapt the array of responses
  // into an array of props for the QuestionnaireSquareItem
  // which expects the following props: name, codeIcon, responseId and questionnaireIdentifier
  const ordersAndCerfa = responses
    .map(response => {
      const [, id] = getTypeAndIdFromLocalReference(
        response.questionnaire.reference
      )
      // returns a tuple with the id of the questionnaire
      // it is later used in this chain to select the correct
      // display for the doc
      return [response, id]
    })
    .filter(([, id]) => {
      // the questionnaire id associated to the response is the second item of the tuple
      // we want to deal only the response of the following list
      // i.e. filtering with an include
      return [
        prescriptionQuestionnaire?.id,
        paramedicQuestionnaire?.id
      ].includes(id)
    })
    .map(([response, questionnaireId]) => {
      switch (questionnaireId) {
        case prescriptionQuestionnaire.id:
          return {
            name: 'Ordonnance',
            responseId: response.id,
            codeIcon: 'pills',
            questionnaireIdentifier: {
              system: TELECONSULTATION_QUESTIONNAIRE_SYSTEM,
              code: ORDONNANCE_MEDICAMENTEUSE_ACTION_VALUE
            }
          }
        case paramedicQuestionnaire.id:
          return {
            name: 'Ordonnance',
            responseId: response.id,
            codeIcon: 'syringe',
            questionnaireIdentifier: {
              system: TELECONSULTATION_QUESTIONNAIRE_SYSTEM,
              code: ORDONNANCE_PARAMEDICALE_ACTION_VALUE
            }
          }
        default:
          throw new Error(`${questionnaireId} is not related to a document`)
      }
    })

  // this variable contains attachment data to display document that doctor send to the patient
  const documentsQuestionnaireResponseItem = extractDocumentsFromTeleconsultation(
    responses,
    documentQuestionnaire
  )

  // This condition is here to check if we have document or cerfa to display
  // If it's not the case you get a beautiful spinner !
  useEffect(() => {
    if (!videoState.documentsDisplay) {
      if (ordersAndCerfa && ordersAndCerfa.length >= 1) {
        dispatch({ type: DOCUMENTS_RECEIVED })
      } else if (documentsQuestionnaireResponseItem != null) {
        dispatch({ type: DOCUMENTS_RECEIVED })
      } else if (procedureRequest && procedureRequest.status === 'completed') {
        dispatch({ type: DOCUMENTS_RECEIVED })
      }
    }
  }, [
    procedureRequest,
    documentsQuestionnaireResponseItem,
    ordersAndCerfa,
    dispatch,
    videoState.documentsDisplay
  ])

  return (
    <Div>
      {!videoState.documentsDisplay ? (
        <Spinner />
      ) : (
        <>
          <Container>
            <MediumMessage>
              <PriceIndication
                price={price}
                paymentMethod={paymentMethod}
                paymentStatus={paymentStatus}
              />
            </MediumMessage>
            {ordersAndCerfa.length !== 0 ||
            documentsQuestionnaireResponseItem != null ? (
              <>
                <MediumMessage>
                  <Trans>Here are the documents sent by your doctor.</Trans>
                </MediumMessage>
                <DocumentContainer>
                  {ordersAndCerfa.map((props, i) => (
                    <QuestionnaireSquareItem
                      {...props}
                      key={i}
                      onPrintQuestionnaireResponse={
                        onPrintQuestionnaireResponse
                      }
                      readOnly={true}
                      teleconsultationScreen={true}
                    />
                  ))}
                  {/* This condition allow the display of Documents without this,
              Document can't be displayed if we have order and cerfa  */}
                  {/* This condition allow the display of Documents without this,
            Document can't be displayed if we have order and cerfa  */}
                  {documentsQuestionnaireResponseItem != null && (
                    <AttachmentContainer>
                      <QuestionnaireResponseItem
                        responseItem={documentsQuestionnaireResponseItem}
                      />
                    </AttachmentContainer>
                  )}
                </DocumentContainer>
              </>
            ) : (
              <SmallMessage>
                <Trans>
                  No document was created by your doctor during the
                  teleconsultation.
                </Trans>
              </SmallMessage>
            )}

            <MediumMessage>
              <Trans>Thank you for using Medeo!</Trans>
            </MediumMessage>
          </Container>
        </>
      )}
    </Div>
  )
}

export default Documents
