import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components/macro';
import { Trans, t } from '@lingui/macro';
import { setupI18n } from '@lingui/core';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getDerivedObservationsFromObservationId } from '../selectors';
import {
  getLoincCodingFromObservation,
  getDisplayInfoFromLoincCode,
  LOINC_BLOOD_PRESSURE_CODE,
  LOINC_SPIROMETRY_PANEL,
  LOINC_OXYGEN_SATURATION_CODE,
  LOINC_URINALYSIS_PANEL_CODE,
  LOINC_COMMENT_CODE
} from '../../utils/loinc';
import * as fromActions from '../actions';
import ValueQuantityView from './ValueQuantityView';
import BloodPressureValueQuantityView from './BloodPressureValueQuantityView';
import OxygenSaturationValueQuantityView from './OxygenSaturationValueQuantityView';
import UrinalysisValueView from './UrinalysisValueView';
import InterpretationView from './InterpretationView';
import ExtraRow from './ExtraRow';
import ObservationViewDropdown from './ObservationViewDropdown';
import ValueTimeView from './ValueTimeView';
import Comment from './Comment';
import englishMessages from '../../locales/en/messages.js';
import frenchMessages from '../../locales/fr/messages.js';
import { displayEffectiveHourFromObservation } from '../../Shared/display/observation';
import ValueStringView from './ValueStringView';
import CommentView from './CommentView';
import EmptyValueQuantityView from './EmptyValueQuantityView';

// I18n
// setup the i18n object with active language and catalogs
let i18n = setupI18n({
  language: 'fr',
  catalogs: {
    en: englishMessages,
    fr: frenchMessages
  }
});

const Container = styled.div`
  width: 100%;
`;

const Top = styled.div`
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
`;

const LeftContainer = styled.div`
  width: 30%;
  text-align: center;
`;

const Type = styled.div`
  width: 2rem;
  box-sizing: content-box;
  text-align: center;
  margin-top: ${props => props.theme.spacing.small};
  padding-right: ${props => props.theme.spacing.medium};
  font-size: ${props => props.theme.small};
`;
const Left = styled.div`
  width: 30%;
  display: flex;
  align-items: center;
  align-self: flex-start;
`;

const Name = styled.div`
  margin-left: ${props => props.theme.spacing.small};
  color: ${props => props.theme.nevada};
  font-weight: ${props => props.theme.bold};
`;

const Middle = styled.div`
  width: 30%;
`;

const WidthDiv = styled.div`
  width: 80%;
`;

const Right = styled.div`
  width: 20%;
`;

const TimeDiv = styled.div`
  margin-top: 0.2em;
`;

const Icon = styled.div`
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CustomIcon = styled(Icon)`
  &:hover {
    cursor: pointer;
  }
`;

const End = styled.div`
  width: 10%;
  height: 100%;
  display: flex;
  justify-content: center;
`;

const Bottom = styled.div``;

const ValueView = ({ observation, isEmpty, observationType }) => {
  if (isEmpty) {
    return (
      <WidthDiv>
        <Type>
          <Trans>Measure</Trans>
        </Type>
        <Top>
          <EmptyValueQuantityView observationType={observationType} />
        </Top>
      </WidthDiv>
    );
  } else {
    switch (observationType) {
      case LOINC_BLOOD_PRESSURE_CODE:
        return <BloodPressureValueQuantityView observation={observation} />;
      case LOINC_OXYGEN_SATURATION_CODE:
        return <OxygenSaturationValueQuantityView observation={observation} />;
      case LOINC_URINALYSIS_PANEL_CODE:
        return <UrinalysisValueView observation={observation} />;
      case LOINC_SPIROMETRY_PANEL:
        return (
          <WidthDiv>
            <Type>
              <Trans>Measure</Trans>
            </Type>
            <Top>
              <InterpretationView observation={observation} />
              <ValueQuantityView
                valueQuantity={{
                  value: i18n._(t`See results in the attached pdf`)
                }}
              />
            </Top>
          </WidthDiv>
        );
      case LOINC_COMMENT_CODE:
        return <ValueStringView valueString={observation.valueString} />;
      default:
        return (
          <WidthDiv>
            <Type>
              <Trans>Measure</Trans>
            </Type>
            <Top>
              <ValueQuantityView valueQuantity={observation.valueQuantity} />
            </Top>
          </WidthDiv>
        );
    }
  }
};

const ObservationView = ({
  observation,
  observationType,
  derived,
  updateObservation,
  updateAllObservations,
  isActive,
  showContextMenu,
  readOnly = true
}) => {
  const [canModifyComment, setCanModifyComment] = useState(isActive);

  const commentRef = useRef(null);
  const [canAddComment, setCanAddComment] = useState(false);

  // observation view differs depending on the type of the observation
  // using the loinc coding we check whether it's a normal valueQuantity or something specific
  const loincCoding = getLoincCodingFromObservation(observation);
  // if there is no loincCoding it fails here:
  if (observation != null && loincCoding == null) {
    if (observation.valueQuantity != null) {
      return (
        <span>
          {observation.code.coding[0].display} :{' '}
          {observation.valueQuantity.value} {observation.valueQuantity.unit}
        </span>
      );
    }
    if (observation.valueString != null) {
      return (
        <span>
          {observation.code.coding[0].display} : {observation.valueString}
        </span>
      );
    }
    if (observation.valueBoolean != null) {
      return (
        <span>
          {observation.code.coding[0].display} :{' '}
          {observation.valueBoolean ? 'Oui' : 'Non'}
        </span>
      );
    }
    return <code>Value is missing from observation</code>;
  }
  let loincCode = null;
  if (observation == null) {
    if (observationType != null) {
      loincCode = observationType;
    }
  } else {
    loincCode = loincCoding.code;
  }

  const { icon, children: name } = getDisplayInfoFromLoincCode(observationType);

  const handleModifyCommentClick = () => {
    setCanAddComment(true);
    setCanModifyComment(true);
    if (commentRef && commentRef.current) {commentRef.current.focus();}
  };

  // called when the comment loses its focus
  const handleCommentChange = comment => {
    updateObservation({
      ...observation,
      comment: comment || ''
    });
    if (!comment) {setCanAddComment(false);}
  };

  const handleRemoveCommentClick = () => {
    updateObservation({
      ...observation,
      comment: ''
    });
    setCanAddComment(false);
  };

  const handleRemoveObservation = () => {
    updateObservation({
      ...observation,
      status: 'entered-in-error'
    });
  };

  const handleRemoveAllObservations = () => {
    if (observation != null) {
      updateAllObservations(
        observationType,
        observation.context.reference.substr(10),
        'entered-in-error'
      );
    }
  };

  let comment = null;
  let isFirst = true;
  if (observation != null) {
    comment = observation.comment;
    isFirst = observation.isFirst;
  }

  return (
    <Container>
      {loincCode === LOINC_COMMENT_CODE ? (
        <CommentView comment={observation.valueString} />
      ) : (
        <>
          <Top>
            {isFirst === false ? (
              <Left></Left>
            ) : (
              <LeftContainer>
                <Type>Type</Type>
                <Left>
                  <Icon>
                    <FontAwesomeIcon size="lg" icon={icon} />
                  </Icon>
                  <Name>{name}</Name>
                </Left>
              </LeftContainer>
            )}
            <Middle>
              <ValueView
                observation={observation}
                isEmpty={observation === null}
                observationType={observationType}
              />
            </Middle>
            <Right>
              <Type>
                <Trans>Time</Trans>
              </Type>
              <TimeDiv>
                {displayEffectiveHourFromObservation(observation)}
              </TimeDiv>
            </Right>

            {/*TODO*/}
            {/*Think about passing the condition through css*/}
            {showContextMenu !== true ? (
              <End>
                {isFirst === false && !readOnly ? (
                  <CustomIcon>
                    <FontAwesomeIcon
                      icon={faTimes}
                      onClick={handleRemoveObservation}
                    />
                  </CustomIcon>
                ) : !readOnly && observation != null ? (
                  <ObservationViewDropdown
                    onModifyComment={handleModifyCommentClick}
                    onRemoveComment={handleRemoveCommentClick}
                    onRemoveObservation={handleRemoveObservation}
                    onRemoveAllObservations={handleRemoveAllObservations}
                    observationType={observationType}
                    hasComment={!!comment}
                    isAlone={isFirst == null}
                  />
                ) : null}
              </End>
            ) : null}
          </Top>

          <Bottom>
            {derived.map(d => (
              <ExtraRow key={d.id}>
                <ValueTimeView
                  readOnly={true}
                  valueTime={d.valueTime}
                  labelText={<Trans>Last Meal</Trans>}
                />
              </ExtraRow>
            ))}

            {(canAddComment || !isActive) && observation != null && (
              <Comment
                inputId={'comment_' + observation.id}
                inputRef={commentRef}
                readOnly={!canModifyComment}
                onChange={handleCommentChange}
                defaultValue={comment || ''}
              />
            )}
          </Bottom>
        </>
      )}
    </Container>
  );
};

const mapStateToProps = (state, ownProps) => ({
  derived: getDerivedObservationsFromObservationId(state, ownProps.observation)
});

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      updateObservation: fromActions.updateObservation,
      updateAllObservations: fromActions.updateAllObservations
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatch)(ObservationView);
