import { useEffect } from 'react'
import FutureEncounterCard from './FutureEncounterCard'
import { isLastEncounterRemoteConsultation } from '../utils'
import { useSelector, useDispatch } from 'react-redux'
import { getFutureAppointments } from '../../Appointment/selectors'
import { Trans } from '@lingui/macro'
import { search } from '../../Shared/actions'
import { DateHeader } from '../components/DateHeader'
import { MEDEO_APPOINTMENT_TYPE_TELECONSULTATION } from '../../utils/codes'
import styled from 'styled-components/macro'

const Container = styled.div`
  padding-bottom: 3rem;
  margin-bottom: 0.5rem;
`

const FutureEventsContainer = ({ shownEncounters, patient }) => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(
      search('Appointment', {
        patient: `Patient/${patient.id}`,
        _sort: '-_id'
      })
    )
  }, [patient, dispatch])
  
  const [appointment] = useSelector(
    getFutureAppointments(patient.id, MEDEO_APPOINTMENT_TYPE_TELECONSULTATION)
  )

  /* Rules for displaying components */
  const hasOngoingTLC =
    appointment != null &&
    // this is to be sure there are no problem with appointment bad fulfilled
    !isLastEncounterRemoteConsultation(shownEncounters)

  if (!hasOngoingTLC) {return null}

  return (
    <Container>
      <DateHeader>
        <Trans>Future</Trans>
      </DateHeader>
      <FutureEncounterCard appointment={appointment} patient={patient} />
    </Container>
  )
}

export default FutureEventsContainer
