import { FHIR_RESOURCES_RECEIVED } from '../actions'
import { camelCase, snakeCase } from 'lodash'
import { appendAllUniqueIds, reduceById, removeById } from './index'
import { combineReducers } from 'redux'

/**
 * Creates simple reducers that reacts to a definitive set of actions
 * These simple reducers can not be used everywhere
 * @param resourceType
 * @returns {Reducer<unknown>}
 */
const createSimpleReducers = resourceType => {
  const allIds = (state = [], action) => {
    const { type, payload } = action
    switch (type) {
      case FHIR_RESOURCES_RECEIVED:
      case `${snakeCase(resourceType).toUpperCase()}S_RECEIVED`:
        return appendAllUniqueIds(state, payload[resourceType])
      case `${snakeCase(resourceType).toUpperCase()}_RECEIVED`:
        // when a POST occurs, the resource nested in the payload with a
        // camelCase syntax. It looks like this:
        // payload = { medicationRequest: { resourceType: 'MedicationRequest', /*...*/ } }
        // TODO: refactor the ducks + action to provide a consistent syntax fo fhir resources
        return appendAllUniqueIds(state, payload[camelCase(resourceType)])
      case `${snakeCase(resourceType).toUpperCase()}_REMOVED`:
        return state.filter(id => id !== payload[camelCase(resourceType)].id)
      default:
        return state
    }
  }
  const byId = (state = {}, action) => {
    const { type, payload } = action
    switch (type) {
      case FHIR_RESOURCES_RECEIVED:
      case `${snakeCase(resourceType).toUpperCase()}S_RECEIVED`:
        return reduceById(state, payload[resourceType])
      case `${snakeCase(resourceType).toUpperCase()}_RECEIVED`:
        return {
          ...state,
          [payload[camelCase(resourceType)].id]:
            payload[camelCase(resourceType)],
        }
      case `${snakeCase(resourceType).toUpperCase()}_REMOVED`:
        return removeById(state, payload[camelCase(resourceType)])
      default:
        return state
    }
  }
  return combineReducers({
    allIds,
    byId,
  })
}

export default createSimpleReducers
