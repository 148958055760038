import React from 'react';
import { useI18n } from '../../utils/I18nHookAdapter';
import { t } from '@lingui/macro';
import {
  BLOOD_PRESSURE_MONITOR_SNOMED,
  OTOSCOPE_SNOMED,
  OXIMETER_SNOMED,
  STETHOSCOPE_SNOMED,
  THERMOMETER_SNOMED,
  DERMATOSCOPE_SNOMED
} from '../../Device/codes';
import styled from 'styled-components/macro';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0.5rem 2rem 0.5rem 0.5rem;
  align-items: center;
`;

const Circle = styled.div`
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background: ${p => p.color};
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.5rem;
`;

class DeviceAttributes {
  constructor(color, name) {
    this.color = color;
    this.name = name;
  }
}

/**
 * Get the device display attributes (i.e color and name) given its snomed code
 *
 * @param {String} code: the device snomed code
 * @param {*} i18n
 * @returns {DeviceAttributes} (with the name already translated)
 */
const getDeviceAttributes = (code, i18n) => {
  switch (code) {
    case BLOOD_PRESSURE_MONITOR_SNOMED:
      return new DeviceAttributes('#fff000', i18n._(t`Blood pressure monitor`));
    case OXIMETER_SNOMED:
      return new DeviceAttributes('#ff8b00', i18n._(t`Oximeter`));
    case THERMOMETER_SNOMED:
      return new DeviceAttributes('#a14bde', i18n._(t`Thermometer`));
    case STETHOSCOPE_SNOMED:
      return new DeviceAttributes('#007acc', i18n._(t`Stethoscope`));
    case OTOSCOPE_SNOMED:
      return new DeviceAttributes('#16ad20', i18n._(t`Otoscope`));
    case DERMATOSCOPE_SNOMED:
      return new DeviceAttributes('#de2d21', i18n._(t`Mobile camera`));
    default:
      return new DeviceAttributes('', '');
  }
};

const DeviceItem = ({ deviceCode, number }) => {
  const i18n = useI18n();
  const deviceAttributes = getDeviceAttributes(deviceCode, i18n);

  // I added 1 to the number of the device so it's less misleading for the user
  return (
    <Container>
      <Circle color={deviceAttributes.color}>{number + 1}</Circle>
      {deviceAttributes.name}
    </Container>
  );
};

export default DeviceItem;
