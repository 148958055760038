export const CREATING_PROCEDURE_REQUEST = 'CREATING_PROCEDURE_REQUEST'
export const UPDATING_PROCEDURE_REQUEST = 'UPDATING_PROCEDURE_REQUEST'
export const FETCHING_PROCEDURE_REQUESTS_FROM_PERFORMER =
  'FETCHING_PROCEDURE_REQUESTS_FROM_PERFORMER'
export const FETCHING_LAST_PROCEDURE_REQUESTS_ACTIVE =
  'FETCHING_LAST_PROCEDURE_REQUESTS_ACTIVE'
export const FETCHING_PROCEDURE_REQUEST = 'FETCHING_PROCEDURE_REQUEST'

export const fetchLastProcedureRequestsActive = () => ({
  type: FETCHING_LAST_PROCEDURE_REQUESTS_ACTIVE,
  payload: {},
})

export const updateProcedureRequest = procedureRequest => ({
  type: UPDATING_PROCEDURE_REQUEST,
  payload: { ...procedureRequest, status: 'completed' },
})
