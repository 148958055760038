import {
  failReceivingFhirResource,
  groupResources,
  receiveFhirResources,
} from '../Shared/actions'
import { useDispatch } from 'react-redux'
import { useCallback, useReducer } from 'react'
import { createFhirClient } from '../Shared/sagas'

const lazySearchInitialState = {
  error: undefined,
  loading: false,
  data: undefined,
  called: false,
  fetchMore: undefined,
}

const lazySearchReducer = (state, action) => {
  switch (action.type) {
    case 'init':
      return lazySearchInitialState
    case 'fetching':
      return {
        ...state,
        error: undefined,
        loading: true,
        called: true,
      }
    case 'fetched':
      return {
        ...state,
        data: groupResources(action.payload.data.entry),
        bundle: action.payload.data,
        nextUrl:
          action.payload.data.link?.find?.(link => link.relation === 'next')
            ?.url ?? null,
        error: undefined,
        loading: false,
        called: true,
      }
    case 'fetchedMore':
      return {
        ...state,
        data: groupResources(action.payload.data.entry, state.data),
        bundle: action.payload.data,
        error: undefined,
        loading: false,
        called: true,
      }
    case 'failed':
      return {
        ...state,
        data: undefined,
        error: action.payload,
        loading: false,
        called: true,
      }
    default:
      return state
  }
}

/**
 * this version of useLazySearch take variables when called not upon creation
 *
 * @param {*} type
 * @param {*} query
 */
const useLazySearch = () => {
  const reduxDispatch = useDispatch()
  const [state, dispatch] = useReducer(
    lazySearchReducer,
    lazySearchInitialState
  )
  const fetchMore = async () => {
    try {
      dispatch({ type: 'fetching' })
      const fhirClient = await createFhirClient()
      const response = await fhirClient.nextPage({
        bundle: state.bundle,
      })
      reduxDispatch(receiveFhirResources(response.data))
      dispatch({ type: 'fetchedMore', payload: response })
      return groupResources(response.data.entry)
    } catch (e) {
      reduxDispatch(failReceivingFhirResource('Patient', e))
      dispatch({ type: 'failed', payload: e })
    }
  }
  const run = useCallback(
    async (type, query) => {
      try {
        dispatch({ type: 'fetching' })
        const fhirClient = await createFhirClient()
        const response = await fhirClient.search({
          type,
          query,
        })
        reduxDispatch(receiveFhirResources(response.data))
        dispatch({ type: 'fetched', payload: response })
        return groupResources(response.data.entry)
      } catch (e) {
        reduxDispatch(failReceivingFhirResource(type, e))
        dispatch({ type: 'failed', payload: e })
      }
    },
    [reduxDispatch]
  )

  /**
   * Here we append fetchMore to the state that is returned from the hooks
   * this hack won't trigger changes on the hook and will reduce rerenders
   * if the bundle return a next link we define the fetchMore function
   * otherwise we set it to undefined
   * this allows us to detect if we should display a load more button or not
   */
  if (state.bundle?.link?.find?.(link => link.relation === 'next') == null) {
    state.fetchMore = undefined
  } else {
    state.fetchMore = fetchMore
  }
  return [run, state]
}
export default useLazySearch
