import { put, takeEvery, take, select } from 'redux-saga/effects'
import { createNotification } from './actions'
import * as fromPatient from '../Patient/actions'
import * as fromEncounter from '../Encounter/actions'
import * as fromAuth from '../Auth/actions'
import * as fromDB from '../Shared/actions/db'
import * as fromClinicalImpression from '../ClinicalImpression/actions'
import * as fromProcedureRequest from '../ProcedureRequest/actions'
import * as fromTeleconsultation from '../Teleconsultation/actions'
import * as fromQuestionnaireResponse from '../QuestionnaireResponse/actions'

// imports for I18n
import { setupI18n } from '@lingui/core'
import englishMessages from '../locales/en/messages.js'
import frenchMessages from '../locales/fr/messages.js'
import { t } from '@lingui/macro'

// other imports
import { isQuestionnaireOfType } from '../Questionnaire/utils'
import { getQuestionnaireById } from '../Questionnaire/selector'
import { MEDEO_CODE_FOR_PRACTITIONER_DOCUMENT_MODEL_QUESTIONNAIRE } from '../utils/codes'
import { getIdByReference } from '../Shared/utils'

// setup the i18n object with active language and catalogs
let i18n = setupI18n({
  language: 'fr',
  catalogs: {
    en: englishMessages,
    fr: frenchMessages
  }
})

export function* createNotificationSaga(action) {
  let notificationType = null
  let message = ''
  const timeout = 5000

  switch (action.type) {
    // success notifications
    case fromDB.getSavedActionName('Patient'):
      notificationType = 'success'
      message = i18n._(t`Patient added`)
      break
    case fromEncounter.STOPPING_ENCOUNTER:
      yield take(fromEncounter.ENCOUNTER_RECEIVED)
      notificationType = 'success'
      message = i18n._(t`Encounter finished`)
      break
    case fromClinicalImpression.CLINICAL_IMPRESSION_RECEIVED:
      notificationType = 'success'
      message = i18n._(t`Review sent`)
      break
    case fromTeleconsultation.TELECONSULTATION_FINISHED:
      notificationType = 'success'
      message = i18n._(t`Teleconsultation finished`)
      break
    case fromProcedureRequest.CREATING_PROCEDURE_REQUEST:
      notificationType = 'success'
      message = i18n._(t`Request sent`)
      break
    // NOTE(charles): the following case has too many side effects
    case fromQuestionnaireResponse.QUESTIONNAIRE_RESPONSE_RECEIVED:
      // here we get the identifier of the related questionnaire
      if (action.payload && action.payload.questionnaireResponse) {
        const questionnaireReference =
          action.payload.questionnaireResponse?.questionnaire?.reference

        const questionnaireId =
          questionnaireReference != null
            ? getIdByReference(questionnaireReference)
            : null
        const questionnaire =
          questionnaireId != null
            ? yield select(getQuestionnaireById(questionnaireId))
            : null
        // the line bellow set the default value for notificationType to null,
        // as other questionnaireType that should not trigger any notification
        // NOTE(charles): the notificationType set to null can cause white screen
        // c.f. https://medeohealth.atlassian.net/browse/MED-2016
        notificationType = null
        if (
          questionnaire != null &&
          isQuestionnaireOfType(
            questionnaire,
            MEDEO_CODE_FOR_PRACTITIONER_DOCUMENT_MODEL_QUESTIONNAIRE
          )
        ) {
          notificationType = 'success'
          message = i18n._(t`Model saved`)
        }
      }
      break
    // error notifications
    case fromEncounter.FAIL_RECEIVING_ENCOUNTER:
    case fromPatient.FAIL_RECEIVING_PATIENT:
      notificationType = 'error'
      message = i18n._(t`Disconnected from server`)
      break
    // warning notifications
    // information notifications
    case fromAuth.SIGN_IN_REQUESTED:
      notificationType = 'information'
      message = i18n._(t`Welcome on Officine`)
      break
    case fromAuth.SIGN_OUT_REQUESTED:
      notificationType = 'information'
      message = i18n._(t`Logged out`)
      break
    default:
      return 'The action name for notification is not recognized'
  }

  // the condition on notificationType is for notification on questionnaireResponse. It'not the same as the quick fix bellow.
  // fast fix : exception for linearize params
  // TODO : fix the error for real
  // TODO(charles): revamp the notification saga for something more reliable
  let displayNotify = true
  if (
    action.payload &&
    // the following condition prevent the bug from MED-2016 from happening
    // c.f. https://medeohealth.atlassian.net/browse/MED-2016
    // we want to test if there is an error and if this error is different this string
    action.payload.error &&
    action.payload.error === 'could not linearizeParams undefined'
  )
    {displayNotify = false}
  if (notificationType == null) {displayNotify = false}
  if (displayNotify)
    {yield put(createNotification(message, notificationType, timeout))}
}

function* rootSaga() {
  yield takeEvery(
    [
      fromDB.getSavedActionName('Patient'),
      fromEncounter.STOPPING_ENCOUNTER,
      fromEncounter.FAIL_RECEIVING_ENCOUNTER,
      fromClinicalImpression.CLINICAL_IMPRESSION_RECEIVED,
      fromPatient.FAIL_RECEIVING_PATIENT,
      fromAuth.SIGN_IN_REQUESTED,
      fromAuth.SIGN_OUT_REQUESTED,
      fromProcedureRequest.CREATING_PROCEDURE_REQUEST,
      fromTeleconsultation.TELECONSULTATION_FINISHED,
      fromQuestionnaireResponse.QUESTIONNAIRE_RESPONSE_RECEIVED
    ],
    createNotificationSaga
  )
}

export default rootSaga
