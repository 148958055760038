import React from 'react'
import DefaultDevicePage from './DefaultDevicePage'
import VideoSequenceManager from '../../Teleconsultation/containers/VideoSequenceManager'
import { useSelector } from 'react-redux'
import { getCurrentOrganization } from '../../Organization/selector'
import styled from 'styled-components/macro'
import { getDeviceById } from '../ducks'

const VideoDiv = styled.div`
  height: 500px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  background-color: grey;
`

const Device = ({ deviceId }) => {
  const device = useSelector(getDeviceById(deviceId))
  const organization = useSelector(getCurrentOrganization)
  // display specific information depending on the model of the device
  switch (device.model) {
    case 'PCP-USB':
    case 'DE300':
    case 'DE400':
    case 'DE500':
      return (
        <VideoDiv>
          <VideoSequenceManager
            // this value has to be set to create a twilio room.
            // Of course the patient 0 will never exist, and if it will,
            //... it's related resource will not be edited in this component.
            patientID={0}
            patientSide={true}
            organizationName={organization.name}
            identifier={'test'}
            displayReturnButton={false}
            displayStethoscopeButton={false}
          />
        </VideoDiv>
      )

    default:
      return <DefaultDevicePage device={device} />
  }
}

export default Device
