import { getFhirResourceFromSubjectId } from '../Shared/selectors'
import { getTypeAndIdFromLocalReference } from '../utils/fhir'
import { ReduxState } from '../Shared/ducks/types'
import { MedicationStatement, Reference } from 'fhir-stu3'

export const getMedicationStatementFromSubjectId = (
  state: ReduxState,
  subjectId: string
) => getFhirResourceFromSubjectId(state, 'MedicationStatement', subjectId)

/**
 * get medication from statements currently active of a specific patient
 * @param state
 * @param subjectId
 * @returns {*}
 */
export const getMedicationFromSubjectId = (
  state: ReduxState,
  subjectId: string
) =>
  getMedicationStatementFromSubjectId(state, subjectId)
    .filter((statement: MedicationStatement) => statement.status === 'active')
    .map((statement: MedicationStatement) => statement.medicationReference)
    .filter((medication: Reference) => medication?.reference != null)
    .map((medication: Reference) => {
      const [, id] = getTypeAndIdFromLocalReference(medication.reference)
      return state.Medication.byId[id]
    })

export const getMedicationStatementFromMedicationId = (
  medicationId: string
) => (state: ReduxState): MedicationStatement | undefined =>
  state.MedicationStatement.allIds
    .map(id => state.MedicationStatement.byId[id])
    ?.find(
      s => s.medicationReference?.reference === 'Medication/' + medicationId
    )
