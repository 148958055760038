export const SAVE_QUESTIONNAIRE_RESPONSE = 'SAVE_QUESTIONNAIRE_RESPONSE'
export const UPDATE_QUESTIONNAIRE_RESPONSE = 'UPDATE_QUESTIONNAIRE_RESPONSE'
export const FETCHING_QUESTIONNAIRE_RESPONSE_BY_ENCOUNTER =
  'FETCHING_QUESTIONNAIRE_RESPONSE_BY_ENCOUNTER'
export const FETCHING_QUESTIONNAIRE_RESPONSE_BY_SUBJECT =
  'FETCHING_QUESTIONNAIRE_RESPONSE_BY_SUBJECT'
export const QUESTIONNAIRE_RESPONSE_RECEIVED = 'QUESTIONNAIRE_RESPONSE_RECEIVED'

export const FETCHING_QUESTIONNAIRE_RESPONSE = 'FETCHING_QUESTIONNAIRE_RESPONSE'
export const SAVING_CERFA_AND_DOCUMENTS = 'SAVING_CERFA_AND_DOCUMENTS'
export const CERFAS_AND_DOCUMENTS_SAVED = 'CERFAS_AND_DOCUMENTS_SAVED'

export const saveQuestionnaireResponse = (
  resource,
  practitionerID,
  patientID,
  questionnaireId,
  questionnaireType
) => ({
  type: SAVE_QUESTIONNAIRE_RESPONSE,
  payload: {
    resource,
    practitionerID,
    patientID,
    questionnaireId,
    questionnaireType
  }
})

export const updateQuestionnaireResponse = (resource) => ({
  type: UPDATE_QUESTIONNAIRE_RESPONSE,
  payload: resource
})

export const cerfasAndDocumentsSaved = (resource) => ({
  type: CERFAS_AND_DOCUMENTS_SAVED,
  payload: resource
})

export const loadQuestionnaireResponseFromEncounter = (
  encounterID,
  patientID
) => ({
  type: FETCHING_QUESTIONNAIRE_RESPONSE_BY_ENCOUNTER,
  payload: {
    encounterID,
    patientID
  }
})

export const saveCerfaAndDocuments = (patientID, practitionerID, answers) => ({
  type: SAVING_CERFA_AND_DOCUMENTS,
  payload: { patientID, practitionerID, answers }
})

export const savePaymentMethodQuestionnaireResponse = (
  practitionerID,
  patientID,
  questionnaireId,
  sourceValue,
  encounterID
) => ({
  type: SAVE_QUESTIONNAIRE_RESPONSE,
  payload: {
    resource: {
      status: 'completed',
      item: [
        {
          linkId: '1',
          answer: [{ valueString: sourceValue }]
        }
      ]
    },
    practitionerID,
    patientID,
    questionnaireId,
    encounterID
  }
})
