import { takeEvery, select, call, put, all } from 'redux-saga/effects';
import {
  SAVE_QUESTIONNAIRE_RESPONSE,
  FETCHING_QUESTIONNAIRE_RESPONSE_BY_ENCOUNTER,
  FETCHING_QUESTIONNAIRE_RESPONSE_BY_SUBJECT,
  UPDATE_QUESTIONNAIRE_RESPONSE,
  FETCHING_QUESTIONNAIRE_RESPONSE,
  SAVING_CERFA_AND_DOCUMENTS,
  cerfasAndDocumentsSaved
} from './actions';
import {
  searchFhirResourceWorker,
  saveFhirResourceWorker
} from '../Shared/sagas';
import QuestionnaireBuilder from '../utils/fhir/QuestionnaireBuilder';
import { getCurrentEncounter } from '../Encounter/selectors';
import {
  MEDEO_CODE_FOR_PRACTITIONER_DOCUMENT_MODEL_QUESTIONNAIRE,
  MEDEO_PAYMENT_METHOD_CHOICE_IDENTIFIER,
  MEDEO_CARE_SHEET_QUESTIONNAIRE_IDENTIFIER,
  MEDEO_DOCUMENT_IDENTIFIER,
  MEDEO_SICK_LEAVE_QUESTIONNAIRE_IDENTIFIER,
  MEDEO_DRUG_PRESCRIPTION_IDENTIFIER,
  MEDEO_PARAMEDICAL_PRESCRIPTION_IDENTIFIER
} from '../utils/codes';
import { getAllQuestionnairesByIdentifierAndSystem } from '../Questionnaire/selector';
import { TELECONSULTATION_QUESTIONNAIRE_SYSTEM } from '../PlanDefinition/utils';
import { getCerfaBinaryId } from '../Cerfa/utils';

function* saveResponse(action) {
  const {
    resource,
    practitionerID,
    patientID,
    questionnaireId,
    questionnaireType,
    encounterID
  } = action.payload;
  let fhirQuestionnaireResponse = {};

  // Note(Pauline): this is a small hack for a bug fix as right now the process
  // to get/pass the encounterID is not consistent throughout the platform.
  // As this function is called from different places, I am selecting back the encounter here
  // to be sure not to break something else somewhere else.
  // TODO: try to make this consistent, like by passing it as an argument from each place this function
  // is called (be careful with all the TLC and pre-TLC processes as well)
  const encounter = yield select(getCurrentEncounter);
  const currentencounterID = encounterID ?? encounter?.id;

  if (resource) {
    const builder = new QuestionnaireBuilder({
      resourceType: 'QuestionnaireResponse'
    });
    switch (questionnaireType) {
      // if the questionnaire is a type of Model, the subject is the practitioner
      case MEDEO_CODE_FOR_PRACTITIONER_DOCUMENT_MODEL_QUESTIONNAIRE:
        fhirQuestionnaireResponse = builder
          .appendAuthored()
          .appendStatus()
          .appendQuestionnaire(questionnaireId)
          .appendAuthor('Practitioner/' + practitionerID)
          .appendSubject('Practitioner/' + practitionerID)
          .appendItem(resource.item);
        break;
      case MEDEO_PAYMENT_METHOD_CHOICE_IDENTIFIER:
        fhirQuestionnaireResponse = builder
          .appendAuthored()
          .appendStatus()
          .appendQuestionnaire(questionnaireId)
          .appendContext(currentencounterID)
          .appendAuthor('Practitioner/' + practitionerID)
          .appendSource('Patient/' + patientID)
          .appendSubject('Patient/' + patientID)
          .appendItem(resource.item);
        break;
      default:
        fhirQuestionnaireResponse = builder
          .appendAuthored()
          .appendStatus()
          .appendQuestionnaire(questionnaireId)
          .appendContext(currentencounterID)
          .appendAuthor('Practitioner/' + practitionerID)
          .appendSource('Patient/' + patientID)
          .appendSubject('Patient/' + patientID)
          .appendItem(resource.item);
    }
  }
  return yield call(saveFhirResourceWorker, fhirQuestionnaireResponse);
}

/**
 * Specific case in the TLC in order to create a single questionnaire response containing
 * both the documents uploaded by the user during the TLC as well as the cerfas generated
 * out of the responses for the sick leave and the care sheet
 *
 * @param {*} action
 */
function* saveCerfaAndDocuments(action) {
  const { patientID, practitionerID, answers } = action.payload;

  const encounter = yield select(getCurrentEncounter);
  const encounterID = encounter?.id;

  const [caresheetQuestionnaire] = yield select(
    getAllQuestionnairesByIdentifierAndSystem,
    MEDEO_CARE_SHEET_QUESTIONNAIRE_IDENTIFIER,
    TELECONSULTATION_QUESTIONNAIRE_SYSTEM
  );

  const [sickLeaveQuestionnaire] = yield select(
    getAllQuestionnairesByIdentifierAndSystem,
    MEDEO_SICK_LEAVE_QUESTIONNAIRE_IDENTIFIER,
    TELECONSULTATION_QUESTIONNAIRE_SYSTEM
  );

  const [documentsQuestionnaire] = yield select(
    getAllQuestionnairesByIdentifierAndSystem,
    MEDEO_DOCUMENT_IDENTIFIER,
    TELECONSULTATION_QUESTIONNAIRE_SYSTEM
  );
  const [drugsQuestionnaire] = yield select(
    getAllQuestionnairesByIdentifierAndSystem,
    MEDEO_DRUG_PRESCRIPTION_IDENTIFIER,
    TELECONSULTATION_QUESTIONNAIRE_SYSTEM
  );

  const [paramedicalQuestionnaire] = yield select(
    getAllQuestionnairesByIdentifierAndSystem,
    MEDEO_PARAMEDICAL_PRESCRIPTION_IDENTIFIER,
    TELECONSULTATION_QUESTIONNAIRE_SYSTEM
  );

  const cerfaResponses = answers.filter(
    answer =>
      answer.questionnaireId === sickLeaveQuestionnaire.id ||
      answer.questionnaireId === caresheetQuestionnaire.id
  );
  let cerfaAttachments = [];
  try {
    // Here we build the questionnaire responses concerning the cerfas specifically
    // using all, we wait for all of the effects to complete
    cerfaAttachments = yield all(
      cerfaResponses.map(function*(answer) {
        const response = yield call(saveResponse, {
          payload: {
            resource: answer.answer,
            practitionerID,
            patientID,
            questionnaireId: answer.questionnaireId
          }
        });

        // Eventually, we call the saga to generate cerfas out of the FHIR response
        const attachment = yield getCerfaBinaryId(patientID, response.id);
        // and return the attachment value for each
        return attachment != null ? { valueAttachment: attachment } : null;
      })
    );
  } catch (error) {
    console.warn(error);
    yield put(cerfasAndDocumentsSaved());
  }

  // Extract the order response
  // similarly to what we do for cerfas except orders are already created
  // therefore responses contains value attachment
  // this solution is quick and dirty, we could refactor this part later
  // a good starting point would be to refactor the PlanDefinitionContext
  const orderResponses = answers.filter(
    answer =>
      answer.questionnaireId === paramedicalQuestionnaire.id ||
      answer.questionnaireId === drugsQuestionnaire.id
  );
  const orderAttachments = orderResponses.flatMap(
    r => r.answer?.item?.[0]?.answer
  );

  // Extract the document response
  const documentResponse = answers.find(
    answer => answer.questionnaireId === documentsQuestionnaire.id
  )?.answer?.item?.[0].answer;

  // Build a response item containing:
  // - the documents
  // - the results of the cerfas
  // - the orders
  const items = [
    ...(documentResponse != null ? documentResponse : []),
    ...cerfaAttachments.filter(attachment => attachment != null),
    ...orderAttachments
  ];

  // Create and save the corresponding questionnaire response
  if (items.length !== 0) {
    const builder = new QuestionnaireBuilder({
      resourceType: 'QuestionnaireResponse'
    });

    const questionnaireResponse = builder
      .appendAuthored()
      .appendStatus()
      .appendQuestionnaire(documentsQuestionnaire.id)
      .appendContext(encounterID)
      .appendAuthor('Practitioner/' + practitionerID)
      .appendSource('Patient/' + patientID)
      .appendSubject('Patient/' + patientID)
      .appendItem([
        {
          linkId: '1',
          answer: [items]
        }
      ]);

    yield call(saveFhirResourceWorker, questionnaireResponse);
  }
  yield put(cerfasAndDocumentsSaved());
}

function* rootSaga() {
  yield takeEvery([SAVE_QUESTIONNAIRE_RESPONSE], saveResponse);
  yield takeEvery(
    [UPDATE_QUESTIONNAIRE_RESPONSE],
    saveFhirResourceWorker,
    a => a.payload
  );
  yield takeEvery(
    [FETCHING_QUESTIONNAIRE_RESPONSE_BY_ENCOUNTER],
    searchFhirResourceWorker,
    'QuestionnaireResponse',
    ({ payload: p }) => ({
      context: 'Encounter/' + p.encounterID,
      subject: 'Patient/'+ p.patientID,
      _include: 'QuestionnaireResponse:questionnaire'
    })
  );
  yield takeEvery(
    [FETCHING_QUESTIONNAIRE_RESPONSE_BY_SUBJECT],
    searchFhirResourceWorker,
    'QuestionnaireResponse',
    ({ payload: p }) => ({ subject: p.reference })
  );

  yield takeEvery(
    [FETCHING_QUESTIONNAIRE_RESPONSE],
    searchFhirResourceWorker,
    'QuestionnaireResponse',
    ({ payload: p }) => ({ ...p })
  );
  yield takeEvery(SAVING_CERFA_AND_DOCUMENTS, saveCerfaAndDocuments);
}

export default rootSaga;
