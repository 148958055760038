import React, { useContext } from 'react'
import { useParams } from '@reach/router'
import { VideoScreenContext } from './VideoSequenceManager'
import { Button, Spinner } from '../../Components'
import { connectUser } from '../utils'
import { Trans } from '@lingui/macro'
import styled from 'styled-components/macro'
import { STARTING_LOADING } from '../reducer'

const Component = styled.div`
  display: flex;
  justify-content: center;
`

const Loading = styled.div`
  color: #fff;
  margin-right: 0.5rem;
`

/**
 * Display Button
 * @param remoteMediaContainer
 * @param localMediaContainer
 * @returns {*}
 * @constructor
 */
const Controls = ({ remoteMediaContainer, localMediaContainer }) => {
  const [videoState, dispatch] = useContext(VideoScreenContext)

  const organizationName = videoState.organizationName
  const identifier = videoState.identifier

  // useParams is used here to retrieve the preconsultationId
  // it is required for mixpanel trackers to tell which TLC is joined
  // see handleConnect for usage
  const params = useParams()
  return (
    <Component>
      {videoState.status === 'loading' ? (
        <>
          <Loading>
            <Trans>loading</Trans>
          </Loading>
          <Spinner />
        </>
      ) : (
        <Button
          variant="outline"
          color="aqua"
          onClick={() => {
            dispatch({ type: STARTING_LOADING })
            return connectUser({
              identifier,
              remoteMediaContainer,
              localMediaContainer,
              videoState,
              dispatch,
              // 👇 used only for Mixpanel
              encounterID: params?.preconsultationId,
              organizationName: organizationName
            })
          }}
        >
          <Trans>Join teleconsultation</Trans>
        </Button>
      )}
    </Component>
  )
}

export default Controls
