import React from 'react'
import { Card } from '../../Components'
import Link from '../../Shared/components/Link'
import { Trans } from '@lingui/macro'
import Container from './Container'
import { SignUpContextProvider } from './SignUpContext'
import CustomPasswordRecoveryForm from './CustomPasswordRecoveryForm'
import { ReactComponent as Logotype } from '../../logo.svg'
import styled from 'styled-components/macro'

const Header = styled.header`
  display: flex;
  justify-content: center;
  & > svg {
    height: 5rem;
  }
  margin-bottom: 2.5rem;
`
const PasswordRecovery = () => {
  return (
    <Container>
      <Card>
        <Header>
          <Logotype />
        </Header>
        <SignUpContextProvider>
          <CustomPasswordRecoveryForm />
        </SignUpContextProvider>
        <Link to="/">
          <Trans>Back to sign in</Trans>
        </Link>
      </Card>
    </Container>
  )
}

export default PasswordRecovery
