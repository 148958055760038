import {
  MEDEO_ENCOUNTER_TYPE_PRE_TELECONSULTATION,
  MEDEO_ENCOUNTER_TYPE_TELECONSULTATION_CONSULTATION
} from '../utils/encounter-type'
import { Trans } from '@lingui/macro'
import {
  MEDEO_CHARGE_ID_SYSTEM,
  MEDEO_PAYMENT_AMOUNT_SYSTEM,
  MEDEO_PAYMENT_METHOD_SYSTEM,
  MEDEO_PAYMENT_STATUS_SYSTEM,
  MEDEO_PROCEDURE_REQUEST_CODE_SYSTEM
} from '../utils/codes'
import { organizationStringUrl } from '../Teleconsultation/utils'

export const isEncounterTypePreConsultation = (
  type,
  matchingCode = MEDEO_ENCOUNTER_TYPE_PRE_TELECONSULTATION
) => {
  return type?.[0]?.coding?.[0]?.code === matchingCode
}

export const getFirstCodeFromEncounter = encounter => {
  const firstCode = encounter?.type?.[0]?.coding?.[0]?.code
  // returns null if the first code is undefined
  return firstCode || null
}

// return the first type of the encounter
export const getCodingFromEncounter = encounter => {
  return encounter?.type?.[0].coding?.find(
    c => c.system === 'http://medeo.io/fhir/ValueSet/encounter-type'
  )?.code
}

// we want to know if the last encounter done is a type of remote consultation
// encounterList will be sort in order to have the most recent in first
export const isLastEncounterRemoteConsultation = encounterList => {
  return (
    encounterList.length > 0 &&
    getCodingFromEncounter(encounterList[0]) ===
      MEDEO_ENCOUNTER_TYPE_TELECONSULTATION_CONSULTATION
  )
}

export const getHeaderFromEncounter = encounter => {
  const encounterType = getCodingFromEncounter(encounter)
  switch (encounterType) {
    case MEDEO_ENCOUNTER_TYPE_PRE_TELECONSULTATION:
      return <Trans>Preconsultation</Trans>
    default:
      return <Trans>Examination</Trans>
  }
}

export const computePaymentNotice = (
  procedureRequestId,
  organizationId,
  price,
  paymentStatus,
  paymentMethod,
  chargeId
) => {
  const paymentStatusCodings = [
    {
      system: MEDEO_PAYMENT_METHOD_SYSTEM,
      code: paymentMethod
    },
    {
      system: MEDEO_PAYMENT_STATUS_SYSTEM,
      code: paymentStatus
    }
  ]
  // if there is a charge, then we add the following codings
  // this will help us link Stripe Resources with Fhir
  // there won't be a charge if the practitioner accepts third party payer

  if (chargeId) {
    paymentStatusCodings.push([
      {
        system: MEDEO_PAYMENT_AMOUNT_SYSTEM,
        code: price
      },
      {
        system: MEDEO_CHARGE_ID_SYSTEM,
        code: chargeId
      }
    ])
  }
  return {
    resourceType: 'PaymentNotice',
    status: 'active',
    request: { reference: `ProcedureRequest/${procedureRequestId}` },
    created: new Date().toISOString(),
    organization: { reference: `Organization/${organizationId}` },
    paymentStatus: {
      coding: paymentStatusCodings
    }
  }
}

export const computeProcedureRequest = (
  encounterID,
  performerId,
  patientID,
  procedureCode,
  organization,
  currentPractitionerID
) => {
  return {
    resourceType: 'ProcedureRequest',
    status: 'active',
    intent: 'proposal',
    code: {
      coding: [
        {
          system: MEDEO_PROCEDURE_REQUEST_CODE_SYSTEM,
          code: procedureCode
        }
      ]
    },
    context: { reference: 'Encounter/' + encounterID },
    performer: { reference: 'Organization/' + performerId },
    subject: { reference: 'Patient/' + patientID },
    requester: {
      agent: { reference: 'Practitioner/' + currentPractitionerID },
      onBehalfOf: {
        reference: 'Organization/' + organization?.id,
        display: organizationStringUrl(organization)
      }
    },
    authoredOn: new Date().toISOString()
  }
}
