import { combineReducers, Reducer } from 'redux'
import {
  ALLERGY_INTOLERANCE_RECEIVED,
  ALLERGIES_INTOLERANCES_RECEIVED,
  ALLERGY_INTOLERANCE_REMOVED
} from './actions'
import { reduceById, appendAllUniqueIds, removeById } from '../Shared/ducks'
import { ReduxState, ById } from '../Shared/ducks/types'
import { FHIR_RESOURCES_RECEIVED } from '../Shared/actions'
import { AllergyIntolerance } from 'fhir-stu3'

export const allIds: Reducer<string[]> = (state = [], action) => {
  const { type, payload } = action
  switch (type) {
    case FHIR_RESOURCES_RECEIVED:
    case ALLERGIES_INTOLERANCES_RECEIVED:
      return appendAllUniqueIds(state, payload.AllergyIntolerance)
    case ALLERGY_INTOLERANCE_RECEIVED:
      return appendAllUniqueIds(state, payload.allergyIntolerance)
    case ALLERGY_INTOLERANCE_REMOVED:
      return state.filter(id => id !== payload.allergyIntolerance.id)
    default:
      return state
  }
}

export const byId: Reducer<ById<AllergyIntolerance>> = (state = {}, action) => {
  const { payload, type } = action
  switch (type) {
    case ALLERGY_INTOLERANCE_RECEIVED:
      return {
        ...state,
        [payload.allergyIntolerance
          .id]: payload.allergyIntolerance as AllergyIntolerance
      } as ById<AllergyIntolerance>
    case FHIR_RESOURCES_RECEIVED:
    case ALLERGIES_INTOLERANCES_RECEIVED:
      return reduceById(state, payload.AllergyIntolerance) as ById<
        AllergyIntolerance
      >
    case ALLERGY_INTOLERANCE_REMOVED:
      return removeById(state, payload.allergyIntolerance) as ById<
        AllergyIntolerance
      >
    default:
      return state as ById<AllergyIntolerance>
  }
}

const getAllergies = (state: ReduxState) => {
  return state.allergies.allIds.map((id: string) => state.allergies.byId[id])
}

export const getAllergiesBypatientID = (patientID: string) => (
  state: ReduxState
) => {
  const allergies = getAllergies(state)
  return allergies.filter((allergy: AllergyIntolerance) => {
    return allergy.patient === 'Patient/' + patientID
  })
}
export default combineReducers({
  allIds,
  byId
})
