import { useReducer, useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components/macro'
import Attachment from '../../Attachment/components/Attachment'
import AttachmentArchiveControl from '../../Attachment/components/AttachmentArchiveControl'
import AttachmentViewControl from '../../Attachment/components/AttachmentViewControl'
import DocumentReferenceList from '../../DocumentReference/containers/DocumentReferenceList'
import { save } from '../../Shared/actions'

const Container = styled.div`
  width: 100%;
`

const initialState = {
  list: [],
  current: null,
  progress: 0
}

const reducer = (state, action) => {
  const { type, payload } = action
  switch (type) {
    case 'uploading':
      return {
        ...state,
        current: payload
      }
    case 'uploaded':
      return {
        ...state,
        progress: 0,
        current: null,
        list: [...state.list, payload]
      }
    case 'progress':
      return {
        ...state,
        progress: payload
      }
    default:
      return state
  }
}

const AddAttachmentsForm = ({ encounter, patient }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const [feedBackMessage, setFeedBackMessage] = useState(null)
  const reduxDispatch = useDispatch()

  // update the DocumentReference by changing the status to entered-in-error
  // basically it archives the doc and remove it from the list of shown elements
  const handleClick = (document) => {
    // the documentReference is expected as a parameter
    reduxDispatch(
      save({
        ...document,
        status: 'entered-in-error'
      })
    )
  }

  const handleProgress = (progress) => {
    dispatch({ type: 'progress', payload: progress })
  }

  const handleChange = (attachment) => {
    if (attachment.url == null) {
      return dispatch({ type: 'uploading', payload: attachment })
    }

    dispatch({ type: 'uploaded', payload: attachment })

    reduxDispatch(
      save({
        resourceType: 'DocumentReference',
        content: {
          attachment
        },
        subject: {
          reference: `Patient/${patient.id}`
        },
        context: {
          encounter: {
            reference: `Encounter/${encounter.id}`
          }
        }
      })
    )
  }

  const onError = (message) => {
    setFeedBackMessage(message)
  }

  return (
    <Container>
      {/*
      the DocumentReferenceList will display the attachment from the
      redux store, we also pass current and progress to give the user
      a visual feedback about the upload of a file.
      */}
      <DocumentReferenceList
        patient={patient}
        progress={state.progress}
        current={state.current}
        encounter={encounter}
        onProgress={handleProgress}
        onChange={handleChange}
        onError={onError}
        feedBackMessage={feedBackMessage}
      >
        {(attachment, progress, document) => (
          <Attachment attachment={attachment} progress={progress}>
            {/*
                <Attachment[X]Control/> do not require extra action or info
                to work, they use plain html or js features
              */}
            <AttachmentViewControl attachment={attachment} />
            {/*
              we can remove the elements from the list using the handleClick
              with the current index
            */}
            <AttachmentArchiveControl onClick={() => handleClick(document)} />
          </Attachment>
        )}
      </DocumentReferenceList>
      {feedBackMessage}
    </Container>
  )
}

export default AddAttachmentsForm
