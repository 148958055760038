//TODO refactor the QuestionnaireBuilder to actually append instead of overwrite !
//TODO rename this to QuestionnaireResponse Builder :P
class QuestionnaireBuilder {
  constructor(questionnaireResponse) {
    Object.assign(this, questionnaireResponse)
  }
  appendQuestionnaire(id) {
    this.questionnaire = { reference: 'Questionnaire/' + id }
    return this
  }
  appendStatus() {
    this.status = 'completed'
    return this
  }
  appendContext(id) {
    this.context = { reference: 'Encounter/' + id }
    return this
  }
  appendAuthored() {
    this.authored = new Date().toISOString()
    return this
  }
  appendAuthor(reference) {
    this.author = { reference: reference }
    return this
  }
  appendSource(reference) {
    this.source = { reference: reference }
    return this
  }
  appendSubject(reference) {
    this.subject = { reference: reference }
    return this
  }
  appendLinkId(reference) {
    this.linkId = reference
    return this
  }
  appendValueString(string) {
    this.answer = [{ valueString: string }]
    return this
  }
  addValueString(string) {
    if (!this.answer) {
      this.answer = []
    }
    this.answer.push({ valueString: string })
    return this
  }
  removeValueString(string) {
    // If there is no value checked for now, we do not need to remove any
    if (this.answer == null) {return this}
    this.answer = this.answer.filter(answer => answer.valueString !== string)
    return this
  }
  appendValueBoolean(bool) {
    // On saving, fhir would convert this value string coming from html to
    // a boolean, therefore we change it here in order to already handle the answer as the saved responses
    this.answer = [{ valueBoolean: bool === 'true' ? true : false }]
    return this
  }
  appendValueDate(date) {
    this.answer = [{ valueDate: date }]
    return this
  }
  appendValueInteger(integer) {
    this.answer = [{ valueInteger: integer }]
    return this
  }
  appendValueDecimal(decimal) {
    this.answer = [{ valueDecimal: decimal }]
    return this
  }

  /**
   * append the valueAttachment to the answers array list
   * creates one if it does not exists
   *
   * @param attachment
   * @returns {QuestionnaireBuilder}
   */
  appendValueAttachment(attachment) {
    this.answer =
      Array.isArray(this.answer) && this.answer.length > 0
        ? [...this.answer, { valueAttachment: attachment }]
        : [{ valueAttachment: attachment }]
    return this
  }

  /**
   * remove the valueAttachment to the answers array list
   * creates one if it does not exists
   *
   * @param index
   * @returns {QuestionnaireBuilder}
   */
  removeValueAttachment(index) {
    const newAnswer = [...this.answer]
    newAnswer.splice(index, 1)
    this.answer = newAnswer
    return this
  }

  appendValueCoding(coding) {
    this.answer = [{ valueCoding: coding }]
    return this
  }

  addValueCoding(coding) {
    if (!this.answer) {
      this.answer = []
    }
    this.answer.push({ valueCoding: coding })
    return this
  }

  removeValueCoding(coding) {
    // If there is no value checked for now, we do not need to remove any
    if (this.answer == null) {return this}
    this.answer = this.answer.filter(
      answer => answer.valueCoding?.code !== coding.code
    )
    return this
  }

  appendValueQuantity(value) {
    this.answer = [{ valueQuantity: value }]
    return this
  }
  appendItem(item) {
    if (this.item != null && Array.isArray(this.item)) {
      this.item.push(item)
    } else {
      this.item = item
    }
    return this
  }
}

export default QuestionnaireBuilder
