import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import QuestionnaireSequencer from '../../Questionnaire/components/QuestionnaireSequencer'
import {
  FEUILLE_SOIN_ACTION_VALUE,
  getQuestionnaireIdentifierByAction
} from '../../PlanDefinition/utils'
import { getItemByLinkId } from '../../Questionnaire/utils'

import usePlanDefinition from '../../PlanDefinition/usePlanDefinition'
import { getQuestionnaireByIdentifier } from '../../Questionnaire/selector'

const QuestionnaireTotalPrice = ({ action, readOnly }) => {
  const { planDefinition: state } = usePlanDefinition()
  const [response, setResponse] = useState(null)
  const [totalPriceIdentifier] = getQuestionnaireIdentifierByAction(action)

  const careSheetQuestionnaire = useSelector(
    getQuestionnaireByIdentifier(FEUILLE_SOIN_ACTION_VALUE)
  )

  // this useEffect will take the price value from the careSheet
  // and buildResponse of totalPriceQuestionnaire
  useEffect(() => {
    if (readOnly) {return}
    const careSheetIndex = state.sequenceResponse.findIndex(
      sr =>
        sr.questionnaireId &&
        careSheetQuestionnaire &&
        sr.questionnaireId === careSheetQuestionnaire.id
    )
    const isCareSheetResponseInMyState = careSheetIndex !== -1
    if (isCareSheetResponseInMyState) {
      const careSheetResponse = state.sequenceResponse[careSheetIndex]
      const priceResponse = getItemByLinkId(careSheetResponse.answer, '1.3.7')

      buildResponse(
        parseFloat(priceResponse?.answer?.[0]?.valueQuantity?.value)
      )
    }
  }, [careSheetQuestionnaire, state.sequenceResponse, readOnly])

  // this function is necessary to set the totalPrice from
  // a children component

  const buildResponse = price => {
    setResponse({
      item: [
        {
          linkId: '1',
          answer: [
            {
              valueQuantity: {
                value: price,
                unit: '€'
              }
            }
          ]
        }
      ]
    })
  }

  return (
    <QuestionnaireSequencer
      identifier={totalPriceIdentifier}
      response={response}
      readOnly={readOnly}
    />
  )
}

export default QuestionnaireTotalPrice
