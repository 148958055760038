import Grid from '../../Shared/components/GridLayout'
import BookingRouter from '../../Booking/containers/BookingRouter'
import React from 'react'

/**
 * Booking router needs to be nested in the grid so it can be displayed properly
 * @param props
 * @return {JSX.Element}
 * @constructor
 */
const BookingRouterWrapper = ({ ...props }) => {
  return (
    <Grid.Main variant="center">
      <BookingRouter {...props} />
    </Grid.Main>
  )
}

export default BookingRouterWrapper
