import { takeEvery, call } from 'redux-saga/effects'
import { v4 as uuid } from 'uuid'
import {
  searchFhirResourceWorker,
  saveFhirResourceWorker,
} from '../Shared/sagas'
import {
  CREATING_BINARY,
  FETCHING_DOCUMENT_REFERENCES,
  SAVING_DOCUMENT_REFERENCE,
  SAVING_DOCUMENT_REFERENCE_FROM_BINARY,
  UPDATING_DOCUMENT_REFERENCE,
} from './actions'

import { createClient } from '../Shared/fhir/createClient'
import { extractDocumentReferenceResource } from './model'
import { getTokenFromAmplify } from '../Auth/utils'

export const createBinaryFhirClient = (contentType, token) => {
  return createClient({
    headers: {
      'Content-Type': contentType,
      Authorization: `Bearer ${token}`,
    },
  })
}

export const toBase64 = async file =>
  new Promise(resolve => {
    const reader = new FileReader()
    reader.onload = function(event) {
      // data:application/pdf;base64,JVBERi0xLjQKJ....
      resolve(event.target.result.split(',')[1])
    }

    reader.readAsDataURL(file)
  })

function* extractBinaryResource({ file, id }) {
  const base64 = yield toBase64(file)

  return {
    id,
    resourceType: 'Binary',
    contentType: file.type,
    data: base64,
  }
}

function* saveDocumentReferenceFromBinary(action) {
  const {
    patientID,
    practitionerID,
    encounterID,
    reference,
    binaryId,
  } = action.payload
  yield call(saveFhirResourceWorker, extractDocumentReferenceResource, {
    encounterID,
    patientID,
    practitionerID,
    reference,
    binaryId,
  })
}

function* saveDocumentReferenceWorker(action) {
  const {
    payload: { patientID, practitionerID, encounterID, reference },
  } = action
  // We need to generate our own ID, because we'll be saving as a PDF which won't
  // return a JSON object containing the ID.
  const id = uuid()
  const token = yield getTokenFromAmplify()
  const file = reference.file
  // We save the binary first
  yield call(
    saveFhirResourceWorker,
    extractBinaryResource,
    { file, id },
    createBinaryFhirClient(file.type, token)
  )
  // Then the DocumentReference
  yield saveDocumentReferenceFromBinary({
    payload: {
      encounterID,
      patientID,
      practitionerID,
      reference,
      binaryId: id,
    },
  })
}

function* saveBinary(action) {
  const { payload } = action
  const { file, id } = payload
  const token = yield getTokenFromAmplify()
  yield call(
    saveFhirResourceWorker,
    extractBinaryResource,
    { file, id },
    createBinaryFhirClient(file.type, token)
  )
}

export default function* watcherSaga() {
  yield takeEvery(SAVING_DOCUMENT_REFERENCE, saveDocumentReferenceWorker)
  yield takeEvery(
    SAVING_DOCUMENT_REFERENCE_FROM_BINARY,
    saveDocumentReferenceFromBinary
  )
  yield takeEvery(
    [FETCHING_DOCUMENT_REFERENCES],
    searchFhirResourceWorker,
    'DocumentReference',
    ({ payload: p }) => ({
      encounter: 'Encounter/' + p.encounterID,
      subject: 'Patient/' + p.patientID,
    })
  )
  yield takeEvery(
    [UPDATING_DOCUMENT_REFERENCE],
    saveFhirResourceWorker,
    a => a.payload
  )
  yield takeEvery(CREATING_BINARY, saveBinary)
}
