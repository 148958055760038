import React from 'react'
import styled from 'styled-components/macro'
import moment from 'moment'

const ValueDiv = styled.div``

const DateAnswer = ({ value }) => {
  const formatedDate = moment(value).format('DD/MM/YYYY')

  return <ValueDiv>{formatedDate}</ValueDiv>
}

export default DateAnswer
