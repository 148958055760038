import React, { useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ReactTooltip from 'react-tooltip'
import styled from 'styled-components/macro'
import { faCalendarAlt, faIdCard } from '@fortawesome/free-regular-svg-icons'
import { faVideo } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link, useNavigate } from '@reach/router'
import { displayPractitionerImg } from '../display/practitioner'
import SignOutButton from '../../Auth/components/SignOutButton'
import RequirePermission from '../../Permissions/containers/RequirePermission'
import { CurrentPractitionerContext } from '../../Practitioner/usePractitioner'
import { getPractitionerAuthState } from '../../Practitioner/selector'
import OrganizationLogo from '../../Organization/components/OrganizationLogo'
import { getCurrentEncounter } from '../../Encounter/selectors'
import { deselectEncounter } from '../../Encounter/actions'
import Menu from './Menu'
import { Trans } from '@lingui/macro'
import SignOutMenuListItem from '../../Auth/containers/SignOutMenuListItem'
import { switchLanguage } from '../../I18n'
import UrlControlledMenu from '../containers/UrlControlledMenu'
import Grid from './GridLayout'
import { getAlertMessage, getShouldAlertOnLeave } from '../selectors/navigation'
import useGetLocationFromOrganizationId from '../hooks/useGetLocationFromOrganizationId'
import { displayFullNameWithTitle } from '../display/entity'
import { getPractitionerRolesBypractitionerIDFromState } from '../../PractitionerRole/selector'
import {
  getMedeoRoleFromGouvCode,
  getSpecialtyCodingFromSystem
} from '../../Permissions/roles'
import { ESANTE_OCCUPATION_SYSTEM } from '../../utils/codes'
const useRole = currentPractitioner => {
  const practitionerRolesBypractitionerID = useSelector(s =>
    getPractitionerRolesBypractitionerIDFromState(s, 'medical')
  )
  if (currentPractitioner == null) {return null}
  if (practitionerRolesBypractitionerID == null) {return null}
  const currentPractitionerWithRole =
    practitionerRolesBypractitionerID[currentPractitioner.id]
  if (currentPractitionerWithRole == null) {return getMedeoRoleFromGouvCode('')}
  const [firstRole] = currentPractitionerWithRole
  if (firstRole == null || firstRole.specialty == null)
    {return getMedeoRoleFromGouvCode('')}
  return getMedeoRoleFromGouvCode(
    getSpecialtyCodingFromSystem(firstRole, ESANTE_OCCUPATION_SYSTEM)?.code
  )
}

const Container = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  justify-items: center;
  height: 100%;
  background: #062557;
`

const Top = styled.nav`
  display: flex;
  flex-direction: column;
  align-items: center;

  // select the logo no matter what tag it is
  // i.e. svg or img
  & > :first-child {
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;
  }
`

const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${p => p.theme.alabaster};
  & > * {
    margin-bottom: 1rem;
  }
`

const HeadImage = styled.img`
  height: 100%;
`

const CustomLink = styled(Link)`
  width: 100%;
  height: 100%;
  padding: 0;
  display: inline-block;
`
const CustomListItem = styled.li`
  cursor: default;
  border-radius: 0.25rem;
  border: 1px solid transparent;
  color: ${p => p.theme.black};
  padding: 0;
  & > * + * {
    margin-top: 0.5rem;
  }
  & > div:last-child {
    color: ${p => p.theme.nevada};
  }
`

const ImageDiv = styled.div`
  border-radius: 50%;
  border: 2px solid ${p => p.theme.white};
  width: 2rem;
  height: 2rem;
  overflow: hidden;
  display: flex;
  justify-content: center;
  cursor: pointer;
`

// see https://reach.tech/router/api/Link
const isActive = () => ({ isPartiallyCurrent }) => {
  return {
    style: {
      color: isPartiallyCurrent ? '#0967D2' : 'white',
      background: isPartiallyCurrent ? 'white' : 'none'
    }
  }
}

// We prefer this notation instead of the .attrs with the ...rest
// it prevents organizationId to be passed down to the underlying <a />
// otherwise React will complain about the props not being standard.
// see https://github.com/styled-components/styled-components/issues/135#issuecomment-256018643
const StyledLink = styled(props => <Link getProps={isActive()} {...props} />)`
  border-radius: 10%;
  width: 2rem;
  height: 2rem;
  display: flex;
  margin-bottom: 1rem;
  justify-content: center;
  align-items: center;
  font-size: ${p => p.theme.large};
`

const MedeoBar = () => {
  const [practitioner, setCurrentPractitioner] = useContext(
    CurrentPractitionerContext
  )
  const navigate = useNavigate()
  const location = useGetLocationFromOrganizationId()
  const currentEncounter = useSelector(getCurrentEncounter)
  const dispatch = useDispatch()
  const practitionerAuthState = useSelector(state =>
    getPractitionerAuthState(state)
  )
  const message = useSelector(getAlertMessage)
  const shouldAlertOnLeave = useSelector(getShouldAlertOnLeave)

  const handleChangePage = e => {
    if (shouldAlertOnLeave) {
      // eslint-disable-next-line no-restricted-globals
      const response = confirm(message)

      if (response === false) {
        e.preventDefault()
        return
      }
    }
    if (currentEncounter != null) {
      dispatch(deselectEncounter(currentEncounter.id))
    }
  }
  const specialtyOfCurrentPractitioner = useRole(practitioner)

  return (
    <Grid.Nav>
      <Container>
        <Top>
          <OrganizationLogo />
          {/* we dont display patientList's link when patient comes back from teleconsultation
             and have to enter the practitioner pin, otherwise the patient could skip the pin authentification
            */}
          {practitionerAuthState.context == null && (
            <StyledLink
              to="/patients"
              onClick={handleChangePage}
              data-tip="Patients"
              data-test="patient_link_MedeoBar"
              data-intercom-target="patients-list"
            >
              <FontAwesomeIcon icon={faIdCard} />
              <ReactTooltip effect="solid" place="right" />
            </StyledLink>
          )}

          <RequirePermission resource="booking" action="see">
            {practitioner && practitionerAuthState.context == null && (
              <StyledLink
                to={`/booking/${location?.id}/schedule`}
                onClick={handleChangePage}
                data-tip="Réservation"
                data-intercom-target="pharmacist-booking"
                data-test="booking"
              >
                <FontAwesomeIcon icon={faCalendarAlt} />
                <ReactTooltip effect="solid" place="right" />
              </StyledLink>
            )}
          </RequirePermission>
          <RequirePermission resource={'waitingRoomList'} action={'read'}>
            <StyledLink
              to="/teleconsultation"
              onClick={handleChangePage}
              data-tip="Téléconsultations"
              data-intercom-target="waiting-room"
              data-test="teleconsultation-screen"
            >
              <FontAwesomeIcon icon={faVideo} />
              <ReactTooltip effect="solid" place="right" />
            </StyledLink>
          </RequirePermission>
          <RequirePermission resource={'schedule'} action={'see'}>
            {practitioner && practitionerAuthState.context == null && (
              <StyledLink
                to={`/schedule/${practitioner.id}`}
                onClick={handleChangePage}
                data-tip="Calendrier"
                data-intercom-target="schedule-doctor"
                data-test="schedule-doctor"
              >
                <FontAwesomeIcon icon={faCalendarAlt} />
                <ReactTooltip effect="solid" place="right" />
              </StyledLink>
            )}
          </RequirePermission>
        </Top>
        <Bottom>
          {/* we dont display settings's link when patient comes back from teleconsultation
             and have to enter the practitioner pin, otherwise the patient could skip the practitioner authentification
            */}
          {practitioner && practitionerAuthState.context == null ? (
            <UrlControlledMenu
              component={({ onClick }) => (
                <ImageDiv
                  onClick={onClick}
                  role="button"
                  data-intercom-target="profil-picture"
                  data-test="profil"
                >
                  <HeadImage src={displayPractitionerImg(practitioner)} />
                </ImageDiv>
              )}
              // this is the param in the url that triggers the opening of the menu
              controlUrlParam={'settings'}
            >
              <CustomListItem>
                <div>{displayFullNameWithTitle(practitioner)}</div>
                <div>{specialtyOfCurrentPractitioner}</div>
              </CustomListItem>
              <Menu.Separator />

              <UrlControlledMenu.ListItem hasNavigationOnClick={true}>
                <CustomLink
                  to="settings/edit"
                  onClick={handleChangePage}
                  data-intercom-target="settings"
                  data-test="setting"
                >
                  <Trans>Settings</Trans>
                </CustomLink>
              </UrlControlledMenu.ListItem>
              <UrlControlledMenu.ListItem
                data-intercom-target="switch-language"
                onClick={() => {
                  dispatch(switchLanguage())
                }}
                controlUrlParam={'settings'}
              >
                <Trans>Switch language</Trans>
              </UrlControlledMenu.ListItem>
              <UrlControlledMenu.ListItem
                onClick={() => {
                  setCurrentPractitioner(null)
                  navigate('/auth')
                }}
              >
                <Trans>Change profile</Trans>
              </UrlControlledMenu.ListItem>
              <Menu.Separator />
              <SignOutMenuListItem />
            </UrlControlledMenu>
          ) : (
            <SignOutButton />
          )}
        </Bottom>
      </Container>
    </Grid.Nav>
  )
}

export default MedeoBar
