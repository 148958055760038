import React, { useState, useEffect } from 'react'
import { Auth } from 'aws-amplify'
import { Input, Button } from '../../Components'
import styled from 'styled-components/macro'
import { Trans } from '@lingui/macro'
import { createNotification } from '../../Notifications/actions'
import { useDispatch } from 'react-redux'
import { signOut } from '../actions'

const Form = styled.form`
  display: flex;
  flex-direction: column;
  & > ${Input}:not(:last-of-type) {
    margin-bottom: 1.5rem;
  }
  & > ${Button} {
    align-self: center;
    min-width: 14rem;
    margin-top: 2.5rem;
  }
`

const Authorization = styled.span`
  margin-top: 0.5rem;
  font-size: small;
  display: flex;
  align-items: center;
  color: #e12d39;
`

const CustomSignInForm = props => {
  const [isLoading, setLoading] = useState(false)
  const dispatch = useDispatch()

  // prevent refresh page
  useEffect(() => {
    if (props.userAuthorized !== true) {
      dispatch(signOut())
    }
  }, [props.userAuthorized, dispatch])
  return (
    <Form
      onSubmit={e => {
        e.preventDefault()
        setLoading(true)
        const { elements } = e.currentTarget
        // set context value so that we can use it in the confirmSignUp step
        Auth.signIn(
          elements.namedItem('email').value,
          elements.namedItem('password').value
        )
          .then(() => {
            props.onStateChange('signedIn')
            setLoading(false)
          })
          .catch(err => {
            setLoading(false)
            dispatch(createNotification(err.message, 'error', 5000))
          })
      }}
    >
      <Input
        type="email"
        name="email"
        id="email"
        label={<Trans>Email address</Trans>}
        required
      />
      <Input
        type="password"
        name="password"
        id="password"
        label={<Trans>Password</Trans>}
        required
      />
      {props.userAuthorized === false && (
        <Authorization>
          <Trans>
            You don't have the permissions required to connect to Medeo
          </Trans>
        </Authorization>
      )}
      <Button data-test="sign-in-sign-in-button" disabled={isLoading === true}>
        {isLoading === true ? (
          <Trans>Please wait...</Trans>
        ) : (
          <Trans>Sign In</Trans>
        )}
      </Button>
    </Form>
  )
}

export default CustomSignInForm
