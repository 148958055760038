import { useSelector } from 'react-redux'
import React from 'react'
import styled from 'styled-components/macro'

import { getOrganizationById } from '../selector'
import { MEDEO_ORGANIZATION_LOGO_URL } from '../../utils/codes'
import Logomark from '../../Shared/components/Logomark'
import useCurrentOrganizationId from '../containers/CurrentOrganizationIdProvider'

const CustomLogo = styled.img`
  padding: 0.5rem;
  max-width: 4rem;
  max-height: 4rem;
  object-fit: cover;
  border-radius: 0.25rem;
`

const WhiteLogomark = styled(Logomark)`
  width: 4rem;
  height: 4rem;
  circle,
  path {
    fill: white;
  }
`

const OrganizationLogo = () => {
  const organizationId = useCurrentOrganizationId()
  const org = useSelector(getOrganizationById(organizationId))
  const logo = org?.extension?.find?.(
    e => e.url === MEDEO_ORGANIZATION_LOGO_URL
  )?.valueUri
  return logo != null ? (
    <CustomLogo src={logo} alt={`${org.name}`} title={`${org.name}`} />
  ) : (
    <WhiteLogomark alt="Medeo" title="Medeo" />
  )
}

export default OrganizationLogo
