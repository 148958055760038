import buildFhir from 'lifen-fhir.js/src/adapters/native'
import { FHIR_BASE_URL } from '../../_common/config'

const BASE_CONFIG = {
  baseUrl: FHIR_BASE_URL.href, // local : "href", remote on azure : "origin"
  credentials: 'same-origin',
}
export const createClient = (overrides = {}) => {
  return buildFhir({
    ...BASE_CONFIG,
    ...overrides,
  })
}
