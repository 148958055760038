import { combineReducers, Reducer } from 'redux'
import { DEVICES_RECEIVED } from './actions'
import { FHIR_RESOURCES_RECEIVED } from '../Shared/actions'
import { appendAllUniqueIds, reduceById } from '../Shared/ducks'
import { Device } from 'fhir-stu3'
import { ById, ReduxState } from '../Shared/ducks/types'

export const allIds: Reducer<string[]> = (state = [], action) => {
  const { type, payload } = action
  switch (type) {
    case DEVICES_RECEIVED:
    case FHIR_RESOURCES_RECEIVED:
      return appendAllUniqueIds(state, payload.Device)
    default:
      return state
  }
}

export const byId: Reducer<ById<Device>> = (state = {}, action) => {
  const { payload, type } = action
  switch (type) {
    case FHIR_RESOURCES_RECEIVED:
    case DEVICES_RECEIVED:
      return reduceById(state, payload.Device)
    default:
      return state
  }
}

export const getDeviceById = (id: string) => (state: ReduxState): Device => {
  return state.Device.byId[id]
}

export default combineReducers({
  allIds,
  byId
})
