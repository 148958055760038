import React, { useContext, useState, useEffect } from 'react'
import styled from 'styled-components/macro'
import { useDispatch } from 'react-redux'
import { Card } from '../../Components'
import { Trans } from '@lingui/macro'
import StripeAccount from '../../Stripe/component/StripeAccount'
import { useLocation } from '@reach/router'
import { completeConnectStripeAccountLambda } from '../../Stripe/utils/lambdas'
import { editPractitioner } from '../actions'
import RequirePermission from '../../Permissions/containers/RequirePermission'
import { CurrentPractitionerContext } from '../../Practitioner/usePractitioner'
const CustomCard = styled(Card)`
  min-height: 40rem;
  justify-content: start;
`

const BillingPage = () => {
  const [currentPractitioner, setCurrentPractitioner] = useContext(
    CurrentPractitionerContext
  )

  const reduxDispatch = useDispatch()
  const stripeIdentifier = currentPractitioner?.identifier?.find(
    i => i?.system === 'http://medeo.io/fhir/Identifier/stripe'
  )?.value

  const identifier = currentPractitioner.identifier
  const location = useLocation()
  const params = new URL(location.href).searchParams
  const [stripeCode, setStripeCode] = useState(params.get('code'))
  useEffect(() => {
    // we prevent if the user has already a stripeId
    // if the stripeCode is already used we don't need to update stripe data
    if (stripeIdentifier == null && stripeCode != null) {
      async function updateStripeData() {
        // we use the stripeCode with the lambda to complete the stripe account
        // we get back the account and extract stripeId
        const stripeUserId = await completeConnectStripeAccountLambda(
          stripeCode
        )
        // we push it the stripe id in identifier of practitioner
        identifier.push({
          system: 'http://medeo.io/fhir/Identifier/stripe',
          type: { coding: [{ code: 'stripe', display: 'Stripe' }] },
          value: stripeUserId
        })
        // we update the practitioner with his new identifier
        let practitioner = {
          ...currentPractitioner,
          identifier
        }
        // we update the resource and the context of current practitioner
        // once it's done we put the stripeCode to null to not use it anymore
        reduxDispatch(editPractitioner(practitioner))
        setCurrentPractitioner(practitioner)
      }
      updateStripeData()
      setStripeCode(null)
    }
  }, [
    stripeCode,
    identifier,
    reduxDispatch,
    currentPractitioner,
    setCurrentPractitioner,
    stripeIdentifier
  ])
  return (
    <RequirePermission resource="billing" action="read">
      <CustomCard>
        <h1>
          <Trans>Billing</Trans>
        </h1>
        <StripeAccount stripeId={stripeIdentifier} />
      </CustomCard>
    </RequirePermission>
  )
}

export default BillingPage
