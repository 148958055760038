import { Trans } from '@lingui/macro'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components/macro'
import { getEncounterById } from '../../Encounter/selectors'
import { getQuestionnaireIdentifierValuesInStringFromEncounterType } from '../../PlanDefinition/utils'
import ProfilePicture from '../../Shared/components/ProfilePicture'
import { displayFullNameWithTitle } from '../../Shared/display/entity'
import { displayPractitionerImg } from '../../Shared/display/practitioner'
import { displayPractitionerActiveRole } from '../../Shared/display/practitionerRole'
import { useLazySearch, useSearch } from '../../Shared/hooks'
import { getRequestGroupByencounterID } from '../ducks'
import RequestGroupAction from './RequestGroupAction'

const Container = styled.div`
  border-top: 1px solid #9aa5b1 !important;
`

const RoleDiv = styled.div`
  color: #77808e;
  font-size: small;
`
const PractitionerDiv = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
`

const FlexContainer = styled.div`
  margin-left: 1rem;
  margin-bottom: 1rem;
  padding: 0.25rem 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const Flex = styled.div`
  display: flex;
`
const PractitionerPhoneDiv = styled.div`
  font-size: small;
  color: #77808e;
  padding-top: 0.5rem;
`
const Phone = ({ author }) => {
  const { data, loading } = useSearch('PractitionerRole', {
    practitioner: author?.reference,
    _include: {
      $and: ['PractitionerRole:organization', 'PractitionerRole:practitioner']
    }
  })
  const organization = data?.Organization?.[0]
  const practitioner = data?.Practitioner?.[0]
  const roles = data?.PractitionerRole
  if (loading === true)
    {return (
      <span>
        <Trans>Loading...</Trans>
      </span>
    )}
  if (organization == null) {return null}

  return (
    <Container>
      <h4>Information du médecin</h4>
      <Flex>
        <ProfilePicture
          size="small"
          src={displayPractitionerImg(practitioner)}
        />
        <FlexContainer>
          <PractitionerDiv>
            <div>{displayFullNameWithTitle(practitioner)}</div>
            <RoleDiv>{displayPractitionerActiveRole(roles)}</RoleDiv>
          </PractitionerDiv>

          <PractitionerPhoneDiv>
            <Trans>Telephone</Trans> :{' '}
            {
              organization?.telecom?.find(
                telecom =>
                  telecom?.system === 'mobile' || telecom.system === 'phone'
              )?.value
            }
          </PractitionerPhoneDiv>
        </FlexContainer>
      </Flex>
    </Container>
  )
}
const Borderer = styled.div`
  & > div {
    border-top: 1px solid ${p => p.theme.gray};
    padding-bottom: 1rem;
  }

  & > div:first-child {
    border-top: none;
  }
`

/**
 * From the parent we pass the encounter id the request group is refering to, and inside this component we will
 * fetch the corresponding requestGroup
 *
 */
const RequestGroup = ({ encounterID, patient }) => {
  const requestGroup = useSelector(getRequestGroupByencounterID(encounterID))
  const encounter = useSelector(getEncounterById(encounterID))
  const encounterType = encounter?.type?.[0]?.coding?.[0]?.code // Fetch the questionnaire responses linked to the encounter

  // Here we prepare the search queries for the different resources we are going to need in
  // the next components
  // We just don't call them now as we want to ensure some conditions first: that the encounterID
  // is not null and that the requestGroup is not already in the store
  const [loadQuestionnaireResponses] = useLazySearch('QuestionnaireResponse', {
    context: `Encounter/${encounterID}`,
    subject: `Patient/${patient.id}`,
    _include: `QuestionnaireResponse:questionnaire`
  })

  const [loadObservations] = useLazySearch('Observation', {
    context: `Encounter/${encounterID}`,
    subject: `Patient/${patient.id}`
  })

  const [loadQuestionnaires] = useLazySearch('Questionnaire', {
    identifier: getQuestionnaireIdentifierValuesInStringFromEncounterType(
      encounterType
    )
  })

  const [loadRequestGroup] = useLazySearch('RequestGroup', {
    context: `Encounter/${encounterID}`,
    patient: `Patient/${patient.id}`
  })

  // Here we load the resources we need
  useEffect(() => {
    if (encounterID != null) {
      loadRequestGroup()
      loadQuestionnaireResponses()
      loadObservations()
      loadQuestionnaires()
    }
  }, [encounterID, loadObservations, loadQuestionnaireResponses, loadQuestionnaires, loadRequestGroup, patient])

  return (
    <Borderer>
      {requestGroup?.action?.[0]?.title === 'Consultation' && (
        <Phone author={requestGroup?.author} />
      )}
      {requestGroup != null
        ? requestGroup.action.map((a, i) => (
            <RequestGroupAction
              encounterID={encounterID}
              action={a}
              requestGroup={requestGroup}
              key={requestGroup.id + i}
              patient={patient}
            />
          ))
        : null}
    </Borderer>
  )
}

export default RequestGroup
