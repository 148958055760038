import {
  DIAGNOSTIC_ACTION_VALUE,
  SYNTHESE_ACTION_VALUE,
} from '../PlanDefinition/utils'

// List of actions needing permission to be seen in readOnly,
// as synthesis and diagnostic for now
export const ACTIONS_NEEDING_PERMISSION = [
  DIAGNOSTIC_ACTION_VALUE,
  SYNTHESE_ACTION_VALUE,
]
