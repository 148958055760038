import { createContext, ReactNode, useCallback, useContext } from 'react';

type PlanDefinitionState = {
  sequenceIndexOfPage: number;
  sequenceWorkThrough: any;
  sequenceResponse: any[];
  isIsolatedElementView: boolean;
  questionnaireIdentifier?: {
    system: string | null;
    code: string | null;
  };
  internalResponseId: string;
  submitButton: ReactNode;
  onFinishing: () => {};
};

type PlanDefinitionContextType =
  | [PlanDefinitionState, (s: PlanDefinitionState) => PlanDefinitionState]
  | null;

export const PlanDefinitionContext = createContext<PlanDefinitionContextType>(
  null
);

const usePlanDefinition = () => {
  const value = useContext(PlanDefinitionContext);
  if (value == null) {throw new Error('plan definition context is null');}

  const [state, setState] = value;

  const goBack = useCallback(() => {
    //@ts-ignore
    setState((s: PlanDefinitionState) => ({
      ...s,
      sequenceIndexOfPage: s.sequenceIndexOfPage - 1
    }));
  }, [setState]);

  const goNext = useCallback(() => {
    //@ts-ignore
    setState((s: PlanDefinitionState) => ({
      ...s,
      sequenceIndexOfPage: s.sequenceIndexOfPage + 1
    }));
  }, [setState]);

  return {
    planDefinition: state,
    goBack,
    goNext,
    /**
     * @deprecated
     */
    setPlanDefinition: setState
  };
};

export default usePlanDefinition;
