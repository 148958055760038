/*eslint-disable*/
import { call, takeEvery, select } from 'redux-saga/effects';
import {
  ADDING_OBSERVATION,
  FETCHING_OBSERVATIONS,
  UPDATING_OBSERVATION,
  FETCHING_SPECIFIC_OBSERVATIONS,
  UPDATING_ALL_OBSERVATIONS
} from './actions';
import * as fromLoinc from '../utils/loinc';
import { sortByLastUpdate, getElapsedTime } from '../utils/dateUtils';
import {
  saveFhirResourceWorker,
  searchFhirResourceWorker
} from '../Shared/sagas';
import ObservationBuilder from '../utils/fhir/ObservationBuilder';
import { getCurrentEncounter } from '../Encounter/selectors';
import {
  getAllObservationsByType,
  getObservationsFromCurrentEncounter,
  filterOutLoincCodes
} from './selectors';

function* addManualResourceWorker(action) {
  const { payload: info } = action;
  let observation;
  // If we want to overwrite the last observation of the same type
  // instead of creating a new one
  if (info.overwriteLast) {
    observation = yield call(updateLastObservationFromType, action);
  } else {
    // destructuring the payload in order to get the actual observation received from the server...
    observation = yield call(
      saveFhirResourceWorker,
      extractManualObservation,
      action
    );
  }

  // if this is a blood glucose observation, then try to add derived observation
  if (info.lastMeal != null) {
    yield call(saveFhirResourceWorker, extractManualDerivedObservation, {
      observation,
      info
    });
  }
}

/**
 * Triggers the update of the last observation having the same type
 * as the one coming, if the time elapsed since this one is
 * more than 10 seconds, which would mean that no observation of the
 * same type was done in 10 seconds.
 * @param {action} action: contains a new measure
 */
function* updateLastObservationFromType(action) {
  const { payload: info } = action;

  const sameTypeObservations = yield select(
    getObservationsFromCurrentEncounter,
    [filterOutLoincCodes([info.loincCoding])]
  );
  // Build observation from current measure
  let observation = yield call(extractManualObservation, action);

  // If observation of same type already exists
  if (sameTypeObservations.length !== 0) {
    const lastObservation = sameTypeObservations.sort(sortByLastUpdate)[0];
    // Get ellipsed time since last observation
    const idleTime = getElapsedTime(lastObservation);
    // If this one is less than 10secs, overwrite last observation
    if (idleTime < 100000)
      // To do so, we add the id of the last observation to the new one
      observation.id = lastObservation.id;
  }
  const updatedObservation = yield call(saveFhirResourceWorker, observation);

  return updatedObservation;
}

function* extractManualObservation(action) {
  const { payload: info } = action;

  // at least 3 cases :
  // 1. it's a simple measure
  // 2. it's a compound measure i.e. bloodPressure with two quantities.
  // 3. it's a blood glucose measure, then we use

  let fhirObservation = {};
  const { id: encounterID } = yield select(getCurrentEncounter);
  const unit = fromLoinc.getUnitFromType(info.loincCoding);

  /*
    I think we cannot let this part like this
    There is a lot of dupplicated code. We should isolate the changing parts
    and mutualize the common part for more maintainanility
   */
  if (info.value != null) {
    const builder = new ObservationBuilder({ resourceType: 'Observation' });
    fhirObservation = builder
      .appendNoCategory()
      .appendStatus()
      .appendLoincCoding(info.loincCoding)
      .appendValueQuantity(info.value, unit)
      .appendReferenceRangeIfPossible()
      .appendInterpretationIfPossible()
      .appendPatient('Patient/' + info.patientID)
      .appendPerformer(info.practitioner)
      .appendContext('Encounter/' + encounterID)
      .appendEffectiveDateTime(info.effectiveDateTime);
  } else if (info.comment != null) {
    const builder = new ObservationBuilder({ resourceType: 'Observation' });
    fhirObservation = builder
      .appendNoCategory()
      .appendStatus()
      .appendLoincCoding(fromLoinc.LOINC_COMMENT_CODE)
      .appendValueString(info.comment)
      .appendReferenceRangeIfPossible()
      .appendInterpretationIfPossible()
      .appendPatient('Patient/' + info.patientID)
      .appendPerformer(info.practitioner)
      .appendContext('Encounter/' + encounterID)
      .appendEffectiveDateTime(info.effectiveDateTime);
  } else if (
    info.diastolicBloodPressure != null &&
    info.systolicBloodPressure != null &&
    info.pulse != null
  ) {
    const diaComponent = new ObservationBuilder()
      .appendLoincCoding(fromLoinc.LOINC_DIASTOLIC_BLOOD_PRESSURE_CODE)
      .appendValueQuantity(info.diastolicBloodPressure, unit)
      .appendReferenceRangeIfPossible()
      .appendInterpretationIfPossible();

    const sysComponent = new ObservationBuilder()
      .appendLoincCoding(fromLoinc.LOINC_SYSTOLIC_BLOOD_PRESSURE_CODE)
      .appendValueQuantity(info.systolicBloodPressure, unit)
      .appendReferenceRangeIfPossible()
      .appendInterpretationIfPossible();

    const pulseComponent = new ObservationBuilder()
      .appendLoincCoding(fromLoinc.LOINC_HEART_RATE_CODE)
      .appendValueQuantity(
        info.pulse,
        fromLoinc.getUnitFromType(fromLoinc.LOINC_HEART_RATE_CODE)
      )
      .appendReferenceRangeIfPossible()
      .appendInterpretationIfPossible();

    const builder = new ObservationBuilder({ resourceType: 'Observation' });
    fhirObservation = builder
      .appendNoCategory()
      .appendStatus()
      .appendEffectiveDateTime(info.effectiveDateTime)
      .appendLoincCoding(fromLoinc.LOINC_BLOOD_PRESSURE_CODE)
      .appendComponent(diaComponent)
      .appendComponent(sysComponent)
      .appendComponent(pulseComponent)
      .appendPatient('Patient/' + info.patientID)
      .appendPerformer(info.practitioner)
      .appendContext('Encounter/' + encounterID);
  } else if (
    info.diastolicBloodPressure != null &&
    info.systolicBloodPressure != null
  ) {
    const diaComponent = new ObservationBuilder()
      .appendLoincCoding(fromLoinc.LOINC_DIASTOLIC_BLOOD_PRESSURE_CODE)
      .appendValueQuantity(info.diastolicBloodPressure, unit)
      .appendReferenceRangeIfPossible()
      .appendInterpretationIfPossible();

    const sysComponent = new ObservationBuilder()
      .appendLoincCoding(fromLoinc.LOINC_SYSTOLIC_BLOOD_PRESSURE_CODE)
      .appendValueQuantity(info.systolicBloodPressure, unit)
      .appendReferenceRangeIfPossible()
      .appendInterpretationIfPossible();

    const builder = new ObservationBuilder({ resourceType: 'Observation' });
    fhirObservation = builder
      .appendNoCategory()
      .appendStatus()
      .appendEffectiveDateTime(info.effectiveDateTime)
      .appendLoincCoding(fromLoinc.LOINC_BLOOD_PRESSURE_CODE)
      .appendComponent(diaComponent)
      .appendComponent(sysComponent)
      .appendPatient('Patient/' + info.patientID)
      .appendPerformer(info.practitioner)
      .appendContext('Encounter/' + encounterID);
  } else if (info.saturation != null && info.pulse != null) {
    const satComponent = new ObservationBuilder()
      .appendLoincCoding(fromLoinc.LOINC_OXYGEN_SATURATION_CODE)
      .appendValueQuantity(
        info.saturation,
        fromLoinc.getUnitFromType(fromLoinc.LOINC_OXYGEN_SATURATION_CODE)
      )
      .appendReferenceRangeIfPossible()
      .appendInterpretationIfPossible();

    const pulseComponent = new ObservationBuilder()
      .appendLoincCoding(fromLoinc.LOINC_HEART_RATE_CODE)
      .appendValueQuantity(
        info.pulse,
        fromLoinc.getUnitFromType(fromLoinc.LOINC_HEART_RATE_CODE)
      )
      .appendReferenceRangeIfPossible()
      .appendInterpretationIfPossible();

    const builder = new ObservationBuilder({ resourceType: 'Observation' });
    fhirObservation = builder
      .appendNoCategory()
      .appendStatus()
      .appendEffectiveDateTime(info.effectiveDateTime)
      .appendLoincCoding(fromLoinc.LOINC_OXYGEN_SATURATION_CODE)
      .appendComponent(satComponent)
      .appendComponent(pulseComponent)
      .appendPatient('Patient/' + info.patientID)
      .appendPerformer(info.practitioner)
      .appendContext('Encounter/' + encounterID);
  } else if (
    info.bilirubinPresence != null &&
    info.urobilinogenPresence != null &&
    info.ketonesPresence != null &&
    info.glucosePresence != null &&
    info.proteinPresence != null &&
    info.hemoglobinPresence != null &&
    info.pH != null &&
    info.nitritePresence != null &&
    info.leukocytePresence != null &&
    info.specificGravity != null
  ) {
    const biliComponent = new ObservationBuilder()
      .appendLoincCoding(fromLoinc.LOINC_BILIRUBIN_PRESENCE_CODE)
      .appendValueString(info.bilirubinPresence)
      .appendReferenceRangeIfPossible()
      .appendInterpretationIfPossible();
    const urobiComponent = new ObservationBuilder()
      .appendLoincCoding(fromLoinc.LOINC_UROBILINOGEN_PRESENCE_CODE)
      .appendValueString(info.urobilinogenPresence)
      .appendReferenceRangeIfPossible()
      .appendInterpretationIfPossible();
    const ketoComponent = new ObservationBuilder()
      .appendLoincCoding(fromLoinc.LOINC_KETONES_PRESENCE_CODE)
      .appendValueString(info.ketonesPresence)
      .appendReferenceRangeIfPossible()
      .appendInterpretationIfPossible();
    const glucComponent = new ObservationBuilder()
      .appendLoincCoding(fromLoinc.LOINC_GLUCOSE_PRESENCE_CODE)
      .appendValueString(info.glucosePresence, '')
      .appendReferenceRangeIfPossible()
      .appendInterpretationIfPossible();
    const protComponent = new ObservationBuilder()
      .appendLoincCoding(fromLoinc.LOINC_PROTEIN_PRESENCE_CODE)
      .appendValueString(info.proteinPresence)
      .appendReferenceRangeIfPossible()
      .appendInterpretationIfPossible();
    const hemoComponent = new ObservationBuilder()
      .appendLoincCoding(fromLoinc.LOINC_HEMOGLOBIN_PRESENCE_CODE)
      .appendValueString(info.hemoglobinPresence)
      .appendReferenceRangeIfPossible()
      .appendInterpretationIfPossible();
    const pHComponent = new ObservationBuilder()
      .appendLoincCoding(fromLoinc.LOINC_PH_CODE)
      .appendValueQuantity(info.pH, '')
      .appendReferenceRangeIfPossible()
      .appendInterpretationIfPossible();
    const nitriteComponent = new ObservationBuilder()
      .appendLoincCoding(fromLoinc.LOINC_NITRITE_PRESENCE_CODE)
      .appendValueString(info.nitritePresence)
      .appendReferenceRangeIfPossible()
      .appendInterpretationIfPossible();
    const leukoComponent = new ObservationBuilder()
      .appendLoincCoding(fromLoinc.LOINC_LEUKOCYTE_PRESENCE_CODE)
      .appendValueString(info.leukocytePresence)
      .appendReferenceRangeIfPossible()
      .appendInterpretationIfPossible();
    const specGravComponent = new ObservationBuilder()
      .appendLoincCoding(fromLoinc.LOINC_SPECIFIC_GRAVITY)
      .appendValueString(info.specificGravity)
      .appendReferenceRangeIfPossible()
      .appendInterpretationIfPossible();
    const builder = new ObservationBuilder({ resourceType: 'Observation' });
    fhirObservation = builder
      .appendNoCategory()
      .appendStatus()
      .appendEffectiveDateTime(info.effectiveDateTime)
      .appendLoincCoding(fromLoinc.LOINC_URINALYSIS_PANEL_CODE)
      .appendComponent(biliComponent)
      .appendComponent(urobiComponent)
      .appendComponent(ketoComponent)
      .appendComponent(glucComponent)
      .appendComponent(protComponent)
      .appendComponent(hemoComponent)
      .appendComponent(pHComponent)
      .appendComponent(nitriteComponent)
      .appendComponent(leukoComponent)
      .appendComponent(specGravComponent)
      .appendPatient('Patient/' + info.patientID)
      .appendPerformer(info.practitioner)
      .appendContext('Encounter/' + encounterID);
    /*
      For cardiologic and pneumonologic examination
     */
  } else {
    console.error('something went wrong...');
  }

  return fhirObservation;
}

function* extractManualDerivedObservation({ observation, info }) {
  const builder = new ObservationBuilder({ resourceType: 'Observation' });
  return builder
    .appendNoCategory()
    .appendStatus()
    .appendLoincCoding(fromLoinc.LOINC_LAST_MEAL_CODE)
    .appendPatient(observation.subject.reference)
    .appendDerivedFrom('Observation/' + observation.id)
    .appendContext(observation.context.reference)
    .appendValueTime(info.lastMeal);
}

function* updateObservations(action) {
  const { payload: info } = action;
  const observations = yield select(getAllObservationsByType, { ...info });
  observations.map(o => {
    o.status = info.status;
  });
  return observations;
}

export default function* watcherSaga() {
  yield takeEvery([ADDING_OBSERVATION], addManualResourceWorker);

  // OFF-131: certaines observations se referencent entre elles c.f. glycemie ce qui rend la suppression compliquée.
  // On recoit de la part du serveur FHIR une erreur HTTP 409
  // une des solutions serait de ne pas supprimer les observations mais de les archiver.
  // l'action REMOVING_OBSERVATION a été remplacée par UPDATING_OBSERVATION. On change simplement le status de
  // l'observation de final à entered-in-error
  // c.f. http://hl7.org/fhir/STU3/valueset-observation-status.html
  //

  yield takeEvery(
    [UPDATING_OBSERVATION],
    saveFhirResourceWorker,
    a => a.payload
  );
  yield takeEvery(
    [UPDATING_ALL_OBSERVATIONS],
    saveFhirResourceWorker,
    updateObservations
  );

  yield takeEvery(
    [FETCHING_OBSERVATIONS],
    searchFhirResourceWorker,
    'Observation',
    ({ payload: p }) => ({
      context: 'Encounter/' + p.encounterID,
      subject: 'Patient/' + p.patientID,
      _count: 100
    })
  );

  yield takeEvery(
    FETCHING_SPECIFIC_OBSERVATIONS,
    searchFhirResourceWorker,
    'Observation',
    ({ payload: p }) => {
      return {
        //status: 'final',
        subject: 'Patient/' + p.patientID,
        code: p.specificCode,
        _sort: '-_id'
      };
    }
  );
}
