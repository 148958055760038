import styled from 'styled-components/macro'
import { Button } from '../../Components'

const AttachmentControl = styled(Button).attrs(() => ({
  variant: 'toggle',
  color: 'alabaster',
  size: 'small',
  // if the attachment control is inside a <form/> it will trigger
  // submit if the following line is missing !
  type: 'button'
}))`
  width: 1.5rem;
  height: 1.5rem;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`

export default AttachmentControl
