import React from 'react'
import { Button, Card } from '../../Components'
import { Trans } from '@lingui/macro'
import CreateOrChangePinCode from '../container/CreateOrChangePinCode'
import EditPractitionerForm from '../container/EditPractitionerForm'
import ArchiveUserForAdmin from '../container/ArchiveUserForAdmin'
import styled from 'styled-components/macro'

const CustomButton = styled(Button)`
  margin-bottom: 1rem;
  padding: 1rem 2rem;
`

const Footer = styled.footer`
  display: flex;
  justify-content: center;
`

const Divider = styled.div`
  margin: 0.5rem 0;
`

const UserPageForAdmin = ({ practitionerID }) => {
  return (
    <Card>
      <h1>
        <Trans>User data</Trans>
      </h1>
      <EditPractitionerForm
        readOnly={false}
        use={'editUserAsAdmin'}
        selectedpractitionerID={practitionerID}
      />
      <Divider />
      <CreateOrChangePinCode
        practitionerID={practitionerID}
        comesFromRequirePractitioner={false}
      />
      <Divider />
      <ArchiveUserForAdmin practitionerID={practitionerID} />
      {/* The below button is located at the end of the page and it saves the editPractitioner form
       as it is done in the design
       */}
      <Footer>
        <CustomButton form="editPractitioner" color="ocean">
          <Trans>Save</Trans>
        </CustomButton>
      </Footer>
    </Card>
  )
}

export default UserPageForAdmin
