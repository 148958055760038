import React from 'react'
import styled from 'styled-components/macro'
import { Trans } from '@lingui/macro'
const Button = styled.button`
  color: ${p => p.theme.ocean};
  text-transform: uppercase;
  border: 0;
  background: none;
  font-weight: ${p => p.theme.bold};
  font-size: ${p => p.theme.small};
  margin-top: ${p => p.theme.spacing.medium};
  margin-bottom: ${p => p.theme.spacing.medium};
  :hover {
    cursor: pointer;
  }
  :focus {
    outline: none;
    border: none;
  }
`
// add an id at the header/top of the page and and a root props with the same name
const ScrollAtTopView = ({ root }) => {
  return (
    <Button
      onClick={() =>
        document
          .getElementById(root)
          .scrollIntoView({ block: 'end', behavior: 'smooth' })
      }
    >
      <Trans>Scroll at the top of page</Trans>
    </Button>
  )
}

export default ScrollAtTopView
