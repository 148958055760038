import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { t } from '@lingui/macro'
import AttachmentControl from './AttachmentControl'
import { faPrint } from '@fortawesome/free-solid-svg-icons'
import { useToken } from '../utils'
import { useI18n } from '../../utils/I18nHookAdapter'

const AttachmentPrintControl = ({ attachment }) => {
  const token = useToken()

  // here we do exactly the same process
  // as the AttachmentViewControl.handleClick method
  // the only difference which is to call the print method on the child window
  const handleClick = e => {
    e.preventDefault()
    fetch(attachment.url, {
      method: 'get',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(res => res.blob())
      .then(blob => {
        // create a local version of the file from a blob
        // we append the content type like "image/jpeg" to the blob
        // to indicates to your browser how to display it
        // see https://developer.mozilla.org/en-US/docs/Web/API/Blob/slice for more details
        const file = window.URL.createObjectURL(
          blob.slice(0, blob.size, attachment.contentType)
        )
        const printWindow = window.open(
          file,
          '_blank',
          'width=720,height=1080,menubar=no'
        )
        // here we can trigger a print directly to a child window
        printWindow.print()
        window.URL.revokeObjectURL(file)
      })
  }

  // we are using @lingui/core@2.9 which does not provide the useLingui hook
  // we need to use a custom hook instead:
  const i18n = useI18n()
  const title = i18n._(t`Print`)
  return (
    token != null && (
      <AttachmentControl
        as="a"
        aria-label={title}
        href={attachment.url}
        onClick={handleClick}
        title={title}
      >
        <FontAwesomeIcon icon={faPrint} />
      </AttachmentControl>
    )
  )
}

export default AttachmentPrintControl
