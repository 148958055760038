import moment from 'moment'
import { FHIR_DATE_FORMAT } from '../utils/dateUtils'

export const extractDocumentReferenceResource = ({
  encounterID,
  patientID,
  practitionerID,
  reference,
  binaryId,
}) => {
  return {
    resourceType: 'DocumentReference',
    status: 'current',
    type: {
      coding: [
        {
          code: 'http://hl7.org/fhir/ValueSet/c80-doc-typecodes',
        },
      ],
      text: reference.type,
    },
    description: '',
    indexed: moment(reference.file.lastModified).format(FHIR_DATE_FORMAT),
    content: [
      {
        attachment: {
          contentType: reference.file.type,
          url: binaryId ? `urn:uuid:${binaryId}` : null,
          title: reference.name,
          creation: moment(reference.file.lastModified).format(
            FHIR_DATE_FORMAT
          ),
        },
      },
    ],
    ...(practitionerID
      ? {
          author: {
            reference: `Practitioner/${practitionerID}`,
          },
          authenticator: {
            reference: `Practitioner/${practitionerID}`,
          },
        }
      : {}),
    ...(patientID
      ? {
          subject: {
            reference: `Patient/${patientID}`,
          },
        }
      : {}),
    ...(encounterID
      ? {
          context: {
            encounter: {
              reference: `Encounter/${encounterID}`,
            },
          },
        }
      : {}),
  }
}
