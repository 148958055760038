import { Card } from '../../Components'
import React from 'react'
import styled from 'styled-components/macro'
import { Trans } from '@lingui/macro'
import LiveObservationsView from '../../Teleconsultation/containers/LiveObservationsView'
import { useKligoSocket } from '../../Shared/hooks/useKligoSocket'

const Page = styled.div`
  & > :not(:last-child) {
    margin-bottom: 1rem;
  }
  margin-top: 4rem;
`
const Row = styled.div`
  margin: 0.5rem;
`

const DefaultDevicePage = ({ device }) => {
  const data = useKligoSocket()

  return (
    <Page>
      <Card>
        <Row>
          <Trans>Identifier</Trans> : {device?.id}
        </Row>
        <Row>
          <Trans>MAC address</Trans> :{' '}
          {device?.identifier?.find?.(t => t.system === 'mac-address')?.value}
        </Row>
        <Row>
          <Trans>Type</Trans> :{' '}
          {
            device?.type?.coding?.find?.(
              t => t.system === 'http://snomed.info/sct'
            )?.display
          }
        </Row>
        <Row>
          <Trans>Manufacturer</Trans> : {device?.manufacturer}
        </Row>
        <Row>
          <Trans>Model</Trans> : {device?.model}
        </Row>
      </Card>
      {data.length !== 0 ? (
        <LiveObservationsView observations={data} />
      ) : (
        <Card>
          <Trans>
            Please check that Kligo is open and take a measurement with your
            device
          </Trans>
        </Card>
      )}
    </Page>
  )
}
export default DefaultDevicePage
