import styled from 'styled-components/macro'
import { Button } from '../../Components'

const QuestionnaireTextButton = styled(Button).attrs({ variant: 'text' })`
  text-transform: none;
  font-size: ${p => p.theme.small};
  padding: 0;
  background: transparent;
  font-weight: ${p => p.theme.medium};
  margin-top: 0.5rem;
  &:hover,
  &:active,
  &:focus {
    border-color: transparent;
    background: transparent;
  }
`

export default QuestionnaireTextButton
