import React, { ChangeEvent, FC, useContext } from 'react'
import { t, Trans } from '@lingui/macro'
import { Input } from '../../Components'
import { EditPractitionerContext } from '../container/EditPractitionerForm'
import BetterSelect from '../../Shared/components/BetterSelect'
import RequiredLabel from '../../Shared/components/RequiredLabel'
import { useI18n } from '../../utils/I18nHookAdapter'
import { Practitioner } from 'fhir-stu3'
import styled from 'styled-components/macro'

const Row = styled.div`
  margin-bottom: 1rem;
`

const NameInputs: FC = () => {
  const [resource, setResource] = useContext(EditPractitionerContext)
  const lastName = resource.practitioner?.name?.[0]?.family
  const firstName = resource.practitioner?.name?.[0]?.given?.[0]
  const title = resource.practitioner?.name?.[0]?.prefix

  const handleChangeFamily = (e: ChangeEvent<HTMLInputElement>) => {
    setResource((r: { practitioner: Practitioner }) => ({
      ...r,
      practitioner: {
        ...r.practitioner,
        name: [
          {
            ...(r.practitioner?.name?.[0] ?? {}),
            use: 'usual',
            family: e.target.value
          }
        ]
      }
    }))
  }

  const handleChangeGiven = (e: ChangeEvent<HTMLInputElement>) => {
    setResource((r: { practitioner: Practitioner }) => ({
      ...r,
      practitioner: {
        ...r.practitioner,
        name: [
          {
            ...(r.practitioner?.name?.[0] ?? {}),
            use: 'usual',
            given: [e.target.value]
          }
        ]
      }
    }))
  }
  const handleChangeTitle = (e: ChangeEvent<HTMLInputElement>) => {
    setResource((r: { practitioner: Practitioner }) => ({
      ...r,
      practitioner: {
        ...r.practitioner,
        name: [
          {
            ...(r.practitioner?.name?.[0] ?? {}),
            use: 'usual',
            prefix: e.target.value
          }
        ]
      }
    }))
  }

  const i18n: any = useI18n()

  return (
    <>
      <Row>
        <div>
          <RequiredLabel required>
            <Trans>Title</Trans>
          </RequiredLabel>
          <BetterSelect
            name="prefix"
            defaultValue={title}
            onChange={handleChangeTitle}
          >
            <option value="">{i18n._(t`Select an option`)}</option>
            <option value="dr">Dr.</option>
            <option value="m">{i18n._(t`Mr.`)}</option>
            <option value="mme" data-test="mme">
              {i18n._(t`Ms.`)}
            </option>
            <option value="pr">Pr.</option>
          </BetterSelect>
        </div>
      </Row>
      <Row>
        <Input
          label={<Trans>Given</Trans>}
          name="firstName"
          data-test="firstname"
          defaultValue={firstName}
          onChange={handleChangeGiven}
          required
        />
      </Row>
      <Row>
        <Input
          label={<Trans>Family</Trans>}
          name="lastName"
          data-test="lastName"
          defaultValue={lastName}
          onChange={handleChangeFamily}
          required
        />
      </Row>
    </>
  )
}

export default NameInputs
