import React, { useContext } from 'react'
import * as R from 'ramda'
import RadioGroup from '../Shared/components/RadioGroup'
import { ModelContext, ReadOnlyContext } from './utils'
import YamlRadioInput from './YamlRadioInput'

const YamlRadioGroup = ({ yaml }) => {
  const [model] = useContext(ModelContext)
  const readOnly = useContext(ReadOnlyContext)
  const { required } = yaml
  const lens = R.lensPath(yaml.name.split('.'))
  const value = R.view(lens, model)

  return (
    <RadioGroup
      required={required}
      label={yaml.label}
      readOnly={readOnly}
      value={value}
    >
      {yaml.children.map((c, i) => (
        <YamlRadioInput key={i} yaml={{ ...c, required }} />
      ))}
    </RadioGroup>
  )
}

export default YamlRadioGroup
