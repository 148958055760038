import createSimpleReducers from '../Shared/ducks/createSimpleReducers'
import { ReduxState } from '../Shared/ducks/types'
const ducks = createSimpleReducers('PaymentNotice')

export const getPaymentNoticeByProcedureRequestId = (
  procedureRequestId: string
) => (state: ReduxState) => {
  return state.PaymentNotice.allIds
    .map(id => state.PaymentNotice.byId[id])
    ?.find(p => p.request === 'ProcedureRequest/' + procedureRequestId)
}

export default ducks
