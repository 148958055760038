import { PaymentNotice } from 'fhir-stu3'
import { useMutation } from 'react-query'
import { getTokenFromAmplify } from '../../Auth/utils'
import { FHIR_BASE_URL } from '../../_common/config'

const updatePaymentNotice = async (paymentNotice: PaymentNotice) => {
  paymentNotice.status = 'active'
  const url = new URL(
    'PaymentNotice/' + paymentNotice.id,
    FHIR_BASE_URL
  )
  const token = await getTokenFromAmplify()
  let res = await fetch(url.toString(), {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(paymentNotice)
  })
  if (res.status >= 400) {
    const message = await res.text()
    throw new Error(message)
  }
  return (await res.json()) as PaymentNotice
}
const useUpdatePaymentNotice = () => {
  return useMutation<PaymentNotice, Error, PaymentNotice>(updatePaymentNotice)
}

export default useUpdatePaymentNotice
