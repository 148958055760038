import React, { useContext } from 'react'
import { t, Trans } from '@lingui/macro'
import {
  allDentistSpecialtyCode,
  allDoctorSpecialty,
  allMedicalRoles,
  allNurseSpecialtyCode,
  allPharmacistSpecialtyCode,
  DENTIST_ESANTE,
  GENERAL_PRACTITIONER_ESANTE,
  getMedeoRoleFromGouvCode,
  getSpecialtyCodingFromSystem,
  getSpecialtyTransFromOccupation,
  NURSE_ESANTE,
  PHARMACIST_ESANTE,
} from '../../Permissions/roles'
import { set } from '../../utils/fhirpath'
import { EditPractitionerContext } from '../container/EditPractitionerForm'
import {
  ESANTE_OCCUPATION_SYSTEM,
  ESANTE_SPECIALTY_SYSTEM,
} from '../../utils/codes'
import BetterSelect from '../../Shared/components/BetterSelect'
import useTranslateRole from '../useTranslateRole'
import RequiredLabel from '../../Shared/components/RequiredLabel'
import { useI18n } from '../../utils/I18nHookAdapter'

const Option = ({ role }) => {
  const trans = useTranslateRole(role)
  return (
    <option value={role} data-test="profession">
      {/* While here, we display the translated string */}
      {trans}
    </option>
  )
}
const MedicalRoleInput = ({ readOnly }) => {
  const [resource, setResource] = useContext(EditPractitionerContext)
  const specialty = resource?.medicalRole?.specialty
  const occupationIdentifier = getSpecialtyCodingFromSystem(
    resource.medicalRole,
    ESANTE_OCCUPATION_SYSTEM
  )
  const occupationCode = occupationIdentifier?.code
  const specialtyIdentifier = getSpecialtyCodingFromSystem(
    resource.medicalRole,
    ESANTE_SPECIALTY_SYSTEM
  )
  const specialtyCode = specialtyIdentifier?.code
  const isPharmacist = occupationCode === PHARMACIST_ESANTE
  const isDoctor = occupationCode === GENERAL_PRACTITIONER_ESANTE
  const isNurse = occupationCode === NURSE_ESANTE
  const isDentist = occupationCode === DENTIST_ESANTE
  const i18n = useI18n()

  const occupationOnChange = e => {
    const { value } = e.target
    // if the occupation is already defined
    if (specialty != null) {
      if (occupationCode != null) {
        occupationIdentifier.code = value
        occupationIdentifier.display = getMedeoRoleFromGouvCode(
          value
        )?.props?.id
      } else {
        specialty.push({
          coding: [
            {
              system: ESANTE_OCCUPATION_SYSTEM,
              code: value,
              display: getMedeoRoleFromGouvCode(value)?.props?.id,
            },
          ],
        })
      }
      setResource({
        ...resource,
      })
      // if we are creating a new practitioner
    } else {
      setResource({
        ...resource,
        medicalRole: set(
          resource.medicalRole,
          `specialty.item(0).coding.item(0)`,
          {
            system: ESANTE_OCCUPATION_SYSTEM,
            code: value,
            // By default, the id from a i18n Trans component is the content of the Trans component
            // And here, it is the non translated string that we want to save
            display: getMedeoRoleFromGouvCode(value)?.props?.id,
          }
        ),
      })
    }
  }

  const specialtyOnChange = e => {
    const { value } = e.target
    if (specialtyCode != null) {
      specialtyIdentifier.code = value
      specialtyIdentifier.display = getSpecialtyTransFromOccupation(
        occupationCode,
        value
      )?.props?.id
    } else {
      specialty.push({
        coding: [
          {
            system: ESANTE_SPECIALTY_SYSTEM,
            code: value,
            display: getSpecialtyTransFromOccupation(occupationCode, value)
              ?.props?.id,
          },
        ],
      })
    }
    setResource({
      ...resource,
    })
  }

  return (
    <>
      <div>
        <RequiredLabel required>
          <Trans>Occupation</Trans>
        </RequiredLabel>
        <BetterSelect
          name="medical"
          defaultValue={occupationCode}
          readOnly={readOnly}
          required
          onChange={occupationOnChange}
          data-intercom-target="medeo-role"
        >
          <option value="">{i18n._(t`Select an option`)}</option>

          {allMedicalRoles.map(role => (
            <Option key={role} role={role} />
          ))}
        </BetterSelect>
      </div>
      {isPharmacist && (
        <div>
          <RequiredLabel>
            <Trans>Specialty</Trans>
          </RequiredLabel>
          <BetterSelect
            name="specialty"
            defaultValue={specialtyCode}
            onChange={specialtyOnChange}
          >
            {allPharmacistSpecialtyCode.map(role => (
              <Option key={role} role={role} />
            ))}
          </BetterSelect>
        </div>
      )}
      {isNurse && (
        <div>
          <RequiredLabel>
            <Trans>Specialty</Trans>
          </RequiredLabel>
          <BetterSelect
            name="specialty"
            defaultValue={specialtyCode}
            onChange={specialtyOnChange}
          >
            {allNurseSpecialtyCode.map(role => (
              <Option key={role} role={role} />
            ))}
          </BetterSelect>
        </div>
      )}
      {isDentist && (
        <div>
          <RequiredLabel>
            <Trans>Specialty</Trans>
          </RequiredLabel>
          <BetterSelect
            name="specialty"
            defaultValue={specialtyCode}
            onChange={specialtyOnChange}
          >
            {allDentistSpecialtyCode.map(role => (
              <Option key={role} role={role} />
            ))}
          </BetterSelect>
        </div>
      )}
      {isDoctor && (
        <div>
          <RequiredLabel>
            <Trans>Specialty</Trans>
          </RequiredLabel>
          <BetterSelect
            name="specialty"
            defaultValue={specialtyCode}
            onChange={specialtyOnChange}
          >
            {allDoctorSpecialty.map(role => (
              <Option key={role} role={role} />
            ))}
          </BetterSelect>
        </div>
      )}
    </>
  )
}

export default MedicalRoleInput
