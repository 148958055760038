import { faStethoscope } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Trans } from '@lingui/macro'
import { Button, Stack } from '@mui/material'
import { Link, useNavigate } from '@reach/router'
import moment from 'moment'
import { FC, useContext } from 'react'
import { useSelector } from 'react-redux'
import { getDevicesByOrganizationId } from '../../Device/selectors'
import { selectTLCDevices } from '../../Device/utils'
import { getCurrentOrganization } from '../../Organization/selector'
import RequirePermission from '../../Permissions/containers/RequirePermission'
import { can } from '../../Permissions/permissions'
import { CurrentPractitionerRoleContext } from '../../PractitionerRole/containers/CurrentPractitionerRoleProvider'
import mixpanel, {
  MIXPANEL_STARTING_PRECONSULTATION
} from '../../Shared/mixpanel'
import { getIdByReference } from '../../Shared/utils'
import { organizationStringUrl } from '../../Teleconsultation/utils'

import { Appointment, Patient, ProcedureRequest } from 'fhir-stu3'
import useQueryPaymentNoticeByAppointmentId from '../../Appointment/hooks/useQueryPaymentNoticeByAppointementId'
import usePractitioner from '../../Practitioner/usePractitioner'

const JoinTeleconsultationButtonForPatient: FC<{
  patient: Patient
  preConsultationencounterID: string
}> = ({ patient, preConsultationencounterID }) => {
  const organization = useSelector(getCurrentOrganization)
  const currentPractitioner = usePractitioner()
  if (currentPractitioner == null) {
    throw new Error('currentPractitioner is not defined')
  }
  const organizationName = organizationStringUrl(organization)
  const identifier = `${currentPractitioner.id}_${currentPractitioner.name?.[0]?.family}`

  if (organization?.id == null) {
    throw new Error('organization Id should be defined')
  }
  const orgDevices = useSelector(getDevicesByOrganizationId(organization.id))
  const tlcDevices = selectTLCDevices(orgDevices)

  const searchParams = new URLSearchParams()
  tlcDevices.forEach((type) => searchParams.append('device', type))

  const tlcUrl = `/patient/${
    patient.id
  }/teleconsultation/${preConsultationencounterID}/identifier/${identifier}/organizationName/${organizationName}?${searchParams.toString()}`

  return (
    <Button component={Link} variant="contained" to={tlcUrl}>
      <Stack gap={1} direction="row" alignItems="center">
        <FontAwesomeIcon icon={faStethoscope} />
        <Trans>Join</Trans>
      </Stack>
    </Button>
  )
}

const JoinTeleconsultationForDistantButtonGroup: FC<{
  patientID: string
  preConsultationencounterID: string
}> = ({ patientID, preConsultationencounterID }) => (
  <Button
    component={Link}
    variant="contained"
    to={`/teleconsultation/${patientID}/${preConsultationencounterID}`}
  >
    <Stack gap={1} direction="row" alignItems="center">
      <FontAwesomeIcon icon={faStethoscope} />
      <Trans>Join</Trans>
    </Stack>
  </Button>
)

const StartPreconsultationButton: FC<{ appointment: Appointment }> = ({
  appointment
}) => {
  const navigate = useNavigate()
  if (appointment == null) {
    throw new Error('should not be undefined')
  }
  // here we want to know if the Patient have an Appointment today
  // in order to disable or not the Teleconsultation button
  const isToday = moment(appointment.start).isSame(moment(), 'day')

  const patientID =
    appointment.participant
      ?.find((p) => p.actor?.reference?.startsWith?.('Patient'))
      ?.actor?.reference?.split('/')?.[1] ?? ''

  const handleStartTLC = () => {
    mixpanel.track(MIXPANEL_STARTING_PRECONSULTATION, {
      from: 'details'
      // it can be from the page details or booking
    })
    navigate(`/patient/${patientID}/start/pre_teleconsultation`)
  }

  return (
    <Button
      variant="contained"
      onClick={handleStartTLC}
      disabled={!isToday}
      data-test={'createPreconsultation'}
    >
      <Stack gap={1} direction="row" alignItems="center">
        <FontAwesomeIcon icon={faStethoscope} />
        <Trans>Start preconsultation</Trans>
      </Stack>
    </Button>
  )
}
const StartCheckoutButton: FC<{ appointment: Appointment }> = ({
  appointment
}) => (
  <Button
    variant="contained"
    component={Link}
    to={`/checkout/${appointment.id}`}
  >
    <Trans>Go to checkout</Trans>
  </Button>
)
const FutureEncounterButtonGroup: FC<{
  patient: Patient
  procedureRequest: ProcedureRequest | undefined
  appointment: Appointment
}> = ({ patient, procedureRequest, appointment }) => {
  // Pre-check. If we don't have a patient, we can't do anything.
  const patientID = patient.id
  if (patientID == null) {
    throw new Error('patientID should be defined')
  }
  const { data: paymentNotice } = useQueryPaymentNoticeByAppointmentId(
    appointment.id as string
  )
  const [currentPractitionerRole] = useContext(CurrentPractitionerRoleContext)
  // 1. Check if the payment has been processed.
  // when user goes to checkout page, the stripe-payment-id will be set
  // when the payment is processed the paymentNotice.status will go from draft -> active
  // NOTE: the stripe paymentIntent object should have status == "requires_capture" or "processing"
  // right before starting the preTLC.
  const isPaymentProcessed =
    paymentNotice?.identifier?.some((i) => i.system === 'stripe-setup-id') &&
    paymentNotice?.status === 'active'
  if (isPaymentProcessed !== true) {
    // Can be undefined.
    return (
      // @todo Do we need to check the permission here?
      <RequirePermission resource={'preconsultationForm'} action={'go'}>
        <StartCheckoutButton appointment={appointment} />
      </RequirePermission>
    )
  }
  // 2. Payment has been processed, check if we have a preconsultation.
  if (procedureRequest == null) {
    return (
      <RequirePermission resource={'preconsultationForm'} action={'go'}>
        <StartPreconsultationButton appointment={appointment} />
      </RequirePermission>
    )
  }
  // 3. We have a preconsultation, check if it has been completed.
  const preConsultationencounterID: string = getIdByReference(
    procedureRequest?.context?.reference
  )
  if (preConsultationencounterID == null) {
    throw new Error('preConsultationencounterID should not be undefined')
  }
  // 4. Preconsultation has been completed, we can proceed.
  // For practitioner. // @todo there must be a better way to do this than
  // grabbing the current practitioner role from the context ?
  if (can(currentPractitionerRole, 'go', 'joinTeleconsultationForDistant')) {
    return (
      <JoinTeleconsultationForDistantButtonGroup
        preConsultationencounterID={preConsultationencounterID}
        patientID={patientID}
      />
    )
  }
  // For patient.
  return (
    <RequirePermission resource={'teleconsultationPatientRoom'} action={'go'}>
      <JoinTeleconsultationButtonForPatient
        patient={patient}
        preConsultationencounterID={preConsultationencounterID}
      />
    </RequirePermission>
  )
}

export default FutureEncounterButtonGroup
