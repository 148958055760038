import React, { Component } from 'react'

class ObservationErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <span>Impossible d'afficher l'observation</span>
    }

    return this.props.children
  }
}

export default ObservationErrorBoundary
