import { combineReducers } from 'redux'
import {
  PRACTITIONER_ROLE_RECEIVED,
  PRACTITIONER_ROLES_RECEIVED,
} from './actions'
import { appendAllUniqueIds, reduceById } from '../Shared/ducks'
import { FHIR_RESOURCES_RECEIVED } from '../Shared/actions'
import { getTypeAndIdFromLocalReference } from '../utils/fhir'
import uniq from 'lodash/uniq'

export const allIds = (state = [], action) => {
  const { type, payload } = action
  switch (type) {
    case FHIR_RESOURCES_RECEIVED:
    case PRACTITIONER_ROLES_RECEIVED:
      return appendAllUniqueIds(state, payload.PractitionerRole)
    case PRACTITIONER_ROLE_RECEIVED:
      return appendAllUniqueIds(state, payload.practitionerRole)
    default:
      return state
  }
}

export const byId = (state = {}, action) => {
  const { type, payload } = action
  switch (type) {
    case FHIR_RESOURCES_RECEIVED:
    case PRACTITIONER_ROLES_RECEIVED:
      return reduceById(state, payload.PractitionerRole)
    case PRACTITIONER_ROLE_RECEIVED:
      return {
        ...state,
        [payload.practitionerRole.id]: payload.practitionerRole,
      }
    default:
      return state
  }
}

/**
 * This function is used in the byPractitioner duck. It creates an object
 * with every practitioner as a key and an array of roles id as value
 * @param state - byPractitioner state
 * @param {PractitionerRole[]} roles - PractitionerRole array
 * @return {*}
 */
const reduceRolesByPractitioner = (state, roles) =>
  roles?.reduce?.((state, role) => {
    const [, practitionerID] = getTypeAndIdFromLocalReference(
      role?.practitioner?.reference
    )
    return {
      ...state,
      [practitionerID]: uniq([...(state?.[practitionerID] ?? []), role.id]),
    }
  }, state) ?? state

/**
 * This function is used in the byOrganizationId duck. It creates an object
 * with every organization as a key and an array of roles id as value
 * @param state - byOrganization state
 * @param {PractitionerRole[]} roles - PractitionerRole array
 * @return {*}
 */
const reduceRolesByOrganization = (state, roles) =>
  roles?.reduce?.((state, role) => {
    const [, organizationId] = getTypeAndIdFromLocalReference(
      role?.organization?.reference
    )
    return {
      ...state,
      [organizationId]: uniq([...(state?.[organizationId] ?? []), role.id]),
    }
  }, state) ?? state

const bypractitionerID = (state = {}, action) => {
  const { type, payload } = action
  switch (type) {
    case FHIR_RESOURCES_RECEIVED:
    case PRACTITIONER_ROLES_RECEIVED:
      return reduceRolesByPractitioner(state, payload.PractitionerRole)
    case PRACTITIONER_ROLE_RECEIVED:
      const [, practitionerID] = getTypeAndIdFromLocalReference(
        payload.practitionerRole?.practitioner?.reference
      )
      return {
        ...state,
        [practitionerID]: uniq([
          ...(state?.[practitionerID] ?? []),
          payload.practitionerRole.id,
        ]),
      }
    default:
      return state
  }
}

const byOrganizationId = (state = {}, action) => {
  const { type, payload } = action
  switch (type) {
    case FHIR_RESOURCES_RECEIVED:
    case PRACTITIONER_ROLES_RECEIVED:
      return reduceRolesByOrganization(state, payload.PractitionerRole)
    default:
      return state
  }
}

export default combineReducers({
  allIds,
  byId,
  bypractitionerID,
  byOrganizationId,
})
