import { lighten } from 'polished'
import React from 'react'
import styled from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Trans } from '@lingui/macro'
import { DropDown } from '../../Components'
import { getIconByName } from '../utils'

const GreySquare = styled.div`
  //width: 100%;
  // height is set to 8.75rem so it fit the width that is set by SquareDiv template column
  height: 8rem;
  width: 8rem;
  background-color: ${p => lighten(0.2, p.theme.gray)};
  border-radius: 0.25rem;
  box-shadow: ${p => p.theme.boxShadow};
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  margin: 0.5rem;
`

// Right Component is not affected by the flex display of the GreySquare Component
const Right = styled.div`
  position: absolute;
  display: block;
  top: 0.25rem;
  right: 0;
`

// Center and Bottom share the space of GraySquare,
// Center takes all the place using flex: 1
// Bottom do not gro or shrink but has a fixed height
const Center = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`
const Bottom = styled.div`
  flex: 0 0 2rem;
  display: flex;
  font-size: ${p => p.theme.small};
  justify-content: center;
`

const CustomKebabButton = styled(DropDown.KebabButton)`
  background-color: ${p => lighten(0.2, p.theme.gray)};
  color: black;
  box-shadow: none;
`

const QuestionnaireSquareItem = ({
  internalResponseId,
  name,
  codeIcon,
  onEditQuestionnaireResponse,
  onDeleteQuestionnaireResponse
}) => {
  const EditQuestionnaireResponseEl = () => (
    <div onClick={() => onEditQuestionnaireResponse(internalResponseId)}>
      <Trans>Edit</Trans>
    </div>
  )

  const RemoveQuestionnaireResponseEl = () => (
    <div onClick={() => onDeleteQuestionnaireResponse(internalResponseId)}>
      <Trans>Delete</Trans>
    </div>
  )

  const listItems = [EditQuestionnaireResponseEl, RemoveQuestionnaireResponseEl]

  return (
    <GreySquare>
      <Right>
        <DropDown direction="left" role="button" tabIndex="1">
          <CustomKebabButton />
          <DropDown.Menu>
            {listItems.map((Component, index) => (
              <DropDown.ListItem key={index}>
                <Component />
              </DropDown.ListItem>
            ))}
          </DropDown.Menu>
        </DropDown>
      </Right>
      <Center>
        <FontAwesomeIcon icon={getIconByName(codeIcon)} size="2x" />
      </Center>
      <Bottom>{name}</Bottom>
    </GreySquare>
  )
}

export default QuestionnaireSquareItem
