import { appendAllUniqueIds, reduceById } from '../Shared/ducks'
import { FHIR_RESOURCES_RECEIVED } from '../Shared/actions'
import { combineReducers, Reducer } from 'redux'
import { getTypeAndIdFromLocalReference } from '../utils/fhir'

import { uniq as unique } from 'lodash'
import { Schedule } from 'fhir-stu3'
import { ById } from '../Shared/ducks/types'

export const allIds: Reducer<string[]> = (state = [], action) => {
  switch (action.type) {
    case FHIR_RESOURCES_RECEIVED:
      return appendAllUniqueIds(state, action.payload.Schedule)
    default:
      return state
  }
}

export const byId: Reducer<ById<Schedule>> = (state = {}, action) => {
  switch (action.type) {
    case FHIR_RESOURCES_RECEIVED:
      return reduceById(state, action.payload.Schedule)
    default:
      return state
  }
}

const reduceSchedulesByActor = (state: ById<string[]>, schedules: Schedule[]) =>
  schedules?.reduce?.((state, schedule) => {
    return schedule?.actor?.reduce?.((state, actor) => {
      const [, actorId] = getTypeAndIdFromLocalReference(actor?.reference)
      return {
        ...state,
        [actorId]: unique([...(state[actorId] ?? []), schedule.id])
      }
    }, state)
  }, state) ?? state

const byActor: Reducer<ById<string[]>> = (state = {}, action) => {
  const { type, payload } = action
  switch (type) {
    case FHIR_RESOURCES_RECEIVED:
      return reduceSchedulesByActor(state, payload.Schedule)
    default:
      return state
  }
}

export default combineReducers({
  byId,
  byActor,
  allIds
})
