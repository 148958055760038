import React, { useEffect, useState } from 'react';
import { retrieveAccountLambda } from '../../Stripe/utils/lambdas';
import StaticErrorNotification, {
  NO_AM_OR_FINESS,
  NO_RPPS_ID_ERROR,
  NO_STRIPE_ID_ERROR
} from '../../Notifications/components/StaticErrorNotification';
import {
  MEDEO_AM_IDENTIFIER_SYSTEM,
  MEDEO_FINESS_IDENTIFIER_SYSTEM,
  MEDEO_RPPS_IDENTIFIER_SYSTEM
} from '../../utils/codes';
import usePractitioner from '../usePractitioner';

const TeleconsultationWarnings = () => {
  const currentPractitioner = usePractitioner();
  const [error, setError] = useState(null);

  useEffect(() => {
    const stripeId = currentPractitioner?.identifier?.find?.(
      i => i.system === 'http://medeo.io/fhir/Identifier/stripe'
    )?.value;
    // all the stripeIds include 'acct_' at the start
    // check if its the case, if so then
    // call stripe API and check if accountId exists
    if (stripeId != null && stripeId.includes('acct_')) {
      async function fetchData() {
        const account = await retrieveAccountLambda(stripeId);
        // if there is no account set the error
        // don't clear the error if there is an account
        // there could be other error coming from other identifiers (RPPS or AM)
        if (account == null) {
          setError(NO_STRIPE_ID_ERROR);
        }
      }
      fetchData();

      // if stripeId is empty or doesnt include 'acct_' show error without calling lambda
    } else {
      setError(NO_STRIPE_ID_ERROR);
    }
  }, [currentPractitioner]);

  useEffect(() => {
    const rppsIdentifier = currentPractitioner?.identifier?.find?.(
      i => i.system === MEDEO_RPPS_IDENTIFIER_SYSTEM
    )?.value;

    const amOrFinessIdentifier = currentPractitioner?.identifier?.find?.(
      i =>
        i.system === MEDEO_AM_IDENTIFIER_SYSTEM ||
        i.system === MEDEO_FINESS_IDENTIFIER_SYSTEM
    )?.value;

    if (rppsIdentifier == null) {
      setError(NO_RPPS_ID_ERROR);
      return;
    }
    if (amOrFinessIdentifier == null) {
      setError(NO_AM_OR_FINESS);
      return;
    }
  }, [currentPractitioner]);

  return error != null && <StaticErrorNotification error={error} />;
};
export default TeleconsultationWarnings;
