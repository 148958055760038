import React from 'react'
import styled from 'styled-components/macro'

const Container = styled.span`
  display: flex;
  align-items: flex-end;
`

const String = styled.span`
  font-size: ${p => p.theme.large};
  margin-top: 0.15em;
`

const ValueStringView = ({ valueString }) => {
  if (valueString == null) {throw new Error('missing valueString')}
  return (
    <Container>
      <String>{valueString}</String>
    </Container>
  )
}

export default ValueStringView
