import React, { useState } from 'react'
import CollapseButton from '../../Encounter/containers/CollapseButton'
import styled from 'styled-components/macro'
import { Card } from '../../Components'

const CollapseHeader = styled.header`
  display: flex;
  justify-content: flex-start;
  padding: ${props => props.theme.small};
  cursor: pointer;
`

const CustomCard = styled(Card)`
  padding: 1rem;
`
const Title = styled.div`
  font-size: large;
  flex: 1;
`

const CollapsibleCard = ({ title, children }) => {
  const [collapsed, setCollapsed] = useState(true)

  const handleClick = () => {
    setCollapsed(s => !s)
  }
  return (
    <CustomCard>
      <CollapseHeader onClick={handleClick}>
        <Title>{title}</Title>
        <CollapseButton collapsed={collapsed} />
      </CollapseHeader>
      {collapsed === false && children}
    </CustomCard>
  )
}

export default CollapsibleCard
