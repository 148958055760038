import * as R from 'ramda';
import React from 'react';
import styled from 'styled-components/macro';
import { css } from 'styled-components';

import { faUser, faCreditCard } from '@fortawesome/free-regular-svg-icons';
import {
  faNotesMedical,
  faAmbulance,
  faCheck,
  faVoteYea,
  faPills
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Trans } from '@lingui/macro';
import { Link, Location } from '@reach/router';

const Navigation = styled.nav`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const CurrentInnerLink = css`
  color: ${p => p.theme.ocean};
`;

const InnerLink = styled.div`
  ${p => (p.clickable ? '' : 'cursor: default;')}
  font-size: ${p => p.theme.large};
  text-decoration: none;
  width: 100%;
  display: flex;
  align-items: baseline;
  padding-left: ${p => p.theme.spacing.medium};
  color: ${p => p.theme.black};
  ${p => (p.isCurrent ? CurrentInnerLink : '')};
`;

const Icon = styled.div`
  width: 28px;
  height: 28px;
`;

const Text = styled.h4`
  margin-left: 0.8rem;
  font-size: ${props => props.theme.small};
`;

const getIconFromText = text => {
  switch (text) {
    case 'pills':
      return faPills;
    case 'avatar':
      return faUser;
    case 'information':
      return faNotesMedical;
    case 'ambulance':
      return faAmbulance;
    case 'consentement':
      return faVoteYea;
    case 'payment':
      return faCreditCard;
    default:
      return null;
  }
};

const NavLink = props => (
  <Location>
    {({ location }) => {
      const to = `../${props.nextStep}`;
      const clickable = R.endsWith('/summary', location.pathname);

      const linkElement = (
        <InnerLink
          isCurrent={R.endsWith(`/${props.nextStep}`, location.pathname)}
          clickable={clickable}
        >
          {props.children}
        </InnerLink>
      );

      if (clickable) {
        return (
          <Link to={to} style={{ textDecoration: 'none' }}>
            {linkElement}
          </Link>
        );
      } else {
        return linkElement;
      }
    }}
  </Location>
);

const SideMenu = ({ pages }) => (
  <Navigation>
    {pages.map((page, index) => (
      <NavLink key={index} nextStep={`${index + 1}`}>
        <Icon>
          <FontAwesomeIcon icon={getIconFromText(page.icon)} />
        </Icon>
        <Text>{page.title}</Text>
      </NavLink>
    ))}
    <NavLink nextStep="consent">
      <Icon>
        <FontAwesomeIcon icon={faVoteYea} />
      </Icon>
      <Text>
        <Trans>Consent</Trans>
      </Text>
    </NavLink>
    <NavLink nextStep="summary">
      <Icon>
        <FontAwesomeIcon icon={faCheck} />
      </Icon>
      <Text>
        <Trans>Summary</Trans>
      </Text>
    </NavLink>
  </Navigation>
);
export default SideMenu;
