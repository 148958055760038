import { CardContent, Typography } from '@mui/material'
import { Trans } from '@lingui/macro'

const CustomCardHeader = () => {
  return (
    <CardContent>
      <Typography variant="overline">
        <Trans>Checkout</Trans>
      </Typography>
    </CardContent>
  )
}
export default CustomCardHeader
