// action about useReducer not the same than redux action in action.js
import * as Sentry from '@sentry/react'
export const USER_CONNECTING = 'USER_CONNECTING'
export const USER_DISCONNECTING = 'USER_DISCONNECTING'
export const USER_STARTING_VIDEO = 'USER_STARTING_VIDEO'
export const USER_STOPPING_VIDEO = 'USER_STOPPING_VIDEO'
export const USER_STARTING_MICRO = 'USER_STARTING_MICRO'
export const USER_STOPPING_MICRO = 'USER_STOPPING_MICRO'
export const NUMBER_PARTICIPANT_CHANGING = 'NUMBER_PARTICIPANT_CHANGING'
export const SETUP_DEVICE_OPTIONS = 'SETUP_DEVICE_OPTIONS'
export const UPDATING_SELECTED_INPUT = 'UPDATE_SELECTED_INPUT'
export const STATUS_CHANGING = 'STATUS_CHANGING'
export const STARTING_STETHOSCOPE = 'STARTING_STETHOSCOPE'
export const TELECONSULTATION_COMPLETED = 'TELECONSULTATION_COMPLETED'
export const UPDATE_WEBSOCKET = 'UPDATE_WEBSOCKET'
export const DOCUMENTS_RECEIVED = 'DOCUMENTS_RECEIVED'
export const TELECONSULTATION_UNSTARTED = 'TELECONSULTATION_UNSTARTED'
export const CANNOT_JOIN_ROOM = 'CANNOT_JOIN_ROOM'
export const STARTING_LOADING = 'STARTING_LOADING'
export const ADD_OBSERVATION = 'ADD_OBSERVATION'
export const CLOSING_MODAL = 'CLOSING_MODAL'
export const OPENING_MODAL = 'OPENING_MODAL'
export const PLUGGING_DEVICE = 'PLUGGING_DEVICE'

const getStatus = (status, nbOtherParticipant) => {
  switch (status) {
    case 'waitingForDoctor':
    case 'waitingForPatient':
      if (nbOtherParticipant === 1) {
        return 'in-progress'
      }
      return status
    case 'in-progress':
      if (nbOtherParticipant === 0) {
        return 'error'
      }
      return status
    case 'error':
      if (nbOtherParticipant === 1) {
        return 'in-progress'
      }
      // Log error.
      Sentry.captureException(
        new Error(
          `Video error`
        )
      )
      return status
    default:
      return status
  }
}

export const videoReducer = (state, action) => {
  switch (action.type) {
    case TELECONSULTATION_UNSTARTED:
      return {
        ...state,
        status: 'unstarted',
      }
    case USER_CONNECTING:
      return {
        ...state,
        status: getStatus(action.payload.status, state.nbOtherParticipant),
        isConnected: action.payload.isConnected,
        isVideo: action.payload.isVideo,
        isAudio: action.payload.isAudio,
        teleconsultationMaterial: action.payload.teleconsultationMaterial,
        webSocket: action.payload.webSocket,
      }
    case STARTING_LOADING:
      return { ...state, status: 'loading' }
    case NUMBER_PARTICIPANT_CHANGING:
      return {
        ...state,
        nbOtherParticipant: action.payload.nbOtherParticipant,
        status: getStatus(state.status, action.payload.nbOtherParticipant),
      }
    case SETUP_DEVICE_OPTIONS:
      return {
        ...state,
        deviceOptions: action.payload.deviceOptions,
      }
    case USER_DISCONNECTING:
      return {
        ...state,
        status: getStatus(action.payload.status, state.nbOtherParticipant),
        isConnected: false,
        isVideo: false,
        isAudio: false,
        teleconsultationMaterial: {},
      }
    case USER_STARTING_VIDEO:
      return {
        ...state,
        isVideo: true,
      }
    case USER_STOPPING_VIDEO:
      return {
        ...state,
        isVideo: false,
      }
    case USER_STARTING_MICRO:
      return {
        ...state,
        isAudio: true,
      }
    case USER_STOPPING_MICRO:
      return {
        ...state,
        isAudio: false,
      }
    case STATUS_CHANGING:
      return {
        ...state,
        status: action.payload.status,
      }
    case TELECONSULTATION_COMPLETED:
      return {
        ...state,
        status: action.payload.status,
        teleconsultationFhirResource: action.payload.teleconsultationEncounter,
      }
    case UPDATE_WEBSOCKET:
      return {
        ...state,
        webSocket: action.payload.webSocket,
      }
    case DOCUMENTS_RECEIVED:
      return {
        ...state,
        documentsDisplay: true,
      }
    case CANNOT_JOIN_ROOM:
      return {
        ...state,
        status: action.payload.status,
      }
    case CLOSING_MODAL:
      return {
        ...state,
        openModal: false,
      }
    case OPENING_MODAL:
      return {
        ...state,
        openModal: true,
      }
    case UPDATING_SELECTED_INPUT:
      return {
        ...state,
        deviceOptions: { ...state.deviceOptions, ...action.payload },
      }
    case PLUGGING_DEVICE:
      return {
        ...state,
        pluggingDevice: !state.pluggingDevice,
      }
    case ADD_OBSERVATION:
      return {
        ...state,
        observations: [...state.observations, action.payload],
      }
    default:
      return state
  }
}
