import React, { useCallback, useMemo, useReducer } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getIconByName } from '../utils'
import {
  getQuestionnaireIdentifierByAction,
  ORDONNANCE_MEDICAMENTEUSE_ACTION_VALUE
} from '../../PlanDefinition/utils'
import MedicationRequestsModal from '../../MedicationRequest/components/MedicationRequestsModal'
import ParamedicalMedicationRequestsModal from '../../MedicationRequest/components/ParamedicalMedicationRequestModal'

import { useParams } from '@reach/router'
import { getCurrentEncounter } from '../../Encounter/selectors'
import Attachment from '../../Attachment/components/Attachment'
import AttachmentViewControl from '../../Attachment/components/AttachmentViewControl'
import AttachmentControl from '../../Attachment/components/AttachmentControl'
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons'
import { generateOrder } from '../../MedicationRequest/utils'
import { useUpdateEffect } from 'react-use'
import usePractitioner from '../../Practitioner/usePractitioner'

const Icon = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 0.5rem;
`

const Text = styled.div`
  padding-left: 0.5rem;
`

const SquareDiv = styled.div`
  display: grid;
  grid-gap: 0.5rem;
  margin-top: 1rem;
  grid-template-columns: repeat(2, 1fr);
`

const Bloc = styled.div`
  margin-bottom: 1rem;
`

const reducer = (state, action) => {
  const { type, payload } = action
  switch (type) {
    case 'changeCurrent':
      return {
        ...state,
        open: true,
        currentGroupId: payload
      }
    case 'changeOpen':
      return {
        ...state,
        open: payload
      }
    case 'delete':
      // never use delete: it won't trigger the useEffect
      // delete mutate the state and the state should be immutable
      // eslint-disable-next-line
      const { [payload]: deleted, ...rest } = state.byGroupId
      return {
        ...state,
        byGroupId: { ...rest }
      }
    case 'downloading':
      return {
        ...state,
        currentAttachment: payload
      }
    case 'downloaded':
      return {
        ...state,
        currentAttachment: null
      }
    case 'submit':
      return {
        currentGroupId: null,
        open: false,
        submitting: false,
        byGroupId: {
          ...state.byGroupId,
          [payload.groupIdentifier]: {
            medicationRequests: payload.medicationRequests,
            attachment: payload.attachment
          }
        }
      }
    case 'save':
      return {
        ...state,
        submitting: true
      }
    default:
      return state
  }
}
/**
 * initialize this component internal state
 * @param response - the planDefinition intermediate response containing the byGroupId state
 * @return {{byGroupId: {}, currentGroupId: null, open: boolean, submitting: boolean}}
 */
const init = response => {
  return {
    currentAttachment: null,
    open: false,
    currentGroupId: null,
    byGroupId: response?.byGroupId ?? {},
    submitting: false // Keeps track of rather the order is getting submitted (medication requests save and pdf upload)
  }
}
/**
 *
 * @param {PlanDefinition.Action} action
 * @param {function} onChange
 * @param response - not an actual FHIR QuestionnaireResponse !!
 * @returns {*}
 * @constructor
 */
const QuestionnaireMedicationRequests = ({ action, onChange, response }) => {
  // practitioner, encounter, and patient should be accessible here
  // unfortunately this is using three different techniques 🙃
  // TODO: harmonize this API
  const practitioner = usePractitioner()
  const encounter = useSelector(getCurrentEncounter)
  const { patientID } = useParams()
  const [state, dispatch] = useReducer(reducer, response, init)

  // useUpdateEffect prevent the useEffect to be called on mount
  // this would happen if the component is loaded with response set
  // see init function for more details
  useUpdateEffect(() => {
    // call the onChange listener with the internal state
    onChange(state.byGroupId)
  }, [state.byGroupId])

  // The two following line were used in the previous version of orders
  // it could be removed if the plan-definition is updated
  // Orders should not refer to a questionnaire anymore
  // Theses lines are kept now in order not to introduce a breaking change
  const [identifier] = useMemo(() => {
    return getQuestionnaireIdentifierByAction(action)
  }, [action])

  const codeIcon = useMemo(() => {
    return action.code
      ?.map?.(c => c.coding?.find?.(coding => coding.system === 'medeo-icon'))
      .find(coding => coding != null)?.code
  }, [action])

  // useCallbacks are required in order to pass the same refs to ModalComponent
  // it prevent infinite rendering loop
  const handleSubmit = useCallback(
    async medicationRequests => {
      dispatch({ type: 'save' })
      const groupIdentifier = medicationRequests[0]?.groupIdentifier.value
      const attachment = {
        title: medicationRequests[0]?.groupIdentifier.extension[0].valueString,
        contentType: 'application/pdf'
      }
      dispatch({ type: 'downloading', payload: attachment })
      const url = await generateOrder({
        groupIdentifier: groupIdentifier,
        encounterID: encounter.id
      })

      dispatch({
        type: 'submit',
        payload: {
          groupIdentifier,
          medicationRequests,
          attachment: {
            ...attachment,
            url
          },
          url
        }
      })
    },
    [encounter.id]
  )

  const handleChange = useCallback(open => {
    dispatch({ type: 'changeOpen', payload: open })
  }, [])

  // here we select which component should be renderer using the identifier.code
  // Note that these two components share the exact same signature
  const ModalComponent =
    identifier.code === ORDONNANCE_MEDICAMENTEUSE_ACTION_VALUE
      ? MedicationRequestsModal
      : ParamedicalMedicationRequestsModal
  
  return (
    <Bloc>
      <Icon>
        <FontAwesomeIcon icon={getIconByName(codeIcon)} />
        <Text>{action.title}</Text>
      </Icon>
      <ModalComponent
        patientID={patientID}
        medicationRequests={
          state.byGroupId[state.currentGroupId]?.medicationRequests
        }
        encounterID={encounter.id}
        practitionerID={practitioner.id}
        open={state.open}
        onChange={handleChange}
        onSubmit={handleSubmit}
        isSubmitting={state.submitting}
      />
      <SquareDiv>
        {Object.entries(state.byGroupId).map(([groupId, slice]) => {
          return (
            <Attachment key={groupId} attachment={slice.attachment}>
              <AttachmentViewControl attachment={slice.attachment} />
              <AttachmentControl
                onClick={() => {
                  dispatch({ type: 'changeCurrent', payload: groupId })
                }}
              >
                <FontAwesomeIcon icon={faPen} />
              </AttachmentControl>
              <AttachmentControl
                onClick={() => {
                  dispatch({
                    type: 'delete',
                    payload: groupId
                  })
                }}
              >
                <FontAwesomeIcon icon={faTrash} />
              </AttachmentControl>
            </Attachment>
          )
        })}
        {/* 👇 gives a visual clue about the order being generated */}
        {state.currentAttachment && <Attachment />}
      </SquareDiv>
    </Bloc>
  )
}

QuestionnaireMedicationRequests.defaultProps = {
  response: null
}

export default QuestionnaireMedicationRequests
