import { call, takeLatest, select, all, takeEvery } from 'redux-saga/effects'
import {
  saveFhirResourceWorker,
  searchFhirResourceWorker
} from '../Shared/sagas'
import {
  FETCHING_MEDICATION_STATEMENTS,
  UPDATING_MEDICATION_STATEMENT,
  UPDATING_MEDICATION_STATEMENTS
} from './actions'
import { getCurrentEncounter } from '../Encounter/selectors'
import { getMedicationStatementFromMedicationId } from './selectors'

/**
 * This method is called from the db.js
 * it saves medication smartly and create a MedicationStatement
 *
 * It checks if the medication exist on the db using the code:
 * - if it does, it creates only a medicationStatement and refer to the medication
 * - if not, it creates the medication then the medicationStatement
 * - if there is no code, simply put the medication display inside the statement.
 * @param medication is the resource corresponding to the drug
 * @param patient
 * @param encounter
 * @returns {Generator<*, void, ?>}
 */
export function* saveMedicationAndMedicationStatementWorker(
  medication,
  patient,
  encounter
) {
  // code might not be defined if the medication is a custom one
  // i.e. the user types "my drug" in the input
  const code = medication?.code?.coding[0]?.code

  // we want to create a reference to the medication passed as a parameter
  // to create the statement. The ref should look like:
  // - { reference: 'Medication/123' }

  let medicationReference
  let medicationId

  if (code != null) {
    // if the code is not null, which means the Medication has been identified
    // then if the Medication is already added to the database, the bundle is going
    // to be filled at least with one Medication resource. We need to get this
    // resource to later refer to it in the MedicationStatement
    const { payload: bundle } = yield call(
      searchFhirResourceWorker,
      'Medication',
      () => ({
        code
      })
    )

    if (Array.isArray(bundle.Medication) && bundle.Medication.length > 0) {
      // bundle looks like this:
      // {
      //    Medication: Array(2),
      //    SomeOtherFhirResource: Array(12),
      //    link: [{...}]
      //    total: 14
      // }
      medicationReference = {
        reference: `Medication/${bundle.Medication[0].id}`
      }
      medicationId = bundle.Medication[0].id
    } else {
      // The medication does not exists yet in the database but a code has been
      // specified so we first create the Medication then we join the general case
      // where we create the statement.
      const resource = yield call(saveFhirResourceWorker, medication, () => {})
      medicationId = resource.id
      medicationReference = {
        reference: `Medication/${resource.id}`
      }
    }
  } else {
    // We create the medication even though the code has not been defined.
    // this is the easiest way to work with Medication at the moment.
    const resource = yield call(saveFhirResourceWorker, medication, () => {})
    medicationId = resource.id
    medicationReference = {
      reference: `Medication/${resource.id}`
    }
  }

  let statement = yield select(
    getMedicationStatementFromMedicationId(medicationId)
  )
  // We check if a statement was already created before, if we found one we
  // we just change the status to stopped at active. Otherwise we create a new one
  if (statement != null) {
    return yield call(saveFhirResourceWorker, {
      ...statement,
      status: 'active'
    })
  } else {
    // Eventually we create and save the statement
    const newStatement = {
      resourceType: 'MedicationStatement',
      status: 'active', // later it can be completed or stopped
      ...(patient?.id && {
        subject: {
          reference: `Patient/${patient?.id}`
        }
      }),
      ...(encounter && {
        context: {
          reference: `Encounter/${encounter.id}`
        }
      }),
      medicationReference
    }
    return yield call(saveFhirResourceWorker, newStatement)
  }
}

/**
 * this worker is called when a user update the MedicationStatement form
 * it expects an action containing at least the patient info
 * the add medications list and the deleted statement list
 *
 * - deleted statements have only their status update
 * - added medication are being processed the same way as in
 *   saveMedicationAndMedicationStatementWorker
 * @param action
 * @returns {Generator<<"ALL", *>|*, void, ?>}
 */
export function* saveMedicationsAndMedicationStatementsWorker(action) {
  const { deletedStatements, addedMedications, patient } = action.payload
  const encounter = yield select(getCurrentEncounter)
  yield all([
    ...deletedStatements.map(s =>
      call(saveFhirResourceWorker, { ...s, status: 'stopped' })
    ),
    ...addedMedications.map(m =>
      call(saveMedicationAndMedicationStatementWorker, m, patient, encounter)
    )
  ])
}

function* rootSaga() {
  // loads MedicationStatement+Medication from subject Id
  // it is called for instance in the MedicationList in the PatientInfo
  // medication is required otherwise, we can not display the medication to
  // the user correctly
  yield takeLatest(
    FETCHING_MEDICATION_STATEMENTS,
    searchFhirResourceWorker,
    'MedicationStatement',
    ({ payload: p }) => ({
      subject: p.subjectId,
      _include: 'MedicationStatement:medication'
    })
  )

  // update medication statements AND MEDICATIONS
  yield takeLatest(
    UPDATING_MEDICATION_STATEMENTS,
    saveMedicationsAndMedicationStatementsWorker
  )
  yield takeEvery(
    UPDATING_MEDICATION_STATEMENT,
    saveFhirResourceWorker,
    a => a.payload
  )
}

export default rootSaga
