import { FHIR_RESOURCES_RECEIVED } from '../Shared/actions'
import { appendAllUniqueIds, reduceById } from '../Shared/ducks'
import { CARETEAMS_RECEIVED } from './actions'
import { combineReducers } from 'redux'

export const allIds = (state = [], action) => {
  const { type, payload } = action
  switch (type) {
    case FHIR_RESOURCES_RECEIVED:
    case CARETEAMS_RECEIVED:
      return appendAllUniqueIds(state, payload.CareTeam)
    default:
      return state
  }
}

export const byId = (state = {}, action) => {
  const { type, payload } = action
  switch (type) {
    case FHIR_RESOURCES_RECEIVED:
    case CARETEAMS_RECEIVED:
      return reduceById(state, payload.CareTeam)
    default:
      return state
  }
}

export default combineReducers({
  allIds,
  byId,
})
