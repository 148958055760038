import React from 'react'
import { getPriceDisplay } from '../utils'
import styled from 'styled-components/macro'

const MediumMessage = styled.div`
  margin-top: ${props => props.theme.spacing.small};
  margin-bottom: ${props => props.theme.spacing.small};
  color: white;
  text-align: center;
  font-size: ${props => props.theme.medium};
`

const PriceIndication = ({ price, paymentMethod, paymentStatus }) => (
  <MediumMessage>
    {getPriceDisplay(price, paymentMethod, paymentStatus)}
  </MediumMessage>
)

export default PriceIndication
