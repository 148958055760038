import React, { useContext } from 'react'
import { Form, Input } from '../../Components'
import { EditPractitionerContext } from '../container/EditPractitionerForm'
import { I18n } from '@lingui/react'
import { t } from '@lingui/macro'

const TelecomInputs = ({ readOnly }) => {
  const [resource, setResource] = useContext(EditPractitionerContext)
  const telecoms = resource.practitioner?.telecom ?? []
  const telecomRows = [
    { label: 'Téléphone', name: 'phone', value: '', disabled: false },
    {
      label: 'Email',
      name: 'email',
      value: '',
      disabled: readOnly
    }
  ]
  telecoms.map(telecom => {
    telecomRows.find(tR => tR.name === telecom.system).value = telecom.value
    return null
  })

  const handleChange = e => {
    const { value, name } = e.currentTarget
    const index = telecoms.findIndex(i => i.system === name)
    if (index >= 0) {
      telecoms[index].value = value
    } else {
      telecoms.push({
        system: name,
        value: value,
        use: 'work'
      })
    }
    setResource({
      ...resource,
      practitioner: {
        ...resource.practitioner,
        telecom: telecoms
      }
    })
  }

  return (
    <Form.Row>
      {telecomRows.map(telecomRow => (
        <I18n key={telecomRow.name}>
          {({ i18n }) => (
            <div
              data-tooltip={
                telecomRow.disabled
                  ? i18n._(t`Please, contact us for modify your email`)
                  : null
              }
            >
              <Input
                name={telecomRow.name}
                label={telecomRow.label}
                data-test={telecomRow.label}
                type={telecomRow.name === 'phone' ? 'tel' : 'text'}
                minLength={telecomRow.name === 'phone' ? '10' : undefined}
                maxLength={telecomRow.name === 'phone' ? '10' : undefined}
                defaultValue={telecomRow.value}
                onChange={handleChange}
                disabled={telecomRow.disabled}
                required
              />
            </div>
          )}
        </I18n>
      ))}
    </Form.Row>
  )
}

export default TelecomInputs
