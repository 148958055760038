// action to send a review by a specialist
export const ADDING_CLINICAL_IMPRESSION = 'ADDING_CLINICAL_IMPRESSION'
export const CLINICAL_IMPRESSION_RECEIVED = 'CLINICAL_IMPRESSION_RECEIVED'
export const CLINICAL_IMPRESSIONS_RECEIVED = 'CLINICAL_IMPRESSIONS_RECEIVED'
export const FETCHING_CLINICAL_IMPRESSIONS = 'FETCHING_CLINICAL_IMPRESSIONS'

export const addClinicalImpression = action => {
  return {
    type: ADDING_CLINICAL_IMPRESSION,
    payload: {
      review: action.text,
      encounterID: action.encounterID,
      practitionerID: action.currentPractitionerID,
      patientID: action.patientID,
    },
  }
}

export const loadClinicalImpressionsFromContext = encounterID => ({
  type: FETCHING_CLINICAL_IMPRESSIONS,
  payload: {
    encounterID,
  },
})
