import { Trans } from '@lingui/macro'
import { useNavigate } from '@reach/router'
import { useContext, useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useEffectOnce } from 'react-use'
import styled from 'styled-components/macro'
import { isEqual } from 'underscore'
import { getCurrentOrganizationId } from '../../Auth/selectors'
import { getTokenFromAmplify } from '../../Auth/utils'
import { Spinner } from '../../Components'
import { CurrentOrganizationIdContext } from '../../Organization/containers/CurrentOrganizationIdProvider'
import { getPractitionerRolesBypractitionerIDFromState } from '../../PractitionerRole/selector'
import GridLayout from '../../Shared/components/GridLayout'
import Heading from '../../Shared/components/Heading'
import Subheading from '../../Shared/components/Subheading'
import { displayPractitionerImg } from '../../Shared/display/practitioner'
import { displayPractitionerActiveRole } from '../../Shared/display/practitionerRole'
import { hoverMixin } from '../../Shared/mixins'
import { FhirEnvIsProd } from '../../_common/config'
import { PIN_CODE_NOT_FOUND, PRACTITIONER_SELECTED } from '../actions'
import { getPractitionersFromCurrentOrganization } from '../selector'
import PractitionerHead from './PractitionerHead'
import { MEDEO_CODE_PIN_SYSTEM } from './TypePinCodeForm'

const List = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 50rem;
  justify-content: center;
  align-items: flex-start;
  margin: 0 auto;
`

const ListItem = styled.div`
  border-radius: 0.25rem;
  border: 1px solid transparent;
  cursor: pointer;
  flex: 0 0 10rem;
  text-decoration: none;
  color: ${p => p.theme.ebony};
  ${hoverMixin}
`

const Flex = styled.div`
  display: flex;
  padding-top: 2rem;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

// this function is extracted from the component as it will cause rerender
// this loads practitionersRoles having the medical code

const SelectPractitionerForm = () => {
  const organizationId = useSelector(getCurrentOrganizationId)
  const [, setCurrentOrganization] = useContext(CurrentOrganizationIdContext)

  // shallowEqual prevents re-render when the selector returns an object
  // see https://react-redux.js.org/api/hooks#equality-comparisons-and-updates
  const practitioners = useSelector(
    getPractitionersFromCurrentOrganization,
    shallowEqual
  )

  // shallowEqual only check for equality on the first level of key value
  // therefore practitionerRolesById should be check with a deeper equality
  // isEqual will do the job.
  const practitionerRolesById = useSelector(
    getPractitionerRolesBypractitionerIDFromState,
    isEqual
  )

  const navigate = useNavigate()
  const reduxDispatch = useDispatch()

  useEffect(() => {
    // we select the organizationId and assign it to our organization context
    setCurrentOrganization(organizationId)
  }, [organizationId, setCurrentOrganization])

  const handleClick = practitioner => {
    // check if Pin Code exists
    const remoteHash = practitioner?.identifier?.find(
      i => i.system === MEDEO_CODE_PIN_SYSTEM
    )?.value
    if (remoteHash == null) {
      reduxDispatch({
        type: PIN_CODE_NOT_FOUND,
        payload: { practitioner: practitioner }
      })
      navigate(`/auth/${practitioner.id}/pin/create`)
    }
    if (remoteHash != null) {
      reduxDispatch({
        type: PRACTITIONER_SELECTED,
        payload: { practitioner: practitioner }
      })
      navigate(`/auth/${practitioner.id}/pin`)
    }
  }

  useEffectOnce(() => {
    
      if (FhirEnvIsProd() === false) {
        getTokenFromAmplify().then(token => {
          console.warn(token)
        })
      } 
  })
  return (
    <GridLayout.Main variant="full">
      <Flex>
        <Heading>
          <Trans>Users</Trans>
        </Heading>
        <Subheading>
          <Trans>Select your profile</Trans>
        </Subheading>
      </Flex>
      {practitioners.length === 0 ? (
        <Flex>
          <Spinner />
        </Flex>
      ) : (
        <List>
          {practitioners.map(practitioner => (
            <ListItem
              key={practitioner.id}
              data-test={`practitioner/${practitioner.id}`}
              to={`/auth/pin?practitionerID=${practitioner.id}`}
            >
              <PractitionerHead
                img={displayPractitionerImg(practitioner)}
                role={displayPractitionerActiveRole(
                  practitionerRolesById[practitioner.id],
                  <Trans>Practitioner</Trans>
                )}
                practitioner={practitioner}
                onClick={() => handleClick(practitioner)}
              />
            </ListItem>
          ))}
        </List>
      )}
    </GridLayout.Main>
  )
}

SelectPractitionerForm.defaultProps = {
  practitioners: []
}

export default SelectPractitionerForm
