import styled from 'styled-components/macro'
import { Link } from '@reach/router'

const CustomLink = styled(Link)`
  color: ${p => p.theme.ocean};
  text-decoration: none;
  font-size: ${p => p.theme.small};
  &:hover {
    color: ${p => p.theme.ocean};
  }
`

export default CustomLink
