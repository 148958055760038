import React, { useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import { useDispatch, useSelector } from 'react-redux'
import { addClinicalImpression } from '../actions'
import { t, Trans } from '@lingui/macro'
import { I18n } from '@lingui/react'
import { faShareSquare } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { usePermission } from '../../Permissions/permissions'
import { displayDateFromClinicalImpression } from '../../Shared/display/clinicalImpression'
import {
  displayFamilyName,
  displayGivenName
} from '../../Shared/display/entity'
import {
  getAskingPractitionerFromProcedureRequestId,
  getAssessorFromClinicalImpression
} from '../../Practitioner/selector'
import usePractitioner from '../../Practitioner/usePractitioner'
import { updateProcedureRequest } from '../../ProcedureRequest/actions'
import { Button } from '../../Components'
import mixpanel from '../../Shared/mixpanel'
import RequirePermission from '../../Permissions/containers/RequirePermission'

const Container = styled.div``

const Right = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 0.3rem;
`

const Label = styled.div`
  color: ${props => props.theme.nevada};
  font-weight: ${props => props.theme.bold};
  text-transform: uppercase;
  margin-right: ${props => props.theme.spacing.medium};
  margin-bottom: ${p => p.theme.spacing.small};
`

const SubLabel = styled.div`
  color: ${props => props.theme.nevada};
  margin-right: ${props => props.theme.spacing.medium};
  margin-bottom: ${p => p.theme.spacing.small};
`

const Form = styled.form``

const ButtonText = styled.span`
  margin-left: ${props => props.theme.spacing.small};
`

const EditReviewForm = ({
  encounter,
  clinicalImpression,
  procedureRequest,
  patientID
}) => {
  const [text, setText] = useState('') //Content of TextArea
  const [buttonDisabled, setButtonDisabled] = useState(true) //Des/Activate the InterpretationButton
  const [
    textAreaDisabledOrCompleted,
    setTextAreaDisabledOrCompleted
  ] = useState(false) //Des/Activate the TextArea
  const assessorOfClinicalImpression = useSelector(state =>
    getAssessorFromClinicalImpression(state, clinicalImpression)
  ) //The selector to get the review owner
  const isUserAllowToAddReview = usePermission('clinicalImpressions', 'write')
  const dispatch = useDispatch()
  const currentPractitioner = usePractitioner()
  const currentPractitionerID = currentPractitioner.id
  const encounterID = encounter.id

  useEffect(() => {
    //check we have a clinicalImpression attached at this encounter and do the process to display it
    if (clinicalImpression != null) {
      setText(clinicalImpression.summary)
      setTextAreaDisabledOrCompleted(true)
    }
    // If the user is a GP, he can't modify the TextArea
    if (!isUserAllowToAddReview) {
      setTextAreaDisabledOrCompleted(true)
    }
    //The process to get the activate or desactive the button for add a review
    if (text === '') {
      setButtonDisabled(true)
    } else if (text !== '') {
      setButtonDisabled(false)
    }
  }, [text, clinicalImpression, isUserAllowToAddReview])

  const askingPractitioner = useSelector(state =>
    getAskingPractitionerFromProcedureRequestId(state, procedureRequest)
  )

  const handleSubmit = e => {
    dispatch(
      addClinicalImpression({
        text,
        encounterID,
        currentPractitionerID,
        patientID
      })
    )
    dispatch(updateProcedureRequest(procedureRequest))
    e.preventDefault()
    // mixpanel for notifications
    if (
      askingPractitioner != null &&
      askingPractitioner.telecom &&
      askingPractitioner.telecom[0] &&
      askingPractitioner.name &&
      askingPractitioner.name[0]
    ) {
      mixpanel.respi.identify(askingPractitioner.id)
      mixpanel.respi.people.set({
        $email: askingPractitioner.telecom[0].value,
        $name: askingPractitioner.name[0].family,
        $last_login: new Date()
      })
      mixpanel.respi.track('receivesClinicalImpression')
      mixpanel.respi.identify(currentPractitioner.id)
      mixpanel.respi.track('createsClinicalImpression')
    } else {
      console.log('no name or email for the practitioner to send an email to')
    }
  }

  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <Label>Interprétation :</Label>
        {clinicalImpression && (
          <SubLabel>
            {displayDateFromClinicalImpression(clinicalImpression)} -{' '}
            {displayGivenName(assessorOfClinicalImpression)}{' '}
            {displayFamilyName(assessorOfClinicalImpression)}
          </SubLabel>
        )}
        <I18n>
          {({ i18n }) => (
            <textarea
              placeholder={i18n._(t`Write a review`)}
              rows="4"
              style={{ resize: 'none', width: '100%' }}
              value={text}
              onChange={e => setText(e.target.value)}
              disabled={textAreaDisabledOrCompleted}
            />
          )}
        </I18n>
        <RequirePermission resource={'clinicalImpressions'} action={'write'}>
          {!textAreaDisabledOrCompleted && (
            <Right>
              <Button disabled={buttonDisabled}>
                <FontAwesomeIcon icon={faShareSquare} />
                <ButtonText>
                  <Trans>Send a report</Trans>
                </ButtonText>
              </Button>
            </Right>
          )}
        </RequirePermission>
      </Form>
    </Container>
  )
}

export default EditReviewForm
