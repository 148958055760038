import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import AttachmentsListFromEncounter from '../../Attachment/components/AttachmentListFromEncounter'
import * as fromDocumentReferences from '../../DocumentReference/actions'
import { getActiveDocumentsByencounterID } from '../ducks'
/**
 * This component will load from the redux store
 * the document references referred by the encounter passed as a prop
 * It will extract the attachments from these refs and display them as
 * an AttachmentsList.
 *
 * It expects children to be a renderProp and will map over it.
 * it allows the parent component to specify which kind of sub component
 * it should show. children should look like the following:
 * attachment => <Attachment attachment={attachment}>
 *   <AttachmentPrintControl/>
 *   <AttachmentCustomControl/>
 * </Attachment>
 *
 * You can pass current and progress props to give a visual feedback to the user
 * about the current progress of an upload.
 * see the AttachmentInput for more details.
 *
 * @param encounter
 * @param current
 * @param children
 * @param progress
 * @param {function} onProgress
 * @param {function} onChange
 * @param readOnly
 * @returns {*}
 * @constructor
 */
const DocumentReferenceList = ({
  encounter,
  current,
  children,
  progress,
  onProgress,
  onChange,
  // default value false is needed in preteleconsultation
  // where readOnly on clinical-exam is undefined
  readOnly = false,
  ...rest
}) => {
  const dispatch = useDispatch()
  useEffect(() => {
    if (encounter === undefined || encounter === null) {
      return
    }
    const patientID = encounter.subject?.reference?.split('/')[1]
    if (patientID === undefined || patientID === null || patientID === '') {
      throw new Error('Patient id is missing')
    }
    dispatch(
      fromDocumentReferences.loadDocumentReferences(encounter.id, patientID)
    )
  }, [encounter, dispatch])
  const documents = useSelector(getActiveDocumentsByencounterID(encounter.id))

  // extract the attachment from the DocumentReference
  // so we can use the Attachment Component.
  const attachments = documents?.flatMap((d) =>
    d?.content?.map((c) => c.attachment)
  )

  // AttachmentsList expect children to be a function, this function takes 3
  // parameters attachment, progress, and the index.
  // We need to return a Component from these parameters and it will populate
  // the underlying list
  return documents.length === 0 && readOnly ? null : ( // if we are in a readonly mode and there is no documents, we don't want to display 'Documents'
    <AttachmentsListFromEncounter
      attachments={attachments}
      current={current}
      progress={progress}
      onProgress={onProgress}
      onChange={onChange}
      encounter={encounter}
      readOnly={readOnly}
      {...rest}
    >
      {(attachment, progress, i) => {
        // What should be rendered is the parent's concern for this component
        // this way we can show different element depending on which component
        // is calling this one
        // DocumentReferenceList from within the clinical exam or from the
        // encounter list.

        // Note: instead of returning the index here
        // we return the original document - documents[i] - it allows handler like
        // handleClick in AddAttachmentForm to be called with the correct parameter
        return children(attachment, progress, documents[i])
      }}
    </AttachmentsListFromEncounter>
  )
}
export default DocumentReferenceList
