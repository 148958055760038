import React, { useContext } from 'react'
import { Trans } from '@lingui/macro'
import { Form, Input } from '../../Components'
import { EditPractitionerContext } from '../container/EditPractitionerForm'
import { set } from '../../utils/fhirpath'

const AddressInputs = () => {
  const [resource, setResource] = useContext(EditPractitionerContext)
  const address = resource.practitioner?.address
  const line = address?.line?.[0]
  const postalCode = address?.postalCode
  const city = address?.city
  const country = address?.country

  const handleChange = e => {
    const { value, name } = e.currentTarget
    if (name === 'line') {
      setResource({
        ...resource,
        practitioner: set(
          resource.practitioner,
          `address.item(0).line.item(0)`,
          value
        )
      })
    } else {
      setResource({
        ...resource,
        practitioner: set(
          resource.practitioner,
          `address.item(0).${name}`,
          value
        )
      })
    }
  }

  return (
    <>
      <Form.Row>
        <Input
          name="line"
          label={<Trans>Street</Trans>}
          onChange={handleChange}
          defaultValue={line}
          data-test="street"
        />
      </Form.Row>
      <Form.Row>
        <Input
          name="postalCode"
          onChange={handleChange}
          label={<Trans>Postal code</Trans>}
          defaultValue={postalCode}
          data-test="zipcode"
        />
        <Input
          name="city"
          onChange={handleChange}
          label={<Trans>City</Trans>}
          defaultValue={city}
          data-test="city"
        />
      </Form.Row>
      <Form.Row>
        <Input
          name="country"
          onChange={handleChange}
          label={<Trans>Country</Trans>}
          defaultValue={country}
          data-test="country"
        />
      </Form.Row>
    </>
  )
}

export default AddressInputs
