import React, { useEffect, useRef } from 'react'
import { Card } from '../../Components'
import styled from 'styled-components/macro'
import { hoverMixin } from '../mixins'

function useOnClickOutside(ref, handler) {
  useEffect(
    () => {
      const listener = event => {
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current.contains(event.target)) {
          return
        }

        handler(event)
      }

      document.addEventListener('mousedown', listener)
      document.addEventListener('touchstart', listener)

      return () => {
        document.removeEventListener('mousedown', listener)
        document.removeEventListener('touchstart', listener)
      }
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [ref, handler]
  )
}

const Relative = styled.div`
  justify-self: flex-start;
  position: relative;
  display: inline-block;
`
const Absolute = styled.div`
  position: absolute;
  right: -0.5rem;
  // this transformation works well for the menu in the MedeoBar
  transform: translateX(100%) translateY(-100%);
  // rbc is using z-index:3 for the current time indicator
  z-index: 4;
`

const List = styled.ul`
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
  flex-direction: column;
  width: max-content;
  text-align: left;
  & > li {
    flex: 1;
    padding: 0.5rem;
  }
`

const CustomCard = styled(Card)`
  padding: 0.5rem;
`
const Separator = styled.li`
  content: '';
  border-bottom: 1px solid ${p => p.theme.gray};
  padding: 0 !important;
  margin: 0.25rem 0;
`

const ListItem = styled.li`
  cursor: pointer;
  border-radius: 0.25rem;
  border: 1px solid transparent;
  ${hoverMixin};
  color: ${p => p.theme.black};
  & a {
    text-decoration: none;
    color: ${p => p.theme.black};
  }
  padding: 0;
`

/**
 *
 * @param {React.Component} Component
 * @param {React.Children} children
 * @param {string} the url param that controls the opening of the menu
 * @return {JSX.Element}
 * @constructor
 */
const Menu = ({
  component: Component,
  children,
  open,
  onClickMenu,
  onClickOutside
}) => {
  // the ref is used to catch any click outside
  // if the user click outside, navigate to the url without the control params
  const ref = useRef()
  useOnClickOutside(ref, () => {
    onClickOutside()
  })
  return (
    <Relative>
      {/* if the user clicks on the menu button, switch the param's state in the url */}
      <Component onClick={onClickMenu} />
      {open && (
        <Absolute ref={ref}>
          <CustomCard>
            <nav>
              <List>{children}</List>
            </nav>
          </CustomCard>
        </Absolute>
      )}
    </Relative>
  )
}
// we expose Separator and ListItem as compound components
Menu.Separator = Separator
Menu.ListItem = ListItem

export default Menu
