import React, { useContext } from 'react'
import styled from 'styled-components/macro'
import { Trans } from '@lingui/macro'
import { Button } from '../../Components'
import { CurrentPractitionerRoleContext } from '../../PractitionerRole/containers/CurrentPractitionerRoleProvider'
import ModalCreateEncounterButton from '../../Encounter/containers/ModalCreateEncounterButton'
import {
  MedeoEncounterTypes,
  MEDEO_ENCOUNTER_TYPE_BOOKING,
  MEDEO_ENCOUNTER_TYPE_PRE_TELECONSULTATION
} from '../../utils/encounter-type'
import { isPractitionerAllowedToStartRemoteConsultation } from '../../Permissions/permissions'
import Modal from '../../Shared/components/Modal'

const Container = styled.div`
  padding: 0 2rem;
  overflow-y: auto;
`

/**
 * When the selected encounter is Bimedoc we don't need the parent element (ChooseEncounterList) to be a grid
 * because instead of showing a button we are showing a custom Bimedoc view.
 * For this reason I created two different styles that will be passed depending on the encounter we choose.
 */
export const ChooseEncounterList = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0.5rem;
  align-items: flex-start;
  margin: 2rem 0;
  width: 100%;
`

const HiddenDiv = styled.div`
  display: none;
`

const EncounterTabGenerator = styled.div``

const ChooseEncounterTypeDialog = ({ hasAppointmentToday }) => {
  const [currentPractitionerRole] = useContext(CurrentPractitionerRoleContext)
  const canDoRemoteConsultation = isPractitionerAllowedToStartRemoteConsultation(
    currentPractitionerRole
  )

  //TODO: the options should be fetch from the FHIR server using the documentReference
  // we could provide an S3 folder were users could define there own forms
  const options = MedeoEncounterTypes

  return (
    <Modal
      title={<Trans>Which examination do you want to start?</Trans>}
      component={({ onClick }) => (
        <Button
          onClick={onClick}
          color="ocean"
          data-intercom-target="new-exam"
          data-test={'new-exam'}
          style={{ fontSize: 'small' }}
        >
          <Trans>Start procedure</Trans>
        </Button>
      )}
    >
      <Container>
        {/* to search another solution, the RequirePermission doesn't work in here because the tabs are loaded before opening the modal */}
        {options.map((tab, j) => {
          const code = tab?.encounters
            .find(e => e.coding)
            ?.coding.find(c => c.code)?.code
          // only pharmacist can access Bimedoc feature
          // Only practitioner who has role 1, 2 or 3 can start a remote consultation
          return !canDoRemoteConsultation &&
            (code === MEDEO_ENCOUNTER_TYPE_PRE_TELECONSULTATION ||
              code === MEDEO_ENCOUNTER_TYPE_BOOKING) ? (
            <HiddenDiv index={j} key={j} />
          ) : (
            <EncounterTabGenerator index={j} key={j}>
              {/* 'code' prop is necessary for changing the style of the parent element depending on the encounter chosen */}
              <ChooseEncounterList code={code}>
                {tab?.encounters.map((e, i) => (
                  <ModalCreateEncounterButton
                    encounter={e}
                    key={i}
                    hasAppointmentToday={hasAppointmentToday}
                  />
                ))}
              </ChooseEncounterList>
            </EncounterTabGenerator>
          )
        })}
      </Container>
    </Modal>
  )
}
export default ChooseEncounterTypeDialog
