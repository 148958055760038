import React from 'react'
import styled from 'styled-components/macro'
import { Trans } from '@lingui/macro'
import { Card } from '../../Components'
import Questionnaire from '../../Questionnaire/components/Questionnaire'
import usePractitioner from '../../Practitioner/usePractitioner'
import { MEDEO_MODEL_SIGNATURE_IDENTIFIER_VALUE } from '../../utils/codes'
import { useSearch } from '../../Shared/hooks'
import { getTypeAndIdFromLocalReference } from '../../utils/fhir'

const Container = styled.div`
  margin-bottom: 2rem;
`

const DisclaimerDiv = styled.div`
  line-height: 2;
  color: #98a5b2;
  font-size: small;
  margin-bottom: 1rem;
`

const ModelForCustomDocumentPage = () => {
  const practitioner = usePractitioner()
  const { data } = useSearch('Questionnaire', {
    _revinclude: 'QuestionnaireResponse:questionnaire',
    identifier: MEDEO_MODEL_SIGNATURE_IDENTIFIER_VALUE
  })
  if (data == null) {return 'chargement'}
  const responses = data.QuestionnaireResponse.filter(r => {
    const [, id] = getTypeAndIdFromLocalReference(r.subject.reference)
    return id === practitioner.id
  })

  let signatureQuestionnaire = data.Questionnaire.find(
    q => q.identifier[0].value === 'signature'
  )

  let signatureQuestionnaireResponse

  responses.forEach(response => {
    const reference = response?.questionnaire?.reference
    const id = reference.split('/')[1]
    if (id === signatureQuestionnaire?.id)
      {signatureQuestionnaireResponse = response}
  })
  return signatureQuestionnaire ? (
    <Container data-intercom-target="user-signature">
      <Card>
        <Card.Header>
          <h1>{signatureQuestionnaire.title}</h1>
        </Card.Header>
        <DisclaimerDiv>
          <Trans>Take a picture of your signature and upload it here</Trans>.
          <br />
          <Trans>
            Medeo requires your signature to customise the documents you create
            directly on the app
          </Trans>
          .
          <br />
          <Trans>Caresheet, Orders and sick leaves will use this picture</Trans>
          .
        </DisclaimerDiv>

        <Questionnaire
          id={signatureQuestionnaire.id}
          readOnly={false}
          response={signatureQuestionnaireResponse}
        />
      </Card>
    </Container>
  ) : (
    <p>
      <Trans>Something went wrong!</Trans>
    </p>
  )
}

export default ModelForCustomDocumentPage
