import React from 'react'
import { Trans } from '@lingui/macro'
import { Card } from '../../Components'
import EditPractitionerForm from '../container/EditPractitionerForm'

const PractitionerPageInSettings = () => {
  return (
    <Card>
      <h1>
        <Trans>My profile</Trans>
      </h1>
      <EditPractitionerForm readOnly={true} use={'editUserAsUser'} />
    </Card>
  )
}

export default PractitionerPageInSettings
