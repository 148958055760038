/** @module Shared */

// This action creator probably needs to be refactored in order to be less dependent on the type of the resource...
// It returns an action which type will look like 'ENCOUNTERS_RECEIVED' and the payload is populated with the entries
// under the correct field name.
import snakeCase from 'lodash/snakeCase'
import camelCase from 'lodash/camelCase'

export const FHIR_RESOURCES_RECEIVED = 'FHIR_RESOURCES_RECEIVED'
export const SAVING_FHIR_RESOURCE = 'SAVING_FHIR_RESOURCE'

export const receiveTotalFhirResources = (type, body) => {
  if (type == null || body == null) {throw new Error('parameters are expected')}
  const totalResource = body.total
  return {
    type: `TOTAL_${snakeCase(type).toUpperCase()}_RECEIVED`,
    payload: { total: totalResource },
  }
}

/**
 * extract FHIR resources out of a Bundle and transforms them in a map
 * the map keys are the FHIR resources names (Patient, Encounter, ...)
 * and the values are arrays of this type of resources
 * @param entries
 * @return {*}
 */
export const groupResources = (entries, previousState = {}) => {
  const resources =
    entries != null && entries.length > 0 ? entries.map(e => e.resource) : []
  //at this step we have resources = [{ id: '12', resourceType: 'Patient' },  { id: '13', resourceType: 'Encounter' }]

  return resources.reduce((groupedByType, r) => {
    const currentType = r.resourceType
    const previousResourceList = groupedByType[r.resourceType] || []
    return {
      // spread the groupedByType object so other types get passed along
      ...groupedByType,
      // overwrite the currentType field with a new list where we append the current resource
      [currentType]: [...previousResourceList, r],
    }
  }, previousState)
}
export const receiveFhirResources = body => {
  if (body == null) {throw new Error('parameters are expected')}

  // soit on créer une action par type
  // body on a [patient, patient, sr, sr, sr]
  // on regroupe les patients ensemble, les services request aussi
  // on aurait [p, p] et [sr, sr, sr]
  // on créé une nouvelle action avec FHIR_RESOURCES_RECEIVED
  // on pourrait avoir un payload comme ca:
  // payload = {
  //   patients: [p, p],
  //   procedureRequests: [sr,sr,sr]
  // }

  //extract total as it can be useful to check if we have all the resources loaded
  // - flatten entries and pass link and total along
  // - retrieve the id of the bundle for pagination purpose, more info in Bundle/duck.js
  const { entry: entries, link, total, id } = body
  const resourcesGroupedByType = groupResources(entries)
  /* at this step we have :
    resourcesGroupedByType = {
      Patient: [{ id: '12', resourceType: 'Patient' }, { id: '14', resourceType: 'Patient' }],
      Encounter: [{ id: '13', resourceType: 'Encounter' }]
    }
  */

  return {
    type: FHIR_RESOURCES_RECEIVED,
    payload: { ...resourcesGroupedByType, link, total, id },
  }
}

export const receiveFhirResource = (type, resource) => {
  if (type == null || resource == null)
    {throw new Error('resourceType is expected')}

  return {
    type:
      type === 'AllergyIntolerance'
        ? 'ALLERGY_INTOLERANCE_RECEIVED'
        : `${snakeCase(type).toUpperCase()}_RECEIVED`,
    payload: {
      [`${camelCase(type)}`]: resource,
    },
  }
}

// Condition if as two parameter ....

export const failReceivingFhirResource = (type, error) => {
  if (type == null) {throw new Error('resourceType is expected')}
  return {
    type: `FAIL_RECEIVING_${type.toUpperCase()}`,
    payload: {
      error,
    },
  }
}

export const SEARCHING_FHIR_RESOURCES = 'SEARCHING_FHIR_RESOURCES'
export const READING_FHIR_RESOURCE = 'READING_FHIR_RESOURCE'

/**
 * @typedef SearchAction
 * @extends Action
 * @param {ResourceType} payload.type
 * @param {object} payload.params
 */

/**
 * load method exposes params from [fhirClient]{@link https://www.npmjs.com/package/lifen-fhir.js}
 * it allows fine control over what type of resource to load.
 * By exposing the params, no specific action nor saga is required to create a
 * complex loader like before.
 * @param {ResourceType} type
 * @param {object} params
 * @returns {SearchAction} action
 */
export const search = (type, params) => {
  if (type == null) {throw new Error('resourceType is expected')}
  return {
    type: SEARCHING_FHIR_RESOURCES,
    payload: {
      type,
      params,
    },
  }
}

/**
 * @deprecated please use the {@link search} method instead
 * @param type
 * @param id
 * @returns {{payload: {id: *}, type: string}}
 */
export const loadFhirResourceFromId = (type, id) => {
  if (type == null) {throw new Error('resourceType is expected')}
  return {
    type: `FETCHING_${snakeCase(type).toUpperCase()}`,
    payload: {
      id,
    },
  }
}

/**
 * For the FhirResource 'AllergyIntolerance' the subject is patientID and not subjectId...
 * @deprecated please use the {@link search} method instead
 * @param type
 * @param subjectId
 * @returns {{payload: {subjectId: *}, type: string}}
 */
export const loadFhirResourceFrompatientID = (type, subjectId) => {
  if (type == null) {throw new Error('resourceType is expected')}
  return {
    type: `FETCHING_${snakeCase(type).toUpperCase()}S`,
    payload: {
      subjectId,
    },
  }
}
/**
 * @deprecated please use the {@link search} method instead
 * @param type
 * @param subjectId
 * @returns {{payload: {subjectId: *}, type: string}}
 */
export const loadFhirResourceFromOrganizationId = (type, subjectId) => {
  if (type == null) {throw new Error('resourceType is expected')}
  return {
    type: `FETCHING_${snakeCase(type).toUpperCase()}S`,
    payload: {
      subjectId,
    },
  }
}

export const fhirResourceRemoved = r => {
  if (r.resourceType == null) {throw new Error('resourceType is expected')}
  const action = {
    type:
      r.resourceType === 'AllergyIntolerance'
        ? 'ALLERGY_INTOLERANCE_REMOVED'
        : `${r.resourceType.toUpperCase()}_REMOVED`,
    payload: {
      [r.resourceType === 'AllergyIntolerance'
        ? 'allergyIntolerance'
        : `${r.resourceType.toLowerCase()}`]: r,
    },
  }
  return action
}

/**
 * the save action creator works with the saveFhirResourceWorker
 * @param resource
 * @returns {{payload: *, type: string}}
 */
export const save = resource => ({
  type: SAVING_FHIR_RESOURCE,
  payload: resource,
})
