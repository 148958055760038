import React from 'react';
import {
  displayAge,
  displayFamilyName,
  displayGender,
  displayGivenName,
  displayLastVisitDate
} from '../../Shared/display/entity';
import styled from 'styled-components/macro';
import { useSelector } from 'react-redux';
import { getLastEncounterFromSubjectIdWithFilters } from '../../Encounter/selectors';

const Cell = styled.td`
  font-size: ${props => props.theme.small};
  font-weight: ${props => props.theme.lighter};
  padding: ${props => props.theme.spacing.medium} 0;
`;

const NameCell = styled(Cell)`
  font-weight: ${props => props.theme.bold};
  padding-left: ${props => props.theme.spacing.large};
`;

const PatientTableRow = ({ patient, ...rest }) => {
  const encounter = useSelector(s =>
    getLastEncounterFromSubjectIdWithFilters(s, patient.id)
  );
  return (
    <tr role="button" {...rest}>
      <NameCell className="fs-block">
        {displayFamilyName(patient)} {displayGivenName(patient)}
      </NameCell>
      <Cell>{displayAge(patient)}</Cell>
      <Cell>{displayGender(patient)}</Cell>
      <Cell>{displayLastVisitDate(encounter)}</Cell>
    </tr>
  );
};

export default PatientTableRow;
