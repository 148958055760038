import React from 'react'
import styled from 'styled-components/macro'
import { Table } from '../../Components'

export const Row = styled.tr`
  & > td,
  & > th {
    padding: 1rem 0.5rem;
    &:first-of-type {
      width: 3rem;
      padding-left: 2.5rem;
    }
    &:last-of-type {
      padding-right: 2rem;
    }
  }
  & > td {
    font-weight: normal;
  }
`

const PractitionerTable = ({
  practitioners,
  children,
  headerRow: HeaderRow
}) => {
  return (
    <Table>
      <thead>
        <HeaderRow />
      </thead>
      <tbody>{practitioners.map(children)}</tbody>
    </Table>
  )
}
export default PractitionerTable
