import {
  BLOOD_PRESSURE_MONITOR_SNOMED,
  DERMATOSCOPE_SNOMED,
  OTOSCOPE_SNOMED,
  OXIMETER_SNOMED,
  STETHOSCOPE_SNOMED,
  THERMOMETER_SNOMED
} from './codes'

/**
 * From a given list of devices, select the ones which can be
 * used for a TLC
 * @param {Device[]} devices
 * @returns
 */
export const selectTLCDevices = devices => {
  if (devices == null) {return []}

  const tlcDevicesSnomed = [
    BLOOD_PRESSURE_MONITOR_SNOMED,
    OTOSCOPE_SNOMED,
    OXIMETER_SNOMED,
    STETHOSCOPE_SNOMED,
    THERMOMETER_SNOMED,
    DERMATOSCOPE_SNOMED
  ]

  return tlcDevicesSnomed.filter(code =>
    devices.some(device => {
      const tlcDeviceType = device.type?.coding?.find(
        coding => coding.code === code
      )

      if (tlcDeviceType == null) {return false}
      else {
        // If the concerned device is a stethoscope,
        // we only consider the wired ones as the connected ones are handled
        // separately
        return !(
          tlcDeviceType.code === STETHOSCOPE_SNOMED &&
          !wiredStethoscopeManufacturers.includes(device.manufacturer)
        )
      }
    })
  )
}

const wiredStethoscopeManufacturers = ['RNK']
