import { Trans } from '@lingui/macro'
import React from 'react'
import styled from 'styled-components/macro'
import {
  MEDEO_SIMPLE_ADD_PATIENT_QUESTIONNAIRE_IDENTIFIER,
  MEDEO_QUESTIONNAIRE_IDENTIFIER,
  MEDEO_SELECT_PATIENT_QUESTIONNAIRE_IDENTIFIER
} from '../../utils/codes'
import QuestionnaireSequencer from '../../Questionnaire/components/QuestionnaireSequencer'
import { Display } from '../../Questionnaire/components/QuestionnaireComponent'
import ExistingPatientRecap from './ExistingPatientRecap'
import usePlanDefinition from '../../PlanDefinition/usePlanDefinition'
import { getQuestionnaireByIdentifier } from '../../Questionnaire/selector'
import { useSelector } from 'react-redux'

const Container = styled.div`
  border-top: 1px solid ${p => p.theme.gray};
  padding-top: 2rem;
`

const SelectPatientResponse = () => {
  const { planDefinition } = usePlanDefinition()

  const addPatientQuestionnaire = useSelector(
    getQuestionnaireByIdentifier(
      MEDEO_SIMPLE_ADD_PATIENT_QUESTIONNAIRE_IDENTIFIER
    )
  )
  const selectPatientQuestionnaire = useSelector(
    getQuestionnaireByIdentifier(MEDEO_SELECT_PATIENT_QUESTIONNAIRE_IDENTIFIER)
  )

  const patientResponse = planDefinition.sequenceResponse.find(
    response =>
      response.questionnaireId === addPatientQuestionnaire.id ||
      response.questionnaireId === selectPatientQuestionnaire.id
  )

  const simpleAddpatientIDentifier = {
    system: MEDEO_QUESTIONNAIRE_IDENTIFIER,
    code: MEDEO_SIMPLE_ADD_PATIENT_QUESTIONNAIRE_IDENTIFIER
  }

  return (
    <Container>
      <Display>
        <Trans>Patient Information</Trans>
      </Display>
      {patientResponse.questionnaireId === selectPatientQuestionnaire.id ? (
        <ExistingPatientRecap questionnaireResponse={patientResponse} />
      ) : (
        <QuestionnaireSequencer
          identifier={simpleAddpatientIDentifier}
          readOnly={true}
          isColumn={false}
          isCard={true}
        />
      )}
    </Container>
  )
}

export default SelectPatientResponse
