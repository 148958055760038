/*eslint-disable*/
import { combineReducers } from 'redux'
import {
  CLINICAL_IMPRESSIONS_RECEIVED,
  CLINICAL_IMPRESSION_RECEIVED,
} from './actions'
import { reduceById, appendAllUniqueIds } from '../Shared/ducks'
import { FHIR_RESOURCES_RECEIVED } from '../Shared/actions'

export const allIds = (state = [], action) => {
  const { type, payload } = action
  switch (type) {
    case FHIR_RESOURCES_RECEIVED:
    case CLINICAL_IMPRESSIONS_RECEIVED:
      return appendAllUniqueIds(state, payload.ClinicalImpression)
    case CLINICAL_IMPRESSION_RECEIVED:
      return appendAllUniqueIds(state, payload.clinicalImpression)
    default:
      return state
  }
}

export const byId = (state = {}, action) => {
  const { payload, type } = action
  switch (type) {
    case CLINICAL_IMPRESSION_RECEIVED:
      return {
        ...state,
        [payload.clinicalImpression.id]: payload.clinicalImpression,
      }
    case FHIR_RESOURCES_RECEIVED:
    case CLINICAL_IMPRESSIONS_RECEIVED:
      return reduceById(state, payload.ClinicalImpression)
    default:
      return state
  }
}

const loading = (state = false, action) => false

export default combineReducers({
  allIds,
  byId,
  loading,
})
