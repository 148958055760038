import { QueryFunction, useQuery } from 'react-query'
import { getTokenFromAmplify } from '../Auth/utils'
import {
  Appointment,
  Bundle,
  PaymentNotice,
  Practitioner,
  Patient
} from 'fhir-stu3'
import { SetupIntent } from '@stripe/stripe-js'
import { API, Auth } from 'aws-amplify'
import { CheckoutDetails, CheckoutDetailsByAppointmentId } from './types'
import { useStripe } from '@stripe/react-stripe-js'
import { useEffect, useState } from 'react'
import { FHIR_BASE_URL } from '../_common/config'
import { CheckoutBody } from '../_common/Request/types/booking'
import { MEDEO_CARE_STRIPE_CAPTURE_AMOUNT } from '../_common/constants'


type PaymentSecretByAppointmentIdKey = [
  'PaymentIntent',
  'secrets',
  { appointmentId: string; isLoading: boolean }
]

const getCheckoutDetailsByAppointmentId: QueryFunction<
  CheckoutDetails,
  CheckoutDetailsByAppointmentId
> = async ({ queryKey }) => {
  const [, , { appointmentId }] = queryKey
  const url = new URL('Appointment', FHIR_BASE_URL)
  if (appointmentId == null) { throw new Error('appointmentId should be defined') }
  url.searchParams.append('_id', appointmentId)
  url.searchParams.append('_revinclude', '*')
  url.searchParams.append('_include', 'Appointment:actor')

  const token = await getTokenFromAmplify()
  const res = await fetch(url.toString(), {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  })
  if (res.status >= 400) {
    const message = await res.text()
    throw new Error(message)
  }
  const bundle: Bundle = await res.json()

  const appointment = bundle.entry?.find(e => e?.search?.mode === 'match')
    ?.resource as Appointment

  const patient = bundle.entry?.find(
    e => e?.resource?.resourceType === 'Patient'
  )?.resource as Patient

  const performerActor = appointment.participant.find(p =>
    p.type?.some(t => t.coding?.some(c => c.code === 'practitioner'))
  )?.actor
  if (performerActor == null) { throw new Error('performer is not defined') }

  const reference = performerActor.reference?.split('/')[1]

  const performer = bundle.entry?.find(e => e.resource?.id === reference)
    ?.resource as Practitioner

  const paymentNotice = bundle.entry?.find(
    e => e.resource?.resourceType === 'PaymentNotice'
  )?.resource as PaymentNotice

  const details: CheckoutDetails = {
    appointment,
    performer,
    patient
  }
  if (paymentNotice != null) { details.paymentNotice = paymentNotice }

  return details
}

const getPaymentSecret: QueryFunction<
  string,
  PaymentSecretByAppointmentIdKey
> = async ({ queryKey }) => {
  try {
    const [, , { appointmentId }] = queryKey
    const body: CheckoutBody = {
      amount: MEDEO_CARE_STRIPE_CAPTURE_AMOUNT * 100
    }
    const { paymentIntent }: { paymentIntent: SetupIntent } = await API.post(
      'booking',
      '/booking/checkout/' + appointmentId,
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getAccessToken()
            .getJwtToken()}`
        },
        body: body,
      }
    )

    const clientSecret = paymentIntent.client_secret
    if (clientSecret == null) { throw new Error('client secret is null') }
    return clientSecret
  } catch (e) {
    throw new Error('Error while creating payment-intent')
  }
}

export const useCheckoutDetails = (appointmentId: string) => {
  return useQuery(
    ['Checkout', 'details', { appointmentId }],
    getCheckoutDetailsByAppointmentId,
    {
      //don't fetch another query for the next 2min
      staleTime: 120000
    }
  )
}
export const useRetrievePaymentStatus = (appointmentId: string) => {
  const stripe = useStripe()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isError, setIsError] = useState<boolean>(false)
  const [status, setStatus] = useState<SetupIntent.Status | null>(null)
  const { data: secret } = usePaymentSecret(appointmentId)

  useEffect(() => {
    if (!stripe) { return }
    if (!secret) { return }
    setIsLoading(true)

    stripe.retrieveSetupIntent(secret).then(({ setupIntent }) => {
      setIsLoading(false)
      setStatus(setupIntent?.status ?? null)
      if (setupIntent?.status === 'succeeded') {
        return
      }
      else if (setupIntent?.status != null) {
        setStatus(setupIntent?.status)
        setIsError(true)
      } else {
        throw new Error('status is null')
      }
    })
  }, [stripe, secret])
  return { status, isLoading, isError }
}

/**
 * This hook calls the stripe lambda responsible for setting a paymentIntent
 * the lambda will return a paymentIntent with a secret
 * This secret is later used by stripe elements to display the correct form
 * 3D Secure requires a redirect after validation
 * In this case, the secret can be passed as url search params
 * If we detect this client secret we prevent the call for the lambda
 *
 * NOTE: the lambda expects the appointmentId which is part of the url
 */
export const usePaymentSecret = (appointmentId: string) => {
  const { isLoading, data } = useCheckoutDetails(appointmentId)
  const paymentNotice = data?.paymentNotice
  let clientSecret = paymentNotice?.identifier?.find(
    i => i.system === 'stripe-setup-client-secret'
  )?.value

  // see https://github.com/TanStack/query/discussions/1318
  // isLoading is in the key to prevent initialData from being read beforehand
  return useQuery(
    ['PaymentIntent', 'secrets', { appointmentId, isLoading }],
    getPaymentSecret,
    {
      refetchOnWindowFocus: false,
      // if the client secret is present in the url, populates the state
      // NOTE: undefined is important here, null is considered as valid data
      initialData: () => clientSecret ?? undefined,
      //don't fetch another query
      staleTime: Infinity,
      enabled: !isLoading
    }
  )
}
