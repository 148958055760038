export const createLocalReference = (resourceType, id) =>
  `${resourceType}/${id}`

export const getTypeAndIdFromLocalReference = reference => {
  const [type, id] = reference?.split('/') ?? [null, null]
  return [type, id]
}

/**
 * retrieve interpretation codings from an observation, it also looks through the component of this given observation
 * @param observation
 * @returns { { coding: { code: {string} }}[]}
 */
export const getInterpretationCodingsFromObservation = observation => {
  if (
    observation.code &&
    observation.code.coding &&
    observation.code.coding[0] &&
    observation.code.coding[0].code &&
    observation.code.coding[0].code === '85354-9'
  ) {
    return [observation, observation.component]
      .flat()
      .filter(
        c =>
          c != null &&
          c.interpretation != null &&
          c.interpretation.coding != null &&
          c.interpretation.coding.length > 0
      )
      .flatMap(c => c.interpretation.coding)
  } else {
    return [observation]
      .flat()
      .filter(
        c =>
          c != null &&
          c.interpretation != null &&
          c.interpretation.coding != null &&
          c.interpretation.coding.length > 0
      )
      .flatMap(c => c.interpretation.coding)
  }
}
