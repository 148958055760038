import { FHIR_BASE_URL } from '../../_common/config'

// Returns the ID of the Binary file from a DocumentReference URL
export const getBinaryIdFromReferenceUrl = url =>
  url.replace(/^urn:uuid:/gi, '')

// Download a binary file and return the JSON. We need to use fetch here
// as Content-Type headings might be incorrectly used in FHIR.
export const getBinary = async (id, token) => {
  const resp = await fetch(`${FHIR_BASE_URL.href}Binary/${id}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  return resp.json()
}
