import { combineReducers, Reducer } from 'redux'
import { ORGANIZATION_RECEIVED, ORGANIZATIONS_RECEIVED } from './actions'
import { appendAllUniqueIds, reduceById } from '../Shared/ducks'
import { FHIR_RESOURCES_RECEIVED } from '../Shared/actions'
import { ById } from '../Shared/ducks/types'
import { Organization } from 'fhir-stu3'

export const allIds: Reducer<string[]> = (state = [], action) => {
  const { type, payload } = action
  switch (type) {
    case FHIR_RESOURCES_RECEIVED:
    case ORGANIZATIONS_RECEIVED:
      return appendAllUniqueIds(state, payload.Organization)
    case ORGANIZATION_RECEIVED:
      return appendAllUniqueIds(state, payload.organization)
    default:
      return state
  }
}

export const byId: Reducer<ById<Organization>> = (state = {}, action) => {
  const { type, payload } = action
  switch (type) {
    case FHIR_RESOURCES_RECEIVED:
    case ORGANIZATIONS_RECEIVED:
      return reduceById(state, payload.Organization)
    case ORGANIZATION_RECEIVED:
      return { ...state, [payload.organization.id]: payload.organization }
    default:
      return state
  }
}

export default combineReducers({
  allIds,
  byId
})
