import { useState, useEffect } from 'react'
import { Graph } from '@dagrejs/graphlib'
import * as R from 'ramda'

import { v4 as uuid } from 'uuid'

const addBuiltModelsToGraph = (resources, graph, rootNode) =>
  resources.reduce((graph, resource) => {
    const thisNodeName = uuid()
    graph.setNode(thisNodeName, R.omit(['then'], resource))
    graph.setEdge(rootNode, thisNodeName)
    return addBuiltModelsToGraph(resource.then || [], graph, thisNodeName)
  }, graph)

const useGraph = form => {
  const [graph] = useState(new Graph())
  useEffect(() => {
    if (form == null) {return}
    graph.setNode('$root')
    addBuiltModelsToGraph(form.resources, graph, '$root')
  }, [form, graph])
  return graph
}

export default useGraph
