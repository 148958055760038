import React from 'react';
import SignInKligo from './SignInKligo';
import SignInMedeo from './SignInMedeo';
import SignInMedeoAndKligo from './SignInMedeoAndKligo';

const CustomSignIn = ({ project, ...props }) => {
  if (props.authState !== 'signIn') {return null;}

  if (project === 'Kligo') {
    return <SignInKligo />;
  } else if (project === 'Medeo and Kligo') {
    return <SignInMedeoAndKligo />;
  } else {
    return <SignInMedeo />;
  }
};

export default CustomSignIn;
