import { saveAs } from 'file-saver'
import mime from 'mime-types'
import * as R from 'ramda'

/**
 * Download a base64-string as a binary file.
 */
export const downloadBase64 = async (fileName, data, contentType) => {
  const blob = await (await fetch(`data:${contentType};base64,${data}`)).blob()
  const extension = mime.extension(contentType) || ''
  const fullFileName = R.endsWith(`.${extension}`, fileName)
    ? fileName
    : `${fileName}.${extension}`
  saveAs(blob, fullFileName)
}
