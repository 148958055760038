import {
  faDownload,
  faGlobe,
  faPhoneAlt,
  faStethoscope,
  faTasks,
  faWifi
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Trans } from '@lingui/macro'
import jwt from 'jsonwebtoken'
import { useEffectOnce } from 'react-use'
import styled from 'styled-components/macro'
import { Card, Label } from '../../Components'
import logo from '../../img/Kligo-Logo@2x.png'
import Intercom from '../../Shared/intercom/Intercom'

const Body = styled.div`
  display: flex;
  justify-content: center;
`

const Container = styled.div`
  width: 35.13rem;
  height: 100vh;
  padding: 4rem 0;
  @media (max-width: 768px) {
    overflow: scroll;
  }
`

const KligoLogo = styled.img`
  display: block;
  position: fixed;
  width: 3rem;
  top: 1rem;
  left: 1rem;
  @media (max-width: 768px) {
    width: 1.5rem;
    top: 0.5rem;
    left: 0.5rem;
  }
`

const CustomLabel = styled(Label)`
  color: ${(p) => p.theme.black};
  margin-left: 0.5rem;
  font-size: 1rem;
  @media (max-width: 768px) {
    font-size: 1rem;
    text-align: center;
    margin: 2rem auto;
    width: 300px;
  }
`

const Header = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 2rem 0 2rem;
  font-size: 1.5rem;
  text-align: center;
  color: ${(p) => p.theme.black};
  @media (max-width: 768px) {
    font-size: 1.33rem;
    width: 300px;
    margin: 2rem auto;
  }
`

const CustomCard = styled(Card)`
  height: 8.88rem;
  width: 16.5rem;
  background: #fff;
  margin: 0.5rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 1.5rem;
  border: 1px solid transparent;
  &:hover {
    border-color: ${(p) => p.theme.ocean};
  }
`

const CustomCardIcon = styled(FontAwesomeIcon)`
  font-size: 2.125rem;
  color: ${(p) => p.theme.ocean};
`

const CardText = styled.div`
  color: ${(p) => p.theme.black};
  font-size: 1rem;
  text-align: center;
`

const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  @media (max-width: 768px) {
    flex-wrap: nowrap;
    flex-direction: column;
    align-items: center;
  }
`

const ContactGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 768px) {
    flex-wrap: nowrap;
    flex-direction: column;
    align-items: center;
  }
`

const CustomRoundedIcon = styled(FontAwesomeIcon)`
  padding: 0.8rem;
  background: ${(p) => p.theme.white};
  border-radius: 50%;
  margin: 0.5rem;
  font-size: 2.6rem;
  color: ${(p) => p.theme.ocean};
`

const ContactContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 50%;
  @media (max-width: 768px) {
    flex-wrap: nowrap;
    flex-direction: column;
    margin: 1rem;
    text-align: center;
  }
`

const CustomLink = styled.a`
  text-decoration: none;
  display: block;
  color: ${(p) => p.theme.ocean};
  &:visited {
    color: ${(p) => p.theme.black};
  }
`

const ContactItem = styled.div`
  font-size: 0.9rem;
`
const Main = styled.main`
  padding-top: 3rem;
`
const Footer = styled.footer`
  padding: 2.5rem 0 3rem 0;
`

const SupportPage = (props) => {
  const token = new URLSearchParams(props.location.search).get('token')
  const data = jwt.verify(token, 'secret')

  // Opens intercom when opening the support page
  useEffectOnce(() => {
    if (window.Intercom) {
      window.Intercom('show')
    }
  })

  return (
    <Body>
      <KligoLogo src={logo} />
      <Container>
        <Header>
          <Trans>Welcome to the Kligo Help Center</Trans>
        </Header>
        <Main>
          <CustomLabel>
            <Trans>Choose the type of help you need :</Trans>
          </CustomLabel>
          <Grid>
            <CustomLink
              href="https://help.medeo.care/fr/articles/3782743-installer-kligo-sur-votre-ordinateur"
              target="_blank"
              rel="noopener noreferrer"
            >
              <CustomCard>
                <CustomCardIcon icon={faDownload} />
                <CardText>
                  <Trans>Install Kligo</Trans>
                </CardText>
              </CustomCard>
            </CustomLink>

            <CustomLink
              href="https://help.medeo.care/fr/collections/2141502-guide-de-demarrage-de-kligo"
              target="_blank"
              rel="noopener noreferrer"
            >
              <CustomCard>
                <CustomCardIcon icon={faTasks} />
                <CardText>
                  <Trans>My first steps in Kligo</Trans>
                </CardText>
              </CustomCard>
            </CustomLink>

            <CustomLink
              href="https://help.medeo.care/fr/articles/4185957-recevoir-les-mesures-des-dispositifs-connectes"
              target="_blank"
              rel="noopener noreferrer"
            >
              <CustomCard>
                <CustomCardIcon icon={faWifi} />
                <CardText>
                  <Trans>Receipt of data</Trans>
                </CardText>
              </CustomCard>
            </CustomLink>

            <CustomLink
              href="https://help.medeo.care/fr/collections/2273284-les-dispositifs-medicaux-connectes"
              target="_blank"
              rel="noopener noreferrer"
            >
              <CustomCard>
                <CustomCardIcon icon={faStethoscope} />
                <CardText>
                  <Trans>Connected medical devices</Trans>
                </CardText>
              </CustomCard>
            </CustomLink>
          </Grid>
          <Intercom fromKligo={data.intercomBody} />
        </Main>
        <Footer>
          <CustomLabel>
            <Trans>
              If you cannot find the answer to your question, contact us !
            </Trans>
          </CustomLabel>
          <ContactGrid>
            <ContactContainer>
              <CustomRoundedIcon icon={faPhoneAlt} />
              <ContactItem>
                <Trans>By telephone :</Trans>
                <CustomLink
                  href="tel:0805692233"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  0 805 692 233
                </CustomLink>
              </ContactItem>
            </ContactContainer>

            <ContactContainer>
              <CustomRoundedIcon icon={faGlobe} />
              <ContactItem>
                <Trans>By e-mail :</Trans>
                <CustomLink
                  href="mailto:assistance@medeo-health.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  assistance@medeo-health.com
                </CustomLink>
              </ContactItem>
            </ContactContainer>
          </ContactGrid>
        </Footer>
      </Container>
    </Body>
  )
}

export default SupportPage
