import {
  STARTING_CAMERA,
  PICTURE_TAKEN,
  UPLOADING_PICTURE,
  DELETING_PICTURE,
  RESET,
} from './actions'

export const init = () => {
  return {
    isConverted: false,
    isPlaying: false,
    data: null,
    id: null,
    shouldDisplayFeedBack: false,
    isLoading: false,
    file: null,
    message: 'prendre la photo',
  }
}

export const byMessage = (state, action) => {
  switch (action.type) {
    case RESET:
      return init()
    case STARTING_CAMERA:
      return {
        ...state,
        data: null,
        isPlaying: true,
      }
    case PICTURE_TAKEN:
      return {
        ...state,
        data: action.payload.data,
        message: 'reprendre',
        isPlaying: false,
      }
    case UPLOADING_PICTURE:
      return {
        ...state,
        file: action.payload.file,
        isLoading: true,
        shouldDisplayFeedback: true,
        message: 'waiting',
      }
    case DELETING_PICTURE:
      return {
        ...state,
        data: null,
        file: null,
        isLoading: false,
        message: 'prendre la photo',
      }
    default:
      return state
  }
}
