const MEDEO_CIS_SYSTEM = 'http://medeo.io/fhir/ValueSet/cis'

const AWS_ES_ENDPOINT =
  'https://search-medeo-elasticsearch-whxkhi7oxdmphqgi26dhwq72bi.eu-west-1.es.amazonaws.com'

/**
 * Retrieves the hits from the AWS Elastic Search Medication Index matching the query passed
 * as a param and transforms it to a FHIR Medication.
 *
 * the hit response from the AWS Elastic search medication index looks like this:
 * {
 *   CIS: 12345678,
 *   name: "Medicament Trop Bien"
 *   formDesc: "pillule"
 * }
 *
 * and it it transformed to a FHIR Medication:
 *
 * {
 *  {
 *    type: 'Medication',
 *    code: {
 *        coding: [
 *          {
 *            system: 'https://medeo.io/fhir/ValueSet/cis',
 *            code: item.CIS,
 *            display: item.name,
 *          },
 *        ],
 *      },
 *      form: {
 *        coding: [
 *          {
 *            display: item.formDesc,
 *          },
 *        ],
 *      },
 *    }
 * }
 *
 * @param query
 * @returns {Promise<*>}
 */

export const queryMedicationFromES = async query => {
  // use the url object to construct and encoded properly the query
  // whitespaces were truncated with the previous method
  // it relied on query.replace(/\W/g, '')
  // searchParams makes sure the query is correctly encoded
  // see https://developer.mozilla.org/fr/docs/Web/API/URLSearchParams
  const url = new URL('medication_3/_search', AWS_ES_ENDPOINT)
  url.searchParams.append('q', query)
  // 👇 also increased the number of hits we fetch
  // it might not be necessary but there is no performance drop related
  // so why not fetch more 🤗
  url.searchParams.append('size', '40')

  // fetch is surrounded with a try/catch block to prevent any failure from breaking the app
  // if we fail we do it silently for now.
  try {
    const response = await fetch(url.toString())
    const json = await response.json()
    return json.hits.hits
      .map(i => i._source)
      .map(item => ({
        resourceType: 'Medication',
        code: {
          coding: [
            {
              system: MEDEO_CIS_SYSTEM,
              code: item.CIS,
              display: item.name,
            },
          ],
        },
        form: {
          coding: [
            {
              display: item.formDesc,
            },
          ],
        },
      }))
  } catch {
    console.warn('something went wrong with medication ES')
    // return an empty list of hits to prevent any whitescreen
    return []
  }
}

/**
 * this method is used to extract a string to represent the medication
 * passed as a param. It is used  in tags where we cannot show structured info
 * but only a string.
 * @param medication the Medication resource to extract the string
 * @returns {*}
 */
export const displayMedication = medication => {
  return medication?.code?.coding?.find(coding => coding.display)?.display
}
