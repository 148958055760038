import React, { useState } from 'react'
import { Trans } from '@lingui/macro'
import { useDispatch, useSelector } from 'react-redux'
import { isSignedIn } from '../selectors'
import { Auth } from 'aws-amplify'
import { Authenticator } from 'aws-amplify-react'
import { succeedToReceiveAccessToken } from '../actions'
import CustomSignIn from './CustomSignInForm'
import { Card } from '../../Components'
import Container from './Container'
import styled from 'styled-components/macro'
import { signOut } from '../actions'
import Link from '../../Shared/components/Link'
import { ReactComponent as Logotype } from '../../logo.svg'

const Header = styled.header`
  display: flex;
  justify-content: center;
  & > svg {
    height: 5rem;
  }
  margin-bottom: 2.5rem;
`

// Get the aws resources configuration parameters
const RequireSignIn = ({ children }) => {
  // The Authenticator component contains only the signIn form
  // the password recovery and the signup forms are located in other routes.
  const isUserSignedIn = useSelector(isSignedIn)
  const dispatch = useDispatch()
  const [userAuthorized, setUserAuthorized] = useState(undefined)
  return isUserSignedIn === true ? (
    children
  ) : (
    <Container>
      <Card>
        <Header>
          <Logotype />
        </Header>

        <Authenticator
          hideDefault={true}
          onStateChange={authState => {
            if (authState === 'signedIn') {
              Promise.all([
                Auth.currentSession(),
                Auth.currentAuthenticatedUser()
              ])
                .then(([session, user]) => {
                  let cognitoGroups = []
                  if (
                    session &&
                    session.accessToken &&
                    session.accessToken.payload &&
                    session.accessToken.payload['cognito:groups']
                  ) {
                    cognitoGroups =
                      session.accessToken.payload['cognito:groups']
                  }
                  const userAuthorization = cognitoGroups.some(
                    p => p === 'Medeo'
                  )
                  setUserAuthorized(userAuthorization)
                  if (userAuthorization) {
                    // we should pass the user attribute, more info on the sagas
                    dispatch(
                      succeedToReceiveAccessToken(
                        session.accessToken.getJwtToken(),
                        user.attributes,
                        cognitoGroups
                      )
                    )
                  } else if (!userAuthorization) {
                    dispatch(signOut())
                  }
                })
                .catch(err => console.warn(err))
            }
          }}
        >
          <CustomSignIn userAuthorized={userAuthorized} />
        </Authenticator>
        <Link to="/recover">
          <Trans>Forgot password ?</Trans>
        </Link>
      </Card>
    </Container>
  )
}

export default RequireSignIn
