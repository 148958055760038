import { Button, Card, TextArea } from '../../Components'
import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link, useNavigate } from '@reach/router'
import { Trans } from '@lingui/macro'
import { useDispatch, useSelector } from 'react-redux'
import React, { useEffect, useReducer, useState } from 'react'
import styled from 'styled-components/macro'
import { deselectEncounter, stopEncounter, updateEncounter } from '../actions'
import { filterOutEnteredInErrorEncounter } from '../selectors'
import { getDocumentReferencesFromencounterID } from '../../DocumentReference/selectors'
import { getObservationsFromCurrentEncounter } from '../../Observation/selectors'
import ManualObservationFormDialog from '../../Observation/components/ManualObservationFormDialog'
import {
  saveObservation,
  searchSpecificObservation
} from '../../Observation/actions'
import DefaultEncounterForm from '../components/DefaultEncounterForm'
import {
  getItemByLinkId,
  getQuestionNumberFromQuestionnaireByName
} from '../../Questionnaire/utils'
import {
  CODE_DYSPNEE,
  LOINC_BLOOD_PRESSURE_CODE,
  LOINC_BODY_TEMPERATURE_CODE,
  LOINC_HEART_RATE_CODE,
  LOINC_OXYGEN_SATURATION_CODE,
  LOINC_RESPIRATORY_RATE_CODE
} from '../../utils/loinc'
import { getAllQuestionnairesByIdentifierAndSystem } from '../../Questionnaire/selector'
import {
  PRECONSULTATION_QUESTIONNAIRE_SYSTEM,
  SYMPTOMS_AND_MOTIVES_ACTION_VALUE
} from '../../PlanDefinition/utils'
import AddAttachmentsForm from '../components/AddAttachmentsForm'
import { MOTIVE_QUESTION_IN_MOTIVES_QUESTIONNAIRE } from '../../utils/codes'
import mixpanel, { MIXPANEL_PHYSICAL_EXAM } from '../../Shared/mixpanel'
import usePatient from '../../Patient/usePatient'
import usePractitioner from '../../Practitioner/usePractitioner'
import usePlanDefinition from '../../PlanDefinition/usePlanDefinition'

const CardContent = styled.div`
  & > h2 {
    font-size: ${p => p.theme.medium};
    margin-bottom: 1rem;
  }

  & > span {
    font-size: ${p => p.theme.small};
  }
  margin-bottom: ${p => p.theme.spacing.large};
`

const CardFooter = styled.div`
  display: flex;
  width: 100%;
  padding-top: 2rem;
  border-top: 1px solid black;
  justify-content: flex-end;
  align-items: center;
`

const ContainerForButtons = styled.div`
  margin: ${props => props.theme.spacing.large} 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  & > :not(:last-child) {
    margin-bottom: 1rem;
  }
`

const RowContainerButtons = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const ContainerBtn = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const Head = styled(Button)`
  float: right;
`

const TextBox = styled.div`
  width: 100%;
`

const SubHeader = styled.div`
  margin-bottom: 2rem;
`

const initalState = {
  nextButtonIsDisabled: true,
  canAddComment: false,
  comment: null,
  observations: [],
  documentReferences: []
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'noPerformerSelected':
      return {
        ...state,
        nextButtonIsDisabled: true
      }
    case 'changeComment':
      return {
        ...state,
        nextButtonIsDisabled: false,
        comment: action.payload
      }
    case 'changeObservations':
      return {
        ...state,
        nextButtonIsDisabled: false,
        observations: action.payload
      }
    case 'changeDocumentReferences':
      return {
        ...state,
        nextButtonIsDisabled: false,
        documentReferences: action.payload
      }
    case 'removeComment':
      return {
        ...state,
        canAddComment: false,
        comment: null
      }
    case 'addComment':
      return {
        ...state,
        canAddComment: true
      }
    default:
      return state
  }
}

const NewEncounterCard = ({ isFromTeleconsultation, encounter }) => {
  const patient = usePatient()
  const currentPractitioner = usePractitioner()
  const { planDefinition, goBack, goNext } = usePlanDefinition()
  const [state, dispatch] = useReducer(reducer, initalState)
  const reduxDispatch = useDispatch()

  const currentencounterID = encounter != null ? encounter.id : null

  // the two following selectors + useEffect bind redux observations and
  // documentsRef to the internal state of the component
  const observations = useSelector(s =>
    getObservationsFromCurrentEncounter(s, [filterOutEnteredInErrorEncounter])
  )
  const documentReferences = useSelector(s =>
    getDocumentReferencesFromencounterID(s, currentencounterID)
  )
  useEffect(() => {
    if (observations.length !== state.observations.length)
      {dispatch({ type: 'changeObservations', payload: observations })}
  }, [dispatch, observations, state.observations.length])
  useEffect(() => {
    if (documentReferences.length !== state.documentReferences.length)
      {dispatch({
        type: 'changeDocumentReferences',
        payload: documentReferences
      })}
  }, [dispatch, documentReferences, state])

  // the next useState, useSelector and useEffect set the measures that have
  // to be taken by the practitioner in the clinical exam
  const [
    preexistingObservationTypes,
    setPreexistingObservationTypes
  ] = useState([])

  const [preTeleconsultationQuestionnaire] = useSelector(s =>
    getAllQuestionnairesByIdentifierAndSystem(
      s,
      SYMPTOMS_AND_MOTIVES_ACTION_VALUE,
      PRECONSULTATION_QUESTIONNAIRE_SYSTEM
    )
  )

  const preTeleconsultationQuestionnaireId =
    preTeleconsultationQuestionnaire?.id
  let encounterSequenceResponse = null
  if (planDefinition)
    {encounterSequenceResponse = planDefinition.sequenceResponse}
  useEffect(() => {
    if (encounterSequenceResponse) {
      // for covid-19 reason
      const motivesAndSymptomsQuestionnaireResponse = encounterSequenceResponse.find(
        sr => sr.questionnaireId === preTeleconsultationQuestionnaireId
      )
      if (motivesAndSymptomsQuestionnaireResponse) {
        const motiveInput = getItemByLinkId(
          motivesAndSymptomsQuestionnaireResponse.answer,
          getQuestionNumberFromQuestionnaireByName(
            MOTIVE_QUESTION_IN_MOTIVES_QUESTIONNAIRE
          )
        )
        // now select input is saved in valueCoding instead of valueString
        const answer =
          motiveInput?.answer?.[0]?.valueString ??
          motiveInput?.answer?.[0]?.valueCoding?.code
        if (answer === 'depistage-covid-19' || answer === 'suivi-covid-19') {
          setPreexistingObservationTypes([
            LOINC_BODY_TEMPERATURE_CODE,
            LOINC_BLOOD_PRESSURE_CODE,
            LOINC_HEART_RATE_CODE,
            LOINC_RESPIRATORY_RATE_CODE,
            LOINC_OXYGEN_SATURATION_CODE,
            CODE_DYSPNEE
          ])
        }
      }
    }
  }, [
    setPreexistingObservationTypes,
    encounterSequenceResponse,
    preTeleconsultationQuestionnaireId
  ])

  const handleRemoveEncounter = () => {
    reduxDispatch(deselectEncounter())
    reduxDispatch(
      updateEncounter({
        ...encounter,
        status: 'entered-in-error'
      })
    )
  }

  const navigate = useNavigate()

  // put the button on disabled if needed
  const handleStopEncounter = () => {
    // comment envoyer seulement true ou false et pas la donnée ?
    mixpanel.track(MIXPANEL_PHYSICAL_EXAM, {
      from: 'details',
      // it can be from preconsultation or details
      hasComment: state.comment !== null,
      hasObservations: state.observations?.length !== 0
    })
    if (state.comment != null) {
      reduxDispatch(
        saveObservation({
          comment: state.comment,
          effectiveDateTime: new Date(),
          patientID: patient.id,
          practitioner: `Practitioner/${currentPractitioner.id}`
        })
      )
    }
    reduxDispatch(stopEncounter(encounter.id, patient.id, null))
    reduxDispatch(
      searchSpecificObservation({
        patientID: patient.id,
        specificCode: '8664-5'
      })
    )
    navigate('./')
  }

  const handleAddComment = () => {
    dispatch({ type: 'addComment' })
  }
  const handleRemoveComment = () =>
    dispatch({
      type: 'removeComment'
    })
  const handleChange = e =>
    dispatch({ type: 'changeComment', payload: e.target.value })
  // When the user edit something on this form the variable isFilled is set to true,
  // The user can then save the form
  return (
    patient != null &&
    encounter != null && (
      <Card>
        <Card.Header>
          {isFromTeleconsultation ? (
            <h1>Questionnaire préconsultation</h1>
          ) : (
            <h1>
              <Trans>Today's Measurements</Trans>
            </h1>
          )}
        </Card.Header>
        <CardContent>
          <h2>
            <Trans>Physical exam</Trans>
          </h2>
          <SubHeader>
            <Trans>
              Any data collected from connected devices will automatically
              appear here
            </Trans>
          </SubHeader>
          <DefaultEncounterForm
            encounter={encounter}
            current
            patientID={patient.id}
            readOnly={false}
            preexistingObservationTypes={preexistingObservationTypes}
            observations={observations}
          />
          <ContainerForButtons>
            <ManualObservationFormDialog patientID={patient.id} />
            {state.canAddComment ? (
              <TextBox>
                <Head
                  color="white"
                  variant="tertiary"
                  onClick={handleRemoveComment}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </Head>
                <TextArea label="Commentaire" onChange={handleChange} />
              </TextBox>
            ) : (
              <Button
                color="ocean"
                variant="tertiary"
                onClick={handleAddComment}
              >
                <FontAwesomeIcon icon={faPlus} />
                &nbsp;
                <Trans>Add a comment</Trans>
              </Button>
            )}
            <AddAttachmentsForm encounter={encounter} patient={patient} />
          </ContainerForButtons>
        </CardContent>

        {isFromTeleconsultation ? (
          <ContainerBtn>
            <Button onClick={goBack} color="ocean" variant="outline">
              <Trans>Previous</Trans>
            </Button>
            <Button
              onClick={() => {
                if (state.comment != null) {
                  reduxDispatch(
                    saveObservation({
                      comment: state.comment,
                      effectiveDateTime: new Date(),
                      patientID: patient.id,
                      practitioner: `Practitioner/${currentPractitioner.id}`
                    })
                  )
                }
                // comment envoyer seulement true ou false et pas la donnée ?
                mixpanel.track(MIXPANEL_PHYSICAL_EXAM, {
                  from: 'preconsultation',
                  // it can be from preconsultation or details
                  hasComment: state.comment != null,
                  hasObservations: state.observations?.length !== 0
                })
                goNext()
              }}
              color="ocean"
            >
              <Trans>Next</Trans>
            </Button>
          </ContainerBtn>
        ) : (
          <CardFooter>
            <RowContainerButtons>
              <Link to="../history" onClick={() => handleRemoveEncounter()}>
                <Button variant="outline" color="ocean">
                  <Trans>Cancel</Trans>
                </Button>
              </Link>

              {(observations.length !== 0 ||
                documentReferences.length !== 0 ||
                (state.canAddComment && state.comment !== null)) && (
                <Button color="ocean" onClick={handleStopEncounter}>
                  <Trans>Finish the examination</Trans>
                </Button>
              )}
            </RowContainerButtons>
          </CardFooter>
        )}
      </Card>
    )
  )
}
export default NewEncounterCard
