import React, { useContext } from 'react'
import styled from 'styled-components/macro'
import {
  faMicrophone,
  faMicrophoneSlash,
  faPhoneSlash,
  faVideo,
  faVideoSlash
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from '../../Components'
import { VideoScreenContext } from '../containers/VideoSequenceManager'
import {
  handleDisconnect,
  handleStartAudio,
  handleStartVideo,
  handleStopAudio,
  handleStopVideo
} from '../utils'
import { I18n } from '@lingui/react'
import { t } from '@lingui/macro'
import { useLocation, useParams } from '@reach/router'
import MediaDevicesModal from './MediaDevicesModal'
import { IPM_KIOSK_ORIGIN, KIOSK_ORIGIN } from '../../utils/codes'

const FlexContainer = styled.div`
  // max-width: 1024px;
  height: 60px;
  justify-content: space-between;
  align-items: center;
  display: flex;
  flex: 1;
`
const FullWidthContainer = styled.div`
  width: 100%;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
`

const Left = styled.div`
  display: flex;
  justify-content: flex-start;
  flex: auto;
  align-items: center;
  margin-right: ${p => p.theme.medium};
`

const Center = styled.div`
  display: flex;
  justify-content: center;
  flex: 0;
  margin-left: ${p => p.theme.medium};
  margin-right: ${p => p.theme.medium};
`

const Right = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-left: ${p => p.theme.medium};
  flex: auto;
`

const Div = styled.div`
  margin-right: 1rem;
`

const CommandPanel = ({ localMediaContainer }) => {
  const location = useLocation()
  const query = new URLSearchParams(location.search)
  const origin = query.get('origin')
  const [videoState, dispatch] = useContext(VideoScreenContext)
  let doctor = ''
  let participants = null
  if (
    videoState.teleconsultationMaterial.room &&
    videoState.teleconsultationMaterial.room.participants
  ) {
    participants = videoState.teleconsultationMaterial.room.participants.values()
    // there will be always just one participant in this and it will be the other participant (not you)
    if (videoState.patientSide) {
      for (let participant of participants) {
        // Here we need to only keep the practitioner name, which is the second part of the identifier,
        // This one being of type 'id_name'
        const splittedIdentifier = participant.identity?.split('_')

        doctor = splittedIdentifier?.[1] ?? participant.identity
      }
    }
  }

  // this is required by mixpanel to identify the TLC we are looking at
  // see handleConnect/handleDisconnect for more details
  const { preconsultationId } = useParams()

  return (
    <FullWidthContainer>
      <FlexContainer>
        <Left>
          <div>
            <img
              alt="medeo"
              src="https://kligo.s3.eu-central-1.amazonaws.com/Medeo+-+Logo+-+White.jpg"
            />
          </div>
          {doctor !== '' && (
            <div>&nbsp;&nbsp;&nbsp;En ligne avec le Dr. {doctor}</div>
          )}
        </Left>
        <Center>
          {!videoState.isAudio && (
            <Div>
              <I18n>
                {({ i18n }) => (
                  <Button
                    color="scarlett"
                    variant="toggle-secondary"
                    data-tooltip={i18n._(t`Activate the microphone`)}
                    onClick={() => handleStartAudio({ videoState, dispatch })}
                  >
                    <FontAwesomeIcon icon={faMicrophoneSlash} />
                  </Button>
                )}
              </I18n>
            </Div>
          )}
          {videoState.isAudio &&
            videoState.teleconsultationMaterial.room != null && (
              <Div>
                <I18n>
                  {({ i18n }) => (
                    <Button
                      color="white"
                      variant="toggle-secondary"
                      data-tooltip={i18n._(t`Deactivate the microphone`)}
                      onClick={() => handleStopAudio({ videoState, dispatch })}
                    >
                      <FontAwesomeIcon icon={faMicrophone} />
                    </Button>
                  )}
                </I18n>
              </Div>
            )}

          <Div>
            <I18n>
              {({ i18n }) => (
                <Button
                  color="scarlett"
                  variant="toggle-secondary"
                  data-tooltip={i18n._(t`Close the teleconsultation`)}
                  onClick={() => {
                    handleDisconnect({
                      videoState,
                      dispatch,
                      encounterID: preconsultationId
                    })
                    // if the patient is on kiosk, the window should close at the end of the TLC
                    // The origin is get from the url params
                    if (
                      origin === KIOSK_ORIGIN ||
                      origin === IPM_KIOSK_ORIGIN
                    ) {
                      window.close()
                    }
                  }}
                >
                  <FontAwesomeIcon icon={faPhoneSlash} />
                </Button>
              )}
            </I18n>
          </Div>

          {!videoState.isVideo && (
            <I18n>
              {({ i18n }) => (
                <Button
                  color="scarlett"
                  variant="toggle-secondary"
                  data-tooltip={i18n._(t`Activate the camera`)}
                  onClick={() =>
                    handleStartVideo({
                      videoState,
                      dispatch,
                      localMediaContainer
                    })
                  }
                >
                  <FontAwesomeIcon icon={faVideoSlash} />
                </Button>
              )}
            </I18n>
          )}
          {videoState.isVideo &&
            videoState.teleconsultationMaterial.room != null && (
              <I18n>
                {({ i18n }) => (
                  <Button
                    color="white"
                    variant="toggle-secondary"
                    data-tooltip={i18n._(t`Deactivate the camera`)}
                    onClick={() => handleStopVideo({ videoState, dispatch })}
                  >
                    <FontAwesomeIcon icon={faVideo} />
                  </Button>
                )}
              </I18n>
            )}
        </Center>
        <Right patientSide={videoState.patientSide}>
          <MediaDevicesModal
            localMediaContainer={localMediaContainer}
            dispatch={dispatch}
            open={videoState.openModal}
          />
          {videoState.patientSide}
        </Right>
      </FlexContainer>
    </FullWidthContainer>
  )
}
export default CommandPanel
