import fuzzy from 'fuzzy'
import React, { useContext, useState } from 'react'
import MultipleSearchInput from '../../Shared/components/MultipleSearchInput'
import { onChangeQuestionnaire, getItemByLinkId } from '../utils'
import { QuestionnaireResponseContext } from './Questionnaire'
import InlineList from '../../Shared/components/InlineList'
import InlineListItem from '../../Shared/components/InlineListItem'
import { Trans } from '@lingui/macro'

const QuestionnaireDropdown = ({ item, readOnly }) => {
  const [questionnaireResponse, dispatchQuestionnaireResponse] = useContext(
    QuestionnaireResponseContext
  )
  const initialList = item.option.map(option => option.valueString)
  const [options, setOptions] = useState(initialList)
  const selectedValues =
    getItemByLinkId(questionnaireResponse, item.linkId)?.answer?.map?.(
      answer => answer.valueString
    ) ?? []

  const handleChange = e => {
    setOptions(initialList)
    const selectedOptions = e.target.selectedOptions
    onChangeQuestionnaire({
      questionnaireResponse: questionnaireResponse,
      dispatchQuestionnaireResponse: dispatchQuestionnaireResponse,
      name: item.linkId,
      value: selectedOptions,
      type: 'drop-down',
    })
  }

  return readOnly ? (
    <InlineList items={selectedValues} fallback={<Trans>No items found</Trans>}>
      {(value, i) => (
        <InlineListItem title={value} key={i}>
          {value}
        </InlineListItem>
      )}
    </InlineList>
  ) : (
    <MultipleSearchInput
      label={item.text}
      name={item.linkId}
      defaultDatalist={options}
      // add fuzzy search
      onInputChange={e => {
        setOptions(
          fuzzy.filter(e.target.value, options).map(item => item.original)
        )
      }}
      onChange={handleChange}
      defaultValue={selectedValues}
    />
  )
}

export default QuestionnaireDropdown
