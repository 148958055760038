import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLocationByOrganizationId } from '../Location/selectors';
import { search } from '../Shared/actions';
import { getCurrentOrganizationId } from '../Auth/selectors';
import moment from 'moment';
import {
  getDisplayFromParticipantCode,
  getReferenceFromParticipantCode
} from '../Appointment/utils';
import { getIdByReference } from '../Shared/utils';
import {
  ESANTE_OCCUPATION_SYSTEM,
  MEDEO_APPOINTMENT_IDENTIFIER_SYSTEM,
  MEDEO_ORGANIZATION_DOCTOR_OFFICE_VALUE
} from '../utils/codes';
import { GENERAL_PRACTITIONER_ESANTE } from '../Permissions/roles';
import usePractitioner from '../Practitioner/usePractitioner';

export const MEDEO_SCHEDULE_TYPE_SYSTEM = 'http://medeo.io/fhir/Schedule/type';
export const MEDEO_SCHEDULE_TYPE_TELECONSULTATION = 'remote-consultation';

// this function take a Schedule Resource and transforms it into rbc events.
export const transformScheduleResourceInEvent = schedules => {
  return schedules.map(schedule => {
    return {
      id: schedule.id,
      start: new Date(schedule.planningHorizon.start),
      end: new Date(schedule.planningHorizon.end),
      scheduleType: schedule.identifier.find(
        identifier => identifier.system === MEDEO_SCHEDULE_TYPE_SYSTEM
      )?.value
    };
  });
};

// this function takes an appointment Resource and transforms it into a rbc events
export const transformAppointmentResourceInEvent = (
  appointments,
  eventsArray
) => {
  if (appointments != null)
    {appointments.map(appointment => {
      const start = appointment?.start;
      const end = appointment?.end;
      const patientReference = getReferenceFromParticipantCode(
        appointment,
        'patient'
      );
      const locationReference = getReferenceFromParticipantCode(
        appointment,
        'location'
      );
      const locationId = getIdByReference(locationReference);
      const patientID = getIdByReference(patientReference);
      const patientDisplay = getDisplayFromParticipantCode(
        appointment,
        'patient'
      );

      const appointmentType = appointment.identifier?.find(
        i => i.system === MEDEO_APPOINTMENT_IDENTIFIER_SYSTEM
      )?.value;

      if (appointmentType === 'remote-consultation') {
        const [motive, autonomousType] = appointment.description?.split(
          '-'
        ) || [appointment.description, ''];
        eventsArray.push({
          start: new Date(start),
          end: new Date(end),
          title: patientDisplay,
          isAppointment: true,
          patientID,
          motive: motive,
          autonomousType: autonomousType,
          status: appointment.status,
          id: appointment.id,
          appointment: appointment, // fhir appointment used to cancel
          doctorId: getIdByReference(
            getReferenceFromParticipantCode(appointment, 'practitioner')
          ),
          locationId: locationId,
          type: appointmentType,
          isVaccination: false
        });
      } else {
        const slotId = getIdByReference(appointment.slot[0].reference);
        if (eventsArray.some(e => e.id === slotId)) {
          const event = eventsArray.find(e => e.id === slotId);
          event.appointments = event.appointments.concat(appointment.id);
          event.patients = event.patients.concat({
            name: patientDisplay,
            id: patientID
          });
        }
      }
      return 0;
    });}
  return eventsArray;
};

/**
 * Check if some events are contained within another one
 *
 * @param {*} event
 * @param {*} eventsToCheck: events we want to know whether they are occurring during the other one
 */
export const hasEventsWithin = (event, eventsToCheck) => {
  const containedEvents = eventsToCheck.filter(
    e =>
      moment(e.start) >= moment(event.start) &&
      moment(e.end) <= moment(event.end)
  );

  return containedEvents.length !== 0;
};

export const getPeriodFromView = (calendarDate, calendarView) => {
  let firstDay = moment(calendarDate)
    .startOf('day')
    .format('YYYY-MM-DD');
  let lastDay = moment(calendarDate)
    .endOf('day')
    .format('YYYY-MM-DD');
  if (calendarView === 'week') {
    firstDay = moment(calendarDate)
      .startOf('week')
      .format('YYYY-MM-DD');
    lastDay = moment(calendarDate)
      .endOf('week')
      .format('YYYY-MM-DD');
    //if we have month view in url
  } else if (calendarView === 'month') {
    firstDay = moment(calendarDate)
      .startOf('month')
      .format('YYYY-MM-DD');
    lastDay = moment(calendarDate)
      .endOf('month')
      .format('YYYY-MM-DD');
  }
  return { start: firstDay, end: lastDay };
};

/**
 * returns true if the event is overlapping  with the start and end date
 * passed along
 * @param start
 * @param end
 * @param event
 * @return {boolean}
 */
export const findCollision = (start, end, event) => {
  // eventA: [****]
  // eventB: 👉[****]
  const a = moment(start).isBetween(event.start, event.end, 'minutes', '[)');
  // eventA:    [****]
  // eventB: [****] 👈
  const b = moment(end).isBetween(event.start, event.end, 'minutes', '(]');
  // eventA: 👉 [****]
  // eventB: [****]
  const c = moment(event.start).isBetween(start, end, 'minutes', '[)');
  // eventA:  👉[****]
  // eventB: [****]
  const d = moment(event.end).isBetween(start, end, 'minutes', '(]');

  // with the 4 conditions we make sure there is no collision between 2 events
  // this works even for events comprised within another
  // eventA:  [**********]
  // eventB:     [****]

  return a || b || c || d;
};

/**
 * Filter out slots which are overlapping with an already booked appointment
 * @param {[Slot]} slots
 * @param {[Appointment]} appointments
 */
export const keepFreeSlots = (slots = [], appointments = []) => {
  return slots.filter(s => {
    return !appointments.some(a =>
      findCollision(a.start, a.end, { start: s.start, end: s.end })
    );
  });
};

export const getPractitionerType = practitionerRole => {
  return practitionerRole?.specialty.find(
    s => s.coding[0].system === ESANTE_OCCUPATION_SYSTEM
  )?.coding[0].code;
};

/**
 * Check whether a practitioner should be able to change their planning horizon
 * depending on their organization and practitioner types
 *
 * @param {String} organizationType
 * @param {String} practitionerType
 * @return boolean
 */
export const canModifyPlanningHorizon = (
  organizationType,
  practitionerType
) => {
  return (
    organizationType === MEDEO_ORGANIZATION_DOCTOR_OFFICE_VALUE &&
    practitionerType === GENERAL_PRACTITIONER_ESANTE
  );
};

/**
 *
 */
// we search schedules depending on the selected week
// we reload the schedule every minute so the docteur doesn't have to leave the calendar to see updates
export const usePollAppointment = propsDate => {
  let date = propsDate != null ? propsDate : moment().format('YYYY-MM-DD');
  let calendarView = 'week';
  const reduxDispatch = useDispatch();
  const practitioner = usePractitioner();
  const organizationId = useSelector(getCurrentOrganizationId);
  const locationFhir = useSelector(s =>
    getLocationByOrganizationId(s, organizationId)
  );
  const locationId = locationFhir?.id;

  useEffect(() => {
    const run = () => {
      const { start, end } = getPeriodFromView(date, calendarView);
      if (practitioner != null) {
        reduxDispatch(
          search('Appointment', {
            practitioner: practitioner.id,
            date: {
              $ge: start,
              $le: end
            },
            _sort: '-_id',
            // ensure the location is loaded
            // it is later use to get the organization details from the events
            _include: 'Appointment:actor',
            _count: 200
          })
        );
      }
      if (locationId != null) {
        reduxDispatch(
          search('Appointment', {
            practitioner: locationId,
            date: {
              $ge: start,
              $le: end
            },
            _sort: '-_id',
            _count: 200
          })
        );
      }
    };
    // immediate call to prevent appointments list from being empty
    run();
    const interval = setInterval(run, 60 * 1000);
    return () => clearInterval(interval);
  }, [practitioner, locationId, reduxDispatch, date, calendarView]);
};
