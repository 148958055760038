import React from 'react'
import styled from 'styled-components/macro'

const Value = styled.span`
  font-size: ${p => p.theme.large};
`

const Unit = styled.span`
  margin-left: ${props => props.theme.spacing.small};
`

const ValueQuantityView = ({ valueQuantity }) => {
  if (valueQuantity == null) {throw new Error('missing valueQuantity')}

  // Here do not do a check on the value quantity unit as some (for instance pH)
  // Do not have any
  if (valueQuantity.value == null)
    {throw new Error(
      `missing value in valueQuantity "${JSON.stringify(valueQuantity)}"`
    )}
  return (
    <div>
      <Value>{valueQuantity.value}</Value>
      <Unit>{valueQuantity.unit}</Unit>
    </div>
  )
}

export default ValueQuantityView
