import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components/macro'
import DefaultEncounterForm from '../../Encounter/components/DefaultEncounterForm'
import { getIdByReference } from '../../Shared/utils'
import { getObservationsFromencounterID } from '../../Observation/selectors'
import { getEncounterById } from '../../Encounter/selectors'

const EncounterSection = styled.div`
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-top: 1rem;
`

/**
 * Intermediate component to observations linked to the encounter of the request group
 * Which are the ones of the clinical-exam for instance
 *
 */
const ReadOnlyEncounterAction = ({ requestGroup, action }) => {
  const encounterReference = requestGroup?.context?.reference
  const encounterID = getIdByReference(encounterReference)
  const encounter = useSelector(getEncounterById(encounterID))
  const observations = useSelector(s =>
    getObservationsFromencounterID(s, encounterID)
  )

  return (
    <EncounterSection>
      <h4>{action.title}</h4>
      <DefaultEncounterForm
        encounter={encounter}
        readOnly={true}
        observations={observations}
      />
    </EncounterSection>
  )
}

export default ReadOnlyEncounterAction
