import React from 'react';
import styled from 'styled-components/macro';
import { Trans } from '@lingui/macro';
import { Link } from '@reach/router';
import { hoverMixin } from '../../Shared/mixins';

const Container = styled.div`
  max-width: 50rem;
  margin: auto;
  & > :not(:last-child) {
    margin-bottom: 1rem;
  }
`;

const CustomLink = styled(Link)`
  padding: 1rem;
  border-radius: 0.25rem;
  display: flex;
  align-items: baseline;
  text-decoration: none;
  ${hoverMixin};
  color: black;
  background: white;
  margin: 2rem 0;
`;

const MainPage = () => {
  return (
    <Container>
      <h1>
        <Trans>Tests</Trans>
      </h1>
      <CustomLink to="visio">
        <Trans>Video</Trans>
      </CustomLink>
    </Container>
  );
};

export default MainPage;
