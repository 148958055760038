import { TELECONSULTATION_QUESTIONNAIRE_SYSTEM } from '../PlanDefinition/utils'
import { ReduxState } from '../Shared/ducks/types'
import { Questionnaire } from 'fhir-stu3'

export const getAllQuestionnaires = (state: ReduxState): Questionnaire[] => {
  return state.questionnaire.allIds.map(id => state.questionnaire.byId[id])
}

export const getQuestionnaireById = (questionnaireId: string) => (
  state: ReduxState
): Questionnaire | undefined =>
  state.questionnaire && state.questionnaire.byId[questionnaireId]

export const getAllQuestionnairesByIdentifierAndSystem = (
  state: ReduxState,
  code: string,
  system = TELECONSULTATION_QUESTIONNAIRE_SYSTEM
) => {
  return getAllQuestionnaires(state)
    ? getAllQuestionnaires(state).filter(
        q => q?.identifier?.find(i => i.system === system)?.value === code
      )
    : []
}
export const getQuestionnaireByIdentifier = (identifier: string) => (
  state: ReduxState
) => {
  return getAllQuestionnaires(state).find(questionnaire => {
    return questionnaire.identifier?.some(i => i.value === identifier)
  })
}
