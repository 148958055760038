import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useEffectOnce } from 'react-use'
import EditPatientForm from '../containers/EditPatientForm'
import { search } from '../../Shared/actions'
import { getPatientById } from '../selectors'

const EditPatientPage = props => {
  const reduxDispatch = useDispatch()
  const patientID = props.patientID
  const isReadOnly = props.readOnly
  const currentPatient = useSelector(getPatientById(patientID))

  // The user might refresh the page, or navigate directly here
  // so we need to load the Patient otherwise the form will be blank
  useEffectOnce(() => {
    if (currentPatient == null) {
      reduxDispatch(
        search('Patient', {
          _id: patientID
        })
      )
    }
  })
  return <EditPatientForm patient={currentPatient} isReadOnly={isReadOnly} />
}

export default EditPatientPage
