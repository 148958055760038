import { Medication } from 'fhir-stu3'
import { FC } from 'react'
import styled from 'styled-components/macro'

const Div = styled.div`
  color: ${(p) => p.theme.black};
  border: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  & > aside {
    margin-right: 1rem;
  }
  & > main {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }
  padding: 1rem;
`

const FirstRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 0.25rem;
`
interface MedicationReferenceSearchDetailsProps {
  medication: Medication
  handleClick: (medication: Medication) => void
}
const MedicationReferenceSearchDetails: FC<MedicationReferenceSearchDetailsProps>= (
  props: MedicationReferenceSearchDetailsProps
) => {
  const { medication, handleClick } = props
  const display = medication!.code!.coding![0].display
  return(
  <Div onClick={() => {handleClick(medication)}}>
    <FirstRow>
      <div>{display}</div>
    </FirstRow>
  </Div>
)}

export default MedicationReferenceSearchDetails
