import React, {
  useState,
  createContext,
  useContext,
  FC,
  ReactNode,
  Dispatch,
  SetStateAction
} from 'react'
import { Practitioner } from 'fhir-stu3'

/**
 * @deprecated
 */
export const CurrentPractitionerContext = createContext<
  [Practitioner | null, Dispatch<SetStateAction<Practitioner | null>>] | null
>(null)

const usePractitioner = (): Practitioner | null => {
  const context = useContext(CurrentPractitionerContext)
  if (context == null) {
    throw new Error('has not been initialized')
  }
  const [practitioner] = context
  return practitioner
}

export const CurrentPractitionerProvider: FC<{ children: ReactNode }> = ({
  children
}) => {
  const value = useState<Practitioner | null>(null)

  return (
    <CurrentPractitionerContext.Provider value={value}>
      {children}
    </CurrentPractitionerContext.Provider>
  )
}

export default usePractitioner
