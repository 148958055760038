import styled from 'styled-components/macro'
import { css } from 'styled-components'
import { Label } from '../../Components'

//this label expect the a require props to passed along
// if required is true add a blue * icon on the right
// it mimics the @medeo/component
const CustomLabel = styled(Label)<{ required?: boolean }>`
  ${p =>
    p.required === true &&
    css`
      &:after {
        margin-left: 0.25rem;
        color: ${p => p.theme.ocean};
        content: '*';
      }
    `}
`

export default CustomLabel
