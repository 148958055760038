import React from 'react';
import styled from 'styled-components/macro';

const Text = styled.div`
  margin: ${props => props.theme.spacing.large} 0;
`;

const Outer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: small;
  color: #77808e;
`;

const NoRequestPage = ({ children }) => {
  return (
    <Outer>
      <Text>{children}</Text>
    </Outer>
  );
};

export default NoRequestPage;
