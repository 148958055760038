import isEqual from 'lodash/isEqual'
import React, { useCallback, useEffect, useReducer, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components/macro'
import { Trans } from '@lingui/macro'
import { Button, Card, Form, Spinner } from '../../Components'
import { Link } from '@reach/router'
import QuestionnaireComponent from './QuestionnaireComponent'
import { getAllQuestionnairesByIdentifierAndSystem } from '../selector'
import { deselectEncounter } from '../../Encounter/actions'
import {
  initQuestionnaireResponse,
  QuestionnaireResponseContext,
  questionnaireResponseReducer,
  SETTING_QUESTIONNAIRE_RESPONSE
} from './Questionnaire'
import { userCanGoToNextStep } from '../utils'
import {
  isActionIdentifierOfValue,
  PREAMBLE_ACTION_VALUE_V2
} from '../../PlanDefinition/utils'
import { search } from '../../Shared/actions'
import { preventSubmitOnKeyPressIfRequired } from '../../Shared/utils'
import usePlanDefinition from '../../PlanDefinition/usePlanDefinition'

const ContainerBtn = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-top: 1px solid ${p => p.theme.gray};
  padding-top: 2rem;
  margin-top: 2rem;
`

const HeaderQuestionnaire = styled(Card.Header)`
  width: 100%;
  padding-bottom: 0;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  padding-left: 1rem;
`

const CustomForm = styled(Form)`
  width: 100%;
`

const CustomSpinner = styled(Spinner)`
  height: 2rem;
  width: 2rem;
  display: block;
  margin: 3rem auto;
`

const SectionQuestionnaire = styled.div`
  margin: 2rem 0;
`

const FooterQuestionnaire = styled(Card.Footer)`
  border-top: none;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
`

const QuestionnaireSequencer = ({
  identifier,
  internalResponseId,
  response,
  readOnly,
  isFromConsultation = false,
  isColumn,
  isCard,
  onChange
}) => {
  const dispatch = useDispatch()
  const { planDefinition, setPlanDefinition, goNext } = usePlanDefinition()
  const selector = useCallback(
    state =>
      getAllQuestionnairesByIdentifierAndSystem(
        state,
        identifier.code,
        identifier.system
      ),
    [identifier]
  )
  const [questionnaire] = useSelector(selector, isEqual)

  // this useEffect is here to prevent a endless spinner,
  // we get the identifer code to fetch the good questionnaire.
  useEffect(() => {
    if (questionnaire == null) {
      dispatch(
        search('Questionnaire', {
          identifier: identifier.code
        })
      )
    }
  }, [identifier.code, dispatch, questionnaire])

  const [questionnaireResponse, dispatchQuestionnaireResponse] = useReducer(
    questionnaireResponseReducer,
    { item: [] },
    initQuestionnaireResponse
  )

  useEffect(() => {
    if (response != null) {
      dispatchQuestionnaireResponse({
        type: SETTING_QUESTIONNAIRE_RESPONSE,
        payload: response
      })
    }
    // the following case works for response identified with internalResponseId, i.e. additionnal questionnaire
    // here we check if we have already an answer about this form (link to the internalResponseId)
    // FOR SUMMARY IN TELECONSULTATION FORM IN GP SIDE
    else if (
      planDefinition.sequenceResponse &&
      planDefinition.sequenceResponse.findIndex(
        sr =>
          sr.internalResponseId && sr.internalResponseId === internalResponseId
      ) !== -1
    ) {
      dispatchQuestionnaireResponse({
        type: SETTING_QUESTIONNAIRE_RESPONSE,
        payload: planDefinition.sequenceResponse.find(
          sr => sr.internalResponseId === internalResponseId
        ).answer
      })
    }
    // the following case works for response identified with responseId, i.e. additionnal questionnaire
    // here we check if we have already an answer about this form (link to the internalResponseId)
    // FOR READONLY IN HISTORY OF PATIENT
    else if (
      planDefinition.sequenceResponse &&
      planDefinition.sequenceResponse.findIndex(
        sr => sr.responseId && sr.responseId === internalResponseId
      ) !== -1
    ) {
      dispatchQuestionnaireResponse({
        type: SETTING_QUESTIONNAIRE_RESPONSE,
        payload: planDefinition.sequenceResponse.find(
          sr => sr.responseId === internalResponseId
        ).answer
      })
    }
    // the following case loads the response in the case of the preconsultation form
    else if (
      questionnaire != null &&
      planDefinition.sequenceResponse &&
      internalResponseId == null &&
      planDefinition.sequenceResponse.findIndex(
        sr => sr.questionnaireId && sr.questionnaireId === questionnaire.id
      ) !== -1
    ) {
      dispatchQuestionnaireResponse({
        type: SETTING_QUESTIONNAIRE_RESPONSE,
        payload: planDefinition.sequenceResponse.find(
          sr => sr.questionnaireId === questionnaire.id
        ).answer
      })
    }
  }, [
    questionnaire,
    response,
    planDefinition.sequenceResponse,
    internalResponseId
  ])
  const [step, setStep] = useState('next')
  const [readOnlyContext, setReadOnlyContext] = useState(readOnly)

  // we get the questionnaire from the state. We use a stringify to create two different object, not a reference
  useEffect(() => {
    if (planDefinition.readOnly) {setReadOnlyContext(planDefinition.readOnly)}
  }, [planDefinition.readOnly])

  // Patients who select a third part during preamble need to add a proof. In
  // this useEffect we check that, if it's not the case we disable the next button
  const [disableNextButton, setDisableNextButton] = useState(false)
  useEffect(() => {
    if (isActionIdentifierOfValue(identifier, PREAMBLE_ACTION_VALUE_V2)) {
      const userAuthorizationToGoNextStep = userCanGoToNextStep(
        questionnaire,
        questionnaireResponse
      )
      setDisableNextButton(userAuthorizationToGoNextStep)
    }
  }, [identifier, questionnaireResponse, questionnaire])

  // Here is the logic that has to be endled when going to next step
  const handleNextStep = () => {
    let newState = {}
    if (!readOnlyContext) {
      newState = {
        ...planDefinition,
        isIsolatedElementView: false,
        internalResponseId: null
      }
    }

    // in the next if(), we are not in readOnly, not in a one-page questionnaire, and not in AME case
    if (readOnlyContext === false && isColumn === false) {
      const idxOfQuestionnaireInState = planDefinition.sequenceResponse.findIndex(
        response => response.questionnaireId === questionnaire.id
      )

      // If the questionnaire is not in the state yet, we add it
      if (idxOfQuestionnaireInState === -1) {
        newState = {
          ...planDefinition,
          sequenceResponse: planDefinition.sequenceResponse.concat([
            {
              type: 'questionnaire',
              answer: questionnaireResponse,
              questionnaireId: questionnaire.id
            }
          ])
        }
      }
      // Otherwise we replace the response we already had
      else {
        const updatedSequenceResponse = planDefinition.sequenceResponse
        updatedSequenceResponse[
          idxOfQuestionnaireInState
        ].answer = questionnaireResponse
        newState = {
          ...planDefinition,
          sequenceResponse: updatedSequenceResponse
        }
      }
    }
    // in a one-page questionnaire, we always save the answer on the click
    if (isColumn === true && readOnly === false) {
      newState = {
        ...planDefinition,
        sequenceResponse: planDefinition.sequenceResponse.concat([
          {
            type: 'questionnaire',
            answer: questionnaireResponse,
            questionnaireId: questionnaire.id
          }
        ])
      }
    }
    // when we submit a secondary form on consultation in doctor side
    if (document && document.getElementById('topOfForm')) {
      document.getElementById('topOfForm').scrollIntoView()
    }
    setPlanDefinition(newState)
    goNext()
    // we need to reset the questionnaireResponse, otherwise it will add it to the next questionnaire
    dispatchQuestionnaireResponse({
      type: SETTING_QUESTIONNAIRE_RESPONSE,
      payload: { item: [] }
    })
  }

  const handleClick = e => {
    e.preventDefault()

    // If we click on a previous button we don't need to do the process for a next button
    if (step === 'previous') {return}

    handleNextStep()
  }

  /**
   * This function performs the update of the planDefinition context
   * Whenever one of the questionnaire responses was updated or added
   *
   * @param {Object} questionnaireResponse: The questionnaire response which was changed
   * @param {int} questionnaireId: the id of the questionnaire this questionnaire response is an answer to
   */
  const onQuestionnaireResponseChange = (
    questionnaireResponse,
    questionnaireId
  ) => {
    onChange()
    // Check if a response already exists for this questionnaire
    const questionnaireIndex = planDefinition.sequenceResponse.findIndex(
      questionnaireResponse => {
        if (!questionnaireResponse.internalResponseId)
          {return questionnaireResponse.questionnaireId === questionnaireId}
        else
          {return (
            questionnaireResponse.questionnaireId === questionnaireId &&
            // This is in case of several questionnaires of same type in the same sequence,
            // That is for instance care sheet
            questionnaireResponse.internalResponseId ===
              planDefinition.internalResponseId
          )}
      }
    )
    // First copy the context as it is for now
    let updatedSequenceContext = planDefinition

    if (questionnaireIndex >= 0) {
      // if the response is saved but the questionnaireResponse is not up to date
      if (
        !isEqual(
          updatedSequenceContext.sequenceResponse[questionnaireIndex].answer,
          questionnaireResponse
        ) &&
        questionnaireResponse.item?.length === 0
      ) {
        return
      }
      // If an instance already exists for the current questionnaire and the current instance
      // We use isEqual to compare as we want to compare the objects themselves and not the references
      if (
        isEqual(
          updatedSequenceContext.sequenceResponse[questionnaireIndex].answer,
          questionnaireResponse
        )
      ) {
        // If the questionnaire response did not change since the last update,
        // Do not update it again
        // This can happen because of shallow comparison on the questionnaireResponse
        // Which would only compare the two object references rather than the objects themselves
        // And therefore trigger a re-render anyway
        return
      } else {
        // If it actually changed, update the response
        updatedSequenceContext.sequenceResponse[
          questionnaireIndex
        ].answer = questionnaireResponse
      }
    } else {
      // If no response was found for this questionnaire and this instance,
      // Add a new one to the context
      updatedSequenceContext.sequenceResponse.push({
        type: 'questionnaire',
        answer: questionnaireResponse,
        questionnaireId: questionnaireId,
        internalResponseId: planDefinition.internalResponseId
      })
    }
    setPlanDefinition({ ...updatedSequenceContext })
  }

  const handleCancel = () => {
    // here we should delete the response because it was created
    const responseId = planDefinition?.internalResponseId
    setPlanDefinition({
      ...planDefinition,
      isIsolatedElementView: false,
      sequenceResponse: planDefinition.sequenceResponse.filter(
        sr => sr.internalResponseId !== responseId
      )
    })
    // when we cancel a secondary form on consultation in doctor side
    if (document && document.getElementById('topOfForm')) {
      document.getElementById('topOfForm').scrollIntoView()
    }
  }

  return !questionnaire || questionnaire.length === 0 ? (
    <CustomSpinner />
  ) : (
    <QuestionnaireResponseContext.Provider
      value={[questionnaireResponse, dispatchQuestionnaireResponse]}
    >
      {isFromConsultation && (
        <HeaderQuestionnaire id="topOfForm">
          <h3>{questionnaire.title}</h3>
        </HeaderQuestionnaire>
      )}
      {/* isCard is used to prevent using a Card element for questionnaires in tele-consultation
      (i.e having a Card on top of a Card) while still showing the Card on pre-teleconsultation*/}
      {!readOnly &&
        !isFromConsultation &&
        (isCard ? (
          <Card.Header>
            <h1> {questionnaire.title}</h1>
          </Card.Header>
        ) : (
          <SectionQuestionnaire>
            <h4> {questionnaire.title}</h4>
          </SectionQuestionnaire>
        ))}
      {/*<pre>{JSON.stringify(questionnaire, null, 2)}</pre>*/}
      {/*<pre>{JSON.stringify(questionnaireResponse, null, 2)}</pre>*/}
      {/*<pre>{JSON.stringify(planDefinition.sequenceResponse, null, 2)}</pre>*/}
      {/*Condition to not display the questionnaire while the questionnaireResponse is not loaded*/}
      {(readOnly === false ||
        (JSON.stringify(questionnaireResponse) !==
          JSON.stringify({
            item: []
          }) &&
          JSON.stringify(questionnaireResponse) !== null)) && (
        <CustomForm
          onSubmit={handleClick}
          onKeyPress={e => preventSubmitOnKeyPressIfRequired(e)}
        >
          {questionnaire.item.map(i => (
            <QuestionnaireComponent
              item={i}
              key={i.linkId}
              readOnly={readOnlyContext}
              questionnaire={questionnaire}
              onChange={onQuestionnaireResponseChange}
            />
          ))}
          {isFromConsultation ? (
            <FooterQuestionnaire>
              <Button
                color="ocean"
                variant="outline"
                type="button"
                onClick={handleCancel}
                data-test={'cancel-button'}
              >
                <Trans>Cancel</Trans>
              </Button>
              {!readOnlyContext && (
                <Button color="ocean" data-test={'save-button'}>
                  <Trans>Save</Trans>
                </Button>
              )}
            </FooterQuestionnaire>
          ) : (
            readOnly === false &&
            isColumn === false && (
              <ContainerBtn>
                {/*Button Previous for first questionnnaire*/}
                {planDefinition.sequenceIndexOfPage === 0 ? (
                  <Link
                    to="../history"
                    onClick={() => {
                      dispatch(deselectEncounter())
                    }}
                  >
                    <Button color="ocean" variant="outline">
                      <Trans>Cancel</Trans>
                    </Button>
                  </Link>
                ) : (
                  // this one is for every other questionaire when we display in pages
                  <Button
                    onClick={() => {
                      setStep('previous')
                      setPlanDefinition({
                        ...planDefinition,
                        sequenceIndexOfPage:
                          planDefinition.sequenceIndexOfPage - 1
                      })
                      dispatchQuestionnaireResponse({
                        type: SETTING_QUESTIONNAIRE_RESPONSE,
                        payload: { item: [] }
                      })
                    }}
                    color="ocean"
                    variant="outline"
                  >
                    <Trans>Previous</Trans>
                  </Button>
                )}
                <Button
                  onClick={() => {
                    setStep('next')
                  }}
                  color="ocean"
                  disabled={disableNextButton}
                  data-test={'next'}
                >
                  <Trans>Next</Trans>
                </Button>
              </ContainerBtn>
            )
          )}
        </CustomForm>
      )}
    </QuestionnaireResponseContext.Provider>
  )
}

QuestionnaireSequencer.defaultProps = {
  // We add a default onChange property in case the questionnaireSequencer is a child of a component depending
  // on a questionnaire response change
  // It is the case for some actions of a PlanDefinition which have a particular logic,
  // as for instance the QuestionnaireTotalPrice
  onChange: () => {}
}

export default QuestionnaireSequencer
