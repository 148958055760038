import { Trans } from '@lingui/macro'
import React from 'react'
import DataListItem from '../../Shared/components/DataListItem'

/**
 * Displays the query the user types in the MedicationMultipleInput, it displays the query
 * in a different manner as the item of the datalist to differentiate the two kind of info.
 * @param query
 * @param onClick
 * @param rest
 * @returns {*}
 * @constructor
 */
const DefaultMedicationDataListItem = ({ query, onClick, ...rest }) => {
  // default onClick handler contained in ...rest, will simply copy the query over
  // we need to match the format of the item in the selection.
  // see extract method in the MedicationTagInput component for more details

  //onClick is actually a thunk in MultipleSearchInput
  //thus the structure like () => () => {...}
  const handleClick = () =>
    onClick({
      resourceType: 'Medication',
      code: { coding: [{ display: query }] },
    })

  return (
    <DataListItem value={query} {...rest} onClick={handleClick}>
      <span>"{query}"</span>
      <span>
        <Trans>no details are available</Trans>
      </span>
    </DataListItem>
  )
}

export default DefaultMedicationDataListItem
