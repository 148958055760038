import React from 'react'
import styled from 'styled-components/macro'
import { lighten } from 'polished'
import { Trans } from '@lingui/macro'
import { Link } from '@reach/router'

export const Container = styled.div`
  background: ${p => lighten(0.25, p.theme.scarlett)};
  border-left: 0.25rem solid ${p => p.theme.scarlett};
  text-align: left;
  padding: 1rem;
  box-shadow: ${p => p.theme.boxShadow};
  border-radius: 0.25rem;
  font-size: ${p => p.theme.small};
  margin-bottom: 0.5rem;
`

const CustomLink = styled(Link)`
  font-weight: bold;
  color: black;
  padding-left: 0.3rem;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`

const Icon = styled.div`
  padding-right: 1rem;
  display: inline;
`

export const NO_RPPS_ID_ERROR = 'NO_RPPS_ID_ERROR'
export const NO_AM_OR_FINESS = 'NO_AM_OR_FINESS'
export const NO_STRIPE_ID_ERROR = 'NO_STRIPE_ID_ERROR'
export const NO_PAYMENT_METHOD = 'NO_PAYMENT_METHOD'
export const PROCESSING_PAYMENT = 'PROCESSING_PAYMENT'
export const ACTIVE_PROCEDURE_REQUEST = 'ACTIVE_PROCEDURE_REQUEST'
export const NO_ESANTE_ROLE_ERROR = 'NO_ESANTE_ROLE_ERROR'
export const REIMBURSEMENT_MUTUALS = 'REIMBURSEMENT_MUTUALS'
export const BUSY_SLOT = 'BUSY_SLOT'
export const NO_ORGANIZATION_EMAIL = 'NO_ORGANIZATION_EMAIL'
export const ACCEPT_THIRD_PARTY_PAYER_NOT_SET =
  'ACCEPT_THIRD_PARTY_PAYER_NOT_SET'

const getErrorMessage = ({ error, arg, patientID }) => {
  switch (error) {
    case NO_RPPS_ID_ERROR:
      return (
        <Trans>
          Your RPPS identifier is not set, you won't be able to create order.
          <CustomLink to={`/settings/edit`}>
            Click here to set it
            <Icon />
          </CustomLink>
        </Trans>
      )
    case NO_ESANTE_ROLE_ERROR:
      return (
        <Trans>
          You do not have access to all of Medeo's features because your
          profession is not entered in your profile. Please contact your
          administrator to fill in this information.
        </Trans>
      )
    case NO_AM_OR_FINESS:
      return (
        <Trans>
          AM or FINESS identifier are not set, you won't be able to create an
          order.
          <CustomLink to={`/settings/edit`}>
            Click here to set it
            <Icon />
          </CustomLink>
        </Trans>
      )
    case NO_STRIPE_ID_ERROR:
      return (
        <Trans>
          Your Stripe identifier is not set, you won't be able to collect the
          remote consultation payment.
          <CustomLink to={`/settings/edit`}>
            Click here to set it
            <Icon />
          </CustomLink>
        </Trans>
      )
    case NO_PAYMENT_METHOD:
      return (
        <Trans>
          The patient did not provide a payment method. No payment possible for
          the amount owed : {arg}€
        </Trans>
      )
    case PROCESSING_PAYMENT:
      return (
        <Trans>
          Please wait, stay with us while the task is being executed.
        </Trans>
      )
    case ACTIVE_PROCEDURE_REQUEST:
      return (
        <Trans>
          A teleconsultation procedure was already started for this patient. In
          order to go further, you can cancel the former one into their patient
          file
          <CustomLink to={`/patient/${patientID}/history`}>here</CustomLink>.
        </Trans>
      )
    // in the case where of the mutual of patient is compatible not with médecin direct
    case REIMBURSEMENT_MUTUALS:
      return (
        <Trans>
          Unfortunately, the mutual insurance company does not cover
          reimbursement for the teleconsultation with MédecinDirect. However,
          you can still access a doctor, the costs will be borne by the patient.
        </Trans>
      )

    case BUSY_SLOT:
      return (
        <Trans>
          Unfortunately, the slot you chose is no longer available. Please go
          back and select another one.
        </Trans>
      )
    case NO_ORGANIZATION_EMAIL:
      return (
        <Trans>
          Oops, it seems like there is no email given for your organization,
          please fill it in order to receive correctly mails.
          <CustomLink to="/settings/organization">Add it</CustomLink>
        </Trans>
      )
    case ACCEPT_THIRD_PARTY_PAYER_NOT_SET:
      return (
        <Trans>
          You did not set whether you are accepting third party payer or not.
          Please fill it by clicking on
          <CustomLink to="/settings/edit">this link.</CustomLink>
        </Trans>
      )
    // if needed for other static notifications they could be added here
    default:
      return null
  }
}

const StaticErrorNotification = ({ error, arg, patientID }) => {
  const errorMessage = getErrorMessage({ error, arg, patientID })
  return <Container>{errorMessage}</Container>
}

export default StaticErrorNotification
