import { takeLeading, call, put } from 'redux-saga/effects'
import { SIGN_OUT_REQUESTED, succeedToReceiveAccessToken } from './actions'
import Amplify, { Auth } from 'aws-amplify'

function* signOutWorker() {
  try {
    console.warn('signing out...')
    yield Auth.signOut()
  } catch (error) {
    console.error(error)
  }
}
function* signInWorker() {
  try {
    const session = yield Auth.currentSession()
    const user = yield Auth.currentAuthenticatedUser()
    if (session.accessToken != null) {
      // user attributes contains info such as mail address and organization id.
      // it should be passed so we can narrow the searches and pass through the RequirePractitioner
      yield put(
        succeedToReceiveAccessToken(
          session.accessToken.getJwtToken(),
          user.attributes,
          session.accessToken.payload['cognito:groups']
        )
      )
    }
  } catch (error) {
    console.warn(error)
  }
}
function* rootSaga() {
  if (process.env.NODE_ENV !== 'test') {
    // eslint-disable-next-line
    const { default: config } = yield import('../aws-exports') // if you are using Amplify CLI
    Amplify.configure(config)
  }
  yield call(signInWorker)
  yield takeLeading(SIGN_OUT_REQUESTED, signOutWorker)
}

export default rootSaga
