import React from 'react'
import { Trans } from '@lingui/macro'
import {
  getMedeoRoleFromGouvCode,
  getSpecialtyCodingFromSystem,
  NO_SPECIALTY,
} from '../../Permissions/roles'
import { ESANTE_OCCUPATION_SYSTEM } from '../../utils/codes'

export const displayPractitionerActiveRole = (
  roles,
  fallback = <Trans>Practitioner</Trans>
) => {
  // Take the first role (even if the practitioner has multiple)
  const activeRole = roles && roles.length ? roles[0] : null
  const mesEsanteRole = getSpecialtyCodingFromSystem(
    activeRole,
    ESANTE_OCCUPATION_SYSTEM
  )

  if (activeRole != null && mesEsanteRole != null) {
    return getMedeoRoleFromGouvCode(mesEsanteRole.code)
  }

  return fallback
}

export const getPractitionerActiveCode = (roles, fallback = NO_SPECIALTY) => {
  // Take the first role (even if the practitioner has multiple)
  const activeRole = roles && roles.length ? roles[0] : null
  const mesEsanteRole = getSpecialtyCodingFromSystem(
    activeRole,
    ESANTE_OCCUPATION_SYSTEM
  )

  if (activeRole != null && mesEsanteRole != null) {
    return mesEsanteRole.code
  }

  return fallback
}
