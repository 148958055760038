import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { t } from '@lingui/macro'
import React, { useState } from 'react'
import { useDebounce } from 'react-use'
import styled from 'styled-components/macro'
import { Button } from '../../Components'
import { useI18n } from '../../utils/I18nHookAdapter'

const Form = styled.form`
  display: flex;
  justify-content: center;
  & > input {
    border-color: transparent;
    box-shadow: ${p => p.theme.boxShadow};
  }
  & > :not(:last-child) {
    margin-right: 0.5rem;
  }
`

const SearchPatientsForm = React.memo(({ onChange, disabled }) => {
  const [state, setState] = useState('')
  const handleSubmit = e => {
    e.preventDefault()
    cancel()
    onChange(state)
  }
  const [, cancel] = useDebounce(
    () => {
      if (state.length > 2) {
        onChange(state)
      }
    },
    1000,
    [state]
  )
  const i18n = useI18n()
  const placeholder = i18n._(t`Search`)
  return (
    <Form onSubmit={handleSubmit}>
      <input
        // type search will show a clear icon on the left side of the input
        type="search"
        value={state}
        placeholder={placeholder}
        onChange={e => {
          const { value } = e.target
          setState(value)
          // if the user clear the text input
          // we want to trigger the onChange event
          // in order to display the full list of patients
          if (value === '') {
            onChange('')
          }
        }}
      />
      <Button disabled={disabled}>
        <FontAwesomeIcon icon={faSearch} />
      </Button>
    </Form>
  )
})

export default SearchPatientsForm
