import { call, put, select } from 'redux-saga/effects'
import * as R from 'ramda'
import { searchFhirResourceWorker, nextPageFhirResourceWorker } from './index'

/**
 * createPageWorker is a factory method that enable pagination feature for a
 * resource, it create the saga responsible for fetching and dealing with
 * @param resourceType the resource type we are going to fetch i.e. Patient
 * @param getQuery
 * @param nextAction
 * @returns {getInitialPageWorker}
 */
export const createPageWorker = (resourceType, getQuery, nextAction) =>
  function* getInitialPageWorker(action) {
    const { payload } = action

    try {
      const { count } = payload

      const searchAction = yield call(searchFhirResourceWorker, resourceType, {
        _count: count,
        ...(getQuery ? getQuery(payload) : {}),
      })
      // if there is nothing i.e. total is 0
      // we simply return an empty array [], this will set the is loading to false
      if (
        searchAction &&
        searchAction.payload &&
        searchAction.payload.total === 0
      ) {
        yield put(nextAction([], searchAction.payload.link, true))
      }
      if (
        searchAction &&
        searchAction.payload &&
        searchAction.payload[resourceType]
      ) {
        const items = searchAction.payload[resourceType].map(entry => entry.id)
        yield put(nextAction(items, searchAction.payload.link, true))
      }
    } catch (err) {
      console.log(err)
    }
  }

export const createLoadMoreWorker = (type, nextAction) =>
  function* loadMoreWorker(action) {
    const { payload } = action

    try {
      const { link } = payload
      const response = yield call(nextPageFhirResourceWorker, type, link)
      const items = response.payload[type].map(entry => entry.id)
      yield put(nextAction(items, response.payload.link, false))
    } catch (err) {
      console.log(err)
    }
  }

export const paginationStale = (selector, initialPageAction) =>
  function* paginationStaleWorker() {
    const state = yield select(selector)
    const config = state.config

    yield put(initialPageAction(config.count, R.omit(['count'], config)))
  }
