export const allergyBuilder = (value, patientID) => {
  const newAllergy = {
    resourceType: 'AllergyIntolerance',
    clinicalStatus: 'active',
    verificationStatus: 'confirmed',
    code: {
      coding: [
        {
          system: 'http://medeo.io/allergy',
          code: value,
        },
      ],
      text: value,
    },
    patient: {
      reference: `Patient/${patientID}`,
    },
  }
  return newAllergy
}
