import { takeEvery, takeLeading, call } from 'redux-saga/effects'
import {
  searchFhirResourceWorker,
  saveFhirResourceWorker,
} from '../Shared/sagas'
import {
  ADDING_CLINICAL_IMPRESSION,
  FETCHING_CLINICAL_IMPRESSIONS,
} from './actions'
import { HTML_DATE_FORMAT } from '../utils/dateUtils'
import moment from 'moment'

function* addingClinicalImpression(action) {
  const review = action.payload.review
  const encounterID = action.payload.encounterID
  const practitionerID = action.payload.practitionerID
  const now = moment().format(HTML_DATE_FORMAT)
  const patientID = action.payload.patientID
  yield call(
    saveFhirResourceWorker,
    {
      resourceType: 'ClinicalImpression',
      status: 'completed',
      subject: { reference: `Patient/${patientID}` },
      context: { reference: `Encounter/${encounterID}` },
      date: now,
      assessor: { reference: `Practitioner/${practitionerID}` },
      summary: review,
    },
    action
  )
}

function* rootSaga() {
  yield takeEvery(
    [FETCHING_CLINICAL_IMPRESSIONS],
    searchFhirResourceWorker,
    'ClinicalImpression',
    ({ payload: p }) => ({ context: 'Encounter/' + p.encounterID })
  )

  yield takeLeading(ADDING_CLINICAL_IMPRESSION, addingClinicalImpression)
}

export default rootSaga
