import * as R from 'ramda';
import { uniq } from 'lodash';
import { getLoincCodingFromObservation } from '../utils/loinc';
import { getCurrentEncounter } from '../Encounter/selectors';
import { sortByFirstDate, sortByLastUpdate } from '../utils/dateUtils';

export const getAllObservations = state =>
  state.observations.allIds.map(id => state.observations.byId[id]);

export const getTheLastObservationBySpecificCode = (state, loincCode, id) => {
  return state.observations.allIds
    .map(i => state.observations.byId[i])
    .sort(sortByFirstDate)
    .filter(o => o.subject && o.subject.reference === `Patient/${id}`)
    .find(
      c =>
        c &&
        c.code &&
        c.code.coding &&
        c.code.coding[0] &&
        c.code.coding[0].code &&
        c.code.coding[0].code === loincCode
    );
};

export const getModelFromDependencies = (state, dependencies, patientID) => {
  return dependencies.reduce((deps, d) => {
    const o = state.observations.allIds
      .map(i => state.observations.byId[i])
      .sort(sortByLastUpdate)
      .filter(o => o.subject && o.subject.reference === `Patient/${patientID}`)
      .find(
        c =>
          c.code &&
          c.code.coding &&
          c.code.coding[0] &&
          c.code.coding[0].code &&
          c.code.coding[0].code === d.code
      );
    // convert the observations in the yaml format { $myVar: { $myProp: 'value' } }
    //TODO: add other valueXXX cases.
    // this selector is formatting only the case of an Observation with a valueString.
    return o != null ? { ...deps, [d.name]: { $value: o.valueString } } : deps;
  }, {});
};

export const getObservationsFromCurrentEncounter = (
  state,
  filters = [() => true]
) => {
  const encounter = getCurrentEncounter(state);
  return encounter != null
    ? getObservationsFromencounterID(state, encounter.id, filters)
    : [];
};

export const getObservationsFromencounterID = (
  state,
  encounterID,
  filters = [() => true]
) => {
  if (encounterID != null) {
    return getAllObservations(state)
      .filter(
        o =>
          o.context &&
          o.context.reference === `Encounter/${encounterID}` &&
          o.category?.[0].coding?.[0].code === 'vital-signs'
      )
      .filter(o => filters.every(f => f(o)));
  } else {
    return [];
  }
};

export const filterOutRelatingObservation = o => o.related == null;
export const filterOutEnteredInErrorObservation = o =>
  o.status !== 'entered-in-error';
export const filterOutLoincCodes = loincCodes => o => {
  return (
    loincCodes == null ||
    loincCodes.some(c => c === getLoincCodingFromObservation(o).code)
  );
};

/**
 *
 * @param state le state redux
 * @param observation correspond à l'observation de la mesure dont on cherche les derivées.
 * @returns {*}
 */
export const getDerivedObservationsFromObservationId = (state, observation) => {
  if (observation == null) {return [];}
  const ref = `Observation/${observation.id}`;
  return getAllObservations(state).filter(
    o =>
      Array.isArray(o.related) &&
      o.related.some(r => r.target.reference === ref)
  );
};

// Return full input of yaml form
export const getInputFromYamlFormCode = (formResources, formCode) => {
  if (formResources == null) {return;}
  return formResources.filter(o => o.refs.$code === formCode);
};

export const getAllObservationsByType = (state, info) => {
  const allObservations = getObservationsFromencounterID(state, info.encounterID);
  return allObservations.filter(observation => {
    return observation.code.coding[0].code === info.observationType;
  });
};

// Add a isFirst field for each observations
export const addPlaceToObservation = observations => {
  let newObservations = [];
  if (observations.length > 1) {
    let firstO = R.merge(observations[0], { isFirst: true });
    newObservations.push(firstO);
    observations.shift();
    observations.map(o => {
      o = R.merge(o, { isFirst: false });
      newObservations.push(o);
      return null;
    });
    return newObservations;
  }
  return observations;
};

export const getObservationCode = observation => {
  if (
    observation.code &&
    observation.code.coding &&
    observation.code.coding[0] &&
    observation.code.coding[0].code
  )
    {return observation.code.coding[0].code;}
  else {return null;}
};

export const getAllObservationsType = observations => {
  if (observations == null) {return null;}
  let observationTypes = [];
  observations.map(o => {
    const code = getObservationCode(o);
    if (code) {
      observationTypes.push(code);
    }
    return null;
  });
  return uniq(observationTypes);
};

export const filterObservationsByTypes = (type, observationList) => {
  const observations = observationList.filter(observation => {
    return type === getObservationCode(observation);
  });
  return addPlaceToObservation(observations);
};
