import { fork, all, take, takeLeading, cancel } from 'redux-saga/effects'
import { sagas as Patients } from './Patient'
import { sagas as Encounters } from './Encounter'
import { sagas as Observations } from './Observation'
import { sagas as Allergies } from './Allergy'
import { sagas as Conditions } from './Condition'
import { sagas as Practitioners } from './Practitioner'
import { sagas as PractitionerRoles } from './PractitionerRole'
import { sagas as Organizations } from './Organization'
import { default as Bluetooth } from './Bluetooth/sagas'
import { default as Database } from './Shared/sagas/db'
import { default as Notifications } from './Notifications/sagas'
import { default as ClinicalImpressions } from './ClinicalImpression/sagas'
import { default as MedicationStatement } from './MedicationStatement/sagas'
import { default as ProcedureRequest } from './ProcedureRequest/sagas'
import { default as CareTeam } from './CareTeam/sagas'
import { sagas as Auth } from './Auth'
import { default as QuestionnaireResponse } from './QuestionnaireResponse/sagas'
import { default as DocumentReferences } from './DocumentReference/sagas'
import { default as RequestGroup } from './RequestGroup/sagas'
import { default as Devices } from './Device/sagas'
import { default as Shared } from './Shared/sagas'
import { default as Schedule } from './Schedule/sagas'
import { default as Slot } from './Slot/sagas'
import { default as Appointment } from './Appointment/sagas'
import { default as Location } from './Location/sagas'
import { default as PaymentNotice } from './PaymentNotice/sagas'
import { default as Consent } from './Consent/sagas'
import { OPEN_ID_TOKEN_RECEIVED, SIGN_OUT_REQUESTED } from './Auth/actions'

function* rootSaga() {
  yield fork(Auth)
  // We are waiting for the fhirClient to be built before launching all the FHIR related sagas.
  // OPEN_ID_TOKEN_RECEIVED is fired either when the sign-in process is finished
  // or when the getFreshToken method has resolved with the token stored in the localstorage
  yield takeLeading(OPEN_ID_TOKEN_RECEIVED, fhirSagasWorker)
}

function* fhirSagasWorker() {
  const tasks = yield all([
    fork(Allergies),
    fork(Appointment),
    fork(Bluetooth),
    fork(CareTeam),
    fork(Conditions),
    fork(Consent),
    fork(ClinicalImpressions),
    fork(Database),
    fork(Devices),
    fork(DocumentReferences),
    fork(Encounters),
    fork(Location),
    fork(MedicationStatement),
    fork(Notifications),
    fork(Observations),
    fork(Organizations),
    fork(Patients),
    fork(PaymentNotice),
    fork(Practitioners),
    fork(PractitionerRoles),
    fork(ProcedureRequest),
    fork(QuestionnaireResponse),
    fork(RequestGroup),
    fork(Schedule),
    fork(Shared),
    fork(Slot)
  ])
  yield take(SIGN_OUT_REQUESTED)
  yield cancel(tasks)
}

export default rootSaga
