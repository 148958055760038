import { Trans } from '@lingui/macro'
import React, { FC, SyntheticEvent, useEffect, useReducer } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components/macro'
import { getOutsideOrgsFromCurrentOrg } from '../../CareTeam/selectors'
import { getSlotsByPerformers } from '../../Slot/selectors'
//@ts-ignore
import { useNavigate } from '@reach/router'
import { Organization, Period, Practitioner, Slot } from 'fhir-stu3'
import { isEqual } from 'lodash'
import moment from 'moment'
import { useUpdateEffect } from 'react-use'
import useLazySearch from '../../MedicationRequest/useLazySearch'
import { MEDEO_SCHEDULE_TYPE_TELECONSULTATION } from '../../Schedule/utils'
import useSearch from '../../Shared/hooks/useSearch'
import BetterSlot from '../../Slot/components/BetterSlot'
import BetterBookingView from '../../Slot/containers/BetterBookingView'
import { isBookable } from '../../Slot/utils'
import useBookingContext from '../containers/BookingContext'
import BookingFormFooter from '../containers/BookingFormFooter'
import LoadingCardContent from './LoadingCardContent'

const ScrollContainer = styled.div`
  padding: 1rem 2.5rem 0;
  overflow-y: auto;
  // tweak the 23rem to make this container grow until the end of the page
  max-height: calc(100vh - 22rem);
`

const Header = styled.header`
  margin: 1.5rem 2.5rem 0 2.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid black;
`
const H4 = styled.h3`
  font-size: 1.125rem;
  margin: 0;
  font-weight: normal;
`

const init = (props: any) => {
  const { slot, performer } = props
  return {
    slot: slot ?? null,
    // the component will look for performers when given an id
    performerID: performer?.id,
    performers: []
  }
}
const reducer = (state: any, action: any) => {
  const { type, payload } = action
  switch (type) {
    case 'click':
      return {
        ...state,
        slot: payload.slot,
        performerID: payload.performerID
      }
    case 'update':
      return {
        ...state,
        performers: payload?.Practitioner ?? []
      }
    default:
      return state
  }
}
const Centered = styled.div`
  display: flex;
  flex-direction: column;
  padding: 3rem 0 2rem;
  align-items: center;
  & > :not(:last-child) {
    margin-bottom: 1rem;
  }
`

const Fallback = () => {
  return (
    <Centered>
      <div>
        <Trans>No practitioner has set their availability yet</Trans>
      </div>
      <div>
        <Trans>You can check this page later</Trans>
      </div>
    </Centered>
  )
}

/**
 * It allows to specify a performer organizationId
 * for a ProcedureRequest (i.e. a remote consultation)
 * @returns {*}
 * @constructor
 */
const BookingSelectSlotForm: FC = () => {
  // performer is recovered from the context
  // it is used to display slots and practitioner when a user navigates back
  const { handleChangeSlot, state: bookingContext } = useBookingContext()
  const navigate = useNavigate()
  const [state, dispatch] = useReducer(
    reducer,
    { slot: bookingContext?.slot, performer: bookingContext?.performer },
    init
  )
  const slots = useSelector(
    //@ts-ignore
    getSlotsByPerformers(state.performers, [isBookable])
  )
  const outsideOrganizations = useSelector(
    getOutsideOrgsFromCurrentOrg,
    isEqual
  )

  useEffect(() => {
    handleChangeSlot({
      slot: state.slot,
      performer: state.performers.find(
        (p: Practitioner) => p.id === state.performerID
      )
    })
  }, [state, handleChangeSlot])

  // the booking view relies on multiple resources:
  // - schedules that define a planningHorizon where a practitioner is bookable
  // - slots that tell us if there are already some appointments taken
  // Note: a schedule contains also the actors to which it applies

  // we formerly fetched schedules using the actor field by doing something like:
  // 1. "fetch the outside collaborators careteam"
  // 3. "then, for each organization, fetch the collaborators"
  // 2. "then, for each collaborator, fetch the schedules + slots"
  // performances were bad as we sent as many requests as there are performers
  // moreover we didn't know whether a practitioner has a schedule or not

  // The following query uses practitionerRoles to solve this issue.
  // It turns out we can fetch schedules from an org with "actor.organization"
  // roles link an org and a practitioner and can be used in this situation
  // NOTE(Charles): schedules should from now on have practitionerRole actor

  // With this query we do not need to refer to a specific practitioner
  // by doing so we will have all in one single query:
  // 1. all of the active schedules from the careTeam
  // 2. the performers associated with the schedules
  // 3. the slots associated with the schedules
  const { data, loading } = useSearch('Schedule', {
    'actor.organization': {
      $or: outsideOrganizations.map((org: Organization) => org.id)
    },
    active: 'true',
    identifier: MEDEO_SCHEDULE_TYPE_TELECONSULTATION,
    date: {
      $ge: moment().format('YYYY-MM-DD'),
      $le: moment()
        .add(2, 'weeks')
        .format('YYYY-MM-DD')
    },
    _sort: 'date',
    _include: 'Schedule:actor',
    _revinclude: 'Slot:schedule',
    _count: 200
  })
  useUpdateEffect(() => {
    dispatch({ type: 'update', payload: data })
  }, [data])

  // handleClick is passed as a prop to BookingView,
  // useCallback is required to prevent extra rerender
  const curriedHandleClick = (performerID: string) => (slot: Slot) => {
    dispatch({ type: 'click', payload: { slot, performerID } })
  }

  // handleChange and lazySearch are used when the user clicks on a nav button
  // this will make a call to the server to fetch slots for the given period
  // this method is curried to simplify composition inside the jsx below.
  // for more details about curried function see:
  // https://medium.com/javascript-scene/curry-and-function-composition-2c208d774983#
  const [search] = useLazySearch()
  const curriedHandleChange = (performerID: string) => async (
    period: Period
  ) => {
    dispatch({ type: 'change' })

    // this search does not require to get any include info
    await search('Schedule', {
      actor: `Practitioner/${performerID}`,
      active: true, // really important otherwise inactive slots could be selected
      date: {
        $ge: period.start,
        $le: period.end
      },
      _revinclude: 'Slot:schedule'
    })
  }
  //@ts-ignore
  const entries = Object.entries(slots)

  const handleSubmit: React.EventHandler<SyntheticEvent> = async (e) => {
    e.preventDefault()
    await navigate('./motive')
  }

  if (loading === true) {
    return <LoadingCardContent />
  }

  return (
    <>
      <Header>
        <H4>
          <Trans>Health professional</Trans>
        </H4>
      </Header>
      <form id="booking" onSubmit={handleSubmit}>
        <ScrollContainer>
          {entries.length === 0 && <Fallback />}
          {/* if there are some performers available display a booking view */}
          {/*               slot={null} */}
          {entries.map(([performerID, slots]) => (
            <BetterBookingView
              key={performerID}
              performerID={performerID}
              slots={slots}
              slot={state.slot}
              onChange={curriedHandleChange(performerID)}
            >
              {(slot: Slot) => (
                <BetterSlot
                  key={slot.id}
                  checked={state.slot?.id === slot.id}
                  slot={slot}
                  onClick={curriedHandleClick(performerID)}
                />
              )}
            </BetterBookingView>
          ))}
        </ScrollContainer>
      </form>
      <BookingFormFooter canNavigateNext={state.slot?.id} />
    </>
  )
}

export default React.memo(BookingSelectSlotForm)
