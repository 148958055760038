import { useEffect, useState } from 'react'
import { Trans } from '@lingui/macro'
import NoRequestsPage from '../components/NoRequestsPage'
import { useDispatch, useSelector } from 'react-redux'
import { useLazySearch } from '../../Shared/hooks'
import { MEDEO_PROCEDURE_REQUEST_REMOTE_CONSULTATION_CODE } from '../../utils/codes'
import { getIdByReference } from '../../Shared/utils'
import { getCurrentOrganizationId } from '../../Auth/selectors'
import moment from 'moment'
import {
  getAllPatientsWithProcedureRequestByProcedureCode,
  getTotalOfPatientsForWaitingRoom
} from '../../ProcedureRequest/selectors'
import { fetchLastProcedureRequestsActive } from '../../ProcedureRequest/actions'
import { useNavigate } from '@reach/router'
import PaginatedPatientList from '../../Patient/containers/PaginatedPatientList'
import PatientTableRow from '../../Patient/components/PatientTableRow'
import LoadMore from '../../Shared/components/LoadMore'
import styled from 'styled-components/macro'
import { Spinner } from '../../Components'

const Title = styled.div`
  font-size: x-large;
`

const Footer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const usePollTeleconsultations = (timeout = 60000) => {
  const dispatch = useDispatch()
  const organizationId = useSelector(getCurrentOrganizationId)
  const [date, setDate] = useState(Date.now())
  const [search, { loading }] = useLazySearch()
  useEffect(() => {
    search('ProcedureRequest', {
      performer: `Organization/${organizationId}`,
      _include: {
        $and: ['ProcedureRequest:subject', 'ProcedureRequest:encounter']
      },
      _sort: '-_id',
      _count: 20
    })
  }, [search, organizationId])
  // this is for refreshing patient list every 30 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setDate(Date.now())
      return dispatch(fetchLastProcedureRequestsActive())
    }, timeout)
    return () => clearInterval(interval)
  }, [dispatch, timeout])
  return { loading, date }
}

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin: ${props => props.theme.spacing.large} 0;
  padding-bottom: 1rem;
  border-bottom: 1px solid black;
`

function WaitingRoomHeader({ children }) {
  return (
    <Header id={'header'}>
      <Title>{children}</Title>
    </Header>
  )
}

const WithTeleconsultations = ({
  patientsAndTeleconsultations,
  total,
  displayMore
}) => {
  const navigate = useNavigate()
  const hasMore = total - patientsAndTeleconsultations.length > 0
  const onClick = ({ patient, preConsultationencounterID }) => {
    // for access from teleconsultation waiting room
    navigate(`/teleconsultation/${patient.id}/${preConsultationencounterID}`)
  }
  return (
    <>
      <PaginatedPatientList
        patients={patientsAndTeleconsultations}
        patientsTotal={total}
      >
        {(entry, i) => {
          const procedureRequest = entry.procedureRequest
          const preConsultationencounterID = getIdByReference(
            procedureRequest?.context?.reference
          )

          return (
            <PatientTableRow
              key={i}
              patient={entry.patient}
              data-test="teleconsultation-list"
              onClick={() =>
                onClick({ patient: entry.patient, preConsultationencounterID: preConsultationencounterID })
              }
            />
          )
        }}
      </PaginatedPatientList>
      <Footer>
        <LoadMore loadMore={displayMore} hasMore={hasMore}>
          <Trans>Load more patients</Trans>
        </LoadMore>
      </Footer>
    </>
  )
}

const AutomaticUpdateFeedBack = ({ loading, date }) => {
  const [state, setState] = useState(Date.now())
  useEffect(() => {
    let interval = setInterval(() => {
      setState(Date.now())
    }, 5000)
    return () => clearInterval(interval)
  }, [])

  const future = moment(date).add(60, 'seconds')
  const duration = moment.duration(moment.duration(moment(future).diff(state)))
  const seconds = duration.seconds()
  const feedback = duration.humanize(true)
  if (loading || seconds < 5) {
    return (
      <div>
        <Spinner />
        &nbsp;
        <Trans>Updating the appointments...</Trans>
      </div>
    )
  }
  if (seconds > 55) {
    return (
      <div>
        <Trans>Appointments were successfully updated</Trans>
      </div>
    )
  }

  return (
    <div>
      <Trans>Appointments will automatically be updated </Trans>
      &nbsp;
      {feedback}.
    </div>
  )
}
const DisclaimerDiv = styled.div`
  line-height: 2;
  color: #98a5b2;
  font-size: small;
  margin-bottom: 1rem;
`
const Teleconsultations = () => {
  const [patientLimit, setPatientLimit] = useState(10)
  const { loading, date } = usePollTeleconsultations()

  const allTeleconsultations = useSelector(state =>
    getAllPatientsWithProcedureRequestByProcedureCode(
      state,
      MEDEO_PROCEDURE_REQUEST_REMOTE_CONSULTATION_CODE,
      patientLimit
    )
  )

  const handleDisplayMore = () => setPatientLimit(limit => limit + 10)

  const teleconsultationTotal = useSelector(getTotalOfPatientsForWaitingRoom)

  return (
    <div>
      <WaitingRoomHeader>
        <Trans>Virtual waiting room</Trans>
      </WaitingRoomHeader>
      <DisclaimerDiv>
        <Trans>
          Patients will appear here when they have finished their
          preconsultation.
        </Trans>
        <AutomaticUpdateFeedBack loading={loading} date={date} />
      </DisclaimerDiv>
      {loading && teleconsultationTotal === 0 && <Spinner />}
      {!loading && teleconsultationTotal === 0 ? (
        <NoRequestsPage>
          <Trans>You have no teleconsultation at the moment</Trans>
        </NoRequestsPage>
      ) : (
        <WithTeleconsultations
          patientsAndTeleconsultations={allTeleconsultations}
          total={teleconsultationTotal}
          displayMore={handleDisplayMore}
        />
      )}
    </div>
  )
}

export default Teleconsultations
