import moment from 'moment'

export const FHIR_DATE_FORMAT = 'YYYY-MM-DD[T]HH:mm:ss'
export const HTML_DATE_FORMAT = 'YYYY-MM-DD'

export const sortByEndDate = (a, b) => {
  if (a.period == null || a.period.end == null) {return 1}
  if (b.period == null || b.period.end == null) {return 1}

  const ta = moment(a.period.end, FHIR_DATE_FORMAT).valueOf()
  const tb = moment(b.period.end, FHIR_DATE_FORMAT).valueOf()
  return tb - ta
}

export const sortByFirstDate = (a, b) => {
  if (a.period == null || a.period.end == null) {return 1}
  if (b.period == null || b.period.end == null) {return 1}

  const ta = moment(a.period.end, FHIR_DATE_FORMAT).valueOf()
  const tb = moment(b.period.end, FHIR_DATE_FORMAT).valueOf()
  return ta - tb
}

export const sortByLastUpdate = (a, b) => {
  if (a.meta?.lastUpdated == null) {return 1}
  if (b.meta?.lastUpdated == null) {return 1}

  const ta = moment(a.meta.lastUpdated, FHIR_DATE_FORMAT).valueOf()
  const tb = moment(b.meta.lastUpdated, FHIR_DATE_FORMAT).valueOf()
  return tb - ta
}

export const sortByDateTime = (a, b) => {
  if (a.dateTime == null) {return 1}
  if (b.dateTime == null) {return 1}

  const ta = moment(a.dateTime, FHIR_DATE_FORMAT).valueOf()
  const tb = moment(b.dateTime, FHIR_DATE_FORMAT).valueOf()
  return ta - tb
}

export const filterBetweenDates = period => e =>
  period == null ||
  (e != null &&
    e.period != null &&
    moment(e.period.end).format('DD-MM-YYYY 00:00:00') >= period.start &&
    moment(e.period.end).format('DD-MM-YYYY 00:00:00') <= period.end)

/**
 * Get elapsed time since the resource was updated for the last time
 * @param {object} a: fhir resource
 */
export const getElapsedTime = a => {
  if (a.meta.lastUpdated == null) {return null}

  const ta = moment(a.meta.lastUpdated).valueOf()
  const currentTime = new Date().getTime()

  return currentTime - ta
}
