import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import { Input } from '../../Components'
import { getDisplay, onChangeQuestionnaire, getItemByLinkId } from '../utils'
import { QuestionnaireResponseContext } from './Questionnaire'

const Unit = styled.span`
  display: flex;
  padding-left: 0.5rem;
`
Unit.displayName = 'Unit'

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: baseline;
  & > span {
    display: flex;
  }
`
Container.displayName = 'Container'

const QuantityInput = styled(Input)`
  max-width: 100%;
`

// useful for debugging
// this will prevent this component to be called Styled(Styled(Input))
// see https://medium.com/javascript-in-plain-english/youre-missing-a-trick-with-your-styled-components-in-react-e3dfcd586f75
QuantityInput.displayName = 'QuantityInput'

const QuestionnaireQuantity = ({ item, readOnly }) => {
  const [questionnaireResponse, dispatchQuestionnaireResponse] = useContext(
    QuestionnaireResponseContext
  )

  const [defaultValue, setDefaultValue] = useState(0)
  const [unit, setUnit] = useState('')
  const getItem = getItemByLinkId(questionnaireResponse, item.linkId)

  const quantityValue = getItem?.answer?.[0]?.valueQuantity?.value

  useEffect(() => {
    // prevent defaultValue to be set to null
    // which will cause react to complain about the input being uncontrolled
    if (quantityValue != null && quantityValue !== defaultValue)
      {setDefaultValue(quantityValue)}
    const unit = getItem?.answer?.[0]?.valueQuantity?.unit
    setUnit(unit)
  }, [item, quantityValue, getItem, defaultValue])

  return (
    <Container>
      <QuantityInput
        label={getDisplay(item)}
        data-test="TiersPayant"
        name={item.linkId}
        type="number"
        required={item.required}
        readOnly={readOnly}
        value={defaultValue}
        onChange={e =>
          onChangeQuestionnaire({
            questionnaireResponse: questionnaireResponse,
            dispatchQuestionnaireResponse: dispatchQuestionnaireResponse,
            name: e.target.name,
            value: {
              value: e.target.value,
              unit: item.initialQuantity.unit
            },
            type: 'quantity'
          })
        }
        className="fs-block"
      />
      <Unit>{unit}</Unit>
    </Container>
  )
}

export default QuestionnaireQuantity
