import React from 'react'
import styled from 'styled-components/macro'
import EncounterReadOnlyView from './EncounterReadOnlyView'
import moment from 'moment'
import { DateHeader } from '../components/DateHeader'

const Margin = styled.div`
  margin-top: 1rem;
`
const EncounterDate = styled.div`
  padding-bottom: 3em;
`

const EncounterDateView = ({
  date,
  encounterList,
  hideDownloadButton,
  patient,
}) => {
  return (
    <EncounterDate>
      <DateHeader>{moment(date, 'DD/MM/YYYY').format('LL')}</DateHeader>
      {encounterList.map(e => (
        <Margin key={e.id}>
          <EncounterReadOnlyView
            key={e.id}
            encounter={e}
            hideDownloadButton={hideDownloadButton}
            patient={patient}
          />
        </Margin>
      ))}
    </EncounterDate>
  )
}

export default EncounterDateView
