import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import SettingsIcon from '@mui/icons-material/Settings';
import EventIcon from '@mui/icons-material/Event';
import { Trans } from '@lingui/macro';
import Button from '@mui/material/Button';
import React, { useState } from 'react';
import { canModifyPlanningHorizon } from '../utils';
import MUIThemeProvider from '../../Shared/MUIThemeProvider';
import { Box, Stack } from '@mui/material';
import RequirePermission from '../../Permissions/containers/RequirePermission';
import { createEncounter } from '../../Encounter/actions';
import { MEDEO_ENCOUNTER_TYPE_SYSTEM } from '../../utils/codes';
import { MEDEO_ENCOUNTER_TYPE_BOOKING } from '../../utils/encounter-type';
import { useDispatch } from 'react-redux';
import usePractitioner from '../../Practitioner/usePractitioner';

const CalendarToolbarActionStack = ({
  eventType,
  onChangeEventType,
  organizationType,
  practitionerType
}) => {
  const dispatch = useDispatch();
  const currentPractitioner = usePractitioner();
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = () => {
    setIsLoading(true);
    dispatch(
      createEncounter(null, currentPractitioner.id, {
        coding: [
          {
            system: MEDEO_ENCOUNTER_TYPE_SYSTEM,
            code: MEDEO_ENCOUNTER_TYPE_BOOKING
          }
        ]
      })
    );
  };

  return (
    <Stack spacing={2} direction="row-reverse">
      {canModifyPlanningHorizon(organizationType, practitionerType) &&
        (eventType === 'appointment' ? (
          <Button
            onClick={() => onChangeEventType('schedule')}
            data-intercom-target="schedule"
            data-test="schedule-btn"
            startIcon={<SettingsIcon />}
          >
            <Trans>Modify planning horizon</Trans>
          </Button>
        ) : (
          <Button
            onClick={() => onChangeEventType('appointment')}
            data-intercom-target="planning"
            data-test="planning-btn"
          >
            <Trans>Back to schedule</Trans>
          </Button>
        ))}

      <RequirePermission action="take" resource="appointment">
        <Button
          onClick={handleClick}
          variant="contained"
          data-testid="booking_appointment_btn"
          disabled={isLoading}
          startIcon={<EventIcon />}
          data-intercom-target="booking_appointment_btn"
        >
          <Trans>Book a slot</Trans>
        </Button>
      </RequirePermission>
    </Stack>
  );
};
const CalendarToolbarNavigationStack = ({ onNavigate, date }) => {
  return (
    <Stack spacing={2} direction="row">
      <Button variant="outlined" onClick={() => onNavigate('TODAY', date)}>
        <Trans>Today</Trans>
      </Button>
      <Button variant="outlined" onClick={() => onNavigate('PREV', date)}>
        <ChevronLeftIcon />
      </Button>
      <Button variant="outlined" onClick={() => onNavigate('NEXT', date)}>
        <ChevronRightIcon />
      </Button>
    </Stack>
  );
};
const CalendarToolbar = ({
  eventType,
  onChangeEventType,
  organizationType,
  practitionerType,
  onNavigate,
  date
}) => {
  return (
    <MUIThemeProvider>
      <Box display="flex" sx={{ mb: 2 }} justifyContent="space-between">
        <CalendarToolbarNavigationStack onNavigate={onNavigate} date={date} />
        <CalendarToolbarActionStack
          eventType={eventType}
          onChangeEventType={onChangeEventType}
          practitionerType={practitionerType}
          organizationType={organizationType}
        />
      </Box>
    </MUIThemeProvider>
  );
};

export default CalendarToolbar;
