import { Trans } from '@lingui/macro'
import React, { useContext, useEffect } from 'react'
import styled from 'styled-components/macro'
import { VideoScreenContext } from '../containers/VideoSequenceManager'
import { UPDATING_SELECTED_INPUT } from '../reducer'

const Main = styled.div`
  font-size: ${props => props.theme.small};
  width: 40rem;
  margin: 0 2rem;
`

const CustomSelect = styled.select`
  max-width: 15em;
  width: 100%;
  padding: 0.5rem;
  border-radius: 0.25rem;
  border-color: ${p => p.theme.gray};

  &:focus,
  &:hover {
    outline: none;
    border-color: ${p => p.theme.ocean};
    cursor: pointer;
  }
`

const MediaDevices = ({ onAfterSubmit }) => {
  const [videoState, dispatch] = useContext(VideoScreenContext)

  /* This useEffect is run when closing the modal.
   * It updates the reducer about the state of the modal. */
  useEffect(() => {
    return () => onAfterSubmit()
  }, [onAfterSubmit])

  const onChangeVideo = e => {
    const value = e.currentTarget.value
    const label = e.currentTarget.textContent
    dispatch({
      type: UPDATING_SELECTED_INPUT,
      payload: {
        selectedVideoInput: {
          value: value,
          label: label
        }
      }
    })
    localStorage.setItem('VideoInput', value)
  }

  const onChangeAudio = e => {
    // the label is later used in the helpers functions for the audioTrack
    // we retrieve the text ("Microphone" or "Stéthoscope") to apply specific options to the track
    // this will prevent larsen or noise cancellation
    const select = e.currentTarget
    const label = select.options[select.selectedIndex].text

    dispatch({
      type: UPDATING_SELECTED_INPUT,
      payload: {
        selectedAudioInput: {
          value: select.value,
          label: label
        }
      }
    })
    localStorage.setItem(
      'AudioInput',
      JSON.stringify({ value: select.value, label: label })
    )
  }

  return (
    <Main>
      <h4>
        <Trans>Video input</Trans>
      </h4>
      <CustomSelect
        name="videoinput"
        value={videoState.deviceOptions.selectedVideoInput.value}
        onChange={onChangeVideo}
      >
        {videoState.deviceOptions.videoinput.map((o, index) => {
          return (
            <option key={index} value={o.value}>
              {o.label}
            </option>
          )
        })}
      </CustomSelect>
      <h4>
        <Trans>Audio input</Trans>
      </h4>
      <CustomSelect
        name="audioinput"
        value={videoState.deviceOptions.selectedAudioInput.value}
        onChange={onChangeAudio}
      >
        {videoState.deviceOptions.audioinput.map((o, index) => {
          return (
            <option key={index} value={o.value}>
              {o.label}
            </option>
          )
        })}
      </CustomSelect>
    </Main>
  )
}
export default MediaDevices
