import React from 'react';
import { useSelector } from 'react-redux';
import { getCurrentOrganization } from '../../Organization/selector';
import {
  FORMATION_IDENTIFIER_SYSTEM,
  FORMATION_IDENTIFIER_VALUE
} from '../../utils/codes';
import Visio from '../components/Visio';
import TrainerVisioPage from './TrainerVisioPage';

const VisioPage = () => {
  const organization = useSelector(getCurrentOrganization);

  // Check whether the organization is a training one
  // If so, it should have the possibility to connect to another organization room
  const isTrainingOrganization = organization.identifier?.some(
    identifier =>
      identifier.system === FORMATION_IDENTIFIER_SYSTEM &&
      identifier.value === FORMATION_IDENTIFIER_VALUE
  );

  if (isTrainingOrganization === false)
    {return (
      <Visio
        identity={`test_${organization.id}`}
        organizationId={organization.id}
      />
    );}
  else {return <TrainerVisioPage organizationId={organization.id} />;}
};

export default VisioPage;
