import React, { useEffect, useReducer } from 'react'
import styled from 'styled-components'
import { faCalendarTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ColumnHeader from './ColumnHeader'

const Flex = styled.main`
  display: flex;
  flex: 1;
  flex-direction: column;
  & > :not(:last-child) {
    margin-bottom: 0.5rem;
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: center;
  align-self: flex-start;

  & > :not(:last-child) {
    margin-bottom: 1rem;
  }

  & > main {
    width: 5rem;
  }
`
const NoAvailability = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${p => p.theme.ebony};
  & > :not(:last-child) {
    margin-bottom: 1rem;
  }
  & > svg {
    color: ${p => p.theme.gray};
  }
  & > span {
    white-space: nowrap;
  }
`

/**
 * @typedef {object} ColumnState
 * @property {number} length - the count of slots
 * @property {number} index - the page index
 * @property {number} pageSize - the page size
 */

/**
 * initializes the state for the column component
 * @param slots - the array of all the slot available for the day
 * @return {ColumnState} state
 */
const init = slots => ({
  index: 0,
  pageSize: 4,
  length: slots.length
})

/**
 *
 * @param {ColumnState} state
 * @param action
 * @return {ColumnState} nextState
 */
const reducer = (state, action) => {
  switch (action.type) {
    case 'limit':
      return { ...state, limit: action.payload }
    case 'setLength':
      return { ...state, length: action.payload }
    default:
      return state
  }
}

/**
 * This component displays slots from a given day
 * @param {Array<Slot>} slots
 * @param {children}
 * @param index - the column index, it is used to compute the corresponding day
 * @return {*}
 * @constructor
 */
const Column = ({ day, slots, limit, children }) => {
  const [, dispatch] = useReducer(reducer, slots, init)
  useEffect(() => {
    dispatch({ type: 'limit', payload: limit })
  }, [limit])

  // We update the length of the column whenever the slots are changing
  useEffect(() => {
    dispatch({ type: 'setLength', payload: slots.length })
  }, [slots.length])

  return (
    <Wrapper>
      <ColumnHeader date={day} />
      {slots.length > 0 ? (
        <Flex>
          {/* If the pharmacist is on the Availability page we show a different
      component where the slots have a different style and are not clickable */}
          {slots.filter((s, i) => limit == null || i < limit).map(children)}
        </Flex>
      ) : (
        <NoAvailability>
          <FontAwesomeIcon icon={faCalendarTimes} />
          <span>----</span>
        </NoAvailability>
      )}
    </Wrapper>
  )
}
Column.defaultProps = {
  slots: []
}

export default Column
