import * as Sentry from '@sentry/react'
import { isEqual } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components/macro'
import EncounterRecap from '../../Encounter/components/EncounterRecap'
import PlanDefinitionAction from './PlanDefinitionAction'
import PlanDefinitionActionDecoration from './PlanDefinitionActionDecoration'
import FinishTeleconsultationButton from '../../Teleconsultation/components/FinishTeleconsultationButton'
import {
  getPlanDefinitionIdentifierValueFromEncounterType,
  getQuestionnaireIdentifierValuesInStringFromEncounterType
} from '../utils'
import QuestionnaireSequencer from '../../Questionnaire/components/QuestionnaireSequencer'
import CaresheetQuestionnaire from '../../Questionnaire/components/CaresheetQuestionnaire'
import { search } from '../../Shared/actions'
import { MEDEO_CARE_SHEET_QUESTIONNAIRE_IDENTIFIER } from '../../utils/codes'
import {
  MEDEO_ENCOUNTER_TYPE_PRE_TELECONSULTATION,
  MEDEO_ENCOUNTER_TYPE_TELECONSULTATION_CONSULTATION
} from '../../utils/encounter-type'
import FallbackCard from '../../Shared/components/FallbackCard'
import { getFirstCodeFromEncounter } from '../../Encounter/utils'
import { PlanDefinitionContext } from '../usePlanDefinition'
import { getPlanDefinitionByEncounterType } from '../selector'

const WideDiv = styled.div`
  width: 100%;
`

const Borderer = styled.div`
  & > div {
    border-top: 1px solid ${p => p.theme.gray};
    padding-bottom: 1rem;
    padding-top: 1rem;
  }
  & > div:first-child {
    border-top: none;
    padding-top: 0;
  }
`

const PlanDefinition = ({
  encounter,
  readOnly = false,
  patient,
  practitioner,
  procedureRequest,
  filterActions,
  defaultState = {
    sequenceIndexOfPage: 0,
    sequenceWorkThrough: { action: [] },
    sequenceResponse: [],
    // if "isIsolatedElementView" is true, show an isolated questionnaire, if false show the main encounter view
    isIsolatedElementView: false,
    questionnaireIdentifier: {
      system: null,
      code: null
    },
    // This questionnaire unique id is used to distinguish the questionnaire responses
    // Answering the same questionnaire type
    // It is for instance necessary for care sheets, which can be several, during teleconsultation
    internalResponseId: null
  }
}) => {
  const [state, setState] = useState(defaultState)
  const reduxDispatch = useDispatch()
  const encounterType = getFirstCodeFromEncounter(encounter)

  const planDefinition = useSelector(
    getPlanDefinitionByEncounterType(encounterType)
  )

  useEffect(() => {
    // When we reload the page on the teleconsultation url, encounterType is null
    // Then encounterType is fetched and updated from the store
    // That's why we check here that the encounterType is not null
    if (planDefinition == null && encounterType != null) {
      reduxDispatch(
        search('PlanDefinition', {
          identifier: getPlanDefinitionIdentifierValueFromEncounterType(
            encounterType
          )
        })
      )
      reduxDispatch(
        search('Questionnaire', {
          identifier: getQuestionnaireIdentifierValuesInStringFromEncounterType(
            encounterType
          )
        })
      )
    }
  }, [encounterType, patient, planDefinition, reduxDispatch])

  // here we update the local state with data (observation, action list...) from the store
  useEffect(() => {
    let actionList = []
    let isColumn = false

    if (state.sequenceWorkThrough?.action?.length !== 0) {
      // if there are actions in the state, we are currently in an encounter.
      // in that case the actions were already loaded from the planDefinition.
      actionList = state.sequenceWorkThrough.action
    } else if (planDefinition != null) {
      // in this last case, we have to load actions from the planDefinition
      actionList = planDefinition.action
    }

    // then apply condition to filter action
    if (
      encounterType === MEDEO_ENCOUNTER_TYPE_PRE_TELECONSULTATION &&
      planDefinition != null &&
      state.sequenceWorkThrough?.action
    ) {
      // This function is used when we have a condition in our actions of PlanDefinition
      // Actually we use it for filter action of Preconsultation Form to adapt if we are
      // on autonomous or assisted consultation. In second time we keep only the symptom
      // questionnaire according to motive that we selected during Booking Form.
      // param : if the param "clinical exam" is "true", the function return the action for
      // an autonomous consultation
      // last param : we pass the actionList from the state, to add the additional exam if exists
      actionList = filterActions(
        planDefinition.action,
        state.sequenceWorkThrough.action
      )
    }
    isColumn = false
    if (encounterType === MEDEO_ENCOUNTER_TYPE_TELECONSULTATION_CONSULTATION) {
      isColumn = true
    }

    // update context when new response are loaded.
    // Do not do it if response is the same and planDefinition not loaded
    if (
      planDefinition != null &&
      (state.sequenceWorkThrough.action.length !== actionList.length ||
        !isEqual(actionList, state.sequenceWorkThrough.action))
    ) {
      setState({
        ...state,
        sequenceWorkThrough: { action: actionList, isColumn: isColumn },
        planDefinitionId: planDefinition.id
      })
    }
  }, [encounterType, state, planDefinition, filterActions])

  // set vars for displaying right components
  // first we check if the sequenceWorkThrough.action is not empty
  // which would cause the recap to appear a brief moment
  // c.f. MED-2337
  const isLastPage =
    state.sequenceWorkThrough.action.length > 0 &&
    state.sequenceIndexOfPage >= state.sequenceWorkThrough.action.length

  let shouldShowButtonFinishConsultation = false
  let actionsToShow = state.sequenceWorkThrough.action

  if (state.sequenceWorkThrough.isColumn) {
    shouldShowButtonFinishConsultation = true
  } else if (!isLastPage && state.sequenceWorkThrough?.action?.length > 0) {
    // ☝️ when the planDefinition is loading parts of the state are not set yet
    // actionsToShow can look like actionsToShow = [undefined]
    // which will break the rest of this component badly 😥
    actionsToShow = [
      state.sequenceWorkThrough.action[state.sequenceIndexOfPage]
    ]
  }

  let step = 'PlanDefinitionAction'
  if (isLastPage && !state.sequenceWorkThrough.isColumn) {
    step = 'EncounterRecap'
  }
  if (state.isIsolatedElementView) {
    step = 'isolatedElementView'
  }

  switch (step) {
    case 'PlanDefinitionAction':
      return (
        <PlanDefinitionContext.Provider value={[state, setState]}>
          <Borderer>
            {actionsToShow.map((a, i) => (
              <WideDiv key={i}>
                <PlanDefinitionActionDecoration action={a} readOnly={readOnly}>
                  <PlanDefinitionAction
                    action={a}
                    encounter={encounter}
                    practitioner={practitioner}
                    patient={patient}
                    readOnly={readOnly}
                    isColumn={state.sequenceWorkThrough.isColumn}
                  />
                </PlanDefinitionActionDecoration>
              </WideDiv>
            ))}
          </Borderer>
          {shouldShowButtonFinishConsultation === true && (
            <FinishTeleconsultationButton state={state} procedureRequest={procedureRequest} encounter={encounter} patient={patient} practitioner={practitioner} />
          )}
        </PlanDefinitionContext.Provider>
      )
    case 'EncounterRecap':
      return (
        <PlanDefinitionContext.Provider value={[state, setState]}>
          <EncounterRecap
            patient={patient}
            practitioner={practitioner}
            encounter={encounter}
          />
        </PlanDefinitionContext.Provider>
      )

    case 'isolatedElementView':
      if (state?.questionnaireIdentifier?.code === MEDEO_CARE_SHEET_QUESTIONNAIRE_IDENTIFIER)
        {return (
          <PlanDefinitionContext.Provider value={[state, setState]}>
            {/* practitioner and patient are passed along for mixpanel purposes*/}
            {/* we could use a saga to prevent drilling props...*/}
            <CaresheetQuestionnaire
              encounterID={encounter.id}
              practitioner={practitioner}
              patient={patient}
              identifier={state.questionnaireIdentifier}
              internalResponseId={state.internalResponseId}
              readOnly={state.readOnly}
              isFromConsultation={true}
            />
          </PlanDefinitionContext.Provider>
        )}
      else
        {return (
          <PlanDefinitionContext.Provider value={[state, setState]}>
            <QuestionnaireSequencer
              internalResponseId={state.internalResponseId}
              identifier={state.questionnaireIdentifier}
              readOnly={state.readOnly}
              isFromConsultation={true}
            />
          </PlanDefinitionContext.Provider>
        )}
    default:
      throw new Error(`cannot load step ${step}`)
  }
}

PlanDefinition.defaultProps = {
  onFinishing: () => {}
}

/**
 * We export the planDefinition component wrapped in the Sentry Error Boundary
 * @param props
 * @return {JSX.Element}
 */
const PlanDefinitionWrapper = props => {
  return (
    <Sentry.ErrorBoundary fallback={FallbackCard}>
      <PlanDefinition {...props} />
    </Sentry.ErrorBoundary>
  )
}

export default PlanDefinitionWrapper
