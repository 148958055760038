import React from 'react'
import * as fromLoinc from '../../utils/loinc'
import { Form, Input, Label } from '../../Components'
import { t, Trans } from '@lingui/macro'
import styled from 'styled-components/macro'
import { useI18n } from '../../utils/I18nHookAdapter'

const LastMealInput = styled(Input)`
  width: auto;
`
const Container = styled.div`
  max-height: 20vh;

  overflow-y: auto;
`

const BetterSelect = styled.select`
  width: 100%;
  padding: 0.5rem;
  border-radius: 0.25rem;
  border-color: ${p => p.theme.gray};

  &:focus,
  &:hover {
    outline: none;
    border-color: ${p => p.theme.ocean};
    cursor: pointer;
  }
`

const displayLabel = (code, shouldShowDiastolic = false) => {
  if (code === fromLoinc.LOINC_BLOOD_PRESSURE_CODE) {
    return shouldShowDiastolic ? t`Diastolic` : t`Systolic`
  }
  return fromLoinc.getLabelFromLoincCode(code)
}
const ObservationSelect = ({ selectedMeasureType, setMeasureType }) => {
  const i18n = useI18n()

  const valueName =
    selectedMeasureType === fromLoinc.LOINC_BLOOD_PRESSURE_CODE
      ? 'sys'
      : 'value'
  const ariaLabelName =
    selectedMeasureType === fromLoinc.LOINC_BLOOD_PRESSURE_CODE
      ? 'systolicBloodPressure'
      : 'value'

  const handleChange = e => {
    const { value } = e.currentTarget
    setMeasureType(value)
  }

  const label = `${i18n._(
    displayLabel(selectedMeasureType)
  )} (${fromLoinc.getUnitFromType(selectedMeasureType)})`

  const diastolicLabel = `${i18n._(
    displayLabel(selectedMeasureType, true)
  )} (${fromLoinc.getUnitFromType(selectedMeasureType)})`

  const placeholder =
    selectedMeasureType === fromLoinc.LOINC_BLOOD_PRESSURE_CODE
      ? i18n._(t`Systolic`)
      : ''

  return (
    <>
      <Form.Row>
        <div>
          <Label>
            <Trans>
              Select the measurement type and enter any necessary values
            </Trans>
          </Label>
          <BetterSelect onChange={handleChange}>
            <option value="" selected disabled>
              {i18n._('Select a value')}
            </option>
            {fromLoinc.DISPLAY_LOINCS.map(o => (
              <option value={o} key={o}>
                {i18n._(fromLoinc.getLabelFromLoincCode(o))}
              </option>
            ))}
          </BetterSelect>
        </div>
      </Form.Row>
      <Container>
        {selectedMeasureType === fromLoinc.LOINC_URINALYSIS_PANEL_CODE ? (
          fromLoinc.getFieldsOfUrineTest().map((field, i) => (
            <Form.Row>
              <BetterSelect
                key={i}
                label={field.placeholder}
                name={field.value}
                required
              >
                {field.options.map(o => (
                  <option value={o} key={o}>
                    {o}
                  </option>
                ))}
              </BetterSelect>
            </Form.Row>
          ))
        ) : (
          <Form.Row>
            <Input
              id={valueName}
              name={valueName}
              aria-label={ariaLabelName}
              label={label}
              type="number"
              min="0"
              max="250"
              step=".01"
              placeholder={placeholder}
              required
            />
            {selectedMeasureType === fromLoinc.LOINC_BLOOD_PRESSURE_CODE && (
              <Input
                id="dia"
                name="dia"
                label={diastolicLabel}
                placeholder={i18n._(t`Diastolic`)}
                type="number"
                min="0"
                max="250"
                step=".01"
                required
              />
            )}
            {selectedMeasureType === fromLoinc.LOINC_BLOOD_GLUCOSE_CODE && (
              <LastMealInput
                label={<Trans>Last Meal</Trans>}
                type="time"
                id="lastMeal"
                name="lastMeal"
                required
              />
            )}
          </Form.Row>
        )}
        <input
          value={fromLoinc.getUnitFromType(selectedMeasureType)}
          id="unit"
          name="observationUnit"
          type="hidden"
        />
      </Container>
    </>
  )
}

export default ObservationSelect
