/*eslint-disable*/
import { combineReducers } from 'redux'
import {
  OBSERVATIONS_RECEIVED,
  OBSERVATION_RECEIVED,
  OBSERVATION_REMOVED,
  API_ERRORED,
  FETCHING_OBSERVATIONS,
  FAIL_RECEIVING_OBSERVATIONS,
  SPECIFIC_OBSERVATIONS_SEARCH_SUCCEEDED,
} from './actions'
import { appendAllUniqueIds, reduceById, removeById } from '../Shared/ducks'
import { FHIR_RESOURCES_RECEIVED } from '../Shared/actions'

export const allIds = (state = [], action) => {
  const { type, payload } = action
  switch (type) {
    case OBSERVATIONS_RECEIVED:
    case FHIR_RESOURCES_RECEIVED:
      return appendAllUniqueIds(state, payload.Observation)
    case OBSERVATION_RECEIVED:
      return appendAllUniqueIds(state, payload.observation)
    case OBSERVATION_REMOVED:
      return state.filter(id => id !== payload.observation.id)
    case API_ERRORED:
      return state
    default:
      return state
  }
}

export const byId = (state = {}, action) => {
  const { type, payload } = action
  switch (type) {
    case OBSERVATIONS_RECEIVED:
    case FHIR_RESOURCES_RECEIVED:
      return reduceById(state, payload.Observation)
    case OBSERVATION_RECEIVED:
      return { ...state, [payload.observation.id]: payload.observation }
    case OBSERVATION_REMOVED:
      return removeById(state, payload.observation)
    case API_ERRORED:
      return state
    default:
      return state
  }
}

export const hasPendingRequests = (state = 0, action) => {
  const { type } = action

  switch (type) {
    case FETCHING_OBSERVATIONS:
      return state + 1
    case OBSERVATIONS_RECEIVED:
    case OBSERVATION_RECEIVED:
    case API_ERRORED:
    case FAIL_RECEIVING_OBSERVATIONS:
    case FHIR_RESOURCES_RECEIVED:
      return Math.max(0, state - 1)
    default:
      return state
  }
}

export default combineReducers({
  allIds,
  byId,
  hasPendingRequests,
})
