import { FC } from 'react'
import { CardContent, CircularProgress, Stack, Typography } from '@mui/material'
import { Trans } from '@lingui/macro'

const LoadingCheckoutCardContent: FC = () => {
  return (
    <CardContent>
      <Stack direction="column" alignItems="center" spacing={2}>
        <Typography>
          <Trans>Loading the booking details</Trans>
        </Typography>
        <CircularProgress size={16} />
      </Stack>
    </CardContent>
  )
}
export default LoadingCheckoutCardContent
