export const DEFAULT_STATE = {
  items: [],
  link: [],
  config: {},
  isLoading: false,
}

export const reducer = (
  getInitialAction,
  loadingMoreAction,
  loadedMoreAction,
  staleAction
) => (state = DEFAULT_STATE, action) => {
  const { payload, type } = action

  switch (type) {
    case getInitialAction:
      return {
        ...state,
        isLoading: true,
        config: action.payload,
      }
    case loadedMoreAction:
      const items = payload.reset
        ? payload.items
        : state.items.concat(payload.items)
      return {
        ...state,
        items: items.filter((e, i, a) => a.indexOf(e) === i),
        link: payload.link,
        isLoading: false,
      }
    case loadingMoreAction:
    case staleAction:
      return {
        ...state,
        isLoading: true,
      }
    default:
      return state
  }
}
