import React, { useContext } from 'react'
import styled from 'styled-components/macro'
import { Label, Input } from '../../Components'
import { getDisplay, onChangeQuestionnaire, getItemByLinkId } from '../utils'
import { QuestionnaireResponseContext } from './Questionnaire'

const BetterSelect = styled.select`
  width: 100%;
  padding: 0.5rem;
  border-radius: 0.25rem;
  border-color: ${p => p.theme.gray};

  &:focus,
  &:hover {
    outline: none;
    border-color: ${p => p.theme.ocean};
    cursor: pointer;
  }
`

const Required = styled.span`
  margin-left: 0.25rem;
  color: ${p => p.theme.ocean};
`

/**
 * Component for display of select questionnaire item
 * We assume that the options, and as a result the responses,
 * can only be of type valueCoding
 */
const QuestionnaireSelect = ({ item, readOnly }) => {
  const [questionnaireResponse, dispatchQuestionnaireResponse] = useContext(
    QuestionnaireResponseContext
  )

  // Now we only support valueCoding for every item of type "choice"
  // That is why we only look for valueCoding values
  const selectedValue =
    getItemByLinkId(questionnaireResponse, item.linkId)?.answer?.[0]
      ?.valueCoding ?? ''

  return (
    <div>
      <Label>
        {getDisplay(item)} {item.required && <Required>*</Required>}
      </Label>
      {readOnly ? (
        <Input
          type="text"
          name={item.linkId}
          readOnly={true}
          label={''} // to prevent error in console we put empty string
          value={selectedValue.display}
          className="fs-block"
        />
      ) : (
        <BetterSelect
          name={item.linkId}
          readOnly={readOnly}
          required={item.required}
          value={selectedValue?.code}
          data-test={item.linkId}
          onChange={e => {
            onChangeQuestionnaire({
              questionnaireResponse: questionnaireResponse,
              dispatchQuestionnaireResponse: dispatchQuestionnaireResponse,
              name: e.target.name,
              value: item.option?.find(
                o => o.valueCoding?.code === e.target.value
              )?.valueCoding,
              type: 'select'
            })
          }}
          className="fs-block"
        >
          <option value="">Choisir une option</option>
          {item.option?.map(o => (
            <option
              value={o.valueCoding?.code}
              key={o.valueCoding?.code}
              data-test={o.valueCoding?.code}
            >
              {o.valueCoding?.display}
            </option>
          ))}
        </BetterSelect>
      )}
    </div>
  )
}

export default QuestionnaireSelect
