import { getCurrentOrganizationId } from '../Auth/selectors'
import { getAllEntities } from '../Shared/selectors'
import { MEDEO_OUTSIDE_COLLABORATOR_CARETEAM_CODE } from './sagas'
import { getTypeAndIdFromLocalReference } from '../utils/fhir'
import { getOrganizationById } from '../Organization/selector'

export const getAllCareTeams = state => getAllEntities(state, 'careTeams')

/**
 * this selector gives the list of organizations participant of the careteam of
 * type "outside collaborators", this list can later be used to get the practitioners
 * from these organizations
 * @param state
 * @returns {Observable<*>}
 */
export const getOutsideOrgsFromCurrentOrg = state => {
  const orgId = getCurrentOrganizationId(state)
  const careTeams = getAllCareTeams(state)

  // outside collaborators are participant of a careTeam with a specific code
  // and with the currentOrganization for managingOrganization

  return (
    careTeams
      .filter(team => {
        const coding = team?.category?.find(
          category =>
            category?.coding?.[0]?.code ===
            MEDEO_OUTSIDE_COLLABORATOR_CARETEAM_CODE
        )?.coding
        return coding != null
      })
      // we flatten the map, in the case there are multiple careteams we will see all outside collaborators
      // as one big list
      // we can definitely improve this part
      .flatMap(team =>
        team?.participant?.map(participant => participant?.member?.reference)
      )
      .map(ref => getTypeAndIdFromLocalReference(ref))
      // filter only participant of type organization
      .filter(([type]) => type === 'Organization')
      // just show the others, so remove the current organization from the list
      .filter(([, id]) => id !== orgId)
      .map(([, id]) => getOrganizationById(id)(state))
      .filter(org => org != null)
    // retrieve the practitioner object from the id
    // we know we have it because the CareTeam was loaded with its participant
    // .map(([, id]) => getPractitionerById(state, id))
    // remove practitioner that may have been removed
    // remove non-active practitioner (due to removing)
    //  .filter(p => p !== null && p.active !== false)
  )
}

/**
 * this selector gives the list of organizations participant of the careteam of
 * type "outside collaborators", this list will be received even if the user is
 * not part of the managingOrganization. Mainly used for Archipel Project
 * @param state
 * @returns {Observable<*>}
 */
export const getOrgMembersOfCareTeam = state => {
  const orgId = getCurrentOrganizationId(state)
  const careTeams = getAllCareTeams(state)

  // outside collaborators are participant of a careTeam with a specific code
  // and with the currentOrganization for managingOrganization

  return (
    careTeams
      .filter(team => {
        const coding = team?.category?.find(
          category =>
            category?.coding?.[0]?.code ===
            MEDEO_OUTSIDE_COLLABORATOR_CARETEAM_CODE
        )?.coding
        return coding != null
      })
      // we flatten the map, in the case there are multiple careteams we will see all outside collaborators
      // as one big list
      // we can definitely improve this part
      .flatMap(team =>
        team?.participant?.map(participant => participant?.member?.reference)
      )
      .map(ref => getTypeAndIdFromLocalReference(ref))
      // filter only participant of type organization
      .filter(([type]) => type === 'Organization')
      // just show the others, so remove the current organization from the list
      .filter(([, id]) => id !== orgId)
      .map(([, id]) => getOrganizationById(id)(state))
      .filter(org => org != null)
  )
}
