import React from 'react'
import styled from 'styled-components/macro'
import { css } from 'styled-components'
import AttachmentFooter from './AttachmentFooter'
import AttachmentHeader from './AttachmentHeader'
import AttachmentAside from './AttachmentAside'
import { transparentize, lighten } from 'polished'

// enabledMixin defines some style for the "enabled" version of the Attachment
// component. Basically it adds the blue hover and the display the footer
// In comparison the default style defines the style for the disabled version
// i.e. when the Attachment is uploading

// Note: Mixins allow you to define styles that can be re-used throughout your stylesheet.
// c.f. https://sass-lang.com/documentation/at-rules/mixin
const enabledMixin = p =>
  p.disabled !== true &&
  css`
    background-color: ${p => lighten(0.2, p.theme.gray)};
    &:hover {
      background: ${transparentize(0.75, p.theme.ocean)};
      border-color: ${p.theme.ocean};
      & > ${AttachmentFooter.ControlFooter} {
        transition: all 300ms ease-in-out;
        transform: translateY(0);
      }
    }
  `

// this Styled Component defines the style for the Attachment Component
// it was formerly known as GrayBox :)
const AttachmentBox = styled.div`
  display: flex;
  height: 8rem;
  width: 8rem;
  border: 1px solid transparent; // shown only while hovered
  border-radius: 0.25rem;
  background-color: ${p => p.theme.cream};
  box-shadow: ${p => p.theme.boxShadow};
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;

  & > aside {
    flex: 1;
    justify-content: center;
    align-items: center;
  }
  & > header {
    position: absolute;
    top: 0;
  }
  & > footer {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
  }
  & > ${AttachmentFooter.ControlFooter} {
    transform: translateY(50px);
    transition: all 300ms ease-in-out;
    & > :not(:last-child) {
      margin-right: 0.25rem;
    }
  }
  overflow: hidden;
  // enable the hover + controls when the component is not disabled
  // disabled props is expected on this component !
  ${p => enabledMixin(p)}
`

/**
 * Attachment component displays a gray box in the UI. This box is a representation
 * of a Binary file. this component changes its behavior depending on the state
 * of the Binary file:
 * <ul>
 *   <li>the file is uploading, it shows progress and the user cannot interact with it </li>
 *   <li>the file is uploaded, it shows some details like title and type
 *       when hovered controls appear and let the user select an action like download, print and so on...
 *   </li>
 * </ul>
 *
 * Note: the uploading state is defined using the url of the attachment. if no url is present
 * the file is uploading and the progress bar is displayed.
 *
 * @param attachment
 * @param progress
 * @returns {*}
 * @constructor
 */
const Attachment = ({ attachment = null, progress, children }) => {
  // this component expects the attachment to target a Binary resource like the following:
  //   {
  //     "contentType": "image/jpeg",
  //     "url": "https://www.urlToMy/Binary/file",
  //     "title": "download.jpeg",
  //     "creation": "2020-04-02T11:00:55"
  //   }

  // There is a little between the moment the progress reaches 1 - meaning the
  // upload to the server has been completed and the moment the location is
  // returned. (see Attachment input for more details)
  // Thus disabled is set using url and not the progress...
  const disabled = attachment?.url == null

  return (
    <AttachmentBox disabled={disabled}>
      <AttachmentHeader attachment={attachment} />
      {/* aside tag is preferred as main because the icon it displays is not the most important
      thing on this component.*/}
      <AttachmentAside attachment={attachment} />
      {/*
       we use render props here (children) to decouple the controls from this component.
       It lets the parent component chose the implementation details.
       It is required because Attachment handling differs if it come from a DocumentReference
       or a Questionnaire.item. for instance if we want to delete the attachment
       we need a different handler :/
       */}
      <AttachmentFooter attachment={attachment} progress={progress}>
        {children}
      </AttachmentFooter>
    </AttachmentBox>
  )
}

Attachment.defaultProps = {
  documentReferences: {
    description: 'download.jpg'
  }
}
export default Attachment
