import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components/macro'
import { Input } from '../../Components'
import { search } from '../../Shared/actions'
import { createAllergyIntelorance, removeAllergyIntelorance } from '../actions'
import { allergyBuilder } from '../utils'
import isEqual from 'lodash/isEqual'
import Tag from '../../Shared/components/Tag'
import { ENTER_KEY } from '../../utils/codes'
import { getAllergiesBypatientID } from '../ducks'

const Container = styled.div`
  width: 100%;
  position: relative;
`

const ListContainer = styled.div`
  width: 100%;
  margin-bottom: ${p => p.theme.spacing.medium};
  min-height: 5rem;
  border-radius: 0.25rem;
  border: 1px solid ${p => p.theme.gray};
  background-color: ${p => p.theme.cream};
  display: flex;
  flex-flow: row wrap;
  padding: ${p => p.theme.spacing.small};
  flex: 1;
`

// This component is use without a Questionnaire or Yaml Form. We use it mainly
// for the edit patient form.
const AllergyTagInput = ({ name, placeholder, patientID }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(
      search('AllergyIntolerance', {
        patient: patientID
      })
    )
  }, [patientID, dispatch])

  const allergies = useSelector(getAllergiesBypatientID(patientID))

  const ref = useRef(allergies)

  const [options, setOptions] = useState(allergies)
  const [value, setValue] = useState('')

  // we update the options when we add new one or we delete one
  useEffect(() => {
    if (!isEqual(ref.current, allergies)) {
      setOptions(allergies)
      ref.current = allergies
    }
  }, [allergies])

  const onSubmit = e => {
    e.preventDefault()
    const trimmedValue = (value || '').trim()
    if (trimmedValue !== '') {
      const newAllergy = allergyBuilder(trimmedValue, patientID)
      dispatch(createAllergyIntelorance(newAllergy))
    }
    setValue('')
  }

  const handleTagRemove = item => {
    dispatch(removeAllergyIntelorance(item))
  }

  return (
    <Container>
      <ListContainer>
        {options.map((item, i) => {
          const display = item?.code?.text
          return (
            <Tag
              key={i}
              value={display}
              onRemove={() => handleTagRemove(item)}
            />
          )
        })}
      </ListContainer>
      <Input
        type="text"
        backgroundColor="white"
        label=""
        value={value}
        name={`${name}-input`}
        onKeyPress={e => {
          if (e.which === ENTER_KEY) {
            return onSubmit(e)
          }
        }}
        onChange={e => setValue(e.target.value)}
        onBlur={onSubmit}
        autoComplete="off"
        placeholder={`+  ${placeholder}`}
      />
    </Container>
  )
}

export default AllergyTagInput
