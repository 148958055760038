export const getAllQuestionnaireResponse = state =>
  state.QuestionnaireResponse.allIds.map(
    id => state.QuestionnaireResponse.byId[id]
  )

export const getQuestionnaireResponseByencounterID = (state, encounterID) => {
  if (encounterID != null) {
    return getAllQuestionnaireResponse(state).filter(
      q => q.context && q.context.reference === `Encounter/${encounterID}`
    )
  } else {
    return null
  }
}

export const getStatusCerfasUploaded = state => {
  return state.encounters.cerfasUploaded
}
