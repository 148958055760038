export const MEDEO_PROCEDURE_REQUEST_CODE_SYSTEM =
  'http://medeo.io/fhir/ValueSet/procedure-code';
export const MEDEO_ENCOUNTER_TYPE_SYSTEM =
  'http://medeo.io/fhir/ValueSet/encounter-type';
export const MEDEO_PROCEDURE_REQUEST_REMOTE_CONSULTATION_CODE =
  'teleconsultation';
export const MEDEO_PROCEDURE_REQUEST_BOOKING_APPOINTMENT_CODE =
  'booking-appointment';

// AM stands for Assurance Maladie
// in France, it is used to identified the Requester of an Order and should appear on every
// Order along with the RPPS.
// see https://www.ameli.fr/fileadmin/user_upload/documents/flash_rpps_prescrits_cpam93.pdf
export const MEDEO_AM_IDENTIFIER_SYSTEM =
  'http://medeo.io/fhir/Identifier/practitioner-am';
export const MEDEO_FINESS_IDENTIFIER_SYSTEM =
  'http://medeo.io/fhir/Identifier/practitioner-finess';
export const MEDEO_NIR_IDENTIFIER_SYSTEM =
  'http://medeo.io/fhir/Identifier/patient-nir';
export const MEDEO_STRIPE_CUSTOMER_IDENTIFIER_SYSTEM =
  'http://medeo.io/fhir/Identifier/stripe-customer-id';

// RPPS stands for repertoire partagé des professionnels de santé
// in France, each practitioner is identified with this number
// it's unique and only practitioner (from nurse to specialist) have one
// see https://www.ameli.fr/rhone/medecin/exercice-liberal/vie-cabinet/rpps/rpps
export const MEDEO_RPPS_IDENTIFIER_SYSTEM =
  'http://medeo.io/fhir/Identifier/numero-rpps';

export const MEDEO_STRIPE_IDENTIFIER_SYSTEM =
  'http://medeo.io/fhir/Identifier/stripe';

export const FINESS_HEALTH_IDENTIFIER_SYSTEM =
  'http://finess.sante.gouv.fr/fhir/Identifier/finessEt';

export const THIRD_PARTY_PAYER_IDENTIFIER_SYSTEM =
  'http://medeo.io/fhir/Identifier/third-party-payer';
export const THIRD_PARTY_PAYER_NONE = 'none';
export const THIRD_PARTY_PAYER_AM_PART = 'am-part';
export const THIRD_PARTY_PAYER_FULL = 'full';

// Schedule and appointment related codes
export const MEDEO_SLOT_IDENTIFIER_SYSTEM = 'http://medeo.io/fhir/Slot/type';
export const MEDEO_APPOINTMENT_IDENTIFIER_SYSTEM =
  'http://medeo.io/fhir/appointment/type';
export const MEDEO_APPOINTMENT_TYPE_TELECONSULTATION = 'remote-consultation';

export const ESANTE_OCCUPATION_SYSTEM =
  'https://mos.esante.gouv.fr/NOS/TRE_R37-TypeProfessionFonction/';
export const ESANTE_SPECIALTY_SYSTEM =
  'https://mos.esante.gouv.fr/NOS/TRE_R38-SpecialiteOrdinale/';
export const MEDEO_QUESTIONNAIRE_IDENTIFIER =
  'http://medeo.io/fhir/Identifier/questionnaire';
// Organization codes
export const MEDEO_ORGANIZATION_TYPE_IDENTIFIER_SYSTEM =
  'http://medeo.io/fhir/ValueSet/organization-type';
export const MEDEO_ORGANIZATION_DOCTOR_OFFICE_VALUE = 'DOCTOR_OFFICE';

export const FORMATION_IDENTIFIER_SYSTEM =
  'http://medeo.io/fhir/Organization/formation';
export const FORMATION_IDENTIFIER_VALUE = 'formation';

export const MEDEO_MODEL_SIGNATURE_IDENTIFIER_VALUE = 'signature';
export const MEDEO_SELECT_PERFORMER_ACTION_TYPE = 'selectPractitioner';
export const MEDEO_CODE_FOR_PRACTITIONER_DOCUMENT_MODEL_QUESTIONNAIRE =
  'practitioner-model';
export const MEDEO_TELECONSULTATION_IDENTIFIER = 'teleconsultation-form';

export const MEDEO_CARE_SHEET_QUESTIONNAIRE_IDENTIFIER = 'caresheet-v2';
export const MEDEO_SICK_LEAVE_QUESTIONNAIRE_IDENTIFIER = 'sick-leave';

export const MEDEO_PREAMBLE_QUESTIONNAIRE_IDENTIFIER_V2 = 'preamble-v2';
export const MEDEO_MOTIVES_QUESTIONNAIRE_IDENTIFIER = 'motives';
export const KIOSK_MOTIVES_QUESTIONNAIRE_IDENTIFIER = 'motive-kiosk';
export const MEDEO_PAYMENT_METHOD_CHOICE_IDENTIFIER = 'payment-method';
export const MEDEO_SIMPLE_ADD_PATIENT_QUESTIONNAIRE_IDENTIFIER =
  'simple-add-patient';
export const MEDEO_SELECT_PATIENT_QUESTIONNAIRE_IDENTIFIER = 'select-patient';
export const MEDEO_DOCUMENT_IDENTIFIER = 'document';
export const MEDEO_TELECONSULTATION = 'teleconsultation';
export const MEDEO_DRUG_PRESCRIPTION_IDENTIFIER = 'drug-prescription';
export const MEDEO_PARAMEDICAL_PRESCRIPTION_IDENTIFIER =
  'paramedical-prescription';

// code for questions. Use with getQuestionNumberFromQuestionnaireByName
export const MOTIVE_QUESTION_IN_MOTIVES_QUESTIONNAIRE =
  'MOTIVE_QUESTION_IN_MOTIVES_QUESTIONNAIRE';

export const MEDEO_QUESTIONNAIRE_ITEM_CODE_SYSTEM =
  'http://medeo.io/fhir/ValueSet/item-code';
export const MEDEO_QUESTIONNAIRE_ITEM_TYPE_CODE_SYSTEM =
  'http://medeo.io/fhir/ValueSet/item-type-code';
export const MEDEO_CODE_FOR_CARESHEET_PRISE_EN_CHARGE =
  'caresheet-prise-en-charge';
export const MEDEO_CODE_FOR_CARESHEET_MALADIE = 'caresheet-maladie';
export const MEDEO_CODE_FOR_TP_AMO_OPTION = 'tp-amo-option';
export const MEDEO_CODE_FOR_TP_AMO_VALUE = 'tp-amo-value';
export const MEDEO_CODE_FOR_TP_AMC_OPTION = 'tp-amc-option';
export const MEDEO_CODE_FOR_TP_AMC_VALUE = 'tp-amc-value';
export const MEDEO_CODE_FOR_C2S = 'c2s';
export const MEDEO_CODE_FOR_ALD = 'ald';
export const MEDEO_CODE_FOR_INVALIDITY = 'invalidity';
export const MEDEO_CODE_FOR_AME = 'ame';
export const MEDEO_CODE_FOR_L212 = 'l212';
export const MEDEO_CODE_FOR_THIRD_PARTY_ATTACHMENT = 'third_party_attachement';
export const MEDEO_LINK_ID_FOR_SELECT_PATIENT_RESPONSE = 'patient-id';
export const MEDEO_CODE_FOR_MOTIVE = 'motive';
export const MEDEO_CODE_FOR_CLINICAL_EXAM = 'clinical-exam';

export const MEDEO_CODING_SYSTEM = 'http://medeo.io';
// codes for tag input like AllergyTagInput MedicationTagInput or TagInput
export const ENTER_KEY = 13;
export const MEDEO_ADMINISTRATOR_CODE = 'administration-medeo';

// Payment Notice
export const MEDEO_PAYMENT_METHOD_SYSTEM =
  'http://medeo.io/fhir/PaymentNotice/payment-method';
export const MEDEO_PAYMENT_STATUS_SYSTEM =
  'http://medeo.io/fhir/PaymentNotice/payment-status';
export const MEDEO_PAYMENT_AMOUNT_SYSTEM =
  'http://medeo.io/fhir/PaymentNotice/payment-amount';
export const MEDEO_CHARGE_ID_SYSTEM =
  'http://medeo.io/fhir/PaymentNotice/charge-id';
export const FHIR_EXTENSION_MIME_TYPE =
  'http://hl7.org/fhir/StructureDefinition/mimeType';
export const FHIR_EXTENSION_MAX_OCCURS =
  'http://hl7.org/fhir/StructureDefinition/questionnaire-maxOccurs';
export const FHIR_EXTENSION_NUMBER_OF_PARTICIPANTS =
  'http://hl7.org/fhir/StructureDefinition/number-of-participants';

export const MEDEO_ORGANIZATION_LOGO_URL =
  'https://medeo.io/StructureDefinition/organization/logo';

export const MEDEO_QUESTIONNAIRE_TAG =
  'http://hl7.org/fhir/StructureDefinition/questionnaire-tag';

// Kiosk
export const KIOSK_ORIGIN = 'kiosk'
export const IPM_KIOSK_ORIGIN = 'kiosk_ipm'

// Billing codes based on location.
export const MEDEO_BILLING_CODE_SYSTEM_METROPOLE = 'FR-MET'
export const MEDEO_BILLING_CODE_SYSTEM_DOM = 'FR-DOM'
