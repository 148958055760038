//@ts-nocheck
import { Redirect, Router } from '@reach/router'
import styled from 'styled-components/macro'
import BookingSelectSlotForm from '../components/BookingSelectSlotForm'
import AddOrSelectPatient from '../components/AddOrSelectPatient'
import { Trans } from '@lingui/macro'
import { Card } from '../../Components'
import MotiveForm from '../components/MotiveForm'
import Recap from './Recap'
import { BookingContextProvider } from './BookingContext'
import { Patient } from 'fhir-stu3'
import { RouteComponentProps } from '@reach/router'
import  { FC } from 'react'

const CustomCard = styled(Card)`
  padding: 0;
  margin: auto;
  max-width: 54rem;
`
const CustomCardHeader = styled(Card.Header)`
  margin: 2rem 2.5rem 0 2.5rem !important;
  padding: 0 0 1rem 0;
`

const Container = styled.div`
  margin: 0 auto;
`

interface BookingRouterProps extends RouteComponentProps {
  patient?: Patient
}
const BookingRouter: FC<BookingRouterProps> = props => {
  const { patient } = props
  return (
    <Container>
      <BookingContextProvider patient={patient}>
        <CustomCard>
          <CustomCardHeader>
            <h1>
              <Trans>Making Appointment</Trans>
            </h1>
          </CustomCardHeader>
          <Router>
            <BookingSelectSlotForm path="slots" />
            <MotiveForm path="motive" />
            <AddOrSelectPatient path="patient" />
            <Recap path="recap" />
            <Redirect from="/" to="./slots" default noThrow />
          </Router>
        </CustomCard>
      </BookingContextProvider>
    </Container>
  )
}
export default BookingRouter
