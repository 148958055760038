import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components/macro'
import { ASKING_FOR_PIN_CODE } from '../../Practitioner/actions'
import { CONTEXT_TELECONSULTATION_FINISHED } from '../../Practitioner/containers/Auth'
import VideoSequenceManager from '../containers/VideoSequenceManager'

const Container = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: grey;
`

const TeleconsultationForPatient = (props) => {
  const reduxDispatch = useDispatch()

  // return a function in the useEffect will apply the function on the unmounted event of the component
  // dispatching this action will make sure that we ask the PIN of the practitioner again
  useEffect(() => {
    return () => {
      reduxDispatch({
        type: ASKING_FOR_PIN_CODE,
        payload: { context: CONTEXT_TELECONSULTATION_FINISHED }
      })
    }
  })

  // In this page we hide Intercom
  window.Intercom?.('update', {
    hide_default_launcher: true
  })

  // And show it back when quitting the page
  useEffect(() => {
    return () => {
      if (window.Intercom) {
        window.Intercom?.('update', {
          hide_default_launcher: false
        })
      }
    }
  })

  return (
    <Container>
      <VideoSequenceManager
        patientID={props.patientID}
        patientSide={true}
        organizationName={props.organizationName}
        identifier={props.identifier}
      />
    </Container>
  )
}

export default TeleconsultationForPatient
