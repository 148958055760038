import { Form } from '../../Components'
import { Trans } from '@lingui/macro'
import BetterSelect from '../../Shared/components/BetterSelect'
import React, { useContext } from 'react'
import {
  THIRD_PARTY_PAYER_AM_PART,
  THIRD_PARTY_PAYER_FULL,
  THIRD_PARTY_PAYER_IDENTIFIER_SYSTEM,
  THIRD_PARTY_PAYER_NONE
} from '../../utils/codes'
import { EditPractitionerContext } from '../container/EditPractitionerForm'
import { pushIdentifierChange } from '../utils'
import RequiredLabel from '../../Shared/components/RequiredLabel'

const ThirdPartyPayerSelect = () => {
  const [resource, setResource] = useContext(EditPractitionerContext)
  const identifiers = resource.practitioner.identifier ?? []

  const thirdPartyPayerIdentifier =
    identifiers.find(i => i.system === THIRD_PARTY_PAYER_IDENTIFIER_SYSTEM) ??
    null

  const handleChange = e => {
    const { value } = e.target

    const index = identifiers.findIndex(
      i => i.system === THIRD_PARTY_PAYER_IDENTIFIER_SYSTEM
    )

    if (index >= 0) {
      identifiers[index].value = value
    } else {
      pushIdentifierChange({ identifiers, name: 'third-party-payer', value })
    }
    setResource({
      ...resource,
      practitioner: {
        ...resource.practitioner,
        identifier: identifiers
      }
    })
  }

  return (
    <Form.Row>
      <div>
        <RequiredLabel required>
          <Trans>Accepts third party payer</Trans>
        </RequiredLabel>
        <BetterSelect
          name="third-party-payer"
          onChange={handleChange}
          required
          defaultValue={thirdPartyPayerIdentifier?.value}
        >
          <option value="">Veuillez selectionner une option</option>
          <option value={THIRD_PARTY_PAYER_NONE}>Aucun</option>
          <option value={THIRD_PARTY_PAYER_AM_PART}>
            Part Assurance Maladie
          </option>
          <option value={THIRD_PARTY_PAYER_FULL}>Intégral</option>
        </BetterSelect>
      </div>
    </Form.Row>
  )
}

export default ThirdPartyPayerSelect
