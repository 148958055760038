import React from 'react'
import {
  displayMedication,
  queryMedicationFromES
} from '../../MedicationStatement/utils'
import MultipleSearchInput from '../../Shared/components/MultipleSearchInput'
import { Trans } from '@lingui/macro'
import MedicationDataListItem from '../components/MedicationDataListItem'
import DefaultMedicationDataListItem from '../components/DefaultMedicationDataListItem'
import { useDispatch, useSelector } from 'react-redux'
import {
  getMedicationFromSubjectId,
  getMedicationStatementFromSubjectId
} from '../../MedicationStatement/selectors'
import { getTypeAndIdFromLocalReference } from '../../utils/fhir'
import { updateMedicationStatements } from '../../MedicationStatement/actions'

const initialState = {
  query: '',
  datalist: [],
  added: [],
  deleted: []
}

// MedicationTagInput keep the same process than QuestionnaireMedicationStatementForm but we remove
// the process of submitting a form. We keep only MultipleSearchInput and added a process
// to do fhir request when add or remove a medication. This component is mainly use in EditPatientForm
// If you need to use it in Questionnaire or Yaml you can check QuestionnaireMedicationStatement or
// YamlMedicationStatementInput
const MedicationTagInput = ({ patient }) => {
  const [state, setState] = React.useState(initialState)
  const dispatch = useDispatch()
  const patientID = patient?.id
  // medications are required in order to display this Component correctly
  // the medication are shown but not the statement.
  // i.e. we show Doliprane, Advil and not "Doliprane has been prescribed to this patient"
  const medications = useSelector(s => getMedicationFromSubjectId(s, patientID))

  // when the users interacts with this component, we need to update the statements
  // either we add or we delete statements from the database
  // The statements are used in the onValueChange handler which is called
  // everytime something is happening with the multipleSearchInput.
  const statements = useSelector(s =>
    getMedicationStatementFromSubjectId(s, patientID)
  )

  // Custom effect to fetch the hits frm AWS ES
  // everytime the user types in a character
  React.useEffect(() => {
    if (state.query.length < 3) {return}
    queryMedicationFromES(state.query).then(datalist => {
      setState(s => ({ ...s, datalist }))
    })
  }, [state.query])

  // this function is used when the user write in the input
  const handleInputChange = e => {
    setState(s => ({ ...s, query: e.target.value }))
  }

  /**
   * get the current Medications as selectedOptions and update the
   * related MedicationStatement. It creates two list:
   * - deleted MedicationStatement, elements which are going to be updated to
   *   {...statement, active: false }
   * - added Medication, which are new elements that we are going to
   *   add to the list
   * @param e
   */
  const handleValueChange = e => {
    // MultipleSearchInput return a stub event as a param
    // looking like this:
    // e = { target : { selectedOptions : Array(12) } }
    // the selected options contain the selected items from the datalist
    const { selectedOptions } = e.target

    // first, we subtract the selected options to the medication list.
    // the medication list is used as defaultValue and contains the initial set of
    // medication
    // Then we map the medication to the associated statement
    // Reminder: a MedicationStatement looks like this:
    // {
    //   resourceType: "MedicationStatement",
    //   medicationReference: {
    //    reference: "Medication/1234",
    //   }
    //   /* clipped for brevity */
    // }
    const deletedStatements = medications
      .filter(option => !selectedOptions.includes(option))
      .map(medication =>
        statements.find(statement => {
          const reference = statement?.medicationReference?.reference
          const [, id] = getTypeAndIdFromLocalReference(reference)
          return id === medication.id
        })
      )
      .filter(statement => statement != null)

    // Secondly, we create the added list
    // i.e. the medications present in the selected options but not in the
    // initial set.
    // here we don't convert them to statements as we need to perform checks on
    // the database before.
    // these checks will occur through a saga that is triggered when this form
    // is submitted. See the medicationStatement sagas for more details
    const addedMedications = selectedOptions.filter(
      option => !medications.includes(option)
    )

    setState(s => ({
      ...s,
      deletedStatements,
      addedMedications
    }))
    dispatch(
      updateMedicationStatements({
        patient,
        addedMedications,
        deletedStatements
      })
    )
  }

  // Custom effect to fetch the hits frm AWS ES
  // everytime the user types in a character
  React.useEffect(() => {
    if (state.query.length < 3) {return}
    queryMedicationFromES(state.query).then(datalist => {
      setState(s => ({ ...s, datalist }))
    })
  }, [state.query])

  return (
    <MultipleSearchInput
      label={<Trans>Medication List</Trans>}
      defaultDatalist={state.datalist}
      onInputChange={handleInputChange}
      extract={displayMedication}
      DataListItem={MedicationDataListItem}
      DefaultDataListItem={DefaultMedicationDataListItem}
      defaultValue={medications}
      onChange={handleValueChange}
    />
  )
}

export default MedicationTagInput
