import { useSelector } from 'react-redux'
import { getOutsideOrgsFromCurrentOrg } from '../../CareTeam/selectors'
import React from 'react'
import styled from 'styled-components/macro'

const Container = styled.div`
  padding-top: 2rem;

  & > :not(:last-child) {
    margin-bottom: 1rem;
  }
`

const OrganizationsList = ({ children }) => {
  const outsideCollaboratorsOrganizations = useSelector(
    getOutsideOrgsFromCurrentOrg
  )
  return (
    <Container>{outsideCollaboratorsOrganizations.map(children)}</Container>
  )
}

export default OrganizationsList
