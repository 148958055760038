import React from 'react'
import styled from 'styled-components/macro'
import moment from 'moment'

const ValueDiv = styled.div``

const DateTimeAnswer = ({ value }) => {
  const formatedTime = moment(value).format('MM/DD/YYYY, h:mm:ss a')

  return <ValueDiv>{formatedTime}</ValueDiv>
}

export default DateTimeAnswer
