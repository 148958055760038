import React, { useContext } from 'react'
import { Form, Card, Button } from '../../Components'
import styled from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { EditPatientContext } from '../containers/EditPatientForm'
import { useDispatch, useSelector } from 'react-redux'
import { editPatient } from '../actions'
import { getOrganizationById } from '../../Organization/selector'
import { getPractitionerById } from '../../Practitioner/ducks'

const CustomCard = styled(Card)`
  margin-top: 1rem;
  padding: 1rem;
  width: 100%;
  height: 3rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: lightgray;
`

const Left = styled.div``

const Right = styled.div``

const GeneralPractitionerInputRow = ({ generalPractitioner }) => {
  const reduxDispatch = useDispatch()
  const [resource, setResource] = useContext(EditPatientContext)
  const display = generalPractitioner?.display
  let name = {}
  let id = '0'
  if (generalPractitioner.reference) {
    id = generalPractitioner.reference.split('/')[1]
  }

  const practitionerFhir = useSelector(getPractitionerById(id))
  const organizationFhir = useSelector(getOrganizationById(id))

  try {
    if (practitionerFhir) {
      const family = practitionerFhir?.name?.[0]?.family
      const given = practitionerFhir?.name?.[0]?.given?.[0]
      name = family + ' ' + given
    } else if (organizationFhir) {
      name = organizationFhir?.name
    } else {
      name = JSON.parse(display).name
    }
  } catch (e) {
    name = 'Nous ne pouvons pas afficher ce champ'
  }

  const handleClick = () => {
    // we prevent the variable error for the some method with an empty array
    const generalPractitioner = resource.generalPractitioner || []
    let patientUpdated = resource
    // with this condition we check if the generalPractitioner that we
    // try to delete is a organization or a practitioner and we change
    // the newGeneralPractitioner array. This resource will be used
    // to update context and patient resource.
    if (organizationFhir) {
      const newGeneralPractitioner = generalPractitioner.filter(
        gp => gp.reference !== `Organization/${organizationFhir.id}`
      )
      // we update the context and dispatch without using resource context otherwise
      // we can't use the context in editPatient action cause by async trouble
      patientUpdated = {
        ...patientUpdated,
        generalPractitioner: newGeneralPractitioner
      }
      setResource(patientUpdated)
      reduxDispatch(editPatient(patientUpdated))
    } else if (practitionerFhir) {
      const newGeneralPractitioner = generalPractitioner.filter(
        gp => gp.reference !== `Practitioner/${practitionerFhir.id}`
      )
      // we update the context and dispatch without using resource context otherwise
      // we can't use the context in editPatient action cause by async trouble
      patientUpdated = {
        ...patientUpdated,
        generalPractitioner: newGeneralPractitioner
      }
      setResource(patientUpdated)
      reduxDispatch(editPatient(patientUpdated))
    }
  }

  // actually the id is equal to a string so we are obligate to put null as string
  return id === 'null' ? null : (
    <>
      <Form.Row>
        <CustomCard>
          <Left>{name}</Left>
          <Right>
            <Button
              type="button"
              color="ocean"
              variant="outline"
              onClick={handleClick}
            >
              <FontAwesomeIcon icon={faTrash} />
            </Button>
          </Right>
        </CustomCard>
      </Form.Row>
    </>
  )
}

export default GeneralPractitionerInputRow
