import React from 'react'
import styled from 'styled-components/macro'
//@ts-ignore
import { Card, Spinner } from '../../Components'

const CustomCard = styled(Card)`
  padding: 0;
  margin: auto;
  max-width: 54rem;
  & > div {
    padding: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`
const LoadingCardContent = () => (
  <CustomCard>
    <div>
      <Spinner />
    </div>
  </CustomCard>
)

export default LoadingCardContent
