import { Trans } from '@lingui/macro'
import {
  AlertTitle,
  Button,
  CardActions,
  CardContent,
  Typography
} from '@mui/material'
import { Link, RouteComponentProps } from '@reach/router'
import { PaymentIntent } from '@stripe/stripe-js/types/api/payment-intents'
import { FC, useEffect } from 'react'
import { useQueryClient } from 'react-query'
import useQueryPaymentNoticeByAppointmentId from '../Appointment/hooks/useQueryPaymentNoticeByAppointementId'
import useUpdatePaymentNotice from '../PaymentNotice/hooks/useUpdatePaymentNotice'
import CustomAlert from './CustomAlert'
import { useCheckoutDetails, useRetrievePaymentStatus } from './hooks'
import LoadingCheckoutCardContent from './LoadingCheckoutCardContent'

const useUpdatePaymentNoticeOnSuccess = (appointmentId: string) => {
  const queryClient = useQueryClient()
  const { data: paymentNotice } = useQueryPaymentNoticeByAppointmentId(
    appointmentId
  )
  const { mutate } = useUpdatePaymentNotice()

  useEffect(() => {
    if (paymentNotice != null) {
      mutate(paymentNotice, {
        onSuccess: () => {
          return queryClient.invalidateQueries(['PaymentNotice'])
        }
      })
    }
  }, [mutate, queryClient, paymentNotice])
}

const SuccessCardContent: FC<{
  status: PaymentIntent.Status | null
  appointmentId: string
}> = ({ status, appointmentId }) => {
  useUpdatePaymentNoticeOnSuccess(appointmentId)
  const { data } = useCheckoutDetails(appointmentId)
  if (data == null) {
    throw new Error('data should be defined')
  }
  const { patient } = data
  return (
    <>
      <CardContent>
        {status === 'succeeded' && (
          <Typography gutterBottom variant="h5" component="div">
            L'empreinte a été réalisée avec succès.
          </Typography>
        )}
      </CardContent>
      <CardActions style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          variant="contained"
          component={Link}
          to={`/patient/${patient.id}/history`}
        >
          <Trans>Continue</Trans>
        </Button>
      </CardActions>
    </>
  )
}
const ErrorCardContent: FC<{ status: PaymentIntent.Status | null }> = ({
  status
}) => {
  return (
    <>
      <CardContent>
        <CustomAlert severity="error" variant="outlined">
          <AlertTitle>
            <Trans>An error occured</Trans>
          </AlertTitle>
          <Trans>Get in touch with the our engineers.</Trans>{' '}
          {status != null ? (
            <>
              <Trans>Payment ended up with the status: </Trans> {status}
            </>
          ) : (
            <Trans>Payment ended up with no status</Trans>
          )}
        </CustomAlert>
      </CardContent>
      <CardActions>
        <Button variant="contained" component={Link} to="../">
          <Trans>Go back</Trans>
        </Button>
      </CardActions>
    </>
  )
}

interface AfterValidationContainerProps extends RouteComponentProps {
  appointmentId?: string
}

const AfterValidationContainer: FC<AfterValidationContainerProps> = ({
  appointmentId
}) => {
  if (appointmentId == null) {
    throw new Error('appointmentId should be defined')
  }
  const { isError, isLoading, status } = useRetrievePaymentStatus(appointmentId)
  if (isLoading) {
    return <LoadingCheckoutCardContent />
  }
  if (isError) {
    return <ErrorCardContent status={status} />
  }
  return (
    <SuccessCardContent
      status={status}
      appointmentId={appointmentId as string}
    />
  )
}

export default AfterValidationContainer
