import { Spinner, Table } from '../../Components'
import { Trans } from '@lingui/macro'
import { useNavigate } from '@reach/router'
import { useSelector } from 'react-redux'
import React from 'react'
import styled from 'styled-components/macro'

import { getCurrentOrganizationId } from '../../Auth/selectors'
import { useSearch } from '../../Shared/hooks'

const Small = styled.div`
  font-size: small;
  color: ${p => p.theme.gray};
`
const Flex = styled.td`
  display: flex;
  flex-direction: column;
`
const Row = styled.tr`
  & > td,
  & > th {
    padding: 0.5rem;
  }
  & > td {
    &:last-of-type {
      text-align: right;
    }
  }
`
const Container = styled.div`
  max-width: 50rem;
  margin: auto;
  & > :not(:last-child) {
    margin-bottom: 1rem;
  }
`

const DeviceList = () => {
  const navigate = useNavigate()
  const orgId = useSelector(getCurrentOrganizationId)
  const { data, loading } = useSearch('Device', {
    organization: orgId,
    _count: 200
  })
  const devices = data?.Device ?? []

  return loading ? (
    <Spinner />
  ) : (
    <Container>
      <h1>
        <Trans>Devices</Trans>
      </h1>
      <Table>
        <tbody>
          {devices.length === 0 ? (
            <p>
              <Trans>No connected device for this organization</Trans>
            </p>
          ) : (
            devices.map(device => (
              <Row
                role="button"
                key={device.id}
                onClick={() => {
                  navigate(`devices/${device.id}`)
                }}
              >
                <Flex>
                  <div>{device.type?.coding?.[0]?.display?.toUpperCase()}</div>
                  <Small>
                    {
                      device.identifier?.find?.(
                        i =>
                          (i.system === 'mac-address' && i.value != null) ||
                          i.system === 'SN'
                      )?.value
                    }
                  </Small>
                </Flex>
                <td>
                  {device.manufacturer}&nbsp;&middot;&nbsp;{device.model}
                </td>
              </Row>
            ))
          )}
        </tbody>
      </Table>
    </Container>
  )
}

export default DeviceList
