import React, { useContext, useRef } from 'react'
import * as R from 'ramda'
import { v4 as uuid } from 'uuid'
import { Input } from '../Components'
import { mergeSimpleValue, ModelContext, ReadOnlyContext } from './utils'

const YamlRadioInput = ({ yaml, ...rest }) => {
  const [model, setModel] = useContext(ModelContext)
  const id = useRef(uuid())
  const readOnly = useContext(ReadOnlyContext)
  const lens = R.lensPath(yaml.name.split('.'))
  let defaultLabel = yaml.label
  if (readOnly && yaml.readOnlyLabel != null) {defaultLabel = yaml.readOnlyLabel}
  const handleChange = e => {
    if (e.target.checked === true) {
      setModel(mergeSimpleValue(lens, model, e))
    }
  }

  // using useState to prevent uuid() to be called at every render
  // id is required for a11y
  // defaultChecked = R.view(lens, model) give us the value of the checkbox/radio
  // then we compare it with the yaml.value. We have to use toString() for boolean
  // because the value of R.view(lens, model) is necessarily a string (even for boolean value)
  const defaultChecked =
    R.view(lens, model) != null &&
    R.view(lens, model).toString() === yaml.value.toString()

  return (
    <Input
      type="radio"
      required={yaml.required}
      name={yaml.name}
      id={id.current}
      data-yaml-form={yaml.name}
      data-yaml-map={yaml.map}
      defaultChecked={defaultChecked}
      {...rest}
      onChange={handleChange}
      aria-label={yaml.label}
      invalid={'false'}
      value={yaml.value}
      label={defaultLabel}
      readOnly={yaml.readOnly || readOnly}
      className="fs-block"
    />
  )
}

YamlRadioInput.defaultProps = {
  readOnly: false
}

export default YamlRadioInput
