import React from 'react'
import styled from 'styled-components/macro'

const ValueDiv = styled.div`
  margin-right: 0;
`

const CodingAnswer = ({ value }) => {
  return <ValueDiv>{value?.display}</ValueDiv>
}

export default CodingAnswer
