import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components/macro'
import { Trans } from '@lingui/macro'
import { Button } from '../../Components'
import { useNavigate } from '@reach/router'
import Header from '../../Organization/components/Header'
import { getDeprecatedPractitionerById } from '../../Practitioner/selector'
import { removePractitioner } from '../../Practitioner/actions'
import { createNotification } from '../../Notifications/actions'
import usePractitioner from '../../Practitioner/usePractitioner'

const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${p => p.theme.spacing.small};
`

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: ${p => p.theme.spacing.small};
  margin-bottom: ${p => p.theme.spacing.large};
  border-bottom: 1px solid ${p => p.theme.gray};
  padding-bottom: 1.5rem;
`

const ToolsButton = styled(Button)`
  margin: ${props => props.theme.spacing.medium};
`

const ArchiveUserForAdmin = ({ practitionerID }) => {
  const reduxDispatch = useDispatch()
  const navigate = useNavigate()
  const practitioner = useSelector(s =>
    getDeprecatedPractitionerById(s, practitionerID)
  )
  const currentPractitioner = usePractitioner()
  const [state, setState] = useState(false)

  const handleArchive = () => {
    if (currentPractitioner.id === practitionerID) {
      // we verify if the admin archive himself, if it's the case
      // we don't manage it to prevent organization without administrator
      reduxDispatch(
        createNotification(
          "Archivage de l'utilisateur impossible",
          'error',
          2000
        )
      )
    } else {
      reduxDispatch(removePractitioner(practitioner))
      navigate(`/`)
    }
  }

  return (
    <>
      <Header>
        <Trans>Archive User</Trans>
      </Header>
      <Trans>When an account is archived:</Trans>
      <ul>
        <li>
          <Trans>
            The user will not be allowed to connect to their work space anymore.
          </Trans>
        </li>
        <li>
          <Trans>The user's activities will remain accessible in Medeo.</Trans>
        </li>
      </ul>
      <Content>
        <Container>
          <Button
            variant="outline"
            color="ocean"
            onClick={() => setState(!state)}
          >
            <Trans>Archive</Trans>
          </Button>
        </Container>
        {state && (
          <>
            <Trans>Are you sure to archive this user ?</Trans>
            <Container>
              <ToolsButton color="ocean" onClick={handleArchive}>
                <Trans>Yes</Trans>
              </ToolsButton>
              <ToolsButton
                variant="outline"
                color="ocean"
                onClick={() => setState(false)}
              >
                <Trans>No</Trans>
              </ToolsButton>
            </Container>
          </>
        )}
      </Content>
    </>
  )
}

export default ArchiveUserForAdmin
