import React, { useContext } from 'react'
import * as R from 'ramda'
import { Input } from '../Components'
import { parseYAMLDate } from '../Shared/form/parseDate'
import {
  mergeMappedValue,
  mergeSimpleValue,
  ModelContext,
  ReadOnlyContext
} from './utils'

const YamlInput = ({ yaml, ...rest }) => {
  const [model, setModel] = useContext(ModelContext)
  const readOnly = useContext(ReadOnlyContext)

  let lens
  if (yaml.map != null) {
    lens = R.lensPath(yaml.map.split('.'))
  } else {
    lens = R.lensPath(yaml.value.split('.'))
  }
  const handleChange = e => {
    if (yaml.map != null) {
      const index = yaml.index
      const name = yaml.value
      setModel(mergeMappedValue(lens, model, index, name, e))
    } else {
      setModel(mergeSimpleValue(lens, model, e))
    }
  }
  let defaultValueLens =
    yaml.map != null
      ? R.lensPath([...yaml.map.split('.'), yaml.index, yaml.value])
      : lens

  // the value can be 0 so no coalescing should be possible
  let defaultValue = R.view(defaultValueLens, model)
  defaultValue = defaultValue != null ? defaultValue : ''

  // The input should use autoComplete="new-password" here is why:
  // autoComplete="new-password" is the only way to prevent autofill
  // it will still show the prompt for completion but unfortunately
  // there is no workaround yet. for more details see
  // https://gist.github.com/niksumeiko/360164708c3b326bd1c8

  // Use "tel" type for phone number, but store it as "phone".
  let inputType = yaml.type
  if (yaml.type === 'phone') {
    inputType = 'tel'
  }
  return (
    <Input
      label={yaml.label}
      defaultValue={defaultValue}
      data-yaml-form={yaml.value}
      data-yaml-map={yaml.map}
      id={yaml.label}
      name={yaml.label}
      type={inputType}
      aria-label={yaml.label}
      required={yaml.required}
      readOnly={readOnly}
      min={yaml.type === 'number' ? yaml.min : parseYAMLDate(yaml.min)}
      max={yaml.type === 'number' ? yaml.max : parseYAMLDate(yaml.max)}
      maxLength={inputType === 'tel' ? '10' : undefined}
      minLength={inputType === 'tel' ? '10' : undefined}
      {...rest}
      onChange={handleChange}
      autoComplete="new-password"
      className="fs-block"
    />
  )
}

export default YamlInput
