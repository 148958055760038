import { call, put, fork, take, cancel, cancelled } from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import io from 'socket.io-client';
import {
  STARTING_BLUETOOTH_ENCOUNTER,
  STOPPING_ENCOUNTER,
  UPDATING_ENCOUNTER,
  STOPPING_PRETELECONSULTATION_ENCOUNTER,
  STOP_BLUETOOTH_ENCOUNTER
} from '../Encounter/actions';
import { ADDING_OBSERVATION } from '../Observation/actions';
import * as fromLoinc from '../utils/loinc';

const createKligoWebSocket = () => {
  return io('http://localhost:63336');
};

const createSocketChannel = (socket, patientID, practitionerID) => {
  return eventChannel(emit => {
    socket.on('fhir', data => {
      let observation;
      if (data.device.display === 'pulse_oximeter' && data.component) {
        observation = {
          loincCoding: '59408-5',
          patientID: patientID,
          effectiveDateTime: new Date(),
          practitioner: 'Practitioner/' + practitionerID,
          pulse: data.component[0].valueQuantity.value,
          saturation: data.component[1].valueQuantity.value,
          // This can be used to trigger the overwrite of the last observation
          // To prevent from flooding
          overwriteLast: true
        };
      } else if (
        data.device.display === 'blood_pressure_monitor' &&
        data.component
      ) {
        observation = {
          loincCoding: '85354-9',
          patientID: patientID,
          effectiveDateTime: new Date(),
          practitioner: 'Practitioner/' + practitionerID,
          diastolicBloodPressure: data.component[1].valueQuantity.value,
          systolicBloodPressure: data.component[0].valueQuantity.value,
          pulse: data.component[2].valueQuantity.value
        };
      } else if (
        data &&
        data.code &&
        data.code.coding &&
        data.code.coding[0] &&
        data.code.coding[0].display
      ) {
        const observationType = fromLoinc.getLoincCodeFromBluetooth(
          data.code.coding[0].display
        );
        observation = {
          loincCoding: observationType,
          patientID: patientID,
          effectiveDateTime: new Date(),
          value: data.valueQuantity.value,
          practitioner: 'Practitioner/' + practitionerID
        };
      }
      emit(observation);
    });
    return () => socket.close();
  });
};

function* kligoSocketWorker(action) {
  const { patientID, practitionerID } = action.payload;
  const kligoSocket = yield call(createKligoWebSocket);
  const kligoSocketChannel = yield call(
    createSocketChannel,
    kligoSocket,
    patientID,
    practitionerID
  );
  try {
    while (true) {
      const payload = yield take(kligoSocketChannel);
      yield put({ type: ADDING_OBSERVATION, payload: payload });
    }
  } finally {
    if (yield cancelled()) {
      kligoSocket.close();
    }
  }
}

function* rootSaga() {
  // this rootSaga differ from the other's the idea here is to be able to interrupt the bluetooth process whenever the user
  // leave the NewEncounterCard component. bluetoothScanAndConnectionWorker should handle task cancellation well:
  // it should reset the bluetooth process (i.e. stop scanning and drop any connection)

  while (true) {
    const action = yield take(STARTING_BLUETOOTH_ENCOUNTER);
    const kligoTask = yield fork(kligoSocketWorker, action);

    // there are two cases when finishing an encounter:
    // either we stop it (i.e. there are measurements)
    // or we cancel it (i.e. there are no measurements attached)
    yield take([
      STOPPING_ENCOUNTER,
      UPDATING_ENCOUNTER,
      STOPPING_PRETELECONSULTATION_ENCOUNTER,
      STOP_BLUETOOTH_ENCOUNTER
    ]);
    yield cancel(kligoTask);
  }
}

export default rootSaga;
