import { faFileAlt, faStopCircle } from '@fortawesome/free-regular-svg-icons'
import {
  faEnvelopeOpenText,
  faExclamationTriangle,
  faPills,
  faSyringe
} from '@fortawesome/free-solid-svg-icons'
import { flattenItems } from '../QuestionnaireResponse/utils'
import { getPostCodeFromAddresses } from '../utils/address'
import {
  MEDEO_BILLING_CODE_SYSTEM_DOM,
  MEDEO_BILLING_CODE_SYSTEM_METROPOLE,
  MEDEO_CODE_FOR_AME,
  MEDEO_CODE_FOR_CLINICAL_EXAM,
  MEDEO_CODE_FOR_MOTIVE,
  MEDEO_CODE_FOR_THIRD_PARTY_ATTACHMENT,
  MEDEO_CODE_FOR_TP_AMC_OPTION,
  MEDEO_CODE_FOR_TP_AMC_VALUE,
  MEDEO_CODE_FOR_TP_AMO_OPTION,
  MEDEO_CODE_FOR_TP_AMO_VALUE,
  MEDEO_QUESTIONNAIRE_ITEM_CODE_SYSTEM,
  MEDEO_QUESTIONNAIRE_TAG,
  MOTIVE_QUESTION_IN_MOTIVES_QUESTIONNAIRE
} from '../utils/codes'
import QuestionnaireBuilder from '../utils/fhir/QuestionnaireBuilder'
import {
  ADDING_RESPONSE_ITEM,
  SETTING_QUESTIONNAIRE_RESPONSE
} from './components/Questionnaire'
/*
 * "index" is, in case of type "deleteFile", the index of the file to delete from the item
 */
function constructResponse(newItem, previousAnswer = null, index) {
  let item
  if (newItem) {
    // Here we take only the first answer, but we should take all the array
    let answer = newItem
    switch (answer.valueType) {
      // available types of questions (in questionnaire) are here : https://www.hl7.org/fhir/valueset-item-type.html
      // available type of answers (in questionnaireResponse) are here : https://www.hl7.org/fhir/questionnaireresponse.html
      // here we have to link the two lists
      case 'text':
      case 'string':
        item = new QuestionnaireBuilder().appendValueString(answer.value)
        break
      case 'time':
        item = new QuestionnaireBuilder().appendValueString(answer.value)
        break
      case 'boolean':
        item = new QuestionnaireBuilder().appendValueBoolean(answer.value)
        break
      case 'date':
        item = new QuestionnaireBuilder().appendValueDate(answer.value)
        break
      case 'radio':
        item = new QuestionnaireBuilder().appendValueCoding(answer.value)
        break
      case 'checkbox':
        // If the item was cheked, add it to the list
        item = answer.checked
          ? new QuestionnaireBuilder(previousAnswer).addValueCoding(
              answer.value
            )
          : // Otherwise, remove it
            new QuestionnaireBuilder(previousAnswer).removeValueCoding(
              answer.value
            )
        break
      case 'file':
        // we need the previous answer because we want to append the new document
        // instead of overwriting it
        item = new QuestionnaireBuilder(previousAnswer).appendValueAttachment(
          answer.value
        )
        break
      case 'deleteFile':
        // we need the previous answer because we want to remove a document from it
        item = new QuestionnaireBuilder(previousAnswer).removeValueAttachment(
          index
        )
        break
      case 'select':
        item = new QuestionnaireBuilder().appendValueCoding(answer.value)
        break
      case 'quantity':
        item = new QuestionnaireBuilder().appendValueQuantity(answer.value)
        break
      case 'drop-down':
      case 'tag':
        item = new QuestionnaireBuilder()
        answer.value.map((v) => item.addValueString(v))
        break
      case 'integer':
        item = new QuestionnaireBuilder().appendValueInteger(answer.value)
        break
      case 'decimal':
        item = new QuestionnaireBuilder().appendValueDecimal(answer.value)
        break
      default:
    }
  }
  return item.answer
}

export const getDisplay = (resource) => {
  if (resource.text != null) {
    return resource.text
  }

  const code = resource?.code?.find(
    (c) => c.display || c.system !== MEDEO_QUESTIONNAIRE_ITEM_CODE_SYSTEM
  )
  // returns display or an empty string if none of the display were found
  return code?.display || code?.code || ''
}

export function isLinkId(linkId) {
  return (level) => level.linkId === linkId
}

export const updateResponse = ({
  questionnaireResponse,
  name,
  value,
  type,
  checked = null,
  index
}) => {
  const address = name
  const itemIndexes = address.split('.')
  // init values
  let currentLinkId = ``
  let currentItemToCheck = questionnaireResponse
  let previousItem = ``

  itemIndexes.map((itemIndex, i) => {
    // we test if items exist at each different deep level, and create them if not

    // new values for my iteration vars
    previousItem = currentItemToCheck.item
    currentLinkId = i !== 0 ? `${currentLinkId}.${itemIndex}` : itemIndex
    currentItemToCheck = currentItemToCheck.item.find(isLinkId(currentLinkId))

    // test if current item exists, create them if not
    if (currentItemToCheck === undefined) {
      previousItem.push({ linkId: currentLinkId })
      currentItemToCheck = previousItem.find(isLinkId(currentLinkId))
      // if the current path is not the final path (= address or name), create an item array
      if (currentLinkId !== address) {
        currentItemToCheck.item = []
      }
    }

    // if the current path is the final path (= address or name), save the answer
    if (currentLinkId === address) {
      // If the item is a checkbox, we need to update the previous answer instead of overwriting it
      if (type === 'checkbox') {
        currentItemToCheck.answer = constructResponse(
          {
            valueType: type,
            value: value,
            checked: checked
          },
          currentItemToCheck
        )
      } else if (type === 'radio') {
        // If the item is a radio, we need to overwriting it instead of append the previous answer
        currentItemToCheck.answer = constructResponse(
          {
            valueType: type,
            value: value,
            checked: checked
          },
          currentItemToCheck
        )
      } else if (type === 'file') {
        // If the item is a file, we need to append the previous answer instead of overwriting it
        currentItemToCheck.answer = constructResponse(
          {
            valueType: type,
            value: value,
            checked: checked
          },
          currentItemToCheck
        )
      } else if (type === 'deleteFile') {
        // If the action is to delete a file, we need the previous answer ans the index of the deleted file
        currentItemToCheck.answer = constructResponse(
          {
            valueType: type
          },
          currentItemToCheck,
          index
        )
      } else if (value == null) {
        currentItemToCheck.answer = []
      } else {
        currentItemToCheck.answer = constructResponse({
          valueType: type,
          value: value
        })
      }
      if (type === 'tag') {
        currentItemToCheck.definition = MEDEO_QUESTIONNAIRE_TAG
      }
    }

    // test if child "item" exists, and create it if needed. This is the case for subquestion of question.
    if (currentLinkId !== address && currentItemToCheck.item === undefined) {
      currentItemToCheck.item = []
    }

    return null
  })

  return questionnaireResponse
}

export const onChangeQuestionnaire = ({
  questionnaireResponse,
  dispatchQuestionnaireResponse,
  name,
  value,
  type,
  checked = null,
  index
}) => {
  // This is the initialization we need in case there was no item in the
  // questionnaire response yet
  if (questionnaireResponse == null) {
    questionnaireResponse = { item: [] }
  }

  questionnaireResponse = updateResponse({
    questionnaireResponse,
    name,
    value,
    type,
    checked,
    index
  })

  dispatchQuestionnaireResponse({
    type: ADDING_RESPONSE_ITEM,
    payload: questionnaireResponse.item
  })
}

// this function is used when we click in remove button for duplicable input
export const removeItemByAddressInArray = (array, address) => {
  return array.filter((e) => e.linkId !== address)
}

/**
 * this function is used when we remove a duplicable input and we
 * have to delete the response
 *
 * here we assume the fact that we follow the dot notation : "1.1.2.3"
 *
 * @param {array} questionnaireResponse
 * @param {string} itemAddress
 * @param {function} dispatchQuestionnaireResponse
 */
export const removeItemInResponse = (
  questionnaireResponse,
  itemAddress,
  dispatchQuestionnaireResponse
) => {
  const parentItemAddress = itemAddress.slice(0, itemAddress.length - 2)
  const parentItem = getItemByLinkId(questionnaireResponse, parentItemAddress)
  let newItemArray
  if (parentItem && parentItem.item) {
    newItemArray = removeItemByAddressInArray(parentItem.item, itemAddress)
    parentItem.item = newItemArray
  }
  dispatchQuestionnaireResponse({
    type: SETTING_QUESTIONNAIRE_RESPONSE,
    payload: questionnaireResponse
  })
}

/**
 *
 * @param {Questionnaire|QuestionnaireResponse|Questionnaire.Item|QuestionnaireResponse.Item} questionnaireOrResponse
 * @param {string} linkId
 * @return {Questionnaire.Item | QuestionnaireResponse.Item} item
 */
export const getItemByLinkId = (questionnaireOrResponse, linkId) => {
  if (linkId == null || questionnaireOrResponse == null) {
    return
  }
  const itemIndexes = linkId.split('.')
  let response
  let currentLinkId = ``
  let currentItemToCheck = questionnaireOrResponse
  itemIndexes.forEach((itemIndex, i) => {
    currentLinkId = i !== 0 ? `${currentLinkId}.${itemIndex}` : itemIndex
    if (currentItemToCheck && currentItemToCheck.item) {
      currentItemToCheck = currentItemToCheck.item.find(isLinkId(currentLinkId))
      if (currentLinkId === linkId) {
        response = currentItemToCheck
      }
    }
  })
  return response
}

export const duplicateItem = (itemArray, itemToDuplicate) => {
  if (itemToDuplicate != null && itemArray.length > 0) {
    const linkId = itemToDuplicate.linkId
    // get the next linkId to create
    const itemIndexes = linkId.split('.')
    const lastIndex = itemIndexes.pop()
    itemIndexes.push(parseInt(lastIndex) + 1)
    const nextFullAddress = itemIndexes.join('.')

    // create the new Item
    const newItemToAdd = JSON.parse(
      JSON.stringify(itemToDuplicate)
        .split(`${linkId}`)
        .join(nextFullAddress)
    )
    newItemToAdd.visible = true
    newItemToAdd.isFirst = false
    itemArray.push(newItemToAdd)

    // update the item we want to duplicate in order to avoid display add button
    const itemToUpdate = itemArray.filter((e) => e.linkId === linkId)[0]
    itemToUpdate.showAddButton = false
  }
  return itemArray
}

export const getIconByName = (icon) => {
  switch (icon) {
    case 'pills':
      return faPills
    case 'syringe':
      return faSyringe
    case 'envelope':
      return faEnvelopeOpenText
    case 'stop':
      return faStopCircle
    case 'cone':
      return faExclamationTriangle
    case 'file':
      return faFileAlt
    default:
      return null
  }
}

export const getQuestionNumberFromQuestionnaireByName = (name) => {
  switch (name) {
    case MOTIVE_QUESTION_IN_MOTIVES_QUESTIONNAIRE:
      return '1.1.2'
    default:
      return null
  }
}

export const isQuestionnaireOfType = (questionnaire, identifierCode) => {
  const code = questionnaire.identifier?.[0]?.type?.coding?.[0]?.code
  return identifierCode === code
}

export const isQuestionnaireOfTypeAndValue = (
  questionnaire,
  identifierCode,
  value
) => {
  const identifier = questionnaire?.identifier?.[0]
  const code = identifier?.type?.coding?.[0]?.code

  return identifierCode === code && identifier.value === value
}

export const getAmountByActCode = (billingCode, postCode) => {
  const billingConvention = getBillingConventionFromPostCode(postCode)
  let amount
  switch (billingCode) {
    case 'APC':
      amount = billingConvention === MEDEO_BILLING_CODE_SYSTEM_DOM ? 66 : 55
      break
    case 'F':
      amount = 19.06
      break
    case 'MCG':
      amount = 5
      break
    case 'MCS':
      amount = 5
      break
    case 'MEG':
      amount = 5
      break
    case 'MM':
      amount = 40
      break
    case 'MN':
      amount = 35
      break
    case 'MUT':
      amount = 5
      break
    case 'SNP':
      amount = 15
      break
    case 'TC':
      amount = billingConvention === MEDEO_BILLING_CODE_SYSTEM_DOM ? 27.6 : 23
      break
    case 'TCG':
      amount = billingConvention === MEDEO_BILLING_CODE_SYSTEM_DOM ? 29.6 : 25
      break
    default:
      amount = 0
  }
  return amount
}

const getBillingConventionFromPostCode = (postCode) => {
  // Malformed postCode, ignore.
  if (postCode === null || postCode === undefined || postCode.length !== 5) {
    return MEDEO_BILLING_CODE_SYSTEM_METROPOLE
  }
  if (postCode.startsWith('97')) {
    return MEDEO_BILLING_CODE_SYSTEM_DOM
  }
  if (postCode.startsWith('98')) {
    return MEDEO_BILLING_CODE_SYSTEM_DOM
  }
  // Default to metropole
  return MEDEO_BILLING_CODE_SYSTEM_METROPOLE
}

export const getAmountForAct = (item, questionnaireResponse, practitioner) => {
  const [, index] = item.linkId.match(/1\.3\.2\.([0-9])\.3/)
  const itemToUse = getItemByLinkId(questionnaireResponse, `1.3.2.${index}.1`)
  const billingCode = itemToUse?.answer?.[0]?.valueCoding?.code
  const postCode = getPostCodeFromAddresses(practitioner?.address)
  return getAmountByActCode(billingCode, postCode)
}

/**
 * Calculate the percentage covered by the tiers payant in the caresheet
 * which is the sum of both the part covered by the AMO and the AMC
 *
 * @param {*} questionnaire
 * @param {*} questionnaireResponse
 * @returns {Number}
 */
const calculateCoveredPercentage = (questionnaire, questionnaireResponse) => {
  let percentageCovered = 0

  const tpAmoAnswer = getItemByLinkId(
    questionnaireResponse,
    getItemLinkIdFromCode(questionnaire, MEDEO_CODE_FOR_TP_AMO_OPTION)
  )?.answer?.[0]?.valueCoding?.code

  const tpAmcAnswer = getItemByLinkId(
    questionnaireResponse,
    getItemLinkIdFromCode(questionnaire, MEDEO_CODE_FOR_TP_AMC_OPTION)
  )?.answer?.[0]?.valueCoding?.code

  // Part covered by AMO
  if (tpAmoAnswer === 'tp-amo') {
    const tpAmoPercentage = getItemByLinkId(
      questionnaireResponse,
      getItemLinkIdFromCode(questionnaire, MEDEO_CODE_FOR_TP_AMO_VALUE)
    )?.answer?.[0]?.valueQuantity?.value

    if (tpAmoPercentage) {
      percentageCovered += parseInt(tpAmoPercentage)
    }
  }

  // Part covered by AMC
  if (tpAmcAnswer === 'tp-amc') {
    const tpAmcPercentage = getItemByLinkId(
      questionnaireResponse,
      getItemLinkIdFromCode(questionnaire, MEDEO_CODE_FOR_TP_AMC_VALUE)
    )?.answer?.[0]?.valueQuantity?.value

    if (tpAmcPercentage) {
      percentageCovered += parseInt(tpAmcPercentage, 10)
    }
  }

  percentageCovered = percentageCovered > 100 ? 100 : percentageCovered

  return percentageCovered
}

/**
 * Calculate the rest of the caresheet to be paid by the patient
 * This takes in account the don't make pay item and the
 * covered by tiers payant (AMC / AMO) percentages
 *
 * @param {*} questionnaire
 * @param {*} questionnaireResponse
 */
export const getRestAmountForCaresheet = (
  questionnaire,
  questionnaireResponse
) => {
  let restValue = 0
  const totalItem = getItemByLinkId(questionnaireResponse, `1.3.3`)
  const totalValue = totalItem?.answer?.[0]?.valueQuantity?.value
  if (totalItem) {
    restValue = totalValue
  }

  const dontMakePayItem = getItemByLinkId(questionnaireResponse, `1.3.6`)
  const dontMakePayItemAnswer = dontMakePayItem?.answer?.[0]?.valueCoding?.code

  const percentageCovered = calculateCoveredPercentage(
    questionnaire,
    questionnaireResponse
  )

  if (
    dontMakePayItemAnswer === 'no-facturation'
    // boolean doesn't work we have to compare it with string
  ) {
    restValue = 0
  } else {
    restValue = restValue - (restValue * percentageCovered) / 100
  }

  return parseFloat(restValue.toFixed(2)) // limit to two decimals
}

export const getTotalAmountForCaresheet = (questionnaireResponse) => {
  let totalValue = 0
  // more actArrayitem
  const actArrayItem = getItemByLinkId(questionnaireResponse, '1.3.2')
  if (actArrayItem && actArrayItem.item) {
    const amountArray = actArrayItem.item.map((actItem) => {
      // Here we cannot just increment the linkId as it might be that they
      // are not following each other because of the possibility for
      // the user to add / remove acts
      const inputAmount = actItem.item.find(isLinkId(`${actItem.linkId}.3`))
      return inputAmount?.answer?.[0]?.valueQuantity?.value
    })
    // remove null in array
    amountArray.filter((i) => i)
    if (amountArray) {
      //calculate total amount
      const total = amountArray.reduce((accumulator, value) => {
        return accumulator + value
      }, 0)
      if (!isNaN(total)) {
        totalValue = total
      }
    }
  }
  return totalValue
}

/**
 * Get item link id in a questionnaire depending on its code
 *
 * @param {Questionnaire} questionnaire - questionnaire where to look for the linkId
 * @param {String} code - code of the item we are looking for
 */
export const getItemLinkIdFromCode = (questionnaire, code) => {
  const item = flattenItems(questionnaire).find((i) => {
    const itemCode = i.code?.[0]?.code
    return itemCode === code
  })

  if (item == null) {
    return null
  } else {
    return item.linkId
  }
}

/**
 * Return the item in the response depending on the item code,
 * which is available in the questionnaire
 *
 * @param {*} code
 * @param {*} questionnaire
 * @param {*} questionnaireResponse
 */
export const getResponseItemByItemCode = (
  code,
  questionnaire,
  questionnaireResponse
) => {
  const linkId = getItemLinkIdFromCode(questionnaire, code)
  if (linkId == null) {
    return null
  }
  return getItemByLinkId(questionnaireResponse, linkId)
}

/**
 * We want to know if the user select an third part payer during his preamble
 * questionnaire. Secondly we want to know if the user add a proof if he select
 * a third part. Once we have this two parameters we authorize or not the user
 * to go to next step. This process is use during the preconsultation.
 * @param questionnaire
 * @param response
 * @returns {boolean}
 */
export const userCanGoToNextStep = (questionnaire, response) => {
  if (questionnaire == null) {
    return false
  }
  //here we get the response for AME
  const AMEResponse = getItemByLinkId(
    response,
    getItemLinkIdFromCode(questionnaire, MEDEO_CODE_FOR_AME)
  )?.answer?.[0]?.valueBoolean

  // here we get the proof parameter
  const fileInput = getItemByLinkId(
    response,
    getItemLinkIdFromCode(questionnaire, MEDEO_CODE_FOR_THIRD_PARTY_ATTACHMENT)
  )?.answer?.[0]?.valueAttachment
  // then with this two parameters we authorize or not the user to go to next step
  // we only require an attachment if the AME option is true
  if (fileInput == null && AMEResponse) {
    return true
  }
  if (fileInput != null && AMEResponse) {
    return false
  }
}

/**
 * Extract clinical exam indication and motive
 *
 * @param {Questionnaire} questionnaire
 * @param {QuestionnaireResponse} response
 */
export const extractResponsesFromMotive = (questionnaire, response) => {
  const clinicalExam = getItemByLinkId(
    response,
    getItemLinkIdFromCode(questionnaire, MEDEO_CODE_FOR_CLINICAL_EXAM)
  )?.answer?.[0]?.valueBoolean

  const motive = getItemByLinkId(
    response,
    getItemLinkIdFromCode(questionnaire, MEDEO_CODE_FOR_MOTIVE)
  )?.answer?.[0]?.valueCoding?.display

  return { clinicalExam, motive }
}
