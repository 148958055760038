/* eslint-disable */
import { Trans } from '@lingui/macro'
import { API } from 'aws-amplify'
import {
  connect as connectToRoom,
  createLocalVideoTrack,
  LocalDataTrack
} from 'twilio-video'
import { getItemByLinkId } from '../Questionnaire/utils'
import mixpanel, {
  MIXPANEL_VISIO_JOINED,
  MIXPANEL_VISIO_LEFT
} from '../Shared/mixpanel'
import { getParamFromUrl } from '../Shared/utils'
import { IPM_KIOSK_ORIGIN } from '../utils/codes'
import {
  getAudioTrackFromInputDeviceSelection,
  getDeviceSelectionOptions,
  getVideoTrackFromInputDeviceSelection,
  isIntegratedMicrophoneLabel
} from '../utils/video/helpersDevices'
import {
  ADD_OBSERVATION,
  CANNOT_JOIN_ROOM,
  NUMBER_PARTICIPANT_CHANGING,
  OPENING_MODAL,
  PLUGGING_DEVICE,
  SETUP_DEVICE_OPTIONS,
  USER_CONNECTING,
  USER_DISCONNECTING,
  USER_STARTING_MICRO,
  USER_STARTING_VIDEO,
  USER_STOPPING_MICRO,
  USER_STOPPING_VIDEO
} from './reducer'

// Constants for instructions types sent on the data track
export const FHIR_OBSERVATION = 'FHIR_OBSERVATION'

/**
 * Calls videoServer lambda and returns twilio token
 *
 * @param {string} identity
 * @param {string} patientID
 */
const request = async (identity, patientID) => {
  const apiName = 'twilio'
  const path = '/video'

  const params = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: { identity: identity, patientID: patientID }
  }

  return await API.post(apiName, path, params)
}

/**
 * Makes video to fit full width and height of parent div
 *
 * @param {component} div
 * @param {object} track
 */
export const attachVideoToDiv = (div, track) => {
  const video = div.appendChild(track.attach())
  video.setAttribute('style', 'object-fit: cover; width:100%; height:100%;')
}

// ------- handle disconnection
export const disconnectionWorker = (room, PreviewVideoTrack) => {
  // Detach the local media elements
  if (room == null) {
    return
  }
  room.localParticipant.tracks.forEach((publication) => {
    if (publication.track.kind !== 'data') {
      const attachedElements = publication.track.detach()
      attachedElements.forEach((element) => element.remove())
      publication.track.stop()
    }
  })
  // disconnect preview track to remove the led
  PreviewVideoTrack.stop()
  const attachedElementsBis = PreviewVideoTrack.detach()
  attachedElementsBis.forEach((element) => element.remove())
}

/**
 * Initiate the room event listeners
 *
 */
export const initiateRoomListeners = ({
  dataTrackCallback = () => { },
  disconnectedCallback = () => { },
  participantConnectedCallback = () => { },
  participantDisconnectedCallback = () => { },
  room,
  trackPublicationCallback = () => { }
}) => {
  room.participants.forEach((participant) => {
    participant.tracks.forEach((publication) => {
      if (publication.isSubscribed) {
        const track = publication.track
        if (track.kind !== 'data') {
          trackPublicationCallback(track)
        } else {
          track.on('message', (data) => {
            dataTrackCallback(data)
          })
        }
      }
    })
    participant.on('trackUnsubscribed', (track) => {
      if (track.kind !== 'data') {
        track.detach().forEach((element) => element.remove())
      }
    })
    participant.on('trackSubscribed', (track) => {
      if (track.kind !== 'data') {
        trackPublicationCallback(track)
      } else {
        /**
         * Listener on data track
         */

        track.on('message', (data) => {
          dataTrackCallback(data)
        })
      }
    })
  })

  room.on('participantConnected', (participant) => {
    participantConnectedCallback()

    participant.tracks.forEach((publication) => {
      if (publication.isSubscribed) {
        const track = publication.track
        if (track.kind !== 'data') {
          trackPublicationCallback(track)
        }
      }
    })

    participant.on('trackSubscribed', (track) => {
      if (track.kind !== 'data') {
        trackPublicationCallback(track)
      } else {
        track.on('message', (data) => {
          dataTrackCallback(data)
        })
      }
    })
    participant.on('trackUnsubscribed', (track) => {
      if (track.kind !== 'data') {
        track.detach().forEach((element) => element.remove())
      }
    })
  })

  room.on('participantDisconnected', () => participantDisconnectedCallback())

  room.on('disconnected', () => disconnectedCallback())
}

// doc here : https://www.twilio.com/docs/video/migrating-1x-2x and here https://www.twilio.com/docs/video/

// Join a room for consultation
// TODO : choose type of room (P2P vs group)
// TODO : set ttl)
export const onConnect = async ({
  identity,
  patientID,
  remoteMediaContainer,
  localMediaContainer,
  dispatch,
  devices
}) => {
  // Here we get the device saved in the local storage from the last TLC
  //and start the new encounter using it as the default device
  //if there's none, the computer default device is going to be the selected one
  const lastVideoDeviceUsed = localStorage.getItem('VideoInput')

  let selectedVideoDevice
  if (lastVideoDeviceUsed != null) {
    selectedVideoDevice = lastVideoDeviceUsed
  } else {
    selectedVideoDevice = devices?.selectedVideoInput.value
  }
  const response = await request(identity, patientID)

  // The lambda we are calling here checks that there is no more than two participants
  // yet, and will return a token only if it is not the case.
  if (response.token == null) {
    dispatch({
      type: CANNOT_JOIN_ROOM,
      payload: { status: 'cannotJoin' }
    })
    return alert('There are already 2 people in this room.')
  }

  const token = response.token

  const RemoteMediaContainer = remoteMediaContainer.current

  // Create track to enchange data
  const dataTrack = new LocalDataTrack()

  try {
    const BigVideoTrack = await createLocalVideoTrack({
      deviceId: selectedVideoDevice
    })

    const PreviewVideoTrack = await createLocalVideoTrack({
      deviceId: selectedVideoDevice
    })

    // Here we first connect to the room in order to be able to check out the amount of participants
    // already connected first.
    const room = await connectToRoom(token, {
      name: 'my-room-name',
      tracks: [BigVideoTrack]
    })
    //same as for the video, we get the device saved in the local storage
    let lastAudioDeviceUsed
    try {
      lastAudioDeviceUsed = JSON.parse(localStorage.getItem('AudioInput'))
    } catch (e) {
      console.warn('cannot read AudioInput')
    }

    let selectedAudioDevice
    if (lastAudioDeviceUsed != null) {
      selectedAudioDevice = lastAudioDeviceUsed
    } else {
      selectedAudioDevice = devices.selectedAudioInput
    }
    // we use the getAudioTrack helper here to apply specific options to the track
    // depending on the microphone used (internal mic or stethoscope)
    // the parameters should change to prevent larsen or noise suppression.
    const audioTrack = await getAudioTrackFromInputDeviceSelection(
      selectedAudioDevice
    )

    // publishTrack() resolves with a LocalTrackPublication when the LocalTrack is
    // successfully published.
    const audioTrackPublication = await room?.localParticipant.publishTrack(
      audioTrack
    )

    // We use a data track to send instructions concerning the stethoscope from one
    // user to the other, i.e start, stop, pause
    const dataTrackPublication = await room?.localParticipant.publishTrack(
      dataTrack
    )

    // ----- local video
    const LocalMediaContainer = localMediaContainer.current
    attachVideoToDiv(LocalMediaContainer, PreviewVideoTrack)

    // handle participant disconnection
    const participantDisconnectedCallback = () => {
      // We stop the stethoscope on patientSide when doctorSide leaves the remote consultation
      // and we close the socket if any of the participants leave

      dispatch({
        type: NUMBER_PARTICIPANT_CHANGING,
        payload: { nbOtherParticipant: room.participants.size }
      })
    }

    // ------- listener disconnection
    const disconnectedCallback = () => {
      disconnectionWorker(room, PreviewVideoTrack)
    }

    initiateRoomListeners({
      dataTrackCallback: (data) => handleDataReception(data, dispatch),
      disconnectedCallback: disconnectedCallback,
      // -------- handle remote participant that come after
      // Attach the Participant's Media to a <div> element.
      participantConnectedCallback: () =>
        dispatch({
          type: NUMBER_PARTICIPANT_CHANGING,
          payload: { nbOtherParticipant: room.participants.size }
        }),
      participantDisconnectedCallback,
      room: room,
      // add remote video when the user arrived in room

      trackPublicationCallback: (track) => {
        // A remote track is removed when it is unpublished.
        // Normally, when switching video or audio, the previous track is unpublished,
        // ... then removed. But sometimes, for external camera or RNK, the track is not
        // ... unpublished, and not removed.
        // To prevent having two tracks displayed on the screen, we delete all
        // ... video or audio child of remoteMediaContainer before adding a new one.
        if (RemoteMediaContainer.hasChildNodes()) {
          var children = RemoteMediaContainer.childNodes
          for (var i = 0; i < children.length; i++) {
            if (children[i]?.tagName === 'VIDEO' && track.kind === 'video') {
              RemoteMediaContainer.removeChild(children[i])
            }
            if (children[i]?.tagName === 'AUDIO' && track.kind === 'audio') {
              RemoteMediaContainer.removeChild(children[i])
            }
          }
        }
        attachVideoToDiv(RemoteMediaContainer, track)
      }
    })

    dispatch({
      type: NUMBER_PARTICIPANT_CHANGING,
      payload: { nbOtherParticipant: room.participants.size }
    })

    return {
      room,
      BigVideoTrack,
      PreviewVideoTrack,
      audioTrackPublication,
      audioTrack,
      dataTrack,
      dataTrackPublication
    }
  } catch (error) {
    const message =
      'Medeo ne peut pas accéder à vos webcam et microphone. Vous devez autoriser votre navigateur à utiliser vos webcam et microphone pour ce site web, puis redémarrer votre navigateur.'
    alert(message)

    return 'error'
  }
}

/**
 * Handles disconnection of a video participant
 *
 * @param {object} videoState
 * @param {function} dispatch
 */
const disconnect = ({ videoState, dispatch }) => {
  videoState.teleconsultationMaterial.room.disconnect()
  const attachedElements = videoState.teleconsultationMaterial.PreviewVideoTrack.detach()
  attachedElements.forEach((element) => element.remove())

  const status = videoState.patientSide ? 'finishForPatient' : 'finishForDoctor'
  dispatch({
    type: USER_DISCONNECTING,
    payload: {
      status: status
    }
  })
}

const dermatoscopeLabel = 'Dermatoscope'
const integratedCameraLabel = 'Caméra frontale intégrée'
const integratedMicroLabel = 'Microphone intégré'
const microscopeLabel = 'Microscope'
const multifonctionLabel = 'Caméra multifonction'
const otoscopeLabel = 'Otoscope'
const stethoscopeLabel = 'Stéthoscope'
export const renameDevice = (kindDeviceInfo) => {
  const deviceId = kindDeviceInfo.deviceId
  /* build label */
  // default value
  let label =
    kindDeviceInfo.label || 'Device [ id: ' + deviceId.substr(0, 5) + '... ]'
  /* rename devices here */
  // video input
  if (
    label.startsWith('Integrated Camera') ||
    label.startsWith('Integrated RGB Camera') ||
    label.startsWith('Caméra FaceTime HD') ||
    label.startsWith('FaceTime HD')
  ) {
    label = integratedCameraLabel
  }
  if (label.startsWith('Digital Microscope')) {
    if (label.includes('21cd:703b')) {
      label = dermatoscopeLabel
    } else if (label.includes('21cd:603b')) {
      label = otoscopeLabel
    } else {
      label = microscopeLabel
    }
  }
  if (label.startsWith('Dino-Lite')) {
    label = otoscopeLabel
  }
  if (label.startsWith('Exam Camera')) {
    label = multifonctionLabel
  }
  /* audio input */

  if (label.includes('PCP-USB') || label.includes('USB PnP')) {
    label = stethoscopeLabel
  }
  if (isIntegratedMicrophoneLabel(label)) {
    label = integratedMicroLabel
  }
  return label
}

/**
 * Check whether the device should be used as default
 * depending on its label and the origin of the request (i.e kiosk or not)
 */
const filterForDefaultAudioInput = (origin, label) => {
  // We need this condition here as when the request if coming from kiosk,
  // we remove the integrated microphone, and we don't want the stethoscope to be
  // selected instead
  if (origin === IPM_KIOSK_ORIGIN) {
    return !label.includes(stethoscopeLabel)
  } else {
    return label.includes(integratedMicroLabel)
  }
}

export const updateDevicesOptions = ({
  devicesSelectionOptions,
  videoState,
  dispatch
}) => {
  const kinds = ['audiooutput', 'videoinput', 'audioinput']

  /** Build device options here **/

  let devices = {}
  if (devicesSelectionOptions == null) {
    return
  }

  kinds.forEach((kind) => {
    var kindDeviceInfos = devicesSelectionOptions[kind]
    devices[kind] = kindDeviceInfos
      .map((kindDeviceInfo) => {
        var deviceId = kindDeviceInfo.deviceId
        var label = renameDevice(kindDeviceInfo)
        return { value: deviceId, label: label }
      })
      // remove misleading options
      .filter(
        (device) =>
          device.value !== 'default' && device.value !== 'communications'
      )
  })

  // if there is only one unknown camera left after excluding known devices but integrated camera,
  // ...assume it is integrated camera
  const filteredVideoDevices = devices.videoinput.filter(
    (device) =>
      device.label !== microscopeLabel && device.label !== multifonctionLabel
  )
  if (
    filteredVideoDevices.length === 1 &&
    filteredVideoDevices[0].label !== integratedCameraLabel
  ) {
    filteredVideoDevices[0].label = integratedCameraLabel
  }

  const origin = getParamFromUrl('origin')

  // if there is only one unknown micro left after excluding known devices but integrated micro,
  // ...assume it is integrated micro
  // Excepted if the user is coming from ipm, as in this case we simply removed the
  // integrated micro from the list (see helpersDevices.js)
  const filteredAudioInputDevices = devices.audioinput.filter(
    (device) => device.label !== stethoscopeLabel
  )
  if (
    filteredAudioInputDevices.length === 1 &&
    filteredAudioInputDevices[0].label !== integratedMicroLabel &&
    origin !== IPM_KIOSK_ORIGIN
  ) {
    filteredAudioInputDevices[0].label = integratedMicroLabel
  }

  // open modal if plugging a new device
  if (
    devices.audioinput.length > videoState.deviceOptions.audioinput?.length ||
    devices.videoinput.length > videoState.deviceOptions.videoinput?.length ||
    devices.audiooutput.length > videoState.deviceOptions.audiooutput?.length
  ) {
    dispatch({ type: OPENING_MODAL })
  }

  /** select default device here **/

  // for audio input, if the selected device has been unplugged (or initialization),
  // we select the default one, or the first one
  devices.selectedAudioInput =
    // keep the same micro even when plugging a new one
    devices.audioinput?.find(
      (i) => i.value === videoState.deviceOptions.selectedAudioInput?.value
    ) != null
      ? videoState.deviceOptions.selectedAudioInput
      : // default choice for micro
      devices.audioinput.find((i) =>
        filterForDefaultAudioInput(origin, i.label)
      ) ?? devices.audioinput[0]

  // idem for video input.
  if (
    devices.videoinput?.find(
      (i) => i.value === videoState.deviceOptions.selectedVideoInput?.value
    ) != null
  ) {
    // keep the same camera even when plugging a new one
    devices.selectedVideoInput = videoState.deviceOptions.selectedVideoInput
  } else {
    // default choice for camera, when removing the used camera
    devices.selectedVideoInput =
      devices.videoinput.find((i) =>
        i.label.startsWith('Caméra frontale intégrée')
      ) ?? devices.videoinput[0]
  }

  // idem for audio output
  devices.selectedAudioOutput =
    devices.audiooutput?.find(
      (i) => i.value === videoState.deviceOptions.selectedAudioOutput?.value
    ) != null
      ? videoState.deviceOptions.selectedAudioOutput
      : devices.audiooutput[0]

  // This action will update videoState.deviceOptions.selected{...}.
  // This update will trigger useEffects with "handleSwitch{...}" in VideoSequenceManager,
  dispatch({
    type: SETUP_DEVICE_OPTIONS,
    payload: {
      deviceOptions: devices
    }
  })

  return devices
}

/**
 * Handles connection to the video
 *
 * @param {string} name
 * @param {object} remoteMediaContainer
 * @param {object} localMediaContainer
 * @param {object} videoState
 * @param {function} dispatch
 */
export const connectUser = async ({
  identifier,
  remoteMediaContainer,
  localMediaContainer,
  videoState,
  dispatch,
  encounterID,
  organizationName
}) => {
  // get connected usb devices
  const devicesSelectionOptions = await getDeviceSelectionOptions()

  const devices = await updateDevicesOptions({
    devicesSelectionOptions,
    videoState,
    dispatch
  })

  // Whenever a media device is added or removed, update the list.
  if (navigator.mediaDevices != null) {
    navigator.mediaDevices.ondevicechange = () => {
      dispatch({ type: PLUGGING_DEVICE })
    }
  }

  const connection = await onConnect({
    identity: identifier,
    patientID: videoState.patientID,
    remoteMediaContainer: remoteMediaContainer,
    localMediaContainer: localMediaContainer,
    dispatch: dispatch,
    devices: devices
  })

  // Within the onConnect, we check whether there are already more than two participants
  // in the room. If it is so, the connection will be unsuccessful and will return null
  // so we stop the process here.
  if (connection == null) {
    return
  }

  mixpanel.track(MIXPANEL_VISIO_JOINED, {
    participant: videoState.patientSide ? 'patient' : 'doctor',
    encounterID: encounterID,
    organizationName: organizationName
  })

  const status = videoState.patientSide
    ? 'waitingForPatient'
    : 'waitingForDoctor'

  dispatch({
    type: USER_CONNECTING,
    payload: {
      status: status,
      isConnected: true,
      isVideo: true,
      isAudio: true,
      teleconsultationMaterial: connection,
      webSocket: connection.ekuoreSocket
    }
  })
}

/**
 * Handles disconnection of a participant from the remote consultation
 *
 * @param {object} videoState
 * @param {function} dispatch
 */
export const handleDisconnect = ({ videoState, dispatch, encounterID }) => {
  if (
    videoState.teleconsultationMaterial != null &&
    videoState.teleconsultationMaterial.room != null
  ) {
    // handleDisconnect seems to be called several times for no obvious reasons
    // first it is called with the encounterID the second time no :/
    // It should not be a problem but we might want to solve that in the future
    // for now, we add just a condition to prevent mixpanel to be called x times
    if (encounterID != null) {
      mixpanel.track(MIXPANEL_VISIO_LEFT, {
        participant: videoState.patientSide ? 'patient' : 'doctor',
        encounterID: encounterID
      })
    }

    disconnect({
      videoState,
      dispatch
    })
  }
}

/**
 * Stops video
 *V
 * @param {object} videoState
 * @param {function} dispatch
 */
export const handleStopVideo = ({ videoState, dispatch }) => {
  if (videoState.teleconsultationMaterial.room == null) {
    return
  }

  // convert from Map type to Array type
  const tracks = Array.from(
    videoState.teleconsultationMaterial.room.localParticipant.tracks.values()
  )

  // if the track is published, unpublish it
  if (
    tracks.find(
      (track) =>
        track.trackName === videoState.teleconsultationMaterial.BigVideoTrack.id
    )
  ) {
    videoState.teleconsultationMaterial.room.localParticipant.unpublishTrack(
      videoState.teleconsultationMaterial.BigVideoTrack
    )
    console.log('track vidéo dépubliée')
    const attachedElements = videoState.teleconsultationMaterial.PreviewVideoTrack?.detach()
    attachedElements.forEach((element) => element.remove())
    dispatch({
      type: USER_STOPPING_VIDEO
    })
  }
}

/**
 * Starts video
 *
 * @param {object} videoState
 * @param {function} dispatch
 * @param {object} localMediaContainer
 */
export const handleStartVideo = ({
  videoState,
  dispatch,
  localMediaContainer
}) => {
  if (videoState.teleconsultationMaterial.room == null) {
    return
  }

  const LocalMediaContainer = localMediaContainer.current
  videoState.teleconsultationMaterial.room.localParticipant
    .publishTrack(videoState.teleconsultationMaterial.BigVideoTrack)
    .then(() => {
      // The local video track is detached when it is unpublished.
      // Normally, when switching video, the previous video track is unpublished,
      // ... then removed. But sometimes, for external camera, the track is not
      // ... unpublished, and not removed.
      // To prevent having two tracks displayed on the screen, we delete all
      // ... video child of localMediaContainer before adding a new one.
      if (LocalMediaContainer.hasChildNodes()) {
        var children = LocalMediaContainer.childNodes
        for (var i = 0; i < children.length; i++) {
          if (children[i].tagName === 'VIDEO') {
            LocalMediaContainer.removeChild(children[i])
          }
        }
      }
      attachVideoToDiv(
        localMediaContainer.current,
        videoState.teleconsultationMaterial.PreviewVideoTrack
      )
      console.log('track vidéo publiée')
      dispatch({
        type: USER_STARTING_VIDEO
      })
    })
    .catch((error) => {
      console.log(error, 'error')
      // If the user disconnects too early, the track will not be published
      // and we will get an error. In that case we want to catch the error, to avoid
      // the error going on sentry.
    })
}

/**
 * Handles the possibility to use another camera
 *
 * @param {object} videoState
 * @param {object} dispatch : dispatch action
 * @param {object} localMediaContainer : div to display the preview video
 */
export const handleSwitchVideo = async ({
  videoState,
  dispatch,
  localMediaContainer
}) => {
  handleStopVideo({ videoState, dispatch })
  // handleStopVideo only unpublish tracks.
  // Then we have to stop tracks if exist
  if (
    videoState.teleconsultationMaterial.PreviewVideoTrack != null &&
    videoState.teleconsultationMaterial.BigVideoTrack != null
  ) {
    videoState.teleconsultationMaterial.PreviewVideoTrack.stop()
    videoState.teleconsultationMaterial.BigVideoTrack.stop()
  }
  // start tracks with the new device
  // we try 2 attemps to start the tracks before give up
  let publicationAttemptsCount = 0
  while (publicationAttemptsCount <= 1) {
    try {
      videoState.teleconsultationMaterial.PreviewVideoTrack = await getVideoTrackFromInputDeviceSelection(
        videoState.deviceOptions.selectedVideoInput.value
      )
      videoState.teleconsultationMaterial.BigVideoTrack = await getVideoTrackFromInputDeviceSelection(
        videoState.deviceOptions.selectedVideoInput.value
      )
      publicationAttemptsCount = 2
    } catch (e) {
      console.error(
        e,
        'Error while creating tracks. Device may be disconnected.'
      )
      publicationAttemptsCount += 1
    }
  }
  videoState.teleconsultationMaterial.room.on('disconnected', (room) => {
    disconnectionWorker(
      room,
      videoState.teleconsultationMaterial.PreviewVideoTrack
    )
  })
  // publish new tracks
  handleStartVideo({
    videoState,
    dispatch,
    localMediaContainer
  })
}

/**
 * Stops audio
 *
 * @param {object} videoState
 * @param {function} dispatch
 */
export const handleStopAudio = ({ videoState, dispatch }) => {
  if (videoState.teleconsultationMaterial.room == null) {
    return
  }

  // convert from Map type to Array type
  const tracks = Array.from(
    videoState.teleconsultationMaterial.room.localParticipant.tracks.values()
  )

  // if the track is published, unpublish it
  if (
    tracks.find(
      (track) =>
        track.trackName === videoState.teleconsultationMaterial.audioTrack.id
    )
  ) {
    videoState.teleconsultationMaterial.room.localParticipant.unpublishTrack(
      videoState.teleconsultationMaterial.audioTrack
    )
    dispatch({
      type: USER_STOPPING_MICRO
    })
  }
}

/**
 * Starts audio
 *
 * @param {object} videoState
 * @param {function} dispatch
 */
export const handleStartAudio = ({ videoState, dispatch }) => {
  if (videoState.teleconsultationMaterial.room == null) {
    return
  }

  videoState.teleconsultationMaterial.room.localParticipant
    .publishTrack(videoState.teleconsultationMaterial.audioTrack)
    .then(() => console.log('track audio publiée'))
    .catch((error) => console.log(error, 'error'))

  dispatch({
    type: USER_STARTING_MICRO
  })
}

/**
 * Handles the possibility to use another micro (like stethoscope RNK)
 *
 * @param {object} videoState
 * @param {function} dispatch
 */
export const handleSwitchAudioInput = async ({ videoState, dispatch }) => {
  handleStopAudio({ videoState, dispatch })
  // handleStopAudio only unpublishes tracks.
  // Then we have to stop tracks if exist
  if (videoState.teleconsultationMaterial != null) {
    videoState.teleconsultationMaterial.audioTrack.stop()
    videoState.teleconsultationMaterial.audioTrack = await getAudioTrackFromInputDeviceSelection(
      videoState.deviceOptions.selectedAudioInput
    )
  }

  handleStartAudio({ videoState, dispatch })
}

/**
 * Used to display the indication of the price when the
 * teleconsultation status is completed
 * @param {*} paymentMethod
 * @param {*} price
 */
export const getPriceDisplay = (price, paymentMethod, paymentStatus) => {
  switch (paymentMethod) {
    case 'sepa_debit':
      // we check if the price is up than 0 to put a indication
      return price > 0 ? (
        <Trans>Your bank account will be debited from {price} €</Trans>
      ) : (
        <Trans>
          The remote teleconsultation will be taken care by your health
          organizations
        </Trans>
      )
    case 'card':
      if (price === '0') {
        return (
          <Trans>
            The remote teleconsultation will be taken care by your health
            organizations
          </Trans>
        )
      } else {
        if (paymentStatus === 'Your card was declined.') {
          return (
            <Trans>
              Your card was declined. You have not been debited {price} €
            </Trans>
          )
        } else if (paymentStatus === 'Your card has insufficient funds.') {
          return (
            <Trans>
              Your card has insufficient funds. You have not been debited{' '}
              {price} €
            </Trans>
          )
        } else if (paymentStatus === 'done') {
          return <Trans>You have been debited with {price} €</Trans>
        } else {
          return (
            <Trans>
              Your card had a problem. You have not been debited {price} €
            </Trans>
          )
        }
      }
    case 'no_payment':
    default:
      return (
        <Trans>
          The remote teleconsultation will be taken care by your health
          organizations
        </Trans>
      )
  }
}

/**
 * return the correct hero message of the teleconsultation screen
 * depending on the status
 * @param status
 * @returns {*}
 */
export const getLargeDisplay = (status) => {
  switch (status) {
    case 'unstarted':
      return <Trans>Preconsultation finished</Trans>
    case 'waitingForDoctor':
      return (
        <Trans>Please wait, a patient will be online in a few minutes</Trans>
      )
    case 'waitingForPatient':
      return (
        <Trans>Please wait, a doctor will be online in a few minutes</Trans>
      )
    case 'finishForDoctor':
    case 'finishForPatient':
    case 'teleconsultationCompleted':
      return <Trans>Remote consultation finished</Trans>
    case 'cannotJoin':
      return (
        <Trans>
          There are already two participants in the room, you cannot join it.
        </Trans>
      )
    case 'error':
      return <Trans>Remote consultation closed</Trans>
    default:
      return ''
  }
}

/**
 * Handles the messages coming on the Twilio data track
 * Those ones are used to send instructions from one side
 *  to the other concerning the stethoscope, i.e start, stop, pause etc
 *
 * @param {object} data: data received on the track
 * @param {function} dispatch
 */
export const handleDataReception = (data, dispatch) => {
  const { type, payload } = JSON.parse(data)

  switch (type) {
    case FHIR_OBSERVATION:
      dispatch({ type: ADD_OBSERVATION, payload: payload })
      return
    default:
      return
  }
}

export const organizationStringUrl = (organization) =>
  `${organization?.id}_${organization?.name}_${organization?.address?.[0]?.postalCode}`

/**
 * return the correct subtitle message of the teleconsultation screen
 * depending on the status and the user
 * @param {string} status
 * @returns {Boolean} patientSide
 */
export const getMediumDisplay = (status, patientSide) => {
  switch (status) {
    case 'unstarted':
      return
    case 'waitingForDoctor':
      return (
        <Trans>
          <p>You can take note of pre-consultation form</p>
        </Trans>
      )
    case 'waitingForPatient':
      return (
        <Trans>
          <p>
            The presence of a pharmacist is no longer necessary except for a
            medical exam
          </p>
        </Trans>
      )
    case 'finishForDoctor':
      return (
        <Trans>
          <p>
            Please fill up the form before finishing the remote consultation
          </p>
        </Trans>
      )
    case 'finishForPatient':
      return (
        <Trans>
          <p>Teleconsultation.utils.finishForPatient.0</p>
          <p>Teleconsultation.utils.finishForPatient.1</p>
          <p>Teleconsultation.utils.finishForPatient.2</p>
        </Trans>
      )
    case 'teleconsultationCompleted':
    case 'cannotJoin':
      return
    case 'error':
      // The error status corresponds to the case where a user disconnected and that
      // the other user is now alone in the room.
      // For now, we decide for the pharmacist side to assume that it means the
      // doctor ended the tlc.
      // TODO: automatically disconnect the patient when the doctor completed the tlc,
      // so that we can distinguish between when an error happened and when the tlc was ended.
      if (patientSide) {
        return (
          <Trans>
            <p>Your doctor is preparing your documents,</p>
            <p>please hang up to receive them.</p>
          </Trans>
        )
      } else {
        return (
          <Trans>
            <p>
              It seems that the other participant ended the video conference or
              got an connection error
            </p>
            <p>
              You can close the remote consultation or wait for your contact
            </p>
          </Trans>
        )
      }
    default:
      return ''
  }
}

//this function will be used for the end of teleconsultation in pharmacist side
// It will allow the user to receive document from teleconsultation,
// we extract the item for the linkId we know to be the answer for the document question
// In Document.jsx we will use QuestionnaireResponseItem to display the documents if we have some
export const extractDocumentsFromTeleconsultation = (
  questionnaireResponse,
  documentQuestionnaire
) => {
  const documentQuestionnaireResponse = questionnaireResponse.find(
    (response) => {
      const ref = response?.questionnaire?.reference
      const documentQuestionnaireId = documentQuestionnaire?.id
      return ref === `Questionnaire/${documentQuestionnaireId}`
    }
  )

  return getItemByLinkId(documentQuestionnaireResponse, '1')
}
