import React from 'react'
import moment from 'moment'
import { Trans } from '@lingui/macro'

import { displayGivenName, displayFamilyName } from './entity'
import { MEDEO_ENCOUNTER_TYPE_PRE_TELECONSULTATION } from '../../utils/encounter-type'

export const displayEndDateFromEncounter = encounter =>
  moment(encounter.period.end).format('dddd DD MMM')

export const displayParticipantName = participant => {
  // On vient rappeler la methode avec uniquement le premier participant.
  if (Array.isArray(participant) && participant.length > 0) {
    return displayParticipantName(participant[0])
  }
  if (participant != null) {
    const name = `${displayGivenName(participant)} ${displayFamilyName(
      participant
    )}`
    return <Trans>Created by {name}</Trans>
  }
  // on ne renvoit rien si on n'a pas l'info sur les participants.
}

export const displayEncounterType = encounter => {
  if (encounter == null) {return}
  const code = encounter?.type?.[0]?.coding?.[0]?.code
  switch (code) {
    case MEDEO_ENCOUNTER_TYPE_PRE_TELECONSULTATION:
      return <Trans>Preconsultation form</Trans>
    case 'teleconsultation_consultation':
      return <Trans>Remote consultation</Trans>
    case 'booking-appointment':
      return <Trans>Taking booking</Trans>
    case 'add_patient':
      return <Trans>Create patient</Trans>
    default:
      return <Trans>clinical exam</Trans>
  }
}
