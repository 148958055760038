import React, { ChangeEvent, FC, useContext } from 'react'
import { Trans } from '@lingui/macro'
import { Input } from '../../Components'
import { EditPatientContext } from '../containers/EditPatientForm'
import { HumanName, Patient } from 'fhir-stu3'
import styled from 'styled-components/macro'

const Container = styled.div`
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

const HumanNameInputsRow: FC = ({ ...rest }) => {
  const [resource, setResource] = useContext(EditPatientContext)
  // If the patient has been recorded before december 2019 then the identifier
  // may not have any type defined. We show fallback message instead.
  const name: HumanName[] = resource.name

  const curriedHandleChangeGiven = (index: number) => (
    e: ChangeEvent<HTMLInputElement>
  ) => {
    setResource((r: Patient) => ({
      ...r,
      name: [
        ...(r.name?.slice(0, index) ?? []),
        {
          ...r.name?.[index],
          given: [e.target.value]
        },
        ...(r.name?.slice(index + 1) ?? [])
      ]
    }))
  }

  const curriedHandleFamily = (index: number) => (
    e: ChangeEvent<HTMLInputElement>
  ) => {
    setResource((r: Patient) => ({
      ...r,
      name: [
        ...(r.name?.slice(0, index) ?? []),
        {
          ...r.name?.[index],
          family: e.target.value
        },
        ...(r.name?.slice(index + 1) ?? [])
      ]
    }))
  }

  if (
    name.length === 2 &&
    name[1].use &&
    name[1].use === 'maiden' &&
    name[1].family
  ) {
    return (
      <Container>
        <Input
          {...rest}
          label={<Trans>Given</Trans>}
          onChange={curriedHandleChangeGiven(0)}
          value={name?.[0]?.given?.[0] ?? ''}
        />
        <Input
          {...rest}
          onChange={curriedHandleFamily(0)}
          className="fs-block"
          label={<Trans>Family</Trans>}
          value={name?.[0]?.family ?? ''}
        />
        <Input
          {...rest}
          onChange={curriedHandleFamily(1)}
          className="fs-block"
          label={<Trans>Maiden Name</Trans>}
          value={name?.[1]?.family ?? ''}
        />
      </Container>
    )
  } else if (name?.[1]?.use === 'maiden') {
    return (
      <Container>
        <Input
          {...rest}
          label={<Trans>Given</Trans>}
          onChange={curriedHandleChangeGiven(0)}
          value={name?.[0]?.given?.[0] ?? ''}
        />
        <Input
          {...rest}
          onChange={curriedHandleFamily(0)}
          className="fs-block"
          label={<Trans>Family</Trans>}
          value={name?.[0]?.family ?? ''}
        />
      </Container>
    )
  } else {
    return (
      <Container>
        <Input
          {...rest}
          label={<Trans>Given</Trans>}
          onChange={curriedHandleChangeGiven(0)}
          value={name?.[0]?.given?.[0] ?? ''}
        />
        <Input
          {...rest}
          onChange={curriedHandleFamily(0)}
          className="fs-block"
          label={<Trans>Family</Trans>}
          value={name?.[0]?.family ?? ''}
        />
      </Container>
    )
  }
}

export default HumanNameInputsRow
