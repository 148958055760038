import { ReduxState } from '../Shared/ducks/types'
import { Patient } from 'fhir-stu3'

export const getPatientById = (patientID: string) => (
  state: ReduxState
): Patient | undefined => state.patients.byId[patientID]
export const getPatients = (state: ReduxState): Patient[] =>
  state.patients.allIds.map(i => state.patients.byId[i])

export const getSearchedPatients = (state: ReduxState): Patient[] =>
  state.patients.searchResultId.searchList.map(i => state.patients.byId[i])
