import React, { useContext } from 'react'
import MultipleSearchInput from '../Shared/components/MultipleSearchInput'
import { Trans } from '@lingui/macro'
import * as R from 'ramda'
import { mergeArray, ModelContext, ReadOnlyContext } from '../Yaml/utils'

import InlineList from '../Shared/components/InlineList'
import InlineListItem from '../Shared/components/InlineListItem'
import { displayMedication, queryMedicationFromES } from './utils'
import MedicationDataListItem from '../Medication/components/MedicationDataListItem'
import DefaultMedicationDataListItem from '../Medication/components/DefaultMedicationDataListItem'

const initialState = {
  query: '',
  datalist: []
}

const YamlMedicationStatementInput = ({ yaml }) => {
  const [model, setModel] = useContext(ModelContext)
  const readOnly = useContext(ReadOnlyContext)
  const [state, setState] = React.useState(initialState)

  // yaml.value is $medication
  const lens = R.lensProp(yaml.value)
  const defaultValue = R.view(lens, model) || []

  const handleInputChange = e => {
    setState(s => ({ ...s, query: e.target.value }))
  }
  const handleValueChange = e => {
    const { selectedOptions } = e.target
    setModel(
      mergeArray(
        lens,
        model,
        selectedOptions.map(m => {
          const { display: $display, code: $code } = m?.code?.coding?.[0]
          const $form = m?.form?.coding?.[0]?.display
          return {
            $display,
            $code,
            $form
          }
        })
      )
    )
  }

  // Custom effect to fetch the hits frm AWS ES
  // everytime the user types in a character
  React.useEffect(() => {
    if (state.query.length < 3) {return}
    queryMedicationFromES(state.query).then(datalist => {
      setState(s => ({ ...s, datalist }))
    })
  }, [state.query])

  return readOnly === true ? (
    <InlineList items={defaultValue}>
      {(item, i) => (
        <InlineListItem key={i} color="emerald">
          {item.$display}
        </InlineListItem>
      )}
    </InlineList>
  ) : (
    <MultipleSearchInput
      label={<Trans>Medication List</Trans>}
      defaultDatalist={state.datalist}
      onInputChange={handleInputChange}
      extract={displayMedication}
      DataListItem={MedicationDataListItem}
      DefaultDataListItem={DefaultMedicationDataListItem}
      defaultValue={defaultValue.map(m => ({
        resourceType: 'Medication',
        code: { coding: [{ code: m.$code, display: m.$display }] },
        form: { coding: [{ display: m.$form }] }
      }))}
      onChange={handleValueChange}
    />
  )
}

export default YamlMedicationStatementInput
