import { createTheme } from '@mui/material/styles'
import ManropeTtf from './fonts/Manrope-Regular.ttf'

let theme = createTheme({
  typography: {
    fontFamily: [
      'Manrope-Regular',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ]
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Manrope';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('Manrope'), local('Manrope-Regular'), url(${ManropeTtf}) format('ttf');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      `
    }
  },
  shape: {
    borderRadius: 5
  },
  palette: {
    primary: {
      main: '#0967D2'
    },
    secondary: {
      main: '#edf2ff'
    }
  },
  shadows: Array(25).fill('0px 4px 8px 0px #1b273533')
})

export default theme
