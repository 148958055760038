import EditParamedicalMedicationRequestForm from './EditParamedicalMedicationRequestForm'
import React, { useState, useEffect } from 'react'
import { Trans } from '@lingui/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import Modal from '../../Shared/components/Modal'
import QuestionnaireTextButton from '../../Questionnaire/components/QuestionnaireTextButton'

const ParamedicalMedicationRequestModal = ({
  patientID,
  encounterID,
  practitionerID,
  medicationRequests,
  onSubmit,
  open,
  onChange,
  isSubmitting,
}) => {
  const [state, setState] = useState(medicationRequests[0] ?? null)
  useEffect(() => {
    setState(medicationRequests[0] ?? null)
  }, [medicationRequests])
  return (
    <Modal
      open={open}
      onChange={onChange}
      title={<Trans>Paramedical medication request</Trans>}
      component={({ onClick }) => (
        <QuestionnaireTextButton
          onClick={() => {
            setState(null)
            onClick()
          }}
          data-test={'create-prescription-paramedical'}
        >
          <FontAwesomeIcon icon={faPlus} />
          &nbsp;<Trans>Create a prescription</Trans>
        </QuestionnaireTextButton>
      )}
    >
      {close => (
        <EditParamedicalMedicationRequestForm
          encounter={{ id: encounterID }}
          practitioner={{ id: practitionerID }}
          patient={{ id: patientID }}
          medicationRequest={state}
          onAfterSubmit={async medicationRequest => {
            await onSubmit(medicationRequest)
            close()
          }}
          isSubmitting={isSubmitting}
        />
      )}
    </Modal>
  )
}

ParamedicalMedicationRequestModal.defaultProps = {
  medicationRequests: [],
}

export default ParamedicalMedicationRequestModal
