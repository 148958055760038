import { combineReducers } from 'redux'
import {
  SLOT_DESELECTED,
  SLOT_RECEIVED,
  SLOT_REMOVED,
  SLOT_SELECTED,
  SLOTS_RECEIVED,
} from './actions'
import { FHIR_RESOURCES_RECEIVED } from '../Shared/actions'
import { appendAllUniqueIds, reduceById, removeById } from '../Shared/ducks'
import { getTypeAndIdFromLocalReference } from '../utils/fhir'
import { uniq as unique } from 'lodash'

export const allIds = (state = [], action) => {
  const { type, payload } = action
  switch (type) {
    case FHIR_RESOURCES_RECEIVED:
    case SLOTS_RECEIVED:
      return appendAllUniqueIds(state, payload.Slot)
    case SLOT_RECEIVED:
      return appendAllUniqueIds(state, payload.Slot)
    case SLOT_REMOVED:
      return state.filter(id => id !== payload.slot.id)
    default:
      return state
  }
}
const reduceSlotsBySchedule = (state, slots) =>
  slots?.reduce((state, slot) => {
    const [, scheduleId] = getTypeAndIdFromLocalReference(
      slot?.schedule?.reference
    )
    return {
      ...state,
      [scheduleId]: unique([...(state[scheduleId] ?? []), slot.id]),
    }
  }, state) ?? state

export const bySchedule = (state = {}, action) => {
  const { payload, type } = action
  switch (type) {
    case FHIR_RESOURCES_RECEIVED:
      return reduceSlotsBySchedule(state, payload.Slot)
    default:
      return state
  }
}
export const byId = (state = {}, action) => {
  const { payload, type } = action
  switch (type) {
    case SLOT_RECEIVED:
      return { ...state, [payload.slot.id]: payload.Slot }
    case FHIR_RESOURCES_RECEIVED:
    case SLOTS_RECEIVED:
      return reduceById(state, payload.Slot)
    case SLOT_REMOVED:
      return removeById(state, payload.slot.id)
    default:
      return state
  }
}

export const currentBookedSlot = (state = null, action) => {
  const { payload, type } = action
  switch (type) {
    case SLOT_DESELECTED:
      return null
    case SLOT_SELECTED:
      return payload.slot
    default:
      return state
  }
}

export default combineReducers({
  allIds,
  byId,
  bySchedule,
  currentBookedSlot,
})
