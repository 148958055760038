import ControlButton from './AttachmentControlButton'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import React from 'react'
import { useI18n } from '../../utils/I18nHookAdapter'
import { t } from '@lingui/macro'

/**
 * Archive action cannot be tied to a generic method, depending on which
 * fhir resource an Attachment is bond we need to define a specific handler
 * for archiving or deleting the attachment
 * This component expose an onClick handler for this reason
 * @param onClick
 * @returns {*}
 * @constructor
 */
const AttachmentArchiveControl = ({ onClick }) => {
  // we are using @lingui/core@2.9 which does not provide the useLingui hook
  // we need to use a custom hook instead:
  const i18n = useI18n()
  const title = i18n._(t`Archive`)
  return <ControlButton title={title} icon={faTrash} onClick={onClick} />
}

export default AttachmentArchiveControl
