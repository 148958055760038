import React from 'react'
import styled from 'styled-components/macro'
import { getTypeAndIdFromLocalReference } from '../../utils/fhir/index'
import ObservationErrorBoundary from './ObservationErrorBoundary'
import ObservationView from './ObservationView'

const Container = styled.div`
  width: 100%;
`

/**
 * Indicate whether the observation is related to another one or not.
 *
 * @param {Observation} observation
 */
const isARelatedObservation = observation => {
  return (
    observation.related?.find(
      relation =>
        relation.type === 'derived-from' &&
        getTypeAndIdFromLocalReference(relation.target?.reference)[0] ===
          'Observation'
    ) == null
  )
}

const ObservationTypeView = ({
  observations,
  observationType,
  isActive,
  loincCodes,
  readOnly,
}) => {
  return (
    <ObservationErrorBoundary>
      <Container>
        {observations.length === 0 ? (
          <ObservationView
            observation={null}
            isActive={isActive}
            showContextMenu={loincCodes != null}
            observationType={observationType}
            readOnly={readOnly}
            isEmpty={true}
          />
        ) : (
          observations
            // Right now the only observations containing related observations
            // are the blood sugar ones.
            // As they are handled separately from the other observations,
            // we filter them out here.
            .filter(observation => isARelatedObservation(observation))
            .map((observation, i) => (
              <ObservationView
                key={i}
                observation={observation}
                isActive={isActive}
                showContextMenu={loincCodes != null}
                observationType={observationType}
                readOnly={readOnly}
              />
            ))
        )}
      </Container>
    </ObservationErrorBoundary>
  )
}

export default ObservationTypeView
