import styled from 'styled-components/macro'

const Header = styled.header`
  font-weight: bold;
  font-size: larger;
  border-top: 1px solid ${p => p.theme.gray};
  padding-top: 1.5rem;
  margin-bottom: 1.5rem;
`
export default Header
