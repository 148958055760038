import moment from 'moment'

/**
 * Takes a single day schedule and extract all slots from the planningHorizon
 * @param schedule
 * @param busySlots
 * @param performerId
 *  * @param currentlySelectedSlot,
 * @returns {[]} array of slots with start, end, display and scheduleId
 */
export const createSlotsFromSchedule = (
  schedule,
  busySlots = [], // FHIR slots are seen as busySlot
  performerId,
  currentlySelectedSlot
) => {
  const start = moment(schedule.planningHorizon.start, 'YYYY-MM-DD hh:mm a')
  const end = moment(schedule.planningHorizon.end, 'YYYY-MM-DD hh:mm a')
  const now = moment()

  // round starting minutes up to nearest 15 (12 --> 15, 17 --> 30)
  // note that 59 will round up to 60, and moment.js handles that correctly
  start.minutes(Math.ceil(start.minutes() / 15) * 15)

  let result = []
  const current = moment(start)
  while (current < end) {
    const currentDate = current.format('YYYY-MM-DD')
    const currentStartHour = current.format('HH:mm')
    const currentEndHour = current.add(15, 'minutes').format('HH:mm:ss')

    const startString = `${currentDate}T${currentStartHour}:00`
    const endString = `${currentDate}T${currentEndHour}`
    result.push({
      display: currentStartHour,
      start: startString,
      end: endString,
      selected: false,
      scheduleId: schedule.id,
      performerId: performerId,
    })
  }

  // at this point we have an array of slots of every 15min per schedule
  // next step is to
  // 1. filter out all the slots at occur after the current time
  // 2. filter out all the fake slots that have the same start date as busySlots (FHIR slot)
  // 3. to make sure that we display the selected slot (if there is one)
  return result.filter(
    slot =>
      moment(slot?.start).isAfter(now) &&
      (!busySlots.some(busySlot => busySlot.start === slot.start) ||
        currentlySelectedSlot?.start === slot.start)
  )
}
export const sortByDate = (a, b) => {
  return moment(a) - moment(b)
}

/**
 * curried filter function that check whether the slot is on the same date
 * @param day
 * @return {function(*): boolean}
 */
const bySameDay = day => slot => moment(slot.start).isSame(moment(day), 'day')

/**
 * takes a period and return the list of days within the period
 * @param {Period} period
 * @return {[]}
 */
const range = ({ start, end }) => {
  let intervals = []
  while (moment(end).diff(start, 'day') >= 1) {
    intervals.push(moment(start).format('YYYY-MM-DD'))
    start = moment(moment(start).add(1, 'day')).format('YYYY-MM-DD')
  }
  return intervals
}

/**
 * curried function to transform slots in a entry keyed with the day
 * @param slots
 * @return {function(*=): (*|*[])[]}
 */
const toEntry = slots => day => [day, slots?.filter?.(bySameDay(day)) ?? []]

/**
 * Creates an object with days as keys and the corresponding slots as values
 * @param {Period} period
 * @param {[Slot]} slots
 * @return {{[p: string]: any} | any}
 */
export const reduceSlotsOverPeriod = (period, slots) => {
  return Object.fromEntries(range(period).map(toEntry(slots)))
}

/**
 * Inform on wether a slot is still bookable,
 * meaning it is not in the past and still has a 'free' status
 *
 * @param {*} slot
 * @returns {Boolean}
 */
export const isBookable = slot => {
  return slot.status === 'free' && moment(slot.start) > moment()
}
