import { Card } from '../../Components'
import DefaultEncounterForm from '../../Encounter/components/DefaultEncounterForm'
import NewEncounterCard from '../../Encounter/containers/NewEncounterCard'
import QuestionnaireMedicationStatement from '../../MedicationStatement/QuestionnaireMedicationStatement'
import QuestionnaireFile from '../../Questionnaire/components/QuestionnaireFile'
import QuestionnaireRepeatableDocument from '../../Questionnaire/components/QuestionnaireRepeatableDocument'
import QuestionnaireSequencer from '../../Questionnaire/components/QuestionnaireSequencer'
import QuestionnaireTotalPrice from '../../QuestionnaireResponse/components/QuestionnaireTotalPrice'
import SelectPatientResponse from '../../QuestionnaireResponse/components/SelectPatientResponse'
import SelectPerformerQuestionnaireResponse from '../../QuestionnaireResponse/components/SelectPerformerQuestionnaireResponse'
import { QuestionnaireResponseActionRecap } from '../../RequestGroup/components/QuestionnaireResponseAction'
import {
  MEDEO_ENCOUNTER_TYPE_BOOKING, MEDEO_ENCOUNTER_TYPE_CLINICAL_EXAM,
  MEDEO_ENCOUNTER_TYPE_PRE_TELECONSULTATION
} from '../../utils/encounter-type'
import { getQuestionnaireIdentifierByAction } from '../utils'
import PlanDefinitionActionDecoration from './PlanDefinitionActionDecoration'

import { useSelector } from 'react-redux'
import { getActiveDocumentsByencounterID } from '../../DocumentReference/ducks'
import usePlanDefinition from '../usePlanDefinition'

// TODO: update systems to proper ones like https://medeo.io/fhir/ValueSet/medeo-action-definition

export const MEDEO_ACTION_DEFINITION_SYSTEM = 'medeo-action-definition'
export const MEDEO_DISPLAY_ON_READ_ONLY_SYSTEM = 'medeo-display-on-readonly'

const Wrapper = ({ children, patient, shouldWrapInCard }) => {
  // we use this condition to apply a margin to the Card element when
  // the appointment is booked from the calendar since this same component is used
  // during the preteleconsultation and we don't want to apply the margin in that case.
  // we know we are booking from the calendar when the patient is null
  const isBookingFromCalendar = patient == null
  return shouldWrapInCard ? (
    <Card shouldHaveMargin={isBookingFromCalendar}>{children}</Card>
  ) : (
    children
  )
}

/**
 * Encounter choice
 * @param action
 * @param readOnly
 * @param encounter
 * @param patientID
 * @param isColumn
 * @returns {null|*}
 * @constructor
 */
const PlanDefinitionAction = ({
  action,
  readOnly,
  encounter,
  patient,
  isColumn = false,
  observations = []
}) => {
  const { planDefinition } = usePlanDefinition()

  // we need to check if documents and observations are saved for the encounter
  // in order to avoid to display Examen Clinique when there's nothing to display

  const documentsFromEncounter = useSelector(
    getActiveDocumentsByencounterID(encounter?.id)
  )

  // Display the right component
  const codeDisplay = action?.code?.[0]?.coding.find(
    c => c.system === MEDEO_DISPLAY_ON_READ_ONLY_SYSTEM
  )?.code

  // codeDisplay might not be set for every action
  // if (readOnly === false || codeDisplay === 'true') will hide the action if codeDisplay
  // with the invert condition we can treat missing codeDisplay correctly
  if (readOnly === true && codeDisplay === 'false') {return null}

  // here we get the current action
  const codeAction = action?.code?.find(
    code => code?.coding?.[0]?.system === MEDEO_ACTION_DEFINITION_SYSTEM
  )?.coding?.[0]?.code

  switch (codeAction) {
    case 'medical-statement':
      if (!readOnly)
        {return (
          <Wrapper shouldWrapInCard={true} patient={patient}>
            <QuestionnaireMedicationStatement
              patient={patient}
              readOnly={readOnly}
              encounter={encounter}
            />
          </Wrapper>
        )}
      else {return null}
    case 'questionnaire':
      // Here we get the identifier of the questionnaire
      const [questionnaireIdentifier] = getQuestionnaireIdentifierByAction(
        action
      )
      // we get display the Card only if we are in pre teleconsultation
      const encounterType = encounter?.type?.[0]?.coding?.[0]?.code

      const shouldWrapInCard =
        encounterType === MEDEO_ENCOUNTER_TYPE_PRE_TELECONSULTATION ||
        encounterType === MEDEO_ENCOUNTER_TYPE_BOOKING
      if (readOnly === false) {
        return (
          <Wrapper
            shouldWrapInCard={shouldWrapInCard}
            encounterType={encounterType}
            patient={patient}
          >
            <QuestionnaireSequencer
              identifier={questionnaireIdentifier}
              readOnly={readOnly}
              isColumn={isColumn}
              isCard={shouldWrapInCard} // style in card
              patient={patient}
            />
          </Wrapper>
        )
      } else {
        // As the response was not saved yet, we cannot use the RequestGroup component
        // And need to rely on this intermediate component to display the elements we want
        return (
          <QuestionnaireResponseActionRecap
            questionnaireIdentifier={questionnaireIdentifier}
            planDefinitionContext={planDefinition}
          />
        )
      }
    case MEDEO_ENCOUNTER_TYPE_CLINICAL_EXAM:
      if (readOnly === false) {
        return (
          <NewEncounterCard
            encounter={encounter}
            isFromTeleconsultation={true}
          />
        )
      } else {
        if (documentsFromEncounter.length > 0 || observations.length > 0) {
          return (
            <div>
              <h4>{action.title}</h4>
              <DefaultEncounterForm
                encounter={encounter}
                patient={patient}
                readOnly={true}
                observations={observations}
              />
            </div>
          )
        } else {return null}
      }
    case 'button':
      // For Facturation Orders or Document we pass through this case
      return !action.action || readOnly ? (
        <></>
      ) : (
        <>
          {action.action.map((a, i) => {
            return (
              <QuestionnaireRepeatableDocument
                action={a}
                key={i}
                name={action.title}
                readOnly={readOnly}
              />
            )
          })}
        </>
      )
    case 'document':
      return <QuestionnaireFile readOnly={readOnly} />
    case 'totalPrice':
      return (
        <QuestionnaireTotalPrice
          action={action}
          readOnly={readOnly}
          encounter={encounter}
        />
      )
    // used in preconsultation
    case 'select-practitioner':
      return <SelectPerformerQuestionnaireResponse />
    case 'select-patient':
      return <SelectPatientResponse />
    default:
      const actions = action?.action ?? []
      return !actions ? (
        <p>no actions</p>
      ) : (
        actions.map(a => (
          <PlanDefinitionActionDecoration
            key={a.title}
            action={a}
            readOnly={readOnly}
          >
            <PlanDefinitionAction
              action={a}
              encounter={encounter}
              patient={patient}
              readOnly={readOnly}
              isColumn={isColumn}
            />
          </PlanDefinitionActionDecoration>
        ))
      )
  }
}

export default PlanDefinitionAction
