import React, { useState, createContext } from 'react'

export const CurrentSearchBarValueContext = createContext(['', () => {}])

const CurrentSearchBarValueProvider = ({ children, initialState = '' }) => {
  const [search, setSearch] = useState(initialState)
  return (
    <CurrentSearchBarValueContext.Provider value={[search, setSearch]}>
      {children}
    </CurrentSearchBarValueContext.Provider>
  )
}
export default CurrentSearchBarValueProvider
