import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Trans } from '@lingui/macro'
import { useEffect, useReducer, useRef } from 'react'
import styled from 'styled-components/macro'
import { getTokenFromAmplify } from '../../Auth/utils'
import { Button } from '../../Components'
import { FHIR_BASE_URL } from '../../_common/config'
import { postBinaryWithProgress } from '../utils'
const HiddenInput = styled.input.attrs({ type: 'file' })`
  display: none;
`

const CustomButton = styled(Button)`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.5rem;
  line-height: 0.7rem;
  padding: 2.5rem 0.5rem;
  border-radius: 0.25rem;
  border: 2px dashed ${p => p.theme.ocean};
  color: ${p => p.theme.gray};
  outline: none;
  transition: border 0.24s ease-in-out;
  height: 8rem;
  width: 8rem;
`

const Text = styled.div`
  font-size: 0.5rem;
`

const AttachmentFeedback = styled.p`
  font-size: 0.8rem;
  color: ${p => p.theme.scarlett};
`

const initialState = {
  progress: 0,
  attachment: null, // when loaded it should have the following props
  // { contentType,  url, size, title, creation }
  status: 'idle' // uploading, completed, failed,
}

const reducer = (state, action) => {
  const { type, payload } = action
  switch (type) {
    case 'reset':
      return { ...initialState }
    case 'uploading':
      return {
        ...state,
        attachment: {
          ...payload
        },
        status: 'uploading'
      }
    case 'uploaded':
      return {
        ...state,
        attachment: {
          ...state.attachment,
          url: payload
        },
        status: 'completed'
      }
    case 'error':
      return {
        ...state,
        attachment: null,
        status: 'failed'
      }
    default:
      return state
  }
}

/**
 * AttachmentInput works like an <input type="file"/> and looks like a Button
 * Its value is formatted as a FHIR Attachment.
 * This format is preferred to any other as it is the common building
 * block used in:
 * <ul>
 *  <li> a DocumentReference </li>
 *  <li> a QuestionnaireResponse.item </li>
 * </ul>
 *
 *
 * @see http://hl7.org/fhir/STU3/datatypes.html#Attachment
 * @param label
 * @param value
 * @param required
 * @param onChange
 * @param onProgress
 * @returns {*}
 * @constructor
 */
const AttachmentInput = ({
  label,
  required,
  onChange,
  onProgress,
  fileType,
  onError,
  ...rest
}) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const input = useRef(null)
  const acceptance = fileType === 'image' ? 'image/*' : ''

  // every time the state attachment is updated we notify via the onChange
  // listener.
  // Note: it will be fired multiple times.
  // 1. when the user select a file, where fields like title or contentType are available
  // 2. when the file is uploaded to the server, where the url field is going to be entered
  // see handleChange for more details
  useEffect(() => {
    // null is the initialState for the attachment
    // no need to run the listener when the component is mounting
    if (state.attachment != null) {
      onChange(state.attachment)
      // NOTE: without the following reset you can't add multiple time the same file
      // from the same instance of this component...
      // this is due to the fact that the onChange on the <input type="file"/> does not
      // detect a change in the selected file !
      if (input.current != null)
        {input.current.value = input.current.defaultValue}
    }
    //eslint-disable-next-line
  }, [state.attachment, input.current])

  // this use effect will delete the message after 5 seconds
  // we have the access to the state using rest
  // since the message is on a parent component 'AddAttachmentForm'
  useEffect(() => {
    if (rest.feedBackMessage != null) {
      setTimeout(() => {
        onError(null)
      }, 5000)
    }
    //eslint-disable-next-line
  }, [rest.feedBackMessage, onError])

  // Callback function for document progress event
  // it dispatch a progress action with percentage of upload done
  // it is used to notify the progress to the user.
  const handleProgress = e => {
    onProgress(Math.ceil((e.loaded / e.total) * 100))
  }

  // handleClick reroutes the click on the
  // <Button /> to the <input type=file>
  // these inputs UI can not be customized. This is why
  // it is hidden and this little trick is required.
  const handleClick = e => {
    e.preventDefault()
    dispatch({
      type: 'reset'
    })
    if (input.current != null) {
      input.current.click()
    }
  }

  // Once the user selects a file, we trigger the post of the Binary to the database
  // we chose to do so because Binary are a bit hard to work with
  // and we need more controls about progress and the format...
  const handleChange = async e => {
    // handleChange handles also when dropping a file
    let file = null

    // check if file was uploaded manually
    if (e.target.files != null) {
      file = e.target.files[0]

      // check if file was dropped
    } else if (e.dataTransfer.files != null) {
      const DnDFiles = [...e.dataTransfer.files]
      file = DnDFiles[0]

      // for this moment we only handle one file
      //todo: handle multiple files drop (MED-2183)
      if (e.dataTransfer.files.length > 1) {
        onError(
          <AttachmentFeedback>
            <Trans>Warning: Only one file can be dragged at a time</Trans>
          </AttachmentFeedback>
        )
      } else {onError(null)}
    }

    if (file == null) {
      console.warn('no file')
      return
    }

    const givenFileType = file.type?.split('/')?.[0]

    if (givenFileType !== 'image' && fileType === 'image') {
      rest.setFeedBackMessage(
        <AttachmentFeedback>
          <Trans>
            Warning: the file must be of type "{fileType}" (.jpg, .png)
          </Trans>
        </AttachmentFeedback>
      )
      return
    }

    let token = null
    if (process.env.NODE_ENV !== 'test') {
      token = await getTokenFromAmplify()
    }
    // when starting to download, we update the state with the first info
    // we got from the file, it's type and the name
    // the location will come after the upload.
    // this dispatch should trigger the onChange listener once the state is
    // updated.
    dispatch({
      type: 'uploading',
      payload: {
        contentType: file.type,
        title: file.name
      }
    })

    // fetch is not supporting onProgress event
    // we fallback to an xhr post request here.
    // it will return the url of the binary once done.
    try {
      const binaryLocation = await postBinaryWithProgress(
        `${FHIR_BASE_URL}Binary`,
        {
          method: 'post',
          headers: {
            Authorization: `Bearer ${token}`
          },
          body: file
        },
        handleProgress
      )

      // right after the upload we can dispatch the action
      // to change the state of this component to uploaded.
      // it should update the inner state attachment attribute with
      // the last bit of information missing: the url of the binary
      // an onChange event will be caused from this dispatch.
      dispatch({
        type: 'uploaded',
        payload: binaryLocation
      })
    } catch {
      dispatch({
        type: 'error'
      })
    }
  }

  // Event triggers when a file is dragged into the dropZone
  const handleDragOver = e => {
    e.preventDefault()
    e.stopPropagation()
    // It will show a '+' green icon next to the selected files
    e.dataTransfer.dropEffect = 'copy'
  }

  // Event triggers when a file is dragged out the dropZone
  const handleDragLeave = e => {
    e.preventDefault()
    e.stopPropagation()
  }

  return (
    <div>
      <CustomButton
        aria-label="upload button" // used for testing
        variant="outline"
        color="ocean"
        type="button"
        {...rest}
        onClick={handleClick}
        onDrop={handleChange}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        disabled={state.status === 'uploading'}
        required={required}
      >
        <FontAwesomeIcon icon={faPlus} />
        &nbsp;
        <Text>
          <Trans>Drop a file or click here to import</Trans>
        </Text>
      </CustomButton>
      {state.status === 'failed' && <Trans>Something went wrong!</Trans>}
      <HiddenInput
        aria-label={label}
        accept={acceptance}
        ref={input}
        onChange={handleChange}
      />
    </div>
  )
}

AttachmentInput.defaultProps = {
  label: null,
  children: <Trans>Drop a file or click here to import</Trans>,
  onChange: () => {},
  onProgress: () => {},
  onError: () => {}
}

export default AttachmentInput
