export const EDITING_PRACTITIONER = 'EDITING_PRACTITIONER'
export const STARTING_CAMERA = 'STARTING_CAMERA'
export const PICTURE_TAKEN = 'PICTURE_TAKEN'
export const UPLOADING_PICTURE = 'UPLOADING_PICTURE'
export const DELETING_PICTURE = 'DELETING_PICTURE'
export const RESET = 'RESET'
export const CREATING_PRACTITIONER = 'CREATING_PRACTITIONER'

export const editPractitioner = payload => ({
  type: EDITING_PRACTITIONER,
  payload: payload,
})

export const createPractitioner = payload => ({
  type: CREATING_PRACTITIONER,
  payload: payload,
})
