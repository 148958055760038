import { Trans } from '@lingui/macro'
import {
  AlertTitle,
  Button,
  CardActions,
  CardContent,
  // CircularProgress,
  Stack,
  Typography
} from '@mui/material'
import { Redirect, RouteComponentProps, useLocation } from '@reach/router'
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { StripePaymentElementOptions } from '@stripe/stripe-js'
import { FC, FormEventHandler, useState } from 'react'
import CustomAlert from './CustomAlert'
import { useCheckoutDetails, useRetrievePaymentStatus } from './hooks'
import LoadingCheckoutCardContent from './LoadingCheckoutCardContent'
import { getBillingDetailsFromPatient } from './utils'
interface CheckoutContainerProps extends RouteComponentProps {
  appointmentId?: string
}

const CheckoutContainer: FC<CheckoutContainerProps> = ({ appointmentId }) => {
  if (appointmentId == null) {
    throw new Error('appointmentId should be defined')
  }
  const { data } = useCheckoutDetails(appointmentId)

  const stripe = useStripe()
  const elements = useElements()
  const [message, setMessage] = useState<string | null>(null)
  const location = useLocation()
  const { status } = useRetrievePaymentStatus(appointmentId)
  // const { status, isLoading: isLoadingToo } = useRetrievePaymentStatus(appointmentId)
  if (data == null) {
    return <LoadingCheckoutCardContent />
  }

  const { patient } = data
  const billingDetails = getBillingDetailsFromPatient(patient)
  const handleSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault()
    if (!stripe || !elements) {
      return
    }
    const { error } = await stripe.confirmSetup({
      elements: elements,
      confirmParams: {
        return_url: location.href + '/done'
      }
    })
    if (error?.message != null) {
      setMessage(error.message)
    }
  }

  const resetMessage = () => {
    setMessage(null)
  }
  const options: StripePaymentElementOptions = {
    defaultValues: { billingDetails }
  }

  const disabled = message != null

  if (status === 'succeeded') {
    return <Redirect to="done" noThrow />
  }

  return (
    <form id="payment" onSubmit={handleSubmit}>
      <CardContent>
        <Stack direction="column" spacing={4}>
          <Stack direction="column" spacing={1}>
            <Typography>
              Afin d'assurer le règlement de votre téléconsultation, nous allons
              réaliser une empreinte bancaire.
            </Typography>
            <Typography>
              A la fin de la téléconsultation, le médecin vous prélèvera le
              montant correspondant à votre type de consultation et à votre
              situation (exonération, tiers-payant partiel, etc…). Une feuille
              de soin vous sera remise ou sera envoyée directement à votre
              caisse d’assurance maladie pour bénéficier du remboursement.
            </Typography>
          </Stack>
          <PaymentElement options={options} onChange={resetMessage} />
          {message && (
            <CustomAlert severity="error" variant="outlined">
              <AlertTitle>
                <Trans>An error occurred</Trans>
              </AlertTitle>
              {message}
            </CustomAlert>
          )}
        </Stack>
      </CardContent>

      <CardActions style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          variant="contained"
          disabled={disabled}
          form="payment"
          type="submit"
        >
          <Trans>Continue</Trans>
        </Button>
      </CardActions>
    </form>
  )
}

export default CheckoutContainer
