import moment from 'moment';
import * as R from 'ramda';
import { call, put, takeEvery, all } from 'redux-saga/effects';
import * as graphlibCore from '@dagrejs/graphlib';
import { saveFhirResourceWorker, searchFhirResourceWorker } from './index';
import { SAVING_MODEL, saveModel, getSavedActionName } from '../actions/db';
import { FHIR_DATE_FORMAT } from '../../utils/dateUtils';
import { createPractitioner } from '../utils';
import {
  MEDEO_GENERAL_PRACTITIONER_IDENTIFIER_MEDECIN_TRAITANT_CODE,
  MEDEO_GENERAL_PRACTITIONER_IDENTIFIER_SYSTEM
} from '../../Patient/sagas';
import { saveMedicationAndMedicationStatementWorker } from '../../MedicationStatement/sagas';
import { getTokenFromAmplify } from '../../Auth/utils';
import { API, Auth } from 'aws-amplify';

async function postConsentPdf(patient) {
  const response = await API.post('documents', '/consent', {
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getAccessToken()
        .getJwtToken()}`
    },
    body: patient
  });

  return response;
}

export const triggerSendEmailLambda = async patientID => {
  const response = await API.get('email', '/email', {
    queryStringParameters: {
      patientID: patientID
    }
  }).catch(error => {
    console.log(error.response);
  });
  return response;
};

export const convertToFhirModel = (model, formModel) => {
  let builtModel = Object.keys(formModel).reduce((str, field) => {
    // if the current field has not been defined simply return the string
    // otherwise this case could be caught by the next condition which causes
    // strange behavior
    // like putting extra "," in the string
    // whatever happens builtModel is sanitize later on with the condition on
    // placeholders
    if (formModel[field] == null) {
      return str;
    } else if (
      // sanitize everywhere but in generalPractitionerInputRow
      (field === 'display' && formModel[field].startsWith('{"name')) ||
      typeof formModel[field] !== 'string'
    ) {
      return str.split(field).join(formModel[field]);
    } else if (isNaN(formModel[field])) {
      const escaped = JSON.stringify(
        formModel[field]
          .replace(/\\?"\$(.*?)"/gi, 'null')
          .replace(/\\n/gi, '\\n')
          .replace(/\\'/gi, "\\'")
          .replace(/\\"/gi, '\\"')
          .replace(/\\&/gi, '\\&')
          .replace(/\\r/gi, '\\r')
          .replace(/\\t/gi, '\\t')
          .replace(/\\b/gi, '\\b')
          .replace(/\\f/gi, '\\f')
      );

      // we receive "value" instead value when we escape
      return str.split(`"${field}"`).join(escaped);
    }
    // formModel[field] is a number
    // parseFloat() will delete the '0' if the number starts by '0'
    // we don't use parseFloat() for phone numbers
    if (
      field === '$telephonePortable' ||
      field === '$telephoneFixe' ||
      field === '$nextOfKinTelephone'
    ) {
      return str.split(field).join(formModel[field]);
    } else {
      return str.split(field).join(parseFloat(formModel[field]));
    }
  }, JSON.stringify(model));

  //replace the placeholders with null
  builtModel = builtModel.replace(/\\?"\$(.*?)"/gi, 'null');
  return JSON.parse(builtModel);
};

// if we try to import this part from DocumentReference saga we get a POST request
// and the binary isn't saved in database. The part is similar but if it's outside
// of this file we can't do a PUT request. let see for optimisation

export function* saveModelWorker(action) {
  const now = moment().format(FHIR_DATE_FORMAT);
  const graph = graphlibCore.json.read(action.payload.graph);
  const form = action.payload.form;
  const fhir = action.payload.fhir;
  const refs = action.payload.refs;
  const rootNode = action.payload.rootNode;
  const token = yield call(getTokenFromAmplify);
  // Get the resources at this node
  const resources = [].concat(graph.node(rootNode) || []);
  let updatedRefs = {
    ...refs
  };

  // Apply the form to that resource
  for (let i = 0; i < resources.length; i++) {
    const resource = resources[i];
    const fhirResource = fhir[resource.type];
    const formModel = form[resource.name];

    let modelsToBuild;
    if (!formModel) {
      modelsToBuild = [];
    } else if (Array.isArray(formModel)) {
      modelsToBuild = formModel;
    } else {
      modelsToBuild = [formModel];
    }
    if (fhirResource.resourceType === 'Encounter') {
      fhirResource.period = { start: now, end: now };
      // for the encounter there is no model coming from the form,
      // so we add one empty to trigger the process.
      modelsToBuild = [''];
    }
    // here we're generating a PDF for the consent in order to access whenever we want
    if (fhirResource.resourceType === 'Consent') {
      fhirResource.dateTime = now;
      fhirResource.organization = {
        reference: `Organization/${form.$consent.$organization}`
      };
      fhirResource.category = [
        {
          coding: [
            {
              system: 'www.medeo-health.com/patient-consent-version',
              code: '1.1'
            }
          ]
        }
      ];
      // we need to do it without the patient id because, we are not sure that the patient is created before calling the lambda
      const pdfDocumentReference = yield call(
        postConsentPdf,
        {
          firstName: form.$patient.$given,
          lastName: form.$patient.$surname,
          phone: form.$patient.$telephonePortable,
          address: `${form.$patient.$addressLine} ${form.$patient.$city} ${form.$patient.$country}`
        },
        token
      );
      fhirResource.sourceAttachment = {
        contentType: 'application/pdf',
        url: pdfDocumentReference.url,
        title: 'Consentement.pdf'
      };
      // for the consent there is no model coming from the form,
      // so we add one empty to trigger the process.
      modelsToBuild = [''];
    }

    // in the YamlAddPatientForm we will search for a generalPractitoner with AWS Elastic search
    // We need to check if the practitioner exist on our database, so we call the server here
    // if it does not exist on the database we simply add it
    // then we add it to the generalPractitioner Field of the patient we are creating.
    // The general practitioner has a specific type to distinguish him from other practitioner that could be added
    // to the patient.
    // This condition check if the user add a practitioner during add patient form
    // If it's the case we create a Practitioner resource in database otherwise we do nothing :)
    if (
      fhirResource.resourceType === 'Patient' &&
      form['$generalPractitioner'] != null &&
      form['$generalPractitioner'] !== 'no'
    ) {
      const generalPractitionerField = form['$generalPractitioner'];
      let practitionerCall = yield call(
        searchFhirResourceWorker,
        'Practitioner',
        () => ({
          identifier: `http://medeo.io/fhir/Identifier/numero-rpps|${generalPractitionerField.rpps}`
        })
      );
      practitionerCall = practitionerCall.payload;
      if (practitionerCall.Practitioner != null) {
        fhirResource.generalPractitioner = {
          reference: 'Practitioner/' + practitionerCall.Practitioner[0].id,
          identifier: {
            system: MEDEO_GENERAL_PRACTITIONER_IDENTIFIER_SYSTEM,
            value: MEDEO_GENERAL_PRACTITIONER_IDENTIFIER_MEDECIN_TRAITANT_CODE
          }
        };
      } else {
        let newPractitioner = yield call(
          saveFhirResourceWorker,
          createPractitioner(generalPractitionerField)
        );
        fhirResource.generalPractitioner = {
          reference: 'Practitioner/' + newPractitioner.id,
          identifier: {
            system: MEDEO_GENERAL_PRACTITIONER_IDENTIFIER_SYSTEM,
            value: MEDEO_GENERAL_PRACTITIONER_IDENTIFIER_MEDECIN_TRAITANT_CODE
          }
        };
      }
    }

    for (let j = 0; j < modelsToBuild.length; j++) {
      if (fhirResource.resourceType === 'DocumentReference') {
        const reference = modelsToBuild[0].$file;
        if (reference != null) {
          // We update the document reference with file data and id of binary
          fhirResource.indexed = moment(reference.creation).format(
            FHIR_DATE_FORMAT
          );
          fhirResource.content = [
            {
              attachment: {
                contentType: reference.contentType,
                url: reference.url,
                title: reference.title,
                creation: moment(reference.creation).format(FHIR_DATE_FORMAT)
              }
            }
          ];
        }
      }
      const builtModel = convertToFhirModel(fhirResource, {
        ...modelsToBuild[j],
        ...R.map(refValue => {
          if (refValue && refValue.startsWith('$')) {
            return R.view(R.lensPath(refValue.split('.')), refs);
          }
          return refValue;
        }, resource.refs || {})
      });

      let savedModel;

      if (builtModel.resourceType === 'Medication') {
        // this condition is required because "Traitement en cours" cannot be added directly
        // using the yaml engine. We want both Medication and MedicationStatement to be
        // from a single input...
        //
        // Moreover we want to add the Medication only if they don't exist on the database !
        // We cannot apply a similar process as GP for the Patient

        const { $patient: patient, $encounter: encounter } = updatedRefs;
        savedModel = yield call(
          saveMedicationAndMedicationStatementWorker,
          builtModel,
          patient,
          encounter
        );

        // refs are passed too, we want info of the patient and of the encounter
        // to create the MedicationStatement
      } else {
        // for any other type of resource we just save the resource
        savedModel = yield call(saveFhirResourceWorker, builtModel);
      }

      //the lambda has to be triggered at the same time the patient infos are being saved in db.
      //that's the reason why we directly take the patient ID from the savedModel and pass it as
      //an argument to the lambda function.
      if (savedModel.resourceType === 'Patient') {
        let patientID = savedModel.id;
        triggerSendEmailLambda(patientID);
      }
      //
      // saveFhirResourceWorker might fail. In this case it returns the fail action
      // which does not contain resourceType
      // the following condition prevents the saga from crashing
      if (savedModel.resourceType != null) {
        yield put({ type: getSavedActionName(savedModel.resourceType) });
      } else {
        console.warn(builtModel, savedModel);
      }

      updatedRefs = {
        ...updatedRefs,
        [resource.name]: savedModel
      };
    }
  }

  // Send actions for all outer edges
  const outerEdges = graph.outEdges(rootNode);
  yield all(
    outerEdges.map(edge => {
      return put(saveModel(graph, form, { fhir }, updatedRefs, edge.w));
    })
  );
}

// watcherSAGA sur l'ajout d'un patient via le formulaire
export default function* watcherSaga() {
  yield takeEvery(SAVING_MODEL, saveModelWorker);
}
