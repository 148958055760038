import {
  getItemByLinkId,
  getItemLinkIdFromCode,
  updateResponse
} from '../Questionnaire/utils'
import {
  MEDEO_CODE_FOR_ALD,
  MEDEO_CODE_FOR_AME,
  MEDEO_CODE_FOR_C2S,
  MEDEO_CODE_FOR_CARESHEET_MALADIE,
  MEDEO_CODE_FOR_CARESHEET_PRISE_EN_CHARGE,
  MEDEO_CODE_FOR_INVALIDITY,
  MEDEO_CODE_FOR_L212,
  MEDEO_CODE_FOR_TP_AMC_OPTION,
  MEDEO_CODE_FOR_TP_AMC_VALUE,
  MEDEO_CODE_FOR_TP_AMO_OPTION,
  MEDEO_CODE_FOR_TP_AMO_VALUE,
  MEDEO_CODING_SYSTEM
} from '../utils/codes'

/**
 *
 * Here we build the default prefilled response for the care sheet,
 * which will potentially already contain the responses for
 * tp amo and amc depending on the responses given in the pre TLC preamble
 *
 * @param {Questionnaire} careSheetQuestionnaire
 * * @param {Questionnaire} preambleQuestionnaire
 * @param {QuestionnaireResponse} preambleResponse
 */
export const buildDefaultResponse = (
  careSheetQuestionnaire,
  preambleQuestionnaire,
  preambleResponse
) => {
  if (!preambleResponse || !careSheetQuestionnaire || !preambleQuestionnaire) {
    return null
  }

  // Here we build the response relative to the Tiers Payant (<=> tp in this code)
  const tpAnswer = buildTpAnswerFromPreamble(
    preambleQuestionnaire,
    preambleResponse
  )

  // Here we get all the linkIds relative to the codes of the questions we are interested in
  // filling out in the caresheet
  const tpAmoOptionLinkId = getItemLinkIdFromCode(
    careSheetQuestionnaire,
    MEDEO_CODE_FOR_TP_AMO_OPTION
  )

  const tpAmoValueLinkId = getItemLinkIdFromCode(
    careSheetQuestionnaire,
    MEDEO_CODE_FOR_TP_AMO_VALUE
  )

  const tpAmcOptionLinkId = getItemLinkIdFromCode(
    careSheetQuestionnaire,
    MEDEO_CODE_FOR_TP_AMC_OPTION
  )

  const tpAmcValueLinkId = getItemLinkIdFromCode(
    careSheetQuestionnaire,
    MEDEO_CODE_FOR_TP_AMC_VALUE
  )

  const diseaseLinkId = getItemLinkIdFromCode(
    careSheetQuestionnaire,
    MEDEO_CODE_FOR_CARESHEET_MALADIE
  )

  const priseEnChargeLinkId = getItemLinkIdFromCode(
    careSheetQuestionnaire,
    MEDEO_CODE_FOR_CARESHEET_PRISE_EN_CHARGE
  )

  // And here, we build up the response consequently
  let response = { item: [] }

  response = updateResponse({
    questionnaireResponse: response,
    name: tpAmoOptionLinkId,
    ...(tpAnswer.tpAmo != null &&
      tpAnswer.tpAmo.checked && {
        value: getItemByLinkId(careSheetQuestionnaire, tpAmoOptionLinkId)
          ?.option?.[0]?.valueCoding,
        type: 'checkbox',
        checked: tpAnswer.tpAmo.checked
      })
  })

  response = updateResponse({
    questionnaireResponse: response,
    name: tpAmoValueLinkId,
    value: {
      value: tpAnswer.tpAmo != null ? tpAnswer.tpAmo.percentage : 0,
      unit: '%'
    },
    type: 'quantity'
  })

  response = updateResponse({
    questionnaireResponse: response,
    name: tpAmcOptionLinkId,
    ...(tpAnswer.tpAmc != null &&
      tpAnswer.tpAmc.checked && {
        value: getItemByLinkId(careSheetQuestionnaire, tpAmcOptionLinkId)
          ?.option?.[0]?.valueCoding,
        type: 'checkbox',
        checked: tpAnswer.tpAmc.checked
      })
  })

  response = updateResponse({
    questionnaireResponse: response,
    name: tpAmcValueLinkId,
    value: {
      value: tpAnswer.tpAmc != null ? tpAnswer.tpAmc.percentage : 0,
      unit: '%'
    },
    type: 'quantity'
  })

  if (tpAnswer.maladie != null) {
    response = updateResponse({
      questionnaireResponse: response,
      name: diseaseLinkId,
      type: 'select',
      value: tpAnswer.maladie
    })
  }

  if (tpAnswer.condition != null) {
    response = updateResponse({
      questionnaireResponse: response,
      name: priseEnChargeLinkId,
      value: tpAnswer.condition,
      type: 'radio'
    })
  }

  return response
}

/**
 * Here we build the tp answer from the preambule
 *
 * @param {Questionnaire}
 * @param {QuestionnaireResponse} response
 */
export const buildTpAnswerFromPreamble = (questionnaire, response) => {
  // This array contains the values of tp amc and amo
  // depending on the preamble questions linkIds
  const conditionToAnswer = [
    // C2S
    {
      linkId: getItemLinkIdFromCode(questionnaire, MEDEO_CODE_FOR_C2S),
      answer: {
        tpAmc: {
          checked: true,
          percentage: 30
        },
        tpAmo: {
          checked: true,
          percentage: 70
        }
      }
    },
    // ALD
    {
      linkId: getItemLinkIdFromCode(questionnaire, MEDEO_CODE_FOR_ALD),
      answer: {
        tpAmo: { checked: true, percentage: 100 },
        condition: {
          system: MEDEO_CODING_SYSTEM,
          code: 'maladie',
          display: 'Maladie'
        },
        maladie: {
          system: MEDEO_CODING_SYSTEM,
          code: 'affection_longue_duree',
          display: 'Affection longue durée'
        }
      }
    },
    // Invalidity
    // In this particular case, other fields which are the condition select
    // and the disease type are also concerned
    {
      linkId: getItemLinkIdFromCode(questionnaire, MEDEO_CODE_FOR_INVALIDITY),
      answer: {
        tpAmo: { checked: true, percentage: 100 },
        condition: {
          system: MEDEO_CODING_SYSTEM,
          code: 'maladie',
          display: 'Maladie'
        },
        maladie: {
          system: MEDEO_CODING_SYSTEM,
          code: 'autre',
          display: 'Autre'
        }
      }
    },
    // AME
    {
      linkId: getItemLinkIdFromCode(questionnaire, MEDEO_CODE_FOR_AME),
      answer: {
        tpAmc: {
          checked: true,
          percentage: 100
        }
      }
    },
    // L212
    {
      linkId: getItemLinkIdFromCode(questionnaire, MEDEO_CODE_FOR_L212),
      answer: {
        tpAmo: { checked: true, percentage: 100 },
        condition: {
          system: MEDEO_CODING_SYSTEM,
          code: 'maladie',
          display: 'Maladie'
        },
        maladie: {
          system: MEDEO_CODING_SYSTEM,
          code: 'article-l212-1',
          display: 'Article L212-1 (pensionnés de guerre, attentats)'
        }
      }
    }
  ]

  // We check rather one of the options from the preamble
  // influencing on the caresheet prefilling were filled during the
  // pre TLC
  const selectedOptions = conditionToAnswer.filter((option) => {
    const preambleInput = getItemByLinkId(response, option.linkId)

    // Here we need to check both valueBoolean and valueString cases as it might be
    // that some preambles were still saved with the old valueString as response for a boolean
    // TODO: remove the 'valueString' check once all the former tlc were completed
    const answer = preambleInput.answer?.find(
      (answer) => answer.valueBoolean != null || answer.valueString != null
    )

    return answer?.valueBoolean === true || answer?.valueString === 'true'
  })

  if (selectedOptions.length === 0) {
    return {}
  }
  // If one of them was, we simply return the object containing the
  // expecting corresponding answers
  else {
    return selectedOptions[0].answer
  }
}

/**
 * Prefills the caresheet depending on the provided during the filling of the care sheet
 *
 * @param {*} formerCaresheetResponse
 * @param {*} questionnaireResponse
 */
export const prefillCaresheet = (
  questionnaire,
  formerCaresheetResponse,
  questionnaireResponse
) => {
  // Those are the link id of the questions influencing on the
  // care sheet completion
  // which are conditions de prise en charge and type of disease
  const priseEnChargeLinkId = getItemLinkIdFromCode(
    questionnaire,
    MEDEO_CODE_FOR_CARESHEET_PRISE_EN_CHARGE
  )

  const diseaseLinkId = getItemLinkIdFromCode(
    questionnaire,
    MEDEO_CODE_FOR_CARESHEET_MALADIE
  )

  // We create an array we are going to iterate through, containing the concerned
  // linkIds and the answers type we are going to look for for each respectively
  const itemsToCheck = [
    { linkId: priseEnChargeLinkId, type: 'answer.valueCoding.code' },
    { linkId: diseaseLinkId, type: 'answer.valueCoding.code' }
  ]

  // And here we check if one of them changed during the very last update
  // This is necessary in order to keep the user possibility
  // to change the pre-filled values
  // we just one to provide default values just after the option was selected
  const changedItemsToCheck = itemsToCheck.find((item) => {
    const formerAnswer = getItemByLinkId(
      formerCaresheetResponse.current,
      item.linkId
    )?.answer?.[0]?.valueCoding?.code

    const newAnswer = getItemByLinkId(questionnaireResponse, item.linkId)
      ?.answer?.[0]?.valueCoding?.code

    return newAnswer != null && formerAnswer !== newAnswer
  })

  // We need this parameter as we want the default value for the disease
  // to be set only when the condition de prise en charge was just changed
  const hasPriseEnChargeChanged =
    changedItemsToCheck?.linkId === priseEnChargeLinkId

  // If some change occured on the triggered items during the last update,
  // we need to add fields to the care sheet response
  if (changedItemsToCheck != null) {
    questionnaireResponse = buildTpAnswerFromCaresheet(
      questionnaire,
      questionnaireResponse,
      priseEnChargeLinkId,
      diseaseLinkId,
      hasPriseEnChargeChanged
    )
  }

  return questionnaireResponse
}

/**
 * Builds the tp answers depending on what was selected in the care sheet
 * and add it to the questionnaire response
 *
 * @param {*} questionnaire
 * @param {*} questionnaireResponse
 * @param {String} priseEnChargeLinkId - the link id of the prise en charge item in the questionnaire
 * @param {String} diseaseLinkId -  the link id of the disease item
 * @param {Boolean} hasPriseEnChargeChanged - inform whether the condition de prise en charge was changed since the last time
 */
const buildTpAnswerFromCaresheet = (
  questionnaire,
  questionnaireResponse,
  priseEnChargeLinkId,
  diseaseLinkId,
  hasPriseEnChargeChanged
) => {
  const conditionAnswer = getItemByLinkId(
    questionnaireResponse,
    priseEnChargeLinkId
  )?.answer?.[0]?.valueCoding?.code

  let answer = null
  // Get the answer resulting from the newly selected option
  switch (conditionAnswer) {
    case 'atmp':
      answer = {
        tpAmo: {
          checked: true,
          percentage: 100
        },
        tpAmc: {
          checked: false,
          percentage: 0
        }
      }
      break
    case 'maternite':
      answer = {
        tpAmo: {
          checked: true,
          percentage: 100
        },
        tpAmc: {
          checked: false,
          percentage: 0
        }
      }
      break
    case 'maladie':
      const situationResponse = getItemByLinkId(
        questionnaireResponse,
        diseaseLinkId
      )
      const situationAnswer = situationResponse?.answer?.[0]?.valueCoding?.code
      switch (situationAnswer) {
        case 'article-l212-1':
        case 'affection_longue_duree':
        case 'actions_de_prevention':
        case 'autre':
          answer = {
            tpAmo: {
              checked: true,
              percentage: 100
            },
            tpAmc: {
              checked: false,
              percentage: 0
            }
          }
          break
        case 'aucune':
          answer = {
            tpAmo: {
              checked: false,
              percentage: 70
            },
            tpAmc: {
              checked: false,
              percentage: 30
            }
          }
          break
        default:
          // By default, when maladie only is checked, we want to preselect 'aucune'
          // only right after the condition was changed to maladie
          if (hasPriseEnChargeChanged === true) {
            answer = {
              maladie: {
                system: MEDEO_CODING_SYSTEM,
                code: 'aucune',
                display: 'Aucune'
              },
              tpAmo: {
                checked: false,
                percentage: 70
              },
              tpAmc: {
                checked: false,
                percentage: 30
              }
            }
          }
          break
      }
      break
    default:
      return
  }
  // If nothing results from it, then we just return the initial questionnaire response
  if (answer == null) {
    return questionnaireResponse
  }

  if (answer.maladie != null) {
    questionnaireResponse = updateResponse({
      questionnaireResponse: questionnaireResponse,
      name: diseaseLinkId,
      type: 'select',
      value: answer.maladie
    })
  }

  const tpAmoOptionLinkId = getItemLinkIdFromCode(
    questionnaire,
    MEDEO_CODE_FOR_TP_AMO_OPTION
  )
  const tpAmoValueLinkId = getItemLinkIdFromCode(
    questionnaire,
    MEDEO_CODE_FOR_TP_AMO_VALUE
  )
  const tpAmcOptionLinkId = getItemLinkIdFromCode(
    questionnaire,
    MEDEO_CODE_FOR_TP_AMC_OPTION
  )
  const tpAmcValueLinkId = getItemLinkIdFromCode(
    questionnaire,
    MEDEO_CODE_FOR_TP_AMC_VALUE
  )

  // otherwise, we add / update the response for each concerned item
  questionnaireResponse = updateResponse({
    questionnaireResponse: questionnaireResponse,
    name: tpAmoOptionLinkId,
    value: getItemByLinkId(questionnaire, tpAmoOptionLinkId)?.option?.[0]
      ?.valueCoding,
    type: 'checkbox',
    checked: answer.tpAmo.checked
  })
  questionnaireResponse = updateResponse({
    questionnaireResponse: questionnaireResponse,
    name: tpAmoValueLinkId,
    value: { value: answer.tpAmo.percentage, unit: '%' },
    type: 'quantity'
  })
  questionnaireResponse = updateResponse({
    questionnaireResponse: questionnaireResponse,
    name: tpAmcOptionLinkId,
    value: getItemByLinkId(questionnaire, tpAmcOptionLinkId)?.option?.[0]
      ?.valueCoding,
    type: 'checkbox',
    checked: answer.tpAmc.checked
  })
  questionnaireResponse = updateResponse({
    questionnaireResponse: questionnaireResponse,
    name: tpAmcValueLinkId,
    value: { value: answer.tpAmc.percentage, unit: '%' },
    type: 'quantity'
  })

  return questionnaireResponse
}
