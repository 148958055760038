import useCurrentOrganizationId from '../../Organization/containers/CurrentOrganizationIdProvider';
import { useCallback } from 'react';
import { getLocationByOrganizationId } from '../../Location/selectors';
import { useSelector } from 'react-redux';

const useGetLocationFromOrganizationId = () => {
  const organizationId = useCurrentOrganizationId();
  const selector = useCallback(
    state => getLocationByOrganizationId(state, organizationId),
    [organizationId]
  );
  return useSelector(selector);
};

export default useGetLocationFromOrganizationId;
