import { faSave } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { t, Trans } from '@lingui/macro'
import { Identifier, MedicationRequest } from 'fhir-stu3'
import moment from 'moment'
import { FC, useEffect, useReducer } from 'react'
import styled from 'styled-components/macro'
import { v4 as uuid } from 'uuid'
import { Button, Form, Input, Spinner } from '../../Components'
import useSave from '../../Shared/hooks/useSave'
import { useI18n } from '../../utils/I18nHookAdapter'
import {
  MEDEO_ALD_TYPE_SYSTEM,
  MEDEO_ALD_TYPE_VALUE,
  MEDEO_MEDICATION_REQUEST_GROUP_IDENTIFIER_NAME
} from '../codes'
import CommentInput from './CommentInput'
import EditMedicationRequestForm from './EditMedicationRequestForm'
import MedicationRequestList from './MedicationRequestList'

const Separator = styled.div`
  margin: 2rem 2rem;
  border-bottom: 1px solid ${(p) => p.theme.nevada};
`
const Section = styled.section`
  & > header {
    margin-bottom: 0.5rem;
  }
  &:not(:last-child) {
    margin-bottom: 1.5rem;
  }
`

const SubmitButton = styled(Button)`
  margin-top: 1rem;
  display: block;
`

const Div = styled.div`
  display: flex;
  padding: 0 2rem;

  & > :first-child {
    flex: 1;
    margin-right: 1rem;
  }
  & > :last-child {
    flex: 0;
  }
`
const ScrollContainer = styled.div`
  overflow-y: scroll;
  flex: 1;
  padding-top: 2rem;
`

const CustomForm = styled(Form)`
  padding: 0 2rem;
`

const SpinnerContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  align-items: center;
  margin-left: 1rem;
`

const Footer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  margin: auto;
`

const IndicationDiv = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: ${(p) => p.theme.small};
  font-style: italic;
  margin-top: 1rem;
`

/**
 * This function is a part of the reducer of EditGroupedMedicationRequestsForm
 * It handle the comment state that is a bit complicated
 */
const reduceComment = (
  state: EditGroupedMedicationRequestsFormState,
  payload: { type: string; text: string }
): EditGroupedMedicationRequestsFormState => {
  // No type (ald or nonAld).
  if (payload.type !== 'ald' && payload.type !== 'nonAld') {
    return state
  }
  // If the user delete the comment, remove it from the state.
  if (payload.text === '') {
    state[payload.type].comment = null
    return state
  }
  // Create/replace the comment in the state.
  const newComment: MedicationRequest = {
    resourceType: 'MedicationRequest',
    intent: 'order',
    medicationCodeableConcept: {
      text: payload.text
    },
    subject: {}
  }
  if (payload.type === 'ald') {
    newComment.identifier = [
      {
        type: {
          coding: [
            {
              system: MEDEO_ALD_TYPE_SYSTEM,
              code: MEDEO_ALD_TYPE_VALUE
            }
          ]
        }
      }
    ]
  }
  state[payload.type].comment = newComment
  return state
}

const reducer = (
  state: EditGroupedMedicationRequestsFormState,
  action: { type: string; payload: any }
): EditGroupedMedicationRequestsFormState => {
  const { type, payload } = action
  let field: 'ald' | 'nonAld' = 'ald'
  const newState = { ...state }
  newState.current = null
  switch (type) {
    case 'add':
      const medicationRequest = payload as MedicationRequest
      field = medicationRequest.identifier?.some((i: any) =>
        i.type?.coding?.some?.((c: any) => c.code === MEDEO_ALD_TYPE_VALUE)
      )
        ? 'ald'
        : 'nonAld'
      newState[field].medicationRequests.push(medicationRequest)
      return newState
    case 'changeTitle':
      newState.title = payload as string
      return newState
    case 'typing-in':
      newState.current = payload.medicationRequest
      return newState
    case 'update':
      field = payload.type as 'ald' | 'nonAld'
      const updateMedicationRequests: MedicationRequest[] = state[
        field
      ].medicationRequests.filter((m, i: number) => i !== payload.index)
      const medicationRequestToUpdate =
        state[field].medicationRequests?.[payload.index] ?? null
      newState.current = medicationRequestToUpdate
      newState[field].medicationRequests = updateMedicationRequests
      return newState
    case 'delete':
      field = payload.type as 'ald' | 'nonAld'
      const deleteMedicationRequests: MedicationRequest[] = state[
        field
      ].medicationRequests.filter((m, i: number) => i !== payload.index)
      newState[field].medicationRequests = deleteMedicationRequests
      return newState
    case 'comment':
      // comments are stored as MedicationRequest too,
      // instead of defining a medicationReference we use a codeableConcept
      // it's like free textarea for the user to type in
      // see paramedical orders for another usage
      return reduceComment(state, payload)
    case 'reset':
      // reset order with the same patient encounter and practitioner
      return init({
        ...state,
        medicationRequests: payload ?? []
      })
    default:
      return newState
  }
}

interface initProps {
  encounterID: string
  patientID: string
  practitionerID: string
  medicationRequests: MedicationRequest[]
}

const init = (props: initProps): EditGroupedMedicationRequestsFormState => {
  const { encounterID, patientID, practitionerID, medicationRequests } = props
  const initialState: EditGroupedMedicationRequestsFormState = {
    encounterID: encounterID,
    patientID: patientID,
    practitionerID: practitionerID,
    ald: {
      medicationRequests: [],
      comment: null
    },
    nonAld: {
      medicationRequests: [],
      comment: null
    },
    title: 'Ordonnance',
    current: null,
    groupIdentifier: {}
  }
  medicationRequests.forEach((request) => {
    const field = request.identifier?.some((i) =>
      i.type?.coding?.some?.((c) => c.code === MEDEO_ALD_TYPE_VALUE)
    )
      ? 'ald'
      : 'nonAld'
    // when receiving the requests we need to sort medications from comments
    // as explain in the reducer above comments are stored as codeableConcept
    // !! will transform the object in its boolean representation
    const isComment = !!request.medicationCodeableConcept
    if (isComment) {
      initialState[field].comment = request
    } else {
      initialState[field].medicationRequests = [
        ...initialState[field].medicationRequests,
        request
      ]
    }
  })
  initialState.groupIdentifier = {
    extension: [
      {
        url: MEDEO_MEDICATION_REQUEST_GROUP_IDENTIFIER_NAME,
        valueString:
          medicationRequests[0]?.groupIdentifier?.extension?.[0]?.valueString ??
          ''
      }
    ],
    value: medicationRequests[0]?.groupIdentifier?.value ?? uuid()
  }
  return initialState
}

interface EditGroupedMedicationRequestsFormProps {
  encounterID: string
  patientID: string
  practitionerID: string
  medicationRequests: MedicationRequest[]
  onAfterSubmit: (medicationRequests: MedicationRequest[]) => void
  isSubmitting?: boolean
}

interface EditGroupedMedicationRequestsFormState {
  title: string
  ald: {
    medicationRequests: MedicationRequest[]
    comment: MedicationRequest | null
  }
  nonAld: {
    medicationRequests: MedicationRequest[]
    comment: MedicationRequest | null
  }
  current: MedicationRequest | null
  groupIdentifier: Identifier
  patientID: string
  encounterID: string
  practitionerID: string
}

const EditGroupedMedicationRequestsForm: FC<EditGroupedMedicationRequestsFormProps> = (
  props: EditGroupedMedicationRequestsFormProps
) => {
  const {
    encounterID,
    patientID,
    practitionerID,
    medicationRequests,
    onAfterSubmit,
    isSubmitting
  } = props
  const i18n = useI18n()
  useEffect(() => {
    if (medicationRequests.length > 0) {
      dispatch({
        type: 'reset',
        payload: medicationRequests
      })
    }
  }, [medicationRequests])
  // medicationRequests represents "one line" of an order
  // to tied them together we use the groupIdentifier attribute,
  // when this component is initialized we generate a uuid.
  // it is shared to all MedicationRequests edited within this components
  const [state, dispatch] = useReducer(
    reducer,
    { encounterID, patientID, practitionerID, medicationRequests },
    init
  )
  const [save, { loading }] = useSave()
  const handleAdd = (medicationRequest: MedicationRequest) => {
    dispatch({
      type: 'add',
      payload: medicationRequest
    })
  }

  const extractFromMedicationRequest = (
    medicationRequest: MedicationRequest
  ) => {
    if (!medicationRequest || !medicationRequest.dosageInstruction) {
      return { dosageInstruction: '', medicationReference: null }
    }
    const dosageInstruction = medicationRequest.dosageInstruction[0].text
    const medicationReference = medicationRequest.medicationReference

    return { dosageInstruction, medicationReference }
  }

  const handleSubmit = async () => {
    // state.current != null means something has been typed in on the EditMedicationRequestForm
    // but was not validated by the user. If it is so, display a confirm pop up to alert them
    // and give them the possibility to cancel
    if (state.current != null) {
      const {
        dosageInstruction,
        medicationReference
      } = extractFromMedicationRequest(state.current)
      // @ts-ignore
      const confirmMessage = i18n._(
        t`One of your entries has not been validated. Your line "${medicationReference?.display ??
          ''}: ${dosageInstruction ??
          ''}" will not be saved. Do you really want to continue?`
      )
      // eslint-disable-next-line no-restricted-globals
      const response = confirm(confirmMessage)
      if (response === false) {
        return
      }
    }

    const groupIdentifierValue = medicationRequests[0]?.groupIdentifier?.value ?? uuid()

    /**
     * toEntry transform a MedicationRequest into a Bundle.Entry format
     * here by simply appending a request field.
     * @param {MedicationRequest} medicationRequest
     * @return {Bundle.Entry}
     */
    const toEntry = (medicationRequest: MedicationRequest) => {
      medicationRequest.groupIdentifier = state.groupIdentifier
      medicationRequest.subject = {
        reference: `Patient/${state.patientID}`
      }
      medicationRequest.requester = {
        agent: {
          reference: `Practitioner/${state.practitionerID}`
        }
      }
      medicationRequest.context = {
        reference: `Encounter/${state.encounterID}`
      }
      medicationRequest.resourceType = 'MedicationRequest'
      medicationRequest.groupIdentifier ={
        extension: [
          {
            url: MEDEO_MEDICATION_REQUEST_GROUP_IDENTIFIER_NAME,
            valueString: state.title ?? ''
          }
        ],
        value: groupIdentifierValue,
      }
      return {
        resource: medicationRequest,
        request: {
          method: medicationRequest.id != null ? 'PUT' : 'POST',
          url:
            medicationRequest.id != null
              ? `MedicationRequest/${medicationRequest.id}`
              : 'MedicationRequest'
        }
      }
    }

    let resources = [
      ...state.nonAld.medicationRequests,
      ...state.ald.medicationRequests
    ]
    if (state.ald.comment) {
      resources.push(state.ald.comment)
    }
    if (state.nonAld.comment) {
      resources.push(state.nonAld.comment)
    }
    // when users submit this form a 2nd time they can delete medicationRequests
    // we need to check which requests do not appear in the state anymore
    // these requests will be set as entered in error within the transaction
    const deletedRequests = medicationRequests
      .filter((request) => !resources?.some?.((r) => r.id === request.id))
      .map((request) => ({
        ...request,
        status: 'entered-in-error'
      }))
    resources = [...resources, ...deletedRequests]
    const bundle = {
      resourceType: 'Bundle',
      type: 'transaction',
      entry: resources.map(toEntry)
    }

    // Note that saving a transaction won't trigger a redux dispatch
    // this means the newly saved MedicationRequests won't appear in the store
    const response = await save(bundle)
    onAfterSubmit(response.entry.map((e: any) => e.resource))
  }
  const handleComment = (category: string) => (text: string) => {
    dispatch({
      type: 'comment',
      payload: { type: category, text: text }
    })
  }
  const handleUpdate = (category: string) => (index: number) => {
    dispatch({
      type: 'update',
      payload: {
        type: category,
        index: index
      }
    })
  }
  const handleDelete = (category: string) => (index: number) => {
    dispatch({
      type: 'delete',
      payload: {
        type: category,
        index: index
      }
    })
  }

  const handleFormChange = (medicationRequest: MedicationRequest) => {
    // Only set a current input when the medicationRequest is not the
    // default state, as we are using it here in order to keep
    // track of whether the user started to type sth in or not
    const {
      dosageInstruction,
      medicationReference
    } = extractFromMedicationRequest(medicationRequest)

    if (dosageInstruction !== '' || medicationReference != null) {
      dispatch({ type: 'typing-in', payload: { medicationRequest } })
    }
  }

  // the save button should be disabled if the user has not entered anything
  // or if the medication is currently saved on the server
  // or if the pdf is being generated
  // see handleAdd for details
  const disabled =
    loading === true ||
    isSubmitting === true ||
    (state.ald.medicationRequests.length === 0 &&
      state.nonAld.medicationRequests.length === 0 &&
      state.ald.comment == null &&
      state.nonAld.comment == null)
  return (
    <>
      <ScrollContainer data-test={'medication-list-option-button1'}>
        <Div>
          <Input
            type="text"
            label={<Trans>Medication request title</Trans>}
            value={state.title}
            onChange={(e: Event) => {
              const target = e.target as HTMLInputElement
              dispatch({ type: 'changeTitle', payload: target?.value })
            }}
            data-test={'medication-request-title'}
          />
          <Input
            label={<Trans>Date</Trans>}
            value={moment().format('YYYY-MM-DD')}
            type="date"
            disabled={true}
          />
        </Div>
        <Separator />
        <EditMedicationRequestForm
          medicationRequest={state.current}
          onSubmit={handleAdd}
          onChange={handleFormChange}
        />
        <Separator />
        <CustomForm>
          <Section data-test={'medication-longterm-section'}>
            <header>
              <Trans>Long-term illness</Trans>
            </header>
            <MedicationRequestList
              medicationRequests={state.ald.medicationRequests}
              onClickUpdate={handleUpdate('ald')}
              onClickDelete={handleDelete('ald')}
            />
            <CommentInput
              value={state.ald.comment?.medicationCodeableConcept?.text ?? ''}
              onChange={handleComment('ald')}
              data-test={'medication-longterm-comment'}
            />
          </Section>
          <Section data-test={'medication-exceptlongterm-section'}>
            <header>
              <Trans>Except long-term illness</Trans>
            </header>
            <MedicationRequestList
              medicationRequests={state.nonAld.medicationRequests}
              onClickUpdate={handleUpdate('nonAld')}
              onClickDelete={handleDelete('nonAld')}
            />
            <CommentInput
              value={
                state.nonAld.comment?.medicationCodeableConcept?.text ?? ''
              }
              onChange={handleComment('nonAld')}
              data-test={'medication-exceptlongterm-comment'}
            />
          </Section>
        </CustomForm>
        <Separator />
        <Footer>
          <SubmitButton
            onClick={handleSubmit}
            disabled={disabled}
            data-test={'medication-submit-button'}
          >
            <FontAwesomeIcon icon={faSave} />
            &nbsp;<Trans>Save</Trans>
          </SubmitButton>
          {isSubmitting && (
            <SpinnerContainer>
              <Spinner />
            </SpinnerContainer>
          )}
        </Footer>
      </ScrollContainer>
      {isSubmitting && (
        <IndicationDiv>
          <span>
            <Trans>Please wait while your order is being saved...</Trans>
          </span>
        </IndicationDiv>
      )}
    </>
  )
}

export default EditGroupedMedicationRequestsForm
