import { useEffect, useState } from 'react'
import { getTokenFromAmplify } from '../Auth/utils'

/**
 * postBinaryWithProgress is like fetch but with support for onProgress event
 * it should only be used inside the DocumentReference component to post Binary
 * file.
 * It returns a promise which will resolve with the response Header location
 * This header is filled with the binary location Url.
 * @param url
 * @param opts the option like in fetch
 * @param onProgress a callback function t be called with the progress event
 * @returns {Promise<String>} a promise to the binaryLocation
 */
export const postBinaryWithProgress = (url, opts = {}, onProgress) => {
  return new Promise((res, rej) => {
    var xhr = new XMLHttpRequest()
    xhr.open(opts.method || 'get', url)
    for (var k in opts.headers || {}) {xhr.setRequestHeader(k, opts.headers[k])}
    xhr.onload = () => {
      var binaryLocation = xhr.getResponseHeader('location')
      res(binaryLocation)
    }
    xhr.onerror = rej
    if (xhr.upload && onProgress) {xhr.upload.onprogress = onProgress} // event.loaded / event.total * 100 ; //event.lengthComputable
    xhr.send(opts.body)
  })
}

// TODO move this hook in root utils folder if it used somewhere else than
//  this folder.
/**
 * this custom hook is getting the currentToken from Cognito. It is used in the
 * Attachment folder because file upload do not use the default saga process for API calls.
 * Therefore some React components will need the token (i.e. AttachmentInput)
 * to communicate with the server.
 *
 * NOTE: we can note use the token from the redux store as if the user session
 * is longer than 1hour the token inside is not valid anymore
 * @returns {unknown}
 */
export const useToken = () => {
  const [token, setToken] = useState(null)
  const run = async () => {
    // Auth is not available while testing, so we provide a fake token instead
    if (process.env.NODE_ENV !== 'test') {
      setToken(await getTokenFromAmplify())
    } else {
      setToken('aaa.bbb.ccc')
    }
  }
  // this hooks is performed when the component is mounted
  useEffect(() => {
    if (process.env.NODE_ENV !== 'test') {
      setTimeout(() => {
        run().then()
      }, 1000)
    } else {
      run().then()
    }
  }, [])
  return token
}

/**
 * This function makes sure to preventDefault behavior of opening the file
 * in the browser when a file is dragged and dropped outside of a dropZone container
 */
export const preventDragAndDropDefault = () => {
  window.addEventListener(
    'dragover',
    e => {
      e && e.preventDefault()
    },
    false
  )
  window.addEventListener(
    'drop',
    e => {
      e && e.preventDefault()
    },
    false
  )
}
