// @ts-nocheck
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getCurrentEncounter } from '../selectors';
import { useNavigate } from '@reach/router';
import usePatient from '../../Patient/usePatient';

/**
 * DefaultEncounterRoute makes sure an encounter is currently selected
 * It renders nothing until the encounter is set
 * It redirects to a safe location i.e. patient encounter history
 * @param children
 * @returns {*}
 * @constructor
 */
const DefaultEncounterRoute = ({ patient: Patient, encounter: Encounter, practitioner: Practitioner, children }) => {
  const patient = usePatient();
  const encounter = useSelector(getCurrentEncounter);
  const navigate = useNavigate();

  useEffect(() => {
    if (encounter == null) {navigate(`/patient/${patient.id}/history`);}
  }, [encounter, navigate, patient.id]);
  return encounter && children;
};

export default DefaultEncounterRoute;
