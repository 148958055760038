import styled from 'styled-components/macro'
import { List } from '../../Components'

const InlineList = styled(List)`
  display: flex;
  color: blue;
  flex-wrap: wrap;
  & > * {
    margin: 0.25rem 0;
  }
  & > :not(:last-child) {
    margin-right: 0.25rem;
  }
`

export default InlineList
