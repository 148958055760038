import { Trans } from '@lingui/macro'
import { Button, Card, DropDown, Spinner } from '../../Components'
import { useNavigate } from '@reach/router'
import { upperFirst } from 'lodash'
import moment from 'moment'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { useDispatch, useSelector } from 'react-redux'
import { useEffectOnce } from 'react-use'
import styled from 'styled-components/macro'

import { CalendarViewContext } from '../containers/CalendarView'
import { search } from '../../Shared/actions'
import {
  displayFamilyName,
  displayPhoneNumberWithSpaces,
  displayPhoneNumber,
  displayGivenName
} from '../../Shared/display/entity'
import {
  getEncounterByAppointmentId,
  getLastEncounterDateByCodeFromSubjectId
} from '../../Encounter/selectors'
import { MEDEO_ENCOUNTER_TYPE_PRE_TELECONSULTATION } from '../../utils/encounter-type'

import { isEncounterTypePreConsultation } from '../../Encounter/utils'
import { cancelAppointment } from '../../Appointment/actions'
import { getOrganizationById } from '../../Organization/selector'
import RequirePermission from '../../Permissions/containers/RequirePermission'
import { getIdByReference } from '../../Shared/utils'
import { useSearch } from '../../Shared/hooks'
import mixpanel, { MIXPANEL_APPOINTMENT_CANCELED } from '../../Shared/mixpanel'
import usePractitioner from '../../Practitioner/usePractitioner'
import { getPatientById } from '../../Patient/selectors'
import { getActiveTeleconsultationBypatientID } from '../../ProcedureRequest/ducks'
import { getLocationById } from '../../Location/selectors'
import { getPractitionerById } from '../../Practitioner/ducks'

const CustomCard = styled(Card)`
  position: absolute;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  box-shadow: ${p => p.theme.boxShadowCloser};
  ${p =>
    p.inView
      ? `top: 50%;transform: translate(${p.translate ? -100 : 0}%, -60%);`
      : ''}
`

const Header = styled.section`
  padding: 1rem 0;
`

const PatientName = styled.h3`
  margin: 0;
  font-weight: normal;
  display: flex;
`

const AppointmentDayAndTime = styled.ul`
  list-style: none;
  font-size: 0.83rem;
  margin-top: 0.5rem;
  padding: 0;
`
const List = styled.ul`
  list-style: none;
  margin: 0;
  border-top: 1px solid ${p => p.theme.gray};
  padding: 1rem 0;
`

const ListItem = styled.li`
  padding-top: 0.5rem;
`

const SectionTitle = styled.div`
  font-size: 0.83rem;
`

const CustomButton = styled(Button)`
  border: none;
  text-align: initial;
  padding: 0;
  text-transform: none;
  font-weight: normal;
`

const CustomKebabButton = styled(DropDown.KebabButton)`
  background-color: transparent;
  color: ${p => p.theme.black};
`

const CustomDropDown = styled(DropDown)`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
`

export function useScrollPosition(element) {
  const [scrollPosition, setPosition] = React.useState(0)
  useEffect(() => {
    function updatePosition() {
      setPosition(element.scrollTop)
    }
    element.addEventListener('scroll', updatePosition)
    updatePosition()
    return () => {
      return element.removeEventListener('scroll', updatePosition)
    }
  }, [element])
  return scrollPosition
}

const TeleconsulationEventDetails = ({
  forwaredRef,
  position,
  calendarType
}) => {
  const currentPractitioner = usePractitioner()
  const [state, setState] = useContext(CalendarViewContext)

  const navigate = useNavigate()
  const reduxDispatch = useDispatch()
  const [shouldGoToTeleconsultation, setShouldGoToTeleconsultation] = useState(
    false
  )
  const [divShouldMove, setDivShouldMove] = useState(false)
  //here we get the number of pixels with the content of the hour view scrolled up
  const initialScroll = useRef(
    document.getElementsByClassName('rbc-time-content')[0].scrollTop
  )
  //we get the position of the event box
  const scrollPosition = useScrollPosition(
    document.getElementById('scrollContainer')
  )
  // we get position of the hour view of calendar
  const scrollInner = useScrollPosition(
    document.getElementsByClassName('rbc-time-content')[0]
  )
  let patientID
  if (state && state.patientID) {patientID = state.patientID}
  // this is what we use to know if the card is in window or not
  const { ref, inView } = useInView({
    threshold: 0.9
  })

  // we load the Location and include the Organization here,
  // this way the organization details will always be available
  // this component was built using the redux state
  // so, we don't need to use the {data} from the useSearch
  // the loading will display a spinner while the details are not available
  const { loading } = useSearch('Location', {
    _id: state.locationId,
    _include: 'Location:organization'
  })

  useEffectOnce(() => {
    setDivShouldMove(!inView)
    reduxDispatch(
      search('Patient', {
        _id: patientID
      })
    )
    reduxDispatch(
      search('ProcedureRequest', {
        patient: patientID,
        status: 'active'
      })
    )
    reduxDispatch(
      search('Encounter', {
        subject: patientID,
        status: 'finished',
        type: MEDEO_ENCOUNTER_TYPE_PRE_TELECONSULTATION
      })
    )
    reduxDispatch(
      search('Practitioner', {
        _id: state.doctorId
      })
    )
  })

  const patient = useSelector(getPatientById(patientID))
  const remoteConsultationProcedureRequest = useSelector(
    getActiveTeleconsultationBypatientID(patientID)
  )

  const location = useSelector(getLocationById(state.locationId))

  const organizationId = getIdByReference(
    location?.managingOrganization?.reference
  )

  const organization = useSelector(getOrganizationById(organizationId))
  const organizationName = organization?.name
  const organizationPhone = organization?.telecom?.find(
    t => t.system === 'phone' || 'mobile'
  )?.value

  const formattedOrganizationPhone = organizationPhone ? (
    displayPhoneNumberWithSpaces(organizationPhone)
  ) : (
    <Trans>No phone number found</Trans>
  )

  // Here we select the potential pre-tlc encounter which refers to the appointment
  const encounterWithAppointment = useSelector(s =>
    getEncounterByAppointmentId(s, state.id)
  )?.find(encounter => isEncounterTypePreConsultation(encounter.type))
  // And here the last encounter of type pre-tlc
  const lastEncounter = useSelector(s =>
    getLastEncounterDateByCodeFromSubjectId(
      s,
      MEDEO_ENCOUNTER_TYPE_PRE_TELECONSULTATION,
      patientID
    )
  )
  // encounterWithAppoitnment will be null until people create new Appointment after the release
  // as the reference to an appointment was not saved in the encounter resource beforehand
  const preConsultationEncounter = encounterWithAppointment ?? lastEncounter

  const doctor = useSelector(getPractitionerById(state.doctorId))

  // we want to forbid the docto to go back to tlc after it's over. When the tlc is done, the appointment state is fulfilled
  useEffect(() => {
    setShouldGoToTeleconsultation(
      remoteConsultationProcedureRequest != null &&
        calendarType === 'schedule' &&
        state.status !== 'fulfilled'
    )
  }, [calendarType, state.status, remoteConsultationProcedureRequest])

  const handleClick = () => {
    if (shouldGoToTeleconsultation) {
      navigate(`/teleconsultation/${patientID}/${preConsultationEncounter?.id}`)
    } else {
      navigate(`/patient/${patientID}/history`)
    }
  }

  const cancelTeleconsultation = () => {
    // And here we cancel the linked appointment and update the
    // booking Procedure Request
    mixpanel.track(MIXPANEL_APPOINTMENT_CANCELED, {
      from: 'calendar'
      // it can be from calendar or from details
    })
    reduxDispatch(cancelAppointment(state.appointment, currentPractitioner))
    setState(null)
  }

  const isAppointmentInTheFuture = moment(
    state?.appointment?.start
  ).isSameOrAfter(moment(), 'day')

  return (
    <div ref={forwaredRef}>
      <CustomCard
        // we added a style for the position of this box. The left position is fixed by the event
        // position. The top position is equal to the position of the event and a adaptation
        // of the user scrolling action
        style={{
          zIndex: 100,
          left: position[0],
          top:
            position[1] + initialScroll.current - scrollPosition - scrollInner
        }}
        ref={ref}
        inView={divShouldMove}
        translate={(position[0] > 900) ? "yes" : "no"}
      >
        <Header>
          <PatientName>{displayFamilyName(patient)}</PatientName>
          <RequirePermission
            resource={'cancelAppointmentCalendar'}
            action={'see'}
          >
            {state?.appointment?.status === 'booked' &&
              isAppointmentInTheFuture && (
                <CustomDropDown variant="down">
                  <CustomKebabButton variant="tertiary" />
                  <DropDown.Menu>
                    <DropDown.ListItem
                      value="cancel"
                      onClick={cancelTeleconsultation}
                    >
                      <Trans>Cancel the Teleconsultation</Trans>
                    </DropDown.ListItem>
                  </DropDown.Menu>
                </CustomDropDown>
              )}
          </RequirePermission>
          <PatientName>{displayGivenName(patient)}</PatientName>
          <AppointmentDayAndTime>
            <div>{upperFirst(moment(state.start).format('dddd D MMMM'))}</div>
            <div>
              {moment(state.start).format('LT')} -{' '}
              {moment(state.end).format('LT')}
            </div>
          </AppointmentDayAndTime>
        </Header>
        {loading === true ? (
          <Spinner />
        ) : (
          <List>
            <li>
              <SectionTitle>
                <Trans>Remote consultation</Trans>
              </SectionTitle>
            </li>
            <ListItem>{upperFirst(organizationName)}</ListItem>
            <ListItem>{formattedOrganizationPhone}</ListItem>
          </List>
        )}
        <RequirePermission resource={'doctor'} action={'read'}>
          <List>
            <li>
              <SectionTitle>
                <Trans>Remote consultation</Trans>
              </SectionTitle>
            </li>
            <ListItem>Dr {displayFamilyName(doctor)}</ListItem>
            <ListItem>
              {displayPhoneNumberWithSpaces(displayPhoneNumber(doctor))}
            </ListItem>
          </List>
        </RequirePermission>
        <List>
          <li>
            <SectionTitle>
              <Trans>Reasons</Trans>
            </SectionTitle>
          </li>
          <ListItem>
            {state.motive != null ? (
              state.motive
            ) : (
              <Trans>No motive found</Trans>
            )}
          </ListItem>
        </List>
        <List>
          <li>
            <SectionTitle>
              <Trans>Preconsultation</Trans>
            </SectionTitle>
          </li>
          <ListItem>{upperFirst(state.autonomousType)}</ListItem>
        </List>
        {/* we change the button depend if we are on pharmacist or doctor side */}
        <CustomButton onClick={handleClick} variant="outline" color="ocean">
          {shouldGoToTeleconsultation ? (
            <Trans>Remote consultation</Trans>
          ) : (
            <Trans>Patient folder</Trans>
          )}
        </CustomButton>
      </CustomCard>
    </div>
  )
}

export default TeleconsulationEventDetails
