import React, { useState } from 'react'
import { Authenticator } from 'aws-amplify-react'

// SignUp is taking two steps:
// - first SignUp
// - then ConfirmSignUp with an email (or SMS but it is not set right now... c.f. cognito)
// for confirmSignUp we want to have the email address already provided, so we use
// this context to pass credentials from one step to another.

// Another feature of this SignUpContext is that is wraps the Authenticator use.
// This prevents having too much dependencies in other place in the code.
const SignUpContext = React.createContext(null)

export const SignUpContextProvider = ({ children }) => {
  const [state, setState] = useState(null)
  return (
    <SignUpContext.Provider value={[state, setState]}>
      <Authenticator hideDefault authState={'signUp'}>
        {children}
      </Authenticator>
    </SignUpContext.Provider>
  )
}

export default SignUpContext
