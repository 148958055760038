import { shallowEqual, useSelector } from 'react-redux';
import {
  filterByTypeCode,
  getPractitionerRolesBypractitionerID
} from '../../PractitionerRole/selector';
import ProfilePicture from '../../Shared/components/ProfilePicture';
import { displayPractitionerImg } from '../../Shared/display/practitioner';
import {
  displayFamilyName,
  displayGivenName
} from '../../Shared/display/entity';
import { displayPractitionerActiveRole } from '../../Shared/display/practitionerRole';
import React from 'react';
import styled from 'styled-components/macro';

const Row = styled.tr`
  box-shadow: none !important;
  &:hover > td {
    border-color: transparent !important;
  }
  & > td,
  & > th {
    padding: 1rem 0.5rem;
    &:first-of-type {
      width: 3rem;
      padding-left: 2.5rem;
    }
    &:last-of-type {
      padding-right: 2rem;
    }
  }
  & > td {
    background: ${p => p.theme.alabaster} !important;
    font-weight: normal;
  }
`;

/**
 *
 * @param {Practitioner} practitioner
 * @param isOC
 * @return {JSX.Element}
 * @constructor
 */
const OutsideCollaboratorRow = ({ practitioner }) => {
  // shallow equal prevent re-renders when selectors returns object
  const roles = useSelector(
    s => getPractitionerRolesBypractitionerID(s, practitioner.id),
    shallowEqual
  );
  const medicalRole = roles.find(filterByTypeCode('medical'));

  return (
    <Row>
      <td>
        <ProfilePicture
          size="small"
          src={displayPractitionerImg(practitioner)}
          alt={`${displayGivenName(practitioner)} ${displayFamilyName(
            practitioner
          )} picture`}
        />
      </td>
      <td>
        {displayGivenName(practitioner)} {displayFamilyName(practitioner)}
      </td>
      <td>{displayPractitionerActiveRole([medicalRole])}</td>
    </Row>
  );
};
export default OutsideCollaboratorRow;
