/**
 * This method use the fhirClient state to indicate whether the user is signed or not
 * The fhirClient is build when the token is received from the server or when the token
 * stored in the browser localstorage is valid
 *
 * @param state
 * @returns {boolean}
 */
export const isSignedIn = state => state.fhirClient != null

export const getCurrentOrganizationId = state =>
  state.auth.attributes != null && state.auth.attributes['custom:orgId']

export const getCurrentCognitoGroups = state => state.auth.cognitoGroups
