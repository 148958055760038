import { useSelector } from 'react-redux';
import { getCurrentEncounter } from '../selectors';
import { PlanDefinitionContext } from '../../PlanDefinition/usePlanDefinition';
import NewEncounterCard from '../containers/NewEncounterCard';
import React, { useState } from 'react';

const ClinicalEncounter = ({ path, patient }) => {
  const encounter = useSelector(getCurrentEncounter);

  const [state, setState] = useState(null);

  return (
    <PlanDefinitionContext.Provider value={[state, setState]}>
      <NewEncounterCard
        isFromTeleconsultation={false}
        patient={patient}
        encounter={encounter}
      />
    </PlanDefinitionContext.Provider>
  );
};

export default ClinicalEncounter;
