import React from 'react'
import { Trans } from '@lingui/macro'

// There are many more roles that could be interpreted as a doctor/nurse/whatever. The full list is here:
// https://www.hl7.org/fhir/valueset-practitioner-role.html

//esante codes
export const GENERAL_PRACTITIONER_ESANTE = '10'
export const PHARMACIST_ESANTE = '21'
export const AUDIOPROSTHESIST_ESANTE = '26'
export const OPTICIAN_ESANTE = '28'
export const DENTAL_ASSISTANT_ESANTE = '31'
export const MEDICAL_PHYSICIST_ESANTE = '32'
export const NURSING_ASSISTANT_ESANTE = '35'
export const AMBULANCE_DRIVER_ESANTE = '36'
export const CHILDCARE_ASSISTANT_ESANTE = '37'
export const PHARMACEUTICAL_ASSISTANT_HOSPITAL_ESANTE = '38'
export const PHARMACEUTICAL_ASSISTANT_DISPENSARY_ESANTE = '39'
export const DENTIST_ESANTE = '40'
export const SOCIAL_CARE_ASSISTANT_ESANTE = '41'
export const PERSONAL_CARER_ASSISTANT_ESANTE = '42'
export const SOCIAL_INTERVENTION_ASSISTANT_ESANTE = '43'
export const PSYCHOLOGICAL_ASSISTANT_ESANTE = '47'
export const MIDWIFE_ESANTE = '50'
export const NURSE_ESANTE = '60'
export const PSYCHIATRIST_NURSE_ESANTE = '69'
export const PHYSIOTHERAPIST_ESANTE = '70'
export const OSTEAOPATH_ESANTE = '71'
export const PSYCHOTHERAPIST_ESANTE = '72'
export const CHIROPRACTOR_ESANTE = '73'
export const PODIATRIST_ESANTE = '80'
export const ORTHOPROSTHESIST_ESANTE = '81'
export const PEDORTHIST_ESANTE = '82'
export const ORTHOPEDIST_ESANTE = '83'
export const OCULARIST_ESANTE = '84'
export const EPITHESIST_ESANTE = '85'
export const MEDICAL_LAB_ASSISTANT_ESANTE = '86'
export const SPEECH_THERAPIST_ESANTE = '91'
export const ORTHOPTIST_ESANTE = '92'
export const PSYCHOLOGIST_ESANTE = '93'
export const OCCUPATIONAL_THERAPIST_ESANTE = '94'
export const DIETITIAN_ESANTE = '95'
export const PSYCHOMOTOR_THERAPIST_ESANTE = '96'
export const GENETIC_ADVISOR_ESANTE = '97'
export const RADIO_OPERATOR = '98'
export const DOMESTIC_HELPER_ESANTE = '350'
export const SECRETARY_ESANTE = '10001'
export const MEDICAL_ASSISTANT_ESANTE = '10002'

// roles for administration-medeo
export const ADMINISTATOR = 'administrator'
export const MEMBER = 'member'
export const NO_SPECIALTY = '00'

// esante doctor specialty
export const ANATOMY_SPECIALTY = 'SM01'
export const ANAESTHESIA_SPECIALTY = 'SM02'
export const MEDICAL_BIOLOGY_SPECIALTY = 'SM03'
export const CARDIOLOGY_SPECIALTY = 'SM04'
export const GENERAL_SURGERY_SPECIALTY = 'SM05'
export const FACE_SURGERY_SPECIALTY = 'SM06'
export const STOMATOLOGY_SURGERY_SPECIALTY = 'SM07'
export const ORTHOPAEDIC_SURGERY_SPECIALTY = 'SM08'
export const CHILD_SURGERY_SPECIALTY = 'SM09'
export const PLASTIC_SURGERY_SPECIALTY = 'SM10'
export const CARDIAC_SURGERY_SPECIALTY = 'SM11'
export const UROLOGICAL_SURGERY_SPECIALTY = 'SM12'
export const VASCULAR_SURGERY_SPECIALTY = 'SM13'
export const STOMACH_SURGERY_SPECIALTY = 'SM14'
export const DERMATOLOGY_SPECIALTY = 'SM15'
export const ENDOCRINOLOGY_SPECIALTY = 'SM16'
export const MEDICAL_GENETIC_SPECIALTY = 'SM17'
export const GERIATRICS_SPECIALTY = 'SM18'
export const MEDICAL_GYNAECOLOGY_SPECIALTY = 'SM19'
export const OBSTETRIC_SPECIALTY = 'SM20'
export const HEMATOLOGY_SPECIALTY = 'SM21'
export const HEMATOLOGY_BLOOD_DISEASE_SPECIALTY = 'SM22'
export const HEMATOLOGY_ONCOLOGY_SPECIALTY = 'SM23'
export const GASTROENTEROLOGY_SPECIALTY = 'SM24'
export const OCCUPATIONAL_MEDICINE_SPECIALTY = 'SM25'
export const GENERAL_MEDICINE_QUALIFIED_SPECIALTY = 'SM26'
export const INTERNAL_MEDICINE_SPECIALTY = 'SM27'
export const NUCLEAR_MEDICINE_SPECIALTY = 'SM28'
export const PHYSICAL_MEDICINE_SPECIALTY = 'SM29'
export const NEPHROLOGY_SPECIALTY = 'SM30'
export const NEUROSURGERY_SPECIALTY = 'SM31'
export const NEUROLOGY_SPECIALTY = 'SM32'
export const NEUROPSYCHIATRY_SPECIALTY = 'SM33'
export const ENT_SPECIALTY = 'SM34'
export const ONCOLOGY_SPECIALTY = 'SM35'
export const MEDICAL_ONCOLOGY_SPECIALTY = 'SM36'
export const RADIO_ONCOLOGY_SPECIALTY = 'SM37'
export const OPHTHALMOLOGY_SPECIALTY = 'SM38'
export const OTORHINOLARYNGOLOGY_SPECIALTY = 'SM39'
export const PEDIATRICS_SPECIALTY = 'SM40'
export const PULMONOLGY_SPECIALTY = 'SM41'
export const PSYCHIATRY_SPECIALTY = 'SM42'
export const CHILD_PSYCHIATRY_SPECIALTY = 'SM43'
export const X_RAY_DIAGNOSIS_SPECIALTY = 'SM44'
export const X_RAY_THERAPY_SPECIALTY = 'SM45'
export const INTENSIVE_CARE_SPECIALTY = 'SM46'
export const MEDICAL_INVESTIGATION_SPECIALTY = 'SM47'
export const RHEUMATOLOGY_SPECIALTY = 'SM48'
export const PUBLIC_HEALTH_SPECIALTY = 'SM49'
export const STOMATOLOGY_SPECIALTY = 'SM50'
export const GYNAECOLOGY_FIRST_OPTION_SPECIALTY = 'SM51'
export const GYNAECOLOGY_SECOND_OPTION_SPECIALTY = 'SM52'
export const GENERAL_MEDECINE_SPECIALIST_SPECIALTY = 'SM53'
export const GENERAL_MEDECINE_SPECIALTY = 'SM54'
export const X_RAY_SPECIALTY = 'SM55'
export const ENT_OPHTHALMOLOGY_SPECIALTY = 'SM99'

// esante pharmacist specialty
export const RADIO_PHARMACY_SPECIALTY = 'SP01'
export const HEALTH_SPECIALTY = 'SP02'
export const PHARMACOVIGILANCE_SPECIALTY = 'SP03'
export const HEMATOLOGYVIGILANCE_SPECIALTY = 'SP04'

// esante dentist specialty
export const DENTOFACIAL_ORTHOPAEDIC_SPECIALTY = 'SCD01'
export const ORAL_SURGERY_SPECIALTY = 'SCD02'
export const ORAL_DENTAL_MEDICINE_SPECIALTY = 'SCD03'

// esante nurse specialty
export const CHRONIC_PATHOLOGY_SPECIALTY = 'SI01'
export const ONCOLOGY_NURSE_SPECIALTY = 'SI02'
export const RENAL_DISEASE_SPECIALTY = 'SI03'
export const MENTAL_HEALTH_SPECIALTY = 'SI04'

export const getMedeoRoleFromGouvCode = code => {
  switch (code) {
    case GENERAL_PRACTITIONER_ESANTE:
      return <Trans>General Practitioner</Trans>
    case PHARMACIST_ESANTE:
      return <Trans>Pharmacist</Trans>
    case AUDIOPROSTHESIST_ESANTE:
      return <Trans>Audioprosthesist</Trans>
    case OPTICIAN_ESANTE:
      return <Trans>Optician</Trans>
    case DENTAL_ASSISTANT_ESANTE:
      return <Trans>Dental assistant</Trans>
    case MEDICAL_PHYSICIST_ESANTE:
      return <Trans>Medical physicist</Trans>
    case NURSING_ASSISTANT_ESANTE:
      return <Trans>Nursing assistant</Trans>
    case AMBULANCE_DRIVER_ESANTE:
      return <Trans>Ambulance driver</Trans>
    case CHILDCARE_ASSISTANT_ESANTE:
      return <Trans>Childcare assistant</Trans>
    case PHARMACEUTICAL_ASSISTANT_HOSPITAL_ESANTE:
      return <Trans>Pharmaceutical assistant in hospital</Trans>
    case PHARMACEUTICAL_ASSISTANT_DISPENSARY_ESANTE:
      return <Trans>Pharmaceutical assistant in dispensary</Trans>
    case DENTIST_ESANTE:
      return <Trans>Dentist</Trans>
    case SOCIAL_CARE_ASSISTANT_ESANTE:
      return <Trans>Social care assistant</Trans>
    case PERSONAL_CARER_ASSISTANT_ESANTE:
      return <Trans>Personal carer assistant</Trans>
    case SOCIAL_INTERVENTION_ASSISTANT_ESANTE:
      return <Trans>Social intervention assistant</Trans>
    case PSYCHOLOGICAL_ASSISTANT_ESANTE:
      return <Trans>Psychological assistant</Trans>
    case MIDWIFE_ESANTE:
      return <Trans>Midwife</Trans>
    case NURSE_ESANTE:
      return <Trans>Nurse</Trans>
    case PSYCHIATRIST_NURSE_ESANTE:
      return <Trans>Psychiatrist nurse</Trans>
    case PHYSIOTHERAPIST_ESANTE:
      return <Trans>Physiotherapist</Trans>
    case OSTEAOPATH_ESANTE:
      return <Trans>Osteopath</Trans>
    case PSYCHOTHERAPIST_ESANTE:
      return <Trans>Psychotherapist</Trans>
    case CHIROPRACTOR_ESANTE:
      return <Trans>Chiropractor</Trans>
    case PODIATRIST_ESANTE:
      return <Trans>Podiatrist</Trans>
    case ORTHOPROSTHESIST_ESANTE:
      return <Trans>Orthoprosthesist</Trans>
    case PEDORTHIST_ESANTE:
      return <Trans>Pedorthist</Trans>
    case ORTHOPEDIST_ESANTE:
      return <Trans>Orthopedist</Trans>
    case OCULARIST_ESANTE:
      return <Trans>Ocularist</Trans>
    case EPITHESIST_ESANTE:
      return <Trans>Epithesist</Trans>
    case MEDICAL_LAB_ASSISTANT_ESANTE:
      return <Trans>Medical laboratory assistant</Trans>
    case SPEECH_THERAPIST_ESANTE:
      return <Trans>Speech therapist</Trans>
    case ORTHOPTIST_ESANTE:
      return <Trans>Orthoptist</Trans>
    case PSYCHOLOGIST_ESANTE:
      return <Trans>Psychologist</Trans>
    case OCCUPATIONAL_THERAPIST_ESANTE:
      return <Trans>Occupational therapist</Trans>
    case DIETITIAN_ESANTE:
      return <Trans>Dietitian</Trans>
    case PSYCHOMOTOR_THERAPIST_ESANTE:
      return <Trans>Psychomotor therapist</Trans>
    case GENETIC_ADVISOR_ESANTE:
      return <Trans>Genetic advisor</Trans>
    case RADIO_OPERATOR:
      return <Trans>Radio operator</Trans>
    case DOMESTIC_HELPER_ESANTE:
      return <Trans>Domestic helper</Trans>
    case SECRETARY_ESANTE:
      return <Trans>Secretary</Trans>
    case MEDICAL_ASSISTANT_ESANTE:
      return <Trans>Medical assistant</Trans>
    case ADMINISTATOR:
      return <Trans>Administrator</Trans>
    case MEMBER:
      return <Trans>Member</Trans>
    default:
      return <Trans>Practitioner</Trans>
  }
}

export const getDoctorSpecialtyFromGouvCode = code => {
  switch (code) {
    case ANATOMY_SPECIALTY:
      return <Trans>Anatomy and pathological cytology</Trans>
    case ANAESTHESIA_SPECIALTY:
      return <Trans>Anaesthesia and intensive care</Trans>
    case MEDICAL_BIOLOGY_SPECIALTY:
      return <Trans>Medical biology</Trans>
    case CARDIOLOGY_SPECIALTY:
      return <Trans>Cardiology and vascular disease</Trans>
    case GENERAL_SURGERY_SPECIALTY:
      return <Trans>General surgery</Trans>
    case FACE_SURGERY_SPECIALTY:
      return <Trans>Maxillofacial surgery</Trans>
    case STOMATOLOGY_SURGERY_SPECIALTY:
      return <Trans>Maxillofacial surgery and stomatology</Trans>
    case ORTHOPAEDIC_SURGERY_SPECIALTY:
      return <Trans>Orthopaedic surgery and traumatology</Trans>
    case CHILD_SURGERY_SPECIALTY:
      return <Trans>Child surgery</Trans>
    case PLASTIC_SURGERY_SPECIALTY:
      return <Trans>Plastic and reconstrive surgery</Trans>
    case CARDIAC_SURGERY_SPECIALTY:
      return <Trans>Thoracic and cardiovascular surgery</Trans>
    case UROLOGICAL_SURGERY_SPECIALTY:
      return <Trans>Urological surgery</Trans>
    case VASCULAR_SURGERY_SPECIALTY:
      return <Trans>Vascular surgery</Trans>
    case STOMACH_SURGERY_SPECIALTY:
      return <Trans>Stomach and digestive surgery</Trans>
    case DERMATOLOGY_SPECIALTY:
      return <Trans>Dermatology and venereology</Trans>
    case ENDOCRINOLOGY_SPECIALTY:
      return <Trans>Endocrinology and metabolism</Trans>
    case MEDICAL_GENETIC_SPECIALTY:
      return <Trans>Medical genetic </Trans>
    case GERIATRICS_SPECIALTY:
      return <Trans>Geriatrics</Trans>
    case MEDICAL_GYNAECOLOGY_SPECIALTY:
      return <Trans>Medical gynaecology</Trans>
    case OBSTETRIC_SPECIALTY:
      return <Trans>Obstetric-gynaecology</Trans>
    case HEMATOLOGY_SPECIALTY:
      return <Trans>Hematology</Trans>
    case HEMATOLOGY_BLOOD_DISEASE_SPECIALTY:
      return <Trans>Hematology (blood disease option)</Trans>
    case HEMATOLOGY_ONCOLOGY_SPECIALTY:
      return <Trans>Hematology (oncology and hematology option)</Trans>
    case GASTROENTEROLOGY_SPECIALTY:
      return <Trans>Gastroenterology and hepatology</Trans>
    case OCCUPATIONAL_MEDICINE_SPECIALTY:
      return <Trans>Occupational medicine</Trans>
    case GENERAL_MEDICINE_QUALIFIED_SPECIALTY:
      return <Trans>General medicine qualified</Trans>
    case INTERNAL_MEDICINE_SPECIALTY:
      return <Trans>Internal medicine</Trans>
    case NUCLEAR_MEDICINE_SPECIALTY:
      return <Trans>Nuclear medicine</Trans>
    case PHYSICAL_MEDICINE_SPECIALTY:
      return <Trans>Physical medicine and rehabilitation</Trans>
    case NEPHROLOGY_SPECIALTY:
      return <Trans>Nephrology</Trans>
    case NEUROSURGERY_SPECIALTY:
      return <Trans>Neurosurgery</Trans>
    case NEUROLOGY_SPECIALTY:
      return <Trans>Neurology</Trans>
    case NEUROPSYCHIATRY_SPECIALTY:
      return <Trans>Neuropsychiatry</Trans>
    case ENT_SPECIALTY:
      return <Trans>ENT and cervical facial surgery</Trans>
    case ONCOLOGY_SPECIALTY:
      return <Trans>Oncology (option oncology hematology)</Trans>
    case MEDICAL_ONCOLOGY_SPECIALTY:
      return <Trans>Oncology medical option</Trans>
    case RADIO_ONCOLOGY_SPECIALTY:
      return <Trans>Oncology radiotherapy option</Trans>
    case OPHTHALMOLOGY_SPECIALTY:
      return <Trans>Ophthalmology</Trans>
    case OTORHINOLARYNGOLOGY_SPECIALTY:
      return <Trans>Otorhinolaryngology</Trans>
    case PEDIATRICS_SPECIALTY:
      return <Trans>Pediatrics</Trans>
    case PULMONOLGY_SPECIALTY:
      return <Trans>Pulmonology</Trans>
    case PSYCHIATRY_SPECIALTY:
      return <Trans>Psychiatry</Trans>
    case CHILD_PSYCHIATRY_SPECIALTY:
      return <Trans>Psychiatry child and teenager option</Trans>
    case X_RAY_DIAGNOSIS_SPECIALTY:
      return <Trans>Radiodiagnosis</Trans>
    case X_RAY_THERAPY_SPECIALTY:
      return <Trans>Radiotherapy</Trans>
    case INTENSIVE_CARE_SPECIALTY:
      return <Trans>Intensive care</Trans>
    case MEDICAL_INVESTIGATION_SPECIALTY:
      return <Trans>Medical investigation</Trans>
    case RHEUMATOLOGY_SPECIALTY:
      return <Trans>Rheumatology</Trans>
    case PUBLIC_HEALTH_SPECIALTY:
      return <Trans>Public health and social medicine</Trans>
    case STOMATOLOGY_SPECIALTY:
      return <Trans>Stomatology</Trans>
    case GYNAECOLOGY_FIRST_OPTION_SPECIALTY:
      return (
        <Trans>
          Obstetric gynaecology and medical gynaecology first option
        </Trans>
      )
    case GYNAECOLOGY_SECOND_OPTION_SPECIALTY:
      return (
        <Trans>
          Obstetric gynaecology and medical gynaecology second option
        </Trans>
      )
    case GENERAL_MEDECINE_SPECIALIST_SPECIALTY:
      return <Trans>General medicine specialist</Trans>
    case GENERAL_MEDECINE_SPECIALTY:
      return <Trans>General medicine</Trans>
    case X_RAY_SPECIALTY:
      return <Trans>Radiodiagnosis and radiotherapy</Trans>
    case ENT_OPHTHALMOLOGY_SPECIALTY:
      return <Trans>ENT and ophthalmology</Trans>
    default:
      return <Trans>No specialty</Trans>
  }
}

export const getPharmacistSpecialtyFromGouvCode = code => {
  switch (code) {
    case RADIO_PHARMACY_SPECIALTY:
      return <Trans>Radio pharmacy</Trans>
    case HEALTH_SPECIALTY:
      return <Trans>Health</Trans>
    case PHARMACOVIGILANCE_SPECIALTY:
      return <Trans>Pharmacovigilance</Trans>
    case HEMATOLOGYVIGILANCE_SPECIALTY:
      return <Trans>Hematology vigilance</Trans>
    default:
      return <Trans>No specialty</Trans>
  }
}

export const getDentistSpecialtyFromGouvCode = code => {
  switch (code) {
    case DENTOFACIAL_ORTHOPAEDIC_SPECIALTY:
      return <Trans>Dentofacial orthopaedic</Trans>
    case ORAL_SURGERY_SPECIALTY:
      return <Trans>Oral surgery</Trans>
    case ORAL_DENTAL_MEDICINE_SPECIALTY:
      return <Trans>Oral dental medicine</Trans>
    default:
      return <Trans>No specialty</Trans>
  }
}

export const getNurseSpecialtyFromGouvCode = code => {
  switch (code) {
    case CHRONIC_PATHOLOGY_SPECIALTY:
      return <Trans>Chronic pathology</Trans>
    case ONCOLOGY_NURSE_SPECIALTY:
      return <Trans>Oncology</Trans>
    case RENAL_DISEASE_SPECIALTY:
      return <Trans>Renal disease</Trans>
    case MENTAL_HEALTH_SPECIALTY:
      return <Trans>Mental health</Trans>
    default:
      return <Trans>No specialty</Trans>
  }
}

export const getSpecialtyCodingFromSystem = (practitionerRole, system) => {
  return practitionerRole?.specialty?.find(
    spe => spe.coding?.[0].system === system
  )?.coding?.[0]
}

export const getSpecialtyTransFromOccupation = (occupation, code) => {
  switch (occupation) {
    case GENERAL_PRACTITIONER_ESANTE:
      return getDoctorSpecialtyFromGouvCode(code)
    case PHARMACIST_ESANTE:
      return getPharmacistSpecialtyFromGouvCode(code)
    case NURSE_ESANTE:
      return getNurseSpecialtyFromGouvCode(code)
    case DENTIST_ESANTE:
      return getDentistSpecialtyFromGouvCode(code)
    default:
      return <Trans>No specialty</Trans>
  }
}

export const allMedicalRoles = [
  GENERAL_PRACTITIONER_ESANTE,
  PHARMACIST_ESANTE,
  AUDIOPROSTHESIST_ESANTE,
  OPTICIAN_ESANTE,
  DENTAL_ASSISTANT_ESANTE,
  MEDICAL_PHYSICIST_ESANTE,
  NURSING_ASSISTANT_ESANTE,
  AMBULANCE_DRIVER_ESANTE,
  CHILDCARE_ASSISTANT_ESANTE,
  PHARMACEUTICAL_ASSISTANT_HOSPITAL_ESANTE,
  PHARMACEUTICAL_ASSISTANT_DISPENSARY_ESANTE,
  DENTIST_ESANTE,
  SOCIAL_CARE_ASSISTANT_ESANTE,
  PERSONAL_CARER_ASSISTANT_ESANTE,
  SOCIAL_INTERVENTION_ASSISTANT_ESANTE,
  PSYCHOLOGICAL_ASSISTANT_ESANTE,
  MIDWIFE_ESANTE,
  NURSE_ESANTE,
  PSYCHIATRIST_NURSE_ESANTE,
  PHYSIOTHERAPIST_ESANTE,
  OSTEAOPATH_ESANTE,
  PSYCHOTHERAPIST_ESANTE,
  CHIROPRACTOR_ESANTE,
  PODIATRIST_ESANTE,
  ORTHOPROSTHESIST_ESANTE,
  PEDORTHIST_ESANTE,
  ORTHOPEDIST_ESANTE,
  OCULARIST_ESANTE,
  EPITHESIST_ESANTE,
  MEDICAL_LAB_ASSISTANT_ESANTE,
  SPEECH_THERAPIST_ESANTE,
  ORTHOPTIST_ESANTE,
  PSYCHOLOGIST_ESANTE,
  OCCUPATIONAL_THERAPIST_ESANTE,
  DIETITIAN_ESANTE,
  PSYCHOMOTOR_THERAPIST_ESANTE,
  GENETIC_ADVISOR_ESANTE,
  RADIO_OPERATOR,
  DOMESTIC_HELPER_ESANTE,
  SECRETARY_ESANTE,
  MEDICAL_ASSISTANT_ESANTE,
]

export const allAdministrationRoles = [ADMINISTATOR, MEMBER]

export const allDoctorSpecialty = [
  ANATOMY_SPECIALTY,
  ANAESTHESIA_SPECIALTY,
  MEDICAL_BIOLOGY_SPECIALTY,
  CARDIOLOGY_SPECIALTY,
  GENERAL_SURGERY_SPECIALTY,
  FACE_SURGERY_SPECIALTY,
  STOMATOLOGY_SURGERY_SPECIALTY,
  ORTHOPAEDIC_SURGERY_SPECIALTY,
  CHILD_SURGERY_SPECIALTY,
  PLASTIC_SURGERY_SPECIALTY,
  CARDIAC_SURGERY_SPECIALTY,
  UROLOGICAL_SURGERY_SPECIALTY,
  VASCULAR_SURGERY_SPECIALTY,
  STOMACH_SURGERY_SPECIALTY,
  DERMATOLOGY_SPECIALTY,
  ENDOCRINOLOGY_SPECIALTY,
  MEDICAL_GENETIC_SPECIALTY,
  GERIATRICS_SPECIALTY,
  MEDICAL_GYNAECOLOGY_SPECIALTY,
  OBSTETRIC_SPECIALTY,
  HEMATOLOGY_SPECIALTY,
  HEMATOLOGY_BLOOD_DISEASE_SPECIALTY,
  HEMATOLOGY_ONCOLOGY_SPECIALTY,
  GASTROENTEROLOGY_SPECIALTY,
  OCCUPATIONAL_MEDICINE_SPECIALTY,
  GENERAL_MEDICINE_QUALIFIED_SPECIALTY,
  INTERNAL_MEDICINE_SPECIALTY,
  NUCLEAR_MEDICINE_SPECIALTY,
  PHYSICAL_MEDICINE_SPECIALTY,
  NEPHROLOGY_SPECIALTY,
  NEUROSURGERY_SPECIALTY,
  NEUROLOGY_SPECIALTY,
  NEUROPSYCHIATRY_SPECIALTY,
  ENT_SPECIALTY,
  ONCOLOGY_SPECIALTY,
  MEDICAL_ONCOLOGY_SPECIALTY,
  RADIO_ONCOLOGY_SPECIALTY,
  OPHTHALMOLOGY_SPECIALTY,
  OTORHINOLARYNGOLOGY_SPECIALTY,
  PEDIATRICS_SPECIALTY,
  PULMONOLGY_SPECIALTY,
  PSYCHIATRY_SPECIALTY,
  CHILD_PSYCHIATRY_SPECIALTY,
  X_RAY_DIAGNOSIS_SPECIALTY,
  X_RAY_THERAPY_SPECIALTY,
  INTENSIVE_CARE_SPECIALTY,
  MEDICAL_INVESTIGATION_SPECIALTY,
  RHEUMATOLOGY_SPECIALTY,
  PUBLIC_HEALTH_SPECIALTY,
  STOMATOLOGY_SPECIALTY,
  GYNAECOLOGY_FIRST_OPTION_SPECIALTY,
  GYNAECOLOGY_SECOND_OPTION_SPECIALTY,
  GENERAL_MEDECINE_SPECIALIST_SPECIALTY,
  GENERAL_MEDECINE_SPECIALTY,
  X_RAY_SPECIALTY,
  ENT_OPHTHALMOLOGY_SPECIALTY,
  NO_SPECIALTY,
]

export const allPharmacistSpecialtyCode = [
  RADIO_PHARMACY_SPECIALTY,
  HEALTH_SPECIALTY,
  PHARMACOVIGILANCE_SPECIALTY,
  HEMATOLOGYVIGILANCE_SPECIALTY,
  NO_SPECIALTY,
]

export const allDentistSpecialtyCode = [
  DENTOFACIAL_ORTHOPAEDIC_SPECIALTY,
  ORAL_SURGERY_SPECIALTY,
  ORAL_DENTAL_MEDICINE_SPECIALTY,
  NO_SPECIALTY,
]

export const allNurseSpecialtyCode = [
  CHRONIC_PATHOLOGY_SPECIALTY,
  ONCOLOGY_NURSE_SPECIALTY,
  RENAL_DISEASE_SPECIALTY,
  MENTAL_HEALTH_SPECIALTY,
  NO_SPECIALTY,
]
