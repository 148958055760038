import React from 'react'
import styled from 'styled-components/macro'
import moment from 'moment'
const Header = styled.div`
  & > div:first-child {
    font-weight: bold !important;
    margin-bottom: 0.5rem;
  }
  & > div:last-child {
    font-weight: normal !important;
  }
`
const CalendarHeader = ({ date }) => (
  <Header>
    <div>{moment(date).format('dddd')}</div>
    <div>{moment(date).format('DD MMM')}</div>
  </Header>
)

export default CalendarHeader
