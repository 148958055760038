// This util is used in redux's byId reducer
import { OPEN_ID_TOKEN_RECEIVED, SIGN_OUT_REQUESTED } from '../../Auth/actions'
//@ts-ignore
import buildFhir from 'lifen-fhir.js/src/adapters/native'
import { FHIR_BASE_URL } from '../../_common/config'

export const reduceById = (state, entities) =>
  entities === undefined
    ? { ...state }
    : entities.reduce((newState, e) => {
        if (e.id == null) {throw new Error('entity must have an id')}
        return { ...newState, [e.id]: e }
      }, state)

export const appendAllUniqueIds = (state, entities) =>
  entities === undefined
    ? [...state]
    : [
        ...state,
        ...(entities.length == null ? [entities.id] : entities.map(e => e.id))
      ].filter((e, i, a) => a.indexOf(e) === i)

export const removeById = (state, entity) => {
  // eslint-disable-next-line
  const { [entity.id]: _, ...rest } = state
  return rest
}
// check for Respi has been removed as the project will be migrated to AWS
let config = {
  baseUrl: FHIR_BASE_URL.href, //FHIR_BASE_URL.href, // local : "href", remote on azure : "origin"
  credentials: 'same-origin'
}

export const initialDevState = buildFhir(config)

/**
 * @typedef State
 * @type { fhirClient.FhirClient | null}
 *
 * @param state {State}
 * @param action
 * @returns {State}
 */

export const fhirClient = (state = null, action) => {
  const { type, payload } = action
  switch (type) {
    case OPEN_ID_TOKEN_RECEIVED:
      return buildFhir({
        ...config,
        auth: {
          bearer: payload.token
        }
      })
    case SIGN_OUT_REQUESTED:
      return null
    default:
      return state
  }
}
