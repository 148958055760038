import { useCondition } from '../Questionnaire/components/QuestionnaireComponent'
import { getItemByLinkId } from '../Questionnaire/utils'

// Those two function bellow are used as an equivalent for flatMap()
// which is supported since node 11 only
const concat = (x, y) => x.concat(y)

const implementedFlatMap = (f, xs) => xs.map(f).reduce(concat, [])

/**
 * this method transforms the nested structure of the questionnaireResponse
 * item and returns the items in a list, that could be used with standard
 * array method or evaluate
 * @param questionnaireResponse
 * @returns {any[]}
 */
export const flattenItems = questionnaireResponse => {
  if (questionnaireResponse == null || questionnaireResponse.item == null)
    {return []}
  const flattenRecursively = item => {
    if (item.item == null) {return item}
    return [].concat.apply(
      [],
      [item, implementedFlatMap(flattenRecursively, item.item)]
    )
  }

  return implementedFlatMap(flattenRecursively, questionnaireResponse.item)
}

/**
 * Merge the questionnaire and the questionnaire response both together
 * The aim is to build a new object which will contain:
 * - all the visible items depending on the response, even those which do not have a response
 * - all the answers contained in the response with an added 'text' field, containing
 * the text of the corresponding questionnaire item
 * This merge is done with the aim to have one single resource containing all the information
 * we need to display a QuestionnaireResponse, and do not rely on both a questionnaire and a response
 * In order to see what the result should look like, take a look at the QuestionnaireResponse/_tests_/utils.spec.js
 *
 * @param {Object} result: the object we take as entry
 * @param {Object} questionnaire
 * @param {Object} response
 */
export const mergeWithQuestionnaire = (result, questionnaire, response) => {
  if (questionnaire == null) {
    return response
  }
  // If the questionnaire contains an item field
  if (questionnaire.item) {
    // We add an "item" field to the result object
    result.item = []
    if (questionnaire.text) {result.text = questionnaire.text}
    // For each item
    questionnaire.item.map(item => {
      // We check if this one should be visible or not (handle the 'enableWhen')
      const visible = useCondition(item, response)
      // If it shouldn't be, do not add anything
      if (visible === false) {
        return null
      } else {
        // Otherwise, add and object to this item array
        result.item.push({ linkId: item.linkId, text: item.text })
        // Go one level deeper to fill the sub-items in the result object
        const resultItem = result.item.find(
          resultItem => resultItem.linkId === item.linkId
        )
        return mergeWithQuestionnaire(resultItem, item, response)
      }
    })
  } else {
    // Find if there is an answer for this item
    const responseItem = getItemByLinkId(response, questionnaire.linkId)
    // If yes, add a response to this item in the result object
    if (responseItem) {result.answer = responseItem.answer}
    // If there is a response and this one has a text, add it to the result object
    if (responseItem && responseItem.text) {result.text = responseItem.text}
    // If there is a response and this one has a definition, add it to the result object
    if (responseItem && responseItem.definition)
      {result.definition = responseItem.definition}
    // Otherwise, add the one of the questionnaire item
    else if (questionnaire.text) {result.text = questionnaire.text}
  }
  return result
}
