import React from 'react';
import { useSelector } from 'react-redux';
import { getDeprecatedPractitionerById } from '../../Practitioner/selector';
import {
  displayFullNameWithTitle,
  displayPhoneNumber
} from '../../Shared/display/entity';
import { Trans } from '@lingui/macro';
import { Label, SectionPart } from './PatientInfo';
import { getIdByReference } from '../../Shared/utils';
import { MEDEO_GENERAL_PRACTITIONER_IDENTIFIER_MEDECIN_TRAITANT_CODE } from '../sagas';
import { getGeneralPractitioner } from '../../utils/checkInfoPatient';
import { MEDEO_RPPS_IDENTIFIER_SYSTEM } from '../../utils/codes';

const GeneralPractitionerSectionPart = ({ patient }) => {
  /*
   * get Medecin Traitant by selecting the reference where the display or the correct identifier are set
   */
  const generalPractitionerPatient = patient.generalPractitioner?.find(
    gp =>
      gp.identifier?.value ===
      MEDEO_GENERAL_PRACTITIONER_IDENTIFIER_MEDECIN_TRAITANT_CODE
  );

  let id = '0';
  let gp = null;
  if (generalPractitionerPatient) {
    if (generalPractitionerPatient.display != null) {
      gp = { name: generalPractitionerPatient.display };
    } else if (generalPractitionerPatient.reference != null) {
      id = getIdByReference(generalPractitionerPatient.reference);
    }
  } else if (patient.generalPractitioner?.some(gp => gp.display != null)) {
    gp = getGeneralPractitioner(patient);
  }

  const generalPractitioner = useSelector(state =>
    getDeprecatedPractitionerById(state, id)
  );
  if (generalPractitioner != null) {
    gp = {
      name: displayFullNameWithTitle(generalPractitioner),
      telecom: generalPractitioner.telecom,
      rpps: generalPractitioner?.identifier?.find?.(
        i => i.system === MEDEO_RPPS_IDENTIFIER_SYSTEM
      )?.value
    };
  }

  return (
    <SectionPart>
      <Label>
        <Trans>GP</Trans>
      </Label>
      <div>
        {gp?.name ?? (
          <div style={{ marginBottom: '1rem' }}>
            <Trans>No GP were found</Trans>
          </div>
        )}
        {gp?.rpps && (
          <div
            style={{
              fontSize: 'small',
              display: 'flex',
              alignItems: 'baseline'
            }}
          >
            <Label>
              <Trans>RPPS</Trans> :&nbsp;
            </Label>
            <div style={{ marginTop: '0.25rem' }}>{gp.rpps}</div>
          </div>
        )}
        {gp?.telecom && (
          <div
            style={{
              fontSize: 'small',
              display: 'flex',
              alignItems: 'baseline'
            }}
          >
            <Label>
              <Trans>Tel</Trans> :&nbsp;
            </Label>
            <div style={{ marginBottom: '0.5rem' }}>
              {gp != null && displayPhoneNumber(gp)}
            </div>
          </div>
        )}
      </div>
    </SectionPart>
  );
};

export default GeneralPractitionerSectionPart;
