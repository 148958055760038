import React from 'react'
import QuestionnaireCheckbox from './QuestionnaireCheckbox'
import QuestionnaireDropdown from './QuestionnaireDropdown'
import QuestionnaireSelect from './QuestionnaireSelect'
import QuestionnaireRadio from './QuestionnaireRadio'
import QuestionnaireBoolean from './QuestionnaireBoolean'

/**
 * This element handles radio-buttons, selects and checkboxes,
 * as they are all defined by the type "choice" in the FHIR Questionnaires
 */
const QuestionnaireChoice = ({ item, readOnly, ...rest }) => {
  // The type can come either from the extension (for instance to distinguish check-box from radio btns)
  // or from the item type itself
  const type =
    item?.extension?.[0]?.valueCodeableConcept?.coding?.[0]?.code ?? item.type

  switch (type) {
    case 'check-box':
      return <QuestionnaireCheckbox item={item} readOnly={readOnly} {...rest} />
    case 'drop-down':
      return <QuestionnaireDropdown item={item} readOnly={readOnly} {...rest} />
    case 'radio-button':
      return <QuestionnaireRadio item={item} readOnly={readOnly} {...rest} />
    case 'boolean':
      return <QuestionnaireBoolean item={item} {...rest} />
    default:
      if (item.option?.length < 5)
        {return <QuestionnaireRadio item={item} readOnly={readOnly} {...rest} />}
      else
        {return <QuestionnaireSelect item={item} readOnly={readOnly} {...rest} />}
  }
}

export default QuestionnaireChoice
