import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components/macro'
import {
  faFilePdf,
  faPaperclip,
  faImage,
} from '@fortawesome/free-solid-svg-icons'

const Aside = styled.aside`
  display: flex;
  & > span {
    display: block;
    flex: 0 1;
  }
`
/**
 * return a fontAwesome icon depending on the contentType
 * passed as a parameter. it defaults to a paperClip
 * @param contentType
 * @returns {IconDefinition}
 */
const getIconFromContentType = contentType => {
  switch (contentType) {
    case 'application/pdf':
      return faFilePdf
    case 'image/png':
    case 'image/jpeg':
      return faImage
    default:
      return faPaperclip
  }
}
/**
 * Displays an icon corresponding to the current attachment.
 * This is an aside eventhough it takes all the place in the component. :P
 * @param attachment
 * @returns {*}
 * @constructor
 */
const AttachmentAside = ({ attachment }) => {
  const icon = getIconFromContentType(attachment?.contentType)

  return (
    <Aside>
      {/* the span is required if we want to center the icon...*/}
      <span>
        <FontAwesomeIcon size="2x" icon={icon} />
      </span>
    </Aside>
  )
}

export default AttachmentAside
