import React from 'react';
import { useSelector } from 'react-redux';
import StaticErrorNotification, {
  NO_ORGANIZATION_EMAIL
} from '../../Notifications/components/StaticErrorNotification';
import { getAdministratorRightBypractitionerID } from '../../PractitionerRole/selector';
import { MEDEO_ADMINISTRATOR_CODE } from '../../utils/codes';
import { getCurrentOrganization } from '../selector';
import usePractitioner from '../../Practitioner/usePractitioner';

/**
 * This component is for displaying, to Administrator user, a warning for add an email to the organization
 */
const OrganizationEmailWarning = () => {
  const currentPractitioner = usePractitioner();
  const isAdmin = useSelector(s =>
    getAdministratorRightBypractitionerID(
      s,
      currentPractitioner.id,
      MEDEO_ADMINISTRATOR_CODE
    )
  );
  const organization = useSelector(getCurrentOrganization);
  const organizationHasEmail =
    organization.telecom != null &&
    organization.telecom.find(({ system }) => system === 'email')?.value !=
      null;
  return (
    !organizationHasEmail &&
    isAdmin && <StaticErrorNotification error={NO_ORGANIZATION_EMAIL} />
  );
};

export default OrganizationEmailWarning;
