import React from 'react'
import { useSelector } from 'react-redux'
import { useSearch } from '../../Shared/hooks'
import styled from 'styled-components/macro'
import moment from 'moment'
import RequirePermission from '../../Permissions/containers/RequirePermission'
import ChooseEncounterTypeDialog from '../../Encounter/containers/ChooseEncounterTypeDialog'

import CancelTeleconsultationModal from '../../Teleconsultation/components/CancelTeleconsultationModal'
import { getPatientActiveProcedureRequests } from '../../ProcedureRequest/selectors'
import {
  MEDEO_APPOINTMENT_IDENTIFIER_SYSTEM,
  MEDEO_APPOINTMENT_TYPE_TELECONSULTATION,
  MEDEO_PROCEDURE_REQUEST_BOOKING_APPOINTMENT_CODE,
  MEDEO_PROCEDURE_REQUEST_CODE_SYSTEM,
  MEDEO_PROCEDURE_REQUEST_REMOTE_CONSULTATION_CODE
} from '../../utils/codes'
import { getAppointmentsBypatientID } from '../../Appointment/selectors'

const Div = styled.div`
  width: 100%;
  display: flex;
  padding-top: 2rem;
  padding-bottom: 2rem;
  background: #ebf2fa;
  position: sticky;
  top: 0;
  justify-content: space-between;
  align-items: center;
  z-index: 10;
`
const Top = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  & > :not(:last-child) {
    margin-right: 0.5rem;
  }
`

const Bottom = styled.div`
  display: flex;
  justify-content: space-between;

  & > :first-child {
    flex: 1;
  }

  & > :not(:last-child) {
    margin-right: 0.5rem;
  }
`
const TeleconsultationTools = styled.div`
  display: flex;
  color: 'ocean';
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`

/**
 * Check whether the procedure request is TLC related
 *
 * @param {ProcedureRequest} procedureRequest
 * @returns boolean
 */
const isTLCProcedureRequest = procedureRequest => {
  const code = procedureRequest.code.coding?.find(
    coding => coding.system === MEDEO_PROCEDURE_REQUEST_CODE_SYSTEM
  )?.code
  // ProcedureRequest codes related to TLC
  return (
    code === MEDEO_PROCEDURE_REQUEST_REMOTE_CONSULTATION_CODE ||
    code === MEDEO_PROCEDURE_REQUEST_BOOKING_APPOINTMENT_CODE
  )
}

/**
 * Check whether the appointment is TLC related
 *
 * @param {Appointment} appointment
 * @returns boolean
 */
const isTLCAppointment = appointment => {
  return (
    appointment.identifier?.find(
      identifier => identifier.system === MEDEO_APPOINTMENT_IDENTIFIER_SYSTEM
    )?.value === MEDEO_APPOINTMENT_TYPE_TELECONSULTATION
  )
}

const PatientPageHeader = ({ patient, hasCurrentEncounter }) => {
  // The procedure request we are interested in here is the one we want to be able to
  // cancel in the TLC scope.
  const [activeProcedureRequest] = useSelector(state =>
    getPatientActiveProcedureRequests(state, patient.id, [
      isTLCProcedureRequest
    ])
  )

  const { error } = useSearch('Appointment', {
    patient: `Patient/${patient.id}`,
    _sort: '-_id',
    // status: 'booked'
  })

  // Here we are using the selector instead of the data out of the
  // useSearch so that we get any new appointment taken right away when
  // one is booked from the patient file.
  const tlcAppointments = useSelector(
    getAppointmentsBypatientID(patient.id, [isTLCAppointment])
  )
  if (error) {return <div>oh noes</div>}

  // here we want to know if the Patient have an Appointment today in order to display, or not, the preconsultation button
  const hasAppointmentToday =
    tlcAppointments?.filter(a => moment(a.start).isSame(moment(), 'day'))
      .length !== 0

  const hasAppointmentInTheFuture =
    tlcAppointments?.filter(a => moment(a.start).isSameOrAfter(moment(), 'day'))
      .length !== 0

  const passedOpenAppointment = tlcAppointments?.find(a =>
    moment(a.start).isBefore(moment(), 'day')
  )

  const canCancelTlc =
    activeProcedureRequest != null &&
    // This is to know whether tlcAppointments in the future will come,
    // as we were not setting the appointment status to fullfilled before
    // which could conduct to a cancellation of one in the future when
    // we want here to cancel the not finished tlc procedure from the past
    passedOpenAppointment != null &&
    !hasAppointmentInTheFuture

  return (
    <Div id="header">
      {/* ID on header is necessary for the scroll at the top in EncounterList */}
      <Top>
        {!hasCurrentEncounter && (
          <RequirePermission resource={'encounter'} action={'start'}>
            <ChooseEncounterTypeDialog
              hasAppointmentToday={hasAppointmentToday}
            />
          </RequirePermission>
        )}
      </Top>
      <Bottom>
        <RequirePermission
          resource={'teleconsultationPatientRoom'}
          action={'go'}
        >
          {canCancelTlc && (
            <TeleconsultationTools>
              <CancelTeleconsultationModal
                procedureRequest={activeProcedureRequest}
                appointment={passedOpenAppointment}
              />
            </TeleconsultationTools>
          )}
        </RequirePermission>
      </Bottom>
    </Div>
  )
}
export default PatientPageHeader
