import moment from 'moment'
import { Schedule } from 'fhir-stu3'
import { ReduxState } from '../Shared/ducks/types'

export const getPlanningByFirstAndLastDay = (
  date: string,
  view: 'week' | 'month' | 'day',
  schedules: Schedule[]
) => {
  // default we put the filter for one day, the day which appears in url
  let firstDay = moment(date).startOf('day')
  let lastDay = moment(date).endOf('day')
  //if we have week view in url
  if (view === 'week') {
    firstDay = moment(date).startOf('week')
    lastDay = moment(date).endOf('week')
    //if we have month view in url
  } else if (view === 'month') {
    firstDay = moment(date).startOf('month')
    lastDay = moment(date).endOf('month')
  }
  // we filter schedule to keep only schedule of our view and date range.
  return schedules.filter(day => {
    let startDate = new Date(day?.planningHorizon?.start ?? '')
    let endDate = new Date(day?.planningHorizon?.end ?? '')
    return (
      startDate >= new Date(firstDay.toDate()) &&
      endDate <= new Date(lastDay.toDate())
    )
  })
}

/**
 * returns the list of schedules of the actor
 * @param {string} actorId
 * @return {function(*): *}
 */
//TODO rename to getCurriedSchedulesByActorId
export const getSchedulesByActorId = (actorId: string) => (state: ReduxState) =>
  state.Schedule.byActor[actorId]?.map(id => state.Schedule.byId[id]) ?? []

export const getScheduleById = (scheduleId: string) => (state: ReduxState) => {
  return state.Schedule.byId[scheduleId]
}
