import React from 'react'
import styled from 'styled-components/macro'

const ControlFooter = styled.footer`
  display: flex;
  justify-content: center;
  padding: 0.5rem 0;
`

const ProgressFooter = styled.footer`
  padding: 0.5rem;
  display: block;
`
const Percent = styled.div`
  font-size: small;
`

const ProgressBar = styled.div`
  height: 0.25rem;
  background: white;
  overflow: hidden;
  border-radius: 0.125rem;
`
const ProgressIndicator = styled.span`
  background: ${p => p.theme.ocean};
  width: ${p => `${p.percent}%`};
  display: block;
  transition: all 300ms ease-in-out;
  height: 0.25rem;
`
const UndeterminedProgressIndicator = styled.span`
  background: ${p => p.theme.ocean};
  animation-name: MOVE-BG;
  animation-duration: 1.33s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  @keyframes MOVE-BG {
    from {
      transform: translateX(-100%);
    }
    to {
      transform: translateX(200%);
    }
  }
  width: 50%;
  display: block;
  transition: all 300ms ease-in-out;
  height: 0.25rem;
`

const DocumentReferenceFooter = ({ attachment, progress, children }) =>
  attachment?.url != null ? (
    <ControlFooter>{children}</ControlFooter>
  ) : progress == null ? (
    <ProgressFooter>
      <ProgressBar>
        <UndeterminedProgressIndicator />
      </ProgressBar>
    </ProgressFooter>
  ) : (
    <ProgressFooter>
      <Percent>{progress}%</Percent>
      <ProgressBar>
        <ProgressIndicator percent={progress} />
      </ProgressBar>
    </ProgressFooter>
  )

DocumentReferenceFooter.ControlFooter = ControlFooter

export default DocumentReferenceFooter
