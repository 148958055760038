import styled from 'styled-components/macro'

const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  margin: auto;
  justify-content: center;
  width: 30rem;
  animation: fadein 300ms ease-out;
  @keyframes fadein {
    from {
      opacity: 0;
      transform: translateY(-2rem);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  & a {
    margin-top: 2rem;
    text-align: center;
  }
`

export default Container
