import React, { useEffect, useMemo, useReducer, useRef } from 'react';
import styled from 'styled-components/macro';
import { darken } from 'polished';
import ScreenVideo from './ScreenVideo';
import { Container } from '../../Notifications/components/StaticErrorNotification';
import { disconnect, getAudioTrackLevel, startVideo } from '../utils';
import CommandPanel from './CommandPanel';
import { Trans } from '@lingui/macro';

const VideoDiv = styled.div`
  height: 500px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  background-color: grey;
  margin: 1rem 0;
`;

const DivContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${p => darken(0.37, p.theme.gray)};
`;

const reducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case 'setError':
      return { ...state, errorMessages: [...state.errorMessages, payload] };
    case 'setAudioLevel':
      return { ...state, audioLevel: payload };
    case 'setVideoMaterial':
      return {
        ...state,
        previewVideoTrack: payload.previewVideoTrack,
        room: payload.room,
        status: 'connected'
      };
    case 'disconnecting':
      return {
        ...state,
        previewVideoTrack: null,
        room: null,
        status: 'disconnected'
      };
    case 'connecting':
      return { ...state, status: 'connecting' };
    default:
      return state;
  }
};

const initialState = {
  audioLevel: null,
  errorMessages: [],
  previewVideoTrack: null,
  room: null,
  status: 'connecting'
};

/**
 * Test out user camera and microphone
 */
const Visio = ({ identity, organizationId }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const localMediaContainer = useRef();
  const remoteMediaContainer = useRef();

  const room = useRef();

  const audioContext = useMemo(() => {
    const AudioContext = window.AudioContext || window.webkitAudioContext;
    return AudioContext ? new AudioContext() : null;
  }, []);
  // Use effect triggered when the status changes to 'connecting',
  // meaning that the video has to start
  useEffect(() => {
    if (state.room == null && state.status === 'connecting') {
      startVideo(identity, organizationId, remoteMediaContainer.current)
        .then(({ audioTrack, previewVideoTrack, room }) => {
          dispatch({
            type: 'setVideoMaterial',
            payload: { room, previewVideoTrack }
          });

          const video = localMediaContainer.current.appendChild(
            previewVideoTrack.attach()
          );
          video.setAttribute(
            'style',
            'object-fit: cover; width: 100%; height: 100%'
          );

          // Track the audio level
          getAudioTrackLevel({ audioContext, audioTrack }, level => {
            if (level !== 0)
              {dispatch({ type: 'setAudioLevel', payload: level });}
          });
        })
        // Display any error which would have occured while trying to start the
        // video
        .catch(error => {
          dispatch({ type: 'setError', payload: error });
        });
    }
  }, [state.status, audioContext, identity, organizationId, state.room]);

  useEffect(() => {
    // We keep the room in a reference in order to be able to
    // access it and disconnect from it on component unmount
    if (state.room != null) {room.current = state.room;}
  }, [state.room]);

  // Automatically trigger the start of the video once the
  // VideoScreen component got mounted
  useEffect(() => {
    if (localMediaContainer != null) {
      dispatch({ type: 'connecting' });
    }
  }, [localMediaContainer]);

  useEffect(() => {
    return () => {
      if (room.current != null) {
        disconnect(state.previewVideoTrack, room.current);
      }
    };
  }, [state.previewVideoTrack]);

  return (
    <div>
      <h1>
        <Trans>Video test</Trans>
      </h1>
      <VideoDiv>
        <DivContainer>
          <ScreenVideo
            localMediaContainer={localMediaContainer}
            remoteMediaContainer={remoteMediaContainer}
            audioLevel={state.audioLevel}
            status={state.status}
          />
          <CommandPanel
            status={state.status}
            onClose={() => {
              disconnect(state.previewVideoTrack, state.room);
              dispatch({ type: 'disconnecting' });
            }}
            onStart={() => {
              dispatch({ type: 'connecting' });
            }}
          />
        </DivContainer>
      </VideoDiv>
      {state.errorMessages.map((message, i) => (
        <Container key={i}>
          {message.type} : {message.error}
        </Container>
      ))}
    </div>
  );
};

export default Visio;
