import { call, takeEvery } from '@redux-saga/core/effects'
import { saveFhirResourceWorker } from '../Shared/sagas'
import { ADDING_REQUEST_GROUP } from '../RequestGroup/actions'

function* saveRequestGroup(action) {
  const {
    actions,
    planDefinitionId,
    encounterID,
    patientID,
    practitionerID,
  } = action.payload

  const requestGroupFhirResource = {
    resourceType: 'RequestGroup',
    definition: {
      reference: `PlanDefinition/${planDefinitionId}`,
    },
    status: 'completed',
    subject: {
      reference: `Patient/${patientID}`,
    },
    context: {
      reference: `Encounter/${encounterID}`,
    },
    author: {
      reference: `Practitioner/${practitionerID}`,
    },
    action: actions,
  }

  yield call(saveFhirResourceWorker, requestGroupFhirResource)
}

function* rootSaga() {
  //TODO: probably delete this saga, not sure it is still in use...
  yield takeEvery([ADDING_REQUEST_GROUP], saveRequestGroup)
}

export default rootSaga
