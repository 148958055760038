import styled from 'styled-components/macro'

/**
 * DataListItem should be used in addition of the MultipleSearchInput component
 * from @medeo/component
 * a DataListItem refers to a line in the prompt of the input.
 *
 * c.f. YamlMedicationStatementInput and MedicationDataListItem for more info
 */
const DataListItem = styled.li`
  display: flex;
  flex-direction: column;
  & > :first-child {
    color: ${p => p.theme.ebony};
  }
  & > :last-child {
    font-size: small;
    margin-top: 0.5rem;
    color: ${p => p.theme.gray};
  }
`

export default DataListItem
