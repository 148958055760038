import { Trans } from '@lingui/macro'
import React from 'react'
import PatientConsent from '../../Consent/components/PatientConsent'

const AddPatientEncounter = ({ patient }) => {
  return (
    <div>
      <h3>
        <Trans>Consent</Trans>
      </h3>
      <PatientConsent patientID={patient.id} />
    </div>
  )
}

export default AddPatientEncounter
