import React, { useContext, useEffect, useRef, useCallback } from 'react'
import sha256 from 'sha256'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components/macro'
import { Trans } from '@lingui/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { useLocation, useNavigate } from '@reach/router'
import {
  PIN_CODE_DELETED,
  PIN_CODE_NOT_FOUND,
  PIN_CODE_NOT_VALIDATED,
  PIN_CODE_VALIDATED,
  PRACTITIONER_UNSELECTED
} from '../actions'
import Heading from '../../Shared/components/Heading'
import Subheading from '../../Shared/components/Subheading'
import { displayPractitionerImg } from '../../Shared/display/practitioner'
import { displayPractitionerActiveRole } from '../../Shared/display/practitionerRole'
import PractitionerHead from './PractitionerHead'
import { getPractitionerRolesBypractitionerIDFromState } from '../../PractitionerRole/selector'
import { Button } from '../../Components'
import { identifyUser } from '../../Shared/mixpanel'
import { getCurrentPractitionerRoleGouvCode } from '../../Permissions/permissions'
import { GENERAL_PRACTITIONER_ESANTE } from '../../Permissions/roles'
import { CurrentPractitionerContext } from '../usePractitioner'
import { CurrentPractitionerRoleContext } from '../../PractitionerRole/containers/CurrentPractitionerRoleProvider'
import Grid from '../../Shared/components/GridLayout'
import useCurrentOrganizationId from '../../Organization/containers/CurrentOrganizationIdProvider'
import { getPractitionerById } from '../selector'
/* this is a secret key that we use to hash (~= crypt) the PIN code before sending it to the database */
export const PIN_CODE_SALT = process.env.REACT_APP_PIN_CODE_SALT
// used for definition of "PIN Code" in practitioner resource
export const MEDEO_CODE_PIN_SYSTEM = 'http://medeo.io/fhir/ValueSet/pin-code'

const Form = styled.form`
  display: flex;
  align-items: center;
  flex-direction: column;
`

const Container = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: column;
  margin-top: 20vh;
`

const greyWidth = '1.876rem'
const transparentWidth = '2.814rem'
const paddingLeftForBackground = '1.782rem'
const backgroundWidth = '10.318rem'
const backgroundHeight = '2px'
const backgroundProperty = `repeating-linear-gradient(
  90deg,
  dimgrey 0,
  dimgrey ${greyWidth},
  transparent 0,
  transparent ${transparentWidth}
) ${paddingLeftForBackground} 100% / ${backgroundWidth} ${backgroundHeight} no-repeat`

const PinCodeInput = styled.input.attrs({
  type: 'text',
  // inputMode numeric open the correct keyboard on mobile and tablet
  inputMode: 'numeric',
  maxLength: 4,
  autoComplete: 'off'
})`
  border: none;
  // the following line makes sure to display the disc instead of the actual
  // characters the user will type
  // It works only for chrome, brave and webkit based browser !
  -webkit-text-security: disc;
  color: ${p => p.theme.ocean};
  font-size: 300%;
  width: 13.7rem;
  padding-left: 1.876rem;
  padding-right: 0;
  margin: 0;
  letter-spacing: 1.126rem;
  background: ${backgroundProperty};
`

const FontAwesomeIconWithMargin = styled(FontAwesomeIcon)`
  margin-right: 0.5rem;
`
const Flex = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const Message = styled.div`
  padding-top: 2em;
  color: ${p => p.theme.scarlett};
`

const CustomButton = styled(Button)`
  align-self: flex-start;
  margin-top: 1em;
  margin-left: 1rem;
  border: none;
  &:hover {
    text-decoration: underline;
  }
`

const TypePinCodeForm = ({ practitionerID, from }) => {
  const wrongPinMessage = false
  const comesFromFinishedTeleconsultation = false
  const practitioner = useSelector(getPractitionerById(practitionerID))
  const organizationId = useCurrentOrganizationId()
  const selector = useCallback(
    s => getPractitionerRolesBypractitionerIDFromState(s, 'medical'),
    []
  )
  const practitionerRolesById = useSelector(selector)
  const [, setCurrentPractitioner] = useContext(CurrentPractitionerContext)
  const [, setCurrentPractitionerRole] = useContext(
    CurrentPractitionerRoleContext
  )

  const reduxDispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const UrlIncludeTeleconsultation = location.href.includes('teleconsultation')
  let newRole

  const logPractitioner = () => {
    // log in as practitioner
    setCurrentPractitioner(practitioner)
    
    newRole = getCurrentPractitionerRoleGouvCode(
      practitionerRolesById[practitioner?.id]
    )
    setCurrentPractitionerRole(newRole)

    // identifyUser is for metrics and mixpanel
    identifyUser(
      practitioner,
      organizationId,
      practitionerRolesById[practitioner?.id]
    )
  }

  const handleOnChange = e => {
    const pinCode = e.currentTarget.value
    // here we remove the error message if needed
    if (wrongPinMessage && pinCode.length !== 4) {
      return reduxDispatch({ type: PIN_CODE_DELETED, payload: {} })
    }
    // Not enough chars.
    if (pinCode.length !== 4) {
      return
    }
    // Get remote hash.
    const remoteHash = practitioner?.identifier?.find?.(
      i => i.system === MEDEO_CODE_PIN_SYSTEM
    )?.value
    // No remote hash stored.
    if (remoteHash == null) {
      return reduxDispatch({ type: PIN_CODE_NOT_FOUND, payload: {} })
    }
    // We have both a remote and a local hash, we can compare.
    const localHash = sha256(`${pinCode}${PIN_CODE_SALT}`)
    // Pins don't match, ask again.
    if (remoteHash !== localHash) {
      reduxDispatch({ type: PIN_CODE_NOT_VALIDATED, payload: {} })
      return document.getElementById('pinCode').select()
    }
    // We have a match at this point, let's proceed.
    logPractitioner()
    reduxDispatch({
      type: PIN_CODE_VALIDATED,
      payload: {}
    })
    // We check if the new user has the right to be on teleconsultation page otherwise we redirect him to the homepage
    // @todo, there might be other constraints to pay attention to ?
    if (UrlIncludeTeleconsultation && newRole !== GENERAL_PRACTITIONER_ESANTE) {
      return navigate('/')
    }
    let redirect = from
    if (from === undefined || from === null || from.length < 1) {
      redirect = '/'
    }
    return navigate(redirect)
  }

  const handleOnClick = () => {
    reduxDispatch({ type: PRACTITIONER_UNSELECTED, payload: {} })
    navigate('/')
  }

  // setFocus
  const PinCodeRef = useRef(null)
  useEffect(() => {
    if (PinCodeRef && PinCodeRef.current) {PinCodeRef.current.focus()}
  })

  const isWelcomePage = !comesFromFinishedTeleconsultation

  return (
    <>
      <Grid.Header variant="full">
        {isWelcomePage && (
          <Flex>
            <Heading>
              <Trans>Welcome</Trans>
            </Heading>
            <Subheading>
              <Trans>Type your PIN code</Trans>
            </Subheading>
          </Flex>
        )}
      </Grid.Header>
      <Grid.Header variant="aside">
        {/* Patient should not see the return button if coming from remote consultation */}
        {isWelcomePage && (
          <CustomButton color="ocean" variant="text" onClick={handleOnClick}>
            <FontAwesomeIconWithMargin icon={faChevronLeft} />
            <Trans>Back</Trans>
          </CustomButton>
        )}
      </Grid.Header>
      <Grid.Main variant="full">
        <Container>
          <Form onSubmit={e => e.preventDefault()}>
            <PractitionerHead
              key={practitioner?.id}
              img={displayPractitionerImg(practitioner)}
              role={displayPractitionerActiveRole(
                practitionerRolesById[practitioner?.id],
                <Trans>Practitioner</Trans>
              )}
              practitioner={practitioner}
              isRow={comesFromFinishedTeleconsultation}
            />
            {comesFromFinishedTeleconsultation && (
              <>
                <Heading>
                  <Trans>Teleconsultation finished</Trans>
                </Heading>
                <Subheading>
                  <Trans>Type your PIN code to go back to patient file</Trans>
                </Subheading>
              </>
            )}
            <PinCodeInput
              name="pinCode"
              id="pinCode"
              ref={PinCodeRef}
              onChange={handleOnChange}
            />
            {wrongPinMessage && (
              <Message>
                <Trans>
                  Wrong PIN code. Please contact your administrator.
                </Trans>
              </Message>
            )}
          </Form>
        </Container>
      </Grid.Main>
    </>
  )
}

export default React.memo(TypePinCodeForm)
