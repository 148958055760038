import React from 'react'
import { Card } from '../../Components'
import { Trans } from '@lingui/macro'
import ChangePassword from './ChangePassword'
import CreateOrChangePinCode from '../container/CreateOrChangePinCode'
import usePractitioner from '../../Practitioner/usePractitioner'

const Security = () => {
  const currentPractitioner = usePractitioner()
  return (
    <Card>
      <h1>
        <Trans>Security</Trans>
      </h1>
      <ChangePassword />
      <CreateOrChangePinCode
        practitionerID={currentPractitioner.id}
        comesFromRequirePractitioner={false}
      />
    </Card>
  )
}

export default Security
