import React, { useContext } from 'react'
import { useDispatch } from 'react-redux'
import { signOut } from '../actions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import styled from 'styled-components/macro'
import { transparentize } from 'polished'
import { deletePatient } from '../../Patient/actions'
import { CurrentPractitionerContext } from '../../Practitioner/usePractitioner'
import { CurrentSearchBarValueContext } from '../../Shared/components/CurrentSearchBarValueProvider'

const Circle = styled.div.attrs(() => ({
  role: 'button'
}))`
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background: ${p => transparentize(0.25, p.theme.alabaster)};
    & svg {
      color: ${p => p.theme.ocean};
    }
  }
`

const SignOutButton = (props = { color: 'ocean' }) => {
  const dispatch = useDispatch()
  const [, setPractitioner] = useContext(CurrentPractitionerContext)
  const [, setCurrentSearchBarValue] = useContext(CurrentSearchBarValueContext)
  return (
    <Circle
      {...props}
      onClick={() => {
        dispatch(signOut())
        setPractitioner(null)
        // if there was an active search we need to deleted when we signOut
        dispatch(deletePatient())
        setCurrentSearchBarValue('')
      }}
    >
      <FontAwesomeIcon icon={faSignOutAlt} />
    </Circle>
  )
}

export default SignOutButton
