// Fetching Practitioner List

// Actions
export const PRACTITIONER_RECEIVED = 'PRACTITIONER_RECEIVED'
export const PRACTITIONERS_RECEIVED = 'PRACTITIONERS_RECEIVED'
export const PRACTITIONER_REMOVED = 'PRACTITIONER_REMOVED'
export const API_ERRORED = 'API_ERRORED'
export const SEARCH_GENERAL_PRACTITIONER = 'SEARCH_GENERAL_PRACTITIONER'

// Actions for practitioner auth
export const PRACTITIONER_SELECTED = 'PRACTITIONER_SELECTED'
export const PIN_CODE_VALIDATED = 'PIN_CODE_VALIDATED'
export const PIN_CODE_NOT_VALIDATED = 'PIN_CODE_NOT_VALIDATED'
export const PRACTITIONER_UNSELECTED = 'PRACTITIONER_UNSELECTED'
export const PIN_CODE_NOT_FOUND = 'PIN_CODE_NOT_FOUND'
export const ASKING_FOR_PIN_CODE = 'ASKING_FOR_PIN_CODE'
export const PIN_CODE_DELETED = 'PIN_CODE_DELETED'

export const removePractitioner = practitioner => ({
  type: PRACTITIONER_REMOVED,
  payload: practitioner,
})
