import { call, takeEvery, select } from '@redux-saga/core/effects'
import { REMOVING_SCHEDULE } from './actions'
import { saveFhirResourceWorker } from '../Shared/sagas'
import { getScheduleById } from './selectors'

// schedules should not be deleted but updated into active false
function* setScheduleAsInactive(action) {
  const id = action.payload
  const schedule = yield select(getScheduleById(id))
  schedule.active = false
  yield call(saveFhirResourceWorker, schedule)
}

function* rootSaga() {
  yield takeEvery(REMOVING_SCHEDULE, setScheduleAsInactive)
}

export default rootSaga
