import React, { useContext } from 'react'
import { Auth } from 'aws-amplify'
import { Input, Button } from '../../Components'
import styled from 'styled-components/macro'
import { Trans } from '@lingui/macro'
import SignUpContext from './SignUpContext'

const Label = styled.label`
  margin: 1rem 0 0.5rem;
`
const Form = styled.form`
  display: flex;
  flex-direction: column;
  & > ${Button} {
    margin-top: 1rem;
  }
`
const CustomConfirmSignUpForm = props => {
  const [value] = useContext(SignUpContext)
  return props.authState === 'confirmSignUp' ? (
    <Form
      onSubmit={e => {
        e.preventDefault()
        const { elements } = e.currentTarget
        Auth.confirmSignUp(
          elements.namedItem('email').value,
          elements.namedItem('code').value
        )
          .then(() => props.onStateChange('signedUp'))
          .catch(err => console.error(err))
      }}
    >
      <Label htmlFor="email">
        <Trans>email address</Trans>
      </Label>
      <Input
        type="email"
        name="email"
        id="email"
        readOnly
        value={value.email}
      />
      <Label htmlFor="code">code</Label>
      <Input type="text" name="code" id="code" required />
      <Button>
        <Trans>Confirm</Trans>
      </Button>
    </Form>
  ) : null
}

export default CustomConfirmSignUpForm
