import React, { useReducer } from 'react'
import styled from 'styled-components/macro'
import { Button } from '../../Components'
import { Trans } from '@lingui/macro'
import { reduceSlotsOverPeriod } from '../../Slot/utils'
import BetterColumn from '../../Slot/components/BetterColumn'
import { useUpdateEffect } from 'react-use'

const Container = styled.div`
  width: 100%;
  min-height: 18rem;
  display: block;
`
const Flex = styled.div`
  display: flex;
  width: 100%;
  // prevent flickering
  min-height: 16.625rem;

  & > * {
    // allow columns to grow but not to shrink
    // their min-width is set to 5rem
    flex: 1 0 5rem;
  }
  & > :not(:last-child) {
    margin-right: 0.5rem;
  }
`
const CustomButton = styled(Button)`
  margin: 1rem auto 0.5rem;
  display: block;
`

/**
 * @typedef {object} SlotsViewState
 * @property {number} daysFromNow - elapsed days from now for the first displayed day in the view
 */
const init = ({ slots, period, limit = 4, slot = null }) => {
  const byDay = reduceSlotsOverPeriod(period, slots)
  const shouldDisplayMore =
    limit != null && Object.values(byDay).some(s => s.length > limit)
  return {
    limit: Object.values(byDay).some(slots =>
      slots.find((s, i) => s.id === slot?.id && i >= limit)
    )
      ? null
      : limit,
    shouldDisplayMore: shouldDisplayMore,
    slots: slots,
    period: period,
    // byDay acts as a cursor over the slots
    // it contains only the slots from the given period
    byDay: byDay
  }
}

/**
 *
 * @param {SlotsViewState} state
 * @param action
 * @return {SlotsViewState} nextState
 */
const reducer = (state, action) => {
  switch (action.type) {
    case 'show':
      return { ...state, shouldDisplayMore: true, limit: null }
    case 'change':
      // when changing the view, we don't update the slots
      // we compute the byDay state to show the correct cursor
      return init({ slots: state.slots, period: action.payload })
    case 'reset':
      // when resetting we keep the period the same
      // user should stay on the same view when slots are updated
      return init({
        slots: action.payload.slots,
        slot: action.payload.slot,
        period: state.period,
        limit: state.limit
      })
    default:
      return state
  }
}

/**
 * This component always shows three days, with for each its corresponding
 * available schedule.
 * It is possible to navigate from today to the three days tuple containing the last
 * set schedule of the perfomer
 * @param {string} performerId
 */
const BetterSlotsView = ({ slots, period, children, slot }) => {
  const [state, dispatch] = useReducer(reducer, { slots, period, slot }, init)
  // current selected/created slot from FHIR is now in the store
  useUpdateEffect(() => {
    dispatch({ type: 'reset', payload: { slots, slot } })
  }, [slots])

  useUpdateEffect(() => {
    dispatch({
      type: 'change',
      payload: period
    })
  }, [period])

  const handleShowMore = () => {
    dispatch({ type: 'show' })
  }

  return (
    <Container>
      <Flex>
        {Object.entries(state.byDay).map(([day, slots], i) => (
          <BetterColumn day={day} limit={state.limit} key={i} slots={slots}>
            {children}
          </BetterColumn>
        ))}
      </Flex>
      {state.shouldDisplayMore && (
        <CustomButton
          type="button"
          color="ocean"
          onClick={handleShowMore}
          variant="text"
        >
          <Trans>More slots</Trans>
        </CustomButton>
      )}
    </Container>
  )
}

export default React.memo(BetterSlotsView)
