export const SETTING_SHOULD_ALERT_ON_LEAVE = 'SETTING_SHOULD_ALERT_ON_LEAVE'
export const REMOVING_SHOULD_ALERT_ON_LEAVE = 'REMOVING_SHOULD_ALERT_ON_LEAVE'

export const setShouldAlertOnLeave = message => ({
  type: SETTING_SHOULD_ALERT_ON_LEAVE,
  payload: {
    message,
  },
})

export const removeShouldAlertOnLeave = () => ({
  type: REMOVING_SHOULD_ALERT_ON_LEAVE,
})
