import {
  failReceivingFhirResource,
  groupResources,
  receiveFhirResources,
} from '../actions'
import { useDispatch } from 'react-redux'
import { useCallback, useEffect, useReducer, useRef } from 'react'
import { createFhirClient } from '../sagas'
import { isEqual } from 'lodash'

const lazySearchInitialState = {
  error: undefined,
  loading: false,
  data: undefined,
  called: false,
}

const lazySearchReducer = (state, action) => {
  switch (action.type) {
    case 'init':
      return lazySearchInitialState
    case 'fetching':
      return {
        ...state,
        error: undefined,
        loading: true,
        called: true,
      }
    case 'fetched':
      return {
        ...state,
        data: groupResources(action.payload.data.entry),
        error: undefined,
        loading: false,
        called: true,
      }
    case 'failed':
      return {
        ...state,
        data: undefined,
        error: action.payload,
        loading: false,
        called: true,
      }
    default:
      return state
  }
}

/**
 * This hook enables to have the search action already prepared,
 * but to call it when wanted. Therefore the query is returned by the hook
 * The parameters can be sent either on function declaration or on function call
 *
 * @param {*} type
 * @param {*} query
 */
const useLazySearch = (type, query) => {
  const reduxDispatch = useDispatch()
  const [state, dispatch] = useReducer(
    lazySearchReducer,
    lazySearchInitialState
  )

  const queryRef = useRef(query)

  const run = useCallback(
    async (newType = null, newQuery = null) => {
      try {
        dispatch({ type: 'fetching' })
        const fhirClient = await createFhirClient()
        const response = await fhirClient.search({
          // Here we check for the potentially new arguments passed to the function,
          // as it might be that their were set when the function was declared
          // or that they are known only once the function is called
          type: newType ?? type,
          query: newQuery ?? queryRef.current,
        })
        reduxDispatch(receiveFhirResources(response.data))
        dispatch({ type: 'fetched', payload: response })
        return groupResources(response.data.entry)
      } catch (e) {
        reduxDispatch(failReceivingFhirResource(type, e))
        dispatch({ type: 'failed', payload: e })
      }
    },
    [type, queryRef, reduxDispatch]
  )

  useEffect(() => {
    if (!isEqual(query, queryRef.current)) {queryRef.current = query}
  }, [query, type])

  return [run, state]
}

export default useLazySearch
