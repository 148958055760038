import moment from 'moment'
import React, { useContext, useEffect, useRef } from 'react'
import styled from 'styled-components/macro'
import QuestionnaireChoice from './QuestionnaireChoice'
import QuestionnaireInput from './QuestionnaireInput'
import {
  getItemByLinkId,
  onChangeQuestionnaire,
  isQuestionnaireOfTypeAndValue,
  getTotalAmountForCaresheet,
  getAmountForAct,
  getRestAmountForCaresheet
} from '../utils'
import QuestionnaireTextarea from './QuestionnaireTextarea'
import QuestionnaireQuantity from './QuestionnaireQuantity'
import { QuestionnaireResponseContext } from './Questionnaire'
import QuestionnaireSection from './QuestionnaireSection'
import QuestionnaireDate from './QuestionnaireDate'
import QuestionnaireDuplicableInput from './QuestionnaireDuplicableInput'
import QuestionnaireItemAttachment from './QuestionnaireItemAttachment'
import QuestionnaireDisplay from './QuestionnaireDisplay'
import {
  MEDEO_TELECONSULTATION_IDENTIFIER,
  MEDEO_CARE_SHEET_QUESTIONNAIRE_IDENTIFIER
} from '../../utils/codes'
import QuestionnaireTag from './QuestionnaireTag'
import usePractitioner from '../../Practitioner/usePractitioner'
export const Display = styled.div`
  font-weight: bold;
  font-size: ${p => p.theme.medium};
  padding-top: 0.5rem;
  margin-bottom: 1rem;
`

const Container = styled.div`
  margin-bottom: 1rem;
`

// this i for handling enableWhen condition
export const useCondition = (item, questionnaireResponse) => {
  if (item.enableWhen == null) {return true}
  if (questionnaireResponse == null) {return false}
  // if the item contain enableWhen
  return item.enableWhen.some(condition => {
    //if nothing is in the questionnaireResponse
    if (questionnaireResponse.item && questionnaireResponse.item.length === 0)
      {return false}
    // here we want to get the question that is relative to the enableWhen condition
    const question = getItemByLinkId(questionnaireResponse, condition.question)
    // if the question is in the questionnaireResponse we have two type of condition
    if (question) {
      // if we only want a response
      if (condition.hasAnswer === true) {return question.answer != null}

      //when we uncheck a checkboxe we return false
      if (question.answer && question.answer[0] == null) {return false}

      //when we use checkbox input we check all responses
      if (question.answer && question.answer.length > 1) {
        // Here we need to check every possible type, as some old response will not be displayed correctly otherwise
        // Indeed, some former answers saved with a valueString for a boolean or a coding would never
        // fall into the valueBoolean or valueCoding condition
        return (
          question.answer.find(answer => {
            const response =
              answer.valueString ??
              answer.valueCoding?.code ??
              answer.valueBoolean

            return (
              response === condition.answerString ||
              response === condition.answerBoolean ||
              response === condition.answerCoding?.code
            )
          }) != null
        )
      }

      let response
      if (question.answer)
        {response =
          question.answer[0]?.valueString ??
          question.answer[0]?.valueCoding?.code ??
          question.answer[0]?.valueBoolean}

      // If the response matches the condition
      // Here again we need to check every possible type, as some old response will not be displayed correctly otherwise
      // Indeed, some former answers saved with a valueString for a boolean or a coding would never
      // fall into the valueBoolean or valueCoding condition
      return (
        response === condition.answerString ||
        response === condition.answerBoolean ||
        response === condition.answerCoding?.code
      )
    }
    return false
  })
}

const QuestionnaireComponentSwitch = ({ item, ...rest }) => {
  const [questionnaireResponse] = useContext(QuestionnaireResponseContext)
  const visible = useCondition(item, questionnaireResponse)

  if (visible === false) {return null}
  switch (item.type) {
    case 'boolean':
    case 'choice':
      return <QuestionnaireChoice item={item} {...rest} />
    case 'text':
      return <QuestionnaireTextarea item={item} {...rest} />
    case 'decimal':
    case 'string':
    case 'integer':
      return <QuestionnaireInput item={item} {...rest} />
    case 'date':
    case 'dateTime':
      return <QuestionnaireDate item={item} {...rest} />
    case 'group':
      return item.text != null && <Display>{item.text}</Display>
    case 'attachment':
      return <QuestionnaireItemAttachment item={item} {...rest} />
    case 'quantity':
      return <QuestionnaireQuantity item={item} {...rest} />
    case 'display':
      return <QuestionnaireDisplay item={item} />
    case 'open-choice':
      return <QuestionnaireTag item={item} {...rest} />
    default:
      return ({ resource }) => <span>{resource.type}</span>
  }
}

/**
 * QuestionnaireComponent
 * The onChange function is a callback from the parent which is meant to be triggered whenever
 * the questionnaireResponse would be changed in the present component
 */
const QuestionnaireComponent = ({ item, onChange, ...rest }) => {
  const [questionnaireResponse, dispatchQuestionnaireResponse] = useContext(
    QuestionnaireResponseContext
  )
  // Keep track of the initial questionnaire response on component mount
  // This is used to prevent calling the onChange function when there was actually no change
  // We use a useRef rather than a useState as we don't need any re-render
  // But just a memorization of value
  const initialQuestionnaireResponse = useRef(questionnaireResponse)

  // if the questionnaire is not is the rest, it's undefined and I don't konw why
  const questionnaire = rest.questionnaire
  const isQuestionnaireCareSheet = isQuestionnaireOfTypeAndValue(
    questionnaire,
    MEDEO_TELECONSULTATION_IDENTIFIER,
    MEDEO_CARE_SHEET_QUESTIONNAIRE_IDENTIFIER
  )
  // We probably should not have to call this here,
  // but it seems that the practitioner is not always available in the context
  // when trying to pass it as a prop.
  const practitioner = usePractitioner()
  let value = undefined
  let defaultValue
  const isItemDuplicable = item.repeats === true
  // code bellow is for input date with the date of the day in defaultValue
  if (item.definition && item.definition === 'dateOfTheDay') {
    const date = moment().format('YYYY-MM-DD')
    defaultValue = date
  }
  // code bellow is for calculated fields
  if (
    item.definition &&
    item.definition === 'calculatedValue' &&
    rest.readOnly === false
  ) {
    value = 0
    if (isQuestionnaireCareSheet && item) {
      // set date
      if (item.linkId.match(/1\.3\.2\.([0-9]).2/g)) {
        value = moment().format('YYYY-MM-DD')
      }
      // set amount for an act
      if (item.linkId.match(/1\.3\.2\.([0-9]).3/g)) {
        value = getAmountForAct(item, questionnaireResponse, practitioner)
      }
      // set total to pay
      if (item.linkId === '1.3.3') {
        value = getTotalAmountForCaresheet(questionnaireResponse)
      }
      // set rest to pay
      if (item.linkId === '1.3.7') {
        value = getRestAmountForCaresheet(questionnaire, questionnaireResponse)
      }
    }

    // save the value in the questionnaireResponse for calculated fields
    const responseItem = getItemByLinkId(questionnaireResponse, item.linkId)
    let previousValue = null
    if (responseItem !== undefined) {
      switch (item.type) {
        case 'quantity':
          previousValue = responseItem.answer[0].valueQuantity.value
          break
        case 'date':
          previousValue = responseItem.answer[0].valueDate
          break
        default:
          previousValue = null
      }
    }
    if (responseItem === undefined || previousValue !== value) {
      let valueToAdd = value
      if (item.type === 'quantity') {
        valueToAdd = { value: value, unit: item.initialQuantity.unit }
      }

      onChangeQuestionnaire({
        questionnaireResponse: questionnaireResponse,
        dispatchQuestionnaireResponse: dispatchQuestionnaireResponse,
        name: item.linkId,
        value: valueToAdd,
        type: item.type
      })
    }
  }

  // This use effect keep tracks of the change on the questionnaireResponse
  // And trigger action when this one has changed
  useEffect(() => {
    // If the questionnaireResponse changed since the component mount
    if (
      questionnaire &&
      questionnaireResponse !== initialQuestionnaireResponse.current &&
      !rest.readOnly
    )
      {onChange(questionnaireResponse, questionnaire.id)}
  }, [questionnaireResponse, onChange, questionnaire, rest.readOnly])

  return (
    <>
      {item &&
        item.visible !== false &&
        (isItemDuplicable ? (
          <QuestionnaireDuplicableInput
            key={item.linkId}
            item={item}
            {...rest}
            value={value}
          />
        ) : (
          <>
            <Container>
              <QuestionnaireComponentSwitch
                key={item.linkId}
                item={item}
                defaultValue={defaultValue}
                {...rest}
                value={value}
              />
            </Container>
            {item.type === 'group' && (
              <QuestionnaireSection
                key={`section_.${[item.linkId]}`}
                item={item}
                {...rest}
              />
            )}
          </>
        ))}
    </>
  )
}

QuestionnaireComponent.defaultProps = {
  // We add a default onChange property in case the questionnaire is not the child of
  // a parent depending on its change, as it is the case a questionnaire within the planDefinition for instance
  onChange: () => {}
}

export default QuestionnaireComponent
