/***
 * Utility functions to retrieve manipulates addresses from FHIR entities.
 */

import { Address } from 'fhir-stu3'
/**
 * Extract the postcode from the first found address.
 * @param addresses Address[]
 * @returns string
 */
export const getPostCodeFromAddresses = (
  addresses: Address[] | undefined | null
): string => {
  if (addresses === undefined || addresses === null) {
    return ''
  }
  if (addresses.length === 0) {
    return ''
  }
  const address = addresses.at(0)
  return getPostCodeFromAddress(address)
}

/**
 * Extract the postcode from an address.
 * @param address Address
 * @returns string
 */
export const getPostCodeFromAddress = (
  address: Address | undefined | null
): string => {
  if (address === undefined || address === null) {
    return ''
  }
  return address.postalCode ? address.postalCode : ''
}
