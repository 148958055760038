import { getAllEntities } from '../Shared/selectors'
import { sortByDateTime } from '../utils/dateUtils'
import { getIdByReference } from '../Shared/utils'

export const getAllConsents = state => getAllEntities(state, 'Consent')

export const getConsentsByPatientSortedByDate = (state, id) => {
  const filtered = getAllConsents(state).filter(c => {
    const patientID = getIdByReference(c.patient.reference)
    return patientID === id
  })
  return filtered.sort((a, b) => sortByDateTime(a, b))
}
