import { useEffect, useReducer } from 'react'
import { Button, TextArea } from '../../Components'
import styled from 'styled-components/macro'
import { Trans } from '@lingui/macro'

const InlineButton = styled(Button).attrs({
  variant: 'text',
  type: 'button'
})`
  text-transform: none;
  font-size: ${p => p.theme.small};
  padding: 0;
  background: transparent;
  font-weight: ${p => p.theme.medium};
  margin-top: 0.5rem;
  &:hover,
  &:active,
  &:focus {
    color: ${p => p.theme.ocean};
    border-color: transparent;
    background: transparent;
  }
`
const Flex = styled.div`
  display: flex;
  flex-direction: column;
`
const Controls = styled.div``
const reducer = (state, action) => {
  const { type, payload } = action
  switch (type) {
    case 'change':
      return {
        open: true,
        touched: true,
        text: payload
      }
    case 'open':
      return {
        ...state,
        open: true
      }
    case 'submit':
      return {
        ...state,
        open: false
      }
    case 'delete':
      return {
        open: false,
        touched: false,
        text: ''
      }
    case 'reset':
      return init(payload)
    default:
      return state
  }
}

const init = value => ({
  open: false,
  touched: value !== '',
  text: value
})
const CommentInput = ({ onChange, value }) => {
  const [state, dispatch] = useReducer(reducer, value, init)

  useEffect(() => {
    dispatch({
      type: 'reset',
      payload: value
    })
  }, [value])

  const handleClick = () => {
    dispatch({ type: 'open' })
  }
  const handleChange = e => {
    const { value } = e.target
    dispatch({
      type: 'change',
      payload: value
    })
  }

  const handleSubmit = () => {
    dispatch({ type: 'submit' })
    onChange(state.text)
  }
  const handleDelete = () => {
    dispatch({ type: 'delete' })
    onChange('')
  }
  return (
    <Flex>
      {(state.text !== '' || state.open) && (
        <TextArea
          onChange={handleChange}
          value={state.text}
          disabled={!state.open}
          data-test={'medication-textarea'}
        />
      )}
      <Controls>
        {!state.open ? (
          <InlineButton
            onClick={handleClick}
            data-test={'medication-comment-button'}
          >
            {!state.touched ? (
              <Trans>Add a comment</Trans>
            ) : (
              <Trans>Edit</Trans>
            )}
          </InlineButton>
        ) : (
          <>
            <InlineButton
              onClick={handleDelete}
              data-test={'medication-delete-comment-button'}
            >
              <Trans>delete</Trans>
            </InlineButton>
            &nbsp;&middot;&nbsp;
            <InlineButton
              onClick={handleSubmit}
              data-test={'medication-save-comment-button'}
            >
              <Trans>save</Trans>
            </InlineButton>
          </>
        )}
      </Controls>
    </Flex>
  )
}
CommentInput.defaultProps = {
  onChange: () => {},
  value: ''
}
export default CommentInput
