import React, { useEffect, useState, useContext } from 'react'
import styled from 'styled-components/macro'
import { Form, Label } from '../../../Components'
import YamlInput from '../../../Yaml/YamlInput'
import YamlSelect from '../../../Yaml/YamlSelect'
import YamlRadioInput from '../../../Yaml/YamlRadioInput'
import { ModelContext } from '../../../Yaml/utils'
import YamlTagsInput from '../../../Yaml/YamlTagsInput'
import YamlRadioGroup from '../../../Yaml/YamlRadioGroup'
import { CurrentPractitionerRoleContext } from '../../../PractitionerRole/containers/CurrentPractitionerRoleProvider'
import { useEffectOnce } from 'react-use'
import YamlPractitionerSearch from '../../../Yaml/YamlPractitionerSearch'
import YamlConsentInput from '../../../Yaml/YamlConsentInput'
import YamlMedicationStatementInput from '../../../MedicationStatement/YamlMedicationStatementInput'
import YamlCountrySelect from '../../../Yaml/YamlCountrySelect'

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  & > :not(:last-child) {
    margin-bottom: 0.5rem;
  }
  & > :last-child {
    margin-bottom: 1rem;
  }
`
const Header = styled.header`
  font-weight: bold;
  //  background: red;
  font-size: large;
  border-top: 1px solid ${p => p.theme.gray};
  padding: 2rem 0 1rem 0;
`

const FieldSet = ({ yaml, model, ...rest }) => {
  const children = yaml.children || []
  const Component = yaml.variant === 'column' ? Column : Form.Row
  // index is required for the duplicable inputs.
  //TODO: pass the index with a Context would be better
  return (
    <>
      {yaml.label && <Label>{yaml.label}</Label>}
      <Component>
        {children.map((c, i) => (
          <YAMLComponent
            key={i}
            yaml={{ ...c, index: yaml.index }}
            model={model}
            {...rest}
          />
        ))}
      </Component>
    </>
  )
}

const Section = ({ yaml, model, readOnly, ...rest }) => {
  const children = yaml.children || []
  const [CurrentPractitionerRole] = useContext(CurrentPractitionerRoleContext)
  const [hideSection, setHideSection] = useState(false)

  // we filter obs for pharmacist, if it's a teleconsultation we hide all obs except
  // the prescription, it's working only with YamlForm let see another method for Questionnaire
  useEffectOnce(() => {
    if (
      CurrentPractitionerRole === '46255001' &&
      readOnly &&
      (yaml.label === 'Diagnostic' ||
        yaml.label === 'Facturation' ||
        yaml.label === 'Synthèse')
    ) {
      setHideSection(true)
    }
  })

  return hideSection ? null : (
    <>
      <Header>{yaml.label}</Header>
      <div
        style={{
          flexDirection: 'column',
          display: 'flex'
        }}
      >
        {children.map((c, i) => (
          <YAMLComponent key={i} yaml={c} model={model} {...rest} />
        ))}
      </div>
    </>
  )
}

// so far there are two types of conditions, depending on the kind of resource
// we are currently checking: the first one is for single value:
// expressed in the model like this { $someField: { $someValue: 'aValue' }, /* ... */ }
// when: $someField.$someValue === 'aValue'
// The other one is for fields that are arrays:
// expressed in the model like this { $someField: [{ $someValue: 'aValue'}], /* ... */ }
// when: $someField.some(i => i.$someValue === 'aValue')
//TODO: these conditions checking are very far from being perfect. Sooner or later we are
// going to need to make some changes.
const useCondition = (yaml, model) => {
  const [visible, setVisible] = useState(true)
  useEffect(() => {
    setVisible(true)
    if (yaml.when == null) {return}
    try {
      const elements = yaml.when.split(' ')
      const condition = elements
        .map(e => (e.startsWith('$') ? 'model.' + e : e))
        .join(' ')
      //eslint-disable-next-line
      setVisible(eval(condition))
    } catch {
      setVisible(false)
    }
  }, [yaml.when, model])
  return visible
}
// un contexte pour gerer les conditions
const YAMLComponent = ({ yaml, ...rest }) => {
  //TODO: move useCondition to yaml model
  // visibility depends on the yaml.when property
  const [model] = useContext(ModelContext)
  const visible = useCondition(yaml, model)
  if (visible === false) {return null}
  switch (yaml.type) {
    case 'medicationStatement':
      return <YamlMedicationStatementInput yaml={yaml} {...rest} />
    case 'practitionerSearch':
      return <YamlPractitionerSearch yaml={yaml} {...rest} />
    case 'nir':
      return <YamlInput {...rest} pattern="[0-9]{13}" yaml={yaml} />
    case 'row':
      return <FieldSet yaml={yaml} {...rest} />
    case 'paragraph':
      return (
        <p
          style={{ fontSize: '14px', lineHeight: '20px' }}
          dangerouslySetInnerHTML={{ __html: yaml.value }}
        />
      )
    case 'section':
      return <Section yaml={yaml} {...rest} />
    case 'radio':
      return <YamlRadioInput yaml={yaml} {...rest} />
    case 'countrySelect':
      return <YamlCountrySelect yaml={yaml} {...rest} />
    case 'select':
      return <YamlSelect yaml={yaml} {...rest} />
    case 'text':
    case 'number':
    case 'tel':
    case 'email':
    case 'phone':
    case 'date':
      return <YamlInput {...rest} yaml={yaml} />
    case 'tags':
      return <YamlTagsInput yaml={yaml} {...rest} />
    case 'radioGroup':
      return <YamlRadioGroup {...rest} yaml={yaml} />
    case 'consent':
      return <YamlConsentInput yaml={yaml} {...rest} />
    // Empty is used to prevent another component taking full width in a row
    case 'empty':
      return <div />
    default:
      console.warn(`${yaml.type} is not renderer`)
      return null
  }
}

export default YAMLComponent
