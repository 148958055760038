import {
  MEDEO_AM_IDENTIFIER_SYSTEM,
  MEDEO_FINESS_IDENTIFIER_SYSTEM,
  MEDEO_RPPS_IDENTIFIER_SYSTEM,
  MEDEO_STRIPE_IDENTIFIER_SYSTEM,
  FINESS_HEALTH_IDENTIFIER_SYSTEM,
  THIRD_PARTY_PAYER_IDENTIFIER_SYSTEM,
} from '../utils/codes'

export const pushIdentifierChange = ({ identifiers, name, value }) => {
  switch (name) {
    case 'RPPS':
      return identifiers.push({
        type: {
          coding: [
            {
              code: 'RPPS',
              display: 'N° RPPS',
            },
          ],
        },
        system: MEDEO_RPPS_IDENTIFIER_SYSTEM,
        value: value,
      })
    case 'AM':
      return identifiers.push({
        type: {
          coding: [
            {
              system: MEDEO_AM_IDENTIFIER_SYSTEM,
              code: 'AM',
              display: 'AM',
            },
          ],
        },
        system: MEDEO_AM_IDENTIFIER_SYSTEM,
        value: value,
      })
    case 'FINESS':
      return identifiers.push({
        type: {
          coding: [
            {
              system: FINESS_HEALTH_IDENTIFIER_SYSTEM,
              code: 'FINESS',
              display: 'FINESS',
            },
          ],
        },
        system: MEDEO_FINESS_IDENTIFIER_SYSTEM,
        value: value,
      })
    case 'stripe':
      return identifiers.push({
        type: {
          coding: [
            {
              code: 'stripe',
              display: 'stripe',
            },
          ],
        },
        system: MEDEO_STRIPE_IDENTIFIER_SYSTEM,
        value: value,
      })
    case 'third-party-payer':
      return identifiers.push({
        type: {
          coding: [
            {
              code: 'third-party-payer',
              display: 'Third Party Payer',
            },
          ],
        },
        system: THIRD_PARTY_PAYER_IDENTIFIER_SYSTEM,
        value: value,
      })
    default:
      throw new Error("identifier doen't exist")
  }
}

export const computePractitionerIntoEntries = resource => {
  return [
    {
      request: {
        method: 'PUT',
        url: `Practitioner/${resource.practitioner.id}`,
      },
      resource: resource.practitioner,
    },
    {
      request: {
        method: 'PUT',
        url: `PractitionerRole/${resource.medicalRole.id}`,
      },
      resource: resource.medicalRole,
    },
    {
      request: {
        method: 'PUT',
        url: `PractitionerRole/${resource.administrationRole.id}`,
      },
      resource: resource.administrationRole,
    },
  ]
}
