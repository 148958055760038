import React from 'react'
import { displayMedication, queryMedicationFromES } from './utils'
import { Form, Input } from '../Components'
import MultipleSearchInput from '../Shared/components/MultipleSearchInput'
import { Trans } from '@lingui/macro'
import MedicationDataListItem from '../Medication/components/MedicationDataListItem'
import DefaultMedicationDataListItem from '../Medication/components/DefaultMedicationDataListItem'
import { useDispatch, useSelector } from 'react-redux'
import {
  getMedicationFromSubjectId,
  getMedicationStatementFromSubjectId
} from './selectors'
import { getTypeAndIdFromLocalReference } from '../utils/fhir'
import { updateMedicationStatements } from './actions'
import RadioGroup from '../Shared/components/RadioGroup'
import { useSearch } from '../Shared/hooks'
import { preventSubmitOnKeyPressIfRequired } from '../Shared/utils'

const initialState = {
  shouldDisplayInput: false, // by default it is false
  //state for the multipleSearchInput
  query: '',
  datalist: [],
  added: [],
  deleted: []
}

const QuestionnaireMedicationStatementForm = ({
  patient,
  onSubmit,
  ...rest
}) => {
  const [state, setState] = React.useState(initialState)
  const dispatch = useDispatch()
  const patientID = patient != null ? patient.id : null
  useSearch('MedicationStatement', {
    patient: patientID,
    _include: 'MedicationStatement:medication'
  })
  // medications are required in order to display this Component correctly
  // the medication are shown but not the statement.
  // i.e. we show Doliprane, Advil and not "Doliprane has been prescribed to this patient"
  const medications = useSelector(s => getMedicationFromSubjectId(s, patientID))

  // when the users interacts with this component, we need to update the statements
  // either we add or we delete statements from the database
  // The statements are used in the onValueChange handler which is called
  // everytime something is happening with the multipleSearchInput.
  const statements = useSelector(s =>
    getMedicationStatementFromSubjectId(s, patientID)
  )
  // If the patient already has some statements, there is no need to ask
  // the preliminary question, we can display the medication list directly
  // this effect makes sure to update the state accordingly
  React.useEffect(() => {
    if (statements.length > 0) {
      setState(s => ({ ...s, shouldDisplayInput: true }))
    }
  }, [statements.length])

  // Custom effect to fetch the hits frm AWS ES
  // everytime the user types in a character
  React.useEffect(() => {
    if (state.query.length < 3) {return}
    queryMedicationFromES(state.query).then(datalist => {
      setState(s => ({ ...s, datalist }))
    })
  }, [state.query])

  const handleInputChange = e => {
    setState(s => ({ ...s, query: e.target.value }))
  }

  /**
   * get the current Medications as selectedOptions and update the
   * related MedicationStatement. It creates two list:
   * - deleted MedicationStatement, elements which are going to be updated to
   *   {...statement, active: false }
   * - added Medication, which are new elements that we are going to
   *   add to the list
   *
   * @param e
   */
  const handleValueChange = e => {
    // MultipleSearchInput return a stub event as a param
    // looking like this:
    // e = { target : { selectedOptions : Array(12) } }
    // the selected options contain the selected items from the datalist
    const { selectedOptions } = e.target

    // first, we subtract the selected options to the medication list.
    // the medication list is used as defaultValue and contains the initial set of
    // medication
    // Then we map the medication to the associated statement
    // Reminder: a MedicationStatement looks like this:
    // {
    //   resourceType: "MedicationStatement",
    //   medicationReference: {
    //    reference: "Medication/1234",
    //   }
    //   /* clipped for brevity */
    // }
    const deletedStatements = medications
      .filter(option => !selectedOptions.includes(option))
      .map(medication =>
        statements.find(statement => {
          const reference = statement?.medicationReference?.reference
          const [, id] = getTypeAndIdFromLocalReference(reference)
          return id === medication.id
        })
      )
      .filter(statement => statement != null)

    // Secondly, we create the added list
    // i.e. the medications present in the selected options but not in the
    // initial set.
    // here we don't convert them to statements as we need to perform checks on
    // the database before.
    // these checks will occur through a saga that is triggered when this form
    // is submitted. See the medicationStatement sagas for more details
    const addedMedications = selectedOptions.filter(
      option => !medications.includes(option)
    )
    setState(s => ({
      ...s,
      deletedStatements,
      addedMedications
    }))
  }

  const handleSubmit = e => {
    e.preventDefault()
    dispatch(
      updateMedicationStatements({
        patient,
        addedMedications: state.addedMedications,
        deletedStatements: state.deletedStatements
      })
    )
    onSubmit(e)
  }

  const handleClick = e => {
    const { value } = e.currentTarget
    // handleClick is called tree time on the input, I guess the props are
    // passed to each child of the Input component inside @medeo/component
    // until this is sorted out, the following early return is required
    if (value == null) {return}
    // we are not checking for boolean directly but for string
    // this is due to <input type="radio" /> value attribute which is a string
    setState(s => ({ ...s, shouldDisplayInput: value === 'true' }))
  }
  return (
    <Form
      onKeyPress={preventSubmitOnKeyPressIfRequired}
      onSubmit={handleSubmit}
      {...rest}
    >
      {/*<pre>{JSON.stringify(state, null, 2)}</pre>*/}
      <Form.Row>
        <RadioGroup
          label={<Trans>Does the patient have undergoing treatment ?</Trans>}
          required
        >
          <Input
            type="radio"
            name="treatment"
            onClick={handleClick}
            checked={state.shouldDisplayInput === true}
            value="true"
            label={<Trans>yes</Trans>}
            required
          />
          <Input
            type="radio"
            name="treatment"
            checked={state.shouldDisplayInput === false}
            value="false"
            onClick={handleClick}
            label={<Trans>no</Trans>}
            required
          />
        </RadioGroup>
      </Form.Row>
      {state.shouldDisplayInput === true && (
        <Form.Row>
          <MultipleSearchInput
            label={<Trans>Medication List</Trans>}
            defaultDatalist={state.datalist}
            onInputChange={handleInputChange}
            extract={displayMedication}
            DataListItem={MedicationDataListItem}
            DefaultDataListItem={DefaultMedicationDataListItem}
            defaultValue={medications}
            onChange={handleValueChange}
          />
        </Form.Row>
      )}
      {/*This form is supposed to be submitted with a Button outside of this Component */}
      {/*Therefore there is no Button in here */}
    </Form>
  )
}

QuestionnaireMedicationStatementForm.defaultProps = {
  readOnly: false,
  onSubmit: () => {}
}

export default QuestionnaireMedicationStatementForm
