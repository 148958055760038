import { takeEvery, call, select, put } from '@redux-saga/core/effects';
import {
  saveFhirResourceWorker,
  removeFhirResourceWorker,
  searchFhirResourceWorker
} from '../Shared/sagas';
import { CREATING_SLOT, REMOVING_SLOT, selectBookedSlot } from './actions';
import {
  MEDEO_APPOINTMENT_TYPE_TELECONSULTATION,
  MEDEO_SLOT_IDENTIFIER_SYSTEM
} from '../utils/codes';
import { getPractitionerRoleBypractitionerID } from '../PractitionerRole/selector';

function* createSlotWorker(action) {
  const { slot } = action.payload;

  // before creating the slot we check if the slot already exist in FHIR
  // the only case where this would be possible is when
  // two users have the slotView already loaded but haven't chosen a slot
  const { payload } = yield call(searchFhirResourceWorker, 'Slot', {
    start: slot.start,
    schedule: slot.scheduleId
  });

  // If no slot was found then create one
  if (!payload?.Slot) {
    const practitionerRole = yield select(
      getPractitionerRoleBypractitionerID,
      slot.performerId
    );
    const [practitionerSnomedCode] = practitionerRole
      .find(pr => pr.specialty)
      ?.specialty?.find(s => s.coding)
      ?.coding?.find(c => c.code)?.code;

    // new slot structure
    const newSlot = {
      resourceType: 'Slot',
      identifier: [
        {
          system: MEDEO_SLOT_IDENTIFIER_SYSTEM,
          value: MEDEO_APPOINTMENT_TYPE_TELECONSULTATION
        }
      ],
      specialty: [
        {
          coding: [
            {
              code: practitionerSnomedCode,
              system: 'http://snomed.info/sct'
            }
          ]
        }
      ],
      schedule: {
        reference: 'Schedule/' + slot.scheduleId
      },
      status: 'busy-tentative', // !R
      start: slot.start, // !R
      end: slot.end // !R
    };

    // save slot in the db
    const bookedSlot = yield call(saveFhirResourceWorker, newSlot);
    // update the store to show the recently created slot as 'currentBookedSlot'
    yield put(selectBookedSlot(bookedSlot));
  } else {
    // is the slot already exist we update the 'currentBookedSlot' in the store first with 'null' and then with 'taken'
    // this way we force a rerender in SlotsView and the slot will disappear
    // if we don't pass 'null' before the 'taken' the second time we select a slot
    // that already existed the useEffect will not be triggered and the last value was 'taken'.
    // This way we force a re render every time the currentBookedSlot change in the store
    yield put(selectBookedSlot(null));
    yield put(selectBookedSlot('taken'));
  }
}

export function* updateSlotStatusWorker(slotId) {
  // fetch booked slot
  const { payload } = yield call(searchFhirResourceWorker, 'Slot', {
    _id: slotId
  });
  // here we will always fetch a slot as it has been created when we select the slot
  if (payload?.Slot?.length !== 0) {
    const bookedSlot = payload.Slot[0];
    // update the booked slot
    bookedSlot.status = 'busy';
    // save it
    yield call(saveFhirResourceWorker, bookedSlot);
  }
}

function* rootSaga() {
  yield takeEvery(
    REMOVING_SLOT,
    removeFhirResourceWorker,
    ({ payload: p }) => ({
      resourceType: 'Slot',
      id: p.id
    })
  );
  yield takeEvery(CREATING_SLOT, createSlotWorker);
}

export default rootSaga;
