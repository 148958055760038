import { faEye } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Trans } from '@lingui/macro'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useEffectOnce } from 'react-use'
import styled from 'styled-components/macro'
import { union, uniq } from 'underscore'
import Attachment from '../../Attachment/components/Attachment'
import AttachmentDownloadControl from '../../Attachment/components/AttachmentDownloadControl'
import AttachmentPrintControl from '../../Attachment/components/AttachmentPrintControl'
import AttachmentViewControl from '../../Attachment/components/AttachmentViewControl'
import { loadClinicalImpressionsFromContext } from '../../ClinicalImpression/actions'
import EditReviewForm from '../../ClinicalImpression/components/EditReviewForm'
import { getClinicalImpressionsFromencounterID } from '../../ClinicalImpression/selectors'
import { Button } from '../../Components'
import * as fromDocumentReferences from '../../DocumentReference/actions'
import DocumentReferenceList from '../../DocumentReference/containers/DocumentReferenceList'
import * as fromObservations from '../../Observation/actions'
import ObservationTypeView from '../../Observation/components/ObservationTypeView'
import {
  filterObservationsByTypes,
  getAllObservationsType
} from '../../Observation/selectors'
import RequirePermission from '../../Permissions/containers/RequirePermission'
import usePractitioner from '../../Practitioner/usePractitioner'
import { getProcedureRequestsFromencounterIDAndPerformerId } from '../../ProcedureRequest/selectors'
import Centered from '../../Shared/components/Centered'
import { LOINC_BREATH_SOUND, LOINC_HEART_SOUND } from '../../utils/loinc'

const Main = styled.div``

const Container = styled.div`
  display: flex;
  align-items: stretch;
  flex-direction: column;
  margin-bottom: ${(props) => props.theme.spacing.medium};
  padding-left: 0.5em;
`

const Top = styled.div``

const BottomObservationView = styled.div`
  margin-top: ${(props) => props.theme.spacing.medium};
`

const ButtonText = styled.span`
  margin-left: ${(props) => props.theme.spacing.small};
`

const DefaultEncounterForm = ({
  encounter,
  patient,
  current,
  readOnly,
  preexistingObservationTypes,
  observations
}) => {
  const currentPractitioner = usePractitioner()
  const dispatch = useDispatch()
  const clinicalImpressions = useSelector((state) =>
    getClinicalImpressionsFromencounterID(state, encounter.id)
  )

  // * alreadyDoneObservationsTypes contains the types of all the observations
  // that are already taken for real by the practitioner during the encounter
  // * preexistingObservationTypes contains all types of observations that were requested
  // * observationTypes is the union of both of them without duplicates
  const alreadyDoneObservationsTypes = getAllObservationsType(observations)
  const observationTypes = uniq(
    union(preexistingObservationTypes, alreadyDoneObservationsTypes)
  )

  // create conditions for displaying the review-related components
  const [openClinicalImpression, setOpenClinicalImpression] = useState(false)
  const [firstClinicalImpression, setFirstClinicalImpression] = useState(null)

  // need to refactor this part with a mapstatetoprops
  let procedureRequestsFromencounterIDAndPerformerId = useSelector((state) =>
    getProcedureRequestsFromencounterIDAndPerformerId(
      state,
      encounter.id,
      currentPractitioner != null ? currentPractitioner.id : null
    )
  )

  // check process for see if the user is requested for a review
  const isUserRequestedForAddingReviewOnThisEncounter =
    procedureRequestsFromencounterIDAndPerformerId.length > 0

  useEffectOnce(() => {
    dispatch(
      fromObservations.loadObservationsFromContext(encounter.id, patient?.id)
    )
    dispatch(
      fromDocumentReferences.loadDocumentReferences(encounter.id, patient?.id)
    )
    dispatch(loadClinicalImpressionsFromContext(encounter.id)) //to get the clinicalImpression data
  })
  useEffect(() => {
    if (clinicalImpressions[0] !== undefined) {
      setFirstClinicalImpression(clinicalImpressions[0])
      setOpenClinicalImpression(true)
    }
  }, [clinicalImpressions, firstClinicalImpression])

  // those are the loincCodes corresponding to observations that we don't want to
  // display in the clinical-exam, for example like stethoscope observations
  const bannishedLoincCodes = [LOINC_BREATH_SOUND, LOINC_HEART_SOUND]

  return (
    <Main>
      {observationTypes.length === 0 && readOnly ? null : (
        <>
          {observationTypes.map((observationType, i) => {
            if (bannishedLoincCodes.includes(observationType) === true) {
              return null
            } else {
              return (
                <Container key={i} id={observationType}>
                  <Top>
                    <ObservationTypeView
                      observations={filterObservationsByTypes(
                        observationType,
                        observations
                      )}
                      observationType={observationType}
                      isActive={current}
                      readOnly={readOnly}
                    />
                  </Top>

                  {encounter.status === 'finished' && (
                    <BottomObservationView>
                      {(firstClinicalImpression || openClinicalImpression) && (
                        <EditReviewForm
                          encounter={encounter}
                          patientID={patient.id}
                          clinicalImpression={firstClinicalImpression}
                          procedureRequest={
                            procedureRequestsFromencounterIDAndPerformerId !=
                            null
                              ? // We take only the first request of the array. There should ever be only one everyway.
                                procedureRequestsFromencounterIDAndPerformerId[0]
                              : null
                          }
                        />
                      )}
                      <RequirePermission
                        resource={'clinicalImpressions'}
                        action={'write'}
                      >
                        {!firstClinicalImpression &&
                          !openClinicalImpression &&
                          isUserRequestedForAddingReviewOnThisEncounter && (
                            <Centered>
                              <Button
                                onClick={() =>
                                  setOpenClinicalImpression(
                                    !openClinicalImpression
                                  )
                                }
                              >
                                <FontAwesomeIcon icon={faEye} />

                                <ButtonText>
                                  <Trans>Add a review</Trans>
                                </ButtonText>
                              </Button>
                            </Centered>
                          )}
                      </RequirePermission>
                    </BottomObservationView>
                  )}
                </Container>
              )
            }
          })}
        </>
      )}

      {/*
        little hack here, we display the document references list here only when the
        encounter is finished. i.e. in a readonly mode like inside the /history of a patient encounters
        We display the list inside the ClinicalEncounter in the case of a 'in-progress' encounter.
        c.f. the usage of AddAttachmentsForm
        in the other hand readOnly === true is required to display the list when the user
        is on a recap of a planDefinition.
      */}
      {(encounter.status === 'finished' || readOnly === true) && (
        <DocumentReferenceList
          encounter={encounter}
          readOnly={readOnly}
          patient={patient}
        >
          {(attachment, progress) => (
            <Attachment attachment={attachment} progress={progress}>
              {/*
                <Attachment[X]Control/> do not require extra action or info
                to work, they use plain html or js features
              */}
              <AttachmentDownloadControl attachment={attachment} />
              <AttachmentPrintControl attachment={attachment} />
              <AttachmentViewControl attachment={attachment} />
            </Attachment>
          )}
        </DocumentReferenceList>
      )}
    </Main>
  )
}

export default DefaultEncounterForm
