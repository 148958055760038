import { NOTIFICATION_CREATED, NOTIFICATION_DELETED } from './actions'

const initialState = []

const notifications = (state = initialState, action) => {
  switch (action.type) {
    case NOTIFICATION_CREATED:
      return [
        ...state,
        {
          notificationType: action.payload.notificationType,
          message: action.payload.message,
          timeout: action.payload.timeout,
        },
      ]
    case NOTIFICATION_DELETED:
      return state.slice(1)
    default:
      return state
  }
}

export default notifications
