import React from 'react';
import { Trans } from '@lingui/macro';

import {
  MEDEO_ENCOUNTER_TYPE_SYSTEM,
  MEDEO_PROCEDURE_REQUEST_BOOKING_APPOINTMENT_CODE,
  MEDEO_PROCEDURE_REQUEST_REMOTE_CONSULTATION_CODE
} from './codes';

export const MEDEO_ENCOUNTER_TYPE_CLINICAL_EXAM = 'clinical-exam';
export const MEDEO_ENCOUNTER_TYPE_PRE_TELECONSULTATION = 'pre_teleconsultation';
export const MEDEO_ENCOUNTER_TYPE_TELECONSULTATION_CONSULTATION =
  'teleconsultation_consultation';
export const MEDEO_ENCOUNTER_TYPE_BOOKING = 'booking-appointment';

export const MedeoEncounterTypes = [
  {
    label: <Trans>Clinical exam</Trans>,
    encounters: [
      {
        coding: [
          {
            system: MEDEO_ENCOUNTER_TYPE_SYSTEM,
            code: MEDEO_ENCOUNTER_TYPE_CLINICAL_EXAM
          }
        ],
        label: (
          <Trans>
            Record medical data and documents in your patient folder
          </Trans>
        ),
        display: <Trans>Add new observations</Trans>,
        action: 'encounter'
      },
      {
        coding: [
          {
            system: MEDEO_ENCOUNTER_TYPE_SYSTEM,
            code: MEDEO_ENCOUNTER_TYPE_BOOKING
          }
        ],
        display: <Trans>Book an appointment</Trans>,
        label: <Trans>Select a slot and a motive for your patient</Trans>,
        linkedProcedureRequestCode: MEDEO_PROCEDURE_REQUEST_BOOKING_APPOINTMENT_CODE,
        action: 'booking-appointment'
      },
      {
        coding: [
          {
            system: MEDEO_ENCOUNTER_TYPE_SYSTEM,
            code: MEDEO_ENCOUNTER_TYPE_PRE_TELECONSULTATION
          }
        ],
        display: <Trans>Start remote consultation</Trans>,
        label: (
          <Trans>Answer the last questions before joining the visio</Trans>
        ),
        linkedProcedureRequestCode: MEDEO_PROCEDURE_REQUEST_REMOTE_CONSULTATION_CODE,
        action: 'pre_teleconsultation'
      }
    ]
  }
];
