import { FC, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import EncounterHeader from '../components/EncounterHeader'
import { Card } from '../../Components'
import * as fromObservations from '../../Observation/actions'
import * as fromDocumentReferences from '../../DocumentReference/actions'
import * as fromQuestionnaireResponse from '../../QuestionnaireResponse/actions'
import {
  filterOutEnteredInErrorObservation,
  filterOutRelatingObservation,
  getObservationsFromencounterID
} from '../../Observation/selectors'
import {
  filterOutEnteredInErrorDocumentReference,
  filterOutRelatingDocumentReference,
  getDocumentReferencesFromencounterID
} from '../../DocumentReference/selectors'
import LoadingFallback from '../../Shared/components/LoadingFallback'
import RequirePermission from '../../Permissions/containers/RequirePermission'
import { getQuestionnaireResponseByencounterID } from '../../QuestionnaireResponse/selector'
import { MEDEO_ENCOUNTER_TYPE_BOOKING } from '../../utils/encounter-type'
import { getCodingFromEncounter } from '../utils'
import { EncounterMain } from './EncounterMain'
import styled from 'styled-components/macro'
import { Encounter, Patient } from 'fhir-stu3'
import { RouteComponentProps } from '@reach/router'

const Container = styled.div``

interface EncounterReadOnlyViewProps extends RouteComponentProps {
  patient: Patient
  encounter: Encounter
  hideDownloadButton: Boolean
}

const EncounterReadOnlyView: FC<EncounterReadOnlyViewProps> = (
  props: EncounterReadOnlyViewProps
) => {
  const { patient, encounter } = props
  // state for open details from EncounterReadOnlyView
  const [collapsed, setCollapsed] = useState(true)
  const dispatch = useDispatch()
  useEffect(() => {
    if (collapsed) {
      return
    }
    dispatch(
      fromObservations.loadObservationsFromContext(encounter.id, patient.id)
    )
    dispatch(
      fromDocumentReferences.loadDocumentReferences(encounter.id, patient.id)
    )
    dispatch(
      fromQuestionnaireResponse.loadQuestionnaireResponseFromEncounter(
        encounter.id,
        patient.id
      )
    )
  }, [encounter.id, patient.id, dispatch, collapsed])

  const observations = useSelector((s) =>
    getObservationsFromencounterID(s, encounter.id, [
      filterOutRelatingObservation,
      filterOutEnteredInErrorObservation
    ])
  )
  // we select the code of the encounterType
  const encounterType = getCodingFromEncounter(encounter)
  const documentReferences = useSelector((s) =>
    getDocumentReferencesFromencounterID(s, encounter.id, [
      filterOutRelatingDocumentReference,
      filterOutEnteredInErrorDocumentReference
    ])
  )

  const questionnaireResponse = useSelector((s) =>
    getQuestionnaireResponseByencounterID(s, encounter.id)
  )

  // Here we define whether the encounter card is collapsable or not
  // depending on the encounter type
  const isCollapsable = encounterType !== MEDEO_ENCOUNTER_TYPE_BOOKING

  const onHeaderClick = isCollapsable
    ? () => {
        setCollapsed(!collapsed)
      }
    : () => {}
  return encounter == null ? (
    <LoadingFallback />
  ) : (
    // we pass the permission resource dynamically
    <RequirePermission resource={encounterType} action={'read'}>
      <Card>
        <EncounterHeader
          encounter={encounter}
          readOnly={true}
          collapsed={collapsed}
          onClick={onHeaderClick}
          isCollapsable={isCollapsable}
        />
        {!collapsed && isCollapsable && (
          <Container>
            <EncounterMain
              documentReferences={documentReferences}
              encounter={encounter}
              patient={patient}
              observations={observations}
              questionnaireResponse={questionnaireResponse}
              readOnly={true}
            />
          </Container>
        )}
      </Card>
    </RequirePermission>
  )
}

export default EncounterReadOnlyView
