import { getCurrentOrganizationId } from '../Auth/selectors'
import { select, call } from 'redux-saga/effects'
import { searchFhirResourceWorker } from '../Shared/sagas'

function* rootSaga() {
  const orgId = yield select(getCurrentOrganizationId)
  yield call(searchFhirResourceWorker, 'Device', {
    organization: orgId,
  })
}

export default rootSaga
