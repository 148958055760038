import React, { useContext, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from '../../Components'
import styled from 'styled-components/macro'
import { Trans } from '@lingui/macro'
import { EditPatientContext } from './EditPatientForm'
import { getOrgMembersOfCareTeam } from '../../CareTeam/selectors'
import { editPatient } from '../actions'
import { filterParticipantsByPatientGP } from '../utils'
import Modal from '../../Shared/components/Modal'

const CustomFooter = styled.footer`
  background-color: white;
  margin-top: 2rem;
  display: flex;
  justify-content: center;
`

const Select = styled.select`
  width: 100%;
`

const Container = styled.div`
  padding: 2rem 2rem 0 2rem;
`

const AddGeneralPractitionerModal = () => {
  const reduxDispatch = useDispatch()
  const [resource, setResource] = useContext(EditPatientContext)
  // here we receive all participants of one careteam, then we filter them
  // to keep only participants which don't appears in gp of patient resource
  const participants = useSelector(s => getOrgMembersOfCareTeam(s))
  const participantsFiltered = filterParticipantsByPatientGP(
    resource,
    participants
  )

  const [orgaId, setOrgaId] = useState('')

  const handleClick = close => {
    // we create a new generalPractitioner array where we will add
    // the new practitioner reference if it doesn't already exist
    // we prevent the variable error for the some method with an empty array
    const newGeneralPractitioner = resource.generalPractitioner || []

    // we prevent if the user doesn't select a option
    if (orgaId === '') {return}

    // we test if this organization is already attached to
    // this patient if it's the case we stop it now
    const alreadyExist = newGeneralPractitioner.some(
      newGp => newGp.reference === `Organization/${orgaId}`
    )
    if (alreadyExist) {return}

    // we create a new object to integrate it on generalPractitioner of patient resource.
    const item = {
      reference: `Organization/${orgaId}`,
      identifier: {
        system: 'https://medeo.io/fhir/Identifier/general-practitioner',
        value: 'medecin-du-territoire'
      }
    }

    // we add it to the new generalPractitioner array
    newGeneralPractitioner.push(item)
    // we replace the previous generalPractitioner by the new one
    let patientUpdated = {
      ...resource,
      generalPractitioner: newGeneralPractitioner
    }
    setResource(patientUpdated)
    reduxDispatch(editPatient(patientUpdated))
    // we force the select to refresh his value to put default one
    document.getElementById('selectOrga').selectedIndex = 0
    close()
  }

  return (
    <Modal
      title={<Trans>Add practitioner</Trans>}
      component={props => (
        <Button {...props} type="button" color="ocean" variant="outline">
          + <Trans>Add an access</Trans>
        </Button>
      )}
    >
      {close => (
        <Container>
          <p>
            <Trans>
              Choose which outside collaborator will have access to the patient
              folder
            </Trans>
          </p>
          <Select
            name="organization"
            label={<Trans>Outside collaborators</Trans>}
            onChange={e => setOrgaId(e.target.value)}
            id="selectOrga"
            defaultValue=""
          >
            <option value="" disabled>
              Sélectionner une organisation
            </option>
            {participantsFiltered.map((org, i) => (
              <option key={i} value={org.id}>
                {org.name}
              </option>
            ))}
          </Select>
          <CustomFooter>
            <Button
              type="button"
              color="ocean"
              onClick={() => handleClick(close)}
            >
              <Trans>Save</Trans>
            </Button>
          </CustomFooter>
        </Container>
      )}
    </Modal>
  )
}

export default AddGeneralPractitionerModal
