import { useEffect } from 'react'
import { useLocation, useNavigate } from '@reach/router'
import { configureAnalytics } from '../../Shared/analytics'
import usePractitioner from '../usePractitioner'

const RequirePractitioner = ({ children }) => {
  const practitioner = usePractitioner()
  const navigate = useNavigate()
  const location = useLocation()

  // Whatever page we're on, if currentPractitioner is null we redirect to /auth
  useEffect(() => {
    if (practitioner == null) {
      navigate(`/auth`, { state: { from: location.href } }).then(() => {
        return () => {}
      })

    }
    // sets the user info in sentry scope
    // see https://docs.sentry.io/enriching-error-data/additional-data/
    configureAnalytics(practitioner)
  }, [location.href, navigate, practitioner])

  /*
  return practitioner == null ? (
    <PractitionerAuth />
  ) : practitionerAuthState.step !== TYPE_CODE_PIN_FORM ? (
    children
  ) : (
    // This happens in the case where the practitioner context is not null
    // But the pin code got unvalidated, so we want to ask for it again
    <Auth />
  );*/

  return practitioner == null ? null : children
}

export default RequirePractitioner
