import { API, Auth } from 'aws-amplify'

/**
 * it retrieves a stripe account by providing a stripe account id
 * @param accountId
 */
export const retrieveAccountLambda = async accountId => {
  const json = await API.post('payment', '/payment/retrieve-account', {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${(await Auth.currentSession())
        .getAccessToken()
        .getJwtToken()}`
    },
    body: {
      stripeAccountId: accountId
    }
  })

  return json.account
}

/**
 * It takes an accessCode and returns a stripeAccountId
 * @param stripeCode
 * @returns {Promise<*>}
 */
export const completeConnectStripeAccountLambda = async stripeCode => {
  const json = await API.post('payment', '/payment/complete-account-creation', {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${(await Auth.currentSession())
        .getAccessToken()
        .getJwtToken()}`
    },
    body: {
      accessCode: stripeCode
    }
  })
  return json.account.stripe_user_id
}

/**
 * User cannot access the stripe dashboard only if its logged in Medeo and clicks on the Button.
 * This function will call a lambda using the amplify API to request for an access which will return an object
 * containing the URL we will use to redirect.
 * @param {string} stripeAccountId
 * @returns {Promise<object>}
 */
export const createLoginLink = async stripeAccountId => {
  return await API.post('payment', '/payment/create-dashboard-access', {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${(await Auth.currentSession())
        .getAccessToken()
        .getJwtToken()}`
    },
    body: {
      stripeAccountId
    }
  })
}
